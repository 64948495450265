import {Capacitor} from '@capacitor/core';
import {AiOutlineRight} from '@react-icons/all-files/ai/AiOutlineRight';
import moment from 'moment';
import {useQuery} from 'react-query';
import {Navigate, useNavigate} from 'react-router';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';

import emptyBg from 'assets/img/empty_dataset_images/rental_applications.svg';
import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import EmptyDataSet from 'components/common/EmptyDataSet';
import LoadingView from 'components/common/LoadingView';
import {HeaderButton} from 'components/navbar/Header';
import PageWrapper from 'components/PageWrapper';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import RentalApplication from 'models/listings/RentalApplication';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';

const RentalApplicationIndexPage = () => {
  usePageVisit('RentalApplicationIndexPage');
  const {activeAccountRole} = useLocalUserSettings();

  const navigate = useNavigate();

  if (activeAccountRole !== 'Renter') {
    toast.error('Sorry, this page is only for tenants.');
    navigate('/');
  }

  const {data, isLoading, error} = useQuery(
    `renter-rental-applications`,
    async () => {
      const apps = await RentalApplication.select(['id', 'created_at'])
        .includes(['rental_application_applicants'])
        .order({createdAt: 'desc'})
        .all();

      return apps.data;
    },
    {enabled: activeAccountRole === 'Renter'},
  );

  const navigateToNew = () => navigate('new');

  const buttons: HeaderButton[] = Capacitor.isNativePlatform()
    ? [{text: 'New', href: '/rental-applications/new'}]
    : [{text: 'New Application', href: '/rental-applications/new'}];

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Rental Applications" buttons={buttons}>
        <LoadingView />
      </PageWrapper>
    );
  } else {
    if (data.length > 0) {
      return (
        <PageWrapper title="Rental Applications" buttons={buttons}>
          <Card className="mt-2">
            <CardBody>
              <CardTitle>Your Rental Applications</CardTitle>
              <p>
                Below are the rental applications you have created, the most
                recent one will be used to apply when you click the apply button
                on a listing.
              </p>

              {data.map((app) => (
                <Link
                  key={app.id}
                  to={app.id}
                  className="my-2 py-2 px-2 flex justify-between items-center hover:bg-gray-100">
                  <div className="flex flex-col">
                    <strong>
                      Created {moment(app.createdAt).format('DD MMM YYYY')}
                    </strong>
                    {app.rentalApplicationApplicants.length > 0 && (
                      <span className="block text-sm">
                        With:{' '}
                        {app.rentalApplicationApplicants
                          .map((a) => a.name)
                          .join(', ')}
                      </span>
                    )}
                    <Link
                      to={`${app.id}/listings`}
                      className="btn btn-neutral btn-sm mt-2">
                      View Listings Applied For
                    </Link>
                  </div>
                  <div>
                    <AiOutlineRight className="w-5 h-5 text-gray-300" />
                  </div>
                </Link>
              ))}
            </CardBody>
          </Card>
        </PageWrapper>
      );
    } else {
      return (
        <PageWrapper title="Rental Applications" buttons={buttons}>
          <EmptyDataSet
            title="No rental applications found"
            message="You haven't created a rental application yet. Click the button below to create an application and start applying for properties."
            image={emptyBg}
            buttonTitle="New Application"
            buttonAction={navigateToNew}
          />
        </PageWrapper>
      );
    }
  }
};

export default RentalApplicationIndexPage;
