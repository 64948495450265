import React, {useCallback, useState} from 'react';

import {useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {toast} from 'react-toastify';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import LandlordNewEnquiryThreadModal from 'components/enquiry_thread/LandlordNewEnquiryThreadModal';
import PageWrapper from 'components/PageWrapper';
import RentalApplicationDetailView from 'components/rental_application/RentalApplicationDetailView';
import {Modal} from 'components_sb/layout';
import ListingRentalApplication from 'models/listings/ListingRentalApplication';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';

import RequestApplicationCheckModal from './RequestApplicationCheckModal';

const {useModal} = Modal.Imperative;

const RentalApplicationDetailPage = () => {
  usePageVisit('RentalApplicationDetailPage');
  const {propertyId, id} = useParams();
  const queryClient = useQueryClient();

  const [notes, setNotes] = useState('');

  const [enquiryModalOpen, setEnquiryModalOpen] = useState(false);

  const navigate = useNavigate();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const {
    data: application,
    isLoading,
    error,
  } = useQuery(`rental-application-${id}`, async () => {
    const response = await ListingRentalApplication.includes([
      {
        rental_application: ['rental_application_applicants', 'user'],
      },

      'listing',
    ]).find(id);

    return response.data;
  });

  const markAsShortlisted = async () => {
    const app = application;
    app.landlordTag = 'shortlisted';

    const result = await app.save();

    if (result) {
      toast.success('Application successfully shortlisted!');
      queryClient.setQueryData(`rental-application-${id}`, app);
    }
  };

  const markAsExcluded = async () => {
    const app = application;
    app.landlordTag = 'excluded';

    const result = await app.save();

    if (result) {
      toast.success('Application has been excluded.');
      queryClient.setQueryData(`rental-application-${id}`, app);
    }
  };

  const saveNotes = async () => {
    const app = application;
    app.landlordNotes = notes;

    const result = await app.save();

    if (result) {
      toast.success('Notes successfully saved!');
      queryClient.setQueryData(`rental-application-${id}`, app);
      setNotes('');
    }
  };

  const confirmAcceptApplication = () => {
    setConfirmationOptions({
      title: 'Accept Application',
      message:
        'This will let the tenant know they have been chosen for this property. Their information will automatically be added to your in progress lease.',
      buttonTitle: 'Confirm',
      color: 'success',
      action: acceptApplication,
    });
  };
  const acceptApplication = async () => {
    const app = application;
    app.accepted = true;

    const result = await app.save();

    const requests = {
      tenancyRequests: [] as any[],
      numberOfTenants: null as any,
    };

    requests['tenancyRequests'].push({
      renterEmail: app.rentalApplication.headTenantEmail,
    });
    app.rentalApplication.rentalApplicationApplicants.forEach((applicant) =>
      requests['tenancyRequests'].push({
        renterEmail: applicant.email,
      }),
    );
    requests['numberOfTenants'] = requests['tenancyRequests'].length;

    localStorage.setItem(
      `property-${propertyId}-new-invite-tenants`,
      JSON.stringify(requests),
    );

    if (result) {
      toast.success('Application successfully accepted!');
      queryClient.invalidateQueries(`rental-application-${id}`);
      setNotes('');
    }
  };

  const showTenancyEditor = () => {
    localStorage.setItem('new-property-id', propertyId);
    navigate('/properties/new');
  };

  const showEnquiryModal = () => setEnquiryModalOpen(true);

  const openModal = useModal();
  const onRequestCheckClicked = useCallback(() => {
    openModal(RequestApplicationCheckModal, {application});
  }, [openModal, application]);

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Rental Application">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper
        title="Rental Application"
        buttons={[
          {text: 'Accept Application', onClick: confirmAcceptApplication},
          {text: 'Shortlist', onClick: markAsShortlisted},
          {text: 'Exclude', onClick: markAsExcluded},
          {text: 'Send a Message', onClick: showEnquiryModal},
        ]}
        showButtonsAsDropDown
        dropdownTitle="Actions">
        {application.accepted && (
          <div
            className="alert alert-success shadow-lg cursor-pointer"
            onClick={showTenancyEditor}>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span>
                This application was successfully chosen. Click here to go to
                your lease builder.
              </span>
            </div>
          </div>
        )}

        {application.landlordTag === 'shortlisted' && (
          <div
            className="alert alert-info shadow-lg cursor-pointer"
            onClick={onRequestCheckClicked}>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span>
                {`You've shortlisted this application. Click here if you'd like to request a credit, background, reference, or socials check on the applicant(s).`}
              </span>
            </div>
          </div>
        )}

        <RentalApplicationDetailView
          rentalApplication={application.rentalApplication}
          tag={application.landlordTag as any}
        />

        <Card className="mt-4">
          <CardBody>
            <CardTitle>Notes</CardTitle>
            <p className="text-secondary text-sm">
              Add any notes for this application.
            </p>

            {application.landlordNotes && <p>{application.landlordNotes}</p>}

            {!application.landlordNotes && (
              <div>
                <textarea
                  rows={3}
                  className="input input-bordered w-full h-auto"
                  placeholder="Write any notes that you might want to record in here."
                  onChange={(e) => setNotes(e.target.value)}
                  value={notes}></textarea>

                <button
                  className="btn btn-neutral btn-block mt-2"
                  disabled={notes.length === 0}
                  onClick={saveNotes}>
                  Save Notes
                </button>
              </div>
            )}
          </CardBody>
        </Card>

        <LandlordNewEnquiryThreadModal
          modalIsOpen={enquiryModalOpen}
          setModalIsOpen={setEnquiryModalOpen}
          application={application.rentalApplication}
          listingId={application.listingId}
        />
      </PageWrapper>
    );
  }
};

export default RentalApplicationDetailPage;
