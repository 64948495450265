import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import TenancyMembershipsTable from 'components/tenancy/TenancyMembershipsTable';
import TenancyRequestsTable from 'components/tenancy/TenancyRequestsTable';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';

const TenantsCard = ({
  property,
  tenancy,
}: {
  property: Property;
  tenancy?: Tenancy;
}) => {
  if (tenancy) {
    return (
      <Card className="mt-8">
        <CardBody>
          <TenancyRequestsTable
            tenancyRequests={tenancy.tenancyRequests}
            tenancyInvites={tenancy.tenancyInvites}
            propertyId={property.id}
          />

          <TenancyMembershipsTable
            tenancyMemberships={tenancy.tenancyMemberships}
            headTenantId={tenancy.headTenantId}
          />
        </CardBody>
      </Card>
    );
  } else {
    return (
      <Card className="mt-8">
        <CardBody>
          <CardTitle>Tenants</CardTitle>
          <div>
            <p>There is no current tenancy for this property</p>
          </div>
        </CardBody>
      </Card>
    );
  }
};

export default TenantsCard;
