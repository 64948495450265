import React, {useState} from 'react';

import {Form, Formik, type FormikHelpers} from 'formik';
import {PersistFormikValues} from 'formik-persist-values';
import _ from 'lodash';
import moment from 'moment';
import {useQuery} from 'react-query';
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import {
  DateField,
  DOBField,
  InputField,
  PseudoBooleanField,
  SelectField,
  SubmitButton,
  TextareaField,
} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import RenterPetField from 'components/forms_fields/RenterPetsField';
import SignatureModalInput from 'components/forms_fields/SignatureModalInput';
import PageWrapper from 'components/PageWrapper';
import RentalApplication from 'models/listings/RentalApplication';
import RentalApplicationApplicant from 'models/listings/RentalApplicationApplicant';
import User from 'models/users/User';
import useAuth from 'services/useAuth';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {useAutoAnimate, usePageVisit} from 'utilities/hooks';

type Applicant = {
  name: string;
  dateOfBirth: string;
  email: string;
  weeklyIncome: number | null;
};

const NewRentalApplicationPage = () => {
  usePageVisit('NewRentalApplicationPage');
  const [numberOfApplicants, setNumberOfApplicants] = useState(0);
  const [applicantAnimateRef] = useAutoAnimate();
  const [petTypeAnimateRef] = useAutoAnimate();

  const navigate = useNavigate();

  const handleSubmit = async (formValues: any, actions: FormikHelpers<any>) => {
    const attrs = {...formValues};
    for (const key in attrs) {
      const val = attrs[key];
      if (val === '') {
        attrs[key] = null;
      }
    }
    const applicants: RentalApplicationApplicant[] =
      attrs.rentalApplicationApplicants.map((applicant: any) => {
        const applicantAttrs = {...applicant};
        for (const key in applicantAttrs) {
          const val = applicantAttrs[key];
          if (val === '') {
            applicantAttrs[key] = null;
          }
        }
        return new RentalApplicationApplicant(applicantAttrs);
      });
    delete attrs.rentalApplicationApplicants;

    const app = new RentalApplication(attrs);
    app.headTenantSignedAt = moment().format();
    app.rentalApplicationApplicants = applicants;

    const result = await app.save({with: 'rentalApplicationApplicants'});

    if (result) {
      localStorage.removeItem('new-rental-application-form');
      toast.success('Your application has been successfully created!');

      navigate(`/rental-applications/${app.id}`);
    } else {
      for (const key of Object.keys(app.errors)) {
        const message = app.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
      for (const index in applicants) {
        const applicant = applicants[index];
        if (applicant.errors) {
          for (const key of Object.keys(applicant.errors)) {
            const message = applicant.errors[key].fullMessage;
            const formikKey = `rentalApplicationApplicants[${index}].${key}`;
            actions.setFieldError(formikKey, message);
          }
        }
      }
    }

    actions.setSubmitting(false);
  };

  const {currentUser} = useAuth();

  const {data, isLoading, error} = useQuery('current-renter', async () => {
    const user = await User.includes('renter_profile').find(currentUser.id);

    return user.data;
  });

  const handleApplicantSelectChange = (formik: any, e: any) => {
    e.preventDefault();
    const val = Number(e.target.value);

    const applicants = formik.values.rentalApplicationApplicants;

    if (val < applicants.length) {
      const diff = applicants.length - val;
      _.times(diff, () => {
        applicants.pop();
      });
    } else if (val > applicants.length) {
      const diff = val - applicants.length;
      _.times(diff, () => {
        applicants.push({
          name: '',
          dateOfBirth: null,
          email: '',
          weeklyIncome: null,
        } as Applicant);
      });
    }

    formik.setFieldValue('rentalApplicationApplicants', applicants);
    setNumberOfApplicants(applicants.length);
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="New Rental Application">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="New Rental Application">
        <Formik
          initialValues={{
            headTenantName: data.name,
            headTenantEmail: data.email,
            headTenantDob: data.renterProfile.birthDate,
            headTenantPhoneNumber: data.renterProfile.phoneNumber,

            idType: '',
            idNumber: '',

            occupation: data.renterProfile.jobTitle,
            weeklyIncome: null,
            employer: '',
            yearsEmployedAtCurrentPlace: '',
            headTenantEmployedFullTime: 'Unspecified',

            currentAddress: '',
            currentAddressReasonLeaving: '',
            currentAddressStayLength: '',

            aboutUs: '',

            preferredTenancyLength: null,
            preferredTenancyStartDate: null,
            cantCompleteTenancyReasons: '',
            vehiclesCount: null,
            hasPets: 'Unspecified',
            petTypes: {} as Record<string, number>,

            employerReferenceName: '',
            employerReferenceCompanyName: '',
            employerReferenceRelationship: '',
            employerReferenceContactNumber: '',

            landlordReferenceName: '',
            landlordReferenceRelationship: '',
            landlordReferenceContactNumber: '',

            otherReferenceName: '',
            otherReferenceRelationship: '',
            otherReferenceContactNumber: '',

            additionalTotalOccupants: null,
            rentalApplicationApplicants: [] as Applicant[],
            applicantsCanProvideReferences: 'Unspecified',

            anySmokers: 'Unspecified',
            involvedWithTenancyTribunal: 'Unspecified',
            tenancyTribunalInformation: null,
            pastBondDeductions: 'Unspecified',
            oweMoneyToLandlord: 'Unspecified',
            hasMissedRentPayments: 'Unspecified',
            canPayBondAndAdvance: 'Unspecified',
            wouldLikeHelpWithUtilities: 'Unspecified',
            anyBadCredit: 'Unspecified',
            anyCriminalRecords: 'Unspecified',

            headTenantSignature: null,
          }}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            headTenantName: Yup.string().required().min(2).label('Full Name'),
            headTenantEmail: Yup.string().email().required().label('Email'),
            headTenantPhoneNumber: Yup.string()
              .required()
              .nullable()
              .label('Phone Number'),
            headTenantDob: Yup.string()
              .optional()
              .nullable()
              .label('Date Of Birth')
              .test('is-valid-date', 'Please enter a valid date', (value) => {
                if (!value) return true;

                return (
                  moment(value, 'DD/MM/YYYY').isValid() ||
                  moment(value, 'YYYY-MM-DDD').isValid()
                );
              }),
            additionalTotalOccupants: Yup.number()
              .nullable()
              .min(0)
              .max(20)
              .label('Additional Number of total occupants')
              .required(),
            rentalApplicationApplicants: Yup.array()
              .of(
                Yup.object().shape({
                  name: Yup.string().required().label('Name').min(2),
                  email: Yup.string().required().label('Email'),
                  dateOfBirth: Yup.string()
                    .optional()
                    .nullable()
                    .label('Date Of Birth')
                    .test(
                      'is-valid-date',
                      'Please enter a valid date',
                      (value) => {
                        if (!value) return true;

                        return (
                          moment(value, 'DD/MM/YYYY').isValid() ||
                          moment(value, 'YYYY-MM-DDD').isValid()
                        );
                      },
                    ),
                  weeklyIncome: Yup.number()
                    .optional()
                    .nullable()
                    .label('Weekly Income')
                    .min(0),
                }),
              )
              .min(0)
              .max(5)
              .label('Other Tenants'),
            headTenantSignature: Yup.string()
              .required()
              .min(2)
              .nullable()
              .label('Signature'),
            preferredTenancyLength: Yup.number()
              .optional()
              .nullable()
              .label('Preferred Tenancy Length'),
          })}>
          {(formik) => (
            <Form>
              <Card className="mt-2">
                <CardBody>
                  <CardTitle>New Rental Application</CardTitle>
                  <p className="mt-2">
                    Apply for rentals easily with Keyhook. Complete your
                    comprehensive application below and use it to apply for any
                    Keyhook listing.
                  </p>

                  <p className="mt-2">
                    Your information will auto save for this device.
                  </p>
                  <p className="mt-2">Fields with a * are required.</p>
                  <p className="mt-2">
                    For free assistance with your application, book a call with
                    our support team{' '}
                    <a
                      className="link link-primary"
                      href="https://www.keyhook.com/book-a-call"
                      target="_blank">
                      here.
                    </a>
                  </p>
                </CardBody>
              </Card>

              <Card className="mt-4">
                <CardBody>
                  <CardTitle>Your Basic Information</CardTitle>

                  <FormRow>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="headTenantName"
                        label="Full Name*"
                        disabled
                      />
                    </FormRowItem>
                    <FormRowItem>
                      <DOBField
                        formik={formik}
                        name="headTenantDob"
                        label="Date Of Birth"
                      />
                    </FormRowItem>
                  </FormRow>

                  <FormRow>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="headTenantEmail"
                        label="Email Address*"
                        disabled
                      />
                    </FormRowItem>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="headTenantPhoneNumber"
                        label="Phone Number*"
                      />
                    </FormRowItem>
                  </FormRow>

                  <FormRow>
                    <FormRowItem>
                      <SelectField
                        formik={formik}
                        name="idType"
                        label="Identification Type">
                        <option value="">Choose an identification type</option>
                        <option value="Passport">Passport</option>
                        <option value="Drivers License">Drivers License</option>
                      </SelectField>
                    </FormRowItem>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="idNumber"
                        label="Identification Number"
                        placeholder="Eg: LMZ123"
                      />
                    </FormRowItem>
                  </FormRow>

                  <CardTitle className="mt-2">Employment Information</CardTitle>

                  <FormRow>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="occupation"
                        label="Occupation"
                        placeholder="Eg: Policy Analyst"
                      />
                    </FormRowItem>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="weeklyIncome"
                        label="Weekly Income Before Tax ($)"
                        type="number"
                        min={0}
                        max={50000}
                        step={1}
                        placeholder="Eg: 1835.00"
                      />
                    </FormRowItem>
                  </FormRow>

                  <FormRow>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="employer"
                        label="Employer"
                        placeholder="Eg: Ministry of Health"
                      />
                    </FormRowItem>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="yearsEmployedAtCurrentPlace"
                        label="Years employed there"
                        placeholder="Eg: 1.5  or 0.5"
                        type="number"
                        min={0}
                        max={100}
                        step={0.5}
                      />
                    </FormRowItem>
                  </FormRow>

                  <div className="w-1/2">
                    <PseudoBooleanField
                      formik={formik}
                      name="headTenantEmployedFullTime"
                      label="Are you currently employed full time?"
                    />
                  </div>

                  <CardTitle className="mt-2">
                    Current Living Situation
                  </CardTitle>
                  <FormRow>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="currentAddress"
                        label="What is your current address?"
                        placeholder="Eg: 123 Example Street, Wellington, New Zealand"
                      />
                    </FormRowItem>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="currentAddressStayLength"
                        label="How long have you stayed there?"
                        placeholder="Eg: 7 months"
                      />
                    </FormRowItem>
                  </FormRow>

                  <TextareaField
                    formik={formik}
                    name="currentAddressReasonLeaving"
                    label="What is your reason for leaving?"
                    placeholder="Eg: Moving out of home."
                  />
                </CardBody>
              </Card>

              <Card className="mt-4">
                <CardBody>
                  <CardTitle>About Us</CardTitle>
                  <p className="text-secondary">
                    Include a short bio about yourself and any other
                    occupants/tenants.
                  </p>

                  <TextareaField
                    formik={formik}
                    name="aboutUs"
                    label="Bio"
                    placeholder="Share a bit about yourself..."
                    rows={5}
                  />
                </CardBody>
              </Card>

              <Card className="mt-4">
                <CardBody>
                  <CardTitle>Tenancy Information</CardTitle>
                  <FormRow>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="preferredTenancyLength"
                        label="Preferred Tenancy Length (months)"
                        placeholder="Eg: 12"
                        type="number"
                        min={1}
                        max={1000}
                        step={1}
                      />
                    </FormRowItem>

                    <FormRowItem>
                      <DateField
                        formik={formik}
                        name="preferredTenancyStartDate"
                        label="Preferred Tenancy Start Date"
                        minDate={new Date()}
                        maxDate={new Date('2100-01-01')}
                      />
                    </FormRowItem>
                  </FormRow>

                  <TextareaField
                    formik={formik}
                    name="cantCompleteTenancyReasons"
                    label="Are there any reasons you might not be able to complete the length of the tenancy?"
                    placeholder="Eg: Visa or Work permit expiring, possible work relocation etc."
                  />

                  <InputField
                    formik={formik}
                    name="vehiclesCount"
                    label="How many vehicles will be parked at the property?"
                    type="number"
                    min={0}
                    max={20}
                    step={1}
                    placeholder="Eg: 2"
                  />

                  <div className="w-1/2">
                    <PseudoBooleanField
                      formik={formik}
                      name="hasPets"
                      label="Do you or any tenant have pets?"
                    />

                    <div ref={petTypeAnimateRef as any}>
                      {Object.entries(formik.values.petTypes).map(
                        ([key, val], index) => (
                          <span className="block" key={index}>
                            {`${key} x ${val}`}
                          </span>
                        ),
                      )}
                    </div>

                    {formik.values.hasPets === 'Yes' ? (
                      <div>
                        <RenterPetField formik={formik} formikKey="petTypes" />
                      </div>
                    ) : null}
                  </div>
                </CardBody>
              </Card>

              <Card className="mt-4">
                <CardBody>
                  <CardTitle>References</CardTitle>
                  <strong>Employer Reference</strong>

                  <FormRow>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="employerReferenceName"
                        label="Full Name"
                        placeholder="Eg: David Smith"
                      />
                    </FormRowItem>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="employerReferenceCompanyName"
                        label="Company Name"
                        placeholder="Eg: Ministry of Health"
                      />
                    </FormRowItem>
                  </FormRow>

                  <FormRow>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="employerReferenceRelationship"
                        label="Relationship"
                        placeholder="Eg: Team Leader"
                      />
                    </FormRowItem>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="employerReferenceContactNumber"
                        label="Contact Number"
                        placeholder="Eg: 022 123 4576"
                      />
                    </FormRowItem>
                  </FormRow>

                  <strong>Landlord Reference</strong>

                  <FormRow>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="landlordReferenceName"
                        label="Full Name"
                        placeholder="Eg: David Smith"
                      />
                    </FormRowItem>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="landlordReferenceRelationship"
                        label="Relationship"
                        placeholder="Eg: Landlord"
                      />
                    </FormRowItem>
                  </FormRow>

                  <FormRow>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="landlordReferenceContactNumber"
                        label="Contact Number"
                        placeholder="Eg: 022 123 4576"
                      />
                    </FormRowItem>
                  </FormRow>

                  <strong>Other Reference</strong>
                  <small className="block text-secondary">
                    This should preferably be another landlord.
                  </small>

                  <FormRow>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="otherReferenceName"
                        label="Full Name"
                        placeholder="Eg: David Smith"
                      />
                    </FormRowItem>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="otherReferenceRelationship"
                        label="Relationship"
                        placeholder="Eg: Landlord"
                      />
                    </FormRowItem>
                  </FormRow>

                  <FormRow>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="otherReferenceContactNumber"
                        label="Contact Number"
                        placeholder="Eg: 022 123 4576"
                      />
                    </FormRowItem>
                  </FormRow>
                </CardBody>
              </Card>

              <Card className="mt-4">
                <CardBody>
                  <CardTitle>Other Tenants</CardTitle>

                  <FormRow>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="additionalTotalOccupants"
                        label="Additional number of occupants*"
                        type="number"
                        placeholder="Excluding yourself, eg: 4"
                        min={0}
                        max={20}
                      />
                    </FormRowItem>
                    <FormRowItem>
                      <span className="label">
                        <span className="label-text">
                          Additional tenants who will sign the lease*
                        </span>
                      </span>
                      <select
                        className="select select-bordered w-full"
                        value={numberOfApplicants}
                        onChange={(e) =>
                          handleApplicantSelectChange(formik, e)
                        }>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </FormRowItem>
                  </FormRow>

                  <div ref={applicantAnimateRef as any}>
                    {formik.values.rentalApplicationApplicants.map(
                      (applicant: any, index: number) => (
                        <div className="mt-4 bg-gray-100 p-4" key={index}>
                          <strong>Additional Tenant {index + 1}</strong>
                          <FormRow>
                            <FormRowItem>
                              <InputField
                                formik={formik}
                                name={`rentalApplicationApplicants[${index}].name`}
                                label="Full Name*"
                                placeholder="Eg: Jane Smith"
                              />
                            </FormRowItem>
                            <FormRowItem>
                              <InputField
                                formik={formik}
                                name={`rentalApplicationApplicants[${index}].email`}
                                label="Email Address*"
                                placeholder="Eg: jane.smith@gmail.com"
                                type="email"
                              />
                            </FormRowItem>
                          </FormRow>
                          <FormRow>
                            <FormRowItem>
                              <DOBField
                                formik={formik}
                                name={`rentalApplicationApplicants[${index}].dateOfBirth`}
                                label="Date Of Birth"
                              />
                            </FormRowItem>
                            <FormRowItem>
                              <InputField
                                formik={formik}
                                name={`rentalApplicationApplicants[${index}].weeklyIncome`}
                                label="Weekly Income Before Tax ($)"
                                placeholder="Eg: 1835.0"
                                type="number"
                                min={0}
                                max={50000}
                                step={1}
                              />
                            </FormRowItem>
                          </FormRow>
                        </div>
                      ),
                    )}
                  </div>

                  <div className="mt-2 w-1/2">
                    <PseudoBooleanField
                      formik={formik}
                      name="applicantsCanProvideReferences"
                      label="Can all of the tenants above provide up to 3 references?"
                    />
                  </div>
                </CardBody>
              </Card>

              <Card className="mt-4">
                <CardBody>
                  <CardTitle>About the tenants</CardTitle>

                  <p className="mt-2">
                    This information is voluntary and is not required to
                    complete your application. The questions below refer to all
                    proposed tenants included on this application, including
                    yourself. If you're unsure of an answer, leave unspecified.
                  </p>

                  <div>
                    <PseudoBooleanField
                      formik={formik}
                      name="anySmokers"
                      label="Are any of the proposed tenants smokers?"
                    />
                  </div>

                  <div>
                    <PseudoBooleanField
                      formik={formik}
                      name="involvedWithTenancyTribunal"
                      label="Have any of the proposed tenants been involved with the Tenancy Tribunal?"
                    />
                    {formik.values.involvedWithTenancyTribunal === 'Yes' ? (
                      <TextareaField
                        formik={formik}
                        name="tenancyTribunalInformation"
                        label="Please provide further information"
                      />
                    ) : null}
                  </div>

                  <div>
                    <PseudoBooleanField
                      formik={formik}
                      name="pastBondDeductions"
                      label="Has any proposed tenant ever had money deducted from their bond?"
                    />
                  </div>

                  <div>
                    <PseudoBooleanField
                      formik={formik}
                      name="oweMoneyToLandlord"
                      label="Does any proposed tenant currently owe money to a landlord or property manager?"
                    />
                  </div>

                  <div>
                    <PseudoBooleanField
                      formik={formik}
                      name="hasMissedRentPayments"
                      label="Has any proposed tenant ever missed a rent payment?"
                    />
                  </div>

                  <div>
                    <PseudoBooleanField
                      formik={formik}
                      name="canPayBondAndAdvance"
                      label="Are you able to pay any rent in advance and bond immediately upon being accepted?"
                    />
                  </div>

                  {/* <div>
                    <PseudoBooleanField
                      formik={formik}
                      name="wouldLikeHelpWithUtilities"
                      label="Would you like help setting up utilities? I.e., internet, power, gas, etc"
                    />
                  </div> */}

                  <div>
                    <PseudoBooleanField
                      formik={formik}
                      name="anyBadCredit"
                      label="Does any proposed tenant have a low credit score? (Below 500)"
                    />
                  </div>

                  <div>
                    <PseudoBooleanField
                      formik={formik}
                      name="anyCriminalRecords"
                      label="Do any of the proposed tenants have a criminal record?"
                    />
                  </div>
                </CardBody>
              </Card>

              <Card className="mt-4">
                <CardBody>
                  <CardTitle>Confirmation of Application</CardTitle>
                  <p className="text-sm text-secondary">
                    By confirming your application and signing below, you
                    confirm that all the above information is true and accurate
                    and may be used for a credit and reference check.
                  </p>
                  <p className="text-sm text-secondary">
                    I authorise the Landlord/Property Manager to:
                  </p>
                  <ul className="text-sm text-secondary ml-4">
                    <li>
                      &gt; collect, retain and use this information for the
                      purpose of assessing my creditworthiness and suitability
                      for the tenancy; and
                    </li>
                    <li>
                      &gt; disclose information about me, whether collected from
                      me directly or from any other source, to any other credit
                      provider or any credit reporting agency for the purposes
                      of providing or obtaining a credit report (which will
                      involve the credit reporting agency providing information
                      about me to the Landlord/Property Manager).{' '}
                    </li>
                  </ul>

                  <p className="text-sm text-secondary">
                    I understand that the credit reporting agency:
                  </p>
                  <ul className="text-sm text-secondary ml-4 mb-4">
                    <li>
                      &gt; may hold my information on their credit reporting
                      database and use it for providing credit reporting
                      services, and they may disclose my information to their
                      subscribers for the purpose of credit checking or debt
                      collection; and
                    </li>
                    <li>
                      &gt; as part of providing a credit report, may check the
                      Ministry of Justice fines database for any overdue fines
                      Under the Privacy Act 2020, you have the right to ask for
                      a copy of all information held about you, and have the
                      right to request the correction of any incorrect
                      information.
                    </li>
                  </ul>
                  <SignatureModalInput
                    mode="formik"
                    name="headTenantSignature"
                  />
                  <div className="mt-5">
                    <SubmitButton
                      formik={formik}
                      text="Save Application"
                      submittingText="Saving"
                    />
                  </div>
                </CardBody>
              </Card>

              <PersistFormikValues
                name="new-rental-application-form"
                persistInvalid={true}
              />
            </Form>
          )}
        </Formik>
      </PageWrapper>
    );
  }
};

export default NewRentalApplicationPage;
