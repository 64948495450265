import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {Http} from '@capacitor-community/http';
import {Capacitor} from '@capacitor/core';
import {Filesystem, Directory} from '@capacitor/filesystem';

import {API_URL, TARGET_ENV} from 'globals/app-globals';
import PresignResponse from 'helpers/PresignResponse';
import InspectionAction from 'models/inspections/InspectionAction';
import InspectionItemAttachment from 'models/inspections/InspectionItemAttachment';
import User from 'models/users/User';
import useInspectionStore, {
  InspectionEventEmitter,
  PendingUploadRow,
} from 'stores/InspectionStore';

import useAuth from '../services/useAuth';

interface ProcessQueueFunction {
  (inspectionId: string): Promise<void>;
}

interface ContextValue {
  isRunning: boolean;
  processQueue: ProcessQueueFunction;
}

const Context = createContext<ContextValue>({} as ContextValue);

interface InspectionUploaderProvider {
  (props: {children: ReactNode}): JSX.Element;
}

export const InspectionUploaderProvider: InspectionUploaderProvider = ({
  children,
}) => {
  const [isRunning, setIsRunning] = useState(false);

  const {currentUser} = useAuth();

  const uploadAttachment = useCallback(
    async (item: PendingUploadRow) => {
      const filename = item.data.split('/').pop();
      const extension = filename.split('.').pop().toLowerCase();

      let mime;
      if (extension === '.jpg' || extension === '.jpeg') {
        mime = 'image/jpeg';
      } else if (extension === '.png') {
        mime = 'image/png';
      } else if (extension === '.mp4') {
        mime = 'video/mp4';
      } else if (extension === '.mov') {
        mime = 'video/mov';
      }

      const directory =
        Capacitor.getPlatform() === 'android'
          ? Directory.Data
          : Directory.Documents;

      const stats = await Filesystem.stat({
        path: filename,
        directory: directory,
      });

      let uploadData: any;

      if (TARGET_ENV === 'development') {
        const response = await Http.uploadFile({
          url: `${API_URL}/uploads/inspection_item_attachment.json`,
          name: 'file',
          filePath: filename,
          fileDirectory: directory,
          params: {
            inspection_item_id: item.inspectionItemId,
          },
          headers: {
            'X-USER-TOKEN': currentUser.meta.authenticationToken,
            'X-USER-EMAIL': currentUser?.email,
          },
        });

        uploadData = response.data;
      } else {
        const presignResponse = await fetch(
          `${API_URL}/presigns/inspection_item_attachment.json?inspection_item_id=${item.inspectionItemId}&filename=${filename}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'X-USER-TOKEN': currentUser.meta.authenticationToken,
              'X-USER-EMAIL': currentUser?.email,
            },
          },
        );
        const presignInfo = (await presignResponse.json()) as PresignResponse;
        if (presignInfo) {
          await Http.uploadFile({
            url: presignInfo.url,
            method: presignInfo.method,
            name: 'file',
            filePath: filename,
            fileDirectory: directory,
            headers: presignInfo.headers as any,
          });

          const url = new URL(presignInfo.url);
          const id = url.pathname.split('/').pop();
          uploadData = {
            id,
            storage: 'cache',
            metadata: {
              size: stats.size,
              filename: filename,
              mime_type: mime,
            },
          };
        }
      }

      if (uploadData) {
        const attach = new InspectionItemAttachment({
          attachment: JSON.stringify(uploadData),
          inspectionItemId: item.inspectionItemId,
        });
        if (item.notes) {
          attach.notes = item.notes;
        }

        await attach.save();

        attach.user = new User({
          id: currentUser.id,
          name: currentUser.name,
          avatar: currentUser.avatar,
          email: currentUser.email,
        });
        attach.user.isPersisted = true;
        attach.userId = currentUser.id;

        return attach;
      } else {
        return null;
      }
    },
    [currentUser],
  );

  const uploadAction = useCallback(
    async (item: PendingUploadRow) => {
      const filename = item.data.split('/').pop();
      const extension = filename.split('.').pop().toLowerCase();

      let mime;
      if (extension === '.jpg' || extension === '.jpeg') {
        mime = 'image/jpeg';
      } else if (extension === '.png') {
        mime = 'image/png';
      } else if (extension === '.mp4') {
        mime = 'video/mp4';
      } else if (extension === '.mov') {
        mime = 'video/mov';
      }

      const directory =
        Capacitor.getPlatform() === 'android'
          ? Directory.Data
          : Directory.Documents;

      const stats = await Filesystem.stat({
        path: filename,
        directory: directory,
      });

      let uploadData: any;

      if (TARGET_ENV === 'development') {
        const response = await Http.uploadFile({
          url: `${API_URL}/uploads/inspection_action.json`,
          name: 'file',
          filePath: filename,
          fileDirectory: directory,
          params: {
            inspection_item_id: item.inspectionItemId,
          },
          headers: {
            'X-USER-TOKEN': currentUser.meta.authenticationToken,
            'X-USER-EMAIL': currentUser?.email,
          },
        });

        uploadData = response.data;
      } else {
        const presignResponse = await fetch(
          `${API_URL}/presigns/inspection_action.json?inspection_item_id=${item.inspectionItemId}&filename=${filename}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'X-USER-TOKEN': currentUser.meta.authenticationToken,
              'X-USER-EMAIL': currentUser?.email,
            },
          },
        );
        const presignInfo = (await presignResponse.json()) as PresignResponse;
        if (presignInfo) {
          await Http.uploadFile({
            url: presignInfo.url,
            method: presignInfo.method,
            name: 'file',
            filePath: filename,
            fileDirectory: directory,
            headers: presignInfo.headers as any,
          });

          const url = new URL(presignInfo.url);
          const id = url.pathname.split('/').pop();
          uploadData = {
            id,
            storage: 'cache',
            metadata: {
              size: stats.size,
              filename: filename,
              mime_type: mime,
            },
          };
        }
      }

      if (uploadData) {
        const action = new InspectionAction({
          attachment: JSON.stringify(uploadData),
          inspectionItemId: item.inspectionItemId,
          propertyId: item.actionParams.propertyId,
          action: item.actionParams.action,
          actionType: item.actionParams.actionType,
          shouldCreateMaintenanceRequest:
            item.actionParams.shouldCreateMaintenanceRequest,
        });

        await action.save();

        action.user = new User({
          id: currentUser.id,
          name: currentUser.name,
          avatar: currentUser.avatar,
          email: currentUser.email,
        });
        action.user.isPersisted = true;
        action.userId = currentUser.id;

        return action;
      } else {
        return null;
      }
    },
    [currentUser],
  );

  const processQueue = useCallback(
    async (inspectionId: string) => {
      setIsRunning(true);

      const inspectionStore = useInspectionStore.getState();
      const db = inspectionStore.database;

      await db.createIndex({
        index: {
          fields: ['inspectionId'],
        },
      });

      const records = await db.find({
        selector: {
          inspectionId,
        },
      });

      for (const record of records.docs) {
        const item = record as any as PendingUploadRow;

        if (
          item.attachmentType === 'normal' ||
          item.attachmentType === 'note'
        ) {
          const attachment = await uploadAttachment(item);
          if (attachment) {
            // Update the inspection items store so that it re-renders the UI for rooms etc
            const inspectionItems =
              useInspectionStore.getState().inspectionItems;
            const setInspectionItems =
              useInspectionStore.getState().setInspectionItems;

            const foundItem = inspectionItems?.find(
              (i) => i.id === item.inspectionItemId,
            );
            if (item) {
              foundItem.inspectionItemAttachments.push(attachment);

              setInspectionItems(inspectionItems);
            }

            db.remove(record);
          }
        } else if (item.attachmentType === 'action') {
          const action = await uploadAction(item);
          if (action) {
            // Update the inspection items store so that it re-renders the UI for rooms etc
            const inspectionItems =
              useInspectionStore.getState().inspectionItems;
            const setInspectionItems =
              useInspectionStore.getState().setInspectionItems;

            const foundItem = inspectionItems?.find(
              (i) => i.id === item.inspectionItemId,
            );
            if (action) {
              foundItem.inspectionActions.push(action);

              setInspectionItems(inspectionItems);
            }

            db.remove(record);
          }
        }

        console.log('UPLOADED ITEM');
        InspectionEventEmitter.emit('INSPECTION_UPDATED', {
          inspectionId: inspectionId,
        });
      }

      setIsRunning(false);
    },
    [uploadAction, uploadAttachment],
  );

  const value = useMemo(
    () => ({
      isRunning,
      processQueue,
    }),
    [isRunning, processQueue],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useInspectionUploader = () => {
  return useContext(Context);
};
