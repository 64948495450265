import {Capacitor} from '@capacitor/core';
import {useQuery} from 'react-query';
import {useParams} from 'react-router';

import LoadingView from 'components/common/LoadingView';
import {HeaderButton} from 'components/navbar/Header';
import PageWrapper from 'components/PageWrapper';
import RentalApplicationDetailView from 'components/rental_application/RentalApplicationDetailView';
import RentalApplication from 'models/listings/RentalApplication';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';

const RentalApplicationDetailPage = () => {
  usePageVisit('RentalApplicationDetailPage');
  const {id} = useParams();

  const {data, isLoading, error} = useQuery(
    `rental-application-${id}`,
    async () => {
      const app = await RentalApplication.includes([
        'rental_application_applicants',
        'user',
      ])
        .order({'rental_applications.id': 'asc'})
        .find(id);

      return app.data;
    },
  );

  const buttons: HeaderButton[] = Capacitor.isNativePlatform()
    ? [{text: 'Edit', href: 'edit'}]
    : [{text: 'Edit Application', href: 'edit'}];

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Rental Application" buttons={buttons}>
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Rental Application" buttons={buttons}>
        <RentalApplicationDetailView rentalApplication={data} />
      </PageWrapper>
    );
  }
};

export default RentalApplicationDetailPage;
