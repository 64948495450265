import React, {useState} from 'react';

import {Form, Formik, type FormikProps, type FormikHelpers} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import {ResizingImageField} from 'components/forms_fields';
import PageWrapper from 'components/PageWrapper';
import UserAvatar from 'components/user/UserAvatar';
import {Button} from 'components_sb/buttons';
import User from 'models/users/User';
import useAuth from 'services/useAuth';
import {errorViewForError} from 'utilities/ErrorHelpers';

type AvatarFormValues = {
  avatar: string;
};

const SettingsAvatarPage = () => {
  const [avatarUploading, setAvatarUploading] = useState(false);

  const {currentUser, setAvatar} = useAuth();

  const {isLoading, error, data} = useQuery('user-avatar-page', async () => {
    const user = await User.find(currentUser.id);

    return user.data;
  });

  const handleSubmit = async (
    formValues: AvatarFormValues,
    actions: FormikHelpers<AvatarFormValues>,
  ) => {
    const user = data;
    setAvatar(formValues.avatar);
    actions.setSubmitting(false);
  };

  const previewUser = (formik: FormikProps<AvatarFormValues>) => {
    if (formik.values.avatar && formik.values.avatar.length > 0) {
      return new User({name: currentUser.name, avatar: formik.values.avatar});
    } else {
      return currentUser;
    }
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Avatar">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Avatar">
        <Card className="mt-2">
          <CardBody>
            <CardTitle>Change your avatar</CardTitle>

            <Formik
              initialValues={{avatar: ''}}
              onSubmit={handleSubmit}
              validationSchema={Yup.object().shape({
                avatar: Yup.string().required().label('Avatar').min(1),
              })}>
              {(formik) => (
                <Form className="mt-2">
                  <div className="flex justify-center">
                    <UserAvatar user={previewUser(formik)} size="20" />
                  </div>

                  <div className="mt-4">
                    <ResizingImageField
                      formik={formik}
                      label="Choose an image"
                      name="avatar"
                      width={500}
                      height={500}
                    />
                  </div>

                  <div className="mt-4">
                    <Button
                      label="Save avatar"
                      mode="formik"
                      category="primary"
                      size="base"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </PageWrapper>
    );
  }
};

export default SettingsAvatarPage;
