import {ChangeEvent, FocusEvent} from 'react';

import clsx from 'clsx';
import {type FormikErrors, useField} from 'formik';

import {type FieldError, InlineError} from 'components_sb/feedback';

/**
 * Tailwind class configuration
 */
const classes = {
  // Classes for all permutations
  base: {
    input: clsx(
      'bg-brand-50 transition-all duration-300',
      'placeholder-brand-800 placeholder-opacity-50', // Placeholder
      'outline-none border-none ring-0', // Untouched
      'focus:ring-2 focus:ring-brand-500', // Focus
      'hover:bg-brand-75', // Hover
    ),
  },

  // TODO: Classes for disabled state
  // disabled: clsx(
  //   // Disable interaction
  //   'pointer-events-none',
  //   // Opacity
  //   'opacity-50',
  // ),

  // Classes for error state
  error: {
    // These classes are set as important to override default hover/focus states
    input: clsx('!ring-2 !ring-error'),
  },
  // Classes based on size
  size: {
    label: {
      base: clsx(
        // Text
        'text-base',
      ),
      lg: clsx(
        // Text
        'text-base',
      ),
    },
    input: {
      base: clsx(
        // Text
        'text-base',
        // Padding
        'px-4',
        // Height
        'h-12',
        // Roundness
        'rounded-lg',
      ),
      lg: clsx(
        // Text
        'text-base',
        // Padding
        'px-6',
        // Height
        'h-14',
        // Roundness
        'rounded-xl',
      ),
    },
  },
};
interface BaseProps {
  /**
   *  The unique name for the input field.
   */
  name: string;
  /**
   *  The text shown above the input field.
   */
  label: string;
  /**
   *  Supporting text to help desribe the purpose or requirement of the field.
   */
  description?: string;
  /**
   *  The strategy for handling field events and states.
   */
  mode: 'manual' | 'formik';
  /**
   *  The size of the input field.
   */
  size?: 'base' | 'lg';
  /**
   *  The type of text input allowed for the input field.
   */
  type?: 'text' | 'email' | 'password';
  /**
   *  The text shown above the input field.
   */
  placeholder?: string;
  /**
   *  Whether a value is required for the input field.
   */
  required?: boolean;
}

type ConditionalModeProps =
  /**
   * Types when mode is 'manual'
   */
  | {
      mode: 'manual';
      value: string | number;
      onBlur?: () => void;
      onChange: () => void;
      error?: FieldError;
      onClick: () => void;
    }
  /**
   * Types when mode is 'formik'
   */
  | {
      mode: 'formik';
      value?: never;
      onBlur?: never;
      onChange?: never;
      error?: never;
      onClick?: never;
    };

/**
 * Conditional types combined with the base types
 */
type TextFieldProps = BaseProps & ConditionalModeProps;

/**
 * Properties that wrapper components must provide to the base component.
 */
interface WrapperOutput {
  value: string;
  onBlur: (event: FocusEvent<any, Element>) => void;
  onChange: (event: ChangeEvent<any>) => void;
  error?: FieldError;
}

/**
 * The minimum properties that wrapper components must receive from
 * the base component.
 */
interface BaseWrapperInput {
  children: (props: WrapperOutput) => JSX.Element;
}

/**
 * A component that wraps the based component to provide functionality
 * based on particular configuration.
 */
interface WrapperComponent {
  (props: BaseWrapperInput): JSX.Element;
}

/**
 * Wraps the base component to enable manual control.
 */
const ManualWrapper = ({
  children,
  ...props
}: BaseWrapperInput & WrapperOutput) => {
  /**
   * For the manual wrapper, the input must include the base properties
   * but also include the expected output for direct passing
   */
  return children({...props});
};

/**
 * Wraps the base component to enable support for
 * integration with the current Formik context.
 */
const FormikWrapper = ({
  name,
  children,
  ...props
}: BaseWrapperInput & {name: string}) => {
  const [fieldProps, meta] = useField({name});
  const {value, onBlur, onChange} = fieldProps;
  const {error} = meta;
  return children({
    value,
    onBlur,
    onChange,
    error,
    ...props,
  });
};

/**
 * Primary UI component for text-based input.
 */
const TextField = (props: TextFieldProps) => {
  // These properties are consistent across all wrapper types
  const {
    name,
    label,
    description = null,
    mode,
    size = 'base',
    type = 'text',
    placeholder = '',
    required = false,
  } = props;

  /**
   * Select the wrapper based on the mode
   */
  const Wrapper: WrapperComponent = {
    manual: ManualWrapper,
    formik: FormikWrapper,
  }[mode];

  return (
    <Wrapper {...props}>
      {({value, onBlur, onChange, error}: WrapperOutput) => (
        <div className="flex-1 w-full flex flex-col gap-y-2">
          <label
            htmlFor={name}
            className={clsx(
              classes.size.label[size],
              'whitespace-nowrap font-medium text-brand-900',
            )}>
            <span>
              {description ? (
                <>
                  <span>{`${label} `}</span>
                  <span className="text-xs">{description}</span>
                </>
              ) : (
                label
              )}
            </span>
            {required && <span className="ml-1 text-red-500">*</span>}
          </label>
          <input
            name={name}
            type={type}
            placeholder={placeholder}
            required={required}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            className={clsx(
              classes.base.input,
              classes.size.input[size],
              !!error && classes.error.input,
            )}
          />
          {/* If the error is a boolean, a message does not need to be shown */}
          <InlineError error={typeof error === 'string' ? error : null} />
        </div>
      )}
    </Wrapper>
  );
};

export default TextField;
