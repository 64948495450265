import React, {useState} from 'react';

import {PhotographIcon} from '@heroicons/react/outline';
import {Form, Formik, FormikHelpers} from 'formik';
import {useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {ResizingImageField, SubmitButton} from 'components/forms_fields';
import ActionCard from 'components/property/landlord/profile_blocks/ActionCard';
import ProfileItemModal from 'components/property/landlord/profile_blocks/ProfileItemModal';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';

const PropertyImageAction = ({
  property,
  tenancy,
  storeKey,
}: {
  property: Property;
  tenancy: Tenancy;
  storeKey: string;
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = async (formValues: any, actions: FormikHelpers<any>) => {
    const prop = property;

    prop.assignAttributes(formValues);
    prop.markStepAsCompleted(storeKey);

    const result = await prop.save();

    if (result) {
      queryClient.setQueryData(`landlord-property-detail-${prop.id}`, prop);
      toast.success('Property successfully updated!');
      setModalVisible(false);
    } else {
      for (const key of Object.keys(prop.errors)) {
        const message = prop.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  const dismissAction = async () => {
    const prop = property;

    prop.markStepAsCompleted(storeKey);

    const result = await prop.save();

    if (result) {
      queryClient.setQueryData(`landlord-property-detail-${prop.id}`, prop);
    } else {
    }
  };

  const title = 'Property Image';
  const subtitle = 'Upload an image of your property.';
  const iconBgColor = 'bg-indigo-300';

  return (
    <div>
      <ActionCard
        title={title}
        subtitle={subtitle}
        IconClass={PhotographIcon}
        iconBgColor={iconBgColor}
        dismissable
        onClick={() => setModalVisible(true)}
        onDismiss={dismissAction}
      />
      <ProfileItemModal
        modalIsOpen={modalVisible}
        setModalIsOpen={setModalVisible}
        title={title}
        subtitle={subtitle}
        IconClass={PhotographIcon}
        bgColor={iconBgColor}
        form={
          <Formik
            initialValues={{
              mainImage: property.mainImage || '',
            }}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              mainImage: Yup.string().min(1).label('Main Image'),
            })}>
            {(formik) => (
              <Form className="md:h-full flex-1">
                <div className="flex flex-col justify-between h-full">
                  <div className="flex flex-col justify-center grow space-y-2">
                    {formik.values.mainImage.length > 0 && (
                      <div>
                        <img
                          className="w-full h-auto"
                          src={formik.values.mainImage}
                        />
                      </div>
                    )}
                    <ResizingImageField
                      formik={formik}
                      name="mainImage"
                      label="Choose an Image"
                      accept=".png,.jpeg,.jpg"
                      width={1000}
                      height={600}
                    />
                  </div>

                  <SubmitButton
                    formik={formik}
                    text="Save"
                    submittingText="Saving"
                    className={`!rounded-full ${iconBgColor} border-none mt-6`}
                  />
                </div>
              </Form>
            )}
          </Formik>
        }
      />
    </div>
  );
};

export default PropertyImageAction;
