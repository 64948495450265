import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import TenancySelectField from 'components/tenancy/TenancySelectField';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';

const TenancySelectCard = ({
  property,
  tenancy,
  setSelectedTenancyId,
}: {
  property: Property;
  tenancy?: Tenancy;
  setSelectedTenancyId: (id: string) => void;
}) => {
  if (
    property &&
    (property.tenanciesCount > 1 || property.nextTenancyActionType)
  ) {
    return (
      <Card className="mt-4">
        <CardBody>
          <CardTitle>Choose a Tenancy</CardTitle>
          <p>
            Use the select field below to change between tenancies for this
            property.
          </p>

          <TenancySelectField
            property={property}
            tenancies={property.tenancies}
            selectedTenancy={tenancy}
            setSelectedTenancyId={setSelectedTenancyId}
          />
        </CardBody>
      </Card>
    );
  }

  return null;
};

export default TenancySelectCard;
