import {useState} from 'react';

import {Clause} from 'models/properties/ClauseLibrary';
import {truncate} from 'utilities/StringHelpers';

const LeaseClause = ({
  title,
  clause,
  icon,
  onEdit,
  onDelete,
}: {
  title: string;
  clause: string;
  icon?: any;
  onEdit?: () => void;
  onDelete?: () => void;
}) => {
  const [shouldTruncate, setShouldTruncate] = useState(true);

  const toggleTruncate = () => setShouldTruncate((t) => !t);

  return (
    <div className="flex flex-1 items-center py-3 pr-2">
      {icon && <img src={icon} className="w-8 h-8 text-gray-200 mr-2" />}
      <label>
        <h4 className="flex justify-between items-center">
          <span>{title}</span>
          <div className="flex">
            {onEdit && (
              <a
                className="link link-secondary inline-block ml-2"
                onClick={onEdit}>
                Edit
              </a>
            )}
            {onDelete && (
              <a
                className="link link-secondary inline-block ml-2"
                onClick={onDelete}>
                Delete
              </a>
            )}
          </div>
        </h4>
        <p
          className="text-sm text-base-content text-opacity-60"
          onClick={toggleTruncate}>
          {shouldTruncate ? truncate(clause, 110) : clause}
        </p>
      </label>
    </div>
  );
};

export default LeaseClause;
