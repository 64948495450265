import {useCallback, useEffect, useMemo, useState} from 'react';

import * as Sentry from '@sentry/react';
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';
import {current} from 'tailwindcss/colors';
import {useLocalStorage} from 'usehooks-ts';

import {API_URL} from 'globals/app-globals';
import User, {UserInterfaceFlags} from 'models/users/User';
import {useSpraypaintMiddleware} from 'providers/SpraypaintMiddleware';
import {SocialAuthProviderType} from 'utilities/auth/SocialAuth';

import TrackingService from './TrackingService';

const CURRENT_USER_KEY = 'current_user';

export type AccountRole = 'Landlord' | 'Renter' | 'ServicePerson';

/**
 * The format that the locally stored current user data must match.
 */
export interface CurrentUser {
  id: string;
  email: string;
  name: string;
  avatar: string;
  roles: Array<AccountRole>;
  isConfirmed: boolean;
  timestamp: number;
  meta: CurrentUserMeta;
  userInterfaceFlags: UserInterfaceFlags;
}

interface CurrentUserMeta {
  authenticationToken: string;
  timestamp: number;
  isPaid: boolean;
}

export interface RegisterFunction {
  (provider: 'email' | SocialAuthProviderType, registrationData: any): any;
}

export interface LogInFunction {
  (provider: 'email' | SocialAuthProviderType, loginData: any): any;
}

/**
 * Due to differences between the identifiers in the backend
 * and the frontend, we must map these values when performing
 * requests to the API
 */
export const accountTypeMapping = {
  landlord: 'Landlord',
  tenant: 'Renter',
};

/**
 * A type guard to ensure that the locally stored data for the user
 * is still of a valid format, and that the required object shape has
 * not changed since it was last stored.
 */
const userDataIsValid = (userData: unknown): userData is CurrentUser => {
  if (typeof userData !== 'object') {
    return false;
  }

  /**
   * Ensure the expected propertes exist as per the type definition above.
   */
  return (
    'id' in userData &&
    'email' in userData &&
    'name' in userData &&
    'avatar' in userData &&
    'roles' in userData &&
    'isConfirmed' in userData &&
    'timestamp' in userData &&
    'meta' in userData &&
    'userInterfaceFlags' in userData
  );
};

/**
 * A hook for accessing authentication related functionality and
 * details for the current user.
 */
const useAuth = () => {
  const [currentUser, setCurrentUser] = useLocalStorage<CurrentUser>(
    CURRENT_USER_KEY,
    null,
  );

  const navigate = useNavigate();

  /**
   * A user is still considered logged in if there is current user data,
   * regardless of whether a refetch is required.
   */
  const userIsLoggedIn = useMemo(() => !!currentUser, [currentUser]);

  /**
   * A refetch of the user data is required if the currently set data in
   * local storage does not match the correct object shape.
   */
  // TODO: Refetch and set data if required using below state
  const [needsRefetch, setNeedsRefetch] = useState(
    userIsLoggedIn && !userDataIsValid(currentUser),
  );

  /**
   * Update user tracking when details change on the current user object.
   */
  useEffect(() => {
    if (userIsLoggedIn) {
      TrackingService.setUserId(currentUser.id);
      TrackingService.setUserDetails({
        $name: currentUser.name,
        $email: currentUser.email,
        Roles: currentUser.roles,
      });
    } else {
      TrackingService.clearUser();
    }
  }, [userIsLoggedIn, currentUser]);

  /**
   * Logs a user in.
   */
  const logInUser: LogInFunction = useCallback(
    async (provider, loginData: any) => {
      /**
       * Check if there is already a user logged in.
       */
      if (currentUser) {
        throw new Error('There is already a user logged in.');
      }

      /**
       * Attempt to log the user in.
       */
      const response = await fetch(
        API_URL +
          (provider === 'email'
            ? '/users/login.json'
            : `/identities/${provider}.json`),
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(loginData),
        },
      );

      if (response.ok) {
        const data = await response.json();
        const userData = data.user as CurrentUser;
        userData.meta = data.meta;

        setCurrentUser(userData);

        TrackingService.trackEvent(TrackingService.Event.Login, {
          provider: 'email',
          userId: userData.id,
          userType: userData.roles.length > 0 ? userData.roles[0] : null,
        });

        toast.success(`You have been successfully logged in!`);
      } else {
        const error = await response.json();
        throw new Error(error.error);
      }
    },
    [currentUser, setCurrentUser],
  );

  /**
   * Logs out the current user.
   */
  const logOutUser = useCallback(async () => {
    /**
     * Check if there is a current user to log out.
     */
    if (!currentUser) {
      throw new Error('There is no user to log out.');
    }

    /**
     * Attempt to log the user out.
     */
    try {
      await fetch(API_URL + '/users/logout.json', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-USER-TOKEN': currentUser.meta.authenticationToken,
          'X-USER-EMAIL': currentUser.email,
        },
      });
    } catch (error) {
      /**
       * Error logging out the user.
       */
      Sentry.captureException(error);
      throw error;
    }

    /**
     * Log the user out of the Customerly integration.
     */
    const {customerly} = window as any;
    if (customerly && customerly.logout) {
      customerly.logout();
    }
    /**
     * Clear the current user data and any settings from local storage.
     */
    localStorage.clear();

    /**
     * Redirect the user to the login page.
     */
    navigate('/login');
  }, [currentUser, navigate]);

  /**
   * [DANGEROUS] Deletes the user's entire account.
   */
  const deleteUser = useCallback(async () => {
    /**
     * Check if there is a current user to log out.
     */
    if (!currentUser) {
      throw new Error('There is no user to delete.');
    }

    /**
     * Attempt to delete the user.
     */
    try {
      const user = new User({id: currentUser.id});
      user.isPersisted = true;
      const result = await user.destroy();
      if (!result) {
        throw new Error(
          `Error deleting account for user with ID: ${currentUser.id}`,
        );
      }
    } catch (error) {
      /**
       * Error deleting the user.
       */
      Sentry.captureException(error);
      throw error;
    }

    toast.success('Your account has been successfully deleted!');

    /**
     * Log the user out of the Customerly integration.
     */
    const {customerly} = window as any;
    if (customerly && customerly.logout) {
      customerly.logout();
    }
    /**
     * Clear the current user data and any settings from local storage.
     */
    localStorage.clear();

    /**
     * Redirect the user to the login page.
     */
    navigate('/login');
  }, [currentUser, navigate]);

  /**
   * Create a new user account.
   */
  const registerUser: RegisterFunction = useCallback(
    async (provider, registrationData) => {
      /**
       * Check if there is already a user logged in.
       */
      if (currentUser) {
        throw new Error('There is already a user logged in.');
      }

      /**
       * Attempt to register the user.
       */
      const response = await fetch(
        API_URL +
          (provider === 'email'
            ? '/users.json'
            : `/identities/${provider}.json`),
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(registrationData),
        },
      );

      if (response.ok) {
        const data = await response.json();
        const userData = data.user as CurrentUser;
        userData.meta = data.meta;
        setCurrentUser(userData);

        // Track the event of the registration of the account itself
        TrackingService.trackEvent(TrackingService.Event.Register, {
          provider: 'email',
        });

        // Track the event for the creation of the particular profile type
        if (registrationData.account_type === 'Landlord') {
          TrackingService.trackEvent(
            TrackingService.Event.CreateLandlordProfile,
          );
        } else if (registrationData.account_type === 'Renter') {
          TrackingService.trackEvent(TrackingService.Event.CreateTenantProfile);
        }

        toast.success(`Your account has been successfully registered!`);

        return {responseObject: userData, status: response.status};
      } else {
        const errors = await response.json();
        return {responseObject: errors, status: response.status};
      }
    },
    [currentUser, setCurrentUser],
  );

  /**
   * Update details for an existing user account.
   */
  const updateUser = useCallback(
    async (userInfo: any) => {
      /**
       * Check if the user to update is logged in.
       */
      if (!currentUser) {
        throw new Error('There is no user logged in.');
      }

      /**
       * Attempt to update the user.
       */
      const response = await fetch(API_URL + `/users.json`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-USER-TOKEN': currentUser.meta.authenticationToken,
          'X-USER-EMAIL': currentUser.email,
        },
        body: JSON.stringify({user: userInfo}),
      });

      if (response.ok) {
        if ([200, 204].includes(response.status)) {
          toast.success('Your account has been successfully updated!');
          return {responseObject: {}, status: response.status};
        } else {
          const data = await response.json();
          const userData = data.user as CurrentUser;
          userData.meta = data.meta;
          setCurrentUser(userData);
          return {responseObject: userData, status: response.status};
        }
      } else {
        const errors = await response.json();
        return {responseObject: errors, status: response.status};
      }
    },
    [currentUser, setCurrentUser],
  );

  /**
   * Sends a password reset email to the user.
   */
  const forgotPass = useCallback(
    async (forgotPassFields: any) => {
      const response = await fetch(API_URL + '/users/password.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({user: forgotPassFields}),
      });
      const data = await response.json();

      if ([200, 201].includes(response.status)) {
        toast.success(
          `An email has been sent to ${forgotPassFields.email} with a link to reset your password!`,
        );
        TrackingService.trackEvent(TrackingService.Event.ForgotPassword);
        navigate('/login');
      }

      return {responseObject: data, status: response.status};
    },
    [navigate],
  );

  /**
   * Sets a new password for the user.
   */
  const resetPass = useCallback(
    async (resetPassFields: any) => {
      const response = await fetch(API_URL + '/users/password.json', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({user: resetPassFields}),
      });
      try {
        const data = await response.json();
        toast.success(
          'Your password has been successfully updated! Please log in with your new password.',
        );
        navigate('/login');
      } catch (e) {
        return {responseObject: null, status: response.status};
      }
    },
    [navigate],
  );

  /**
   * Validates whether a provided password reset token is still valid
   * and has not expired or been overwritten by a subsequent password reset.
   * @param token The password reset token included in the email to the user.
   */
  const validatePasswordResetToken = useCallback(async (token: string) => {
    // TODO: Enable below once token validity endpoint has been created
    // ========================================================================
    // const response = await fetch(API_URL + '/users/password/token.json', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({token: token}),
    // });
    // try {
    //   const data = await response.json();
    //   return !data.expired;
    //   return true;
    // } catch (error) {
    //   // TODO: Log error in Sentry
    //   return false;
    // }
    // ========================================================================
    return true;
  }, []);

  /**
   * Sets a user's email address as confirmed.
   */
  const confirmEmail = useCallback(async (token: any) => {
    const response = await fetch(
      API_URL + '/users/confirmation.json?confirmation_token=' + token,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    try {
      const data = await response.json();
      return {responseObject: data, status: response.status};
    } catch (e) {
      return {responseObject: null, status: response.status};
    }
  }, []);

  /**
   * Set the user's active subcription status.
   * TODO: This is currently a local-only update and should be refactored so
   * that the subscription update is performed here, and the current user is
   * then set using the customer data within the response of the update.
   */
  const setUserIsPaid = useCallback(
    async (isPaid: boolean) => {
      /**
       * Check if the user to update is logged in.
       */
      if (!currentUser) {
        throw new Error('There is no user logged in.');
      }

      const newUserData = {
        ...currentUser,
      };
      newUserData.meta.isPaid = isPaid;
      setCurrentUser(newUserData);
    },
    [currentUser, setCurrentUser],
  );

  /**
   * Sets the user's avatar.
   * TODO: This is currently a local-only update and should be refactored so
   * that the avatar update on the user is performed here, and the current user is
   * then set using the customer data within the response of the update.
   */
  const setAvatar = useCallback(
    async (avatar: string) => {
      /**
       * Check if the user to update is logged in.
       */
      if (!currentUser) {
        throw new Error('There is no user logged in.');
      }

      const newUserData = {
        ...currentUser,
        avatar,
      };

      setCurrentUser(newUserData);

      toast.success('Your profile picture has been successfully updated!');
    },
    [currentUser, setCurrentUser],
  );

  /**
   * Sets the user's email confirmation status.
   * TODO: This is currently a local-only update and should be refactored so
   * that the email confirmation update on the user is performed here, and the
   * current user is then set using the customer data within the response of
   * the update.
   */
  const setEmailConfirmed = useCallback(async () => {
    /**
     * Check if the user to update is logged in.
     */
    if (!currentUser) {
      throw new Error('There is no user logged in.');
    }

    const newUserData = {
      ...currentUser,
      isConfirmed: true,
    };

    setCurrentUser(newUserData);
    TrackingService.trackEvent(TrackingService.Event.VerifyEmail);
    toast.success('Your email address has been successfully confirmed!');
    navigate('/');
  }, [currentUser, setCurrentUser, navigate]);

  /**
   * Enables admins to log in as an existing user.
   */
  const ghostUser = useCallback(
    async ({
      data,
      token,
      redirect,
    }: {
      data: any;
      token: string;
      redirect?: string;
    }) => {
      const user = {
        id: data.id,
        name: data.name,
        email: data.email,
        avatar: data.avatar,
        roles: data.roles,
        timestamp: Date.now() / 1000,
        isConfirmed: data.confirmed,
        meta: {
          authenticationToken: token,
          timestamp: Date.now() / 1000,
          isPaid: true,
        },
      } as any;

      setCurrentUser(user);

      /**
       * Identify that the session is for ghosting a user, this is so we can prevent
       * performing some tracking / events if ghosting and to display a warning.
       */
      localStorage.setItem('ghosting', 'true');

      if (redirect) {
        navigate(redirect);
      } else {
        navigate('/');
      }
    },
    [navigate, setCurrentUser],
  );

  /**
   * Return the data and functions provided by the hook.
   */
  return {
    currentUser,
    userIsLoggedIn,
    ghostUser,
    logInUser,
    logOutUser,
    deleteUser,
    registerUser,
    updateUser,
    forgotPass,
    resetPass,
    validatePasswordResetToken,
    confirmEmail,
    setUserIsPaid,
    setAvatar,
    setEmailConfirmed,
  };
};

export default useAuth;
