import {AiOutlineInfoCircle} from '@react-icons/all-files/ai/AiOutlineInfoCircle';
import {useNavigate} from 'react-router';

import Property from 'models/properties/Property';

const ListingWalkthroughCard = ({property}: {property: Property}) => {
  const navigate = useNavigate();

  const resumeListing = () => {
    const newAddress = {address1: property.streetAddress};
    localStorage.setItem('new-listing-address', JSON.stringify(newAddress));
    navigate('/listings/new');
  };

  if (property && property.listings.length > 0) {
    const listing = property.listings[0];

    if (listing.status === 'draft') {
      return (
        <div
          className="alert alert-info shadow-lg bg-[#232A59] text-brand-25 cursor-pointer"
          onClick={resumeListing}>
          <div className="flex justify-between items-center w-full">
            <div className="flex justify-start gap-2">
              <AiOutlineInfoCircle className="w-6 h-6" />
              <span>Click here to resume setting up your listing.</span>
            </div>
            <button
              className="btn btn-xs bg-white text-black"
              onClick={resumeListing}>
              Continue
            </button>
          </div>
        </div>
      );
    }
  }

  return null;
};

export default ListingWalkthroughCard;
