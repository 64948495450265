import React, {HTMLProps, useState} from 'react';

import {type FormikProps} from 'formik';
import _ from 'lodash';
import Select from 'react-select';

import {InlineError} from 'components_sb/feedback';
import {removeUnderscores, titleize} from 'utilities/StringHelpers';

interface PetTypeFieldProps extends HTMLProps<HTMLSelectElement> {
  formik: FormikProps<any>;
}

interface Option {
  readonly label: string;
  readonly value: string;
}

const PetTypeField = ({formik, ...rest}: PetTypeFieldProps) => {
  const name = 'allowedPetTypes';

  const [petTypes, setPetTypes] = useState(
    _.union(formik.values[name], [
      'cat',
      'small_dog',
      'large_dog',
      'caged_bird',
    ]),
  );
  const [isEditing, setIsEditing] = useState(false);
  const [customText, setCustomText] = useState('');

  const options = petTypes.map((type) => {
    return {label: titleize(removeUnderscores(type)), value: type};
  });

  const defaultValue = (formik.values[name] as string[]).map((str) => {
    return {label: titleize(removeUnderscores(str)), value: str};
  });

  const classN = formik.errors[name] ? ' border border-red-500' : '';
  if (rest.className) {
    rest.className += classN;
  } else {
    rest.className = classN;
  }

  rest.className += ' w-full';

  const saveCustom = () => {
    if (customText.length > 0) {
      const formatted = customText.toLowerCase().replaceAll(' ', '_');
      formik.setFieldValue(name, [...formik.values[name], formatted]);
      setPetTypes([...petTypes, formatted]);
    }
    setIsEditing(false);
  };

  return (
    <div>
      <div className="flex justify-start">
        <Select
          isMulti={true}
          className={rest.className}
          options={options}
          defaultValue={defaultValue}
          value={defaultValue}
          onChange={(vals: Option[]) => {
            formik.setFieldValue(
              name,
              vals.map((val) => val.value),
            );
          }}
          placeholder="Select what pet types are allowed"
        />
        {!isEditing && (
          <button
            type="button"
            className="btn btn-neutral btn-sm h-[38px] ml-2"
            onClick={() => setIsEditing(true)}>
            Add Custom
          </button>
        )}
      </div>

      {isEditing && (
        <div className="mt-2 form-control">
          <div className="input-group">
            <input
              type="text"
              className="input input-bordered w-full"
              placeholder="Add a pet type or something more specific"
              onChange={(e) => setCustomText(e.target.value)}
            />

            <button
              type="button"
              className="btn btn-success"
              onClick={saveCustom}>
              Save
            </button>
          </div>
        </div>
      )}

      <InlineError error={formik.errors[name]} />
    </div>
  );
};

export default PetTypeField;
