import {Capacitor} from '@capacitor/core';
import {toast, ToastContainer, cssTransition} from 'react-toastify';
import './Toaster.css';

/**
 * Custom transition generated using Animista (https://animista.net/).
 * Refer to ./Toaster.css for animation properties.
 */
const Transition = cssTransition({
  enter: 'slide-in',
  exit: 'slide-out',
});

/**
 * Provider for displaying toasts across the application.
 */
const Toaster = () => {
  return (
    <ToastContainer
      /**
       * The position on the screen for toasts to appear.
       */
      position={
        Capacitor.isNativePlatform()
          ? toast.POSITION.TOP_CENTER
          : toast.POSITION.TOP_RIGHT
      }
      /**
       * The animation performed when a toast appears/disappears.
       */
      transition={Transition}
      /**
       * Classes for the root toast container.
       */
      className="fixed w-full px-4 lg:px-0 lg:w-96"
      /**
       * Classes for the toast itself.
       */
      toastClassName="rounded-xl"
      /**
       * Classes for the body of the toast.
       */
      bodyClassName="font-poppins text-base text-brand-900 text-opacity-70 flex flex-row gap-x-2"
      /**
       * Classes for the toast progress bar
       */
      progressClassName=""
      /**
       * Styles for the toast itself.
       * Used for Capacitor safe area padding
       */
      style={
        Capacitor.isNativePlatform()
          ? {
              paddingTop: 'env(safe-area-inset-top)',
              paddingBottom: 'env(safe-area-inset-bottom)',
              zIndex: 100,
            }
          : {}
      }
    />
  );
};

export default Toaster;
