import React, {useEffect, useRef, useState} from 'react';

import {BiBuildingHouse} from '@react-icons/all-files/bi/BiBuildingHouse';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {Form, Formik, type FormikHelpers} from 'formik';
import * as Yup from 'yup';

import autocompleteIcon from 'assets/img/icons/autocomplete_icon.png';
import ClearDataButton from 'components/chifis_theme/Button/ClearDataButton';
import {InputField} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightFooter from 'components/walkthrough/common/RightFooter';
import RightSideText from 'components/walkthrough/common/RightSideText';
import {API_URL} from 'globals/app-globals';
import Property from 'models/properties/Property';
import TrackingService from 'services/TrackingService';

const PropertyAddressStep = ({
  setPropertyId,
}: {
  setPropertyId: (id: string) => void;
}) => {
  /**
   * Track starting the flow and step.
   */
  useEffect(() => {
    TrackingService.trackEvent(TrackingService.Event.AddProperty_StartFlow);
    TrackingService.trackEvent(
      TrackingService.Event.AddProperty_StartAddressStep,
    );
  }, []);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [showPopover, setShowPopover] = useState(false);
  const [searchOptions, setSearchOptions] = useState<any[]>([]);
  const [formType, setFormType] = useState<'autocomplete' | 'manual'>(
    'autocomplete',
  );

  const [value, setValue] = useState('');
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const [propertyErrors, setPropertyErrors] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const placeholder = 'Address';
  const desc = 'Start typing your property address...';

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener('click', eventClickOutsideDiv);
    }
    showPopover && document.addEventListener('click', eventClickOutsideDiv);
    return () => {
      document.removeEventListener('click', eventClickOutsideDiv);
    };
  }, [showPopover]);

  const filter = async (): Promise<any[]> => {
    if (value.length <= 2) {
      return [];
    }

    const url = `${API_URL}/addresses.json?query=${value}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const places = data.addresses;

      setSearchOptions(places);
    } catch (e) {
      setSearchOptions([]);
    }
  };
  const query = AwesomeDebouncePromise(filter, 300);

  useEffect(() => {
    if (value && value.length > 0) {
      query();
      setShowPopover(true);
    }
  }, [value]);

  const selectAddress = async (option: any): Promise<any[]> => {
    if (option) {
      const url = `${API_URL}/addresses/${option.id}.json`;

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        const ad = data.address;
        setValue(`${ad.displayline}, ${ad.suburb}, ${ad.city}`);

        // Give time for the value useEffect hook to run
        // We want to hide the dropdown that has suggestions
        setTimeout(() => {
          setSelectedAddress(ad);
          setShowPopover(false);
          setSearchOptions([]);
        }, 50);
      } catch (e) {
        return [];
      }
    }
  };

  const handleFormSubmit = async (e: any) => {
    if (e) e.preventDefault();

    if (!selectedAddress) {
      return;
    }

    setIsLoading(true);

    const property = new Property();
    property.addressMeta = selectedAddress;
    property.streetAddress = selectedAddress.displayline;
    property.suburb = selectedAddress.suburb;
    property.city = selectedAddress.city;
    property.postcode = selectedAddress.postcode;
    property.country = 'New Zealand';
    property.lastOnboardingStepCompleted = 'add_property_address';

    const result = await property.save();

    if (result) {
      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.AddProperty_CompleteAddressStep,
        {
          propertyId: property.id,
        },
      );
      localStorage.setItem('new-property-id', property.id);
      setPropertyId(property.id);
    } else {
      const errors = [];
      for (const key of Object.keys(property.errors)) {
        const message = property.errors[key].fullMessage;
        errors.push(message);
      }

      setPropertyErrors(errors.join(', '));
      setIsLoading(false);
    }
  };

  // Manual form
  const handleSubmit = async (formValues: any, actions: FormikHelpers<any>) => {
    const property = new Property(formValues);
    property.lastOnboardingStepCompleted = 'add_property_address';

    const result = await property.save();

    if (result) {
      localStorage.setItem('new-property-id', property.id);
      setPropertyId(property.id);
    } else {
      console.log(property.errors);
      for (const key of Object.keys(property.errors)) {
        const message = property.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  const renderSearchValue = () => {
    return (
      <>
        {searchOptions.map((item) => (
          <span
            onClick={() => selectAddress(item)}
            key={item.id}
            className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer">
            <span className="block text-neutral-400">
              <BiBuildingHouse className="w-6 h-6" />
            </span>
            <span className="block font-medium text-neutral-700 dark:text-neutral-200">
              {item.text}
            </span>
          </span>
        ))}
      </>
    );
  };

  const title = "What's your property's address?";
  const subtitle = 'Enter the address of your rental property';

  return (
    <Container>
      <LeftSide title={title} subtitle={subtitle} />
      <RightContainer>
        <RightSideText title={title} subtitle={subtitle} />
        {formType === 'autocomplete' ? (
          <div className="flex flex-col items-center space-y-4 mx-0 lg:mx-8">
            <form
              id="address-form"
              className="w-full relative xl:mt-8 flex flex-col lg:flex-row lg:items-center rounded-3xl lg:rounded-full shadow-xl dark:shadow-2xl bg-white divide-y divide-neutral-200 lg:divide-y-0"
              onSubmit={handleFormSubmit}>
              <div className="relative flex flex-grow" ref={containerRef}>
                <div
                  onClick={() => {
                    if (searchOptions.length > 0) {
                      setShowPopover(true);
                    }
                    inputRef.current.focus();
                  }}
                  className={`flex flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
                    showPopover
                      ? 'shadow-2xl rounded-full dark:bg-neutral-800'
                      : ''
                  }`}>
                  <div className="text-neutral-300 dark:text-neutral-400">
                    <img className="w-8 h-8" src={autocompleteIcon} />
                  </div>
                  <div className="flex-grow">
                    <input
                      className="block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate"
                      placeholder={placeholder}
                      value={value}
                      autoFocus={showPopover}
                      onChange={(e) => setValue(e.currentTarget.value)}
                      ref={inputRef}
                      autoComplete="off"
                      id="create-listing-address"
                    />
                    <label
                      className="block mt-0.5 text-sm text-neutral-400 font-light"
                      htmlFor="create-listing-address">
                      <span className="line-clamp-1">
                        {value ? placeholder : desc}
                      </span>
                    </label>
                    {value && showPopover && (
                      <ClearDataButton onClick={() => setValue('')} />
                    )}
                  </div>
                </div>
                {showPopover && (
                  <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
                    {renderSearchValue()}
                  </div>
                )}
              </div>
            </form>

            {propertyErrors.length > 0 && (
              <div className="mt-4">
                <p className="text-red-500">{propertyErrors}</p>
              </div>
            )}

            <div>
              <a
                className="link link-primary"
                onClick={() => setFormType('manual')}>
                Enter address manually
              </a>
            </div>
            <ProgressBox
              nextStep={() => handleFormSubmit(null)}
              step={1}
              totalSteps={10}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              previousStep={() => {}}
              previousStepEnabled={false}
              nextStepEnabled={!!selectedAddress && !isLoading}
            />
          </div>
        ) : (
          <div className="flex flex-col items-center space-y-4 mx-8">
            <Formik
              initialValues={{
                address: '',
                streetAddress: '',
                suburb: '',
                city: '',
                country: 'New Zealand',
                postcode: '',
              }}
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={Yup.object({
                streetAddress: Yup.string()
                  .required('Required')
                  .label('Street Address'),
                suburb: Yup.string().required('Required').label('Suburb'),
                city: Yup.string().required('Required').label('City'),
                postcode: Yup.string()
                  .required('Required')
                  .length(4)
                  .label('Post Code'),
              })}>
              {(formik) => (
                <Form>
                  <InputField
                    label="Street Address"
                    name="streetAddress"
                    formik={formik}
                    placeholder="eg: 123 Example Street"
                    autoComplete="off"
                    className="!rounded-lg"
                  />
                  <FormRow>
                    <FormRowItem>
                      <InputField
                        label="Suburb"
                        name="suburb"
                        formik={formik}
                        placeholder="eg: Tawa"
                        autoComplete="off"
                        className="!rounded-lg"
                      />
                    </FormRowItem>
                    <FormRowItem>
                      <InputField
                        label="City"
                        name="city"
                        formik={formik}
                        placeholder="eg: Wellington"
                        autoComplete="off"
                        className="!rounded-lg"
                      />
                    </FormRowItem>
                  </FormRow>
                  <InputField
                    label="Post Code"
                    name="postcode"
                    formik={formik}
                    placeholder="eg: 1234"
                    type="number"
                    autoComplete="off"
                    className="!rounded-lg"
                  />

                  <div className="mt-2 text-center">
                    <a
                      className="link link-primary"
                      onClick={() => setFormType('autocomplete')}>
                      Enter address using autocomplete
                    </a>
                  </div>

                  <small className="text-secondary mt-4 block">
                    Please use the format Unit/Street Number for houses with apt
                    / unit numbers, Eg: "12/59 Fake Street".
                  </small>

                  <ProgressBox
                    nextStep={formik.submitForm}
                    step={1}
                    totalSteps={10}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    previousStep={() => {}}
                    previousStepEnabled={false}
                    nextStepEnabled={!isLoading && !formik.isSubmitting}
                  />
                </Form>
              )}
            </Formik>
          </div>
        )}
      </RightContainer>
    </Container>
  );
};

export default PropertyAddressStep;
