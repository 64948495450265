import React, {Fragment, useState} from 'react';

import {Dialog, Transition} from '@headlessui/react';
import {XIcon} from '@heroicons/react/outline';
import {AiOutlineFileDone} from '@react-icons/all-files/ai/AiOutlineFileDone';
import {AiOutlineSearch} from '@react-icons/all-files/ai/AiOutlineSearch';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router';

import {InlineError} from 'components_sb/feedback';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';

const PrepareNextTenancyModal = ({
  isOpen,
  setIsOpen,
  property,
}: {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  property: Property;
}) => {
  const [errorMessages, setErrorMessages] = useState('');
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => setIsOpen(false);

  const navigate = useNavigate();

  const handleAction = async (action: 'advertise' | 'new') => {
    setIsLoading(true);

    if (action === 'advertise') {
      property.noTenancyActionType = action;
      property.lastOnboardingStepCompleted = 'select_onboarding_type';

      const result = await property.save();

      if (result) {
        queryClient.invalidateQueries(
          `landlord-property-detail-${property.id}`,
        );
        setIsLoading(false);
        closeModal();

        localStorage.setItem(
          'new-listing-address',
          JSON.stringify({address1: property.streetAddress}),
        );
        navigate('/listings/new');
      } else {
        let errorString = '';
        for (const key in property.errors) {
          const error = property.errors[key];
          errorString += error.message + ' ';
        }
        setErrorMessages(errorString);
      }
    } else {
      const t = new Tenancy();
      t.status = 'draft';
      t.isNew = true;
      t.propertyId = property.id;

      const result = await t.save();

      if (result) {
        property.noTenancyActionType = action;
        property.lastOnboardingStepCompleted = 'select_onboarding_type';

        await property.save();

        queryClient.invalidateQueries(
          `landlord-property-detail-${property.id}`,
        );
        setIsLoading(false);
        closeModal();

        localStorage.setItem('new-property-id', property.id);
        navigate('/properties/new');
      } else {
        let errorString = '';
        for (const key in t.errors) {
          const error = t.errors[key];
          errorString += error.message + ' ';
        }
        setErrorMessages(errorString);
        console.log(t.errors);
      }
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}>
        <div className="min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            className="inline-block py-8 w-full"
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
              <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900">
                  Prepare next tenancy
                </Dialog.Title>
                <span className="absolute left-3 top-3">
                  <button onClick={closeModal}>
                    <XIcon className="w-5 h-5" />
                  </button>
                </span>
              </div>

              <div className="flex-grow overflow-y-auto">
                <div className="px-10">
                  <p className="mt-2">
                    If your current tenancy will not be continuing after the end
                    date, you can choose an option below to prepare your next
                    tenancy for this property.
                  </p>

                  <div className="mt-4">
                    <div className="flex justify-center mt-4 mb-8">
                      <div className="ml-4">
                        <button
                          className="btn btn-neutral gap-2"
                          onClick={() => handleAction('new')}
                          disabled={isLoading}>
                          <AiOutlineFileDone className="w-6 h-6 text-white hidden lg:inline-block" />
                          Start A New Tenancy
                        </button>
                      </div>

                      <div className="ml-4">
                        <button
                          className="btn btn-neutral gap-2"
                          onClick={() => handleAction('advertise')}
                          disabled={isLoading}>
                          <AiOutlineSearch className="w-6 h-6 hidden lg:inline-block" />
                          Advertise Property
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="mt-2">
                    {errorMessages.length > 0 && (
                      <InlineError error={errorMessages} />
                    )}
                  </div>
                </div>
              </div>

              <div className="p-2 flex-shrink-0 bg-neutral-100 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                <button
                  className="btn btn-outline btn-secondary btn-sm"
                  onClick={closeModal}>
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PrepareNextTenancyModal;
