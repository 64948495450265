import keyhookLogoWhite from 'assets/img/logo/logo-white-text.png';

const LeftSide = ({
  image,
  title,
  subtitle,
}: {
  image?: any;
  title: string;
  subtitle: any;
}) => {
  return (
    <div className="relative overflow-hidden hidden xl:flex w-1/3 px-12 bg-gradient-to-tr from-blue-300 to-blue-800 justify-around items-center">
      <div className="max-w-xl">
        <h1 className="text-white font-bold text-5xl font-sans leading-snug">
          {title}
        </h1>
        <p className="text-white mt-2 text-lg">{subtitle}</p>
      </div>
    </div>
  );
};

export default LeftSide;
