import React, {FC} from 'react';

import {MdArrowBack} from '@react-icons/all-files/md/MdArrowBack';
import {MdArrowForward} from '@react-icons/all-files/md/MdArrowForward';

import twFocusClass from 'utilities/twFocusClass';

export interface NextPrevProps {
  className?: string;
  currentPage?: number;
  totalPage?: number;
  btnClassName?: string;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  onlyNext?: boolean;
  onlyPrev?: boolean;
}

const NextPrev: FC<NextPrevProps> = ({
  className = '',
  onClickNext = () => {},
  onClickPrev = () => {},
  btnClassName = 'w-10 h-10',
  onlyNext = false,
  onlyPrev = false,
}) => {
  return (
    <div
      className={`nc-NextPrev relative flex items-center text-neutral-900 ${className}`}
      data-nc-id="NextPrev"
      data-glide-el="controls">
      {!onlyNext && (
        <button
          className={`${btnClassName} ${
            !onlyPrev ? 'mr-[6px]' : ''
          } bg-white border border-neutral-200 text-black rounded-full flex items-center justify-center hover:border-neutral-300 ${twFocusClass()}`}
          onClick={onClickPrev}
          title="Prev"
          data-glide-dir="<">
          <MdArrowBack />
        </button>
      )}
      {!onlyPrev && (
        <button
          className={`${btnClassName} bg-white text-black border border-neutral-200 rounded-full flex items-center justify-center hover:border-neutral-300 ${twFocusClass()}`}
          onClick={onClickNext}
          title="Next"
          data-glide-dir=">">
          <MdArrowForward />
        </button>
      )}
    </div>
  );
};

export default NextPrev;
