import moment from 'moment';
import {Link} from 'react-router-dom';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import {RenewalStatusCard} from 'components/tenancy/RenewalStatusCard';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import {DATE_FORMAT} from 'utilities/DateHelpers';

const TenancyRenewalCard = ({
  property,
  tenancy,
}: {
  property: Property;
  tenancy?: Tenancy;
}) => {
  if (property.tenanciesCount === 0) {
    return null;
  }
  if (!tenancy) {
    return null;
  }

  if (!tenancy.isActive) {
    return null;
  }

  if (tenancy.activeTenancyContinuation) {
    const renewal = tenancy.activeTenancyContinuation;
    const endDate = tenancy.endDate;
    if (!renewal.landlordRequestedAction) {
      return (
        <Card className="mt-8 border-t-2 border-info">
          <CardBody>
            <CardTitle>Renewal Options</CardTitle>
            <p>
              Its time to choose what you would like to do when this tenancy
              ends on {moment(endDate).format(DATE_FORMAT)}. Click the button
              below to see your options.
            </p>

            <Link
              to={`tenancies/${tenancy.id}/tenancy-renewals/${renewal.id}/edit`}>
              <button className="btn mt-4">Choose Renewal Option</button>
            </Link>
          </CardBody>
        </Card>
      );
    } else {
      return <RenewalStatusCard renewal={renewal} />;
    }
  }

  return null;
};

export default TenancyRenewalCard;
