import {AiOutlineRight} from '@react-icons/all-files/ai/AiOutlineRight';
import moment from 'moment';
import {useQuery} from 'react-query';
import {useParams} from 'react-router';
import {Link} from 'react-router-dom';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import Property from 'models/properties/Property';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';

const ListingIndexPage = () => {
  usePageVisit('ListingIndexPage');
  const {propertyId} = useParams();

  const {data, error, isLoading} = useQuery(
    `property-${propertyId}-listings`,
    async () => {
      const property = await Property.includes('listings').find(propertyId);

      return property.data;
    },
  );

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Listings">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    if (data.listings.length > 0) {
      const hasActive =
        data.listings.filter((listing) => listing.status === 'active').length >
        0;

      return (
        <PageWrapper
          title="Listings"
          buttons={hasActive ? [] : [{text: 'New Listing', href: 'new'}]}>
          <div>
            {data.listings.map((listing) => (
              <Link to={listing.publicId} key={listing.id}>
                <Card className="mt-2 cursor-pointer" key={listing.id}>
                  <CardBody>
                    <div className="flex justify-between items-center">
                      <div>
                        <CardTitle className="mb-0">
                          Listing {listing.publicId}
                        </CardTitle>
                        <p className="text-secondary text-sm">
                          Created On:{' '}
                          {moment(listing.createdAt).format(DATE_FORMAT)}
                        </p>
                        {listing.status === 'active' && (
                          <div className="badge badge-accent mt-2 text-white">
                            Active
                          </div>
                        )}
                      </div>
                      <div>
                        <AiOutlineRight className="w-5 h-5 text-secondary-300" />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Link>
            ))}
          </div>
        </PageWrapper>
      );
    } else {
      return (
        <PageWrapper
          title="Listings"
          buttons={[{text: 'New Listing', href: 'new'}]}>
          <div></div>
        </PageWrapper>
      );
    }
  }
};

export default ListingIndexPage;
