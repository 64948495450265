import {useEffect, useState} from 'react';

import {type FormikHelpers, Formik, Form} from 'formik';
import {PersistFormikValues} from 'formik-persist-values';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import ToggleButtonGroup from 'components/forms_fields/ToggleButtonGroup';
import LeaseConditionsOnboardFlow from 'components/tenancy/LeaseConditionsOnboardFlow';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightFooter from 'components/walkthrough/common/RightFooter';
import RightSideText from 'components/walkthrough/common/RightSideText';
import {Clause} from 'models/properties/ClauseLibrary';
import Property from 'models/properties/Property';
import {CustomClauses} from 'models/properties/Tenancy';
import TrackingService from 'services/TrackingService';

type FormValues = {
  customClauses: CustomClauses;
  clauses: Clause[];
  leaseDocumentType: string;
};

const LeaseConditionsStep = ({
  property,
  stepNumber,
  totalSteps,
}: {
  property: Property;
  stepNumber: number;
  totalSteps: number;
}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_StartBuildLeaseStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const tenancy = property.tenancies[0];
  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    setIsLoading(true);

    tenancy.assignAttributes(formValues);
    await tenancy.save();

    property.lastOnboardingStepCompleted = 'new_lease_conditions';

    await property.save();

    /**
     * Track completion of the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_CompleteBuildLeaseStep,
      {
        propertyId: property.id,
      },
    );

    localStorage.removeItem('new-tenancy-clauses');
    queryClient.setQueryData(['new-property', property.id], property);
    setIsLoading(false);
  };

  const previousStep = async () => {
    property.lastOnboardingStepCompleted = 'new_chattels';
    await property.save();

    queryClient.setQueryData(['new-property', property.id], property);
  };

  const title = 'Build your lease';
  const subtitle =
    'Use Keyhooks lease builder to add the clauses relevant to your property';

  return (
    <Container>
      <LeftSide title={title} subtitle={subtitle} />
      <RightContainer align="items-start">
        <RightSideText title={title} />
        <Formik
          initialValues={
            {
              customClauses: tenancy.customClauses,
              clauses: tenancy.clauses,
              leaseDocumentType: 'keyhook_new_zealand',
            } as FormValues
          }
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            leaseDocumentType: Yup.string()
              .required()
              .oneOf(['keyhook_new_zealand', 'tenancy_services'])
              .label('Lease Type'),
            customClauses: Yup.object()
              .shape({
                clauses: Yup.array().of(Yup.string().min(0).label('Condition')),
              })
              .label('Conditions'),
          })}>
          {(formik) => (
            <Form>
              <div className="flex flex-col mx-0 md:mx-16 space-y-8">
                <ToggleButtonGroup
                  name="leaseDocumentType"
                  formik={formik}
                  label="Lease"
                  helpText="Choose the lease template you wish to use. We recommend using the Keyhook Lease. You can review and edit your lease below."
                  options={[
                    {label: 'Keyhook', value: 'keyhook_new_zealand'},
                    {label: 'Tenancy Services', value: 'tenancy_services'},
                  ]}
                />

                <LeaseConditionsOnboardFlow
                  property={property}
                  formik={formik}
                />
              </div>
              <ProgressBox
                nextStep={formik.submitForm}
                previousStep={previousStep}
                step={stepNumber}
                totalSteps={totalSteps}
                previousStepEnabled
                nextStepEnabled={!isLoading}
              />

              <PersistFormikValues
                name="new-tenancy-clauses"
                persistInvalid={true}
              />
            </Form>
          )}
        </Formik>
      </RightContainer>
    </Container>
  );
};

export default LeaseConditionsStep;
