import {useCallback, useImperativeHandle} from 'react';

import {ModalDefinition} from 'components_sb/layout';
import Paragraph from 'components_sb/typography/Paragraph/Paragraph';
import useAuth from 'services/useAuth';

const LandlordWelcomeModal: ModalDefinition = {
  title: 'Welcome to Keyhook!',
  buttonsConfig: {
    cancel: {
      label: 'Skip tour',
    },
    actions: [
      {
        id: 'start-tour',
        label: 'Start tour',
        handle: 'onStartTour',
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const {currentUser} = useAuth();

    const onCancel = useCallback(() => false, []);
    const onStartTour = useCallback(() => true, []);

    useImperativeHandle(ref, () => ({
      onCancel,
      onStartTour,
    }));

    return (
      <>
        <Paragraph>We're glad to have you on board with us.</Paragraph>
        <Paragraph disableMargin>
          To get you started, let's explore some of the key features of Keyhook
          and how they work.
        </Paragraph>
      </>
      // <div className="w-full h-72 rounded-lg flex items-center justify-center">
      //   TODO: add video here
      // </div>
    );
  },
};

export default LandlordWelcomeModal;
