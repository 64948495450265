import {useEffect, useState} from 'react';

import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';

import keyhookLogo from 'assets/img/logo/logo-black-text.png';
import trademeLogo from 'assets/img/partner_logos/trademe.png';
import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightFooter from 'components/walkthrough/common/RightFooter';
import {API_URL, STRIPE_KEY} from 'globals/app-globals';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import TrackingService from 'services/TrackingService';
import useAuth from 'services/useAuth';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';

const stripePromise = loadStripe(STRIPE_KEY);

const CreditCardForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stripeErrors, setStripeErrors] = useState<string | null>(null);
  const stripe = useStripe();
  const elements = useElements();

  const queryClient = useQueryClient();

  const handleSubmit = async (event: any) => {
    setStripeErrors(null);
    setIsSubmitting(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setIsSubmitting(false);
      return;
    }

    const result = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    });

    if (result.error) {
      setIsSubmitting(false);
      setStripeErrors(result.error.message);
    } else {
      toast.success('Your payment information has been saved!');
      queryClient.invalidateQueries('user-payment-methods');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="bg-gray-50 py-4">
        <div className="w-1/2 ml-auto mr-auto">
          <PaymentElement />

          {stripeErrors && <p className="text-error my-2">{stripeErrors}</p>}

          <button
            className="btn btn-neutral btn-block mt-4"
            disabled={isSubmitting}>
            Save Card Information
          </button>
        </div>
      </form>
    </div>
  );
};

const PublishStep = ({property}: {property: Property}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_StartPublishListingStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const listing = property.listings[0];

  const {currentUser} = useAuth();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {data, isLoading, error} = useQuery(
    'user-payment-methods',
    async () => {
      const response = await fetch(`${API_URL}/stripe/payment_methods.json`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-USER-TOKEN': currentUser?.meta.authenticationToken,
          'X-USER-EMAIL': currentUser?.email,
        },
      });

      if (response.ok) {
        const methods = await response.json();
        return methods['payment_methods'];
      } else {
        return [];
      }
    },
  );

  const {data: clientToken, isLoading: clientTokenIsLoading} = useQuery(
    'user-stripe-client-token',
    async () => {
      const response = await fetch(`${API_URL}/stripe/client_token.json`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-USER-TOKEN': currentUser?.meta.authenticationToken,
          'X-USER-EMAIL': currentUser?.email,
        },
      });

      if (response.ok) {
        const body = await response.json();
        return body;
      } else {
        return null;
      }
    },
    {
      enabled: data && data.length === 0,
    },
  );

  const previousStep = async () => {
    property.lastOnboardingStepCompleted = 'listing_description';
    await property.save();
    queryClient.setQueryData('new-listing-wizard', property);
  };

  const confirmPublishListing = () => {
    setConfirmationOptions({
      title: 'Publish Listing',
      message:
        'Your listing will be published on Keyhook Listings and Trade Me. By clicking Publish you acknowledge that you have read and understood the promotional terms.',
      color: 'success',
      buttonTitle: 'Publish',
      action: publishListing,
    });
  };

  const publishListing = async () => {
    if (listing) {
      if (listing.status !== 'pending_approval') {
        listing.status = 'pending_approval';
        const result = await listing.save();
        if (result) {
          // Create a blank tenancy for user to get started on.
          const t = new Tenancy();
          t.status = 'draft';
          t.isNew = true;
          t.propertyId = property.id;

          const result2 = await t.save();

          property.noTenancyActionType = 'new';
          // They can skip ahead a few steps
          // They would have set basic info via the listing set up.
          property.lastOnboardingStepCompleted = 'new_general_information';

          const result3 = await property.save();

          if (result2 && result3) {
            toast.success(
              'Your listing is awaiting approval and will be published soon!',
            );
            // Clear out listing from walkthrough
            localStorage.removeItem('new-listing-address');

            await property.save();

            /**
             * Track completion of the step.
             */
            TrackingService.trackEvent(
              TrackingService.Event.ListProperty_CompletePublishListingStep,
              {
                propertyId: property.id,
              },
            );

            /**
             * Track completion of the flow.
             */
            TrackingService.trackEvent(
              TrackingService.Event.ListProperty_CompleteFlow,
              {
                propertyId: property.id,
              },
            );

            navigate(`/listings/${listing.publicId}`);
          }
        } else {
          const errors = [];
          for (const key of Object.keys(listing.errors)) {
            const message = listing.errors[key].fullMessage;
            errors.push(message);
          }
          console.log(errors);
        }
      } else {
        toast.error('This listing has already been published.');
      }
    }
  };

  const renderCardInformation = () => {
    if (data.length === 0) {
      return (
        <Card className="mt-4">
          <CardBody>
            <CardTitle>Promotion Terms & Conditions</CardTitle>
            <p className="text-sm">
              Free Trade Me listings are only available to users who manage
              their rental property through Keyhook for a period of at least six
              months following the posting of the listing. A payment of $199
              +gst and credit card fees will be charged to the card given below
              if you fail to use Keyhook for the required six months. Your card
              will not be charged otherwise.
            </p>

            <p className="text-sm">
              For full Terms and Conditions,{' '}
              <a
                href="https://keyhook.com/trademe-promotion"
                target="_blank"
                className="link link-primary text-sm">
                click here
              </a>
            </p>

            <CreditCardForm />
          </CardBody>
        </Card>
      );
    } else {
      return (
        <Card className="mt-4">
          <CardBody>
            <CardTitle>Promotion Terms & Conditions</CardTitle>
            <p>You have a credit card saved with Keyhook.</p>

            <p>
              Please note: your listing is subject to a quick review and will be
              published once it has been approved.
            </p>

            <p>
              For full Terms and Conditions,{' '}
              <a
                href="https://keyhook.com/trademe-promotion"
                target="_blank"
                className="link link-primary">
                click here
              </a>
            </p>
          </CardBody>
        </Card>
      );
    }
  };

  const title = 'Publish your listing on Keyhook & Trade Me';
  const subtitle =
    "Please make sure you've read and agreed to the Terms and Conditions";

  if (error) {
    return (
      <Container>
        <LeftSide title={title} subtitle={subtitle} />
        <RightContainer>
          <div className="flex flex-col md:mx-16">
            <p>
              There was an issue with this page, please contact our support
              team.
            </p>
          </div>
          <RightFooter
            nextStep={() => {
              console.log('next');
            }}
            previousStep={previousStep}
            step={10}
            previousStepEnabled
            nextStepEnabled={false}
          />
        </RightContainer>
      </Container>
    );
  } else if (isLoading || clientTokenIsLoading) {
    return (
      <Container>
        <LeftSide title={title} subtitle={subtitle} />
        <RightContainer>
          <div className="flex flex-col md:mx-16">
            <LoadingView />
          </div>
          <RightFooter
            nextStep={() => {
              console.log('next');
            }}
            previousStep={previousStep}
            step={10}
            previousStepEnabled
            nextStepEnabled={false}
          />
        </RightContainer>
      </Container>
    );
  } else {
    return (
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret: clientToken.token,
          appearance: {theme: 'stripe'},
        }}>
        <Container>
          <LeftSide title={title} subtitle={subtitle} />
          <RightContainer align="items-start">
            <div className="flex flex-col md:mx-16">
              <Card>
                <CardBody>
                  <CardTitle>Publish Your Listing</CardTitle>
                  <p>
                    Your listing is ready to publish to Keyhook Listings and
                    Trade Me. Below is a summary of where your ad will be posted
                    and any associated fees.
                  </p>

                  <div className="mt-4">
                    <div className="flex justify-between items-center bg-gray-50 p-8 pt-4">
                      <div>
                        <img
                          src={keyhookLogo}
                          alt="Keyhook"
                          className="h-16 w-auto"
                        />

                        <ul className="list-disc list-inside ml-4 text-sm">
                          <li>Comprehensive tenant applications</li>
                          <li>One inquiries inbox for all platforms</li>
                          <li>Easy-to-use open home planner</li>
                          <li>Integration with NZ's biggest platform</li>
                          <li>Turn applicants into tenants in one click</li>
                          <li>Curated tenancy agreements</li>
                        </ul>
                      </div>
                      <div className="hidden md:block">
                        <h5 className="text-2xl font-semibold">FREE</h5>
                      </div>
                    </div>

                    <div className="block md:hidden">
                      <div className="flex justify-center bg-gray-50 p-8 pt-0">
                        <h5 className="text-2xl font-semibold text-center">
                          FREE
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <div className="flex justify-between items-center bg-gray-50 p-8">
                      <div>
                        <img
                          src={trademeLogo}
                          alt="TradeMe"
                          className="h-8 w-auto ml-2"
                        />

                        <ul className="list-disc list-inside ml-4 text-sm">
                          <li>Free premium 1-week upgrade</li>
                          <ul className="list-disc list-inside ml-4 md:ml-8 text-xs">
                            <li>Trade Me's biggest ad</li>
                            <li>Always featured top of page</li>
                            <li>23% more watchlist adds</li>
                            <li>35% more views</li>
                            <li>Not normally available to individuals</li>
                          </ul>
                          <li>New Zealand's biggest listings platform </li>
                          <li>Featured listing (enlarged, yellow boarder)</li>
                          <li>Free renewals</li>
                        </ul>
                      </div>
                      <div className="text-center hidden md:block">
                        <h5 className="text-2xl font-semibold line-through text-gray-400">
                          $299
                        </h5>
                        <h5 className="text-2xl font-semibold">FREE</h5>
                      </div>
                    </div>

                    <div className="block md:hidden">
                      <div className="flex flex-col items-center bg-gray-50 p-8 pt-0">
                        <h5 className="text-2xl font-semibold line-through text-gray-400">
                          $299
                        </h5>
                        <h5 className="text-2xl font-semibold">FREE</h5>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>

              {renderCardInformation()}
            </div>
            <RightFooter
              nextStep={confirmPublishListing}
              previousStep={previousStep}
              step={10}
              previousStepEnabled
              nextStepEnabled={data.length > 0}
            />
          </RightContainer>
        </Container>
      </Elements>
    );
  }
};

export default PublishStep;
