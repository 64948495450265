import React, {ReactNode} from 'react';

import {type FormikProps} from 'formik';

import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import {InlineError} from 'components_sb/feedback';
import TrackingService from 'services/TrackingService';

type Value = {
  label: string;
  value: string | number;
};

const ToggleButtonGroup = ({
  formik,
  label,
  name,
  helpText,
  options,
}: {
  formik: FormikProps<any>;
  name: string;
  label?: ReactNode;
  helpText?: string;
  options: Value[];
}) => {
  const handleChange = (val: string | number) => {
    formik.setFieldValue(name, val);
    TrackingService.trackEvent(TrackingService.Event.SetToggleButtonGroup, {
      field: name,
    });
  };

  let sizeClass = '';
  if (options.length <= 4) {
    sizeClass = 'px-4';
  } else {
    sizeClass = 'btn-sm px-2';
  }

  return (
    <div>
      <div className="flex justify-between items-center">
        {label && (
          <div>
            <span className="text-xl md:text-3xl font-medium text-neutral-800 dark:text-neutral-200">
              <span>{label}</span>
              <HelpTextPresenter helpText={helpText} />
            </span>
          </div>
        )}

        <div className="btn-group">
          {options.map((val) => (
            <button
              key={val.value}
              type="button"
              className={`btn ${sizeClass} ${
                formik.values[name] === val.value ? '' : 'btn-outline'
              }`}
              onClick={() => handleChange(val.value)}>
              {val.label}
            </button>
          ))}
        </div>
      </div>
      <InlineError error={formik.errors[name]} />
    </div>
  );
};

export default ToggleButtonGroup;
