import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Listing from 'models/listings/Listing';

@Model()
class ExternalListingAd extends ApplicationRecord {
  static jsonapiType = 'external_listing_ads';

  @Attr({persist: false}) link: string;
  @Attr({persist: false}) site: 'trademe.co.nz';
  @Attr({persist: false}) externalId: string;

  @Attr({persist: false}) viewCount: number;

  @Attr({persist: false}) listingId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('listings') listing: Listing;
}

export default ExternalListingAd;
