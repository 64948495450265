import {useEffect, useState} from 'react';

import {type FormikHelpers, Formik, Form} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import {CountableMoneyField} from 'components/forms_fields';
import InlineMoneyField from 'components/forms_fields/InlineMoneyField';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightSideText from 'components/walkthrough/common/RightSideText';
import {MOBILE_BREAKPOINT_WIDTH} from 'globals/ScreenSizeConstants';
import useWindowSize from 'hooks/useWindowResize';
import Property from 'models/properties/Property';
import TrackingService from 'services/TrackingService';

type FormValues = {
  rentAmount: number;
  bondAmount?: number | null;
  rentalPeriod: string;
};

const FinancialsStep = ({property}: {property: Property}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_StartRentDetailsStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const listing = property.listings[0];

  const [isLoading, setIsLoading] = useState(false);

  const [isMobile, setIsMobile] = useState(
    window.screen.width <= MOBILE_BREAKPOINT_WIDTH,
  );

  const queryClient = useQueryClient();

  const {width: windowWidth} = useWindowSize();

  useEffect(
    () => setIsMobile(windowWidth <= MOBILE_BREAKPOINT_WIDTH),
    [windowWidth],
  );

  const handleSubmit = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    setIsLoading(true);

    property.lastOnboardingStepCompleted = 'listing_financials';
    await property.save();

    listing.assignAttributes(formValues);
    await listing.save();

    /**
     * Track completion of the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_CompleteRentDetailsStep,
      {
        propertyId: property.id,
      },
    );

    setIsLoading(false);
    queryClient.setQueryData('new-listing-wizard', property);
  };

  const previousStep = async () => {
    property.lastOnboardingStepCompleted = 'listing_general_information';
    await property.save();
    queryClient.setQueryData('new-listing-wizard', property);
  };

  const title = 'Next up, tenancy information...';

  return (
    <Container>
      <LeftSide title={title} subtitle="" />
      <RightContainer>
        <RightSideText title={title} />
        <Formik
          initialValues={
            {
              rentAmount: listing.rentAmount || 0,
              bondAmount: listing.bondAmount || null,
              rentalPeriod: 'Weekly',
            } as FormValues
          }
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            rentAmount: Yup.number().required().min(1).label('Rent'),
            bondAmount: Yup.number()
              .min(0)
              .optional()
              .nullable()
              .label('Bond')
              .test(
                'max-bond-is-allowed',
                'Bond can not be greater than 4 weeks rent',
                function (value) {
                  if (!value || value == 0) {
                    return true;
                  }
                  const rentalPeriod = this.parent.rentalPeriod;
                  const rent = this.parent.rentAmount;
                  if (rentalPeriod === 'Weekly') {
                    return value <= rent * 4;
                  } else if (rentalPeriod === 'Fortnightly') {
                    return value <= rent * 2;
                  } else {
                    return true;
                  }
                },
              ),
          })}>
          {(formik) => (
            <Form className="mx-0 lg:mx-8">
              <div className="flex flex-col space-y-8">
                {isMobile ? (
                  <>
                    <InlineMoneyField
                      formik={formik}
                      name="rentAmount"
                      label="Rent per week"
                    />

                    <InlineMoneyField
                      formik={formik}
                      name="bondAmount"
                      label="Bond (optional)"
                    />
                  </>
                ) : (
                  <>
                    <CountableMoneyField
                      formik={formik}
                      name="rentAmount"
                      label="Rent per week"
                    />

                    <CountableMoneyField
                      formik={formik}
                      name="bondAmount"
                      label="Bond (optional)"
                    />
                  </>
                )}
              </div>
              <ProgressBox
                nextStep={formik.submitForm}
                previousStep={previousStep}
                step={4}
                previousStepEnabled
                nextStepEnabled={!isLoading}
              />
            </Form>
          )}
        </Formik>
      </RightContainer>
    </Container>
  );
};

export default FinancialsStep;
