import {MouseEvent} from 'react';

import {BiBath} from '@react-icons/all-files/bi/BiBath';
import {BiBed} from '@react-icons/all-files/bi/BiBed';
import {BiCar} from '@react-icons/all-files/bi/BiCar';
import {useNavigate} from 'react-router-dom';

import GallerySlider from 'components/chifis_theme/GallerySlider';
import Listing from 'models/listings/Listing';

const ListingSearchResultCard = ({listing}: {listing: Listing}) => {
  const navigate = useNavigate();

  const renderSliderGallery = () => {
    return (
      <div className="flex-shrink-0 p-3 w-full sm:w-64 ">
        <GallerySlider
          ratioClass="aspect-w-1 aspect-h-1"
          className="w-full h-full rounded-2xl overflow-hidden"
          listing={listing}
        />
      </div>
    );
  };

  const handleClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    const tag = target.tagName.toLowerCase();

    // This basically fixes the issue where clicking
    // on the next/prev buttons for the gallery slider
    // would navigate to the listing
    if (tag !== 'button' && tag !== 'svg' && tag !== 'path') {
      navigate(`/listings/${listing.publicId}`);
    }
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
        <div className="space-y-4 w-full">
          <div className="inline-flex space-x-3">
            <div className="badge badge-primary badge-sm p-4 text-white">
              {listing.propertyType}
            </div>
          </div>
          <div className="flex items-center">
            <h2 className="text-xl font-medium capitalize">
              <span className="line-clamp-2">{listing.streetAddress}</span>
            </h2>
          </div>
          <p className="text-sm text-secondary !mt-[-4px]">
            {listing.suburb}, {listing.city}
          </p>

          <div className="inline-grid grid-cols-3 gap-2">
            <div className="flex items-center space-x-2">
              <span className="hidden sm:inline-block">
                <BiBed className="w-5 h-5" />
              </span>
              <span className="text-xs text-neutral">
                {listing.bedrooms} beds
              </span>
            </div>

            {/* ---- */}
            <div className="flex items-center space-x-2">
              <span className="hidden sm:inline-block">
                <BiBath className="w-5 h-5" />
              </span>
              <span className="text-xs text-neutral">
                {listing.bathrooms} baths
              </span>
            </div>

            {/* ---- */}
            {listing.garages > 0 && (
              <div className="flex items-center space-x-2">
                <span className="hidden sm:inline-block">
                  <BiCar className="w-5 h-5" />
                </span>
                <span className="text-xs text-neutral">
                  {listing.garages} garages
                </span>
              </div>
            )}
          </div>

          <div className="w-14 border-b border-neutral-100"></div>
          <div className="flex w-full justify-between items-end">
            <span className="flex items-center justify-center px-3 py-2 border border-accent rounded leading-none text-base font-medium text-accent">
              ${Number(listing.rentAmount).toFixed(2)} / week
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="nc-PropertyCardH group relative bg-white border border-neutral-100 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow cursor-pointer mb-2"
      data-nc-id="PropertyCardH"
      onClick={handleClick}>
      <div className="h-full w-full flex flex-col sm:flex-row sm:items-center">
        {renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  );
};

export default ListingSearchResultCard;
