import {useState} from 'react';

import {Capacitor} from '@capacitor/core';
import Decimal from 'decimal.js';
import {Formik, Form, type FormikProps} from 'formik';
import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import {Navigate, useNavigate, useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import headTenantIcon from 'assets/img/tenancy_clause_icons/head-tenant.svg';
import ChatableMessages from 'components/chat/ChatableMessages';
import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import TenancyClause from 'components/common/TenancyClause';
import DocumentPreviewModal from 'components/document/DocumentPreviewModal';
import {InputField, SelectField, SubmitButton} from 'components/forms_fields';
import SignatureModalInput from 'components/forms_fields/SignatureModalInput';
import PageWrapper from 'components/PageWrapper';
import UserAvatar from 'components/user/UserAvatar';
import {API_URL} from 'globals/app-globals';
import TenancyRequest from 'models/properties/TenancyRequest';
import TrackingService from 'services/TrackingService';
import useAuth from 'services/useAuth';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';
import {shareEvenly} from 'utilities/MathHelpers';
import {toCurrency} from 'utilities/StringHelpers';

const TenancyRequestDetailPage = () => {
  useTitle('Tenancy Request');
  usePageVisit('TenancyRequestDetailPage');
  const {id} = useParams();

  const navigate = useNavigate();

  const [leaseViewerVisible, setLeaseViewerVisible] = useState(false);
  const [bondViewerVisible, setBondViewerVisible] = useState(false);

  const {currentUser} = useAuth();

  const {data, error, isLoading} = useQuery(
    `renter-tenancy-request-detail-${id}`,
    async () => {
      const res = await TenancyRequest.select({
        tenancy_requests: [
          'accepted',
          'is_head_tenant',
          'created_at',
          'renter_email',
          'rent_split',
          'bond_split',
        ],
        tenancies: [
          'start_date',
          'end_date',
          'total_rent',
          'rental_period',
          'bond',
          'is_new',
          'status',
          'custom_clauses',
          'weeks_rent_in_advance',
          'message_from_landlord',
        ],
        properties: [
          'street_address',
          'suburb',
          'city',
          'main_image',
          'pets_allowed',
          'allowed_pet_types',
          'smokers_allowed',
          'renters_insurance_required',
          'insurance_covers_tenants',
          'chattels',
          'courtesy_chattels',
          'max_tenants',
          'renters_insurance_required',
          'inspection_period',
        ],
        users: ['name', 'avatar'],
      })
        .includes({
          tenancy: [{property: 'landlord'}, 'documents', 'tenancy_requests'],
        })
        .find(id);
      return res.data;
    },
    {retry: 1},
  );

  const queryClient = useQueryClient();

  const handleSubmit = async (formValues: any, actions: any) => {
    const request: TenancyRequest = data;
    request.assignAttributes(formValues);
    request.accepted = true;

    const result = await request.save();
    if (result) {
      queryClient.setQueryData(`renter-tenancy-request-detail-${id}`, request);
      queryClient.invalidateQueries('renter-tenancy-requests');

      const toastString =
        data.tenancy.tenancyRequests.length === 1
          ? 'You have successfully accepted this tenancy. More details will become available from your dashboard in a few moments once Keyhook has processed the tenancy.'
          : 'You have successfully accepted this tenancy. More details will be available once all invited tenants have signed.';

      toast.success(toastString);

      TrackingService.trackEvent(TrackingService.Event.AcceptNewLease);
    } else {
      for (const field in request.errors) {
        const error = request.errors[field];
        actions.setFieldError(field, error?.fullMessage);
      }
    }

    actions.setSubmitting(false);
  };

  const acceptMigratedLease = async () => {
    const request: TenancyRequest = data;
    request.accepted = true;

    const result = await request.save();
    if (result) {
      queryClient.setQueryData(`renter-tenancy-request-detail-${id}`, request);
      queryClient.invalidateQueries('renter-tenancy-requests');

      toast.success(
        'You have successfully accepted your landlords invitation!',
      );

      TrackingService.trackEvent(TrackingService.Event.AcceptMigratedLease);
    } else {
      toast.error('Something went wrong while accepting this tenancy.');
    }
  };

  const handleSplitsSubmit = async (formValues: any, actions: any) => {
    const response = await fetch(
      `${API_URL}/tenancies/${data.tenancy.id}/set_rent_splits.json`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-USER-TOKEN': currentUser.meta.authenticationToken,
          'X-USER-EMAIL': currentUser.email,
        },
        body: JSON.stringify(formValues),
      },
    );

    if (response.ok) {
      const bondResponse = await fetch(
        `${API_URL}/tenancies/${data.tenancy.id}/set_bond_splits.json`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-USER-TOKEN': currentUser.meta.authenticationToken,
            'X-USER-EMAIL': currentUser.email,
          },
          body: JSON.stringify(formValues),
        },
      );

      if (bondResponse.ok) {
        queryClient.invalidateQueries(`renter-tenancy-request-detail-${id}`);

        TrackingService.trackEvent(TrackingService.Event.SetRentBondSplits);
      } else {
        const error = await response.json();
        actions.setFieldError('tenancyRequests', error.errors.join(', '));
      }
    } else {
      const error = await response.json();
      actions.setFieldError('tenancyRequests', error.errors.join(', '));
    }
    actions.setSubmitting(false);
  };

  const splitRentEvenly = (formikProps: FormikProps<any>) => {
    const numTenants = data.tenancy.tenancyRequests.length;
    const totalRent = data.tenancy.totalRent;

    const amounts = shareEvenly(totalRent, numTenants);
    for (const index in amounts) {
      const amount = amounts[index];

      formikProps.setFieldValue(`tenancyRequests[${index}].rentSplit`, amount);
    }

    TrackingService.trackEvent(TrackingService.Event.SplitRentEvenly);
  };

  const splitBondEvenly = (formikProps: FormikProps<any>) => {
    const numTenants = data.tenancy.tenancyRequests.length;
    const totalBond = data.tenancy.bond;

    const amounts = shareEvenly(totalBond, numTenants);
    for (const index in amounts) {
      const amount = amounts[index];

      formikProps.setFieldValue(`tenancyRequests[${index}].bondSplit`, amount);
    }

    TrackingService.trackEvent(TrackingService.Event.SplitBondEvenly);
  };

  const renderRentSplitCard = () => {
    if (data.isHeadTenant) {
      const values = {
        tenancyRequests: data.tenancy.tenancyRequests.map((req) => {
          return {
            id: req.id,
            rentSplit: req.rentSplit,
            bondSplit: req.bondSplit,
          };
        }),
      };
      return (
        <Card className="mt-4">
          <CardBody>
            <CardTitle className="mb-0">Rent & Bond Share</CardTitle>
            <p className="mb-2">
              You have been designated as the head tenant and are responsible
              for setting the portion of rent / bond each tenant will pay.
              Please be sure to agree to these amounts in person with other
              tenants. You will not be able to edit these amounts if you make a
              mistake.
            </p>

            {!data.tenancy.isNew && (
              <p className="mb-2">
                The bond amounts should reflect what each tenant has already
                paid to Tenancy Services.
              </p>
            )}

            <p>
              Tenants won't be able to sign the lease until this is filled in.
            </p>

            <Formik
              initialValues={values}
              validationSchema={Yup.object().shape({
                tenancyRequests: Yup.array()
                  .of(
                    Yup.object().shape({
                      id: Yup.string().required('Required').label('Id'),
                      rentSplit: Yup.number()
                        .typeError('Please enter a number')
                        .required()
                        .label('Share of Rent'),
                      bondSplit: Yup.number()
                        .typeError('Please enter a number')
                        .required()
                        .label('Share of Bond'),
                    }),
                  )
                  .required('Must have rent/bond shares set')
                  .test({
                    name: 'rent-amounts-equal-total',
                    message: `Rent amounts do not total up to $${data.tenancy.totalRent}`,
                    test: (values) => {
                      let total = new Decimal(0);
                      for (const ship of values) {
                        total = total.add(new Decimal(ship.rentSplit));
                      }

                      return total.equals(new Decimal(data?.tenancy.totalRent));
                    },
                  })
                  .test({
                    name: 'bond-amounts-equal-total',
                    message: `Bond amounts do not total up to $${data.tenancy.bond}`,
                    test: (values) => {
                      let total = new Decimal(0);
                      for (const ship of values) {
                        total = total.add(new Decimal(ship.bondSplit));
                      }

                      return total.equals(new Decimal(data?.tenancy.bond));
                    },
                  }),
              })}
              onSubmit={handleSplitsSubmit}
              validateOnBlur={false}
              validateOnChange={false}>
              {(formik) => {
                return (
                  <Form>
                    {data.tenancy.tenancyRequests.map((req, index) => {
                      return (
                        <div className="form-control" key={req.id}>
                          <span className="block mt-2">{req.renterEmail}</span>
                          <div className="flex justify-between">
                            <div className="w-full">
                              <label className="label">
                                <span className="label-text">Rent</span>
                              </label>
                              <label className="input-group">
                                <span>$</span>
                                <InputField
                                  name={`tenancyRequests[${index}].rentSplit`}
                                  formik={formik}
                                  type="number"
                                  placeholder="eg: 123.45"
                                  label=""
                                  disabled={req.rentSplit !== null}
                                />
                              </label>
                            </div>
                            <div className="w-full ml-2">
                              <label className="label">
                                <span className="label-text">Bond</span>
                              </label>
                              <label className="input-group">
                                <span>$</span>
                                <InputField
                                  name={`tenancyRequests[${index}].bondSplit`}
                                  formik={formik}
                                  type="number"
                                  placeholder="eg: 123.45"
                                  label=""
                                  disabled={req.bondSplit !== null}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {data.rentSplit && data.bondSplit ? (
                      <div className="mt-4 text-success">
                        <p>
                          You have successfully set the rent and bond portions
                          for your tenancy. You and your other tenants can now
                          sign the lease.
                        </p>
                      </div>
                    ) : (
                      <div className="mt-4">
                        <button
                          className="btn btn-neutral"
                          type="button"
                          onClick={() => splitRentEvenly(formik)}>
                          Split Rent Evenly
                        </button>
                        <button
                          className="btn btn-neutral ml-4"
                          type="button"
                          onClick={() => splitBondEvenly(formik)}>
                          Split Bond Evenly
                        </button>
                        <SubmitButton
                          submittingText="Saving"
                          text="Save"
                          formik={formik}
                          className="mt-3"
                        />

                        {formik.errors.tenancyRequests && (
                          <p className="mt-2 text-red-500">
                            {formik.errors.tenancyRequests
                              .toString()
                              .includes('[')
                              ? ''
                              : formik.errors.tenancyRequests.toString()}
                          </p>
                        )}

                        <p className="text-secondary text-sm mt-2">
                          When your rent or bond doesn't divide evenly, make
                          sure that each portion doesn't have recurring numbers.
                        </p>
                        <p className="text-secondary text-sm mt-2">
                          Please make sure your rent adds up to{' '}
                          {toCurrency(data.tenancy.totalRent)} and your bond
                          adds up to {toCurrency(data.tenancy.bond)}
                        </p>

                        <p className="text-secondary text-sm mt-1">
                          0 is a valid amount if a tenant isn't going to be
                          paying rent or the bond, e.g., one half of a couple is
                          paying both portions of the rent/bond.
                        </p>
                      </div>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </CardBody>
        </Card>
      );
    } else {
      if (data.rentSplit && data.bondSplit) {
        return (
          <Card className="mt-4">
            <CardBody>
              <CardTitle className="mb-0">
                Your share of the rent / bond
              </CardTitle>
              <p>
                The head tenant has specified that your share of the rent will
                be {toCurrency(data.rentSplit)} and your share of the bond will
                be {toCurrency(data.bondSplit)}. If there are any issues with
                this amount, please discuss it with them and not your landlord.
              </p>
            </CardBody>
          </Card>
        );
      } else {
        return (
          <Card className="mt-4">
            <CardBody>
              <CardTitle className="mb-0">Waiting on the head tenant</CardTitle>
              <p>
                We are currently waiting on the head tenant to input your share
                of the rent and bond. This should be discussed in person as to
                how much each of you will pay.
              </p>

              <p>
                Once the head tenant has inputted your share of the rent and
                bond, you will be able to see it here and will then be able to
                accept the lease and sign any paperwork.
              </p>
            </CardBody>
          </Card>
        );
      }
    }
  };

  if (error) {
    const errMessage = (error as any).message as string;
    if (errMessage.includes('not found')) {
      queryClient.invalidateQueries('renter-tenancy-requests');
      return <Navigate to="/" />;
    } else {
      return errorViewForError(error);
    }
  } else if (isLoading) {
    return (
      <PageWrapper title="Tenancy Request">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    const tenancy = data.tenancy;
    const property = tenancy.property;
    const landlord = property.landlord;

    let leaseDocumentURL: string;
    let bondDocumentURL: string;
    if (tenancy.isNew) {
      leaseDocumentURL = `${API_URL}/tenancy_requests/${id}/lease_preview.pdf`;
      bondDocumentURL = `${API_URL}/tenancy_requests/${id}/bond_preview.pdf`;
    } else {
      const docs = tenancy.documents.filter(
        (doc) => doc.documentType === 'lease',
      );
      if (docs.length > 0) {
        const doc = docs[0];
        leaseDocumentURL = doc.document;
      }

      const docs2 = tenancy.documents.filter(
        (doc) => doc.documentType === 'bond',
      );
      if (docs2.length > 0) {
        const doc = docs2[0];
        bondDocumentURL = doc.document;
      }
    }

    const showBondViewer = () => {
      if (Capacitor.isNativePlatform()) {
        navigate(`/document-previews?url=${bondDocumentURL}`);
      } else {
        setBondViewerVisible(true);
        TrackingService.trackEvent(TrackingService.Event.ViewBond);
      }
    };

    const showLeaseViewer = () => {
      if (Capacitor.isNativePlatform()) {
        navigate(`/document-previews?url=${leaseDocumentURL}`);
      } else {
        setLeaseViewerVisible(true);
        TrackingService.trackEvent(TrackingService.Event.ViewLease);
      }
    };

    if (tenancy.isNew) {
      return (
        <PageWrapper title="Tenancy Request">
          <Card className="mt-4" key={data.id}>
            <div className="alert items-start">
              <div className="flex-1">
                <UserAvatar user={landlord} size="10" />
                <label className="ml-2">
                  <h4 className="font-semibold">{landlord.name}</h4>
                  <p className="text-sm text-base-content text-opacity-60">
                    Sent {moment(data.createdAt).fromNow()}
                  </p>
                </label>
              </div>
            </div>

            <CardBody className="mb-0">
              <strong className="block text-2xl font-semibold">
                {property.streetAddress}
              </strong>
              <span className="block text-sm text-secondary">
                {property.suburb}, {property.city}
              </span>

              <div className="flex justify-start mt-2">
                <div className="w-1/2">
                  <span className="block text-secondary">Rent</span>
                  <strong>{toCurrency(tenancy.totalRent)}</strong>
                  <span>/ {tenancy.rentalPeriod}</span>
                </div>
                <div className="w-1/2">
                  <span className="block text-secondary">Bond</span>
                  <strong>{toCurrency(tenancy.bond)}</strong>
                </div>
              </div>
              <div className="flex justify-start mt-2">
                <div className="w-1/2">
                  <span className="block text-secondary">Lease Starts</span>
                  <strong>
                    {moment(tenancy.startDate).format(DATE_FORMAT)}
                  </strong>
                </div>
                <div className="w-1/2">
                  <span className="block text-secondary">Lease Ends</span>
                  <strong>
                    {tenancy.endDate
                      ? moment(tenancy.endDate).format(DATE_FORMAT)
                      : 'Ongoing'}
                  </strong>
                </div>
              </div>

              {tenancy.weeksRentInAdvance > 0 && (
                <div className="flex justify-start mt-2">
                  <div className="w-1/2">
                    <span className="block text-secondary">
                      Rent in Advance
                    </span>
                    <strong>
                      {tenancy.weeksRentInAdvance}{' '}
                      {tenancy.weeksRentInAdvance === 1 ? 'Week' : 'Weeks'}
                    </strong>
                  </div>
                </div>
              )}

              {data.tenancy.messageFromLandlord && (
                <div className="mt-2">
                  <span className="block text-secondary">
                    Message from Landlord
                  </span>
                  <p>
                    <em>{data.tenancy.messageFromLandlord}</em>
                  </p>
                </div>
              )}

              {data.isHeadTenant && (
                <TenancyClause
                  title="Head Tenant"
                  clause="You have been selected as the head tenant by the landlord. This means that you will be responsible for setting the portion of total rent that each tenant pays."
                  icon={headTenantIcon}
                />
              )}

              <div className="mt-2">
                <p className="text-secondary text-sm mb-4">
                  Note: These documents are just a preview and will show only
                  your details under the tenant section. The full documents will
                  have all parties' details. You do not need to fill any
                  information into these documents.
                </p>

                <button
                  className="btn btn-neutral btn-block"
                  type="button"
                  onClick={showLeaseViewer}>
                  Preview Lease
                </button>

                <button
                  className="btn btn-neutral btn-block mt-4"
                  type="button"
                  onClick={showBondViewer}>
                  Preview Bond
                </button>

                <DocumentPreviewModal
                  isVisible={leaseViewerVisible}
                  setIsVisible={setLeaseViewerVisible}
                  documentUrl={leaseDocumentURL}
                  title="Lease Preview"
                />

                <DocumentPreviewModal
                  isVisible={bondViewerVisible}
                  setIsVisible={setBondViewerVisible}
                  documentUrl={bondDocumentURL}
                  title="Bond Preview"
                />
              </div>
            </CardBody>
          </Card>

          {data.tenancy.tenancyRequests.length > 1 && renderRentSplitCard()}

          {data.accepted ? (
            <Card className="mt-4">
              <CardBody>
                <CardTitle className="mb-0">Request Accepted</CardTitle>

                {data.tenancy.tenancyRequests.length === 1 ? (
                  <p className="mb-2">
                    You have accepted the tenancy, our system is currently
                    processing the tenancy and it will become available from
                    your dashboard in a few moments.
                  </p>
                ) : (
                  <p className="mb-2">
                    You have accepted the tenancy, once every other tenant has
                    signed you will receive a notification and this tenancy will
                    become available from your dashboard.
                  </p>
                )}
              </CardBody>
            </Card>
          ) : (
            <Card className="mt-4">
              {data.rentSplit && data.bondSplit && (
                <div>
                  <CardBody>
                    <CardTitle>Fill Info and Sign</CardTitle>
                    <p className="text-sm text-secondary">
                      Note that your ID information is only used for filling in
                      the lease, we delete this from our servers once the lease
                      is signed.
                    </p>

                    <p className="text-secondary text-sm mt-2">
                      By signing below you confirm that you agree to the terms
                      of the lease and bond.
                    </p>
                    <Formik
                      initialValues={{
                        identificationType: 'Passport',
                        identificationNumber: '',
                        renterSignature: '',
                      }}
                      onSubmit={handleSubmit}
                      validationSchema={Yup.object().shape({
                        identificationType: Yup.string()
                          .required()
                          .oneOf(['Passport', 'Drivers licence'])
                          .label('Identification Type'),
                        identificationNumber: Yup.string()
                          .required()
                          .min(5)
                          .max(12)
                          .label('Identification Number'),
                        renterSignature: Yup.string()
                          .required()
                          .min(1)
                          .label('Signature'),
                      })}
                      validateOnBlur={false}
                      validateOnChange={false}>
                      {(formik) => (
                        <Form>
                          <div className="flex flex-col lg:flex-row justify-between">
                            <div className="flex-1">
                              <SelectField
                                label="Identification Type"
                                name="identificationType"
                                formik={formik}>
                                {['Passport', 'Drivers licence'].map((val) => (
                                  <option
                                    value={val}
                                    key={val}
                                    selected={
                                      formik.values.identificationType == val
                                    }>
                                    {val}
                                  </option>
                                ))}
                              </SelectField>
                            </div>

                            <div className="flex-1 ml-0 lg:ml-2">
                              <InputField
                                label="Identification Number"
                                name="identificationNumber"
                                formik={formik}
                              />
                            </div>
                          </div>

                          <div className="mt-3">
                            <SignatureModalInput
                              mode="formik"
                              name="renterSignature"
                            />
                          </div>

                          <div className="mt-2">
                            <SubmitButton
                              text="Accept Tenancy Request"
                              submittingText="Saving..."
                              formik={formik}
                              color="success"
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </div>
              )}
            </Card>
          )}

          <ChatableMessages
            chatableId={data.tenancy.id}
            chatableType="Tenancy"
            isLandlord={false}
          />
        </PageWrapper>
      );
    } else {
      return (
        <PageWrapper title="Tenancy Request">
          <Card className="mt-4" key={data.id}>
            <div className="alert items-start">
              <div className="flex-1">
                <UserAvatar user={landlord} size="10" />
                <label className="ml-2">
                  <h4 className="font-semibold">{landlord.name}</h4>
                  <p className="text-sm text-base-content text-opacity-60">
                    Sent {moment(data.createdAt).fromNow()}
                  </p>
                </label>
              </div>
            </div>

            <CardBody className="mb-0">
              <strong className="block text-2xl font-semibold">
                {property.streetAddress}
              </strong>
              <span className="block text-sm text-secondary">
                {property.suburb}, {property.city}
              </span>

              {data.tenancy.messageFromLandlord && (
                <div className="mt-2">
                  <span className="block text-secondary">
                    Message from Landlord
                  </span>
                  <p>
                    <em>{data.tenancy.messageFromLandlord}</em>
                  </p>
                </div>
              )}

              <div>
                <p>
                  Your landlord has invited you to start using Keyhook to manage
                  your tenancy. Keyhook provides...
                </p>

                <ul className="ml-4 mt-2 list-disc">
                  <li>Easy maintenance requests</li>
                  <li>Privacy friendly inspections</li>
                  <li>Easy communication with your landlord</li>
                  <li>All your agreements e-signed and stored in one place</li>
                  <li>And lots more</li>
                </ul>
              </div>

              {!data.accepted && (
                <div className="mt-2">
                  <button
                    className="btn btn-success btn-block"
                    onClick={acceptMigratedLease}>
                    Accept Invite
                  </button>
                </div>
              )}
            </CardBody>
          </Card>

          {data.accepted && (
            <Card className="mt-4">
              <CardBody>
                <CardTitle className="mb-0">Invite Accepted</CardTitle>

                {data.tenancy.startDate ? (
                  <div>
                    <p>
                      Congratulations, your landlord has already set a
                      commencement date of{' '}
                      {moment(data.tenancy.startDate).format('DD MMM YYYY')},
                      this is when your payments, inspections and maintenance
                      will all be done through Keyhook. You can view your
                      tenancy information from your Keyhook Dashboard for more
                      information.
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>
                      Congratulations! You're almost ready to use Keyhook. Your
                      landlord is setting a commencement date after which you'll
                      be able to enjoy all of Keyhook's benefits free!
                    </p>

                    <p>
                      Once your landlord has set the commencement date, you will
                      be provided with updated banking information and get
                      access to the full suite of Keyhook features.
                    </p>
                  </div>
                )}
              </CardBody>
            </Card>
          )}
        </PageWrapper>
      );
    }
  }
};

export default TenancyRequestDetailPage;
