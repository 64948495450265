import {Capacitor} from '@capacitor/core';
import {PencilAltIcon, TrashIcon, UsersIcon} from '@heroicons/react/outline';
import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';

import emptyBg from 'assets/img/empty_dataset_images/open_homes.svg';
import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import EmptyDataSet from 'components/common/EmptyDataSet';
import LoadingView from 'components/common/LoadingView';
import ManageListingMenu from 'components/listing/ManageListingMenu';
import PageWrapper from 'components/PageWrapper';
import KeyhookLink from 'components_sb/navigation/Link/Link';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import Listing from 'models/listings/Listing';
import OpenHome from 'models/listings/OpenHome';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';

const OpenHomeIndexPage = () => {
  usePageVisit('OpenHomeIndexPage');
  const {propertyId, listingId} = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {data, isLoading, error} = useQuery(
    `listing-${listingId}-open-homes`,
    async () => {
      const listing = await Listing.select({listings: ['id']})
        .includes('open_homes')
        .order('open_homes.start_time')
        .find(listingId);

      return listing.data;
    },
  );

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const confirmRemoveOpenHome = (openHome: OpenHome) => {
    setConfirmationOptions({
      title: 'Remove Open Home',
      message: 'Are you sure you want to remove this open home?',
      color: 'error',
      buttonTitle: 'Remove',
      action: () => removeOpenHome(openHome),
    });
  };

  const removeOpenHome = async (openHome: OpenHome) => {
    const result = await openHome.destroy();

    if (result) {
      toast.success('Open home has been removed.');

      queryClient.invalidateQueries(`listing-${listingId}-open-homes`);
    }
  };

  const isMobile = !useTailwindBreakpoint('sm');

  const buttons = Capacitor.isNativePlatform()
    ? [{text: 'Add', href: 'new'}]
    : [{text: 'Add Open Home', href: 'new'}];

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Listing Open Homes" buttons={buttons}>
        <ManageListingMenu propertyId={propertyId} listingId={listingId} />
        <LoadingView />
      </PageWrapper>
    );
  } else {
    if (data.openHomes.length > 0) {
      if (isMobile) {
        const upcoming = data.openHomes.filter((oh) =>
          moment(oh.startTime).isAfter(moment()),
        );
        const past = data.openHomes.filter((oh) =>
          moment(oh.startTime).isBefore(moment()),
        );
        return (
          <PageWrapper title="Listing Open Homes" buttons={buttons}>
            <ManageListingMenu propertyId={propertyId} listingId={listingId} />
            <Card className="mt-4">
              <CardBody>
                {upcoming.length > 0 && (
                  <div>
                    <CardTitle>Upcoming Open Homes</CardTitle>

                    {upcoming.map((oh) => (
                      <Card className="mt-2" key={oh.id}>
                        <CardBody>
                          <div className="flex flex-col text-sm space-y-2">
                            <strong>
                              {moment(oh.startTime).format(
                                'dddd DD MMM hh:mm a',
                              )}{' '}
                              -
                              {moment(oh.endTime).format('dddd DD MMM hh:mm a')}
                            </strong>
                            <p>
                              <KeyhookLink to={oh.id}>
                                Attendees: {oh.openHomeAttendeesCount || 0}
                              </KeyhookLink>
                            </p>

                            <div className="flex justify-between items-center">
                              <Link
                                to={`${oh.id}/edit`}
                                className="btn btn-sm btn-neutral">
                                Change Time
                              </Link>

                              <a
                                onClick={() => confirmRemoveOpenHome(oh)}
                                className="btn btn-sm btn-neutral">
                                Remove
                              </a>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    ))}
                  </div>
                )}

                {past.length > 0 && (
                  <div>
                    <CardTitle>Past Open Homes</CardTitle>

                    {upcoming.map((oh) => (
                      <Card className="mt-2" key={oh.id}>
                        <CardBody>
                          <div className="flex flex-col text-sm space-y-2">
                            <strong>
                              {moment(oh.startTime).format(
                                'dddd DD MMM hh:mm a',
                              )}{' '}
                              -
                              {moment(oh.endTime).format('dddd DD MMM hh:mm a')}
                            </strong>
                            <p>
                              <KeyhookLink to={oh.id}>
                                Attendees: {oh.openHomeAttendeesCount || 0}
                              </KeyhookLink>
                            </p>
                          </div>
                        </CardBody>
                      </Card>
                    ))}
                  </div>
                )}
              </CardBody>
            </Card>
          </PageWrapper>
        );
      } else {
        const upcoming = data.openHomes.filter((oh) =>
          moment(oh.startTime).isAfter(moment()),
        );
        const past = data.openHomes.filter((oh) =>
          moment(oh.startTime).isBefore(moment()),
        );
        return (
          <PageWrapper title="Listing Open Homes" buttons={buttons}>
            <ManageListingMenu propertyId={propertyId} listingId={listingId} />
            <Card className="mt-4">
              <CardBody>
                {upcoming.length > 0 && (
                  <div>
                    <CardTitle>Upcoming Open Homes</CardTitle>

                    <div className="overflow-x-auto mt-2">
                      <table className="table table-zebra w-full">
                        <thead>
                          <tr>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th># Attending</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {upcoming.map((oh) => (
                            <tr key={oh.id}>
                              <td>
                                {moment(oh.startTime).format(
                                  'dddd DD MMM hh:mm a',
                                )}
                              </td>
                              <td>
                                {moment(oh.endTime).format(
                                  'dddd DD MMM hh:mm a',
                                )}
                              </td>
                              <td>{oh.openHomeAttendeesCount || 0}</td>
                              <td>
                                <div className="flex justify-start gap-4">
                                  <Link
                                    to={oh.id}
                                    className="tooltip"
                                    data-tip="View Attendees">
                                    <UsersIcon className="w-5 h-5" />
                                  </Link>

                                  <Link
                                    to={`${oh.id}/edit`}
                                    className="tooltip"
                                    data-tip="Change Time">
                                    <PencilAltIcon className="w-5 h-5" />
                                  </Link>

                                  <a
                                    onClick={() => confirmRemoveOpenHome(oh)}
                                    className="cursor-pointer tooltip"
                                    data-tip="Remove Open Home">
                                    <TrashIcon className="w-5 h-5" />
                                  </a>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {past.length > 0 && (
                  <div>
                    <CardTitle>Past Open Homes</CardTitle>

                    <div className="overflow-x-auto mt-2">
                      <table className="table table-zebra w-full">
                        <thead>
                          <tr>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th># Attending</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {past.map((oh) => (
                            <tr key={oh.id}>
                              <td>
                                {moment(oh.startTime).format(
                                  'dddd DD MMM hh:mm a',
                                )}
                              </td>
                              <td>
                                {moment(oh.endTime).format(
                                  'dddd DD MMM hh:mm a',
                                )}
                              </td>
                              <td>{oh.openHomeAttendeesCount || 0}</td>
                              <td>
                                <div className="flex justify-start gap-4">
                                  <Link
                                    to={oh.id}
                                    className="tooltip"
                                    data-tip="View Attendees">
                                    <UsersIcon className="w-5 h-5" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </PageWrapper>
        );
      }
    } else {
      return (
        <PageWrapper title="Listing Open Homes" buttons={buttons}>
          <ManageListingMenu propertyId={propertyId} listingId={listingId} />
          <EmptyDataSet
            title="No Open Homes"
            message="This listing does not have any open homes set, click the button below to add some."
            image={emptyBg}
            buttonTitle="Add Open Home"
            buttonAction={() => navigate('new')}
          />
        </PageWrapper>
      );
    }
  }
};

export default OpenHomeIndexPage;
