import {useEffect, useState} from 'react';

import {type FormikHelpers, Formik, Form} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import {YesNoField, CountableNumberField} from 'components/forms_fields';
import PetTypeField from 'components/forms_fields/PetTypeField';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightFooter from 'components/walkthrough/common/RightFooter';
import RightSideText from 'components/walkthrough/common/RightSideText';
import Property from 'models/properties/Property';
import TrackingService from 'services/TrackingService';
import {isDefined} from 'utilities/MiscHelpers';

type FormValues = {
  petsAllowed: boolean;
  allowedPetTypes: string[];
  smokersAllowed: boolean;
  maxTenants: number;
};

const RequirementsStep = ({
  property,
  stepNumber,
  totalSteps,
}: {
  property: Property;
  stepNumber: number;
  totalSteps: number;
}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_StartPropertyRequirementsStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = async (
    formValues: FormValues,
    _actions: FormikHelpers<FormValues>,
  ) => {
    setIsLoading(true);

    property.assignAttributes(formValues);
    property.lastOnboardingStepCompleted = 'new_requirements';

    await property.save();

    /**
     * Track completion of the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_CompletePropertyRequirementsStep,
      {
        propertyId: property.id,
      },
    );

    setIsLoading(false);
    queryClient.setQueryData(['new-property', property.id], property);
  };

  const previousStep = async () => {
    property.lastOnboardingStepCompleted = 'new_financials';
    await property.save();

    queryClient.setQueryData(['new-property', property.id], property);
  };

  const title = 'Select your property requirements';

  return (
    <Container>
      <LeftSide title={title} subtitle="" />
      <RightContainer>
        <RightSideText title={title} />
        <Formik
          initialValues={
            {
              petsAllowed: isDefined(property.petsAllowed)
                ? property.petsAllowed
                : null,
              allowedPetTypes: property.allowedPetTypes || [],
              smokersAllowed: isDefined(property.smokersAllowed)
                ? property.smokersAllowed
                : null,
              maxTenants: property.maxTenants || 1,
            } as FormValues
          }
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            petsAllowed: Yup.boolean().required().label('Pets Allowed?'),
            allowedPetTypes: Yup.array()
              .optional()
              .nullable()
              .label('Allowed Pet Types')
              .min(0),
            smokersAllowed: Yup.boolean()
              .nullable()
              .required()
              .label('Smokers Allowed?'),

            maxTenants: Yup.number()
              .min(1)
              .max(20)
              .required()
              .label('Max Number of Tenants'),
          })}>
          {(formik) => (
            <Form>
              <div className="flex flex-col md:mx-16 space-y-8">
                <YesNoField
                  formik={formik}
                  name="petsAllowed"
                  label="Pets allowed?"
                />

                {formik.values.petsAllowed && <PetTypeField formik={formik} />}

                <YesNoField
                  formik={formik}
                  name="smokersAllowed"
                  label="Smoking allowed?"
                />

                <CountableNumberField
                  formik={formik}
                  name="maxTenants"
                  label="Max tenants"
                />
              </div>
              <ProgressBox
                nextStep={formik.submitForm}
                previousStep={previousStep}
                step={stepNumber}
                totalSteps={totalSteps}
                previousStepEnabled
                nextStepEnabled={!isLoading}
              />
            </Form>
          )}
        </Formik>
      </RightContainer>
    </Container>
  );
};

export default RequirementsStep;
