import {createElement, useMemo} from 'react';

import clsx from 'clsx';

import Paragraph from '../Paragraph/Paragraph';

const classes = {
  // Classes based on level
  level: {
    h1: {
      base: 'text-2xl xs:text-3xl lg:text-4xl font-bold',
      margin: 'mb-5 xs:mb-6',
    },
    h2: {
      base: 'text-2xl xs:text-3xl font-bold',
      margin: 'mb-4 xs:mb-5',
    },
    h3: {
      base: 'text-xl xs:text-2xl font-semibold',
      margin: 'mb-3 xs:mb-4',
    },
    h4: {
      base: 'text-lg xs:text-xl font-semibold',
      margin: 'mb-2 xs:mb-3',
    },
    h5: {
      base: 'text-lg xs:text-xl font-medium',
      margin: 'mb-2',
    },
    h6: {
      base: 'text-base xs:text-lg font-medium',
      margin: 'mb-1',
    },
  },
  // Classes based on color
  color: {
    brand: 'text-brand-500',
    light: 'text-brand-0',
    dark: 'text-brand-900',
  },
};

interface TitleProps {
  /**
   *  The text shown as the link.
   */
  children: string;
  /**
   *  The color of the title.
   */
  level: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  /**
   *  The color of the title.
   */
  color?: 'brand' | 'light' | 'dark';
  /**
   * Supporting text to append below the title.
   */
  subtitle?: string;
  /**
   * Disables the standard title margin.
   */
  disableMargin?: boolean;
}

/**
 *  A heading for use at the start of a page or section.
 */
const Title = ({
  children,
  level,
  color = 'dark',
  subtitle,
  disableMargin = false,
}: TitleProps) => {
  const hasSubtitle = useMemo(() => !!subtitle, [subtitle]);
  const element = useMemo(
    () =>
      createElement(
        level,
        {
          className: clsx(
            classes.level[level].base,
            !hasSubtitle && !disableMargin && classes.level[level].margin,
            classes.color[color],
          ),
        },
        children,
      ),
    [children, level, color],
  );
  return hasSubtitle ? (
    <div
      className={clsx(
        'flex flex-col gap-y-6',
        !disableMargin && classes.level[level].margin,
      )}>
      {element}
      <Paragraph disableMargin>{subtitle}</Paragraph>
    </div>
  ) : (
    element
  );
};

export default Title;
