import {Capacitor} from '@capacitor/core';
import {ArrowLeftIcon, ChevronDownIcon} from '@heroicons/react/outline';
import _ from 'lodash';
import {Link, useLocation, useNavigate} from 'react-router-dom';

import ProOnlyButton from 'components/subscription/ProOnlyButton';
import {Title} from 'components_sb/typography';

export interface HeaderButton {
  text: string;
  href?: string;
  onClick?: any;
  bgColor?: string;
  isPro?: boolean;
}

const Header = ({
  title,
  buttons,
  showButtonsAsDropDown = false,
  dropdownTitle,
  backEnabled = false,
  condensed = false,
}: {
  title: string;
  buttons?: HeaderButton[];
  showButtonsAsDropDown?: boolean;
  dropdownTitle?: string;
  backEnabled?: boolean;
  condensed?: boolean;
}) => {
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const goBack = () => {
    // Remove the scroll position if you go back.
    // Doesnt make sense to restore it when going forward again to that route..
    if (Capacitor.isNativePlatform()) {
      sessionStorage.removeItem(pathname);
    }
    navigate(-1);
  };

  const stickyClasses = Capacitor.isNativePlatform() ? `sticky top-0 z-10` : '';

  const buttonProps = (button: object) => {
    return _.omit(button, 'bgColor');
  };

  return (
    <header
      data-tour="page-header"
      className={`w-full bg-white border-brand-50 border-b-2 ${stickyClasses}`}
      id="page-header">
      <div className="flex justify-between items-center max-w-7xl mx-auto py-2 lg:py-6 px-4 sm:px-6 lg:px-8">
        <div className="flex justify-start items-center gap-4">
          {backEnabled && (
            <a
              className="bg-gray-100 p-2 lg:p-3 rounded-full cursor-pointer"
              onClick={goBack}>
              <ArrowLeftIcon className="w-3 h-3 lg:w-4 lg:h-4 text-gray-900 bg-grey-50" />
            </a>
          )}
          <Title
            level={Capacitor.isNativePlatform() ? 'h3' : 'h1'}
            disableMargin>
            {title}
          </Title>
        </div>
        {buttons && buttons.length > 0 && !showButtonsAsDropDown && (
          <div>
            {buttons.map((button, index) =>
              button.onClick ? (
                <button
                  className={`btn btn-xs md:btn-sm btn-${
                    button.bgColor ? button.bgColor : 'neutral'
                  } mr-2`}
                  onClick={button.onClick}
                  key={index}
                  {...buttonProps(button)}>
                  {button.text}
                </button>
              ) : (
                <Link
                  to={button.href}
                  key={index}
                  {...buttonProps(button)}
                  className={`btn btn-xs md:btn-sm btn-${
                    button.bgColor ? button.bgColor : 'neutral'
                  } mr-2`}>
                  {button.text}
                </Link>
              ),
            )}
          </div>
        )}

        {buttons && buttons.length > 0 && showButtonsAsDropDown && (
          <div className="dropdown dropdown-hover dropdown-end">
            <label
              tabIndex={0}
              className="m-1 btn btn-neutral btn-xs md:btn-sm">
              {dropdownTitle}{' '}
              <ChevronDownIcon className="w-4 h-4 text-white ml-4" />
            </label>
            <ul
              tabIndex={0}
              className="p-2 shadow menu dropdown-content top-full bg-base-100 rounded-box w-52">
              {buttons.map((button, index) => (
                <li key={index}>
                  {button.href ? (
                    <Link to={button.href}>{button.text}</Link>
                  ) : (
                    <a onClick={button.onClick}>{button.text}</a>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
