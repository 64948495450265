import keyhookLogoBlack from 'assets/img/logo/logo-black-text.png';

const RightSideText = ({title, subtitle}: {title: string; subtitle?: any}) => {
  return (
    <div className="xl:hidden mb-8 flex flex-col items-center">
      <h1 className="font-bold text-3xl font-san text-center">{title}</h1>
      {subtitle && <p className="mt-1 text-center">{subtitle}</p>}
    </div>
  );
};

export default RightSideText;
