import React, {HTMLProps} from 'react';

import {type FormikProps} from 'formik';

import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import {InlineError} from 'components_sb/feedback';
import TrackingService from 'services/TrackingService';

interface InlineSelectFieldProps extends HTMLProps<HTMLSelectElement> {
  formik: FormikProps<any>;
  helpText?: string;
}
const InlineSelectField = ({
  formik,
  label,
  name,
  children,
  helpText,
  ...rest
}: InlineSelectFieldProps) => {
  return (
    <div>
      <div className="flex justify-between items-center">
        {label && (
          <div>
            <span className="text-xl md:text-3xl font-medium text-neutral-800 dark:text-neutral-200">
              <span>{label}</span>
              <HelpTextPresenter helpText={helpText} />
            </span>
          </div>
        )}

        <select
          name={name}
          onChange={(e) => {
            formik.setFieldValue(name, e.target.value);
            TrackingService.trackEvent(TrackingService.Event.SelectOption, {
              field: name,
            });
          }}
          value={formik.values[name]}
          {...rest}>
          {children}
        </select>
      </div>
      <InlineError error={formik.errors[name]} />
    </div>
  );
};

export default InlineSelectField;
