import {ReactNode} from 'react';

const CardBody = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return <div className={`card-body p-5 lg:p-8 ${className}`}>{children}</div>;
};

export default CardBody;
