import {useEffect, useState} from 'react';

import bankAccountValidator from '@fnzc/nz-bank-account-validator';
import {type FormikHelpers, Formik, Form} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import {InputField} from 'components/forms_fields';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightSideText from 'components/walkthrough/common/RightSideText';
import Property from 'models/properties/Property';
import TrackingService from 'services/TrackingService';

type FormValues = {
  bankAccountName: string;
  bankAccountNumber: string;
};

const BankingStep = ({property}: {property: Property}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.MigrateTenancy_StartRentCollectionStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    setIsLoading(true);

    const prop = property;

    prop.assignAttributes(formValues);
    property.lastOnboardingStepCompleted = 'migrate_banking';

    const result = await prop.save();

    if (result) {
      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.MigrateTenancy_CompleteRentCollectionStep,
        {
          propertyId: property.id,
        },
      );

      queryClient.setQueryData(['new-property', property.id], property);
    } else {
      for (const key of Object.keys(property.errors)) {
        const message = property.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    setIsLoading(false);
  };

  const goToPrevious = async () => {
    setIsLoading(true);
    property.lastOnboardingStepCompleted = 'migrate_rent_information';
    await property.save();

    queryClient.setQueryData(['new-property', property.id], property);
    setIsLoading(false);
  };

  const title = 'Enter your rent collecting account';
  const subtitle = 'Where should rent be deposited?';

  return (
    <Container>
      <LeftSide title={title} subtitle={subtitle} />
      <RightContainer>
        <RightSideText title={title} subtitle={subtitle} />
        <Formik
          initialValues={
            {
              bankAccountName: property.bankAccountName || '',
              bankAccountNumber: property.bankAccountNumber || '',
            } as FormValues
          }
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            bankAccountName: Yup.string()
              .min(2)
              .required()
              .label('Account Name'),
            bankAccountNumber: Yup.string()
              .required()
              .test(
                'is-valid-format',
                'Must be a valid NZ account. Please include dashes.',
                (value) => {
                  if (!value) return false;

                  const parts = value.split('-');
                  if (parts.length === 4) {
                    return bankAccountValidator.isValidNZBankNumber(
                      parts[0],
                      parts[1],
                      parts[2],
                      parts[3],
                    );
                  } else {
                    return false;
                  }
                },
              )
              .label('Account Number'),
          })}>
          {(formik) => (
            <Form className="mx-0 lg:mx-16">
              <InputField
                label="Account Name"
                name="bankAccountName"
                formik={formik}
                placeholder="eg: John Smith"
                autoCapitalize="words"
                className="!rounded-full"
              />
              <InputField
                label="Account Number (include dashes)"
                name="bankAccountNumber"
                formik={formik}
                placeholder="eg: 02-1234-5678900-00"
                autoCapitalize="words"
                className="!rounded-full"
              />

              <p className="mt-4 text-sm text-secondary">
                To learn more about how rent payment works,{' '}
                <a
                  className="link link-primary"
                  href="https://help.keyhook.com/financials/how-do-i-collect-my-rent-payments-using-keyhook"
                  target="_blank"
                  rel="noreferrer">
                  click here.
                </a>
              </p>

              <ProgressBox
                nextStep={formik.submitForm}
                previousStep={goToPrevious}
                step={5}
                totalSteps={7}
                previousStepEnabled
                nextStepEnabled={!isLoading}
              />
            </Form>
          )}
        </Formik>
      </RightContainer>
    </Container>
  );
};

export default BankingStep;
