import {
  createContext,
  FunctionComponent,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {Disclosure, Transition} from '@headlessui/react';
import {HiChevronDown} from '@react-icons/all-files/hi/HiChevronDown';
import clsx from 'clsx';
import {Collapse} from 'react-collapse';
import {Link as RouterLink, useLocation, useNavigate} from 'react-router-dom';
import {current} from 'tailwindcss/colors';

import UserAvatar from 'components/user/UserAvatar';
import {Button} from 'components_sb/buttons';
import {NumberBadge} from 'components_sb/feedback';
import {Divider, ScrollContainer} from 'components_sb/layout';
import useLinkComponent from 'hooks/useLinkComponent';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import useAuth from 'services/useAuth';
import {NavLink, NavCategory, NavItem} from 'utilities/nav-links/types';

interface DrawerProvider {
  (props: {
    /**
     * The underlying child content that the drawer will be
     * displayed on top of.
     */
    children: ReactNode;
  }): JSX.Element;
}

/**
 * The context used for setting the content of the drawer.
 */
const Context = createContext({
  navItems: [],
  setNavItems: null,
  setBreakpoint: null,
});

/**
 * Returns whether the drawer is open as indicatd by the
 * checked value on the checkbox input element.
 */
const isOpen = () => {
  const element = document.getElementById('mobile-drawer') as HTMLInputElement;
  return !!element && element.checked;
};

/**
 * Closes the drawer by disabling the checked value on the
 * checkbox input element.
 */
const close = () => {
  const element = document.getElementById('mobile-drawer') as HTMLInputElement;
  if (element) {
    element.checked = false;
  }
};

/**
 * A drawer that opens from the side of the screen and
 * overlays the page content.
 */
const Provider: DrawerProvider = ({children}) => {
  /**
   * Create the state for the nav item and breakpoint settings.
   */
  const [navItems, setNavItems] = useState([]);
  const [breakpoint, setBreakpoint] = useState(null);

  /**
   * Create the context value to pass to the provider.
   */
  const value = useMemo(
    () => ({
      navItems,
      setNavItems,
      setBreakpoint,
    }),
    [navItems, setNavItems, setBreakpoint],
  );

  const breakpointActive = useTailwindBreakpoint(breakpoint);

  const disableDrawer = useMemo(() => {
    return !breakpoint || breakpointActive;
  }, [breakpoint, breakpointActive]);

  /**
   * Close the drawer upon route change.
   */
  const location = useLocation();
  useEffect(() => {
    if (!disableDrawer && isOpen()) {
      close();
    }
  }, [disableDrawer, location]);

  return (
    <Context.Provider value={value}>
      <div className="drawer drawer-end">
        {/* The input element below controls the open state of the drawer */}
        <input id="mobile-drawer" type="checkbox" className="drawer-toggle" />
        {/* The content that the drawer renders on top of is passed through */}
        <div className="drawer-content">{children}</div>
        <div className="drawer-side">
          {/* The label element below acts as the transparent overlay of the drawer
          and is used to close the drawer upon click */}
          <label htmlFor="mobile-drawer" className="drawer-overlay"></label>
          {/* Draw content is set when the useMobileDrawer hook is used and is
          rendered below*/}
          <div className="bg-white flex flex-col max-h-screen">
            <DrawerContent />
          </div>
        </div>
      </div>
    </Context.Provider>
  );
};

/**
 * Clicking this button will open the mobile menu drawer.
 */
const ToggleButton = ({breakpoint}: {breakpoint: string}) => {
  const disableDrawer = useTailwindBreakpoint(breakpoint);
  return disableDrawer ? null : (
    <Button
      label="Menu"
      category="secondary"
      size="base"
      mode="html-label"
      htmlFor="mobile-drawer"
    />
  );
};

const classes = {
  navItem: {
    main: clsx(
      'w-full px-6 h-16 flex items-center gap-x-2',
      'bg-white text-brand-500',
      'active:bg-brand-25',
      'transition-all duration-200',
      'group',
    ),
    label: clsx(
      'leading-none',
      'transition-all duration-200',
      'scale-100 group-active:scale-95',
      'flex flex-row gap-x-2 items-center',
    ),
  },
};

/**
 * A button for expanding and displaying sub nav link items
 * within a category.
 */
const NavCategoryButton = ({label, subItems, badgeCount}: NavCategory) => {
  /**
   * Determines the expanded state of the sub nav link items.
   */
  const [isOpened, setIsOpened] = useState(false);

  /**
   * Expand or collase the sub nav link items.
   */
  const toggle = useCallback(
    () => setIsOpened((current) => !current),
    [setIsOpened],
  );

  return (
    <>
      <button className={classes.navItem.main} onClick={toggle}>
        <span className={classes.navItem.label}>
          <span>{label}</span>
          <span>
            <HiChevronDown
              className={clsx(
                'w-5 h-5',
                'transition-transform duration-300',
                isOpened ? 'rotate-180' : 'rotate-0',
              )}
            />
          </span>
          <NumberBadge count={badgeCount} positioning="inline" />
        </span>
      </button>
      <Collapse isOpened={isOpened}>
        {/* TODO: Update level by adding 1 to previous level */}
        <NavItems navItems={subItems} level={1} />
      </Collapse>
    </>
  );
};

/**
 * A button for navigating to a nav link location.
 */
const NavLinkButton = ({label, to, badgeCount}: NavLink) => {
  const Component = useLinkComponent(to);
  return (
    <Component to={to} className={classes.navItem.main}>
      <span className={classes.navItem.label}>{label}</span>
      <NumberBadge count={badgeCount} positioning="inline" />
    </Component>
  );
};

const NavItems = ({navItems, level}: {navItems: NavItem[]; level: number}) => (
  <div style={{paddingLeft: level * 10}}>
    {navItems.map((navItem) => {
      return navItem.subItems ? (
        <NavCategoryButton key={navItem.id} {...(navItem as NavCategory)} />
      ) : (
        <NavLinkButton key={navItem.id} {...(navItem as NavLink)} />
      );
    })}
  </div>
);

const DrawerContent = () => {
  const {currentUser, logOutUser} = useAuth();
  const {navItems} = useContext(Context);

  const onLogOutClicked = useCallback(() => {
    logOutUser();
  }, [logOutUser]);

  return (
    <div className="flex-1 flex flex-col min-w-64 max-h-full">
      {/* User details and options */}
      {currentUser && (
        <>
          <div className="p-6 flex flex-col gap-y-4 bg-brand-50">
            <div className="flex flex-row items-center gap-x-4">
              <UserAvatar user={currentUser} size={14} />
              <div className="flex flex-col gap-y-1 leading-none text-brand-900">
                <span className="text-bold">{currentUser.name}</span>
                <span className="text-sm opacity-70">{currentUser.email}</span>
              </div>
            </div>
            <div className="flex flex-row gap-x-2">
              <Button
                label="My Account"
                category="secondary"
                size="sm"
                mode="link"
                to="/account/my-account"
              />
              <Button
                label="Settings"
                category="secondary"
                size="sm"
                mode="link"
                to="/account/settings"
              />
            </div>
          </div>
          <Divider disableMargin />
        </>
      )}

      {/* Navigation link items */}
      <ScrollContainer>
        {navItems && <NavItems navItems={navItems} level={0} />}
      </ScrollContainer>
      <Divider disableMargin />

      <div className="flex flex-col gap-y-4 p-6">
        {/* Log in and register buttons */}
        {!currentUser && (
          <>
            <Button
              label="Register"
              category="primary"
              size="sm"
              mode="link"
              to="/register"
            />
            <Button
              label="Log in"
              category="secondary"
              size="sm"
              mode="link"
              to="/login"
            />
          </>
        )}
        <Button
          label="Close menu"
          category="secondary"
          size="sm"
          mode="manual"
          onClick={close}
        />
        {/* Log out button */}
        {currentUser && (
          <Button
            label="Log out"
            category="secondary"
            size="sm"
            mode="manual"
            onClick={onLogOutClicked}
          />
        )}
      </div>
    </div>
  );
};

/**
 * This hook enables accessing context from a component deeper in the
 * component tree than the provider (where the content is rendered).
 * This enables properties only accessilble from these deeper levels
 * to then be utilised within the context of the drawer content.
 */
export const useMobileDrawer = ({
  breakpoint,
  navItems,
}: {
  breakpoint: string;
  navItems: NavItem[];
}) => {
  /**
   * Access state functions from the provider.
   */
  const {setNavItems, setBreakpoint} = useContext(Context);

  /**
   * Set the breakpoint on the provider (and update if it changes).
   */
  useEffect(() => setBreakpoint(breakpoint), [breakpoint, setBreakpoint]);

  /**
   * Set the content on the provider (and update if links change).
   */
  useEffect(() => setNavItems(navItems), [setNavItems, navItems]);

  /**
   * Returning the toggle button as part of the hook ensures that
   * the toggle button can only be rendered when the drawer content
   * has been set.
   */
  return <ToggleButton breakpoint={breakpoint} />;
};

export default {
  Provider,
  close,
};
