import React, {useEffect, useState} from 'react';

import {Capacitor} from '@capacitor/core';
import {SearchIcon} from '@heroicons/react/outline';
import {Form, Formik, type FormikHelpers} from 'formik';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {DateField, SelectField, SubmitButton} from 'components/forms_fields';
import ActionCard from 'components/property/landlord/profile_blocks/ActionCard';
import ProfileItemModal from 'components/property/landlord/profile_blocks/ProfileItemModal';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';

const InspectionSettingsAction = ({
  property,
  tenancy,
  storeKey,
}: {
  property: Property;
  tenancy: Tenancy;
  storeKey: string;
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  // I dont know why the date field is focused when the modal is shown
  // But this fixes that.
  useEffect(() => {
    if (modalVisible) {
      setTimeout(() => {
        const els = document.querySelectorAll('.rdt');
        if (els.length > 0) {
          const el = els[0];
          el.classList.remove('rdtOpen');
          (el.firstChild as HTMLInputElement).blur();
        }
      }, 50);
    }
  }, [modalVisible]);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleMigrateSubmit = async (
    formValues: any,
    actions: FormikHelpers<any>,
  ) => {
    const prop = property;

    tenancy.nextInspectionDate = formValues.nextInspectionDate;
    const result1 = await tenancy.save();

    if (result1) {
      prop.inspectionPeriod = formValues.inspectionPeriod;
      prop.markStepAsCompleted(storeKey);

      const result = await prop.save();

      if (result) {
        // Invalidate so that it loads the inspection
        queryClient.invalidateQueries(`landlord-property-detail-${prop.id}`);
        toast.success('Property successfully updated!');
        setModalVisible(false);
      } else {
        for (const key of Object.keys(prop.errors)) {
          const message = prop.errors[key].fullMessage;
          actions.setFieldError(key, message);
        }
      }
    } else {
      actions.setFieldError(
        'nextInspectionDate',
        tenancy.errors.nextInspectionDate.fullMessage,
      );
    }

    actions.setSubmitting(false);
  };

  const handleNewSubmit = async () => {
    property.markStepAsCompleted(storeKey);
    const result = await property.save();
    if (result) {
      queryClient.invalidateQueries(`landlord-property-detail-${property.id}`);
      toast.success('Property successfully updated!');
      setModalVisible(false);
    }
  };

  const showEditInspectionTemplate = () => {
    if (Capacitor.isNativePlatform()) {
      let path = location.pathname;
      if (!path.endsWith('/')) {
        path += '/';
      }
      path += 'inspection-template';
      navigate(path);
    } else {
      window.open(`${window.location.href}/inspection-template`, '_blank');
    }
  };

  const title = 'Inspection Settings';
  const subtitle = 'Configure how inspections are conducted within Keyhook.';
  const iconBgColor = 'bg-emerald-300';

  if (tenancy.isNew) {
    return (
      <div>
        <ActionCard
          title={title}
          subtitle={subtitle}
          IconClass={SearchIcon}
          iconBgColor={iconBgColor}
          onClick={() => setModalVisible(true)}
        />
        <ProfileItemModal
          modalIsOpen={modalVisible}
          setModalIsOpen={setModalVisible}
          title={title}
          subtitle={subtitle}
          IconClass={SearchIcon}
          bgColor={iconBgColor}
          form={
            <div className="md:h-full flex-1">
              <div className="flex flex-col justify-between h-full">
                <div className="flex flex-col justify-center grow space-y-8">
                  <div>
                    <p className="text-sm">
                      Click the button below to go to the your inspection plan
                      editor. This will allow you to customise the template used
                      for your inspections.
                    </p>
                    <a
                      onClick={showEditInspectionTemplate}
                      className="btn btn-neutral btn-block mt-2">
                      Inspection Plan Editor
                    </a>
                  </div>
                </div>

                <button
                  className={`btn btn-block !rounded-full ${iconBgColor} border-none mt-6`}
                  onClick={handleNewSubmit}>
                  Save
                </button>
              </div>
            </div>
          }
        />
      </div>
    );
  } else {
    return (
      <div>
        <ActionCard
          title={title}
          subtitle={subtitle}
          IconClass={SearchIcon}
          iconBgColor={iconBgColor}
          onClick={() => setModalVisible(true)}
        />
        <ProfileItemModal
          modalIsOpen={modalVisible}
          setModalIsOpen={setModalVisible}
          title={title}
          subtitle={subtitle}
          IconClass={SearchIcon}
          bgColor={iconBgColor}
          form={
            <Formik
              initialValues={{
                inspectionPeriod: property.inspectionPeriod || 3,
                nextInspectionDate: undefined,
              }}
              onSubmit={handleMigrateSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                inspectionPeriod: Yup.number()
                  .min(1)
                  .max(6)
                  .required()
                  .label('Inspection Frequency'),
                nextInspectionDate: Yup.string()
                  .required()
                  .optional()
                  .label('Next Inspection Date'),
              })}>
              {(formik) => (
                <Form className="md:h-full flex-1">
                  <div className="flex flex-col justify-between h-full">
                    <div className="flex flex-col justify-center grow space-y-8">
                      <div>
                        <DateField
                          name="nextInspectionDate"
                          label="Next Inspection Date"
                          formik={formik}
                          minDate={new Date()}
                          maxDate={new Date('2100-01-01')}
                        />
                      </div>

                      <div>
                        <SelectField
                          name="inspectionPeriod"
                          formik={formik}
                          label="Inspections to be held every..."
                          helpText="Insurance policies typically require inspections to happen every 3 months.">
                          <option value="1">1 Month</option>
                          <option value="2">2 Months</option>
                          <option value="3">3 Months</option>
                          <option value="4">4 Months</option>
                          <option value="5">5 Months</option>
                          <option value="6">6 Months</option>
                        </SelectField>
                      </div>

                      <div>
                        <a
                          onClick={showEditInspectionTemplate}
                          className="btn btn-info btn-block mt-2">
                          Manage your inspection plan
                        </a>
                      </div>
                    </div>

                    <SubmitButton
                      formik={formik}
                      text="Save"
                      submittingText="Saving"
                      className={`!rounded-full ${iconBgColor} border-none mt-6`}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          }
        />
      </div>
    );
  }
};

export default InspectionSettingsAction;
