import React from 'react';

import moment from 'moment';

import UserAvatar from 'components/user/UserAvatar';
import InspectionSignOff from 'models/inspections/InspectionSignOff';
import {DATE_FORMAT} from 'utilities/DateHelpers';

// Ideally change this later to not have a hardcoded min width
// and instead the parent implements a responsive grid
const SignOffView = ({signOff}: {signOff: InspectionSignOff}) => (
  <div className="border-gray-100 border-2 shadow-lg rounded-lg min-w-[300px] pl-4 pr-4 pt-2">
    {signOff.signature && (
      <img src={signOff.signature} className="w-auto h-[80px] mb-4" />
    )}
    <div className="flex justify-start mb-2">
      <div className="mr-2">
        <UserAvatar user={signOff.user} size="12" />
      </div>
      <div>
        <strong className="block">{signOff.user.name}</strong>
        <small className="block text-secondary mb-0">
          Date: {moment(signOff.createdAt).format(DATE_FORMAT)}
        </small>
      </div>
    </div>
  </div>
);

export default SignOffView;
