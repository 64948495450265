import {useEffect, useState} from 'react';

import {type FormikHelpers, Formik, Form, type FormikProps} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import catIcon from 'assets/img/icons/pet_types/cat.png';
import largeDogIcon from 'assets/img/icons/pet_types/large_dog.png';
import negotiableIcon from 'assets/img/icons/pet_types/negotiable.png';
import smallDogIcon from 'assets/img/icons/pet_types/small_dog.png';
import {YesNoField, CountableNumberField} from 'components/forms_fields';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightSideText from 'components/walkthrough/common/RightSideText';
import Property from 'models/properties/Property';
import TrackingService from 'services/TrackingService';
import {isDefined} from 'utilities/MiscHelpers';

type FormValues = {
  petsAllowed: boolean;
  allowedPetTypes: string[];
  smokingAllowed: boolean;
  maxTenants: number;
};

const RequirementsStep = ({property}: {property: Property}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_StartPropertyRequirementsStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const listing = property.listings[0];

  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = async (
    formValues: FormValues,
    _actions: FormikHelpers<FormValues>,
  ) => {
    setIsLoading(true);

    property.assignAttributes(formValues);
    property.smokersAllowed = formValues.smokingAllowed;
    property.lastOnboardingStepCompleted = 'listing_requirements';

    await property.save();

    listing.assignAttributes(formValues);
    await listing.save();

    /**
     * Track completion of the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_CompletePropertyRequirementsStep,
      {
        propertyId: property.id,
      },
    );

    setIsLoading(false);
    queryClient.setQueryData('new-listing-wizard', property);
  };

  const previousStep = async () => {
    property.lastOnboardingStepCompleted = 'listing_property_type';
    await property.save();

    queryClient.setQueryData('new-listing-wizard', property);
  };

  const title = 'Select your property requirements';

  const togglePetType = (formik: FormikProps<FormValues>, petType: string) => {
    let types = formik.values.allowedPetTypes as string[];
    if (types.includes(petType)) {
      types = types.filter((t) => t !== petType);
    } else {
      types.push(petType);
    }

    formik.setFieldValue('allowedPetTypes', types);
  };

  const petTypes = [
    {value: 'cat', label: 'Cat', icon: catIcon},
    {value: 'small_dog', label: 'Small Dog', icon: smallDogIcon},
    {value: 'large_dog', label: 'Large Dog', icon: largeDogIcon},
    {value: 'negotiable', label: 'Negotiable', icon: negotiableIcon},
  ];

  return (
    <Container>
      <LeftSide title={title} subtitle="" />
      <RightContainer>
        <RightSideText title={title} />
        <Formik
          initialValues={
            {
              petsAllowed: isDefined(listing.petsAllowed)
                ? listing.petsAllowed
                : null,
              allowedPetTypes: listing.allowedPetTypes || [],
              smokingAllowed: isDefined(listing.smokingAllowed)
                ? listing.smokingAllowed
                : null,
              maxTenants: listing.maxTenants || 1,
            } as FormValues
          }
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            petsAllowed: Yup.boolean().required().label('Pets Allowed?'),
            allowedPetTypes: Yup.array()
              .optional()
              .nullable()
              .label('Allowed Pet Types')
              .min(0),
            smokingAllowed: Yup.boolean()
              .nullable()
              .required()
              .label('Smokers Allowed?'),

            maxTenants: Yup.number()
              .min(1)
              .max(20)
              .required()
              .label('Max Number of Tenants'),
          })}>
          {(formik) => (
            <Form>
              <div className="flex flex-col md:mx-16 space-y-8">
                <YesNoField
                  formik={formik}
                  name="petsAllowed"
                  label="Pets allowed?"
                />

                {formik.values.petsAllowed && (
                  <div className="flex flex-col md:flex-row justify-between space-y-2 md:space-y-0 items-center">
                    {petTypes.map((pet) => {
                      const isSelected = formik.values.allowedPetTypes.includes(
                        pet.value,
                      );
                      return (
                        <button
                          key={pet.value}
                          type="button"
                          className={`btn sm:btn-sm md:btn-md gap-2 ${
                            isSelected ? 'btn-neutral' : 'btn-outline'
                          }`}
                          onClick={() => togglePetType(formik, pet.value)}>
                          <img
                            src={pet.icon}
                            className={`w-6 h-6 ${isSelected ? 'invert' : ''}`}
                          />
                          {pet.label}
                        </button>
                      );
                    })}
                  </div>
                )}

                <YesNoField
                  formik={formik}
                  name="smokingAllowed"
                  label="Smoking allowed?"
                />

                <CountableNumberField
                  formik={formik}
                  name="maxTenants"
                  label="Max tenants"
                />
              </div>
              <ProgressBox
                nextStep={formik.submitForm}
                previousStep={previousStep}
                step={3}
                previousStepEnabled
                nextStepEnabled={!isLoading}
              />
            </Form>
          )}
        </Formik>
      </RightContainer>
    </Container>
  );
};

export default RequirementsStep;
