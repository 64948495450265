import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {App as CapacitorApp} from '@capacitor/app';
import {Capacitor} from '@capacitor/core';
import {
  ScreenOrientation,
  OrientationType,
} from '@capawesome/capacitor-screen-orientation';
import {AnimatePresence} from 'framer-motion';
import {
  Routes,
  Route,
  BrowserRouter,
  useLocation,
  Navigate,
  useNavigate,
} from 'react-router-dom';

import LoadingView from 'components/common/LoadingView';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import MobileDrawer from 'components/navbar/MobileDrawer';
import LandlordRoute from 'components/routing/LandlordRoute';
import LoggedInRoute from 'components/routing/LoggedInRoute';
import RenterRoute from 'components/routing/RenterRoute';
import SubscriptionRequiredModal from 'components/subscription/SubscriptionRequiredModal';
import {Toaster} from 'components_sb/feedback';
import {Modal} from 'components_sb/layout';
import EmptyLayout from 'components_sb/layout/EmptyLayout/EmptyLayout';
import MainLayout from 'components_sb/layout/MainLayout/MainLayout';
import NativeLayout from 'components_sb/layout/NativeLayout/NativeLayout';
import useCustomerly from 'hooks/useCustomerly';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import NewPropertyPage from 'pages/landlord/properties/NewPropertyPage';
import PropertyTransferConfirmationPage from 'pages/landlord/property_transfers/PropertyTransferConfirmationPage';
import ConfirmEmailPage from 'pages/shared/auth/ConfirmEmailPage';
import SetRealEmailPage from 'pages/shared/auth/SetRealEmailPage';
import ChatDetailPage from 'pages/shared/chat/ChatDetailPage';
import ChatIndexPage from 'pages/shared/chat/ChatIndexPage';
import NewChatPage from 'pages/shared/chat/NewChatPage';
import DocumentPreviewPage from 'pages/shared/documents/DocumentPreviewPage';
import NotFoundPage from 'pages/shared/errors/NotFoundPage';
import GhostPage from 'pages/shared/ghost/GhostPage';
import InspectionCameraPage from 'pages/shared/inspections/InspectionCameraPage';
import ListingDetailPage from 'pages/shared/listings/ListingDetailPage';
import ListingIndexPage from 'pages/shared/listings/ListingIndexPage';
import ListingSearchPage from 'pages/shared/listings/ListingSearchPage';
import NewListingWalkthroughPage from 'pages/shared/listings/NewListingWalkthroughPage';
import PublicServiceRequestDetailPage from 'pages/shared/service_requests/PublicServiceRequestDetailPage';
import SettingsAccountPage from 'pages/shared/settings/SettingsAccountPage';
import SettingsAvatarPage from 'pages/shared/settings/SettingsAvatarPage';
import SettingsIndexPage from 'pages/shared/settings/SettingsIndexPage';
import SettingsLandlordProfilePage from 'pages/shared/settings/SettingsLandlordProfilePage';
import SettingsNotificationsPage from 'pages/shared/settings/SettingsNotificationsPage';
import SettingsRenterProfilePage from 'pages/shared/settings/SettingsRenterProfilePage';
import DemoRoutes from 'routes/DemoRoutes';
import LandlordRoutes from 'routes/LandlordRoutes';
import LegacyAuthRouteRedirects from 'routes/LegacyAuthRouteRedirects';
import LoggedOutRoutes from 'routes/LoggedOutRoutes';
import RenterRoutes from 'routes/RenterRoutes';
import UserRoutes from 'routes/UserRoutes';
import useAuth from 'services/useAuth';

import 'react-datetime/css/react-datetime.css';
import 'react-toggle/style.css';
import '@glidejs/glide/dist/css/glide.core.css';
import 'leaflet/dist/leaflet.css';
import 'react-toastify/dist/ReactToastify.min.css';

/**
 *  Manually sets the viewport height CSS variable based on the window size.
 *  (to resolve issues with mobile viewports when 100vh is used)
 *  Refer to: https://www.markusantonwolf.com/blog/solution-to-the-mobile-viewport-height-issue-with-tailwind-css/
 *
 * This messes with the Keyboard in Capacitor, so it gets disabled there.
 */
const setViewHeight = () => {
  if (!Capacitor.isNativePlatform()) {
    const viewporHeight = window.innerHeight * 0.01;
    document.documentElement.style.setProperty(
      '--viewport-height',
      `${viewporHeight}px`,
    );
  }
};

/**
 * Detect viewport height before initial mount.
 */
setViewHeight();

const App = () => {
  /**
   * Whether the app is currently being built for native.
   */
  const isNative = useMemo(() => Capacitor.isNativePlatform(), []);

  /**
   * Force vertical orientation for mobile when the app first loads.
   */
  useEffect(() => {
    if (isNative) {
      ScreenOrientation.lock({type: OrientationType.PORTRAIT_PRIMARY});
    }
  }, [isNative]);

  /**
   * Add listener on mount to detect viewport height when the window is resized.
   */
  useEffect(() => {
    setViewHeight();
    window.addEventListener('resize', () => {
      setViewHeight();
    });
  }, []);

  /**
   * Adds a listener to wire up android back button presses.
   */
  const navigate = useNavigate();
  useEffect(() => {
    CapacitorApp.addListener('backButton', () => {
      navigate(-1);
    });

    return () => {
      CapacitorApp.removeAllListeners();
    };
  }, [navigate]);

  const {userIsLoggedIn} = useAuth();

  const location = useLocation();

  /**
   * Obtain (or also initially configure) the user's settings in local storage.
   */
  const {activeAccountRole} = useLocalUserSettings();

  /**
   * Handle configuring Customerly.
   */
  useCustomerly();

  return (
    <>
      {/* <AnimatePresence mode="wait" initial={false}> */}
      <Routes location={location} key={location.pathname}>
        {/* Enable the page for taking inspection photos dfirectly for native builds only */}
        {isNative && (
          <Route
            path="/properties/:propertyId/inspections/:inspectionId/:roomKey/camera"
            element={<InspectionCameraPage />}
          />
        )}

        {/* Due to how the page is laid out, we dont want the native layout inteferring with these routes */}
        {activeAccountRole === 'Landlord' && (
          <Route element={isNative ? <EmptyLayout /> : <MainLayout />}>
            <Route path="properties/new" element={<NewPropertyPage />} />
            <Route
              path="listings/new"
              element={<NewListingWalkthroughPage />}
            />
          </Route>
        )}

        <Route element={isNative ? <NativeLayout /> : <MainLayout />}>
          {/* Logged out routes can only be accessed when there is no currently logged in user */}
          {LoggedOutRoutes}
          {/* Redirects to the new auth routes */}
          {LegacyAuthRouteRedirects}

          <Route path="ghost" element={<GhostPage />} />

          <Route path="jobs">
            <Route path=":id" element={<PublicServiceRequestDetailPage />} />
          </Route>

          <Route path="property-transfers">
            <Route
              path=":confirmationToken"
              element={<PropertyTransferConfirmationPage />}
            />
          </Route>

          <Route path="listings">
            <Route path="search" element={<ListingSearchPage />} />
            <Route path=":id" element={<ListingDetailPage />} />
            <Route path="" element={<ListingIndexPage />} />
          </Route>

          {/* Authenticated Routes --- REQUIRES current user -- */}
          <Route element={<LoggedInRoute />}>
            {/* The set and confirm email pages are under the /register path but are not grouped with the
                  other register routes as these require the user to be logged in */}
            <Route path="register">
              {/* Set real email address for when Apple ID auth returns private relay address */}
              <Route path="set-email" element={<SetRealEmailPage />} />

              {/* Confirm email address by entering the code sent via email */}
              <Route path="confirm-email" element={<ConfirmEmailPage />} />
            </Route>

            <Route path="chat">
              <Route path="new" element={<NewChatPage />} />
              <Route path=":roomKey" element={<ChatDetailPage />} />
              <Route path="" element={<ChatIndexPage />} />
            </Route>

            <Route path="settings">
              <Route path="account" element={<SettingsAccountPage />} />
              <Route path="avatar" element={<SettingsAvatarPage />} />
              <Route
                path="notifications"
                element={<SettingsNotificationsPage />}
              />
              <Route
                path="landlord-profile"
                element={<SettingsLandlordProfilePage />}
              />
              <Route
                path="renter-profile"
                element={<SettingsRenterProfilePage />}
              />

              <Route path="" element={<SettingsIndexPage />} />
            </Route>

            <Route path="document-previews" element={<DocumentPreviewPage />} />

            {/* User Routes, Requires Authentication. */}
            {UserRoutes}

            {activeAccountRole === 'Landlord' && (
              <Route element={<LandlordRoute />}>
                {LandlordRoutes.concat(DemoRoutes)}
              </Route>
            )}

            {activeAccountRole === 'Renter' && (
              <Route element={<RenterRoute />}>{RenterRoutes}</Route>
            )}
          </Route>
          {!userIsLoggedIn && (
            <Route path="/" element={<Navigate to="/welcome" />} />
          )}
          <Route path="/*" element={<NotFoundPage />} />
        </Route>
      </Routes>
      {/* </AnimatePresence> */}

      <ConfirmationModal />
    </>
  );
};

export default App;
