import {ReactNode} from 'react';

import clsx from 'clsx';

interface ParagraphProps {
  /**
   *  The text for the paragraph.
   */
  children: string | ReactNode;
  /**
   * Decreases the emphasis of the paragraph visually.
   */
  secondary?: boolean;
  /**
   * Disables the standard paragraph margin.
   */
  disableMargin?: boolean;
}

/**
 *  Standard body text.
 */
const Paragraph = ({
  children,
  secondary = false,
  disableMargin = false,
}: ParagraphProps) => (
  <p
    className={clsx(
      'text-brand-900 text-base font-normal',
      !disableMargin && 'mb-2',
      secondary && 'text-opacity-70',
    )}>
    {children}
  </p>
);

export default Paragraph;
