import {useState} from 'react';

import {Formik, Form, type FormikHelpers, type FormikProps} from 'formik';
import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import DocumentPreviewModal from 'components/document/DocumentPreviewModal';
import {
  DateField,
  InputField,
  SubmitButton,
  SelectField,
} from 'components/forms_fields';
import SignatureModalInput from 'components/forms_fields/SignatureModalInput';
import PageWrapper from 'components/PageWrapper';
import LeaseConditions from 'components/tenancy/LeaseConditions';
import {statusForRenewal} from 'components/tenancy/RenewalStatusCard';
import {API_URL} from 'globals/app-globals';
import {Clause} from 'models/properties/ClauseLibrary';
import TenancyContinuation from 'models/properties/TenancyContinuation';
import useAuth from 'services/useAuth';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';
import {removeUnderscores, titleize} from 'utilities/StringHelpers';

const EditTenancyRenewalPage = () => {
  useTitle('Tenancy Renewal');
  usePageVisit('EditTenancyRenewalPage');

  const {propertyId, id} = useParams();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const {currentUser} = useAuth();

  const [selectedOption, setSelectedOption] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const [leaseUrl, setLeaseUrl] = useState(null);

  const [documentModalVisible, setDocumentModalVisible] = useState(false);

  const {data, isLoading, error} = useQuery(
    `landlord-tenancy-renewal-${id}`,
    async () => {
      const renewal = await TenancyContinuation.includes({
        tenancy: 'property',
      }).find(id);

      return renewal.data;
    },
  );
  const queryClient = useQueryClient();

  const handleGoPeriodic = async () => {
    setIsSaving(true);
    const renewal = data;
    renewal.landlordRequestedAction = 'go_periodic';

    const result = await renewal?.save();

    if (result) {
      queryClient.setQueryData(`landlord-tenancy-renewal-${id}`, renewal);
      queryClient.invalidateQueries(`landlord-property-detail-${propertyId}`);

      toast.success('Renewal option successfully saved!');
    }
    setIsSaving(false);
  };

  const handleStartNewLeaseTenancy = async (formValues: any, actions: any) => {
    const renewal = data;
    renewal.landlordRequestedAction = 'start_new_lease';

    renewal.assignAttributes(formValues);

    const result = await renewal.save();

    if (result) {
      queryClient.setQueryData(`landlord-tenancy-renewal-${id}`, renewal);
      queryClient.invalidateQueries(`landlord-property-detail-${propertyId}`);

      toast.success('Renewal option successfully saved!');
    } else {
      for (const key of Object.keys(renewal.errors)) {
        const error = renewal.errors[key];
        actions.setFieldError(key, error?.fullMessage);
      }
    }

    actions.setSubmitting(false);
  };

  const handleRentSubmit = async (
    formValues: any,
    actions: FormikHelpers<any>,
  ) => {
    const renewal = data;
    renewal.newTotalRent = formValues.newTotalRent;

    const result = await renewal.save();

    if (result) {
      queryClient.setQueryData(`landlord-tenancy-renewal-${id}`, renewal);
      queryClient.invalidateQueries(`landlord-property-detail-${propertyId}`);

      toast.success('Rent change successfully saved!');
    } else {
      for (const key of Object.keys(renewal.errors)) {
        const error = renewal.errors[key];
        actions.setFieldError(key, error?.fullMessage);
      }
    }

    actions.setSubmitting(false);
  };

  const keepSameRent = (formik: FormikProps<any>) => {
    formik.setFieldValue('newTotalRent', data.tenancy.totalRent);
  };

  const confirmUndoDecision = () => {
    setConfirmationOptions({
      title: 'Undo Renewal Choice',
      message: 'Are you sure you want to undo your choice for this renewal?',
      color: 'error',
      buttonTitle: 'Undo',
      action: undoDecision,
    });
  };

  const undoDecision = async () => {
    const renewal = data;

    renewal.landlordRequestedAction = null;
    renewal.tenantChosenAction = null;

    const result = await renewal.save();

    if (result) {
      queryClient.setQueryData(`landlord-tenancy-renewal-${id}`, renewal);
      queryClient.invalidateQueries(`landlord-property-detail-${propertyId}`);

      toast.success('Renewal choice has been undone.');
    } else {
      toast.error('There was an issue undoing your renewal choice.');
    }
  };

  const quickSetEndDate = (formik: FormikProps<any>, numMonths: number) => {
    if (data.tenancy.endDate) {
      const ed = moment(data.tenancy.endDate);
      const endDate = ed.add(numMonths, 'months').format('YYYY-MM-DD');
      formik.setFieldValue('endDate', endDate);
    }
  };

  const reuseConditions = (formik: FormikProps<any>) => {
    formik.setFieldValue('customClauses', data.tenancy.customClauses);
    formik.setFieldValue('clauses', data.tenancy.clauses);
  };

  const downloadLease = (formik: FormikProps<any>) => {
    const leaseParams = {...formik.values};
    leaseParams.customClauses = leaseParams.customClauses.clauses;

    fetch(`${API_URL}/tenancy_continuations/${id}/lease_preview.pdf?`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-USER-TOKEN': currentUser.meta.authenticationToken,
        'X-USER-EMAIL': currentUser.email,
      },
      body: JSON.stringify(leaseParams),
    })
      .then((response) => {
        return response.arrayBuffer();
      })
      .then((data) => {
        setLeaseUrl(data);
        setDocumentModalVisible(true);
      });
  };

  const cardForRenewalType = () => {
    if (data?.landlordRequestedAction) {
      const title = titleize(removeUnderscores(data.landlordRequestedAction));
      const status = statusForRenewal(data);
      return (
        <Card>
          <CardBody>
            <CardTitle className="mb-0">Renewal Option: {title}</CardTitle>
            <p className="mb-2">{status}</p>

            {!data.allTenantsSigned && (
              <div>
                <button
                  type="button"
                  className="btn btn-error mt-4"
                  onClick={confirmUndoDecision}>
                  Undo Renewal Choice
                </button>
              </div>
            )}
          </CardBody>
        </Card>
      );
    }

    if (!data.newTotalRent) {
      const ed = moment(data.tenancy.endDate);

      let minDate: Date;

      if (moment().add(60, 'days').isSameOrBefore(ed)) {
        minDate = ed.toDate();
      } else {
        minDate = ed.add(60, 'days').toDate();
      }

      const maxDate = ed.add(6, 'months').toDate();

      const dateFilter = (
        currentDate: moment.Moment,
        _selectedDate: moment.Moment,
      ) => {
        if (
          currentDate.isAfter(moment(minDate)) &&
          currentDate.isBefore(moment(maxDate))
        ) {
          return currentDate.format('dddd') === data.tenancy.dayOfWeekRentPaid;
        } else {
          return false;
        }
      };

      return (
        <Card className="mt-4 overflow-visible">
          <CardBody>
            <CardTitle>Give notice for changing rent</CardTitle>
            <p>
              Before you choose a renewal option, it is recommended to give your
              60 day notice if you would like to change the rent. No notice will
              be sent if you keep the same rent amount. Otherwise Keyhook will
              send a formal notice to your tenant(s).
            </p>

            <Formik
              initialValues={{
                newTotalRent: undefined,
                rentChangeEffeciveDate: '',
              }}
              onSubmit={handleRentSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                newTotalRent: Yup.number()
                  .min(1)
                  .required()
                  .label('New Rent Amount'),
                rentChangeEffectiveDate: Yup.string()
                  .optional()
                  .test(
                    'is-required',
                    'The effective date is required if you are changing the rent',
                    function (value) {
                      if (!this.parent.newTotalRent) {
                        return true;
                      }
                      if (this.parent.newTotalRent != data.tenancy.totalRent) {
                        return !!value;
                      } else {
                        return true;
                      }
                    },
                  )
                  .label('Effective Date'),
              })}>
              {(formik) => {
                return (
                  <Form>
                    <div className="flex justify-between">
                      <div className="flex-1 mr-2">
                        <InputField
                          label="New Rent Amount"
                          name="newTotalRent"
                          formik={formik}
                          placeholder={`Your current rent is $${data.tenancy.totalRent}`}
                          type="number"
                        />
                      </div>

                      <div className="flex-1 ml-2">
                        <DateField
                          label="Effective Date"
                          name="rentChangeEffectiveDate"
                          formik={formik}
                          minDate={minDate}
                          maxDate={maxDate}
                          isValidDate={dateFilter}
                          helpText="Any dates that are before required notice periods are greyed out, this field is only required if you are changing the rent."
                        />
                      </div>
                    </div>

                    <button
                      className="btn btn-neutral mt-4"
                      type="button"
                      onClick={() => keepSameRent(formik)}>
                      Keep the same rent
                    </button>

                    <div className="mt-4">
                      <SubmitButton
                        color="success"
                        formik={formik}
                        text="Give notice of rent change"
                        submittingText="Saving"
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </CardBody>
        </Card>
      );
    }

    if (selectedOption === '') {
      return (
        <Card className="mt-4 overflow-visible">
          <CardBody>
            <CardTitle>Select a Renewal Option</CardTitle>
            <p>
              Please select a renewal option from the list below. If you do not
              choose an option, your tenancy will change to being periodic
              automatically after the end date.
            </p>
            <p>
              Make sure to discuss this with your tenant(s) prior to choosing an
              option.
            </p>

            <div className="flex justify-between mt-2">
              <div className="mr-2 w-1/2">
                <button
                  className="btn btn-neutral w-full"
                  type="button"
                  onClick={() => setSelectedOption('periodic')}>
                  Go Periodic
                </button>
              </div>

              <div className="ml-2 w-1/2">
                <button
                  className="btn btn-neutral w-full"
                  type="button"
                  onClick={() => setSelectedOption('startNewLease')}>
                  Start New Fixed Term Lease
                </button>
              </div>
            </div>
          </CardBody>
        </Card>
      );
    } else if (selectedOption === 'periodic') {
      return (
        <Card className="mt-4 overflow-visible">
          <CardBody>
            <CardTitle className="mb-0">Go Periodic</CardTitle>

            <p className="mb-2">
              Periodic leases have no fixed end date and are subject to notice
              periods for terminating the lease.
            </p>

            <p>
              Click the button below to lock in your decision, this will then be
              sent to the head tenant for approval.
            </p>

            <div className="mt-2">
              <button
                className="btn btn-success btn-block"
                onClick={handleGoPeriodic}
                disabled={isSaving}>
                Go Periodic
              </button>

              <div className="mt-3">
                <button
                  className="btn btn-secondary btn-block"
                  onClick={() => setSelectedOption('')}>
                  Choose a different option
                </button>
              </div>
            </div>
          </CardBody>
        </Card>
      );
    } else if (selectedOption === 'startNewLease') {
      const minEndDate = moment(data?.tenancy.endDate).add(7, 'days').toDate();
      return (
        <Card className="mt-3 overflow-visible">
          <CardBody>
            <CardTitle className="mb-0">Start new fixed term lease</CardTitle>
            <p className="mb-2">
              Easily put together a new lease and send it to to your tenants to
              sign.
            </p>
            <div>
              <Formik
                initialValues={{
                  endDate: '',
                  landlordSignature: '',
                  leaseDocumentType: 'keyhook_new_zealand',
                  customClauses: {
                    clauses: [] as string[],
                  },
                  clauses: [] as Clause[],
                }}
                onSubmit={handleStartNewLeaseTenancy}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={Yup.object().shape({
                  endDate: Yup.date()
                    .required()
                    .label('End Date')
                    .test(
                      'should-be-greather-than-previous-end-date',
                      'Should be after the previous end date',
                      (value) => {
                        if (!value) {
                          return true;
                        }
                        const endDate = data.tenancy.endDate;
                        if (!endDate) {
                          return true;
                        }

                        return moment(value) > moment(endDate);
                      },
                    ),
                  landlordSignature: Yup.string()
                    .required()
                    .min(1)
                    .label('Signature'),
                  leaseDocumentType: Yup.string()
                    .required()
                    .oneOf(['keyhook_new_zealand', 'tenancy_services'])
                    .label('Lease Type'),
                  customClauses: Yup.object()
                    .shape({
                      clauses: Yup.array().of(
                        Yup.string().min(1).label('Condition'),
                      ),
                    })
                    .label('Conditions'),
                })}>
                {(formik) => {
                  return (
                    <Form>
                      <DateField
                        label="End Date"
                        name="endDate"
                        formik={formik}
                        minDate={minEndDate}
                        maxDate={moment().add(100, 'years').toDate()}>
                        <div className="dropdown dropdown-end">
                          <label
                            tabIndex={0}
                            className="btn !rounded-l-none !rounded-r">
                            Quick Set
                          </label>
                          <ul
                            tabIndex={0}
                            className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                            {[6, 12, 24].map((numMonths) => (
                              <li key={numMonths}>
                                <a
                                  onClick={() =>
                                    quickSetEndDate(formik, numMonths)
                                  }>
                                  {numMonths} Months
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </DateField>

                      <div className="form-group">
                        <SelectField
                          name="leaseDocumentType"
                          formik={formik}
                          label="Lease Type"
                          helpText="Choose the lease template you wish to use. We recommend using the Keyhook Lease. You can review and edit your lease below.">
                          <option value="keyhook_new_zealand">
                            Keyhook Lease
                          </option>
                          <option value="tenancy_services">
                            Tenancy Services
                          </option>
                        </SelectField>
                      </div>

                      <div className="mt-4">
                        <LeaseConditions
                          property={data.tenancy.property}
                          formik={formik}
                        />

                        {data.tenancy.isNew && (
                          <button
                            type="button"
                            className="btn btn-info btn-block mt-2"
                            onClick={() => reuseConditions(formik)}>
                            Reuse Conditions From Current Lease
                          </button>
                        )}
                      </div>

                      <div className="mt-8">
                        <p className="mb-2">
                          Once the fields above have been filled. click the
                          button below to preview the new lease and then draw
                          your signature below to sign it if you are satisified.
                        </p>

                        <button
                          className="btn btn-neutral btn-sm"
                          type="button"
                          onClick={() => {
                            if (leaseUrl) {
                              setDocumentModalVisible(true);
                            } else {
                              downloadLease(formik);
                            }
                          }}>
                          Preview Lease
                        </button>

                        {leaseUrl && (
                          <DocumentPreviewModal
                            isVisible={documentModalVisible}
                            setIsVisible={setDocumentModalVisible}
                            documentUrl={leaseUrl}
                            title="Preview New Lease"
                          />
                        )}
                      </div>

                      <div className="mt-2">
                        <SignatureModalInput
                          mode="formik"
                          name="landlordSignature"
                        />
                      </div>

                      <div className="mt-4">
                        <SubmitButton
                          color="success"
                          formik={formik}
                          text="Save Renewal"
                          submittingText="Saving"
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>

            <div className="mt-3">
              <button
                className="btn btn-secondary btn-block"
                onClick={() => setSelectedOption('')}
                type="button">
                Choose a different option
              </button>
            </div>
          </CardBody>
        </Card>
      );
    } else {
      return null;
    }
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper
        title="Tenancy Renewal"
        buttons={[
          {
            text: 'Back To Property',
            href: `/properties/${propertyId}`,
            bgColor: 'neutral',
          },
        ]}>
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper
        title="Tenancy Renewal"
        buttons={[
          {
            text: 'Back To Property',
            href: `/properties/${propertyId}`,
            bgColor: 'neutral',
          },
        ]}>
        {cardForRenewalType()}
      </PageWrapper>
    );
  }
};

export default EditTenancyRenewalPage;
