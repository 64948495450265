import {useNavigate} from 'react-router-dom';

import Listing from 'models/listings/Listing';

const DraftListingWarning = ({listing}: {listing: Listing}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const existingAddress = localStorage.getItem('new-listing-address');
    if (existingAddress) {
      const address = JSON.parse(existingAddress);
      if (address.address1 !== listing.streetAddress) {
        const newAddress = {address1: listing.streetAddress};
        localStorage.setItem('new-listing-address', JSON.stringify(newAddress));
      }
    } else {
      const newAddress = {address1: listing.streetAddress};
      localStorage.setItem('new-listing-address', JSON.stringify(newAddress));
    }

    navigate('/listings/new');
  };

  if (listing.status === 'draft') {
    return (
      <div className="mt-4">
        <div
          className="alert alert-warning shadow-lg cursor-pointer"
          onClick={handleClick}>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-current flex-shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
            Your listing has not been published, click here to finish creating
            your listing.
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default DraftListingWarning;
