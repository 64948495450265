import {useState} from 'react';

import {Form, Formik, type FormikHelpers} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {Navigate, useNavigate, useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import {InputField, SelectField, SubmitButton} from 'components/forms_fields';
import PageWrapper from 'components/PageWrapper';
import {InlineError} from 'components_sb/feedback';
import PreferredTradesman from 'models/service_requests/PreferredTradesman';
import ServiceRequest from 'models/service_requests/ServiceRequest';
import useAuth from 'services/useAuth';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

interface FormValues {
  name: string;
  email: string | null;
  phoneNumber: string | null;
  tradeCategory: string;
  propertyId: string;
}

const EditPreferredTradesmanPage = () => {
  useTitle('Edit Preferred Tradesperson');
  usePageVisit('EditPreferredTradesmanPage');

  const {propertyId, id} = useParams();
  const {currentUser} = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [customTradeCategory, setCustomTradeCategory] = useState('');

  if (!currentUser.meta.isPaid) {
    return <Navigate to="/" />;
  }

  const {isLoading, error, data} = useQuery(
    `preferred-tradespeople-${id}`,
    async () => {
      const tradie = await PreferredTradesman.find(id);
      return tradie.data;
    },
  );

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    if (values.tradeCategory === 'Other' && customTradeCategory.length === 0) {
      actions.setFieldError(
        'tradeCategory',
        'Other trade category is required',
      );
    } else {
      const tradie = data;
      tradie.assignAttributes(values);
      if (values.tradeCategory === 'Other') {
        tradie.tradeCategory = customTradeCategory;
      }

      const result = await tradie.save();

      if (result) {
        toast.success('Preferred tradesperson was successfully updated!');
        queryClient.invalidateQueries(
          `property-${propertyId}-preferred-tradespeople`,
        );
        navigate(`/properties/${propertyId}/preferred-tradespeople`);
      } else {
        for (const field in tradie.errors) {
          const error = tradie.errors[field];
          actions.setFieldError(field, error?.fullMessage);
        }
      }
    }

    actions.setSubmitting(false);
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Edit Preferred Tradesperson">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    const categories = [...ServiceRequest.categoryTypes];
    if (!categories.includes(data.tradeCategory)) {
      categories.unshift(data.tradeCategory);
    }

    return (
      <PageWrapper title="Edit Preferred Tradesperson">
        <Card>
          <CardBody>
            <CardTitle>Edit preferred tradesperson</CardTitle>
            <p className="mb-4">
              Preferred tradespeople are tradespeople that Keyhook will try to
              arrange quotes from first when you have approved a maintenance
              request. You can add multiple tradespeople for the same job
              category.
            </p>

            <Formik
              initialValues={data!.attributes}
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={Yup.object().shape(
                {
                  name: Yup.string()
                    .required()
                    .min(2)
                    .label('Tradesman or Company name'),
                  email: Yup.string()
                    .email()
                    .label('Email Adress')
                    .when('phoneNumber', {
                      is: (phone: any) => !phone || phone.length === 0,
                      then: Yup.string()
                        .email()
                        .required()
                        .label('Email Address'),
                      otherwise: Yup.string(),
                    }),
                  phoneNumber: Yup.string()
                    .min(9)
                    .label('Phone Number')
                    .when('email', {
                      is: (email: any) => !email || email.length === 0,
                      then: Yup.string()
                        .required()
                        .min(9)
                        .label('Phone Number'),
                      otherwise: Yup.string(),
                    }),
                  tradeCategory: Yup.string()
                    .required()
                    .label('Trade Category'),
                  propertyId: Yup.string().required().label('Property Id'),
                },
                [['email', 'phoneNumber']],
              )}>
              {(formik) => (
                <Form>
                  <InputField
                    formik={formik}
                    name="name"
                    label="Tradesmen Name or Company Name"
                  />
                  <div className="flex justify-between">
                    <div className="flex-1 mr-2">
                      <InputField
                        formik={formik}
                        name="email"
                        label="Email Address"
                      />
                    </div>
                    <div className="flex-1 ml-2">
                      <InputField
                        formik={formik}
                        name="phoneNumber"
                        label="Phone Number"
                      />
                    </div>
                  </div>
                  <small className="block mt-2 text-secondary">
                    Note you only need to provide an email address OR a phone
                    number
                  </small>

                  <SelectField
                    formik={formik}
                    name="tradeCategory"
                    label="Trade Category">
                    {categories.map((category) => (
                      <option value={category} key={category}>
                        {category}
                      </option>
                    ))}
                  </SelectField>

                  {formik.values.tradeCategory === 'Other' && (
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">
                          Other (Please Specify)
                        </span>
                      </label>
                      <div className="input-group">
                        <input
                          onChange={(e) =>
                            setCustomTradeCategory(e.target.value)
                          }
                          value={customTradeCategory}
                          className="input input-bordered w-full input-rounded"
                        />
                      </div>
                      <InlineError error={formik.errors.tradeCategory} />
                    </div>
                  )}

                  <SubmitButton
                    formik={formik}
                    text="Update Tradesman"
                    submittingText="Update Tradesman..."
                    className="mt-3"
                    block
                  />
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </PageWrapper>
    );
  }

  return <div></div>;
};

export default EditPreferredTradesmanPage;
