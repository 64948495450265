import React, {useRef, useState} from 'react';

import {
  FilePondFile,
  LoadServerConfigFunction,
  ProcessServerConfigFunction,
  registerPlugin,
  RemoveServerConfigFunction,
} from 'filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import {Form, Formik, type FormikHelpers} from 'formik';
import {FilePond} from 'react-filepond';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import {
  DateField,
  InputField,
  SubmitButton,
  TextareaField,
} from 'components/forms_fields';
import ListingTagsModalField from 'components/forms_fields/ListingTagsModalField';
import ManageListingMenu from 'components/listing/ManageListingMenu';
import PageWrapper from 'components/PageWrapper';
import {API_URL, TARGET_ENV} from 'globals/app-globals';
import PresignResponse from 'helpers/PresignResponse';
import Listing from 'models/listings/Listing';
import ListingPhoto from 'models/listings/ListingPhoto';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import useAuth from 'services/useAuth';

type EditListingFormValues = {
  title?: string;
  description: string;
  startDate: string;
  endDate: string | null;
  rentAmount: number;
  bondAmount: number;
  rentalPeriod: string;
  garageType: string | null;
  tags: string[];
  listing_photos: any[];
};

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
);

const EditListingPage = () => {
  usePageVisit('EditListingPage');
  const {propertyId, listingId} = useParams();

  const {currentUser} = useAuth();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const pond = useRef(null);
  const [isUploadingPhotos, setIsUploadingPhotos] = useState(false);

  const {data, error, isLoading} = useQuery(
    `listing-${listingId}-detail-manage`,
    async () => {
      const listing = await Listing.includes('listing_photos')
        .order({'listing_photos.order_index': 'asc'})
        .find(listingId);

      return listing.data;
    },
    {
      cacheTime: 0,
    },
  );

  const handleSubmit = async (
    formValues: EditListingFormValues,
    actions: FormikHelpers<EditListingFormValues>,
  ) => {
    const values = {...formValues};
    const listing = data.dup();

    if ((values.bondAmount as any) === '') {
      delete values.bondAmount;
    }

    const photos: FilePondFile[] = values.listing_photos;

    listing.assignAttributes(values);

    listing.listingPhotos = photos.map((p, index) => {
      const id = p.getMetadata('photoId');
      if (id) {
        const photo = data.listingPhotos.find((ph) => ph.id === id);
        photo.orderIndex = index;

        return photo;
      } else {
        const photo = new ListingPhoto();
        photo.photo = JSON.stringify({
          id: p.serverId,
          storage: 'cache',
          metadata: {
            size: p.file.size,
            filename: p.file.name,
            mime_type: p.file.type,
          },
        });
        photo.orderIndex = index;
        photo.listingId = listing.id;
        return photo;
      }
    });

    await Promise.all(
      listing.listingPhotos.map(async (ph) => {
        return await ph.save();
      }),
    );

    const result = await listing.save();
    if (result) {
      toast.success('Listing successfully updated!');
      await queryClient.invalidateQueries(`listing-${listingId}-detail-manage`);
      navigate(`/listings/${listing.publicId}`);
    } else {
      toast.error('There was an issue trying to save your listing.');
      for (const field in listing.errors) {
        const error = listing.errors[field];
        actions.setFieldError(field, error?.fullMessage);
      }
      console.log(listing.errors);
    }

    actions.setSubmitting(false);
  };

  const uploadPhoto: ProcessServerConfigFunction = async (
    _fieldName,
    file,
    _metadata,
    load,
    error,
    progress,
    abort,
    _transfer,
    _options,
  ) => {
    const extension = file.type.split('/').pop();
    const filename = `${Date.now()}.${extension}`;

    const formdata = new FormData();
    formdata.append('file', file);

    if (TARGET_ENV === 'development') {
      const request = new XMLHttpRequest();
      request.open(
        'POST',
        `${API_URL}/uploads/listing_attachment.json?property_id=${propertyId}`,
      );

      request.setRequestHeader(
        'X-USER-TOKEN',
        currentUser.meta.authenticationToken,
      );
      request.setRequestHeader('X-USER-EMAIL', currentUser.email);

      request.upload.onprogress = (e) => {
        progress(e.lengthComputable, e.loaded, e.total);
      };

      request.onload = function () {
        if (request.status >= 200 && request.status < 300) {
          // the load method accepts either a string (id) or an object
          const data = JSON.parse(request.responseText);
          load(data.id);
        } else {
          // Can call the error method if something is wrong, should exit after
          error('oh no');
        }
      };

      request.send(formdata);

      return {
        abort: () => {
          // This function is entered if the user has tapped the cancel button
          request.abort();

          // Let FilePond know the request has been cancelled
          abort();
        },
      };
    } else {
      const presignResponse = await fetch(
        `${API_URL}/presigns/listing_attachment.json?property_id=${propertyId}&filename=${filename}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-USER-TOKEN': currentUser.meta.authenticationToken,
            'X-USER-EMAIL': currentUser?.email,
          },
        },
      );
      const presignInfo = (await presignResponse.json()) as PresignResponse;
      if (presignInfo) {
        const request = new XMLHttpRequest();
        request.open(presignInfo.method, presignInfo.url);

        for (const [key, value] of Object.entries(presignInfo.headers)) {
          request.setRequestHeader(key, value);
        }

        request.upload.onprogress = (e) => {
          progress(e.lengthComputable, e.loaded, e.total);
        };

        request.onload = function () {
          if (request.status >= 200 && request.status < 300) {
            // the load method accepts either a string (id) or an object

            const url = new URL(presignInfo.url);
            const id = url.pathname.split('/').pop();

            load(id);
          } else {
            // Can call the error method if something is wrong, should exit after
            error('error');
          }
        };

        request.send(file);

        return {
          abort: () => {
            request.abort();
            abort();
          },
        };
      }
    }
  };

  const downloadPhoto: LoadServerConfigFunction = (
    source,
    load,
    _error,
    progress,
    abort,
    _headers,
  ) => {
    // Should call the progress method to update the progress to 100% before calling load
    // (endlessMode, loadedSize, totalSize)
    progress(true, 0, 1024);

    const request = new XMLHttpRequest();
    request.open('GET', source, true);
    request.responseType = 'blob';

    request.upload.onprogress = (e) => {
      progress(e.lengthComputable, e.loaded, e.total);
    };

    request.onload = function (e) {
      if (this.status !== 200) return;
      load(this.response);
    };

    request.send();

    return {
      abort: () => {
        abort();
      },
    };
  };

  const deletePhoto: RemoveServerConfigFunction = async (
    source,
    load,
    error,
  ) => {
    const photo = data.listingPhotos.find((p) => p.photo === source);
    if (photo) {
      const id = photo.id;
      const result = await photo.destroy();
      if (result) {
        load();
      } else {
        error('There was an issue deleting the photo');
      }
    }
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title={`Edit Listing`}>
        <ManageListingMenu propertyId={propertyId} listingId={listingId} />
        <LoadingView />
      </PageWrapper>
    );
  } else {
    const vals = {
      title: data.title,
      description: data.description,
      startDate: data.startDate,
      endDate: data.endDate,
      rentAmount: data.rentAmount,
      bondAmount: data.bondAmount,
      tags: data.tags,
      listing_photos: data.listingPhotos.map((p) => {
        return {
          source: p.photo,
          options: {
            type: 'local',
            metadata: {
              photoId: p.id,
            },
          },
        };
      }),
    } as EditListingFormValues;

    return (
      <PageWrapper
        title={`Edit Listing`}
        buttons={[{text: 'View Listing Ad', href: `/listings/${listingId}`}]}>
        <ManageListingMenu propertyId={data.propertyId} listingId={listingId} />
        <Card>
          <CardBody>
            <CardTitle>Edit Listing</CardTitle>
            <small>Fields with a * are required</small>
            <Formik
              initialValues={vals}
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                title: Yup.string().optional().nullable().label('Title'),
                description: Yup.string()
                  .min(100)
                  .required()
                  .label('Description'),
                startDate: Yup.string().required().label('Available From'),
                endDate: Yup.string()
                  .optional()
                  .nullable()
                  .label('Available Until'),
                rentAmount: Yup.number().required().min(0).label('Rent'),
                bondAmount: Yup.number()
                  .min(0)
                  .optional()
                  .nullable()
                  .label('Bond')
                  .test(
                    'max-bond-is-allowed',
                    'Bond can not be greater than 4 weeks rent',
                    function (value) {
                      if (!value || value == 0) {
                        return true;
                      }
                      const rentalPeriod = this.parent.rentalPeriod;
                      const rent = this.parent.rentAmount;
                      if (rentalPeriod === 'Weekly') {
                        return value <= rent * 4;
                      } else if (rentalPeriod === 'Fortnightly') {
                        return value <= rent * 2;
                      } else {
                        return true;
                      }
                    },
                  ),
                tags: Yup.array().min(0),
                listing_photos: Yup.array().min(3).required().label('Photos'),
              })}>
              {(formik) => (
                <Form>
                  <div className="flex flex-col lg:flex-row justify-between">
                    <div className="flex-1">
                      <DateField
                        formik={formik}
                        label="Available From*"
                        name="startDate"
                        minDate={new Date()}
                        maxDate={new Date('2099-01-01')}
                      />
                    </div>

                    <div className="flex-1 lg:ml-4">
                      <DateField
                        formik={formik}
                        label="Available Until (optional)"
                        name="endDate"
                        minDate={new Date()}
                        maxDate={new Date('2099-01-01')}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col lg:flex-row justify-between">
                    <div className="flex-1">
                      <InputField
                        formik={formik}
                        label="Rent ($ per week)*"
                        name="rentAmount"
                        placeholder="Eg: 750.00"
                      />
                    </div>

                    <div className="flex-1 lg:ml-4">
                      <InputField
                        formik={formik}
                        label="Bond ($)"
                        name="bondAmount"
                        placeholder="Eg: 2000.00"
                      />
                    </div>
                  </div>

                  <div>
                    <ListingTagsModalField formik={formik} name="tags" />
                  </div>

                  <div className="mt-4">
                    <InputField
                      formik={formik}
                      name="title"
                      label="Title (Optional)"
                      placeholder="If left blank the address will be used."
                    />
                  </div>

                  <TextareaField
                    formik={formik}
                    label="Description*"
                    name="description"
                    placeholder="Your listing description here, should be at least 100 characters long."
                    rows={6}
                  />

                  <CardTitle className="mt-4">Photos</CardTitle>
                  <small>
                    At least 4 photos are required to publish your listing.
                  </small>

                  <div className="listings-photo-upload">
                    <FilePond
                      ref={pond}
                      allowMultiple
                      allowReplace={false}
                      allowRevert
                      files={formik.values.listing_photos as any}
                      maxFiles={30}
                      maxParallelUploads={5}
                      imageTransformOutputQuality={80}
                      imageTransformOutputQualityMode="always"
                      imageTransformOutputMimeType="image/jpeg"
                      imageResizeTargetWidth={1200}
                      imageResizeMode="contain"
                      imageResizeUpscale={false}
                      maxFileSize="5MB"
                      acceptedFileTypes={[
                        'image/png',
                        'image/jpeg',
                        'image/jpg',
                      ]}
                      allowReorder={true}
                      server={{
                        process: uploadPhoto,
                        fetch: null,
                        revert: (_uniqueFileId, load, _error) => {
                          load();
                        },
                        load: downloadPhoto,
                        remove: deletePhoto,
                      }}
                      instantUpload={true}
                      onprocessfilestart={() => setIsUploadingPhotos(true)}
                      onprocessfile={() => setIsUploadingPhotos(false)}
                      onprocessfiles={() => setIsUploadingPhotos(false)}
                      onupdatefiles={(files) =>
                        formik.setFieldValue('listing_photos', files)
                      }
                      onreorderfiles={(files) =>
                        formik.setFieldValue('listing_photos', files)
                      }
                      credits={false}
                    />
                  </div>

                  {isUploadingPhotos ? (
                    <button
                      type="button"
                      disabled
                      className="btn btn-primary btn-block mt-4">
                      Update Listing
                    </button>
                  ) : (
                    <SubmitButton
                      formik={formik}
                      text="Update Listing"
                      submittingText="Saving"
                      className="mt-4"
                    />
                  )}
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </PageWrapper>
    );
  }
};

export default EditListingPage;
