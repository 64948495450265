import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import {Paragraph} from 'components_sb/typography';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';

const BondStatusCard = ({
  property,
  tenancy,
}: {
  property: Property;
  tenancy?: Tenancy;
}) => {
  if (property && property.tenanciesCount === 0) {
    return null;
  }

  if (!tenancy) {
    return null;
  }

  if (tenancy.isNew && !['draft', 'pending'].includes(tenancy.status)) {
    if (tenancy.externalBondId) {
      return (
        <Card className="mt-8">
          <CardBody>
            <CardTitle>Bond Status</CardTitle>
            <Paragraph>
              <strong>Status: </strong>
              <span>Lodged</span>
            </Paragraph>
            <Paragraph>
              <strong>Bond ID: </strong>
              <span>{tenancy.externalBondId}</span>
            </Paragraph>
          </CardBody>
        </Card>
      );
    } else if (tenancy.externalBondFiled) {
      return (
        <Card className="mt-8">
          <CardBody>
            <CardTitle>Bond Status</CardTitle>
            <Paragraph>
              <strong>Status: </strong>
              <span>Awaiting response from Tenancy Services</span>
            </Paragraph>
          </CardBody>
        </Card>
      );
    } else if (tenancy.readyToBeFiled) {
      return (
        <Card className="mt-8">
          <CardBody>
            <CardTitle>Bond Status</CardTitle>
            <p>
              The money for the bond for this tenancy has been received from the
              tenants and the bond will automatically be filed with Tenancy
              Services shortly.
            </p>
          </CardBody>
        </Card>
      );
    } else {
      return (
        <Card className="mt-8">
          <CardBody>
            <CardTitle>Bond Status</CardTitle>
            <p>
              Keyhook is currently waiting for the tenant(s) to make their bond
              payment(s). Once received, it will automatically be filed with
              Tenancy Services for you.
            </p>
          </CardBody>
        </Card>
      );
    }
  }

  return null;
};

export default BondStatusCard;
