import {Browser} from '@capacitor/browser';
import {AiOutlineWarning} from '@react-icons/all-files/ai/AiOutlineWarning';
import Decimal from 'decimal.js';
import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import DocumentsTable from 'components/document/DocumentsTable';
import CopyButton from 'components/forms_fields/CopyButton';
import InspectionsTable from 'components/inspection/InspectionsTable';
import {HeaderButton} from 'components/navbar/Header';
import PageWrapper from 'components/PageWrapper';
import ServiceRequestsTable from 'components/service_request/ServiceRequestsTable';
import TenancyMembershipsTable from 'components/tenancy/TenancyMembershipsTable';
import RentersList from 'components/user/RentersList';
import CompanyInfoGlobals from 'globals/CompanyInfoGlobals';
import Document from 'models/properties/Document';
import TenancyContinuation from 'models/properties/TenancyContinuation';
import TenancyInvite from 'models/properties/TenancyInvite';
import TenancyMembership from 'models/properties/TenancyMembership';
import useAuth from 'services/useAuth';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';
import {removeUnderscores, titleize, toCurrency} from 'utilities/StringHelpers';

const TenancyDetailPage = () => {
  useTitle('Tenancy');
  usePageVisit('TenancyDetailPage');

  const {id} = useParams();

  const queryClient = useQueryClient();

  const {currentUser} = useAuth();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const {data, isLoading, error} = useQuery(
    `renter-tenancy-detail-${id}`,
    async () => {
      const res = await TenancyMembership.select({
        tenancy_memberships: [
          'active',
          'rent_split',
          'bond_split',
          'bond_paid',
          'bond_reference',
          'rent_reference',
          'created_at',
        ],
        tenancies: [
          'status',
          'bond',
          'start_date',
          'end_date',
          'total_rent',
          'rental_period',
          'head_tenant_id',
          'created_at',
          'weeks_rent_in_advance',
        ],
        properties: ['street_address', 'suburb', 'city', 'main_image'],
        users: ['name', 'avatar'],
        documents: [
          'document',
          'document_type',
          'name',
          'created_at',
          'documentable_id',
          'documentable_type',
        ],
      })
        .includes({
          tenancy: [
            {property: ['documents', 'landlord']},
            {tenancy_memberships: 'renter'},
            {tenancy_invites: 'renter'},
            'inspections',
            'documents',
            'active_tenancy_continuation',
            'service_requests',
            'unprocessed_rent_price_changes',
          ],
          not_paid_rent_payments: 'late_payment_notifications',
        })
        .where({tenancy_id: id, renter_id: currentUser.id})
        .first();

      if (res.data) {
        return res.data;
      } else {
        throw new Error('not found');
      }
    },
    {retry: 1},
  );

  const navigate = useNavigate();

  const showServiceRequest = (requestId: string) => {
    navigate(`service-requests/${requestId}`);
  };

  const showInspection = (inspectionId: string) => {
    navigate(`inspections/${inspectionId}`);
  };

  const showDocument = async (document: Document) => {
    await Browser.open({url: document.document, windowName: document.name});
  };

  const showUnpaidRentHelp = async () => {
    await Browser.open({
      url: 'https://help.keyhook.com/financials/why-does-keyhook-tell-me-my-rent-is-overdue-after-i-ve-paid-it',
    });
  };

  const actionButtons = (): HeaderButton[] => {
    const items = [] as HeaderButton[];

    if (data.tenancy.status === 'active_periodic' && !data.tenancy.endDate) {
      items.push({
        text: 'End Tenancy',
        href: `end-tenancy`,
      });
    }

    if (data.tenancy.headTenantId === currentUser.id) {
      items.push({
        text: 'Update Rent Amounts',
        href: 'edit-rent-amounts',
      });
    }

    if (!data.tenancy.isNew && data.tenancy.headTenantId === currentUser.id) {
      items.push({
        text: 'Invite Other Tenants',
        href: 'invite-tenants',
      });
    }

    return items;
  };

  const confirmDeleteTenancyInvite = async (invite: TenancyInvite) => {
    setConfirmationOptions({
      title: 'Delete Tenant Invite',
      message: 'Are you sure you want to delete the invite for this tenant?',
      buttonTitle: 'Delete',
      action: () => deleteTenancyInvite(invite),
      color: 'error',
    });
  };

  const deleteTenancyInvite = async (invite: TenancyInvite) => {
    const result = await invite.destroy();
    if (result) {
      queryClient.invalidateQueries(`renter-tenancy-detail-${id}`);

      toast.success('You have successfully deleted the invitation.');
    }
  };

  const alerts = () => {
    if (data) {
      const count = data.notPaidRentPayments.length;

      if (count > 0) {
        return (
          <div>
            <div className="alert alert-error shadow-lg text-white mb-4">
              <div>
                <AiOutlineWarning className="w-6 h-6" />
                <span>
                  You have <strong>{count} unpaid rent payment(s)</strong>. If
                  you think this is a mistake,{' '}
                  <a
                    className="text-white underline font-semibold"
                    onClick={showUnpaidRentHelp}>
                    click here
                  </a>
                  .
                </span>
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    }

    return null;
  };

  const tenancyRenewalCard = () => {
    let renewal: TenancyContinuation;
    if (data.tenancy && data.tenancy.activeTenancyContinuation) {
      renewal = data.tenancy.activeTenancyContinuation;
    }
    if (!renewal || !renewal.landlordRequestedAction) {
      return null;
    }

    const isHeadTenant = data?.tenancy.headTenantId == currentUser?.id;

    const renderHeadTenantInformation = () => {
      if (!renewal.tenantChosenAction) {
        return (
          <div>
            <p className="mb-2">
              Your landlord has decided to {renewal.landlordActionString},
              please discuss this with the other tenants and then click the
              button below to make a decision.
            </p>

            <p className="mb-2">
              If you do not wish to renew this tenancy after the end date,
              choose the "Give Notice" option from the actions dropdown.
            </p>

            <Link to={`tenancy-renewals/${renewal.id}/edit`}>
              <button className="btn btn-sm btn-neutral">
                Respond to landlord's decision
              </button>
            </Link>
          </div>
        );
      } else if (
        renewal.landlordRequestedAction === renewal.tenantChosenAction
      ) {
        return (
          <div>
            <p className="mb-2">
              Your landlord has decided to {renewal.landlordActionString}, which
              you have agreed to.
            </p>

            {renewal.tenantChosenAction === 'start_new_lease' &&
              !renewal.tenantsWhoHaveSigned.includes(currentUser.id) && (
                <div>
                  <p className="mb-2">
                    You have not yet signed the new lease, click the button
                    below to review.
                  </p>

                  <Link to={`tenancy-renewals/${renewal.id}/edit`}>
                    <button className="btn btn-sm btn-neutral">
                      Review New Lease
                    </button>
                  </Link>
                </div>
              )}
          </div>
        );
      } else if (
        renewal.landlordRequestedAction !== renewal.tenantChosenAction
      ) {
        return (
          <div>
            <p className="mb-2">
              Your landlord has decided to {renewal.landlordActionString},
              however you chose to go periodic instead.
            </p>
          </div>
        );
      }

      return null;
    };

    const renderRegularTenantInformation = () => {
      if (!renewal.tenantChosenAction) {
        return (
          <div>
            <p className="mb-2">
              Your landlord has decided to {renewal.landlordActionString},
              please discuss this with the other tenants, your head tenant will
              be responsible for confirming or rejecting this.
            </p>
          </div>
        );
      } else if (
        renewal.landlordRequestedAction === renewal.tenantChosenAction
      ) {
        return (
          <div>
            <p className="mb-2">
              Your landlord has decided to {renewal.landlordActionString}, which
              your head tenant has agreed to.
            </p>
          </div>
        );
      } else if (
        renewal.landlordRequestedAction !== renewal.tenantChosenAction
      ) {
        return (
          <div>
            <p className="mb-2">
              Your landlord has decided to {renewal.landlordActionString},
              however your head tenant has chosen to go periodic instead.
            </p>
          </div>
        );
      }

      return null;
    };

    const signingStatus = () => {
      if (
        !renewal.tenantChosenAction ||
        renewal.landlordRequestedAction === 'go_periodic' ||
        renewal.tenantChosenAction === 'go_periodic'
      ) {
        return null;
      }

      if (renewal.allTenantsSigned) {
        return (
          <div>
            <p>All tenants have signed the renewal.</p>
          </div>
        );
      } else {
        return (
          <div>
            <p className="mb-2">
              We are currently waiting for all tenants to sign this renewal,
              otherwise the tenancy will go periodic after the end date.
            </p>

            {!renewal.tenantsWhoHaveSigned.includes(currentUser.id) && (
              <div>
                <p className="mb-2">
                  You have not yet signed the new lease, click the button below
                  to review and sign.
                </p>

                <Link to={`tenancy-renewals/${renewal.id}/edit`}>
                  <button className="btn btn-sm btn-neutral">
                    Review New Lease
                  </button>
                </Link>
              </div>
            )}
          </div>
        );
      }
    };

    return (
      <Card className="mt-4 border-t-3 border-info">
        <CardBody>
          <CardTitle>Renewal Options</CardTitle>

          {isHeadTenant
            ? renderHeadTenantInformation()
            : renderRegularTenantInformation()}

          {signingStatus()}
        </CardBody>
      </Card>
    );
  };

  const rentPriceChangesCard = () => {
    if (
      data.tenancy &&
      data.tenancy.unprocessedRentPriceChanges &&
      data.tenancy.unprocessedRentPriceChanges.length > 0
    ) {
      const rentChange = data.tenancy.unprocessedRentPriceChanges[0];
      const newPersonalRent = new Decimal(data.rentSplit)
        .add(new Decimal(rentChange.newRentSplits[currentUser.id]))
        .toString();

      return (
        <Card className="mt-4">
          <CardBody>
            <CardTitle className="mb-0">Rent Change</CardTitle>
            <p>
              Your landlord has given notice that the rent for this tenancy will
              change to ${rentChange.newTotalRent} from{' '}
              {moment(rentChange.effectiveDate).format(DATE_FORMAT)} onwards.
            </p>

            <p>
              Your personal rent will change to ${newPersonalRent}, please make
              sure to update any recurring payments.
            </p>
          </CardBody>
        </Card>
      );
    } else {
      return null;
    }
  };

  const tenancyInvitesCard = () => {
    if (data.tenancy && data.tenancy.tenancyInvites.length > 0) {
      return (
        <Card className="mt-8">
          <CardBody>
            <CardTitle>Invited Tenants</CardTitle>
            <p className="mt-2">
              These other named tenants have been invited to join the tenancy
              either by the landlord or the head tenant.
            </p>

            <div className="overflow-x-auto">
              <table className="table w-full">
                <thead>
                  <tr>
                    <th>Tenant</th>
                    <th>Sent</th>
                    <th>Signed Up To Keyhook</th>
                    <th>Accepted Invite</th>
                    {data.tenancy.headTenantId === currentUser.id && <th></th>}
                  </tr>
                </thead>
                <tbody>
                  {data.tenancy.tenancyInvites.map((req) => (
                    <tr key={req.id}>
                      <td>{req.renter ? req.renter.name : req.email}</td>
                      <td>{moment(req.createdAt).fromNow()}</td>
                      <td>{req.renter ? 'Yes' : 'No'}</td>
                      <td>{req.accepted ? 'Yes' : 'No'}</td>
                      {data.tenancy.headTenantId === currentUser.id && (
                        <td>
                          <a
                            className="link link-primary"
                            onClick={() => confirmDeleteTenancyInvite(req)}>
                            Delete
                          </a>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      );
    }

    return null;
  };

  const unpaidBondCard = () => {
    if (!data?.bondPaid && data.bondSplit > 0.0) {
      return (
        <Card className="mt-4 border-t-2 border-red-500">
          <CardBody>
            <CardTitle className="mb-0">Bond Payment</CardTitle>

            <p className="mb-2">
              Your bond has not been paid, please make a payment as soon as
              possible with these details for your bond to be registered. It is
              crucial that you enter these details accurately or we will not be
              able to track your payment.
            </p>

            <p className="mb-2 text-secondary text-small">
              It may take up to 24 hours for your bond payment to clear within
              our system.
            </p>

            <div className="flex flex-col lg:flex-row justify-between">
              <div className="flex-1">
                <label className="label">
                  <span className="label-text">Account Name</span>
                </label>
                <div className="flex flex-start">
                  <input
                    type="text"
                    readOnly
                    className="w-full pr-16 input input-bordered rounded-r-none"
                    value={CompanyInfoGlobals.bankAccountName}
                    id="bond-account-name-field"
                  />
                  <CopyButton id="bond-account-name-field" />
                </div>
              </div>
              <div className="flex-1 lg:ml-2">
                <label className="label">
                  <span className="label-text">Account Number</span>
                </label>
                <div className="flex flex-start">
                  <input
                    type="text"
                    readOnly
                    className="w-full pr-16 input input-bordered rounded-r-none"
                    value={CompanyInfoGlobals.bankAccountNumber}
                    id="bond-account-number-field"
                  />
                  <CopyButton id="bond-account-number-field" />
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row justify-between">
              <div className="flex-1">
                <label className="label">
                  <span className="label-text">Reference (case sensitive)</span>
                </label>
                <div className="flex flex-start">
                  <input
                    type="text"
                    readOnly
                    className="w-full pr-16 input input-bordered rounded-r-none"
                    value={data?.bondReference}
                    id="bond-reference-field"
                  />
                  <CopyButton id="bond-reference-field" />
                </div>
              </div>
              <div className="flex-1 lg:ml-2">
                <label className="label">
                  <span className="label-text">Amount ($)</span>
                </label>
                <div className="flex flex-start">
                  <input
                    type="text"
                    readOnly
                    className="w-full pr-16 input input-bordered rounded-r-none"
                    value={data.bondSplit.toString()}
                    id="bond-amount-field"
                  />
                  <CopyButton id="bond-amount-field" />
                </div>
              </div>
            </div>

            <div className="mt-2">
              <p className="text-sm text-secondary">
                You will receive a notification once your payment clears and
                this warning will dissapear.
              </p>
            </div>
          </CardBody>
        </Card>
      );
    } else {
      return null;
    }
  };

  const rentCard = () => {
    if (data?.rentSplit) {
      if (new Decimal(data.rentSplit).equals(new Decimal(0.0))) {
        return (
          <Card className="mt-4">
            <CardBody>
              <CardTitle className="mb-0">Rent Payments</CardTitle>
              <p className="mb-2">
                Your share of the rent was set as $0.00, you do not need to
                worry about making any rent payments through Keyhook.
              </p>
            </CardBody>
          </Card>
        );
      }

      let rentalPeriod;
      if (data.tenancy.rentalPeriod === 'Weekly') {
        rentalPeriod = '7 Days';
      } else if (data.tenancy.rentalPeriod === 'Fortnightly') {
        rentalPeriod = '14 Days';
      }

      let startDate = moment(data.tenancy.startDate);
      if (data.tenancy.weeksRentInAdvance > 0) {
        startDate = startDate.add(data.tenancy.weeksRentInAdvance, 'weeks');
      }

      return (
        <Card className="mt-4 border-t-2 border-info">
          <CardBody>
            <CardTitle className="mb-0">Rent Payments</CardTitle>
            <p className="mb-2">
              Please use the details below to set up a recurring bank transfer
              for rent payments. It is crucial that you enter these details
              accurately or we will not be able to track your payments.
            </p>

            <div className="flex flex-col lg:flex-row justify-between">
              <div className="flex-1">
                <label className="label">
                  <span className="label-text">Account Name</span>
                </label>
                <div className="flex flex-start">
                  <input
                    type="text"
                    readOnly
                    className="w-full pr-16 input input-bordered rounded-r-none"
                    value={CompanyInfoGlobals.bankAccountName}
                    id="rent-account-name-field"
                  />
                  <CopyButton id="rent-account-name-field" />
                </div>
              </div>
              <div className="flex-1 lg:ml-2">
                <label className="label">
                  <span className="label-text">Account Number</span>
                </label>
                <div className="flex flex-start">
                  <input
                    type="text"
                    readOnly
                    className="w-full pr-16 input input-bordered rounded-r-none"
                    value={CompanyInfoGlobals.bankAccountNumber}
                    id="rent-account-number-field"
                  />
                  <CopyButton id="rent-account-number-field" />
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row justify-between">
              <div className="flex-1">
                <label className="label">
                  <span className="label-text">Reference (case sensitive)</span>
                </label>
                <div className="flex flex-start">
                  <input
                    type="text"
                    readOnly
                    className="w-full pr-16 input input-bordered rounded-r-none"
                    value={data?.rentReference}
                    id="rent-reference-field"
                  />
                  <CopyButton id="rent-reference-field" />
                </div>
              </div>
              <div className="flex-1 lg:ml-2">
                <label className="label">
                  <span className="label-text">Amount ($)</span>
                </label>
                <div className="flex flex-start">
                  <input
                    type="text"
                    readOnly
                    className="w-full pr-16 input input-bordered rounded-r-none"
                    value={data?.rentSplit.toString()}
                    id="rent-amount-field"
                  />
                  <CopyButton id="rent-amount-field" />
                </div>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row justify-between">
              <div className="flex-1">
                <label className="label">
                  <span className="label-text">Start Date</span>
                </label>
                <div className="flex flex-start">
                  <input
                    type="text"
                    readOnly
                    className="w-full pr-16 input input-bordered rounded-r-none"
                    value={startDate.format(DATE_FORMAT)}
                    id="rent-start-date-field"
                  />
                  <CopyButton id="rent-start-date-field" />
                </div>
              </div>
              <div className="flex-1 lg:ml-2">
                <label className="label">
                  <span className="label-text">Repeat Every</span>
                </label>

                <input
                  type="text"
                  readOnly
                  className="w-full pr-16 input input-bordered"
                  value={rentalPeriod}
                />
              </div>
            </div>

            <div className="mt-4">
              <CardTitle className="mb-0">
                Unpaid or pending rent payments
              </CardTitle>
              {data.notPaidRentPayments.length === 0 ? (
                <p className="mb-2">
                  You do not have any unpaid rent payments.
                </p>
              ) : (
                data.notPaidRentPayments.map((rp) => (
                  <div key={rp.id} className="mt-2">
                    <strong className="block">
                      {moment(rp.dueDate).format(DATE_FORMAT)}
                      {data.tenancy.weeksRentInAdvance > 0 &&
                        moment(rp.dueDate).isBefore(data.tenancy.startDate) && (
                          <span> ( Rent In Advance ) </span>
                        )}
                    </strong>
                    <div>
                      <p className="mb-0 text-secondary text-sm">
                        Status: {titleize(removeUnderscores(rp.status))}
                      </p>
                      {rp.status === 'pending_clearance' ? (
                        <>
                          <p className="mb-0 text-sm">
                            Amount Due: {toCurrency(rp.amountDue)}
                          </p>
                          <p className="mb-0 text-sm">
                            Amount Received Pending Clearance:{' '}
                            {toCurrency(rp.amountPendingClearance)}
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="mb-0 text-sm">
                            Amount Due: {toCurrency(rp.amountDue)}
                          </p>
                          <p className="mb-0 text-sm">
                            Amount Paid: {toCurrency(rp.amountPaid)}
                          </p>
                        </>
                      )}
                      {rp.latePaymentNotifications.length > 0 && (
                        <p className="mb-0 text-sm">
                          Late Payment Notifications:{' '}
                          {rp.latePaymentNotifications.length}
                        </p>
                      )}

                      {rp.status === 'pending_clearance' && (
                        <small className="text-secondary">
                          It may take up to 24 hours (or longer over the
                          weekend) for a payment to clear.
                        </small>
                      )}

                      <hr className="bg-gray-200 w-100 mt-1" />
                    </div>
                  </div>
                ))
              )}
              <Link
                to="rent-payments"
                className="btn btn-neutral btn-block mt-4">
                View All Rent Payments
              </Link>
            </div>
          </CardBody>
        </Card>
      );
    } else {
      return null;
    }
  };

  const serviceRequestsCard = () => {
    return (
      <Card className="mt-4">
        <CardBody>
          <CardTitle className="flex justify-between">
            Maintenance Requests
            <Link
              to={'service-requests/new'}
              className="hidden lg:inline-block">
              <button
                className="btn btn-sm btn-neutral float-right"
                type="button">
                New Maintenance Request
              </button>
            </Link>
          </CardTitle>
          <div>
            <ServiceRequestsTable
              requests={data.tenancy.serviceRequests}
              clickHandler={showServiceRequest}
              accountType="Renter"
            />
          </div>

          <Link
            to={'service-requests/new'}
            className="mt-4 btn btn-block btn-neutral">
            New Maintenance Request
          </Link>
        </CardBody>
      </Card>
    );
  };

  const inspectionsCard = () => {
    return (
      <Card className="mt-4">
        <CardBody>
          <CardTitle>Inspections</CardTitle>
          <div>
            <InspectionsTable
              inspections={data.tenancy.inspections}
              clickHandler={showInspection}
              showCount={true}
            />
          </div>
        </CardBody>
      </Card>
    );
  };

  const documentsCard = () => {
    const documents = data.tenancy.property.documents
      .filter((d) => d.document && d.document.length > 0)
      .concat(data.tenancy.documents);

    return (
      <Card className="mt-4">
        <CardBody>
          <CardTitle>Documents</CardTitle>
          <div>
            <DocumentsTable
              documents={documents}
              clickHandler={showDocument}
              showParent={true}
            />
          </div>
        </CardBody>
      </Card>
    );
  };

  const tenantsCard = () => {
    return (
      <Card className="mt-4">
        <CardBody>
          <TenancyMembershipsTable
            tenancyMemberships={data.tenancy.tenancyMemberships}
            headTenantId={data.tenancy.headTenantId}
          />
        </CardBody>
      </Card>
    );
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Tenancy">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    const property = data.tenancy.property;
    const buttons = actionButtons();
    return (
      <PageWrapper
        title="Tenancy"
        buttons={buttons}
        showButtonsAsDropDown={buttons.length > 0}
        dropdownTitle="Actions">
        <div className="flex flex-col justify-center mb-4">
          <div className="relative flex flex-col md:flex-row md:space-x-0 space-y-3 md:space-y-0 rounded-xl shadow-lg p-3 w-full mx-auto border border-white bg-white">
            <div className="w-full md:w-1/4 bg-white grid place-items-center">
              <img
                src={property.mainImage}
                alt={property.streetAddress}
                className="rounded-xl"
              />
            </div>
            <div className="w-full md:w-2/3 bg-white flex flex-col space-y-2 p-3">
              <div className="flex justify-between item-center">
                <p className="text-gray-500 font-medium md:block">
                  {property.suburb}, {property.city}
                </p>
              </div>
              <h3 className="font-black text-gray-800 md:text-3xl text-xl">
                {property.streetAddress}
              </h3>
              <p className="text-sm text-secondary">
                Landlord: {property.landlord.name}
              </p>
            </div>
          </div>
        </div>

        {alerts()}

        {tenancyRenewalCard()}
        {rentPriceChangesCard()}

        {unpaidBondCard()}
        {rentCard()}
        {tenancyInvitesCard()}
        {serviceRequestsCard()}
        {inspectionsCard()}
        {documentsCard()}
        {tenantsCard()}
      </PageWrapper>
    );
  }
};

export default TenancyDetailPage;
