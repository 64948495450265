import {Model, Attr} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';

@Model()
class Document extends ApplicationRecord {
  static jsonapiType = 'documents';

  @Attr() document: string;

  @Attr() documentType: string;

  @Attr() name: string;

  @Attr() private: boolean;

  @Attr() documentableType: string;
  @Attr() documentableId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;
}

export default Document;
