import React from 'react';

import {Capacitor} from '@capacitor/core';
import {AiOutlineInfoCircle} from '@react-icons/all-files/ai/AiOutlineInfoCircle';
import {toast} from 'react-toastify';

const HelpTextPresenter = ({helpText}: {helpText: string}) => {
  if (!helpText) {
    return <React.Fragment />;
  }

  const handleCapacitorClick = (e: React.MouseEvent<any>) => {
    e.preventDefault();

    toast.dismiss();
    toast.info(helpText, {
      position: 'bottom-center',
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      autoClose: 5000,
    });
  };

  const handleWebClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  if (Capacitor.isNativePlatform()) {
    return (
      <AiOutlineInfoCircle
        className="w-4 h-4 text-primary"
        onClick={handleCapacitorClick}
      />
    );
  } else {
    return (
      <div
        className="tooltip tooltip-bottom"
        data-tip={helpText}
        onClick={handleWebClick}>
        <AiOutlineInfoCircle className="w-4 h-4 text-primary" />
      </div>
    );
  }
};

export default HelpTextPresenter;
