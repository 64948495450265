import React from 'react';

import {Form, Formik, FormikHelpers} from 'formik';
import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import {DateField, DatetimeField, SubmitButton} from 'components/forms_fields';
import StackedButtonSelectField from 'components/forms_fields/StackedButtonSelectField';
import PageWrapper from 'components/PageWrapper';
import Inspection from 'models/inspections/Inspection';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import useInspectionStore from 'stores/InspectionStore';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {isDefined} from 'utilities/MiscHelpers';

type FormValues = {
  doneBy: string;
  finalizedDate: string;
  reportType: string;
};

const EditInspectionPage = () => {
  const {id} = useParams();

  const possibleInspection = useInspectionStore((state) => state.inspection);

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const {data, error} = useQuery(
    `edit-inspection-${id}`,
    async () => {
      const result = await Inspection.find(id);

      return result.data;
    },
    {
      enabled: !isDefined(possibleInspection),
    },
  );

  const queryClient = useQueryClient();

  const inspection = possibleInspection || data;

  const updateInspection = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    if (formValues.reportType !== inspection.reportType) {
      inspection.completedRooms = [];
    }

    inspection.assignAttributes(formValues);

    const result = await inspection.save();
    if (result) {
      await queryClient.invalidateQueries(
        `inspection-detail-${inspection.id}`,
        inspection,
      );
      await queryClient.invalidateQueries(
        `inspection-detail-${inspection.id}-items`,
        inspection,
      );

      toast.success('Inspection successfully updated!');
    } else {
      for (const key of Object.keys(inspection.errors)) {
        const message = inspection.errors[key]!.fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  const handleSubmit = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    if (formValues.reportType !== inspection.reportType) {
      setConfirmationOptions({
        color: 'success',
        title: 'Update Inspection',
        message:
          'Are you sure? You may lose the photos or videos saved against your current inspection.',
        buttonTitle: 'Save',
        action: () => updateInspection(formValues, actions),
      });
    } else {
      updateInspection(formValues, actions);
    }
  };

  const minDate = moment().toDate();
  const maxDate = moment('2100-01-01').toDate();

  if (inspection) {
    if (
      inspection.status === 'awaiting_sign_offs' ||
      inspection.status === 'complete'
    ) {
      return (
        <PageWrapper title="Edit Inspection" backEnabled>
          <Card className="mt-4">
            <CardBody>
              <CardTitle>Edit Inspection</CardTitle>
              <p className="mt-2">This inspection can no longer be edited.</p>
            </CardBody>
          </Card>
        </PageWrapper>
      );
    }

    return (
      <PageWrapper title="Edit Inspection" backEnabled>
        <Formik
          initialValues={
            {
              doneBy: inspection.doneBy,
              reportType: inspection.reportType,
              finalizedDate: inspection.finalizedDate,
            } as FormValues
          }
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            doneBy: Yup.string()
              .required()
              .oneOf(['landlord', 'tenant'])
              .label('Photos taken by'),
            reportType: Yup.string()
              .required()
              .oneOf(['detailed', 'basic'])
              .label('Report type'),
            finalizedDate: Yup.string().required().label('Date'),
          })}
          onSubmit={handleSubmit}>
          {(formik) => (
            <Form>
              <Card className="mt-4 overflow-visible">
                <CardBody>
                  <CardTitle>Edit Inspection</CardTitle>

                  <StackedButtonSelectField
                    label="Report Type"
                    helpText="Detailed reports will guide you through a detailed inspection.
                  Basic reports will enable you to inspect the property unguided."
                    name="reportType"
                    formik={formik}
                    options={[
                      {label: 'Detailed', value: 'detailed'},
                      {label: 'Basic', value: 'basic'},
                    ]}
                  />

                  <p className="text-sm text-secondary">
                    Please note, updating the report type will remove anything
                    you have currently added to this inspection.
                  </p>

                  <StackedButtonSelectField
                    label="Photos taken by"
                    helpText="You can now enable your tenant to take inspection photos."
                    name="doneBy"
                    formik={formik}
                    options={[
                      {label: 'Landlord', value: 'landlord'},
                      {label: 'Tenant', value: 'tenant'},
                    ]}
                  />

                  <div className="w-1/2">
                    {formik.values.doneBy === 'tenant' ? (
                      <DateField
                        label="Choose a date"
                        name="finalizedDate"
                        formik={formik}
                        minDate={minDate}
                        maxDate={maxDate}
                      />
                    ) : (
                      <DatetimeField
                        label="Choose a date"
                        name="finalizedDate"
                        formik={formik}
                        minDate={minDate}
                        maxDate={maxDate}
                        inputProps={{
                          className: 'input input-bordered w-full',
                        }}
                      />
                    )}
                  </div>

                  <SubmitButton
                    className="mt-4"
                    formik={formik}
                    submittingText="Updating"
                    text="Save"
                  />
                </CardBody>
              </Card>
            </Form>
          )}
        </Formik>
      </PageWrapper>
    );
  } else {
    if (error) {
      return errorViewForError(error);
    } else {
      return (
        <PageWrapper title="Edit Inspection" backEnabled>
          <LoadingView />
        </PageWrapper>
      );
    }
  }
};

export default EditInspectionPage;
