import {useCallback, useEffect} from 'react';

import {useCookies} from 'react-cookie';
import {useLocalStorage} from 'usehooks-ts';

import {TARGET_ENV} from 'globals/app-globals';
import useAuth from 'services/useAuth';

/**
 * A hook to automatically (and conditionally) enable Customerly tracking
 * and chat bubble.
 */
const useCustomerly = () => {
  const {currentUser, userIsLoggedIn} = useAuth();

  const [{customerly_jwt}, _, removeCookie] = useCookies(['customerly_jwt']);

  /**
   * Properly removes the cookie set by Customerly. Note that only
   * using the logout function on the Customerly window object will
   * only unload the currently loaded user and does not delete the
   * cookie, so we need to handle this manually.
   */
  const clearCookie = useCallback(() => {
    if (customerly_jwt) {
      removeCookie('customerly_jwt');
    }
  }, [customerly_jwt, removeCookie]);

  const [ghosting] = useLocalStorage<boolean>('ghosting', false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const {customerly} = window as any;
    if (
      customerly &&
      ['development', 'staging', 'production'].includes(TARGET_ENV)
    ) {
      /**
       * Only initialise Customerly for logged in users that
       * are not being ghosted. If a user is being ghosted,
       * clear any existing customerly cookies to avoid tracking
       * against a previously non-ghosted login.
       */
      if (userIsLoggedIn) {
        if (ghosting) {
          clearCookie();
        } else {
          let configure;
          if (customerly.loaded) {
            configure = customerly.update;
          } else if (customerly.update) {
            configure = customerly.loaded;
          }

          if (configure) {
            if (
              currentUser &&
              (currentUser.roles.includes('Renter') || currentUser.meta.isPaid)
            ) {
              configure({
                app_id: '8bb60776',
                user_id: currentUser.id,
                name: currentUser.name,
                email: currentUser.email,
                attributes: {
                  roles: currentUser.roles.join(', '),
                  env: process.env.TARGET_ENV,
                },
                accentColor: '#00d27a',
              });
            }
          }
        }
      } else {
        clearCookie();
      }
    }
  }, [currentUser, ghosting, userIsLoggedIn, clearCookie]);
};

export default useCustomerly;
