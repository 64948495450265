import {Outlet} from 'react-router-dom';

import ScrollContainer from 'components_sb/layout/ScrollContainer/ScrollContainer';
import useNativeInsets from 'hooks/useNativeInsets';
import useScrollPositionFix from 'hooks/useScrollPositionFix';

const NativeLayout = () => {
  const insets = useNativeInsets();

  useScrollPositionFix();

  return (
    <div
      id="native-layout"
      className="w-full h-full flex flex-col"
      style={{paddingTop: insets.top}}>
      <ScrollContainer id="root-scroll-container">
        <Outlet />
      </ScrollContainer>
    </div>
  );
};

export default NativeLayout;
