import LoadingView from 'components/common/LoadingView';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import RightContainer from 'components/walkthrough/common/RightContainer';

const LoadingStep = () => {
  return (
    <Container>
      <LeftSide title="Loading" subtitle="" />
      <RightContainer>
        <LoadingView />
      </RightContainer>
    </Container>
  );
};

export default LoadingStep;
