import {useQuery} from 'react-query';
import {useParams} from 'react-router';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import {API_URL} from 'globals/app-globals';
import {usePageVisit, useTitle} from 'utilities/hooks';

const PropertyTransferConfirmationPage = () => {
  useTitle('Property Transfer Confirmation');
  usePageVisit('PropertyTransferConfirmationPage');

  const {confirmationToken} = useParams();

  if (!confirmationToken) {
    return (
      <PageWrapper title="Property Transfer">
        <Card className="mt-4">
          <CardBody>
            <CardTitle>Property Transfer</CardTitle>
            <p>
              We could not confirm this property transfer as no confirmation
              token was present. Please make sure you click the button in the
              email sent to you.
            </p>
          </CardBody>
        </Card>
      </PageWrapper>
    );
  }

  const {data, isLoading} = useQuery(
    `property-transfer-confirmation-${confirmationToken}`,
    async () => {
      const response = await fetch(
        `${API_URL}/property_transfers/${confirmationToken}/confirm.json`,
      );

      if (response.ok) {
        return response.json();
      } else {
        return 'error';
      }
    },
  );

  if (isLoading) {
    return (
      <PageWrapper title="Property Transfer">
        <LoadingView />
      </PageWrapper>
    );
  } else if (data === 'error') {
    return (
      <PageWrapper title="Property Transfer">
        <Card className="mt-4">
          <CardBody>
            <CardTitle>Property Transfer</CardTitle>
            <p>Sorry, there was an error confirming this property transfer.</p>
          </CardBody>
        </Card>
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Property Transfer">
        <Card className="mt-4">
          <CardBody>
            <CardTitle>Property Transfer</CardTitle>
            <p>
              You have successfully confirmed the transfer of{' '}
              {data.data.address} to {data.data.to_user}, they will need to
              accept this transfer before the property is actually transferred
              to their account. We will handle notifying them.
            </p>
            <p>You can now close this window.</p>
          </CardBody>
        </Card>
      </PageWrapper>
    );
  }
};

export default PropertyTransferConfirmationPage;
