import {useMemo} from 'react';

import {useMediaQuery} from 'react-responsive';

import tailwindConfig from 'utilities/FullTailwindConfig';

const {
  theme: {screens},
} = tailwindConfig;

/**
 * Leverages the breakpoint values configured for Tailwind to
 * enable programatic changes using the same breakpoints.
 */
const useTailwindBreakpoint = (key: string) => {
  const breakpoint = screens[key as keyof typeof screens];
  if (!breakpoint) {
    console.warn('No Tailwind breakpoint exists for:', key);
  }
  const active = useMediaQuery({
    query: `(min-width: ${breakpoint})`,
  });
  return active;
};

export default useTailwindBreakpoint;
