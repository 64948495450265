import {useCallback, useEffect, useState} from 'react';

import ChatIndexCard from 'components/chat2/ChatIndexCard';
import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import {usePusherService} from 'services/PusherService';
import {usePageVisit, useTitle} from 'utilities/hooks';

const ChatIndexPage = () => {
  useTitle('Conversations');
  usePageVisit('ChatIndexPage');

  const {channels, channelsLoaded} = usePusherService();

  return !channelsLoaded ? (
    <PageWrapper title="Chat">
      <LoadingView />
    </PageWrapper>
  ) : (
    <PageWrapper
      title="Chat"
      buttons={[{text: 'New Conversation', href: 'new'}]}>
      <Card>
        <CardBody>
          <CardTitle>Your Property Chats and Conversations</CardTitle>
          <div className="mt-2">
            {channels &&
              channels.map((channel, index) => (
                <ChatIndexCard key={index} channel={channel} />
              ))}
          </div>
        </CardBody>
      </Card>
    </PageWrapper>
  );
};

export default ChatIndexPage;
