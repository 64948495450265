import {useEffect, useState} from 'react';

import {useQueryClient} from 'react-query';

import advertiseIcon from 'assets/img/onboarding/onboarding_types/advertise.png';
import migrateIcon from 'assets/img/onboarding/onboarding_types/migrate.png';
import newIcon from 'assets/img/onboarding/onboarding_types/new.png';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightFooter from 'components/walkthrough/common/RightFooter';
import RightSideText from 'components/walkthrough/common/RightSideText';
import Property from 'models/properties/Property';
import TrackingService from 'services/TrackingService';

const OnboardTypeStep = ({property}: {property: Property}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.AddProperty_StartOnboardTypeStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const [selectedType, setSelectedType] = useState(
    property.noTenancyActionType || '',
  );
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const savePropertyType = async () => {
    if (selectedType) {
      setIsLoading(true);
      property.noTenancyActionType = selectedType;
      property.lastOnboardingStepCompleted = 'select_onboarding_type';
      const result = await property.save();

      if (result) {
        /**
         * Track selection of each onboarding type.
         */
        const selectionEvent = {
          advertise: TrackingService.Event.AddProperty_SelectAdvertise,
          migrate: TrackingService.Event.AddProperty_SelectMigrateTenancy,
          new: TrackingService.Event.AddProperty_SelectNewTenancy,
        }[selectedType];
        if (selectionEvent) {
          TrackingService.trackEvent(selectionEvent, {
            propertyId: property.id,
          });
        }

        /**
         * Track general completion of the step.
         */
        TrackingService.trackEvent(
          TrackingService.Event.AddProperty_CompleteOnboardTypeStep,
          {
            propertyId: property.id,
          },
        );

        /**
         * Track completion of the add property flow (since it is then passed to
         * either the listing, new or migrate tenancy flows).
         */
        TrackingService.trackEvent(
          TrackingService.Event.AddProperty_CompleteFlow,
          {
            propertyId: property.id,
          },
        );

        /**
         * Track starting the new flow.
         */
        const newFlowEvent = {
          advertise: TrackingService.Event.ListProperty_StartFlow,
          migrate: TrackingService.Event.MigrateTenancy_StartFlow,
          new: TrackingService.Event.NewTenancy_StartFlow,
        }[selectedType];
        if (newFlowEvent) {
          TrackingService.trackEvent(newFlowEvent, {
            propertyId: property.id,
          });
        }

        queryClient.setQueryData(['new-property', property.id], property);
      } else {
        console.log(property.errors);
      }
      setIsLoading(false);
    }
  };

  const options = [
    {
      name: 'Advertise my rental property',
      id: 'advertise',
      description: 'List your property on Keyhook and TradeMe for free.',
      icon: advertiseIcon,
    },
    {
      name: 'Self-manage my current tenancy',
      id: 'migrate',
      description:
        'Use Keyhook to perform your next inspection, record financials, and more!',
      icon: migrateIcon,
    },
    {
      name: 'Start a new tenancy from scratch',
      id: 'new',
      description:
        'Create a lease, invite a tenant, file their bond and self-manage expertly.',
      icon: newIcon,
    },
  ];

  const title = 'What do you want to do?';

  return (
    <Container>
      <LeftSide
        title={title}
        subtitle={
          <span>
            Still unsure?{' '}
            <a
              className="link link-primary text-white"
              target="_blank"
              href="https://help.keyhook.com/account-management/selecting-a-tenancy-option">
              Find out more here.
            </a>
          </span>
        }
      />
      <RightContainer>
        <RightSideText
          title={title}
          subtitle={
            <span>
              Still unsure?{' '}
              <a
                target="_blank"
                className="link link-primary"
                href="https://help.keyhook.com/account-management/selecting-a-tenancy-option">
                Find out more here.
              </a>
            </span>
          }
        />
        <div className="flex flex-col items-center space-y-4">
          {options.map((type, index) => (
            <div
              onClick={() => setSelectedType(type.id)}
              className={
                'flex flex-col justify-center px-4 py-6 border-2 rounded-xl w-full md:w-1/2 cursor-pointer ' +
                (selectedType === type.id
                  ? 'border-neutral bg-gray-100'
                  : 'border-gray-300 bg-white')
              }
              key={index}>
              <div className="flex justify-start items-center gap-4">
                <img src={type.icon} alt={type.name} className="w-8 h-8" />
                <div>
                  <p
                    className={type.id === selectedType ? 'font-semibold' : ''}>
                    {type.name}
                  </p>
                  <p className="text-sm text-secondary">{type.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <ProgressBox
          nextStep={savePropertyType}
          step={2}
          totalSteps={10}
          previousStep={() => console.log('previous step')}
          previousStepEnabled={false}
          nextStepEnabled={selectedType.length > 0 && !isLoading}
        />
      </RightContainer>
    </Container>
  );
};

export default OnboardTypeStep;
