import React, {useEffect, useRef, useState} from 'react';

import {BiBuildingHouse} from '@react-icons/all-files/bi/BiBuildingHouse';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {useNavigate} from 'react-router';

import autocompleteIcon from 'assets/img/icons/autocomplete_icon.png';
import ClearDataButton from 'components/chifis_theme/Button/ClearDataButton';
import {API_URL} from 'globals/app-globals';

const StartNewListingForm = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [showPopover, setShowPopover] = useState(false);
  const [searchOptions, setSearchOptions] = useState<any[]>([]);

  const [value, setValue] = useState('');
  const [selectedAddress, setSelectedAddress] = useState<any>(null);

  const navigate = useNavigate();

  const placeholder = 'Address';
  const desc = 'Start typing your property address to list your property';

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener('click', eventClickOutsideDiv);
    }
    showPopover && document.addEventListener('click', eventClickOutsideDiv);
    return () => {
      document.removeEventListener('click', eventClickOutsideDiv);
    };
  }, [showPopover]);

  const filter = async (): Promise<any[]> => {
    if (value.length <= 2) {
      return [];
    }

    const url = `${API_URL}/addresses.json?query=${value}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const places = data.addresses;

      setSearchOptions(places);
    } catch (e) {
      setSearchOptions([]);
    }
  };
  const query = AwesomeDebouncePromise(filter, 300);

  useEffect(() => {
    if (value && value.length > 0) {
      query();
      setShowPopover(true);
    }
  }, [value]);

  const selectAddress = async (option: any): Promise<any[]> => {
    if (option) {
      const url = `${API_URL}/addresses/${option.id}.json`;

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        const ad = data.address;
        setValue(`${ad.displayline}, ${ad.suburb}, ${ad.city}`);

        // Give time for the value useEffect hook to run
        // We want to hide the dropdown that has suggestions
        setTimeout(() => {
          setSelectedAddress(ad);
          setShowPopover(false);
          setSearchOptions([]);
        }, 500);
      } catch (e) {
        return [];
      }
    }
  };

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    if (!selectedAddress) {
      return;
    }

    localStorage.setItem(
      'new-listing-address',
      JSON.stringify(selectedAddress),
    );

    navigate('new');
  };

  const renderSearchValue = () => {
    return (
      <>
        {searchOptions.map((item) => (
          <span
            onClick={() => selectAddress(item)}
            key={item.id}
            className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer">
            <span className="block text-neutral-400">
              <BiBuildingHouse className="w-6 h-6" />
            </span>
            <span className="block font-medium text-neutral-700 dark:text-neutral-200">
              {item.text}
            </span>
          </span>
        ))}
      </>
    );
  };

  return (
    <div className="container mt-4">
      <form
        className="w-full relative xl:mt-8 flex flex-col lg:flex-row lg:items-center rounded-3xl lg:rounded-full shadow-xl dark:shadow-2xl bg-white divide-y divide-neutral-200 lg:divide-y-0"
        onSubmit={handleFormSubmit}>
        <div className="relative flex flex-grow" ref={containerRef}>
          <div
            onClick={() => {
              if (searchOptions.length > 0) {
                setShowPopover(true);
              }
              inputRef.current.focus();
            }}
            className={`flex flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
              showPopover ? 'shadow-2xl rounded-full dark:bg-neutral-800' : ''
            }`}>
            <div className="text-neutral-300 dark:text-neutral-400">
              <img className="w-8 h-8" src={autocompleteIcon} />
            </div>
            <div className="flex-grow">
              <input
                className="block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate"
                placeholder={placeholder}
                value={value}
                autoFocus={showPopover}
                onChange={(event) => setValue(event.currentTarget.value)}
                ref={inputRef}
                autoComplete="off"
                id="create-listing-address"
              />
              <label
                className="block mt-0.5 text-sm text-neutral-400 font-light"
                htmlFor="create-listing-address">
                <span className="line-clamp-1">
                  {value ? placeholder : desc}
                </span>
              </label>
              {value && showPopover && (
                <ClearDataButton onClick={() => setValue('')} />
              )}
            </div>
          </div>
          {showPopover && (
            <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
              {renderSearchValue()}
            </div>
          )}
        </div>

        <div className="px-4 py-4 lg:py-0">
          <button
            type="submit"
            className="btn btn-primary rounded-full"
            disabled={!selectedAddress}>
            Add Listing
          </button>
        </div>
      </form>
    </div>
  );
};

export default StartNewListingForm;
