import {useCallback, useState} from 'react';

import FsLightbox from 'fslightbox-react';

import InspectionMediaPreview from 'components/inspection/media_items/InspectionMediaPreview';
import {Modal} from 'components_sb/layout';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import InspectionAction from 'models/inspections/InspectionAction';
import InspectionItem from 'models/inspections/InspectionItem';
import InspectionItemAttachment from 'models/inspections/InspectionItemAttachment';
import {MediableItems} from 'models/inspections/MediableItems';
import useAuth from 'services/useAuth';
import useInspectionStore from 'stores/InspectionStore';
import {titleize} from 'utilities/StringHelpers';

import InspectionAddActionFromAttachmentModal from '../InspectionAddActionFromAttachmentModal';
import InspectionAddNotesFromAttachmentModal from '../InspectionAddNotesFromAttachmentModal';

const {useModal} = Modal.Imperative;

const InspectionGallery = ({
  item,
  sources,
}: {
  item: InspectionItem;
  sources: MediableItems;
}) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const {currentUser} = useAuth();

  const openModal = useModal();

  const {activeAccountRole} = useLocalUserSettings();

  const inspection = useInspectionStore((state) => state.inspection);

  const handleClick = (index: number) => {
    setSelectedIndex(index + 1);
    setLightboxOpen(!lightboxOpen);
  };

  const onAddNoteClicked = useCallback(
    (attachment: any) => {
      // setLightboxOpen(false);
      openModal(InspectionAddNotesFromAttachmentModal, {
        inspectionItem: item,
        attachment,
      });
    },
    [setLightboxOpen, openModal, item],
  );

  const onAddActionClicked = useCallback(
    (attachment: any) => {
      // setLightboxOpen(false);
      openModal(InspectionAddActionFromAttachmentModal, {
        inspectionItem: item,
        attachment,
      });
    },
    [setLightboxOpen, openModal, item],
  );

  return (
    <div>
      <div className="flex flex-wrap flex-start gap-4 my-4">
        {sources.map((source, index) => (
          <InspectionMediaPreview
            source={source}
            key={index}
            onClick={() => handleClick(index)}
          />
        ))}
      </div>

      <FsLightbox
        toggler={lightboxOpen}
        sources={sources.map((source) => source.attachment)}
        slide={selectedIndex}
        disableThumbs
        captions={sources.map((source) => {
          if (source.isType('inspection_item_attachments')) {
            const attachment = source as InspectionItemAttachment;
            return (
              <div key={attachment.id} className="flex flex-col z-50">
                <h5 className="text-white text-lg">
                  {inspection.reportType === 'detailed'
                    ? `${item.room} - ${item.name}`
                    : item.room}
                </h5>
                <p className="text-white text-md">{attachment.notes}</p>

                {activeAccountRole &&
                  activeAccountRole === 'Landlord' &&
                  inspection &&
                  inspection.status !== 'complete' && (
                    <div className="flex justify-between mt-2">
                      <button
                        className="btn btn-info btn-sm"
                        type="button"
                        onClick={() => onAddNoteClicked(attachment)}>
                        {currentUser.id === attachment.userId &&
                        attachment.notes &&
                        attachment.notes.length > 0
                          ? 'Edit Note'
                          : 'Add Note'}
                      </button>

                      <button
                        className="btn btn-warning btn-sm"
                        type="button"
                        onClick={() => onAddActionClicked(attachment)}>
                        Add Action
                      </button>
                    </div>
                  )}
              </div>
            );
          } else {
            const attachment = source as InspectionAction;
            return (
              <div key={attachment.id} className="flex flex-col">
                <h5 className="text-white text-lg">
                  {item.room} - {item.name}
                </h5>
                <p className="text-white text-md">
                  <strong>{titleize(attachment.actionType)} Action: </strong>
                  {attachment.action}
                </p>
              </div>
            );
          }
        })}
      />
    </div>
  );
};

export default InspectionGallery;
