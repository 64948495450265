import {Form, Formik, type FormikHelpers} from 'formik';
import {Navigate, useNavigate} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {InputField, SubmitButton} from 'components/forms_fields';
import {API_URL} from 'globals/app-globals';
import useAuth from 'services/useAuth';

type FormValues = {
  email: string;
};

const SetRealEmailPage = () => {
  const navigate = useNavigate();

  const {userIsLoggedIn, currentUser} = useAuth();

  /**
   * Redirect to the dashboard if there is no need for
   * the user to set their email on this page
   */
  if (
    userIsLoggedIn &&
    !currentUser.email.endsWith('@privaterelay.appleid.com')
  ) {
    navigate('/');
  }

  const saveUserDetails = async (
    formData: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    const response = await fetch(
      `${API_URL}/users/${currentUser!.id}.jsonapi`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-USER-TOKEN': currentUser!.meta.authenticationToken,
          'X-USER-EMAIL': currentUser!.email,
        },
        body: JSON.stringify({
          data: {
            type: 'users',
            id: currentUser!.id,
            attributes: formData,
          },
        }),
      },
    );

    if (response.ok) {
      toast.success('Your email address has been successfully updated!');

      localStorage.setItem('apple-relay-new-email', formData.email);

      navigate('/register/confirm-email');
    } else {
      const body = await response.json();
      if (body.errors) {
        const err = body.errors[0];
        if (err) {
          actions.setFieldError('email', err.detail);
        }
      }
    }
    actions.setSubmitting(false);
  };

  if (currentUser) {
    return (
      <div className="max-w-md mx-auto pl-8 pr-8 lg:pl-24 lg:pr-24 mb-24 lg:mb-32 border-2 border-gray-200 my-20 rounded-xl flex justify-center">
        <div className="min-w-[300px]">
          <h2 className="mt-8 mb-2 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Set Your Email
          </h2>

          <div className="mt-4 mb-8">
            <p className="mb-4 text-gray-500">
              You have used a private relay email to register. On occasion,
              Keyhook submits paperwork to Tenancy Services on your behalf.
              Please enter a valid email address to be used when completing
              these documents. You may view our{' '}
              <a
                href="https://www.keyhook.com/privacy/"
                target="_blank"
                className="link link-primary">
                Privacy Policy here.
              </a>
            </p>

            <div className="mt-4">
              <Formik
                initialValues={{
                  email: '',
                }}
                onSubmit={saveUserDetails}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email().required().label('Email'),
                })}>
                {(formik) => (
                  <Form>
                    <div className="mt-4">
                      <InputField
                        name="email"
                        label="Email Address"
                        formik={formik}
                        placeholder="Eg: john.smith@gmail.com"
                        type="email"
                      />

                      <div className="mt-4">
                        <SubmitButton
                          text="Save"
                          submittingText="Saving Email"
                          formik={formik}
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Navigate to="/login" />;
  }
};

export default SetRealEmailPage;
