import React, {useState} from 'react';

import {AdjustmentsIcon} from '@heroicons/react/outline';
import {Form, Formik, type FormikHelpers} from 'formik';
import moment from 'moment';
import {useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {DateField, InputField, SubmitButton} from 'components/forms_fields';
import MoneyField from 'components/forms_fields/MoneyField';
import ActionCard from 'components/property/landlord/profile_blocks/ActionCard';
import ProfileItemModal from 'components/property/landlord/profile_blocks/ProfileItemModal';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';

const TenancyInformationAction = ({
  property,
  tenancy,
  storeKey,
}: {
  property: Property;
  tenancy: Tenancy;
  storeKey: string;
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = async (formValues: any, actions: FormikHelpers<any>) => {
    const prop = property;

    tenancy.assignAttributes(formValues);
    prop.markStepAsCompleted(storeKey);

    const result1 = await tenancy.save();
    const result2 = await prop.save();

    if (result1 && result2) {
      queryClient.setQueryData(`landlord-property-detail-${prop.id}`, prop);
      toast.success('Property successfully updated!');
      setModalVisible(false);
    } else {
      for (const key of Object.keys(prop.errors)) {
        const message = prop.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  const title = 'Tenancy Information';
  const subtitle = 'Add more information about this tenancy.';
  const iconBgColor = 'bg-cyan-300';

  return (
    <div>
      <ActionCard
        title={title}
        subtitle={subtitle}
        IconClass={AdjustmentsIcon}
        iconBgColor={iconBgColor}
        onClick={() => setModalVisible(true)}
      />
      <ProfileItemModal
        modalIsOpen={modalVisible}
        setModalIsOpen={setModalVisible}
        title={title}
        subtitle={subtitle}
        IconClass={AdjustmentsIcon}
        bgColor={iconBgColor}
        form={
          <Formik
            initialValues={{
              bond: tenancy.bond || undefined,
              externalBond: tenancy.externalBondId || undefined,
              endDate: tenancy.endDate || undefined,
            }}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              bond: Yup.number().min(0).required().label('Bond'),
              externalBondId: Yup.string()
                .optional()
                .nullable()
                .label('Tenancy Services Bond Id'),
              endDate: Yup.string()
                .nullable()
                .optional()
                .label('End Date')
                .test(
                  'should-be-greather-than-start-date',
                  'Should be after the Start Date',
                  (value) => {
                    if (!value) {
                      return true;
                    }
                    const startDate = tenancy.startDate;
                    if (!startDate) {
                      return true;
                    }

                    return moment(value) > moment(startDate);
                  },
                ),
            })}>
            {(formik) => (
              <Form className="md:h-full flex-1">
                <div className="flex flex-col justify-between h-full">
                  <div className="flex flex-col justify-center grow space-y-2">
                    <MoneyField
                      label="Bond Amount"
                      name="bond"
                      formik={formik}
                      placeholder="Eg: 1200.00"
                      type="number"
                    />

                    <DateField
                      label="End Date"
                      name="endDate"
                      formik={formik}
                      minDate={new Date()}
                      maxDate={new Date('01-01-2100')}
                      helpText="You can leave this blank if your tenancy is periodic with no
                    fixed end date set."
                    />

                    <InputField
                      label="Tenancy Services Bond Id"
                      name="externalBondId"
                      formik={formik}
                      placeholder="Eg: 1234567-890"
                      helpText="This is optional but will help with filing your bond refund. It can be found as the 'Bond Id' on your bond receipt letter."
                    />
                  </div>
                  <SubmitButton
                    formik={formik}
                    text="Save"
                    submittingText="Saving"
                    className={`!rounded-full ${iconBgColor} border-none mt-6`}
                  />
                </div>
              </Form>
            )}
          </Formik>
        }
      />
    </div>
  );
};

export default TenancyInformationAction;
