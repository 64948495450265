import React, {useEffect, useState} from 'react';

import {Browser} from '@capacitor/browser';
import {Capacitor} from '@capacitor/core';
import {Keyboard} from '@capacitor/keyboard';

import {Link} from 'components_sb/navigation';
import {TARGET_ENV} from 'globals/app-globals';
import useAuth from 'services/useAuth';

type NextStep = () => void;

const ProgressBox = ({
  step,
  nextStep,
  previousStep,
  nextStepEnabled = false,
  previousStepEnabled = true,
  totalSteps = 11,
}: {
  totalSteps?: number;
  step: number;
  nextStep: NextStep;
  previousStep: NextStep;
  nextStepEnabled?: boolean;
  previousStepEnabled?: boolean;
}) => {
  const [keyboardVisible, setKeyboardVisible] = useState(false);
  const percent = Math.ceil((step / totalSteps) * 100);

  const {currentUser} = useAuth();

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      // Purposefully use WillShow and DidHide
      // This is to mitigate some flickering.
      Keyboard.addListener('keyboardWillShow', (info) => {
        setKeyboardVisible(true);
      });

      Keyboard.addListener('keyboardDidHide', () => {
        setKeyboardVisible(false);
      });

      return () => {
        Keyboard.removeAllListeners();
      };
    }
  }, []);

  const showHelp = async () => {
    if (Capacitor.isNativePlatform()) {
      const params = new URLSearchParams();
      params.set('user_id', currentUser.id);
      params.set('name', currentUser.name);
      params.set('email', currentUser.email);
      params.set('env', TARGET_ENV);
      let url = `https://livesupport.keyhook.com?${params.toString()}`;
      url = url + currentUser.roles.map((role) => `&roles=${role}`).join('');

      await Browser.open({url: url});
    } else {
      if ((window as any).customerly) {
        (window as any).customerly.open();
      }
    }
  };

  // We hide this element if the Capacitor Keyboard is visible.
  // This is because it sits on top of the keyboard and intefers
  // with various fields like autocomplete and selects.
  return (
    <div
      className={`bg-base-100 p-2 mlg:pb-6 fixed lg:absolute w-full xl:w-2/3 right-0 bottom-0 z-100 ${
        keyboardVisible ? 'hidden' : ''
      }`}
      id="onboard-progress-box">
      <div className="flex justify-between items-center">
        <div className="flex justify-start items-start ml-2">
          <div
            className="radial-progress text-success border-white border-2"
            style={{'--value': percent, '--size': '3rem'} as any}>
            {percent}%
          </div>
          <div className="flex flex-col ml-2">
            <strong>Step {step}</strong>
            <a className="link link-primary" onClick={showHelp}>
              Need Help?
            </a>
          </div>
        </div>

        <div className="flex justify-between items-center mx-2 gap-4">
          <button
            className="btn btn-neutral btn-ghost btn-sm"
            disabled={!previousStepEnabled}
            onClick={previousStep}
            type="button">
            Back
          </button>
          <button
            className="btn btn-primary btn-sm"
            disabled={!nextStepEnabled}
            onClick={nextStep}
            type="button">
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProgressBox;
