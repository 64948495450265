import moment from 'moment';
import {FaUser} from 'react-icons/fa';
import {useQueryClient} from 'react-query';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';

import CardTitle from 'components/common/card/CardTitle';
import MobileListItem from 'components_sb/lists/MobileListItem/MobileListItem';
import {Paragraph} from 'components_sb/typography';
import TenancyInvite from 'models/properties/TenancyInvite';
import TenancyRequest from 'models/properties/TenancyRequest';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';

const TenancyRequestsTable = ({
  tenancyRequests,
  tenancyInvites,
  propertyId,
}: {
  tenancyRequests: TenancyRequest[];
  tenancyInvites: TenancyInvite[];
  propertyId: string;
}) => {
  const queryClient = useQueryClient();
  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const confirmDeleteTenancyInvite = async (invite: TenancyInvite) => {
    setConfirmationOptions({
      title: 'Delete Tenant Invite',
      message: 'Are you sure you want to delete the invite for this tenant?',
      buttonTitle: 'Delete',
      action: () => deleteTenancyInvite(invite),
      color: 'error',
    });
  };

  const deleteTenancyInvite = async (invite: TenancyInvite) => {
    const result = await invite.destroy();
    if (result) {
      queryClient.invalidateQueries(`landlord-property-detail-${propertyId}`);

      toast.success('The invitation has been deleted.');
    }
  };

  if (tenancyRequests.length > 0 || tenancyInvites.length > 0) {
    return (
      <div>
        <CardTitle>Tenancy Invitations</CardTitle>
        <Paragraph>Pending invitations:</Paragraph>
        <div className="overflow-x-auto hidden lg:block">
          <table className="table w-full">
            <thead>
              <tr>
                <th>Tenant</th>
                <th>Sent</th>
                <th>Signed Up To Keyhook</th>
                <th>Accepted Invite</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tenancyRequests.map((req) => (
                <tr key={req.id}>
                  <td>{req.renter ? req.renter.name : req.renterEmail}</td>
                  <td>{moment(req.createdAt).fromNow()}</td>
                  <td>{req.renter ? 'Yes' : 'No'}</td>
                  <td>{req.accepted ? 'Yes' : 'No'}</td>
                  <td>
                    <Link
                      to={`tenancy-requests/${req.id}/edit`}
                      className="link link-primary">
                      Edit
                    </Link>
                  </td>
                </tr>
              ))}
              {tenancyInvites.map((req) => (
                <tr key={req.id}>
                  <td>{req.renter ? req.renter.name : req.email}</td>
                  <td>{moment(req.createdAt).fromNow()}</td>
                  <td>{req.renter ? 'Yes' : 'No'}</td>
                  <td>{req.accepted ? 'Yes' : 'No'}</td>
                  <td>
                    <a
                      className="link link-primary"
                      onClick={() => confirmDeleteTenancyInvite(req)}>
                      Delete
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="block lg:hidden">
          <div className="flex flex-col gap-2">
            {tenancyRequests.map((request) => (
              <MobileListItem
                key={request.id}
                link={`tenancy-requests/${request.id}/edit`}
                title={
                  request.renter ? request.renter.name : request.renterEmail
                }
                icon={FaUser}
                subtitle={
                  <>
                    <p>Signed up to Keyhook: {request.renter ? 'Yes' : 'No'}</p>
                    <p>Accepted Invite: {request.accepted ? 'Yes' : 'No'}</p>
                  </>
                }
              />
            ))}

            {tenancyInvites.map((request) => (
              <MobileListItem
                key={request.id}
                link={`tenancy-requests/${request.id}/edit`}
                title={request.renter ? request.renter.name : request.email}
                icon={FaUser}
                subtitle={
                  <>
                    <p>Signed up to Keyhook: {request.renter ? 'Yes' : 'No'}</p>
                    <p>Accepted Invite: {request.accepted ? 'Yes' : 'No'}</p>

                    <button
                      className="btn btn-sm btn-warning mt-2"
                      onClick={() => confirmDeleteTenancyInvite(request)}>
                      Delete
                    </button>
                  </>
                }
              />
            ))}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default TenancyRequestsTable;
