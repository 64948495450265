import moment from 'moment';
import {useQueryClient} from 'react-query';
import {toast} from 'react-toastify';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {DATE_FORMAT} from 'utilities/DateHelpers';

const RentPriceChangesCard = ({
  property,
  tenancy,
}: {
  property: Property;
  tenancy?: Tenancy;
}) => {
  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const queryClient = useQueryClient();

  const confirmUndoRentPriceChange = async () => {
    setConfirmationOptions({
      title: 'Remove Rent Change',
      message: 'Are you sure you want to undo this rent change?',
      buttonTitle: 'Remove',
      action: undoRentPriceChange,
      color: 'error',
    });
  };

  const undoRentPriceChange = async () => {
    const rentChange = tenancy.unprocessedRentPriceChanges[0];
    if (rentChange) {
      const result = await rentChange.destroy();
      if (result) {
        toast.success('You have successfully removed this rent change!');
        await queryClient.invalidateQueries(
          `landlord-property-detail-${property.id}`,
        );
      }
    }
  };

  if (property.tenanciesCount === 0) {
    return null;
  }
  if (!tenancy) {
    return null;
  }

  if (tenancy.status !== 'active' && tenancy.status !== 'active_periodic') {
    return null;
  }

  if (
    tenancy.unprocessedRentPriceChanges &&
    tenancy.unprocessedRentPriceChanges.length > 0
  ) {
    const rentChange = tenancy.unprocessedRentPriceChanges[0];

    return (
      <Card className="mt-8">
        <CardBody>
          <CardTitle>Rent Change</CardTitle>
          <p>
            The rent for this tenancy will change to ${rentChange.newTotalRent}{' '}
            from {moment(rentChange.effectiveDate).format(DATE_FORMAT)}.
          </p>

          <button className="btn mt-4" onClick={confirmUndoRentPriceChange}>
            Undo this rent change
          </button>
        </CardBody>
      </Card>
    );
  }
  return null;
};

export default RentPriceChangesCard;
