import {useEffect, useRef} from 'react';

import {motion} from 'framer-motion';

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const pageTransition = {
  type: 'tween',
  ease: 'linear',
  duration: 0.5,
};

const RightContainer = ({children, align}: {children: any; align?: string}) => {
  const spacerRef = useRef<HTMLDivElement>(null);

  // This ensures that there is enough height in the main content area
  // Otherwise elements could end up behind the progress box and won't
  // be visble.
  // Multiplied by 1.5 to just add a bit of extra padding for a safety margin.
  // Otherwise sometimes it rests right up at the top of the progress box
  // and sometimes extra scrolling isnt needed due to overflow for popup type elements.
  useEffect(() => {
    if (spacerRef.current) {
      const progressBox = document.querySelector('#onboard-progress-box');
      if (progressBox) {
        spacerRef.current.style.height = `${progressBox.clientHeight * 1.5}px`;
      }
    }
  }, [spacerRef]);

  return (
    <div
      className={`flex-1 flex w-full lg:w-2/3 justify-start items-${
        align || 'center'
      } py-10 bg-white overflow-y-scroll`}
      id="onboard-right-container">
      <motion.div
        className="w-full"
        initial="initial"
        animate="in"
        variants={pageVariants}
        transition={pageTransition}>
        <div className="mmd:container">{children}</div>
        <div id="onboard-right-spacer" ref={spacerRef}></div>
      </motion.div>
    </div>
  );
};

export default RightContainer;
