import {HTMLProps} from 'react';

import {type FormikProps} from 'formik';
import moment from 'moment';
import NumberFormat from 'react-number-format';

import {errorsFor, valueFor} from 'components/forms_fields/Helpers';
import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import TrackingService from 'services/TrackingService';

interface DOBFieldProps extends HTMLProps<HTMLInputElement> {
  formik: FormikProps<any>;
  label: string;
  name: string;
  helpText?: string;
}

export const DOBField = ({
  formik,
  label,
  name,
  helpText,
  ...rest
}: DOBFieldProps) => {
  const classN = formik.errors[name] ? ' border border-danger' : '';
  if (rest.className) {
    rest.className += classN;
  } else {
    rest.className = classN;
  }

  const val = valueFor(formik, name);
  const errors = errorsFor(formik, name);

  const handleBlur = () => {
    formik.handleBlur(name);

    const v = formik.values[name] as string | undefined | null;
    if (v && v.toString().length > 0) {
      TrackingService.trackEvent(TrackingService.Event.FillField, {
        field: name,
      });
    }
  };

  return (
    <>
      <label className="label flex justify-start items-center">
        <span className="label-text mr-2">{label}</span>
        <HelpTextPresenter helpText={helpText} />
      </label>
      <NumberFormat
        className="input input-bordered w-full"
        format="##/##/####"
        placeholder="DD/MM/YYYY"
        mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
        defaultValue={val ? moment(val).format('DD/MM/YYYY') : ''}
        onValueChange={(values) => {
          formik.setFieldValue(name, values.formattedValue);
        }}
        onBlur={handleBlur}
      />
      {errors && (
        <span className="block text-red-500">
          <small>{errors}</small>
        </span>
      )}
    </>
  );
};
