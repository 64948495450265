/**
 *  The keys for the event data in the constants file.
 */
enum Event {
  // [========== General ==========]
  PageView = 'PAGE_VIEW',
  ClickCopyButton = 'CLICK_COPY_BUTTON',

  // [========== Landlord Property / Tenancy Setup ==========]
  // [Add Property Flow]
  AddProperty_StartFlow = 'ADD_PROPERTY:START_FLOW',
  AddProperty_CompleteFlow = 'ADD_PROPERTY:COMPLETE_FLOW',
  // => Address
  AddProperty_StartAddressStep = 'ADD_PROPERTY:START_ADDRESS_STEP',
  AddProperty_CompleteAddressStep = 'ADD_PROPERTY:COMPLETE_ADDRESS_STEP',
  // => Onboard type
  AddProperty_SelectAdvertise = 'ADD_PROPERTY:SELECT_ADVERTISE',
  AddProperty_SelectMigrateTenancy = 'ADD_PROPERTY:SELECT_MIGRATE_TENANCY',
  AddProperty_SelectNewTenancy = 'ADD_PROPERTY:SELECT_NEW_TENANCY',
  AddProperty_StartOnboardTypeStep = 'ADD_PROPERTY:START_ONBOARD_TYPE_STEP',
  AddProperty_CompleteOnboardTypeStep = 'ADD_PROPERTY:COMPLETE_ONBOARD_TYPE_STEP',

  // [List Property Flow]
  ListProperty_StartFlow = 'LIST_PROPERTY:START_FLOW',
  ListProperty_CompleteFlow = 'LIST_PROPERTY:COMPLETE_FLOW',
  // => Property type
  ListProperty_SelectApartment = 'LIST_PROPERTY:SELECT_APARTMENT',
  ListProperty_SelectHouse = 'LIST_PROPERTY:SELECT_HOUSE',
  ListProperty_SelectTownhouse = 'LIST_PROPERTY:SELECT_TOWNHOUSE',
  ListProperty_SelectUnit = 'LIST_PROPERTY:SELECT_UNIT',
  ListProperty_StartPropertyTypeStep = 'LIST_PROPERTY:START_PROPERTY_TYPE_STEP',
  ListProperty_CompletePropertyTypeStep = 'LIST_PROPERTY:COMPLETE_PROPERTY_TYPE_STEP',
  // => Specify rooms (aka general information)
  ListProperty_StartRoomsStep = 'LIST_PROPERTY:START_ROOMS_STEP',
  ListProperty_CompleteRoomsStep = 'LIST_PROPERTY:COMPLETE_ROOMS_STEP',
  // => Property requirements
  ListProperty_StartPropertyRequirementsStep = 'LIST_PROPERTY:START_PROPERTY_REQUIREMENTS_STEP',
  ListProperty_CompletePropertyRequirementsStep = 'LIST_PROPERTY:COMPLETE_PROPERTY_REQUIREMENTS_STEP',
  // => Rent details
  ListProperty_StartRentDetailsStep = 'LIST_PROPERTY:START_RENT_DETAILS_STEP',
  ListProperty_CompleteRentDetailsStep = 'LIST_PROPERTY:COMPLETE_RENT_DETAILS_STEP',
  // => Rental availability
  ListProperty_StartRentalAvailabilityStep = 'LIST_PROPERTY:START_RENTAL_AVAILABILITY_STEP',
  ListProperty_CompleteRentalAvailabilityStep = 'LIST_PROPERTY:COMPLETE_RENTAL_AVAILABILITY_STEP',
  // => Key property features
  ListProperty_StartKeyPropertyFeaturesStep = 'LIST_PROPERTY:START_KEY_PROPERTY_FEATURES_STEP',
  ListProperty_CompleteKeyPropertyFeaturesStep = 'LIST_PROPERTY:COMPLETE_KEY_PROPERTY_FEATURES_STEP',
  // => Upload photos
  ListProperty_StartUploadPhotosStep = 'LIST_PROPERTY:START_UPLOAD_PHOTOS_STEP',
  ListProperty_CompleteUploadPhotosStep = 'LIST_PROPERTY:COMPLETE_UPLOAD_PHOTOS_STEP',
  // => Add description
  ListProperty_StartDescribePropertyStep = 'LIST_PROPERTY:START_DESCRIBE_PROPERTY_STEP',
  ListProperty_CompleteDescribePropertyStep = 'LIST_PROPERTY:COMPLETE_DESCRIBE_PROPERTY_STEP',
  // => Preview listing
  ListProperty_StartPreviewListingStep = 'LIST_PROPERTY:START_PREVIEW_LISTING_STEP',
  ListProperty_CompletePreviewListingStep = 'LIST_PROPERTY:COMPLETE_PREVIEW_LISTING_STEP',
  // => Publish listing
  ListProperty_StartPublishListingStep = 'LIST_PROPERTY:START_PUBLISH_LISTING_STEP',
  ListProperty_CompletePublishListingStep = 'LIST_PROPERTY:COMPLETE_PUBLISH_LISTING_STEP',

  // [Migrate Tenancy Flow]
  MigrateTenancy_StartFlow = 'MIGRATE_TENANCY:START_FLOW',
  MigrateTenancy_CompleteFlow = 'MIGRATE_TENANCY:COMPLETE_FLOW',
  // => Rent details
  MigrateTenancy_StartRentDetailsStep = 'MIGRATE_TENANCY:START_RENT_DETAILS_STEP',
  MigrateTenancy_CompleteRentDetailsStep = 'MIGRATE_TENANCY:COMPLETE_RENT_DETAILS_STEP',
  // => Invite tenant
  MigrateTenancy_StartInviteTenantStep = 'MIGRATE_TENANCY:START_INVITE_TENANT_STEP',
  MigrateTenancy_CompleteInviteTenantStep = 'MIGRATE_TENANCY:COMPLETE_INVITE_TENANT_STEP',
  // => Rent collection (aka banking)
  MigrateTenancy_StartRentCollectionStep = 'MIGRATE_TENANCY:START_RENT_COLLECTION_STEP',
  MigrateTenancy_CompleteRentCollectionStep = 'MIGRATE_TENANCY:COMPLETE_RENT_COLLECTION_STEP',
  // => Select start/commencement date
  MigrateTenancy_StartCommencementDateStep = 'MIGRATE_TENANCY:START_COMMENCEMENT_DATE_STEP',
  MigrateTenancy_CompleteCommencementDateStep = 'MIGRATE_TENANCY:COMPLETE_COMMENCEMENT_DATE_STEP',

  // [New Tenancy Flow]
  NewTenancy_StartFlow = 'NEW_TENANCY:START_FLOW',
  NewTenancy_CompleteFlow = 'NEW_TENANCY:COMPLETE_FLOW',
  // => Property type
  NewTenancy_SelectApartment = 'NEW_TENANCY:SELECT_APARTMENT',
  NewTenancy_SelectHouse = 'NEW_TENANCY:SELECT_HOUSE',
  NewTenancy_SelectTownhouse = 'NEW_TENANCY:SELECT_TOWNHOUSE',
  NewTenancy_SelectUnit = 'NEW_TENANCY:SELECT_UNIT',
  NewTenancy_StartPropertyTypeStep = 'NEW_TENANCY:START_PROPERTY_TYPE_STEP',
  NewTenancy_CompletePropertyTypeStep = 'NEW_TENANCY:COMPLETE_PROPERTY_TYPE_STEP',
  // => Specify rooms (aka general information)
  NewTenancy_StartRoomsStep = 'NEW_TENANCY:START_ROOMS_STEP',
  NewTenancy_CompleteRoomsStep = 'NEW_TENANCY:COMPLETE_ROOMS_STEP',
  // => Property requirements
  NewTenancy_StartPropertyRequirementsStep = 'NEW_TENANCY:START_PROPERTY_REQUIREMENTS_STEP',
  NewTenancy_CompletePropertyRequirementsStep = 'NEW_TENANCY:COMPLETE_PROPERTY_REQUIREMENTS_STEP',
  // => Rent details
  NewTenancy_StartRentDetailsStep = 'NEW_TENANCY:START_RENT_DETAILS_STEP',
  NewTenancy_CompleteRentDetailsStep = 'NEW_TENANCY:COMPLETE_RENT_DETAILS_STEP',
  // => Rental availability
  NewTenancy_StartRentalAvailabilityStep = 'NEW_TENANCY:START_RENTAL_AVAILABILITY_STEP',
  NewTenancy_CompleteRentalAvailabilityStep = 'NEW_TENANCY:COMPLETE_RENTAL_AVAILABILITY_STEP',
  // => Landlord details
  NewTenancy_StartLandlordDetailsStep = 'NEW_TENANCY:START_LANDLORD_DETAILS_STEP',
  NewTenancy_CompleteLandlordDetailsStep = 'NEW_TENANCY:COMPLETE_LANDLORD_DETAILS_STEP',
  // => Chattels
  NewTenancy_StartChattelsStep = 'NEW_TENANCY:START_CHATTELS_STEP',
  NewTenancy_CompleteChattelsStep = 'NEW_TENANCY:COMPLETE_CHATTELS_STEP',
  // => Insurance
  NewTenancy_StartInsuranceStep = 'NEW_TENANCY:START_INSURANCE_STEP',
  NewTenancy_CompleteInsuranceStep = 'NEW_TENANCY:COMPLETE_INSURANCE_STEP',
  // => Build lease
  NewTenancy_StartBuildLeaseStep = 'NEW_TENANCY:START_BUILD_LEASE_STEP',
  NewTenancy_CompleteBuildLeaseStep = 'NEW_TENANCY:COMPLETE_BUILD_LEASE_STEP',
  // => Preview and sign lease
  NewTenancy_StartPreviewLeaseStep = 'NEW_TENANCY:START_PREVIEW_LEASE_STEP',
  NewTenancy_CompletePreviewLeaseStep = 'NEW_TENANCY:COMPLETE_PREVIEW_LEASE_STEP',
  // => Healthy homes
  NewTenancy_StartHealthyHomesStep = 'NEW_TENANCY:START_HEALTHY_HOMES_STEP',
  NewTenancy_CompleteHealthyHomesStep = 'NEW_TENANCY:COMPLETE_HEALTHY_HOMES_STEP',
  // => Send lease and invite tenants
  NewTenancy_StartInviteTenantsStep = 'NEW_TENANCY:START_INVITE_TENANTS_STEP',
  NewTenancy_SaveInviteTenantsStep = 'NEW_TENANCY:SAVE_INVITE_TENANTS_STEP',
  NewTenancy_CompleteInviteTenantsStep = 'NEW_TENANCY:COMPLETE_INVITE_TENANTS_STEP',

  // [========== Landlord General ==========]
  AddPreferredTradesperson = 'ADD_PREFERRED_TRADESPERSON',

  // [========== Tenant General ==========]
  // [- ADD GENERAL TENANT EVENTS HERE -]

  // [========== Accounts ==========]
  Login = 'LOGIN',
  Register = 'REGISTER',
  VerifyEmail = 'VERIFY_EMAIL',
  ForgotPassword = 'FORGOT_PASSWORD',
  CreateLandlordProfile = 'CREATE_LANDLORD_PROFILE',
  CreateTenantProfile = 'CREATE_TENANT_PROFILE',

  // [========== Chat ==========]
  SendTextChatMessage = 'SEND_TEXT_CHAT_MESSAGE',
  SendImageChatMessage = 'SEND_IMAGE_CHAT_MESSAGE',

  // [========== Fields ==========]
  SelectAutocompleteAddress = 'SELECT_AUTOCOMPLETE_ADDRESS',
  SelectFile = 'SELECT_FILE',
  SelectImage = 'SELECT_IMAGE',
  FillField = 'FILL_FIELD',
  SelectOption = 'SELECT_OPTION',
  ToggleOption = 'TOGGLE_OPTION',
  SetToggleButtonGroup = 'SET_TOGGLE_BUTTON_GROUP',
  SaveSignature = 'SAVE_SIGNATURE',
  ClearSignature = 'CLEAR_SIGNATURE',

  // [========== Clauses ==========]
  OpenClauseLibrary = 'OPEN_CLAUSE_LIBRARY',
  CloseClauseLibrary = 'CLOSE_CLAUSE_LIBRARY',
  AddCustomClause = 'ADD_CUSTOM_CLAUSE',
  EditKeyhookClause = 'EDIT_KEYHOOK_CLAUSE',

  // [========== Properties ==========]
  EditProperty = 'EDIT_PROPERTY',

  // [========== Tenancies ==========]
  QuickSetBond = 'QUICK_SET_BOND',
  QuickSetEndDate = 'QUICK_SET_END_DATE',
  PreviewLease = 'PREVIEW_LEASE',
  PreviewBond = 'PREVIEW_BOND',
  ViewLease = 'VIEW_LEASE',
  ViewBond = 'VIEW_BOND',
  SetTenancyData = 'SET_TENANCY_DATA',
  EditTenancy = 'EDIT_TENANCY',
  AcceptNewLease = 'ACCEPT_NEW_LEASE',
  AcceptMigratedLease = 'ACCEPT_MIGRATED_LEASE',
  SplitRentEvenly = 'SPLIT_RENT_EVENLY',
  SplitBondEvenly = 'SPLIT_BOND_EVENLY',
  SetRentBondSplits = 'SET_RENT_BOND_SPLITS',

  // [========== Maintenance Requests ==========]
  CreateMaintenanceRequest = 'CREATE_MAINTENANCE_REQUEST',
  EditMaintenanceRequest = 'EDIT_MAINTENANCE_REQUEST',
  RejectMaintenanceRequest = 'REJECT_MAINTENANCE_REQUEST',
  ApproveMaintenanceRequestQuote = 'APPROVE_MAINTENANCE_REQUEST_QUOTE',
}

export default Event;
