import {ReactNode} from 'react';

import {GoogleOAuthProvider} from '@react-oauth/google';
import {CookiesProvider} from 'react-cookie';
import {QueryClient, QueryClientProvider} from 'react-query';
import {BrowserRouter} from 'react-router-dom';
import {QueryParamProvider} from 'use-query-params';
import {ReactRouter6Adapter} from 'use-query-params/adapters/react-router-6';

import MobileDrawer from 'components/navbar/MobileDrawer';
import {Toaster} from 'components_sb/feedback';
import {Modal} from 'components_sb/layout';
import {InspectionUploaderProvider} from 'providers/InspectionUploader';
import {PushPermissionsProvider} from 'providers/PushPermissions';
import {SpraypaintMiddlewareProvider} from 'providers/SpraypaintMiddleware';
import {ToursProvider} from 'providers/ToursProvider';
import {PusherServiceProvider} from 'services/PusherService';
import NavItemBadgeCount from 'utilities/nav-links/NavItemBadgeCount';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default ({children}: {children: ReactNode}) => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <CookiesProvider>
            <SpraypaintMiddlewareProvider>
              <GoogleOAuthProvider clientId="469157937967-86ntkk49m2mjqbf571u9mmkdqh0dae18.apps.googleusercontent.com">
                <Modal.Imperative.Provider>
                  <PushPermissionsProvider>
                    <PusherServiceProvider>
                      <NavItemBadgeCount.Provider>
                        <InspectionUploaderProvider>
                          <ToursProvider>
                            <MobileDrawer.Provider>
                              {children}
                            </MobileDrawer.Provider>
                          </ToursProvider>
                        </InspectionUploaderProvider>
                      </NavItemBadgeCount.Provider>
                    </PusherServiceProvider>
                  </PushPermissionsProvider>
                </Modal.Imperative.Provider>
                <Toaster />
              </GoogleOAuthProvider>
            </SpraypaintMiddlewareProvider>
          </CookiesProvider>
        </QueryParamProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};
