import {useEffect, useState} from 'react';

import {Formik, Form} from 'formik';
import type {FormikHelpers} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import {InputField, TextareaField} from 'components/forms_fields';
import FormRowItem from 'components/forms_fields/FormRowItem';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightSideText from 'components/walkthrough/common/RightSideText';
import Property from 'models/properties/Property';
import TenancyRequest from 'models/properties/TenancyRequest';
import TrackingService from 'services/TrackingService';

type FormValues = {
  renterEmail: string;
  messageFromLandlord?: string | null;
  phoneNumber?: string | null;
};

const TenantDetailsStep = ({property}: {property: Property}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.MigrateTenancy_StartInviteTenantStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const queryClient = useQueryClient();
  const tenancy = property.tenancies[0];
  const request = tenancy.tenancyRequests[0];

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    setIsLoading(true);

    const req = request ? request : new TenancyRequest();
    if (
      formValues.messageFromLandlord &&
      formValues.messageFromLandlord.length > 0
    ) {
      tenancy.messageFromLandlord = formValues.messageFromLandlord;
    }
    req.renterEmail = formValues.renterEmail;
    req.phoneNumber = formValues.phoneNumber;
    req.isHeadTenant = true;
    req.rentSplit = tenancy.totalRent;

    tenancy.tenancyRequests = [req];

    const result = await tenancy.save({with: 'tenancyRequests'});
    if (result) {
      property.lastOnboardingStepCompleted = 'migrate_invite_tenant';
      await property.save();

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.MigrateTenancy_CompleteInviteTenantStep,
        {
          propertyId: property.id,
        },
      );

      queryClient.setQueryData(['new-property', property.id], property);
    } else {
      for (const key of Object.keys(tenancy.errors)) {
        const message = tenancy.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    setIsLoading(false);
  };

  const goToPrevious = async () => {
    setIsLoading(true);
    property.lastOnboardingStepCompleted = 'select_onboarding_type';
    await property.save();

    queryClient.setQueryData(['new-property', property.id], property);
    setIsLoading(false);
  };

  const title = 'Invite your tenant to join you!';
  const subtitle = 'To start, invite one tenant to download the Keyhook app';

  return (
    <Container>
      <LeftSide title={title} subtitle={subtitle} />
      <RightContainer>
        <RightSideText title={title} subtitle={subtitle} />
        <Formik
          initialValues={
            {
              renterEmail: request ? request.renterEmail : '',
              messageFromLandlord: tenancy.messageFromLandlord || '',
              phoneNumber: request ? request.phoneNumber : '',
            } as FormValues
          }
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            renterEmail: Yup.string()
              .email()
              .required('Required')
              .label('Tenant Email'),
            messageFromLandlord: Yup.string()
              .optional()
              .label('Message to tenant'),
            phoneNumber: Yup.string()
              .optional()
              .nullable()
              .label('Phone Number'),
          })}>
          {(formik) => (
            <Form className="mx-0 lg:mx-16">
              <div className="flex flex-col lg:flex-row justify-between lg:space-x-2">
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="renterEmail"
                    label="Email"
                    placeholder="name@email.com"
                    type="email"
                    autoComplete="off"
                    className="!rounded-full"
                  />
                </FormRowItem>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="phoneNumber"
                    label="Phone number (optional)"
                    placeholder="021 123 4567"
                    autoComplete="off"
                    className="!rounded-full"
                  />
                </FormRowItem>
              </div>

              <div className="mt-4">
                <TextareaField
                  formik={formik}
                  name="messageFromLandlord"
                  label="Add a message (optional)"
                  rows={3}
                  placeholder="Include a welcome message for your tenant."
                  className="!rounded-full"
                />
              </div>

              <ProgressBox
                nextStep={formik.submitForm}
                previousStep={goToPrevious}
                step={4}
                totalSteps={7}
                previousStepEnabled
                nextStepEnabled={!isLoading}
              />
            </Form>
          )}
        </Formik>
      </RightContainer>
    </Container>
  );
};

export default TenantDetailsStep;
