import {useEffect, useState} from 'react';

import {type FormikHelpers, Formik, Form} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import {InputField, TextareaField} from 'components/forms_fields';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightSideText from 'components/walkthrough/common/RightSideText';
import Property from 'models/properties/Property';
import TrackingService from 'services/TrackingService';

type FormValues = {
  title: string | null;
  description: string;
};

const DescriptionStep = ({property}: {property: Property}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_StartDescribePropertyStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const listing = property.listings[0];

  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    setIsLoading(true);

    if (formValues.title && formValues.title.length === 0) {
      formValues.title = null;
    }

    listing.assignAttributes(formValues);
    await listing.save();

    property.lastOnboardingStepCompleted = 'listing_description';
    await property.save();

    /**
     * Track completion of the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_CompleteDescribePropertyStep,
      {
        propertyId: property.id,
      },
    );

    setIsLoading(false);
    queryClient.setQueryData('new-listing-wizard', property);
  };

  const previousStep = async () => {
    property.lastOnboardingStepCompleted = 'listing_tags';
    await property.save();
    queryClient.setQueryData('new-listing-wizard', property);
  };

  const title = 'Describe your property';
  const subtitle =
    'What are some highlights? What’s the neighbourhood like? Please use at least 100 characters';

  return (
    <Container>
      <LeftSide title={title} subtitle={subtitle} />
      <RightContainer>
        <RightSideText title={title} subtitle={subtitle} />
        <Formik
          initialValues={
            {
              description: listing.description || '',
              title: listing.title || null,
            } as FormValues
          }
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            description: Yup.string().min(100).required().label('Description'),
            title: Yup.string().optional().nullable().label('Title'),
          })}
          validateOnBlur={false}
          validateOnChange={false}>
          {(formik) => (
            <Form>
              <div className="flex flex-col md:mx-16 space-y-4">
                <div>
                  <InputField
                    formik={formik}
                    name="title"
                    label="Title (Optional)"
                    placeholder="If left blank the address will be used."
                    className="!rounded-lg"
                  />
                </div>
                <div>
                  <TextareaField
                    formik={formik}
                    name="description"
                    label="Description"
                    rows={8}
                    placeholder="This property is..."
                  />
                  {formik.values.description.length > 0 && (
                    <span className="block text-xs text-secondary mt-2">
                      {formik.values.description.length} characters
                    </span>
                  )}
                </div>
              </div>
              <ProgressBox
                nextStep={formik.submitForm}
                previousStep={previousStep}
                step={8}
                previousStepEnabled
                nextStepEnabled={
                  !isLoading && formik.values.description.length >= 100
                }
              />
            </Form>
          )}
        </Formik>
      </RightContainer>
    </Container>
  );
};

export default DescriptionStep;
