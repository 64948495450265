import React, {useState} from 'react';
import {FC} from 'react';
import {useEffect} from 'react';
import {useRef} from 'react';

import {BiMap} from '@react-icons/all-files/bi/BiMap';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

import ClearDataButton from 'components/chifis_theme/Button/ClearDataButton';
import SearchLocation from 'models/listings/SearchLocation';

export interface LocationInputProps {
  defaultValue: string;
  onChange?: (value: string) => void;
  onInputDone?: (value: SearchLocation) => void;
  placeHolder?: string;
  desc?: string;
  className?: string;
  autoFocus?: boolean;
}

const LocationInput: FC<LocationInputProps> = ({
  defaultValue,
  autoFocus = false,
  onChange,
  onInputDone,
  placeHolder = 'Suburb or City',
  desc = 'Start typing an area name',
  className = 'nc-flex-1.5',
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(defaultValue);
  const [showPopover, setShowPopover] = useState(autoFocus);
  const [searchOptions, setSearchOptions] = useState<SearchLocation[]>([]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener('click', eventClickOutsideDiv);
    }
    showPopover && document.addEventListener('click', eventClickOutsideDiv);
    return () => {
      document.removeEventListener('click', eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    onChange && onChange(value);
  }, [value]);

  const filter = async () => {
    const results = await SearchLocation.where({location: value})
      .per(100)
      .all();

    const items = results.data;
    if (items.length > 0 && items[items.length - 1].suburb === null) {
      const sl = items.pop();
      items.unshift(sl);
    }

    setSearchOptions(items);
  };
  const query = AwesomeDebouncePromise(filter, 300);

  useEffect(() => {
    if (value && value.length > 0) {
      query();
      setShowPopover(true);
    }
  }, [value]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  const handleSelectLocation = (item: SearchLocation) => {
    setValue(item.suburb || item.city);
    onInputDone && onInputDone(item);
    setShowPopover(false);
  };

  const renderSearchValue = () => {
    return (
      <>
        {searchOptions.map((item) => (
          <span
            onClick={() => handleSelectLocation(item)}
            key={item.id}
            className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer">
            <span className="block text-neutral-400">
              <BiMap className="w-6 h-6" />
            </span>
            <span className="block font-medium text-neutral-700 dark:text-neutral-200">
              {item.suburb ? `${item.suburb}, ${item.city}` : item.city}
            </span>
          </span>
        ))}
      </>
    );
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => {
          if (searchOptions.length > 0) {
            setShowPopover(true);
          }
        }}
        className={`flex flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? 'shadow-2xl rounded-full dark:bg-neutral-800' : ''
        }`}>
        <div className="text-neutral-300 dark:text-neutral-400">
          <BiMap className="w-8 h-8" />
        </div>
        <div className="flex-grow">
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={placeHolder}
            value={value}
            autoFocus={showPopover}
            onChange={(e) => setValue(e.currentTarget.value)}
            ref={inputRef}
            autoComplete="off"
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">{!!value ? placeHolder : desc}</span>
          </span>
          {value && showPopover && (
            <ClearDataButton onClick={() => setValue('')} />
          )}
        </div>
      </div>
      {showPopover && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {renderSearchValue()}
        </div>
      )}
    </div>
  );
};

export default LocationInput;
