import {Formik, Form} from 'formik';
import * as Yup from 'yup';

import {
  AutocompleteField,
  InputField,
  SubmitButton,
} from 'components/forms_fields';
import {API_URL} from 'globals/app-globals';
import LandlordProfile from 'models/users/LandlordProfile';
import useAuth from 'services/useAuth';

const LandlordProfileForm = ({
  model,
  submitHandler,
}: {
  model: LandlordProfile;
  submitHandler: any;
}) => {
  const {currentUser} = useAuth();

  const queryAddresses = async (str: string) => {
    if (str.length <= 2) {
      return [];
    }

    const url = `${API_URL}/addresses.json?query=${str}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-USER-TOKEN': currentUser.meta.authenticationToken,
          'X-USER-EMAIL': currentUser.email,
        },
      });
      const data = await response.json();
      const places = data.addresses;
      return places;
    } catch (e) {
      return [];
    }
  };

  const selectAddress = async (option: any, formik: any): Promise<any[]> => {
    if (option) {
      const url = `${API_URL}/addresses/${option['id']}.json`;

      if (currentUser) {
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'X-USER-TOKEN': currentUser.meta.authenticationToken,
              'X-USER-EMAIL': currentUser.email,
            },
          });
          const data = await response.json();

          const ad = data.address;

          formik.setFieldValue('addressMeta', ad);
          formik.setFieldValue('address', ad.full);
        } catch (e) {
          return [];
        }
      }
    }
  };

  const initialValues = {
    address: model.address || '',
    addressMeta: model.addressMeta || null,
    phoneNumber: model.phoneNumber || '',
    externalLandlordId: model.externalLandlordId || '',
    landlordSubscription: null as any,
  };

  if (!model.isPersisted) {
    initialValues['landlordSubscription'] = {
      promoString: null,
    };
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
      validationSchema={Yup.object({
        address: Yup.string().required().label('Address'),
        phoneNumber: Yup.string().min(7).required().label('Phone Number'),
        externalLandlordId: Yup.string()
          .optional()
          .label('Tenancy Services Landlord Id')
          .min(5)
          .max(7),
      })}>
      {(formik) => (
        <Form>
          <div>
            <AutocompleteField
              label="Landlord Postal Address"
              name="address"
              formik={formik}
              placeholder="123 Example Street, Suburb, City"
              filter={queryAddresses}
              select={(option: any) => selectAddress(option, formik)}
              value={formik.values.address}
            />
            <InputField
              label="Phone Number"
              name="phoneNumber"
              formik={formik}
              placeholder="eg: 027 123 4567"
              autoCapitalize="none"
            />
            <InputField
              label="Tenancy Services Landlord Id"
              name="externalLandlordId"
              formik={formik}
              placeholder="If you don't know, leave blank"
              autoCapitalize="none"
            />
            <p className="text-secondary text-sm mt-2">
              Your phone number and address will be included in leases and bonds
              for Tenancy Services.
            </p>

            {!model.isPersisted && (
              <div>
                <InputField
                  formik={formik}
                  name="landlordSubscription.promoString"
                  label="Promo Code"
                  helpText="Have a promo code? Add it here."
                  placeholder="Case sensitive"
                />

                {(formik.errors as any).landlordSubscription &&
                  (formik.errors as any).landlordSubscription.promoString && (
                    <p className="text-red-500">
                      {(
                        formik.errors as any
                      ).landlordSubscription.promoString.replace(
                        'string',
                        'code',
                      )}
                    </p>
                  )}
              </div>
            )}

            <SubmitButton
              formik={formik}
              text="Save Profile"
              submittingText="Saving"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LandlordProfileForm;
