import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {Device} from '@capacitor/device';
import {Network, type ConnectionStatus} from '@capacitor/network';
import {LightningBoltIcon} from '@heroicons/react/outline';
import {ArrowLeftIcon} from '@heroicons/react/solid';
import {
  type CaptureResult,
  PreviewCamera,
} from '@numbersprotocol/preview-camera';
import {motion} from 'framer-motion';
import {useLocation, useNavigate, useParams} from 'react-router';

import InspectionAddActionPhotoModal from 'components/inspection/InspectionAddActionPhotoModal';
import InspectionAddNotePhotoModal from 'components/inspection/InspectionAddNotePhotoModal';
import {Modal} from 'components_sb/layout';
import useInspectionQueries from 'hooks/useInspectionQueries';
import {useInspectionUploader} from 'providers/InspectionUploader';
import useInspectionStore, {PendingUploadRow} from 'stores/InspectionStore';

import theme from '../../../themes/keyhook';

const {useModal} = Modal.Imperative;

const INACTIVE_COLOR = 'rgba(140, 140, 140, 0.5)';
const ACTIVE_COLOR = 'rgba(255, 255, 255, 0.7)';
const CAPTURE_BUTTON_SIZE = 78;

const BORDER_WIDTH = CAPTURE_BUTTON_SIZE * 0.1;

type AttachmentType = 'normal' | 'note' | 'action';

let cameraButtonTimer = 0;
let isRecordingVideo = false;

// The activeItemRef is a reference to the currently active item.
// Its used because the removeAllListeners doesnt seem to work properly
// So we only want to call the addListener once, otherwise the photo gets uploaded
// multiple times.
// The event listener function was capturing the state reference and not updating it.
// Changing the depenedency array will cause the listener to be added multiple times without
// being cleaned up.

const InspectionCameraPage = () => {
  const {propertyId, inspectionId, roomKey} = useParams();
  const decodedRoomKey = decodeURIComponent(roomKey);
  const [isVirtual, setIsVirtual] = useState(true);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const activeItemRef = useRef(activeItemIndex);
  const [networkConnected, setNetworkConnected] = useState<boolean>(true);

  const openModal = useModal();

  const inspectionUploader = useInspectionUploader();

  const {inspectionIsLoading, inspectionItemsIsLoading} =
    useInspectionQueries(inspectionId);

  const location = useLocation();

  const [attachmentType, setAttachmentType] =
    useState<AttachmentType>('normal');
  const attachmentTypeRef = useRef(attachmentType);

  const [
    inspection,
    setInspection,
    inspectionItems,
    setInspectionItems,
    selectedRoomKey,
    setSelectedRoomKey,
    database,
  ] = useInspectionStore((state) => [
    state.inspection,
    state.setInspection,
    state.inspectionItems,
    state.setInspectionItems,
    state.selectedRoomKey,
    state.setSelectedRoomKey,
    state.database,
  ]);

  const handleMediaCaptured = useCallback(async (data: CaptureResult) => {
    if (attachmentTypeRef.current === 'normal') {
      const doc = {
        data: data.filePath,
        attachmentType: 'normal',
        inspectionId: inspection.id,
        inspectionItemId: roomItems[activeItemRef.current].id,
        notes: null,
        _id: new Date().getTime().toString(),
      } as PendingUploadRow;
      const result = await database.put(doc);
      if (result.ok && networkConnected) {
        if (!inspectionUploader.isRunning) {
          inspectionUploader.processQueue(inspection.id);
        }
      }
    } else if (attachmentTypeRef.current === 'note') {
      openModal(InspectionAddNotePhotoModal, {
        inspectionItem: roomItems[activeItemRef.current],
        inspection,
        propertyId,
        filePath: data.filePath,
      });
    } else if (attachmentTypeRef.current === 'action') {
      openModal(InspectionAddActionPhotoModal, {
        inspectionItem: roomItems[activeItemRef.current],
        inspection,
        propertyId,
        filePath: data.filePath,
      });
    }

    setAttachmentType('normal');
    attachmentTypeRef.current = 'normal';
  }, []);

  const navigate = useNavigate();

  const cameraPressInterval = useRef<NodeJS.Timer>();

  useEffect(() => {
    Device.getInfo().then((info) => {
      setIsVirtual(info.isVirtual);
    });

    Network.addListener('networkStatusChange', (status) => {
      setNetworkConnected(status.connected);
    });

    const root = document.querySelector('#root');

    if (root) {
      root.classList.remove('bg-white');
      root.classList.add('bg-transparent');
    }

    return () => {
      root.classList.remove('bg-transparent');
      root.classList.add('bg-white');

      Network.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    if (!isVirtual) {
      PreviewCamera.startPreview();

      PreviewCamera.addListener('capturePhotoFinished', handleMediaCaptured);
      PreviewCamera.addListener('captureVideoFinished', handleMediaCaptured);
    }

    return () => {
      if (!isVirtual) {
        PreviewCamera.stopPreview();
        PreviewCamera.removeAllListeners();
      }
    };
  }, [isVirtual, handleMediaCaptured]);

  const handleUpdateRoomKey = useCallback(() => {
    if (!selectedRoomKey) {
      setSelectedRoomKey(decodedRoomKey);
    }
  }, [decodedRoomKey, selectedRoomKey, setSelectedRoomKey]);

  useEffect(() => {
    handleUpdateRoomKey();
  }, [roomKey, handleUpdateRoomKey]);

  const roomItems = useMemo(
    () => inspectionItems?.filter((item) => item.room === selectedRoomKey),
    [inspectionItems, selectedRoomKey],
  );

  const goBack = () => navigate(-1);

  const startCameraPress = () => {
    cameraPressInterval.current = setInterval(() => {
      cameraButtonTimer += 10;
      if (cameraButtonTimer > 200) {
        if (!isVirtual && !isRecordingVideo) {
          isRecordingVideo = true;
          PreviewCamera.startRecord();
        }
      }
    }, 10);
  };

  const finishCameraPress = () => {
    clearInterval(cameraPressInterval.current);
    if (cameraButtonTimer <= 200) {
      if (!isVirtual) {
        PreviewCamera.takePhoto();
      }
    } else {
      if (!isVirtual) {
        PreviewCamera.stopRecord();
        isRecordingVideo = false;
      }
    }
    cameraButtonTimer = 0;
  };

  const navigateToRoomPage = () => {
    const path = location.pathname.replace('/camera', '');
    navigate(path);
  };

  if (!inspection || inspectionIsLoading || inspectionItemsIsLoading) {
    return <div></div>;
  } else {
    return (
      <div className="h-full bg-transparent">
        <div className="absolute top-16 left-4 h-3/4 w-1/2 overflow-x-clip overflow-y-scroll flex flex-col items-start space-y-2">
          <a
            className="rounded-full text-white p-2 mb-6 select-none"
            style={{backgroundColor: INACTIVE_COLOR}}
            onClick={goBack}>
            <ArrowLeftIcon className="w-5 h-5" />
          </a>
          {roomItems.map((item, index) => (
            <motion.button
              key={item.id}
              type="button"
              onClick={() => {
                setActiveItemIndex(() => {
                  activeItemRef.current = index;
                  return activeItemRef.current;
                });
              }}
              whileTap={{scale: 0.8, transition: {duration: 0.2}}}
              className="py-1 px-2 rounded-lg text-sm select-none flex items-center text-left"
              style={{
                backgroundColor:
                  index === activeItemIndex ? ACTIVE_COLOR : INACTIVE_COLOR,
                color: index === activeItemIndex ? '#000' : '#fff',
              }}>
              {item.name}

              {item.inspectionItemAttachments.length > 0 && (
                <div className="bg-info ml-2 w-6 h-6 flex items-center leading-none justify-center text-white text-xs rounded-full">
                  {item.inspectionItemAttachments.length}
                </div>
              )}

              {item.inspectionActions.length > 0 && (
                <div className="bg-warning ml-2 w-6 h-6 flex items-center leading-none justify-center text-white text-xs rounded-full">
                  {item.inspectionActions.length}
                </div>
              )}
            </motion.button>
          ))}
          <button
            type="button"
            className="py-1 px-2 rounded-lg text-sm bg-white select-none"
            onClick={navigateToRoomPage}>
            Review Photos
          </button>
        </div>

        {/* <div className="absolute top-16 right-4 w-1/2">
          <div className="flex justify-end">
            <motion.button
              className="rounded-full text-white p-2 mb-6"
              style={{
                backgroundColor: flashActive ? ACTIVE_COLOR : INACTIVE_COLOR,
                color: flashActive ? '#000' : '#fff',
              }}
              onClick={() => setFlashActive((active) => !active)}
              whileTap={{scale: 0.8, transition: {duration: 0.2}}}>
              <LightningBoltIcon className="w-5 h-5" />
            </motion.button>
          </div>
        </div> */}

        <div className="absolute bottom-16 left-4 right-4 flex justify-between items-center">
          <motion.button
            className="py-2 px-2 rounded-lg text-sm text-white select-none"
            whileTap={{scale: 0.8, transition: {duration: 0.2}}}
            style={{
              backgroundColor:
                attachmentType === 'note'
                  ? theme.daisyUITheme['info']
                  : INACTIVE_COLOR,
            }}
            onClick={() => {
              setAttachmentType((t) => {
                let type = t;
                if (t !== 'note') {
                  type = 'note';
                } else {
                  type = 'normal';
                }

                attachmentTypeRef.current = type;
                return attachmentTypeRef.current;
              });
            }}>
            Add Note
          </motion.button>
          <motion.button
            className="select-none"
            style={{
              width: CAPTURE_BUTTON_SIZE,
              height: CAPTURE_BUTTON_SIZE,
              borderRadius: 9999,
              borderWidth: BORDER_WIDTH,
              borderColor: 'white',
            }}
            whileTap={{
              backgroundColor: theme.colors.brand[500],
              borderWidth: BORDER_WIDTH * 2,
              transition: {duration: 0.1},
            }}
            onTouchStart={startCameraPress}
            onTouchEnd={finishCameraPress}></motion.button>
          <motion.button
            className="py-2 px-2 rounded-lg text-sm text-white select-none"
            whileTap={{scale: 0.8, transition: {duration: 0.2}}}
            style={{
              backgroundColor:
                attachmentType === 'action'
                  ? theme.daisyUITheme['warning']
                  : INACTIVE_COLOR,
            }}
            onClick={() => {
              setAttachmentType((t) => {
                let type = t;
                if (t !== 'action') {
                  type = 'action';
                } else {
                  type = 'normal';
                }

                attachmentTypeRef.current = type;
                return attachmentTypeRef.current;
              });
            }}>
            Add Action
          </motion.button>
        </div>
      </div>
    );
  }
};

export default InspectionCameraPage;
