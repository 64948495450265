import {Browser} from '@capacitor/browser';
import {Capacitor} from '@capacitor/core';
import {
  DocumentDuplicateIcon,
  EyeIcon,
  HomeIcon,
  ChatAltIcon,
} from '@heroicons/react/outline';
import {useQuery} from 'react-query';
import {useParams} from 'react-router';

import keyhookLogo from 'assets/img/logo/logo-black-text.png';
import trademeLogo from 'assets/img/partner_logos/trademe.png';
import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import DraftListingWarning from 'components/listing/DraftListingWarning';
import ManageListingMenu from 'components/listing/ManageListingMenu';
import {HeaderButton} from 'components/navbar/Header';
import PageWrapper from 'components/PageWrapper';
import ExternalListingAd from 'models/listings/ExternalListingAd';
import Listing from 'models/listings/Listing';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';

const ListingDetailPage = () => {
  usePageVisit('ListingDetailPage');
  const {propertyId, listingId} = useParams();

  const {data, isLoading, error} = useQuery(
    `listing-${listingId}-external-ads`,
    async () => {
      const listing = await Listing.select({
        listings: ['id', 'public_id', 'view_count', 'status', 'property_id'],
      })
        .stats({
          enquiries: 'count',
          applications: 'count',
          open_homes: 'count',
        })
        .includes('external_listing_ads')
        .find(listingId);

      return listing;
    },
  );

  const logoForService = (ad: ExternalListingAd) => {
    if (ad.site === 'trademe.co.nz') {
      return trademeLogo;
    } else {
      return keyhookLogo;
    }
  };

  const openAdLink = async (link: string) => {
    await Browser.open({url: link});
  };

  const buttons: HeaderButton[] = Capacitor.isNativePlatform()
    ? []
    : [
        {
          text: 'Back to Property',
          href: `/properties/${propertyId}`,
          bgColor: 'primary',
        },
      ];

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Listing Dashboard" buttons={buttons}>
        <ManageListingMenu propertyId={propertyId} listingId={listingId} />
        <LoadingView />
      </PageWrapper>
    );
  } else {
    const viewCount =
      data.data.viewCount +
      data.data.externalListingAds.reduce((sum, ad) => sum + ad.viewCount, 0);
    return (
      <PageWrapper title="Listing Dashboard" buttons={buttons}>
        <ManageListingMenu propertyId={propertyId} listingId={listingId} />

        <DraftListingWarning listing={data.data} />

        <div className="w-full stats mmd:grid-flow-row shadow-xl mb-4 mt-4">
          <div className="stat place-items-center">
            <div className="stat-figure text-secondary">
              <EyeIcon className="w-8 w-8 text-secondary" />
            </div>
            <div className="stat-title">Views</div>
            <div className="stat-value">{viewCount}</div>
            <div className="stat-desc">In Total</div>
          </div>
          <div className="stat place-items-center">
            <div className="stat-figure text-secondary">
              <DocumentDuplicateIcon className="w-8 w-8 text-secondary" />
            </div>
            <div className="stat-title">Applications</div>
            <div className="stat-value">
              {data.meta.stats.applications.count}
            </div>
            <div className="stat-desc">Received</div>
          </div>
          <div className="stat place-items-center">
            <div className="stat-figure text-secondary">
              <ChatAltIcon className="w-8 w-8 text-secondary" />
            </div>
            <div className="stat-title">Enquiries</div>
            <div className="stat-value">{data.meta.stats.enquiries.count}</div>
            <div className="stat-desc">Received</div>
          </div>
          <div className="stat place-items-center">
            <div className="stat-figure text-secondary">
              <HomeIcon className="w-8 w-8 text-secondary" />
            </div>
            <div className="stat-title">Open Homes</div>
            <div className="stat-value">{data.meta.stats.open_homes.count}</div>
            <div className="stat-desc">Scheduled</div>
          </div>
        </div>
        <Card className="mt-2">
          <CardBody>
            <CardTitle>Ads</CardTitle>
            <p>Listed below are all the ads for your listing.</p>

            <div className="mt-4">
              <div className="flex justify-between items-center">
                <img
                  src={keyhookLogo}
                  alt="Keyhook Listings"
                  className="h-24 w-72 object-contain object-left w-auto"
                />

                <a
                  onClick={() =>
                    openAdLink(
                      `${window.location.origin}/listings/${data.data.publicId}`,
                    )
                  }
                  className="btn btn-neutral btn-sm">
                  View Ad
                </a>
              </div>
              <div className="divider my-0"></div>

              {data.data.externalListingAds.map((ad, index) => (
                <div key={ad.id}>
                  <div className="flex justify-between items-center mt-2">
                    <img
                      src={logoForService(ad)}
                      alt={ad.site}
                      className="h-12 lg:h-24 w-48 lg:w-72 object-contain object-left w-auto"
                    />

                    <a
                      onClick={() => openAdLink(ad.link)}
                      className="btn btn-neutral btn-sm">
                      View Ad
                    </a>
                  </div>

                  {index === data.data.externalListingAds.length - 1 ? null : (
                    <div className="divider my-0"></div>
                  )}
                </div>
              ))}
            </div>
          </CardBody>
        </Card>
      </PageWrapper>
    );
  }
};

export default ListingDetailPage;
