import {Model, Attr, HasMany, BelongsTo, HasOne} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Listing from 'models/listings/Listing';
import Document from 'models/properties/Document';
import {InsurancePolicy} from 'models/properties/InsurancePolicy';
import RoomPlan from 'models/properties/RoomPlan';
import Tenancy from 'models/properties/Tenancy';
import PreferredTradesman from 'models/service_requests/PreferredTradesman';
import User from 'models/users/User';

@Model()
class Property extends ApplicationRecord {
  static jsonapiType = 'properties';

  @Attr({persist: false}) completed: boolean;

  @Attr() noTenancyActionType: string | undefined | null;
  @Attr() nextTenancyActionType: 'new' | 'advertise' | undefined | null;

  @Attr() lastOnboardingStepCompleted: string | null | undefined;
  @Attr() completedProfileSteps: string[];

  @Attr() streetAddress: string;
  @Attr() suburb: string;
  @Attr() city: string;
  @Attr() state: string;
  @Attr() postcode: string;
  @Attr() country: string;

  @Attr() bathrooms: number;
  @Attr() bedrooms: number;
  @Attr() garages: number;

  @Attr() propertyType: string;

  @Attr() addressMeta: object;

  @Attr() chattels: Record<string, number>;
  @Attr() courtesyChattels: Record<string, number>;

  @Attr() inspectionPeriod: number;

  @Attr() maxTenants: number;

  @Attr() petsAllowed: boolean;
  @Attr() smokersAllowed: boolean;
  @Attr() rentersInsuranceRequired: boolean;

  @Attr() authorityToFixLimit: number;

  @Attr() allowedPetTypes: string[];

  @Attr({persist: false}) hasInsulationReport: boolean;

  @Attr() insurancePolicies: InsurancePolicy[];
  @Attr() insuranceCoversTenants: boolean;

  @Attr() mainImage: string;

  @Attr() bankAccountName: string;
  @Attr() bankAccountNumber: string;

  @Attr() purchasePrice: number;
  @Attr() purchaseDate: string;
  @Attr() mortgageInterestRate: number;
  @Attr() mortgageRepaymentFrequency:
    | null
    | 'daily'
    | 'weekly'
    | 'fortnightly'
    | 'monthly'
    | 'quarterly'
    | 'semi-annually'
    | 'annually';
  @Attr() mortgageRenewalDate: string;

  @Attr({persist: false}) tenanciesCount: number;

  @Attr({persist: false}) isPendingTransfer: boolean;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @Attr({persist: false}) landlordId: string;

  @BelongsTo('users') landlord: User;

  @HasMany('tenancies') tenancies: Tenancy[];
  @HasOne('tenancies') currentTenancy: Tenancy;
  @HasMany('documents') documents: Document[];
  @HasMany('documents') publicDocuments: Document[];
  @HasMany('preferred_tradesmen') preferredTradesmans: PreferredTradesman[];
  @HasOne('room_plans') roomPlan: RoomPlan;
  @HasMany('listings') listings: Listing[];

  public markStepAsCompleted(step: string) {
    if (!this.completedProfileSteps.includes(step)) {
      this.completedProfileSteps.push(step);
    }
  }
}

export default Property;
