import {HTMLProps} from 'react';

import {type FormikProps} from 'formik';

import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import {InlineError} from 'components_sb/feedback';
import TrackingService from 'services/TrackingService';

interface SelectFieldProps extends HTMLProps<HTMLSelectElement> {
  formik: FormikProps<any>;
  helpText?: string;
}

const SelectField = ({
  formik,
  label,
  name,
  children,
  helpText,
  ...rest
}: SelectFieldProps) => {
  const classN = formik.errors[name] ? ' border border-red-500' : '';
  if (rest.className) {
    rest.className += classN;
  } else {
    rest.className = classN;
  }
  rest.className = 'select select-bordered w-full' + rest.className;
  return (
    <>
      {label && (
        <label className="label flex justify-start items-center">
          <span className="label-text mr-2">{label}</span>
          <HelpTextPresenter helpText={helpText} />
        </label>
      )}
      <select
        name={name}
        onChange={(e) => {
          formik.setFieldValue(name, e.target.value);
          TrackingService.trackEvent(TrackingService.Event.SelectOption, {
            field: name,
          });
        }}
        value={formik.values[name]}
        {...rest}>
        {children}
      </select>
      <InlineError error={formik.errors[name]} />
    </>
  );
};

export default SelectField;
