import {type FormikProps} from 'formik';
import NumericFormat from 'react-number-format';

import {errorsFor, valueFor} from 'components/forms_fields/Helpers';
import {InlineError} from 'components_sb/feedback';

interface InputFieldProps {
  formik: FormikProps<any>;
  label: JSX.Element | string;
  name: string;
  // incrementValue?: number;
  placeholder?: string;
}

const InlineMoneyField = ({
  formik,
  label,
  name,
  // incrementValue = 5,
  placeholder,
}: InputFieldProps) => {
  const errors = errorsFor(formik, name);
  const value = valueFor(formik, name);
  // value = Number(value);

  return (
    <div>
      <div className="flex justify-between items-center">
        <span className="text-xl md:text-3xl font-medium text-neutral-800 dark:text-neutral-200">
          {label}
        </span>

        <div>
          <NumericFormat
            value={value}
            thousandSeparator=","
            allowNegative={false}
            className="input input-bordered rounded-lg text-center h-12 lg:h-16 border-black border-1 text-xl lg:text-3xl font-semibold w-32 lg:w-48"
            onValueChange={(values) => formik.setFieldValue(name, values.value)}
            placeholder={placeholder ?? ''}
            prefix="$"
            decimalScale={2}
            type="tel"
          />
        </div>
      </div>

      <InlineError error={errors} />
    </div>
  );
};

export default InlineMoneyField;
