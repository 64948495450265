import {LOGGED_IN, USER_IS_LANDLORD, USER_IS_RENTER} from './conditions';
import type {NavItem} from './types';

/**
 * Static configuration for nav link items.
 */
const NAV_ITEMS: NavItem[] = [
  {
    id: 'dashboard',
    label: 'Dashboard',
    conditions: [LOGGED_IN],
    to: '/',
  },
  {
    id: 'chat',
    label: 'Chat',
    conditions: [LOGGED_IN],
    to: '/chat',
  },
  {
    id: 'financials',
    label: 'Financials',
    conditions: [USER_IS_LANDLORD],
    to: '/payments',
  },
  {
    id: 'listings',
    label: 'Listings',
    subItems: [
      {
        id: 'view',
        label: 'View Listings',
        to: '/listings',
      },
      {
        id: 'enquiries',
        label: 'Enquiries',
        to: '/enquiries',
        conditions: [USER_IS_RENTER],
      },
      {
        id: 'rental-applications',
        label: 'Applications',
        to: '/rental-applications',
        conditions: [USER_IS_RENTER],
      },
      {
        id: 'open-homes',
        label: 'Open Homes',
        to: '/open-homes',
        conditions: [USER_IS_RENTER],
      },
    ],
  },
  {
    id: 'help',
    label: 'Help',
    to: 'https://help.keyhook.com',
  },
];

export default NAV_ITEMS;
