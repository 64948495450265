// @ts-nocheck

import {Form, Formik} from 'formik';
import type {FormikHelpers} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import {
  Base64FileField,
  InputField,
  SubmitButton,
  ToggleField,
} from 'components/forms_fields';
import PageWrapper from 'components/PageWrapper';
import Document from 'models/properties/Document';
import Tenancy from 'models/properties/Tenancy';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

const EditDocumentPage = () => {
  usePageVisit('EditDocumentPage');
  useTitle('Edit Document');
  const {id, propertyId} = useParams();

  const {data, isLoading, error} = useQuery(`edit-document-${id}`, async () => {
    const doc = await Document.select({
      properties: ['id'],
      tenancies: ['id', 'is_new'],
    })
      .includes('documentable')
      .find(id);
    return doc.data;
  });
  const queryClient = useQueryClient();

  const handleSubmit = async (formValues: any, actions: FormikHelpers<any>) => {
    const doc = data;
    doc.assignAttributes(formValues);

    const result = await doc.save();
    if (result) {
      toast.success('Document successfully updated!');
      queryClient.setQueryData(`edit-document-${id}`, doc);
    } else {
      for (const key of Object.keys(doc.errors)) {
        const message = doc.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }
    actions.setSubmitting(false);
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Edit Document">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    if (
      data.documentableType === 'Tenancy' &&
      (data.documentable as Tenancy).isNew
    ) {
      return (
        <PageWrapper
          title="Edit Document"
          buttons={[
            {
              text: 'Back to property',
              href: `/properties/${propertyId}`,
              bgColor: 'neutral',
            },
          ]}>
          <Card className="mt-4">
            <CardBody>
              <CardTitle>Document</CardTitle>
              <p>You cannot edit documents that were created by Keyhook.</p>
            </CardBody>
          </Card>
        </PageWrapper>
      );
    } else {
      let schema;
      if (data.documentType === 'other') {
        schema = Yup.object().shape({
          document: Yup.string().optional().min(0).label('Document File'),
          name: Yup.string().label('Document Name').min(1).required(),
        });
      } else {
        schema = Yup.object().shape({
          document: Yup.string().required().min(1).label('Document File'),
        });
      }

      return (
        <PageWrapper
          title="Edit Document"
          buttons={[
            {
              text: 'Back to property',
              href: `/properties/${propertyId}`,
              bgColor: 'neutral',
            },
          ]}>
          <Card className="mt-4">
            <CardBody>
              <CardTitle>Document</CardTitle>
              <Formik
                initialValues={{
                  name: data.name,
                  document: '',
                  private: data.private,
                }}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={schema}>
                {(formik) => (
                  <Form>
                    <p>
                      You can update this document replacing the file with an
                      updated one below.
                    </p>
                    {data.documentType === 'other' && (
                      <InputField formik={formik} name="name" label="Name" />
                    )}

                    <div className="mt-2">
                      <Base64FileField
                        formik={formik}
                        name="document"
                        accept=".pdf"
                        label="Document File ( PDF only )"
                      />
                    </div>

                    <div className="mt-2 w-1/2">
                      <ToggleField
                        name="private"
                        formik={formik}
                        label="Private"
                        helpText="Private documents are not viewable by tenants."
                      />
                    </div>

                    <SubmitButton
                      formik={formik}
                      text="Update Document"
                      submittingText="Updating"
                      block={false}
                      className="mt-6"
                    />
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </PageWrapper>
      );
    }
  }
};

export default EditDocumentPage;
