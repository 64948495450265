import {useNavigate} from 'react-router';
import {Link} from 'react-router-dom';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import ServiceRequestsTable from 'components/service_request/ServiceRequestsTable';
import Tenancy from 'models/properties/Tenancy';

const MaintenanceRequestsCard = ({tenancy}: {tenancy?: Tenancy}) => {
  const navigate = useNavigate();

  const showServiceRequest = (serviceRequestId: string) => {
    navigate(`service-requests/${serviceRequestId}`);
  };

  if (tenancy && tenancy.serviceRequests.length > 0) {
    return (
      <Card className="mt-8">
        <CardBody>
          <CardTitle className="flex justify-between">
            Maintenance Requests
            {tenancy && (
              <div className="hidden md:block">
                <Link
                  to={`tenancies/${tenancy.id}/service-requests/new`}
                  className="btn btn-sm btn-neutral float-right">
                  New Maintenance Request
                </Link>
              </div>
            )}
          </CardTitle>
          <div>
            <ServiceRequestsTable
              requests={tenancy.serviceRequests}
              clickHandler={showServiceRequest}
            />

            <div className="block md:hidden">
              {tenancy && (
                <Link
                  to={`tenancies/${tenancy.id}/service-requests/new`}
                  className="btn btn-block btn-neutral mt-2">
                  New Maintenance Request
                </Link>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    );
  } else {
    return (
      <Card className="mt-8">
        <CardBody>
          <CardTitle className="flex justify-between">
            Maintenance Requests
            {tenancy && (
              <div className="hidden md:block">
                <Link
                  to={`tenancies/${tenancy.id}/service-requests/new`}
                  className="btn btn-sm btn-neutral float-right">
                  New Maintenance Request
                </Link>
              </div>
            )}
          </CardTitle>
          {tenancy && (
            <div className="block md:hidden">
              <Link
                to={`tenancies/${tenancy.id}/service-requests/new`}
                className="btn btn-block btn-neutral mt-2">
                New Maintenance Request
              </Link>
            </div>
          )}
        </CardBody>
      </Card>
    );
  }
};

export default MaintenanceRequestsCard;
