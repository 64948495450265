import {HTMLProps, useState} from 'react';

import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {type FormikProps} from 'formik';
import Autosuggest from 'react-autosuggest';

import {errorsFor} from 'components/forms_fields/Helpers';
import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';

import './AutocompleteField.css';

interface AutocompleteFieldProps extends HTMLProps<HTMLInputElement> {
  formik: FormikProps<any>;
  label: string;
  name: string;
  helpText?: string;
  filter: (str: string) => Promise<any>;
  select: (option: any) => Promise<any[]>;
}

const AutocompleteField = ({
  formik,
  name,
  label,
  filter,
  select,
  helpText,
  ...rest
}: AutocompleteFieldProps) => {
  const [items, setItems] = useState([]);
  const [searchQuery, _] = useState(() => AwesomeDebouncePromise(filter, 300));

  const [query, setQuery] = useState('');

  const queryForSuggestions = () => {
    if (query.length > 0) {
      searchQuery(query)
        .then((newItems) => {
          setItems(newItems);
        })
        .catch();
    }
  };

  const clearItems = () => {
    setItems([]);
  };

  const getSuggestionValue = (val: any) => {
    return val.text;
  };

  const onChange = (event: any, {newValue}: any) => {
    formik.setFieldValue(name, newValue);
    setQuery(newValue.toString());
  };

  const onSelect = (event: any, {suggestion}: any) => {
    formik.setFieldValue(name, suggestion.text);
    if (select) {
      select(suggestion);
    }
  };

  const renderSuggestion = (suggestion: any) => {
    return <span>{suggestion.text}</span>;
  };

  const renderInput = (inputProps: any) => {
    let className;
    if (inputProps.className) {
      className = 'input input-bordered w-full ' + inputProps.className;
    } else {
      className = 'input input-bordered w-full';
    }
    return (
      <input
        {...inputProps}
        className={className}
        autoComplete="off"
        disabled={formik.isSubmitting}
      />
    );
  };

  const inputProps = {
    placeholder: rest.placeholder,
    value: formik.values[name],
    onChange: onChange,
    className: rest.className || '',
  };
  // if (disabled) {
  //   inputProps.disabled = true;
  // }

  const errors = errorsFor(formik, name);

  return (
    <>
      <label className="label flex justify-start items-center">
        <span className="label-text mr-2">{label}</span>
        <HelpTextPresenter helpText={helpText} />
      </label>
      <Autosuggest
        suggestions={items}
        onSuggestionsFetchRequested={queryForSuggestions}
        onSuggestionsClearRequested={clearItems}
        getSuggestionValue={getSuggestionValue}
        onSuggestionSelected={onSelect}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={renderInput}
      />
      {errors && (
        <span className="text-danger">
          <small>{errors}</small>
        </span>
      )}
    </>
  );
};

export default AutocompleteField;
