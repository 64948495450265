import {useState} from 'react';

import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import LandlordProfile from 'models/users/LandlordProfile';
import useAuth from 'services/useAuth';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';
import {toCurrency} from 'utilities/StringHelpers';

const SubscriptionDetailPage = () => {
  useTitle('Manage Subscription');
  usePageVisit('SubscriptionDetailPage');

  const [mutationLoading, setMutationLoading] = useState(false);

  const {currentUser, setUserIsPaid} = useAuth();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const lp = useQuery('landlord-profile-with-subscription', async () => {
    const profile = await LandlordProfile.includes({
      landlord_subscription: 'subscription',
    })
      .where({user_id: currentUser.id})
      .first();

    return profile.data;
  });

  const queryClient = useQueryClient();

  const confirmDowngradePlan = () => {
    setConfirmationOptions({
      title: 'Cancel Subscription',
      message:
        'Are you sure you want to cancel your subscription? Your account will go into read only mode.',
      buttonTitle: 'Confirm',
      action: downgradePlan,
      color: 'error',
    });
  };

  const downgradePlan = async () => {
    setMutationLoading(true);

    const profile = lp.data;
    profile.landlordSubscription.isActive = false;

    const result = await profile.landlordSubscription.save();
    if (result) {
      await setUserIsPaid(false);
      queryClient.setQueryData('landlord-profile-with-subscription', profile);
    }

    setMutationLoading(false);
  };

  const confirmUpgradePlan = () => {
    setConfirmationOptions({
      title: 'Activate Subscription',
      message:
        'Are you sure you want to activate your subscription? By agreeing to this you indicate that you have read and understood the terms of service.',
      buttonTitle: 'Subscribe',
      action: upgradePlan,
      color: 'success',
    });
  };

  const upgradePlan = async () => {
    setMutationLoading(true);

    const profile = lp.data;
    profile.landlordSubscription.isActive = true;

    const result = await profile.landlordSubscription.save();

    if (result) {
      await setUserIsPaid(true);
      queryClient.setQueryData('landlord-profile-with-subscription', profile);
    }

    setMutationLoading(false);
  };

  if (lp.error) {
    return errorViewForError(lp.error);
  } else if (lp.isLoading) {
    return (
      <PageWrapper title="Manage Subscription">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Manage Subscription">
        <Card>
          <CardBody>
            <CardTitle>Keyhook Subscription</CardTitle>
            {lp.data!.landlordSubscription &&
            lp.data.landlordSubscription.isActive ? (
              <div className="mt-2">
                <p>You currently have an active subscription for Keyhook.</p>

                <p className="mt-2">
                  The cost of this plan is{' '}
                  {toCurrency(
                    lp.data!.landlordSubscription.subscription.amount,
                  )}{' '}
                  per week for each property (excl gst) which we will
                  automatically deduct from your rent.
                </p>

                {lp.data.landlordSubscription.freeUntil &&
                  moment(lp.data.landlordSubscription.freeUntil).isAfter(
                    moment(),
                  ) && (
                    <p>
                      Your free promo offer is still active and this fee will be
                      waived until{' '}
                      {moment(lp.data.landlordSubscription.freeUntil).format(
                        'DD MMMM YYYY',
                      )}
                      .
                    </p>
                  )}

                <p className="mt-2 text-sm text-secondary">
                  If you would like to cancel your subscription, press the
                  button below. Your account will go into read only mode where
                  you can still access your data.
                </p>

                <button
                  className="btn btn-error mt-4"
                  type="button"
                  onClick={confirmDowngradePlan}>
                  Cancel Subscription
                </button>
              </div>
            ) : (
              <div className="mt-2">
                <p>
                  You do not currently have an active subscription and therefore
                  your account is in read only mode. You can access your past
                  data but not create or modify anything within Keyhook.
                </p>

                <p className="mt-2">
                  You can click the button below to re-activate your
                  subscription for $4.99 / month per property (excl gst).
                </p>

                <p className="mt-2 text-sm text-secondary">
                  Please note, Keyhook does not require an upfront payment as we
                  will instead deduct your subscription fee from your rental
                  payments. For more information, see our{' '}
                  <a
                    className="link link-primary"
                    href="https://keyhook.com/pricing"
                    target="_blank"
                    rel="noreferrer">
                    pricing information
                  </a>{' '}
                  and{' '}
                  <a
                    className="link link-primary"
                    href="https://keyhook.com/terms-of-service"
                    target="_blank"
                    rel="noreferrer">
                    terms of service
                  </a>
                </p>

                <button
                  className="btn btn-success mt-3"
                  onClick={confirmUpgradePlan}
                  type="button"
                  disabled={mutationLoading}>
                  {mutationLoading ? 'Subscribing...' : 'Subscribe'}
                </button>
              </div>
            )}
          </CardBody>
        </Card>
      </PageWrapper>
    );
  }
};

export default SubscriptionDetailPage;
