import {ElementType} from 'react';

import {type FormikProps} from 'formik';

import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import {InlineError} from 'components_sb/feedback';
import TrackingService from 'services/TrackingService';

const YesNoField = ({
  formik,
  label,
  name,
  helpText,
  size = 'large',
  ...rest
}: {
  formik: FormikProps<any>;
  name: string;
  label: string | ElementType;
  id?: string;
  helpText?: string;
  size?: 'small' | 'large';
}) => {
  const handleChange = (val: boolean) => {
    formik.setFieldValue(name, val);
    TrackingService.trackEvent(TrackingService.Event.ToggleOption, {
      field: name,
    });
  };

  const fontSize = size === 'large' ? 'text-3xl' : 'text-xl';
  const buttonSize = size === 'large' ? 'btn-md px-8' : 'btn-sm';

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <span
            className={`text-xl md:text-3xl font-medium text-neutral-800 dark:text-neutral-200`}>
            {`${label} `}
            <HelpTextPresenter helpText={helpText} />
          </span>
        </div>
        <div className="btn-group">
          <button
            type="button"
            className={`btn btn-sm md:btn-md px-4 md:px-8 ${
              formik.values[name] === true ? '' : 'btn-outline'
            }`}
            onClick={() => handleChange(true)}>
            Yes
          </button>
          <button
            type="button"
            className={`btn btn-sm md:btn-md px-4 md:px-8 ${
              formik.values[name] === false ? '' : 'btn-outline'
            }`}
            onClick={() => handleChange(false)}>
            No
          </button>
        </div>
      </div>
      <InlineError error={formik.errors[name]} />
    </div>
  );
};

export default YesNoField;
