import {Browser} from '@capacitor/browser';
import {Link} from 'react-router-dom';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import DocumentsTable from 'components/document/DocumentsTable';
import Document from 'models/properties/Document';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';

const DocumentsCard = ({
  property,
  tenancy,
}: {
  property: Property;
  tenancy?: Tenancy;
}) => {
  const showDocument = async (document: Document) => {
    await Browser.open({url: document.document, windowName: document.name});
    // window.open(document.document, '_blank');
  };

  if (
    property &&
    tenancy &&
    (tenancy.documents.length > 0 || property.documents.length > 0)
  ) {
    let documents = property.documents;
    if (tenancy) {
      documents = documents.concat(tenancy.documents);
    }

    return (
      <Card className="mt-8">
        <CardBody>
          <CardTitle className="flex justify-between">
            Documents
            <div className="hidden md:block">
              <Link
                to={'documents/new'}
                className="btn btn-sm float-right mr-2">
                Add Document
              </Link>
            </div>
          </CardTitle>
          <div>
            <DocumentsTable
              documents={documents}
              clickHandler={showDocument}
              showParent={true}
              tenancyisMigrated={!tenancy.isNew}
              queryKey={`landlord-property-detail-${property.id}`}
            />

            <div className="block md:hidden">
              <Link to={'documents/new'} className="btn btn-block mt-2">
                Add Document
              </Link>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  } else {
    if (property.documents.length === 0) {
      return (
        <Card className="mt-8">
          <CardBody>
            <CardTitle className="flex justify-between">
              All Documents
              <div className="hidden md:block">
                <Link to={'documents/new'} className="btn btn-sm float-right">
                  Add Document
                </Link>
              </div>
            </CardTitle>
            <div className="block md:hidden">
              <Link to={'documents/new'} className="btn btn-block mt-2">
                Add Document
              </Link>
            </div>
          </CardBody>
        </Card>
      );
    } else {
      return (
        <Card className="mt-8">
          <CardBody>
            <CardTitle className="flex justify-between">
              Documents
              <div className="hidden md:block">
                <Link
                  to={'documents/new'}
                  className="btn btn-sm float-right mr-2">
                  Add Document
                </Link>
              </div>
            </CardTitle>
            <div>
              <DocumentsTable
                documents={property.documents}
                clickHandler={showDocument}
                showParent={true}
                queryKey={`landlord-property-detail-${property.id}`}
              />

              <div className="block md:hidden">
                <Link to={'documents/new'} className="btn btn-block mt-2">
                  Add Document
                </Link>
              </div>
            </div>
          </CardBody>
        </Card>
      );
    }
  }
};

export default DocumentsCard;
