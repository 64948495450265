import {Browser} from '@capacitor/browser';
import {Capacitor} from '@capacitor/core';
import {ChevronRightIcon} from '@heroicons/react/outline';
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import PageWrapper from 'components/PageWrapper';
import {TARGET_ENV} from 'globals/app-globals';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import User from 'models/users/User';
import useAuth from 'services/useAuth';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';

interface SettingsItem {
  title: string;
  subtitle: string;
}

const SettingsIndexPage = () => {
  const {currentUser, logOutUser, deleteUser} = useAuth();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const navigate = useNavigate();

  const settings = [
    // {title: 'App Settings', subtitle: 'Configure how the app works and looks'},
    {title: 'Account Information', subtitle: 'Change your email or password'},
    {title: 'Avatar', subtitle: 'Upload an avatar'},
    currentUser.roles.includes('Landlord')
      ? {
          title: 'Landlord Profile',
          subtitle:
            'Change any account information specific to your landlord profile',
        }
      : {
          title: 'Renter Profile',
          subtitle:
            'Change any account information specific to your renter profile',
        },

    {
      title: 'Notifications',
      subtitle: 'Configure which notifications you receive',
    },
    {
      title: 'Live Chat Support',
      subtitle:
        'Contact our team to get support or to provide us with any feedback.',
    },
    {
      title: 'Email Support',
      subtitle: 'Email our team to get support',
    },
    {
      title: 'Knowledge Base',
      subtitle: 'How to guides and frequently asked questions',
    },
    {
      title: 'Logout',
      subtitle: 'Logout of the app',
    },
  ] as SettingsItem[];

  const handlePress = (itemTitle: string) => {
    switch (itemTitle) {
      case 'Account Information':
        navigate('account');
        break;
      case 'Notifications':
        navigate('notifications');
        break;
      case 'Avatar':
        navigate('avatar');
        break;
      case 'Landlord Profile':
        navigate('landlord-profile');
        break;
      case 'Renter Profile':
        navigate('renter-profile');
        break;
      case 'Subscription':
        navigate('subscription');
        break;
      case 'Live Chat Support':
        showSupport();
        break;
      case 'Email Support':
        window.open('mailto:support@keyhook.com', '_blank');
        break;
      case 'Knowledge Base':
        Browser.open({url: 'https://keyhook.customerly.help/'});
        break;
      case 'Logout':
        logOutUser();

        break;
      default:
        break;
    }
  };

  const showSupport = async () => {
    const params = new URLSearchParams();
    params.set('user_id', currentUser.id);
    params.set('name', currentUser.name);
    params.set('email', currentUser.email);
    params.set('env', TARGET_ENV);
    let url = `https://livesupport.keyhook.com?${params.toString()}`;
    url = url + currentUser.roles.map((role) => `&roles=${role}`).join('');

    await Browser.open({url: url});
  };

  const confirmDeleteAccount = () => {
    setConfirmationOptions({
      color: 'error',
      title: 'Delete Your Account',
      message:
        'Are you sure you want to delete your Keyhook Account? This cannot be undone. If you are part way through a tenancy, you may be legally liable for any missed actions.',
      action: deleteUser,
      buttonTitle: 'Delete Account',
    });
  };

  return (
    <PageWrapper title="Settings">
      <div>
        {settings.map((item, index) => (
          <Card
            key={index}
            className="mt-2"
            onClick={() => handlePress(item.title)}>
            <CardBody>
              <div className="flex items-center justify-between gap-2">
                <div className="flex flex-col">
                  <strong className="text-sm">{item.title}</strong>
                  <p className="text-xs text-secondary">{item.subtitle}</p>
                </div>

                <ChevronRightIcon className="w-4 h-4 text-gray-500" />
              </div>
            </CardBody>
          </Card>
        ))}

        {Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios' && (
          <Card className="mt-4" onClick={confirmDeleteAccount}>
            <CardBody>
              <div className="flex items-center justify-between gap-2">
                <div className="flex flex-col">
                  <strong className="text-sm text-error">Delete Account</strong>
                  <p className="text-xs text-secondary">
                    Permanently delete your Keyhook account.
                  </p>
                </div>

                <ChevronRightIcon className="w-4 h-4 text-gray-500" />
              </div>
            </CardBody>
          </Card>
        )}
      </div>
    </PageWrapper>
  );
};

export default SettingsIndexPage;
