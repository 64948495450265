import React from 'react';

import {ChevronRightIcon} from '@heroicons/react/outline';
import {Link} from 'react-router-dom';

interface IconBaseProps extends React.SVGAttributes<SVGElement> {
  children?: React.ReactNode;
  size?: string | number;
  color?: string;
  title?: string;
}
type IconType = (props: IconBaseProps) => JSX.Element;

const MobileListItem = ({
  link,
  onClick,
  icon,
  title,
  subtitle,
  showArrowIcon = true,
}: {
  link?: string;
  onClick?: () => void;
  icon: IconType;
  title: string;
  subtitle: React.ReactNode;
  showArrowIcon?: boolean;
}) => {
  const Icon = icon;

  const renderOuter = (children: React.ReactNode) => {
    if (onClick) {
      return (
        <div className="mt-2" onClick={onClick}>
          {children}
        </div>
      );
    } else {
      return (
        <Link to={link} className="mt-2">
          {children}
        </Link>
      );
    }
  };

  return renderOuter(
    <>
      <div className="flex justify-between items-center mb-2">
        <div className="flex justify-start gap-2 items-center">
          <Icon className="w-6 h-6 text-neutral" />
          <div>
            <strong>{title}</strong>
            <p className="text-sm flex flex-col">{subtitle}</p>
          </div>
        </div>

        {showArrowIcon && (
          <ChevronRightIcon className="w-4 h-4 text-gray-500" />
        )}
      </div>
      <div className="w-full h-px bg-gray-200"></div>
    </>,
  );
};

export default MobileListItem;
