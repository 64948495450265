import {Form, Formik, type FormikHelpers, type FormikProps} from 'formik';
import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import {
  DatetimeField,
  SubmitButton,
  TextareaField,
} from 'components/forms_fields';
import PageWrapper from 'components/PageWrapper';
import Listing from 'models/listings/Listing';
import OpenHome from 'models/listings/OpenHome';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';

interface OpenHomeFormVal {
  startTime: string;
  endTime: string;
}

interface Vals {
  notes: string;
  openHomes: OpenHomeFormVal[];
}

const NewOpenHomePage = () => {
  usePageVisit('NewOpenHomePage');
  const {propertyId, listingId} = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {data, isLoading, error} = useQuery(
    `listing-${listingId}-new-open-home`,
    async () => {
      const listing = await Listing.select({listings: ['id']}).find(listingId);

      return listing.data;
    },
  );

  const removeTimeAtIndex = (formik: FormikProps<Vals>, index: number) => {
    const openHomes = formik.values.openHomes;
    openHomes.splice(index, 1);

    formik.setFieldValue('openHomes', openHomes);
  };

  const addTime = (formik: FormikProps<Vals>) => {
    const openHomes = formik.values.openHomes || [];

    openHomes.push({
      startTime: '',
      endTime: '',
    });

    formik.setFieldValue('openHomes', openHomes);
  };

  const handleSubmit = async (
    formValues: Vals,
    actions: FormikHelpers<Vals>,
  ) => {
    const notes = formValues.notes;

    await Promise.all(
      formValues.openHomes.map(async (oh, index) => {
        const openHome = new OpenHome(oh);
        openHome.listingId = data.id;
        if (notes && notes.length > 0) {
          openHome.notes = notes;
        }

        return await openHome.save();
      }),
    );

    toast.success('Open homes successfully created!');

    actions.setSubmitting(false);

    await queryClient.invalidateQueries(`listing-${listingId}-open-homes`);
    navigate(`/properties/${propertyId}/listings/${listingId}/open-homes`);
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title={`Add Open Home`}>
        <LoadingView />
      </PageWrapper>
    );
  } else {
    const initialVals = {
      notes: '',
      openHomes: [{startTime: '', endTime: ''}],
    } as Vals;

    return (
      <PageWrapper title={`Add Open Home`}>
        <Card className="mt-4">
          <CardBody>
            <CardTitle>Add Open Home</CardTitle>
            <Formik
              initialValues={initialVals}
              onSubmit={handleSubmit}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={Yup.object().shape({
                notes: Yup.string().optional().nullable().label('Notes'),
                openHomes: Yup.array()
                  .of(
                    Yup.object().shape({
                      startTime: Yup.string().required().label('Start Time'),
                      endTime: Yup.string().required().label('End Time'),
                    }),
                  )
                  .min(1)
                  .label('Open Home Times'),
              })}>
              {(formik) => (
                <Form>
                  <TextareaField
                    formik={formik}
                    name="notes"
                    label="Notes"
                    placeholder="Include any notes for potential tenants, e.g., how to access the property."
                  />

                  <strong className="block mt-4">Times</strong>
                  {formik.values.openHomes.map((oh, index) => (
                    <div
                      key={index}
                      className="flex flex-col lg:flex-row justify-start items-start lg:items-end gap-2 lg:gap-4">
                      <div>
                        <DatetimeField
                          formik={formik}
                          name={`openHomes[${index}].startTime`}
                          label="Start Time"
                          minDate={new Date()}
                          maxDate={new Date('2100-01-01')}
                          inputProps={{
                            placeholder: 'Start Time',
                            className: 'input input-bordered',
                          }}
                          onBlur={() => {
                            if (oh.startTime && oh.startTime.length > 0) {
                              formik.setFieldValue(
                                `openHomes[${index}].endTime`,
                                moment(oh.startTime)
                                  .add(1, 'hour')
                                  .toISOString(),
                              );
                            }
                          }}
                        />
                      </div>
                      <div>
                        <DatetimeField
                          formik={formik}
                          name={`openHomes[${index}].endTime`}
                          label="End Time"
                          minDate={
                            oh.startTime
                              ? moment(oh.startTime).toDate()
                              : new Date()
                          }
                          maxDate={new Date('2100-01-01')}
                          inputProps={{
                            placeholder: 'End Time',
                            className: 'input input-bordered',
                          }}
                        />
                      </div>
                      <div>
                        <button
                          className="btn btn-ghost btn-sm"
                          onClick={() => removeTimeAtIndex(formik, index)}>
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                  {/* {formik.errors.openHomes && (
                    <p className="text-red-500 text-sm">
                      {formik.errors.openHomes}
                    </p>
                  )} */}

                  <div className="mt-4">
                    <button
                      className="btn btn-neutral btn-sm"
                      onClick={() => addTime(formik)}>
                      Add Another Time
                    </button>
                  </div>

                  <SubmitButton
                    formik={formik}
                    text={
                      formik.values.openHomes.length > 1
                        ? 'Save Open Homes'
                        : 'Save Open Home'
                    }
                    submittingText="Saving..."
                    className="mt-20"
                  />
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </PageWrapper>
    );
  }
};

export default NewOpenHomePage;
