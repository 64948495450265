import React from 'react';

import _ from 'lodash';

import {LightboxGallerySource} from 'components/common/LightboxGallery';
import InspectionGallery from 'components/inspection/media_items/InspectionGallery';
import Inspection from 'models/inspections/Inspection';
import InspectionItem from 'models/inspections/InspectionItem';
import {MediableItems} from 'models/inspections/MediableItems';

const MediaItemGrid = ({
  inspection,
  item,
}: {
  inspection: Inspection;
  item: InspectionItem;
}) => {
  const landlordAttachments: MediableItems = item.inspectionItemAttachments
    .concat(item.inspectionActions as any)
    .filter((a) => a.userId === inspection?.tenancy.property.landlordId);

  const tenantAttachments: MediableItems = item.inspectionItemAttachments
    .concat(item.inspectionActions as any)
    .filter((a) => a.userId !== inspection?.tenancy.property.landlordId);

  const groupedTenantAttachments = _.groupBy(
    tenantAttachments,
    (a) => (a as any).user.name,
  );

  if (
    item.inspectionItemAttachments.length + item.inspectionActions.length ===
    0
  ) {
    return (
      <p className="text-sm md:text-md text-secondary">
        There are no photos to display.
      </p>
    );
  }

  return (
    <div>
      {inspection?.doneBy === 'landlord' && (
        <div>
          <InspectionGallery item={item} sources={landlordAttachments} />

          {Object.entries(groupedTenantAttachments).map(
            ([name, attachmentGroup]) => (
              <div key={name} className="mt-2 bg-blue-50 p-4 rounded-lg">
                <strong>Added by {name}</strong>

                <InspectionGallery item={item} sources={attachmentGroup} />
              </div>
            ),
          )}
        </div>
      )}

      {inspection?.doneBy === 'tenant' && (
        <div>
          <InspectionGallery item={item} sources={tenantAttachments} />

          {landlordAttachments.length > 0 && (
            <div className="mt-2 bg-blue-50 p-4 rounded-lg">
              <strong>
                Added by {(landlordAttachments[0] as any).user.name}
              </strong>

              <InspectionGallery item={item} sources={landlordAttachments} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MediaItemGrid;
