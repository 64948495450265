import {useEffect, useState} from 'react';

import {Form, Formik} from 'formik';
import {Link, Navigate, useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import LoadingView from 'components/common/LoadingView';
import {InputField, SubmitButton} from 'components/forms_fields';
import Paragraph from 'components_sb/typography/Paragraph/Paragraph';
import useAuth from 'services/useAuth';
import {usePageVisit, useTitle} from 'utilities/hooks';

const ResetPasswordPage = () => {
  useTitle('Reset Password');
  usePageVisit('ResetPasswordPage');

  const {token} = useParams();

  const [validatingToken, setValidatingToken] = useState(true);
  const [tokenValid, setTokenValid] = useState(false);

  const {validatePasswordResetToken, resetPass} = useAuth();

  const navigate = useNavigate();

  // Validate that the password reset token is still valid upon page load
  useEffect(() => {
    const validateToken = async () => {
      try {
        if (!token) {
          throw Error();
        }
        setTokenValid(await validatePasswordResetToken(token));
      } catch (error) {
        setTokenValid(false);
      } finally {
        setValidatingToken(false);
      }
    };
    validateToken();
  }, [token, validatePasswordResetToken]);

  const {userIsLoggedIn} = useAuth();
  if (userIsLoggedIn) {
    navigate('/');
  }

  const handleSubmit = (formData: any, actions: any) => {
    resetPass(formData).then(({responseObject, status}) => {
      if (status.toString().indexOf('20') != -1) {
        actions.setSubmitting(false);
      } else {
        const errors = responseObject.errors;
        for (const key in errors) {
          const reasons = errors[key];
          for (const reason of reasons) {
            if (key === 'reset_password_token') {
              actions.setFieldError(
                'resetPasswordToken',
                'Your password reset link has expired',
              );
            } else {
              actions.setFieldError(key, key + ' ' + reason);
            }
          }
        }
        actions.setSubmitting(false);
      }
    });
  };

  return (
    <div className="max-w-md mx-auto pl-8 pr-8 lg:pl-24 lg:pr-24 mb-24 lg:mb-32 border-2 border-gray-200 my-20 rounded-xl flex justify-center">
      <div className="min-w-[300px]">
        <h2 className="mt-8 mb-2 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Reset your password
        </h2>

        {/* Token is being validated */}
        {validatingToken && (
          <div className="w-full h-52 flex items-center justify-center">
            <LoadingView />
          </div>
        )}

        {/* Either no token exists in the URL or the token is invalid */}
        {!validatingToken && !tokenValid && (
          <div className="w-full h-52 flex flex-col gap-y-2 items-center justify-center">
            <p>Your password reset link is invalid or has expired.</p>
            <Link to="/forgot-password" className="link link-primary">
              Click here to request a new one
            </Link>
          </div>
        )}

        {/* Token is valid and password reset may now be performed */}
        {!validatingToken && tokenValid && (
          <div>
            <Paragraph>
              Enter a new password for your Keyhook account below.
            </Paragraph>

            <div className="mt-4 mb-8">
              <Formik
                onSubmit={handleSubmit}
                initialValues={{
                  password: '',
                  passwordConfirmation: '',
                  resetPasswordToken: token,
                }}
                validationSchema={Yup.object().shape({
                  password: Yup.string()
                    .required('Please enter a new password')
                    .min(6, 'Password must be at least 6 characters')
                    .max(128, 'Password must be 128 characters or fewer'),
                  passwordConfirmation: Yup.string()
                    .required('Please confirm the above password')
                    .test(
                      'passwords-match',
                      'Passwords must match',
                      function (value) {
                        return this.parent.password === value;
                      },
                    ),
                })}>
                {(formik) => (
                  <Form>
                    <InputField
                      formik={formik}
                      placeholder="New Password"
                      name="password"
                      type="password"
                      autoComplete="new-password"
                      className="!rounded-full"
                      label={
                        (
                          <span>
                            New Password{' '}
                            <em className="text-xs">(6+ characters)</em>
                          </span>
                        ) as any
                      }
                    />

                    <InputField
                      formik={formik}
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      name="passwordConfirmation"
                      type="password"
                      autoComplete="new-password"
                      className="!rounded-full"
                    />

                    <InputField
                      formik={formik}
                      name="resetPasswordToken"
                      type="hidden"
                      label=""
                    />
                    <SubmitButton
                      color="primary"
                      className="mt-3 rounded-full"
                      formik={formik}
                      text="Set new password"
                      submittingText="Saving new password"
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordPage;

/**
 * Allows persisting the 'token' URL param when redirecting
 * from '/auth/reset-password/:token' to '/reset-password/:token'.
 */
const LegacyRedirect = () => {
  const {token} = useParams();
  return <Navigate to={`/reset-password/${token}`} />;
};
ResetPasswordPage.LegacyRedirect = LegacyRedirect;
