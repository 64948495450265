import {useCallback, useMemo} from 'react';

import {type IconType} from '@react-icons/all-files';
import clsx from 'clsx';
import {FaFacebook, FaApple} from 'react-icons/fa';
import {FcGoogle} from 'react-icons/fc';

import {Button} from 'components_sb/buttons';
import SocialAuth, {
  type SocialAuthProviderType,
} from 'utilities/auth/SocialAuth';

/**
 * Tailwind class configuration
 */
const classes = {
  // Classes for all permutations
  base: 'shadow-md',
};

export interface SocialAuthButtonConfig {
  /**
   * The action being performed by the button.
   */
  action: 'login' | 'register';
  /**
   * A callback function that is invoked upon successful authentication.
   */
  onSuccess: (provider: SocialAuthProviderType, data: any) => void;
  /**
   * A callback function that is invoked upon authentication failure.
   */
  onError: (provider: SocialAuthProviderType) => void;
}

interface SocialAuthButtonProps {
  /**
   * The auth provider to use for the button.
   */
  provider: SocialAuthProviderType;
  /**
   * Configuration for the social authentication.
   */
  config: SocialAuthButtonConfig;
}

/**
 * Configuration for an auth provider at a component level.
 */
interface AuthProviderConfig {
  label: string;
  icon: IconType;
  classes: string;
}

/**
 * Definitions for each of the auth providers.
 */
const AUTH_PROVIDER_CONFIGS: Record<string, AuthProviderConfig> = {
  [SocialAuth.Provider.Facebook]: {
    label: 'Facebook',
    icon: FaFacebook,
    classes:
      'bg-[#1877F2] border-[#1877F2] hover:bg-[#3587f3] hover:border-[#3587f3] text-white',
  },
  [SocialAuth.Provider.Google]: {
    label: 'Google',
    icon: FcGoogle,
    classes:
      'bg-white border-[#f7f7f7] hover:bg-[#eeeeee] hover:border-[#eeeeee] text-[#757575]',
  },
  [SocialAuth.Provider.Apple]: {
    label: 'Apple',
    icon: FaApple,
    classes:
      'bg-black border-black hover:bg-[#262626] hover:border-[#262626] text-white',
  },
};

/**
 * A button for authenticating a user via a social platform.
 */
const SocialAuthButton = ({provider, config}: SocialAuthButtonProps) => {
  const providerConfig = AUTH_PROVIDER_CONFIGS[provider];

  const labelPrefix = useMemo(
    () => (config.action === 'register' ? 'Register with' : 'Log in with'),
    [config.action],
  );

  const onClick = useCallback(() => {
    const {onSuccess, onError} = config;
    SocialAuth.authenticate(provider, onSuccess, onError);
  }, [provider, config]);

  return (
    <Button
      label={`${labelPrefix} ${providerConfig.label}`}
      category="custom"
      customClasses={clsx(classes.base, providerConfig.classes)}
      size="base"
      mode="manual"
      onClick={onClick}
      icon={providerConfig.icon}
    />
  );
};

export default SocialAuthButton;
