import {useEffect, useState} from 'react';

import {type FormikHelpers, Formik, Form} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import InlineMoneyField from 'components/forms_fields/InlineMoneyField';
import InlineSelectField from 'components/forms_fields/InlineSelectField';
import ToggleButtonGroup from 'components/forms_fields/ToggleButtonGroup';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightSideText from 'components/walkthrough/common/RightSideText';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import TrackingService from 'services/TrackingService';

type FormValues = {
  totalRent: number;
  rentalPeriod: 'Weekly' | 'Fortnightly';
  dayOfWeekRentPaid:
    | 'Monday'
    | 'Tuesday'
    | 'Wednesday'
    | 'Thursday'
    | 'Friday'
    | 'Saturday'
    | 'Sunday';
};

const RentStep = ({property}: {property: Property}) => {
  useEffect(() => {
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.MigrateTenancy_StartRentDetailsStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const queryClient = useQueryClient();
  const tenancy = property.tenancies[0];

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    setIsLoading(true);

    const t = tenancy || new Tenancy();
    t.status = 'draft';
    t.isNew = false;
    t.assignAttributes(formValues);
    t.propertyId = property.id;

    const result = await t.save();
    if (result) {
      property.lastOnboardingStepCompleted = 'migrate_rent_information';
      await property.save();
      if (property.tenancies.length === 0) {
        property.tenancies = [t];
      } else {
        property.tenancies.unshift(t);
      }

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.MigrateTenancy_CompleteRentDetailsStep,
        {
          propertyId: property.id,
        },
      );

      queryClient.setQueryData(['new-property', property.id], property);
    } else {
      console.log(t.errors);
      for (const key of Object.keys(t.errors)) {
        const message = t.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    setIsLoading(false);
  };

  const goToPrevious = async () => {
    setIsLoading(true);
    property.lastOnboardingStepCompleted = 'add_property_address';
    await property.save();

    queryClient.setQueryData(['new-property', property.id], property);
    setIsLoading(false);
  };

  const title = 'Next up, tenancy information...';
  const subtitle = 'Enter the rent details for this property';

  return (
    <Container>
      <LeftSide title={title} subtitle={subtitle} />
      <RightContainer>
        <RightSideText title={title} subtitle={subtitle} />
        <Formik
          initialValues={{
            totalRent: tenancy ? tenancy.totalRent : null,
            rentalPeriod: tenancy ? tenancy.rentalPeriod : null,
            dayOfWeekRentPaid: tenancy ? tenancy.dayOfWeekRentPaid : null,
          }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            totalRent: Yup.number().nullable().required().min(1).label('Rent'),
            rentalPeriod: Yup.string()
              .nullable()
              .oneOf(['Weekly', 'Fortnightly'])
              .required()
              .label('Rent Frequency'),
            dayOfWeekRentPaid: Yup.string()
              .nullable()
              .oneOf([
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday',
              ])
              .required()
              .label('Rent Day'),
          })}
          validateOnChange={false}
          validateOnBlur={false}>
          {(formik) => (
            <Form>
              <div className="flex flex-col md:mx-16 space-y-8">
                <InlineMoneyField
                  formik={formik}
                  name="totalRent"
                  label="Rent amount"
                />

                <ToggleButtonGroup
                  formik={formik}
                  name="rentalPeriod"
                  label="Frequency"
                  options={[
                    {value: 'Weekly', label: 'Weekly'},
                    {value: 'Fortnightly', label: 'Fortnightly'},
                  ]}
                />

                <InlineSelectField
                  formik={formik}
                  name="dayOfWeekRentPaid"
                  helpText="The day of the week rent is paid"
                  label="Paid on">
                  <option value="">Choose an option</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                  <option value="Saturday">Saturday</option>
                  <option value="Sunday">Sunday</option>
                </InlineSelectField>
              </div>
              <ProgressBox
                nextStep={formik.submitForm}
                previousStep={goToPrevious}
                step={3}
                totalSteps={7}
                previousStepEnabled
                nextStepEnabled={!isLoading}
              />
            </Form>
          )}
        </Formik>
      </RightContainer>
    </Container>
  );
};

export default RentStep;
