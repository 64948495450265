import {AiOutlineInfoCircle} from '@react-icons/all-files/ai/AiOutlineInfoCircle';
import {useNavigate} from 'react-router';

import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';

const TenancyWalkthroughCard = ({
  property,
  tenancy,
}: {
  property: Property;
  tenancy?: Tenancy;
}) => {
  const navigate = useNavigate();

  const resumeTenancy = () => {
    localStorage.setItem('new-property-id', property.id);
    navigate('/properties/new');
  };

  if (property) {
    if (tenancy && tenancy.status === 'draft') {
      return (
        <div
          className="alert alert-info shadow-lg bg-[#232A59] text-brand-25 cursor-pointer mt-2"
          onClick={resumeTenancy}>
          <div className="flex justify-between items-center w-full">
            <div className="flex justify-start gap-2">
              <AiOutlineInfoCircle className="w-6 h-6" />
              <span>Click here to resume setting up your tenancy.</span>
            </div>
            <button
              className="btn btn-xs bg-white text-black"
              onClick={resumeTenancy}>
              Continue
            </button>
          </div>
        </div>
      );
    } else if (!tenancy && property.noTenancyActionType !== 'advertise') {
      return (
        <div
          className="alert alert-info shadow-lg bg-[#232A59] text-brand-25 cursor-pointer mt-2"
          onClick={resumeTenancy}>
          <div className="flex justify-between items-center w-full">
            <div className="flex justify-start gap-2">
              <AiOutlineInfoCircle className="w-6 h-6" />
              <span>Click here to continue setting up your property.</span>
            </div>
            <button
              className="btn btn-xs bg-white text-black"
              onClick={resumeTenancy}>
              Continue
            </button>
          </div>
        </div>
      );
    }
  } else {
    return null;
  }
};

export default TenancyWalkthroughCard;
