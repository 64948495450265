import {Provider} from './providers';
import {authenticate as aunthenticateApple} from './providers/apple';
import {authenticate as aunthenticateFacebook} from './providers/facebook';
import {authenticate as aunthenticateGoogle} from './providers/google';

const providerAuthentication = {
  [Provider.Google]: aunthenticateGoogle,
  [Provider.Facebook]: aunthenticateFacebook,
  [Provider.Apple]: aunthenticateApple,
};

export interface ProviderAuthFunction {
  (onSuccess: (data: any) => void, onError: () => void): void;
}

const authenticate = (
  provider: Provider,
  onSuccess: (provider: Provider, data: any) => void,
  onError: (provider: Provider) => void,
) => {
  providerAuthentication[provider](
    // Pass the provider to the callback functions for reference
    (data: any) => onSuccess(provider, data),
    () => onError(provider),
  );
};

export default authenticate;
