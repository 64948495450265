import {useQuery} from 'react-query';

import Inspection from 'models/inspections/Inspection';
import InspectionItem from 'models/inspections/InspectionItem';
import useInspectionStore from 'stores/InspectionStore';

const useInspectionQueries = (id: string) => {
  const [setInspection, inspectionItems, setInspectionItems] =
    useInspectionStore((state) => [
      state.setInspection,
      state.inspectionItems,
      state.setInspectionItems,
    ]);

  const {
    isLoading,
    data: inspection,
    error,
  } = useQuery(
    `inspection-detail-${id}`,
    async () => {
      const result = await Inspection.includes([
        {
          inspection_sign_offs: 'user',
        },
        {tenancy: 'property'},
      ])
        .where({'inspection_sign_offs.was_auto_signed': false})
        .select({
          tenancies: ['head_tenant_id', 'property_id'],
          users: ['name', 'id', 'avatar'],
          properties: ['id', 'street_address', 'landlord_id'],
        })
        .find(id);

      return result.data;
    },
    {
      onSuccess: setInspection,
    },
  );

  const {isLoading: inspectionItemsIsLoading, refetch: refetchInspectionItems} =
    useQuery(
      `inspection-detail-${id}-items`,
      async () => {
        const items = await InspectionItem.includes([
          {inspection_item_attachments: 'user'},
          {inspection_actions: 'user'},
        ])
          .where({inspectionId: id})
          .order({id: 'asc'})
          .select({
            users: ['id', 'name', 'avatar'],
          })
          .all();

        return items.data;
      },
      {
        enabled: !!inspection,
        onSuccess: setInspectionItems,
        // When a landlord is on schedule inspection, upon saving, the room list is empty
        // It might take a second or two for all the items to generate
        // So we want to refetch until the items have been loaded.
        refetchInterval: (data) => {
          if (inspection && inspection.status === 'awaiting_inspection') {
            return !data || data.length === 0 ? 1000 : false;
          } else {
            return false;
          }
        },
      },
    );

  return {
    inspectionIsLoading: isLoading,
    inspectionError: error,
    inspection: inspection,
    inspectionItemsIsLoading,
  };
};

export default useInspectionQueries;
