import {Capacitor} from '@capacitor/core';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {setBackForwardNavigationGestures} from 'capacitor-plugin-ios-webview-configurator';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import {createRoot} from 'react-dom/client';

import {TARGET_ENV} from 'globals/app-globals';
import TrackingService from 'services/TrackingService';

import App from './App';
import Providers from './Providers';
import 'models/loadModels';

import 'assets/styles/global.css';

if (['production', 'staging'].includes(process.env.TARGET_ENV)) {
  Sentry.init({
    dsn: 'https://2ab6f77375f74b48a9b7d42fc135fef2@o4504233323855872.ingest.sentry.io/4504233326084096',
    integrations: [new BrowserTracing()],
    environment: TARGET_ENV,
    tracesSampleRate: 1.0,
  });
}

if (process.env.TARGET_ENV === 'production') {
  LogRocket.init('4rny14/keyhook-app');
  setupLogRocketReact(LogRocket);

  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}

TrackingService.init();

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

if (Capacitor.isNativePlatform()) {
  setBackForwardNavigationGestures(true);
}

root.render(
  <Providers>
    <App />
  </Providers>,
);
