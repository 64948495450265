import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Tenancy from 'models/properties/Tenancy';
import User from 'models/users/User';

@Model()
class TenancyRequest extends ApplicationRecord {
  static jsonapiType = 'tenancy_requests';

  @Attr() accepted: boolean;

  @Attr() renterEmail: string;
  @Attr() phoneNumber: string;

  @Attr() renterSignature: string;

  @Attr() identificationType: string;
  @Attr() identificationNumber: string;

  @Attr() isHeadTenant: boolean;

  @Attr() rentSplit: number;
  @Attr() bondSplit: number;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('tenancies') tenancy: Tenancy;
  @BelongsTo('users') renter: User;
}

export default TenancyRequest;
