import ListingApplicationCard from 'components/listing/ListingApplicationCard';
import ListingEnquireCard from 'components/listing/ListingEnquireCard';
import Listing from 'models/listings/Listing';

const ListingSidebar = ({listing}: {listing: Listing}) => (
  <>
    <ListingEnquireCard listing={listing} />
    <ListingApplicationCard listing={listing} />
  </>
);

export default ListingSidebar;
