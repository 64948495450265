import {Route} from 'react-router';

import EnquiryThreadDetailPage from 'pages/renter/enquiry_threads/EnquiryThreadDetailPage';
import EnquiryThreadIndexPage from 'pages/renter/enquiry_threads/EnquiryThreadIndexPage';
import OpenHomeIndexPage from 'pages/renter/open_homes/OpenHomeIndexPage';
import RentPaymentIndexPage from 'pages/renter/rent_payments/RentPaymentIndexPage';
import EditRentalApplicationPage from 'pages/renter/rental_applications/EditRentalApplicationPage';
import NewRentalApplicationPage from 'pages/renter/rental_applications/NewRentalApplicationPage';
import RentalApplicationDetailPage from 'pages/renter/rental_applications/RentalApplicationDetailPage';
import RentalApplicationIndexPage from 'pages/renter/rental_applications/RentalApplicationIndexPage';
import RentalApplicationListingsPage from 'pages/renter/rental_applications/RentalApplicationListingsPage';
import EditServiceRequestPage from 'pages/renter/service_requests/EditServiceRequestPage';
import NewServiceRequestPage from 'pages/renter/service_requests/NewServiceRequestPage';
import ServiceRequestDetailPage from 'pages/renter/service_requests/ServiceRequestDetailPage';
import EditRentSplitsPage from 'pages/renter/tenancies/EditRentSplitsPage';
import TenancyDetailPage from 'pages/renter/tenancies/TenancyDetailPage';
import TenancyIndexPage from 'pages/renter/tenancies/TenancyIndexPage';
import EditTenancyRenewalPage from 'pages/renter/tenancy_renewals/EditTenancyRenewalPage';
import TenancyRequestDetailPage from 'pages/renter/tenancy_requests/TenancyRequestDetailPage';
import InspectionActionDetailPage from 'pages/shared/inspections/InspectionActionDetailPage';
import InspectionAmendmentsPage from 'pages/shared/inspections/InspectionAmendmentsPage';
import InspectionDetailPage from 'pages/shared/inspections/InspectionDetailPage';
import InspectionRoomDetailPage from 'pages/shared/inspections/InspectionRoomDetailPage';
import NewTenancyInvitePage from 'pages/shared/tenancy_invites/NewTenancyInvitePage';

const RenterRoutes = [
  <Route path="tenancies" key="1">
    <Route path=":tenancyId/rent-payments">
      <Route path="" element={<RentPaymentIndexPage />} />
    </Route>

    <Route path=":tenancyId/inspections">
      <Route
        path=":inspectionId/actions/:actionId"
        element={<InspectionActionDetailPage />}
      />
      <Route
        path=":inspectionId/revisions"
        element={<InspectionAmendmentsPage />}
      />
      <Route
        path=":inspectionId/:roomKey"
        element={<InspectionRoomDetailPage />}
      />

      <Route path=":id" element={<InspectionDetailPage />} />
    </Route>
    <Route path=":tenancyId/service-requests">
      <Route path="new" element={<NewServiceRequestPage />} />
      <Route path=":id/edit" element={<EditServiceRequestPage />} />
      <Route path=":id" element={<ServiceRequestDetailPage />} />
    </Route>
    <Route path=":tenancyId/tenancy-renewals">
      <Route path=":id/edit" element={<EditTenancyRenewalPage />} />
    </Route>

    <Route path=":id/invite-tenants" element={<NewTenancyInvitePage />} />

    <Route path=":id/edit-rent-amounts" element={<EditRentSplitsPage />} />

    <Route path=":id" element={<TenancyDetailPage />} />
    <Route path="" element={<TenancyIndexPage />} />
  </Route>,
  <Route path="tenancy-requests" key="3">
    <Route path=":id" element={<TenancyRequestDetailPage />} />
  </Route>,

  <Route path="rental-applications" key="4">
    <Route path="new" element={<NewRentalApplicationPage />} />
    <Route path=":id/edit" element={<EditRentalApplicationPage />} />
    <Route path=":id/listings" element={<RentalApplicationListingsPage />} />
    <Route path=":id" element={<RentalApplicationDetailPage />} />
    <Route path="" element={<RentalApplicationIndexPage />} />
  </Route>,

  <Route path="enquiries" key="5">
    <Route path=":id" element={<EnquiryThreadDetailPage />} />
    <Route path="" element={<EnquiryThreadIndexPage />} />
  </Route>,

  <Route path="open-homes" key="6">
    <Route path="" element={<OpenHomeIndexPage />} />
  </Route>,

  <Route path="" element={<TenancyIndexPage />} key="2" />,
];

export default RenterRoutes;
