import {useState} from 'react';

import {Form, Formik, type FormikProps} from 'formik';
import _ from 'lodash';
import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import DocumentPreviewModal from 'components/document/DocumentPreviewModal';
import {
  DateField,
  InputField,
  SelectField,
  SubmitButton,
} from 'components/forms_fields';
import SignatureModalInput from 'components/forms_fields/SignatureModalInput';
import PageWrapper from 'components/PageWrapper';
import LeaseConditions from 'components/tenancy/LeaseConditions';
import {API_URL} from 'globals/app-globals';
import Tenancy from 'models/properties/Tenancy';
import TrackingService from 'services/TrackingService';
import useAuth from 'services/useAuth';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

const queryString = require('query-string');

const EditTenancyPage = () => {
  useTitle('Edit Lease');
  usePageVisit('EditTenancyPage');

  const [tenancyData, setTenancyData] = useState<any>({});
  const [formView, setFormView] = useState('TenancyForm');
  const [leaseUrl, setLeaseUrl] = useState(null);
  const [bondUrl, setBondUrl] = useState('');

  const {currentUser} = useAuth();

  const [signatureData, setSignatureData] = useState('');

  const [leaseModalVisible, setLeaseModalVisible] = useState(false);
  const [bondModalVisible, setBondModalVisible] = useState(false);

  const {propertyId, id} = useParams();

  const {data, isLoading, error} = useQuery(
    `landlord-edit-tenancy-${id}`,
    async () => {
      const tenancy = await Tenancy.includes(['tenancy_requests', 'property'])
        .select({
          properties: [
            'chattels',
            'courtesy_chattels',
            'inspection_period',
            'pets_allowed',
            'allowed_pet_types',
            'renters_insurance_required',
            'smokers_allowed',
            'max_tenants',
          ],
        })
        .find(id);

      return tenancy.data;
    },
  );
  const queryCache = useQueryClient();

  const navigate = useNavigate();

  const saveTenancy = async () => {
    const tenancy: Tenancy = data;
    tenancy.assignAttributes(tenancyData);

    tenancy.landlordSignature = signatureData;

    const response = await tenancy.save();
    if (response) {
      await queryCache.invalidateQueries(
        `landlord-property-detail-${propertyId}`,
      );
      toast.success('Tenancy successfully updated!');
      TrackingService.trackEvent(TrackingService.Event.EditTenancy, {
        tenancyId: tenancy.id,
      });
      navigate(`/properties/${propertyId}`);
    } else {
      console.log(tenancy.errors);
      for (const savedRequest of tenancy.tenancyRequests) {
        console.log(savedRequest.errors);
      }
    }
  };

  const handleSubmit = async (formData: object, actions: any) => {
    const leaseParams: any = _.omit(formData, [
      'tenancyRequests',
      'bankAccountName',
      'bankAccountNumber',
      'insurancePolicies',
    ]);
    const bondParams: any = _.omit(formData, [
      'tenancyRequests',
      'customClauses',
      'bankAccountName',
      'bankAccountNumber',
      'insurancePolicies',
      'clauses',
      'leaseDocumentType',
    ]);

    leaseParams.customClauses = leaseParams.customClauses.clauses;

    fetch(`${API_URL}/properties/${propertyId}/lease_preview.pdf`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        'X-USER-TOKEN': currentUser.meta.authenticationToken,
        'X-USER-EMAIL': currentUser.email,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(leaseParams), // body data type must match "Content-Type" header
    })
      .then((response) => {
        return response.arrayBuffer();
      })
      .then((data) => setLeaseUrl(data));

    // const leaseURL = `${API_URL}/properties/${propertyId}/lease_preview.pdf?${qString}`;
    const qString = queryString.stringify(bondParams, {arrayFormat: 'bracket'});
    const bondURL = `${API_URL}/properties/${propertyId}/bond_preview.pdf?${qString}`;
    // setLeaseUrl(leaseURL);
    setBondUrl(bondURL);
    actions.setSubmitting(false);

    setTenancyData(formData);
    TrackingService.trackEvent(TrackingService.Event.SetTenancyData);
    setFormView('Signature');
  };

  const handleMigrateSubmit = async (formData: any, actions: any) => {
    actions.setSubmitting(false);

    const tenancy: Tenancy = data;
    tenancy.assignAttributes(formData);

    const response = await tenancy.save();
    if (response) {
      await queryCache.invalidateQueries(
        `landlord-property-detail-${propertyId}`,
      );
      toast.success('Tenancy successfully updated!');
      navigate(`/properties/${propertyId}`);
    } else {
      console.log(tenancy.errors);
      for (const savedRequest of tenancy.tenancyRequests) {
        console.log(savedRequest.errors);
      }
    }
  };

  const rentalPeriods = ['Weekly', 'Fortnightly'];

  const quickSetEndDate = (formik: FormikProps<any>, numMonths: number) => {
    if (formik.values.startDate) {
      const sd = moment(formik.values.startDate);
      const endDate = sd.add(numMonths, 'months').format('YYYY-MM-DD');
      formik.setFieldValue('endDate', endDate);
    }
  };

  const quickSetBond = (formik: FormikProps<any>, numberOfWeeks: number) => {
    if (formik.values.totalRent && formik.values.totalRent !== '') {
      let rentAmount: number;
      if (formik.values.rentalPeriod === 'Weekly') {
        rentAmount = Number(formik.values.totalRent);
      } else {
        rentAmount = Number(formik.values.totalRent) / 2.0;
      }
      const bondAmount = rentAmount * numberOfWeeks;
      formik.setFieldValue('bond', bondAmount);

      TrackingService.trackEvent(TrackingService.Event.QuickSetBond, {
        numberOfWeeks,
      });
    }
  };

  const componentForFormView = () => {
    if (data.isNew) {
      const schema = Yup.object().shape({
        bond: Yup.number()
          .min(0)
          .required()
          .nullable()
          .label('Bond')
          .test(
            'max-bond-is-allowed',
            'Bond can not be greater than 4 weeks rent',
            function (value) {
              if (!value || value == 0) {
                return true;
              }
              const rentalPeriod = this.parent.rentalPeriod;
              const rent = this.parent.totalRent;
              if (rentalPeriod === 'Weekly') {
                return value <= rent * 4;
              } else if (rentalPeriod === 'Fortnightly') {
                return value <= rent * 2;
              } else {
                return true;
              }
            },
          ),
        totalRent: Yup.number().min(0).required().label('Rent'),
        rentalPeriod: Yup.string()
          .oneOf(rentalPeriods)
          .required()
          .label('Rental Period'),
        weeksRentInAdvance: Yup.number()
          .min(0)
          .max(2)
          .required()
          .label('Rent in advance'),
        leaseDocumentType: Yup.string()
          .required()
          .oneOf(['keyhook_new_zealand', 'tenancy_services'])
          .label('Lease Type'),
        startDate: Yup.string().required().label('Start Date'),
        endDate: Yup.string()
          .nullable()
          .label('End Date')
          .test(
            'should-be-greather-than-start-date',
            'Should be after the Start Date',
            function (value) {
              if (!value) {
                return true;
              }
              const startDate = this.parent.startDate;
              if (!startDate) {
                return true;
              }

              return moment(value) > moment(startDate);
            },
          ),
        customClauses: Yup.object()
          .shape({
            clauses: Yup.array().of(Yup.string().min(1).label('Condition')),
          })
          .label('Conditions'),
      });

      if (formView === 'TenancyForm') {
        return (
          <Formik
            initialValues={data.attributes as any}
            onSubmit={handleSubmit}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}>
            {(formik) => {
              const today = new Date();
              const minDate = formik.values.startDate
                ? new Date(formik.values.startDate)
                : new Date(today);
              minDate.setDate(minDate.getDate() + 1);
              const maxDate = new Date('2100-01-01');
              return (
                <Form>
                  <div>
                    <Card className="mt-4 overflow-visible">
                      <CardBody>
                        <CardTitle>Tenancy Information</CardTitle>

                        <div className="flex justify-between">
                          <div className="flex-1">
                            <DateField
                              label="Start Date"
                              name="startDate"
                              formik={formik}
                              minDate={today}
                              maxDate={maxDate}
                            />
                          </div>
                          <div className="flex-1 ml-2">
                            <DateField
                              label="End Date (Leave blank if this is an ongoing lease.)"
                              name="endDate"
                              formik={formik}
                              minDate={minDate}
                              maxDate={maxDate}>
                              <div className="dropdown dropdown-end">
                                <button
                                  tabIndex={0}
                                  className="btn !rounded-l-none !rounded-r"
                                  type="button"
                                  disabled={
                                    !formik.values.startDate ||
                                    formik.values.startDate === ''
                                  }>
                                  Quick Set
                                </button>
                                <ul
                                  tabIndex={0}
                                  className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                                  {[3, 6, 12, 24].map((numMonths) => (
                                    <li key={numMonths}>
                                      <a
                                        onClick={() =>
                                          quickSetEndDate(formik, numMonths)
                                        }>
                                        {numMonths} Months
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </DateField>
                          </div>
                        </div>

                        <div className="flex justify-between">
                          <div className="flex-1">
                            <InputField
                              formik={formik}
                              name="totalRent"
                              label="Total Rent ($)"
                              placeholder="eg: 500"
                              type="number"
                            />
                          </div>
                          <div className="flex-1 ml-2">
                            <InputField
                              formik={formik}
                              name="bond"
                              label="Bond ($)"
                              placeholder="eg: 2000"
                              type="number"
                              className="w-auto flex-1">
                              <div className="dropdown dropdown-end">
                                <button
                                  tabIndex={0}
                                  className="btn !rounded-l-none !rounded-r"
                                  disabled={
                                    !formik.values.totalRent ||
                                    formik.values.totalRent === ''
                                  }>
                                  Quick Set
                                </button>
                                <ul
                                  tabIndex={0}
                                  className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                                  {[1, 2, 3, 4].map((numWeeks) => (
                                    <li key={numWeeks}>
                                      <a
                                        onClick={() =>
                                          quickSetBond(formik, numWeeks)
                                        }>
                                        {numWeeks} Weeks Rent
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </InputField>
                          </div>
                        </div>
                        <div className="form-group">
                          <SelectField
                            name="leaseDocumentType"
                            formik={formik}
                            label="Lease Type"
                            helpText="Choose the lease template you wish to use. We recommend using the Keyhook Lease. You can review and edit your lease below.">
                            <option value="keyhook_new_zealand">
                              Keyhook Lease
                            </option>
                            <option value="tenancy_services">
                              Tenancy Services
                            </option>
                          </SelectField>
                        </div>
                        <div className="form-group">
                          <SelectField
                            name="weeksRentInAdvance"
                            formik={formik}
                            label="Rent In Advance">
                            <option value="0">None</option>
                            <option value="1">1 Week</option>
                            <option value="2">2 Weeks</option>
                          </SelectField>
                        </div>
                      </CardBody>
                    </Card>

                    <Card className="mt-4">
                      <CardBody>
                        <LeaseConditions
                          property={data.property}
                          formik={formik}
                        />
                      </CardBody>
                    </Card>
                  </div>
                  <div className="mt-2">
                    <SubmitButton
                      formik={formik}
                      text="Next"
                      submittingText="Loading"
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        );
      } else if (formView === 'Signature') {
        return (
          <Card className="mt-4 overflow-visible">
            <CardBody>
              <CardTitle>Documents</CardTitle>
              <p>
                Click the buttons below to preview the lease and bond, your
                signature will be filled in the landlord section of these
                documents.
              </p>
              <div>
                <div>
                  {leaseUrl ? (
                    <button
                      className="btn btn-neutral btn-sm mt-2"
                      type="button"
                      onClick={() => setLeaseModalVisible(true)}>
                      Preview Lease
                    </button>
                  ) : (
                    <button
                      className="btn btn-neutral btn-sm mt-2"
                      type="button">
                      Lease Preview Is Loading
                    </button>
                  )}
                </div>

                <div>
                  <button
                    className="btn btn-neutral btn-sm mt-2"
                    type="button"
                    onClick={() => setBondModalVisible(true)}>
                    Preview Bond
                  </button>
                </div>
              </div>
              {leaseUrl && (
                <DocumentPreviewModal
                  isVisible={leaseModalVisible}
                  setIsVisible={setLeaseModalVisible}
                  documentUrl={leaseUrl}
                  title="Lease Preview"
                />
              )}

              <DocumentPreviewModal
                isVisible={bondModalVisible}
                setIsVisible={setBondModalVisible}
                documentUrl={bondUrl}
                title="Bond Preview"
              />

              <SignatureModalInput
                mode="manual"
                name="signature"
                signature={signatureData}
                onSave={setSignatureData}
              />
            </CardBody>
          </Card>
        );
      } else if (formView === 'Saving') {
        return <LoadingView />;
      }
    } else {
      const schema = Yup.object().shape({
        bond: Yup.number().min(0).nullable().label('Bond'),
        totalRent: Yup.number().min(0).required().label('Rent'),
        rentalPeriod: Yup.string()
          .oneOf(rentalPeriods)
          .required()
          .label('Rental Period'),
        startDate: Yup.string().required().label('Start Date'),
        endDate: Yup.string()
          .nullable()
          .label('End Date')
          .test(
            'should-be-greather-than-start-date',
            'Should be after the Start Date',
            function (value) {
              if (!value) {
                return true;
              }
              const startDate = this.parent.startDate;
              if (!startDate) {
                return true;
              }

              return moment(value) > moment(startDate);
            },
          ),
      });

      return (
        <Formik
          initialValues={data.attributes as any}
          onSubmit={handleMigrateSubmit}
          validationSchema={schema}
          validateOnBlur={false}
          validateOnChange={false}>
          {(formik) => {
            const today = new Date();
            const minDate = formik.values.startDate
              ? new Date(formik.values.startDate)
              : new Date(today);
            minDate.setDate(minDate.getDate() + 1);
            const maxDate = new Date('2100-01-01');
            return (
              <Form>
                <div>
                  <Card className="mt-4 overflow-visible">
                    <CardBody>
                      <CardTitle>Tenancy Information</CardTitle>

                      <div className="flex justify-between">
                        <div className="flex-1">
                          <DateField
                            label="Start Date"
                            name="startDate"
                            formik={formik}
                            minDate={today}
                            maxDate={maxDate}
                          />
                        </div>
                        <div className="flex-1 ml-2">
                          <DateField
                            label="End Date (Leave blank if this is an ongoing lease.)"
                            name="endDate"
                            formik={formik}
                            minDate={minDate}
                            maxDate={maxDate}>
                            <div className="dropdown dropdown-end">
                              <button
                                tabIndex={0}
                                className="btn !rounded-l-none !rounded-r"
                                disabled={
                                  !formik.values.startDate ||
                                  formik.values.startDate === ''
                                }>
                                Quick Set
                              </button>
                              <ul
                                tabIndex={0}
                                className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                                {[3, 6, 12, 24].map((numMonths) => (
                                  <li key={numMonths}>
                                    <a
                                      onClick={() =>
                                        quickSetEndDate(formik, numMonths)
                                      }>
                                      {numMonths} Months
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </DateField>
                        </div>
                      </div>

                      <div className="flex justify-between">
                        <div className="flex-1">
                          <InputField
                            formik={formik}
                            name="totalRent"
                            label="Total Rent ($)"
                            placeholder="eg: 500"
                            type="number"
                          />
                        </div>
                        <div className="flex-1 ml-2">
                          <InputField
                            formik={formik}
                            name="bond"
                            label="Bond ($)"
                            placeholder="eg: 2000"
                            type="number"
                            className="w-auto flex-1">
                            <div className="dropdown dropdown-end">
                              <button
                                tabIndex={0}
                                className="btn !rounded-l-none !rounded-r"
                                disabled={
                                  !formik.values.totalRent ||
                                  formik.values.totalRent === ''
                                }>
                                Quick Set
                              </button>
                              <ul
                                tabIndex={0}
                                className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                                {[1, 2, 3, 4].map((numWeeks) => (
                                  <li key={numWeeks}>
                                    <a
                                      onClick={() =>
                                        quickSetBond(formik, numWeeks)
                                      }>
                                      {numWeeks} Weeks Rent
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </InputField>
                        </div>
                      </div>
                      <div className="form-group">
                        <SelectField
                          name="rentalPeriod"
                          formik={formik}
                          label="Rental Period">
                          {rentalPeriods.map((period) => (
                            <option key={period} value={period}>
                              {period}
                            </option>
                          ))}
                        </SelectField>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="mt-2">
                  <SubmitButton
                    formik={formik}
                    text="Update"
                    submittingText="Saving"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      );
    }
  };

  const allowedStatuses = ['pending', 'draft'];
  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Edit Lease">
        <LoadingView />
      </PageWrapper>
    );
  } else if (
    data &&
    (allowedStatuses.indexOf(data.status) === -1 ||
      data.tenancyRequests.filter((req) => req.accepted).length > 1)
  ) {
    return (
      <PageWrapper title="Edit Lease">
        <Card>
          <CardBody>
            <CardTitle>You cannot edit this tenancy</CardTitle>
            <p>
              This tenancy is either already active, or has been accepted by at
              least one of the invited tenants.
            </p>
            <div className="mt-2">
              <Link to={`/properties/${propertyId}`}>
                <button className="btn btn-sm btn-secondary">Go Back</button>
              </Link>
            </div>
          </CardBody>
        </Card>
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Edit Lease">{componentForFormView()}</PageWrapper>
    );
  }
};

export default EditTenancyPage;
