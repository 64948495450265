import {useEffect, useState} from 'react';

import {Capacitor} from '@capacitor/core';
import {Keyboard} from '@capacitor/keyboard';
import {SafeArea} from 'capacitor-plugin-safe-area';

type Insets = {
  top: string | number;
  bottom: string | number;
};

/**
 * Returns the insets from the device to use for positioning
 * navbars and bottom bars etc.
 * Defaults to the css variable so that it reduces jumpiness.
 * Reason we dont just use the css variable is because its not perfect
 * when the keyboard is shown it will often hide under the status bar in iOS.
 */
const useNativeInsets = () => {
  const [appInsets, setAppinsets] = useState<Insets>({
    top: 'env(safe-area-inset-top)',
    bottom: 'env(safe-area-inset-bottom)',
  });

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      const setupWithInsets = async function () {
        const insets = await SafeArea.getSafeAreaInsets();
        setAppinsets({
          top: insets.insets.top,
          bottom: insets.insets.bottom,
        });
      };
      setupWithInsets();

      Keyboard.addListener('keyboardWillShow', (info) => {
        setupWithInsets();
      });

      Keyboard.addListener('keyboardWillHide', () => {
        setupWithInsets();
      });

      return () => {
        Keyboard.removeAllListeners();
      };
    }
  }, []);

  return appInsets;
};

export default useNativeInsets;
