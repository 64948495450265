import {useEffect, useState} from 'react';

import {AiOutlineInfoCircle} from '@react-icons/all-files/ai/AiOutlineInfoCircle';
import {type FormikHelpers, Formik, Form} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import ChattelsField from 'components/forms_fields/ChattelsField';
import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightFooter from 'components/walkthrough/common/RightFooter';
import RightSideText from 'components/walkthrough/common/RightSideText';
import Property from 'models/properties/Property';
import TrackingService from 'services/TrackingService';

const ChattelsStep = ({
  property,
  stepNumber,
  totalSteps,
}: {
  property: Property;
  stepNumber: number;
  totalSteps: number;
}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_StartChattelsStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = async (formValues: any, actions: FormikHelpers<any>) => {
    setIsLoading(true);

    property.assignAttributes(formValues);
    property.lastOnboardingStepCompleted = 'new_chattels';
    property.markStepAsCompleted('property_chattels');

    await property.save();

    /**
     * Track completion of the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_CompleteChattelsStep,
      {
        propertyId: property.id,
      },
    );

    queryClient.setQueryData(['new-property', property.id], property);
    setIsLoading(false);
  };

  const previousStep = async () => {
    property.lastOnboardingStepCompleted = 'new_requirements';
    await property.save();

    queryClient.setQueryData(['new-property', property.id], property);
  };

  const title = 'Add the chattels for your property';

  return (
    <Container>
      <LeftSide title={title} subtitle="" />
      <RightContainer align="start">
        <RightSideText title={title} />
        <Formik
          initialValues={{
            chattels: property.chattels || {},
            courtesyChattels: property.courtesyChattels || {},
          }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            chattels: Yup.object().required().label('Chattels'),
            courtesyChattels: Yup.object()
              .optional()
              .label('Courtesy Chattels'),
          })}>
          {(formik) => (
            <Form>
              <div className="flex flex-col md:mx-16 space-y-8">
                <div>
                  <strong className="mb-2 block">Chattels</strong>
                  <ChattelsField formik={formik} name="chattels" />
                </div>

                <div>
                  <strong className="mb-2 block flex justify-start items-center gap-2">
                    Courtesy Chattels
                    <HelpTextPresenter
                      helpText="Courtesy chattels are provided by the landlord under the understanding
                          that the landlord is not responsible for their maintenance. An example
                          of a courtesy chattel is a BBQ a landlord has left on their rental
                          property for a tenant to use throughout their tenancy."
                    />
                  </strong>
                  <ChattelsField formik={formik} name="courtesyChattels" />
                </div>
              </div>
              <ProgressBox
                nextStep={formik.submitForm}
                previousStep={previousStep}
                step={stepNumber}
                totalSteps={totalSteps}
                previousStepEnabled
                nextStepEnabled={!isLoading}
              />
            </Form>
          )}
        </Formik>
      </RightContainer>
    </Container>
  );
};

export default ChattelsStep;
