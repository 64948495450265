import {useMemo} from 'react';

import {Link as RouterLink} from 'react-router-dom';

/**
 * A basic wrapper for an HTML anchor (a) tag.
 * @param to The URL translating to the href property.
 * @param props Arbitrary props to apply to the anchor tag.
 */
const AnchorLink = ({to, ...props}: {to: string}) => <a href={to} {...props} />;

/**
 * Returns either the appropriate component type depending on whether
 * a given link is internal or external.
 * @param to A link to either an internal or external destination.
 */
const useLinkComponent = (to: string) => {
  // Use the React Router link if linking to an internal path, otherwise
  // default to a standard anchor tag
  const Component = useMemo(
    () => (to.startsWith('/') ? RouterLink : AnchorLink),
    [to],
  );

  return Component;
};

export default useLinkComponent;
