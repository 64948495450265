import {useCallback, useImperativeHandle, useState} from 'react';

import {ModalDefinition, Modal} from 'components_sb/layout';
import type {ModalProps} from 'components_sb/layout';
import {API_URL} from 'globals/app-globals';
import InspectionItem from 'models/inspections/InspectionItem';
import useAuth from 'services/useAuth';
import useInspectionStore from 'stores/InspectionStore';
import {camelize} from 'utilities/MiscHelpers';

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const AddInspectionAreaModal: ModalDefinition = {
  title: 'Add an area',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'save',
        label: {
          idle: 'Save',
          loading: 'Saving',
        },
        handle: 'onSave',
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const [areaName, setAreaName] = useState('');

    const [inspection, inspectionItems, setInspectionItems] =
      useInspectionStore(
        ({inspection, inspectionItems, setInspectionItems}) => [
          inspection,
          inspectionItems,
          setInspectionItems,
        ],
      );

    const {currentUser} = useAuth();

    const onSave = async () => {
      const resp = await fetch(
        `${API_URL}/inspections/${inspection.id}/add_area.json`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-USER-TOKEN': currentUser.meta.authenticationToken,
            'X-USER-EMAIL': currentUser.email,
          },
          body: JSON.stringify({
            areaName,
          }),
        },
      );

      const data = await resp.json();
      const items = data.items as Record<string, any>[];

      const models = items.map((obj) => {
        const transformed = camelize(obj);
        const model = new InspectionItem(transformed);
        model.isPersisted = true;

        return model;
      });

      for (const model of models) {
        inspectionItems.push(model);
      }
      setInspectionItems([...inspectionItems]);

      inspection.deleteSignOffs();

      await delay(3000);
    };

    useImperativeHandle(ref, () => ({
      onSave,
    }));

    return (
      <>
        <p className="text-secondary">Enter the name of the area</p>
        <div className="mt-2">
          <input
            className="input input-bordered w-full"
            value={areaName}
            onChange={(e) => setAreaName(e.target.value)}
            placeholder="Area name..."
          />
        </div>
      </>
    );
  },
};

export default AddInspectionAreaModal;
