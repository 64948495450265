import {HTMLAttributes} from 'react';

type CardProps = HTMLAttributes<HTMLElement>;

const Card = ({children, className, ...rest}: CardProps) => {
  return (
    <div
      className={`card bg-base-100 shadow-xl overflow-visible ${className}`}
      {...rest}>
      {children}
    </div>
  );
};

export default Card;
