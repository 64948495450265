import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import Property from 'models/properties/Property';
import PropertyTransfer from 'models/properties/PropertyTransfer';
import useAuth from 'services/useAuth';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {titleize, removeUnderscores} from 'utilities/StringHelpers';

const PendingTransferCard = ({property}: {property: Property}) => {
  const {currentUser} = useAuth();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const queryClient = useQueryClient();

  const transferRequest = useQuery(
    `landlord-property-transfer-${property.id}`,
    async () => {
      const transfer = await PropertyTransfer.where({
        propertyId: property.id,
        sendingUserId: currentUser.id,
        status: {eq: ['pending', 'confirmed_by_landlord']},
      })
        .select({
          users: ['name'],
        })
        .order({created_at: 'desc'})
        .includes('receiving_user')
        .first();

      return transfer.data;
    },
    {
      enabled: property && property.isPendingTransfer,
      retry: 1,
    },
  );

  const confirmDeletePendingTransfer = async () => {
    setConfirmationOptions({
      title: 'Undo Property Transfer',
      message: 'Are you sure you want to undo this property transfer?',
      buttonTitle: 'Undo',
      action: deletePendingTransfer,
      color: 'error',
    });
  };

  const deletePendingTransfer = async () => {
    const transfer = transferRequest.data as PropertyTransfer;

    const result = await transfer.destroy();
    if (result) {
      property!.isPendingTransfer = false;
      queryClient.setQueryData(
        `landlord-property-detail-${property.id}`,
        property,
      );
      queryClient.setQueryData(
        `landlord-property-transfer-${property.id}`,
        null,
      );

      toast.success('You have successfully undone this property transfer!');
    }
  };

  if (transferRequest.data) {
    return (
      <Card className="mt-8 border-t-4 border-blue-500">
        <CardBody>
          <CardTitle className="mb-0">Property Transfer</CardTitle>
          <span className="block text-secondary mt-0 text-sm">
            {titleize(removeUnderscores(transferRequest.data.status))}
          </span>
          <p className="mt-4">
            This property is currently pending transfer to{' '}
            {transferRequest.data.receivingUser.name}.
          </p>
          {transferRequest.data.status === 'pending' && (
            <>
              <p className="my-4">
                We are currently waiting for you to visit the link in the
                confirmation email.
              </p>

              <button
                className="btn btn-danger mt-4"
                onClick={confirmDeletePendingTransfer}>
                Undo Transfer
              </button>
            </>
          )}
          {transferRequest.data.status === 'confirmed_by_landlord' && (
            <p className="my-4">
              You have confirmed the transfer, we are currently waiting for the
              new Landlord to accept this transfer.
            </p>
          )}
        </CardBody>
      </Card>
    );
  }

  return null;
};

export default PendingTransferCard;
