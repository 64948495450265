import {ReactNode} from 'react';

import {Scrollbars} from 'react-custom-scrollbars-2';

interface ScrollContainerProps {
  /**
   *  The content to render within the scrollable container.
   */
  children: ReactNode;
  /**
   * A unique identifier for the scroll container element.
   */
  id?: string;
}

/**
 *  A container with custom scrollbars for scrollable content.
 */
const ScrollContainer = ({children, id = undefined}: ScrollContainerProps) => {
  return (
    <Scrollbars
      id={id}
      className="flex-1 flex"
      autoHide
      autoHideTimeout={1000}
      autoHideDuration={200}
      autoHeight
      autoHeightMax="100%"
      // onTouchMove={handleScroll}
      renderTrackVertical={(props) => (
        <div {...props} className="right-0 h-full !w-2" />
      )}
      renderThumbVertical={(props) => (
        <div {...props} className="bg-brand-900 bg-opacity-20 rounded-full" />
      )}
      renderTrackHorizontal={(props) => (
        <div {...props} className="bottom-0 w-full !h-2" />
      )}
      renderThumbHorizontal={(props) => (
        <div {...props} className="bg-brand-900 bg-opacity-20 rounded-full" />
      )}
      renderView={(props) => <div {...props} className="flex-1" />}>
      {children}
    </Scrollbars>
  );
};

export default ScrollContainer;
