const tailwindColors = require('tailwindcss/colors');

const colors = {
  // Brand pallete generated at: https://www.tints.dev/keyhook/3366FF
  brand: {
    0: '#FFFFFF',
    25: '#fafbff',
    50: '#f5f7ff',
    75: '#EBF0FF',
    100: '#D6E0FF',
    200: '#ADC2FF',
    300: '#85A3FF',
    400: '#5C85FF',
    500: '#3366FF',
    600: '#003DF5',
    700: '#002EB8',
    800: '#001F7A',
    900: '#000F3D',
    950: '#000D33',
    975: '#1a1a1a', // Text from logo
  },
};

const daisyUITheme = {
  // Primary color
  primary: colors.brand[500],
  'primary-focus': colors.brand[600],
  'primary-content': colors.brand[50],

  // Secondary color
  secondary: colors.brand[300],
  'secondary-focus': colors.brand[200],
  'secondary-content': colors.brand[800],

  // TODO: Accent
  // accent: '#37cdbe',

  neutral: colors.brand[900],
  'neutral-focus': colors.brand[950],
  'neutral-content': colors.brand[50],

  'base-100': tailwindColors.slate[100],
  'base-200': tailwindColors.slate[200],
  'base-300': tailwindColors.slate[300],
  'base-content': tailwindColors.slate[900],

  // Info
  info: colors.brand[100],
  'info-content': colors.brand[700],

  // Success
  success: tailwindColors.green[400],
  'success-content': tailwindColors.green[900],

  // Warning
  warning: tailwindColors.amber[400],
  'warning-content': tailwindColors.amber[800],

  // Error
  error: tailwindColors.red[400],
  'error-content': tailwindColors.red[800],

  // Border radius rounded-box utility class, used in card and other large boxes
  '--rounded-box': '1rem',

  // Border radius rounded-btn utility class, used in buttons and similar element
  '--rounded-btn': '0.5rem',

  // Border radius rounded-badge utility class, used in badges and similar
  '--rounded-badge': '1.9rem',

  // Duration of animation when you click on button
  '--animation-btn': '0.25s',

  // Duration of animation for inputs like checkbox, toggle, radio, etc
  '--animation-input': '0.2s',

  // Set default text transform for buttons
  '--btn-text-case': 'uppercase',

  // Scale transform of button when you focus on it
  '--btn-focus-scale': '0.95',

  // Border width of buttons
  '--border-btn': '1px',

  // Border width of tabs
  '--tab-border': '1px',

  // Border radius of tabs
  '--tab-radius': '0.5rem',
};

module.exports = {
  colors,
  daisyUITheme,
};
