import {Form, Formik, type FormikHelpers} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import {InputField, SubmitButton} from 'components/forms_fields';
import PageWrapper from 'components/PageWrapper';
import TenancyRequest from 'models/properties/TenancyRequest';
import {errorViewForError} from 'utilities/ErrorHelpers';

const EditTenancyRequestPage = () => {
  const {propertyId, id} = useParams();
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const {data, error, isLoading} = useQuery(
    `landlord-tenancy-request-${id}`,
    async () => {
      const tr = await TenancyRequest.find(id);

      return tr.data;
    },
  );

  const handleSubmit = async (formValues: any, actions: FormikHelpers<any>) => {
    const req = data;

    req.renterEmail = formValues.renterEmail;

    const result = await req.save();
    if (result) {
      toast.success('Tenant email address successfully updated!');
      queryClient.invalidateQueries(`landlord-tenancy-request-${id}`);

      navigate(`/properties/${propertyId}`, {replace: true});
    }

    actions.setSubmitting(false);
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Edit Tenancy Request">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Edit Tenancy Request">
        <Card className="mt-4">
          <CardBody>
            <CardTitle>Edit Tenancy Request</CardTitle>
            <p>
              Made a mistake with your tenants email address? Use the field
              below to change the email.
            </p>

            <Formik
              initialValues={{
                renterEmail: data.renterEmail,
              }}
              onSubmit={handleSubmit}
              validationSchema={Yup.object().shape({
                renterEmail: Yup.string()
                  .email()
                  .required('Required')
                  .label('Tenant Email'),
              })}>
              {(formik) => (
                <Form>
                  <InputField
                    formik={formik}
                    name="renterEmail"
                    label="Tenant Email Address"
                    placeholder="johnsmith@gmail.com"
                  />

                  <SubmitButton
                    text="Update Email"
                    submittingText="Updating"
                    formik={formik}
                  />
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </PageWrapper>
    );
  }
};

export default EditTenancyRequestPage;
