import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import User from 'models/users/User';

@Model()
class RenterProfile extends ApplicationRecord {
  static jsonapiType = 'renter_profiles';

  @Attr() bio: string;
  @Attr() birthDate: string;
  @Attr() gender: string;
  @Attr() jobTitle: string;
  @Attr() pets: string;
  @Attr() phoneNumber: string;

  @Attr() bankAccountName: string;
  @Attr() bankAccountNumber: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @Attr() userId: string;

  @BelongsTo('users') user: User;
}

export default RenterProfile;
