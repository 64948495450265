import {useCallback, useImperativeHandle} from 'react';

import {PushNotifications} from '@capacitor/push-notifications';

import pushNotificationsImage from 'assets/img/push-notifications.png';
import {ModalDefinition} from 'components_sb/layout';
import Paragraph from 'components_sb/typography/Paragraph/Paragraph';

const PushPermissionsModal: ModalDefinition = {
  title: 'Push Notifications',
  buttonsConfig: {
    cancel: {
      label: 'Got it',
    },
  },

  ContentComponent: (props, ref) => {
    useImperativeHandle(ref, () => ({
      onCancel: PushNotifications.requestPermissions,
    }));

    return (
      <>
        <img
          src={pushNotificationsImage}
          className="w-full max-h-[200px] object-contain"
        />

        <Paragraph>
          We're about to ask for push notification permissions, we highly
          recommend that you enable them so that you don't miss anything
          important. If you have already enabled them, this prompt won't be
          reshown.
        </Paragraph>

        <Paragraph>
          We promise to only send important notifications, you can also
          customize what you'd like to receive in the app settings.
        </Paragraph>
      </>
    );
  },
};

export default PushPermissionsModal;
