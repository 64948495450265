import {HTMLProps} from 'react';

import {type FormikProps} from 'formik';

import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import {InlineError} from 'components_sb/feedback';
import TrackingService from 'services/TrackingService';

interface PseudoBooleanFieldProps extends HTMLProps<HTMLSelectElement> {
  formik: FormikProps<any>;
  helpText?: string;
}

const PseudoBooleanField = ({
  formik,
  label,
  name,
  helpText,
  ...rest
}: PseudoBooleanFieldProps) => {
  const classN = formik.errors[name] ? ' border border-red-500' : '';
  if (rest.className) {
    rest.className += classN;
  } else {
    rest.className = classN;
  }
  rest.className = 'select select-bordered w-auto' + rest.className;
  return (
    <div>
      <div className="flex justify-between items-center">
        <label className="label flex justify-start items-center">
          <span className="label-text mr-2">{label}</span>
          <HelpTextPresenter helpText={helpText} />
        </label>

        <select
          name={name}
          onChange={(e) => {
            formik.setFieldValue(name, e.target.value);
            TrackingService.trackEvent(TrackingService.Event.SelectOption, {
              field: name,
            });
          }}
          value={formik.values[name]}
          {...rest}>
          <option value="Unspecified">Unspecified</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>
      <InlineError error={formik.errors[name]} />
    </div>
  );
};

export default PseudoBooleanField;
