import {useEffect, useRef, useState} from 'react';

import {Capacitor} from '@capacitor/core';
import TextareaAutosize from 'react-textarea-autosize';
import {toast} from 'react-toastify';

import ChatMessage from 'models/ChatMessage';
import type {ChatChannel} from 'services/PusherService';
import {useChatChannelsStore} from 'services/PusherService/_ChatChannel';
import TrackingService from 'services/TrackingService';
import {convertBase64} from 'utilities/FileUtils';

const ChatInputBox = ({channel}: {channel: ChatChannel}) => {
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const textarea = useRef<HTMLTextAreaElement>(null);

  const {setMessages} = useChatChannelsStore();

  const updatePadding = () => {
    if (textarea && Capacitor.isNativePlatform()) {
      const paddingEl = document.getElementById('chat-input-padder');
      paddingEl.style.height = `${textarea.current.clientHeight}px`;
    }
  };

  useEffect(() => {
    updatePadding();
  }, [textarea]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (message.length > 0) {
      const chatMessage = new ChatMessage({
        content: message,
        chatableId: channel.model.id,
        chatableType: channel.identifier.replace('Channel', ''),
      });
      const result = await chatMessage.save();

      if (result) {
        TrackingService.trackEvent(TrackingService.Event.SendTextChatMessage);
      } else {
        toast.error('There was an error sending your message');
      }
    }

    setMessage('');
    setIsSubmitting(false);
  };

  const handleImageRead = async (event: any) => {
    setIsSubmitting(true);
    const file = event.target.files[0];
    const base64 = await convertBase64(file);

    const message = new ChatMessage({
      media: base64,
      chatableId: channel.model.id,
      chatableType: channel.identifier.replace('Channel', ''),
    });
    const result = await message.save();
    setIsSubmitting(false);

    if (result) {
      TrackingService.trackEvent(TrackingService.Event.SendImageChatMessage);
    } else {
      toast.error('There was an error sending your message');
    }
  };

  let containerCss = 'border-t-2 border-gray-200 px-2 pt-2 pb-4 bg-white ';
  if (Capacitor.isNativePlatform()) {
    containerCss += ' fixed bottom-0 left-0 right-0 z-50';
  }

  return (
    <div className={containerCss}>
      <form className="relative flex shrink" onSubmit={handleSubmit}>
        <TextareaAutosize
          placeholder="Your message here..."
          className="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-300 pl-6 bg-gray-100 rounded-xl border-gray-200 pr-20"
          value={message}
          minRows={2}
          maxRows={10}
          ref={textarea}
          onChange={({target}) => {
            setMessage(target.value);
          }}
          onHeightChange={updatePadding}
        />
        <input
          className="hidden"
          type="file"
          id="chat-file-upload"
          accept=".png,.jpeg,.jpg"
          onChange={handleImageRead}
          disabled={isSubmitting}
        />
        <div className="absolute right-0 items-center inset-y-0 flex">
          <label
            htmlFor="chat-file-upload"
            className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6 text-gray-600">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"></path>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"></path>
            </svg>
          </label>
          <button
            type="submit"
            disabled={isSubmitting}
            className="inline-flex items-center justify-center rounded-full h-10 w-10 mr-2 transition duration-500 ease-in-out text-white bg-primary hover:bg-primary-focus focus:outline-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="h-5 w-5 transform rotate-90">
              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
            </svg>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChatInputBox;
