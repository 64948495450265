import {themeColors} from 'helpers/utils';

export type NextStep = () => void;

const RightFooter = ({
  step,
  nextStep,
  previousStep,
  nextStepEnabled = false,
  previousStepEnabled = true,
  totalSteps = 11,
}: {
  totalSteps?: number;
  step: number;
  nextStep: NextStep;
  previousStep: NextStep;
  nextStepEnabled?: boolean;
  previousStepEnabled?: boolean;
}) => {
  return (
    <div className="absolute bottom-4 md:bottom-0 w-full lg:w-2/3 right-0 bg-white">
      <progress
        className="progress progress-success w-full rounded-none h-[4px]"
        value={step}
        max={totalSteps}></progress>

      <div className="flex justify-between items-center mb-2 mx-2">
        <button
          className="btn btn-neutral btn-ghost btn-sm"
          disabled={!previousStepEnabled}
          onClick={previousStep}
          type="button">
          Back
        </button>
        <button
          className="btn btn-neutral btn-sm"
          disabled={!nextStepEnabled}
          onClick={nextStep}
          type="button">
          Next
        </button>
      </div>
    </div>
  );
};

export default RightFooter;
