/* eslint-disable react-hooks/rules-of-hooks */
import React, {useEffect, useMemo} from 'react';

import {useQuery} from 'react-query';
import {Navigate, useNavigate} from 'react-router';
import {toast} from 'react-toastify';

import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import {API_URL} from 'globals/app-globals';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import useAuth from 'services/useAuth';

const GhostPage = () => {
  const params = new URLSearchParams(window.location.search);
  const id = params.get('id');
  const token = params.get('token');
  const email = params.get('email')?.replaceAll(' ', '+');
  const redirect = params.get('redirect');

  const paramsSupplied = useMemo(
    () => id && token && email,
    [id, token, email],
  );

  const {ghostUser} = useAuth();

  const {data} = useQuery(`ghost-${id}`, async () => {
    if (paramsSupplied) {
      const response = await fetch(`${API_URL}/users/${id}.json`, {
        method: 'GET',
        headers: {
          'X-USER-EMAIL': email,
          'X-USER-TOKEN': token,
        },
      });
      const body = await response.json();
      return body;
    }
  });

  useEffect(() => {
    if (data && data.data) {
      ghostUser({
        data: data.data,
        token,
        redirect,
      });
    }
  }, [data, token, redirect, ghostUser]);

  if (!paramsSupplied) {
    return <Navigate to="/" />;
  }

  if (data && data.error) {
    return (
      <PageWrapper title="Ghosting">
        <div>
          <p>Error ghosting user:</p>
          <p>{data.error}</p>
        </div>
      </PageWrapper>
    );
  } else {
    return (
      <div>
        <LoadingView />
        <p>Wait a second...</p>
      </div>
    );
  }
};

export default GhostPage;
