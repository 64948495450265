import React, {Fragment} from 'react';
import {FC} from 'react';

import {Popover, Transition} from '@headlessui/react';
import {BiBuildingHouse} from '@react-icons/all-files/bi/BiBuildingHouse';

import Checkbox from 'components/chifis_theme/Checkbox';

const propertyTypes = [
  {
    name: 'Apartment',
    checked: false,
  },
  {
    name: 'House',
    checked: false,
  },
  {
    name: 'Townhouse',
    checked: false,
  },
  {
    name: 'Unit',
    checked: false,
  },
];

export interface PropertyTypeSelectProps {
  onChange?: (data: any) => void;
  fieldClassName?: string;
}

const PropertyTypeSelect: FC<PropertyTypeSelectProps> = ({
  onChange,
  fieldClassName = '[ nc-hero-field-padding ]',
}) => {
  const inputChanged = () => {
    const checkedNames = propertyTypes
      .filter((type) => type.checked)
      .map((type) => type.name);
    onChange(checkedNames);
  };

  return (
    <Popover className="flex relative [ nc-flex-1 ]">
      {({open, close}) => (
        <>
          <Popover.Button
            className={`flex text-left w-full flex-shrink-0 items-center ${fieldClassName} space-x-3 focus:outline-none cursor-pointer ${
              open ? 'shadow-2xl rounded-full dark:bg-neutral-800' : ''
            }`}>
            <div className="text-neutral-300 dark:text-neutral-400">
              <BiBuildingHouse className="w-8 h-8" />
            </div>
            <div className="flex-grow">
              <span className="block xl:text-lg font-semibold">Type</span>
              <span className="block mt-1 text-sm text-neutral-400 leading-none font-light ">
                Property type
              </span>
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1">
            <Popover.Panel className="absolute left-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <div className="">
                <div className="relative flex flex-col space-y-5">
                  {propertyTypes.map((item) => (
                    <div key={item.name} className="">
                      <Checkbox
                        name={item.name}
                        label={item.name}
                        subLabel={''}
                        defaultChecked={item.checked}
                        onChange={(checked: boolean) => {
                          item.checked = checked;
                          inputChanged();
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default PropertyTypeSelect;
