// @ts-nocheck

import {useState} from 'react';

import {PencilIcon, TrashIcon, ViewGridAddIcon} from '@heroicons/react/outline';
import {useQuery, useQueryClient} from 'react-query';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import {Button} from 'components_sb/buttons';
import RoomPlan, {Room, RoomItem} from 'models/properties/RoomPlan';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {errorViewForError} from 'utilities/ErrorHelpers';

const RoomItemCard = ({
  value,
  roomIndex,
  roomPlan,
  setRoomPlan,
}: {
  value: RoomItem;
  roomIndex: number;
  roomPlan: RoomPlan;
  setRoomPlan: (plan: RoomPlan) => void;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editingText, setEditingText] = useState(value.name);

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const confirmRemoveItem = () => {
    setConfirmationOptions({
      title: 'Remove Item',
      message: `Are you sure you want to remove '${value.name}'?`,
      buttonTitle: 'Remove',
      action: removeItem,
      color: 'error',
    });
  };

  const removeItem = () => {
    const room = roomPlan.roomPlan[roomIndex];
    room.items = room.items.filter((item) => item.name !== value.name);
    setRoomPlan(roomPlan);
  };

  const saveItemName = () => {
    if (editingText.length > 0) {
      value.name = editingText;
      setRoomPlan(roomPlan);
      setIsEditing(false);
    }
  };

  return (
    <div className="p-2 mb-2 bg-gray-100 flex justify-between">
      <div>
        {isEditing ? (
          <div className="input-group">
            <input
              type="text"
              placeholder="Room name..."
              className="input input-bordered w-full"
              value={editingText}
              onChange={(e) => {
                setEditingText(e.target.value);
              }}
            />
            <button
              disabled={editingText.length === 0}
              className="btn btn-square px-2"
              onClick={saveItemName}>
              Save
            </button>
          </div>
        ) : (
          value.name
        )}
      </div>
      {!isEditing && (
        <div className="btn-group">
          <button className="btn btn-sm btn-ghost" onClick={confirmRemoveItem}>
            <TrashIcon className="w-5 h-5 pointer-events-none" />
          </button>
          <button
            className="btn btn-sm btn-ghost"
            onClick={() => setIsEditing(true)}>
            <PencilIcon className="w-5 h-5 pointer-events-none" />
          </button>
        </div>
      )}
    </div>
  );
};

const RoomCard = ({
  value,
  roomIndex,
  roomPlan,
  setRoomPlan,
}: {
  value: Room;
  roomIndex: number;
  roomPlan: RoomPlan;
  setRoomPlan: (plan: RoomPlan) => void;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editingText, setEditingText] = useState(value.name);
  const [isAddingItem, setIsAddingItem] = useState(false);
  const [addingText, setAddingText] = useState('');

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const saveRoomName = () => {
    if (editingText.length > 0) {
      value.name = editingText;
      setRoomPlan(roomPlan);
      setIsEditing(false);
      setEditingText('');
    }
  };

  const addItem = () => {
    if (addingText.length > 0) {
      value.items.push({name: addingText});
      setRoomPlan(roomPlan);
      setIsAddingItem(false);
      setAddingText('');
    }
  };

  const confirmRemoveRoom = () => {
    setConfirmationOptions({
      title: 'Remove Room',
      message: `Are you sure you want to remove '${value.name}'?`,
      buttonTitle: 'Remove',
      action: removeRoom,
      color: 'error',
    });
  };

  const removeRoom = () => {
    const newRooms = roomPlan.roomPlan.filter((r) => r.name !== value.name);
    roomPlan.roomPlan = newRooms;
    setRoomPlan(roomPlan);
  };

  return (
    <Card className="mb-4">
      <CardBody>
        <CardTitle className="flex justify-between">
          <div>
            {isEditing ? (
              <div>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Room name..."
                    className="input input-bordered w-full"
                    value={editingText}
                    onChange={(e) => {
                      setEditingText(e.target.value);
                    }}
                  />
                  <button
                    disabled={editingText.length === 0}
                    className="btn btn-square px-2"
                    onClick={saveRoomName}>
                    Save
                  </button>
                </div>
              </div>
            ) : (
              value.name
            )}
          </div>
          {!isEditing && (
            <div className="btn-group">
              <button
                className="btn btn-sm btn-ghost"
                onClick={confirmRemoveRoom}>
                <TrashIcon className="w-5 h-5 pointer-events-none" />
              </button>
              <button
                className="btn btn-sm btn-ghost"
                onClick={() => {
                  setIsEditing(true);
                }}>
                <PencilIcon className="w-5 h-5 pointer-events-none" />
              </button>
              <button
                className="btn btn-sm btn-ghost"
                onClick={() => setIsAddingItem(true)}>
                <ViewGridAddIcon className="w-5 h-5 pointer-events-none" />
              </button>
            </div>
          )}
        </CardTitle>

        <div>
          {value.items.map((value, index) => (
            <RoomItemCard
              key={`item-${value.name}`}
              index={index}
              roomIndex={roomIndex}
              value={value}
              roomPlan={roomPlan}
              setRoomPlan={setRoomPlan}
            />
          ))}
        </div>
        {isAddingItem && (
          <div className="mt-2">
            <div className="form-control">
              <label className="label-text">Name</label>
              <div className="input-group">
                <input
                  type="text"
                  placeholder="Item name..."
                  className="input input-bordered w-1/2"
                  value={addingText}
                  onChange={(e) => {
                    setAddingText(e.target.value);
                  }}
                />
                <button
                  disabled={addingText.length === 0}
                  className="btn btn-square px-2"
                  onClick={addItem}>
                  Add
                </button>
              </div>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

const EditRoomPlanPage = () => {
  const {propertyId} = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [hasMadeChanges, setHasMadeChanges] = useState(false);
  const [addingRoomText, setAddingRoomText] = useState('');

  const cacheKey = `property-${propertyId}-room-plan`;

  const {data, isLoading, error} = useQuery(cacheKey, async () => {
    const rp = await RoomPlan.where({propertyId}).first();

    return rp.data;
  });
  const queryClient = useQueryClient();

  const saveRoomPlan = async () => {
    setIsSaving(true);
    const result = await data.save();
    if (result) {
      toast.success('Changes successfully saved!');
    }
    setIsSaving(false);
    setHasMadeChanges(false);
  };

  const addRoom = () => {
    if (addingRoomText.length > 0) {
      const d = data;
      d.roomPlan.push({
        name: addingRoomText,
        items: [],
      });
      setData(d);
      setAddingRoomText('');
    }
  };

  const setData = (d: RoomPlan) => {
    queryClient.setQueryData(cacheKey, d);
    setHasMadeChanges(true);
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Inspection Template">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Inspection Template">
        <Card className="mt-4">
          <CardBody>
            <CardTitle>Inspection Template</CardTitle>

            <p>
              The cards below represent the areas for your property. These areas
              are used as a template to generate your inspections, so we highly
              recommend customising this to make inspections easier.
            </p>

            <div className="mt-2">
              <CardTitle>Add an area</CardTitle>
              <p className="text-secondary text-sm">
                Use the field below to add any areas or sections that might be
                missing from your property.
              </p>

              <div className="mt-2">
                <div className="form-control">
                  <label className="label-text">Name</label>
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Area name..."
                      className="input input-bordered w-1/2"
                      value={addingRoomText}
                      onChange={(e) => {
                        setAddingRoomText(e.target.value);
                      }}
                    />
                    <button
                      disabled={addingRoomText.length === 0}
                      className="btn btn-square px-2"
                      onClick={addRoom}>
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>

        <div className="mt-4">
          {data &&
            data.roomPlan &&
            data.roomPlan.map((value, index) => (
              <RoomCard
                key={`room-${value.name}`}
                roomIndex={index}
                value={value}
                roomPlan={data}
                setRoomPlan={setData}
              />
            ))}
        </div>

        <Button
          mode="manual"
          label="Save"
          loadingLabel="Saving"
          loading={isSaving}
          onClick={saveRoomPlan}
          disabled={!hasMadeChanges}
          size="lg"
          category="primary"
          customClasses="mt-4"
        />
      </PageWrapper>
    );
  }
};

export default EditRoomPlanPage;
