import {useEffect, useState} from 'react';

import {type FormikHelpers, Formik, Form, type FormikProps} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import {YesNoField} from 'components/forms_fields';
import InsurancePolicyField from 'components/forms_fields/InsurancePolicyField';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightFooter from 'components/walkthrough/common/RightFooter';
import RightSideText from 'components/walkthrough/common/RightSideText';
import {InsurancePolicy} from 'models/properties/InsurancePolicy';
import Property from 'models/properties/Property';
import TrackingService from 'services/TrackingService';
import {isDefined} from 'utilities/MiscHelpers';

const InsuranceStep = ({
  property,
  stepNumber,
  totalSteps,
}: {
  property: Property;
  stepNumber: number;
  totalSteps: number;
}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_StartInsuranceStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = async (formValues: any, actions: FormikHelpers<any>) => {
    setIsLoading(true);

    property.assignAttributes(formValues);
    property.lastOnboardingStepCompleted = 'new_insurance';
    property.markStepAsCompleted('insurance_policies');

    await property.save();

    /**
     * Track completion of the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_CompleteInsuranceStep,
      {
        propertyId: property.id,
      },
    );

    queryClient.setQueryData(['new-property', property.id], property);
    setIsLoading(false);
  };

  const previousStep = async () => {
    property.lastOnboardingStepCompleted = 'new_personal_profile';
    await property.save();

    queryClient.setQueryData(['new-property', property.id], property);
  };

  const handleAddPolicy = (formik: FormikProps<any>) => {
    let policies = formik.values.insurancePolicies;
    if (!policies) {
      policies = [] as InsurancePolicy[];
    }
    policies.push({
      policy_name: '',
      insurer: '',
      excess_amount: 0,
      yearly_cost: '',
    });
    formik.setFieldValue('insurancePolicies', policies);
  };

  const title = 'Add any insurance policies for your property';
  const subtitle =
    'NZ law requires the insurance information for a property to be included with any leases';

  const policies = property.insurancePolicies || ([] as InsurancePolicy[]);

  return (
    <Container>
      <LeftSide title={title} subtitle={subtitle} />
      <RightContainer>
        <RightSideText title={title} subtitle={subtitle} />
        <Formik
          initialValues={{
            insuranceCoversTenants: isDefined(property.insuranceCoversTenants)
              ? property.insuranceCoversTenants
              : false,
            insurancePolicies: policies,
          }}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            insuranceCoversTenants: Yup.boolean().label(
              'Insurance Covers Tenants',
            ),
            insurancePolicies: Yup.array()
              .of(
                Yup.object().shape({
                  policy_name: Yup.string()
                    .required()
                    .min(3)
                    .max(40)
                    .label('Policy Name'),
                  insurer: Yup.string()
                    .required()
                    .min(2)
                    .max(40)
                    .label('Insurer'),
                  excess_amount: Yup.number()
                    .min(1)
                    .required()
                    .label('Excess Amount'),
                  yearly_cost: Yup.number()
                    .optional()
                    .nullable()
                    .label('Yearly Cost')
                    .min(0),
                }),
              )
              .min(0)
              .label('Insurance Policies')
              .optional(),
          })}>
          {(formik) => (
            <Form>
              <div className="flex flex-col md:mx-16 space-y-8">
                {formik.values.insurancePolicies.map(
                  (policy: any, index: number) => (
                    <InsurancePolicyField
                      formik={formik}
                      index={index}
                      key={index}
                    />
                  ),
                )}

                {formik.errors.insurancePolicies &&
                  typeof formik.errors.insurancePolicies === 'string' && (
                    <span className="block text-red-500">
                      {formik.errors.insurancePolicies}
                    </span>
                  )}

                <div className="flex justify-between items-center mt-2">
                  <button
                    className="btn btn-block btn-info"
                    type="button"
                    onClick={() => handleAddPolicy(formik)}>
                    {formik.values.insurancePolicies.length > 0
                      ? 'Add Another Policy'
                      : 'Add A Policy'}
                  </button>
                </div>

                <YesNoField
                  name="insuranceCoversTenants"
                  formik={formik}
                  label="Insurance Covers Tenants?"
                  helpText="There is insurance covering this rental property that is relevant
            to tenant's liability for damage to premises, including damage to
            body corporate facilities."
                  size="small"
                />
              </div>
              <ProgressBox
                nextStep={formik.submitForm}
                previousStep={previousStep}
                step={stepNumber}
                totalSteps={totalSteps}
                previousStepEnabled
                nextStepEnabled={!isLoading}
              />
            </Form>
          )}
        </Formik>
      </RightContainer>
    </Container>
  );
};

export default InsuranceStep;
