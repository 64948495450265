import {Model, Attr, BelongsTo, HasMany} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Listing from 'models/listings/Listing';
import ListingRentalApplication from 'models/listings/ListingRentalApplication';
import RentalApplicationApplicant from 'models/listings/RentalApplicationApplicant';
import User from 'models/users/User';

type PseudoBoolean = 'Unspecified' | 'Yes' | 'No' | undefined;

@Model()
class RentalApplication extends ApplicationRecord {
  static jsonapiType = 'rental_applications';

  @Attr() aboutUs: string;

  @Attr({persist: false}) headTenantName: string;
  @Attr() headTenantDob: string;
  @Attr() headTenantPhoneNumber: string;
  @Attr({persist: false}) headTenantEmail: string;

  @Attr() cantCompleteTenancyReasons: string;

  @Attr() preferredTenancyLength: number;
  @Attr() preferredTenancyStartDate: string;

  @Attr() idType: string;
  @Attr() idNumber: string;

  @Attr() headTenantEmployedFullTime: PseudoBoolean;
  @Attr() occupation: string;

  @Attr() weeklyIncome: number;
  @Attr() employer: string;
  @Attr() yearsEmployedAtCurrentPlace: string;

  @Attr() employerReferenceName: string;
  @Attr() employerReferenceCompanyName: string;
  @Attr() employerReferenceRelationship: string;
  @Attr() employerReferenceContactNumber: string;

  @Attr() landlordReferenceName: string;
  @Attr() landlordReferenceRelationship: string;
  @Attr() landlordReferenceContactNumber: string;

  @Attr() otherReferenceName: string;
  @Attr() otherReferenceRelationship: string;
  @Attr() otherReferenceContactNumber: string;

  @Attr() currentAddress: string;
  @Attr() currentAddressReasonLeaving: string;
  @Attr() currentAddressStayLength: string;

  @Attr() additionalTotalOccupants: number;
  @Attr({persist: false}) rentalApplicationApplicantsCount: number;

  @Attr() applicantsCanProvideReferences: PseudoBoolean;

  @Attr() vehiclesCount: number;
  @Attr() hasPets: PseudoBoolean;
  @Attr() petTypes: Record<string, number>;
  @Attr() anySmokers: PseudoBoolean;

  @Attr() involvedWithTenancyTribunal: PseudoBoolean;
  @Attr() tenancyTribunalInformation: string | null;

  @Attr() pastBondDeductions: PseudoBoolean;
  @Attr() oweMoneyToLandlord: PseudoBoolean;
  @Attr() hasMissedRentPayments: PseudoBoolean;
  @Attr() canPayBondAndAdvance: PseudoBoolean;
  @Attr() wouldLikeHelpWithUtilities: PseudoBoolean;
  @Attr() anyBadCredit: PseudoBoolean;
  @Attr() anyCriminalRecords: PseudoBoolean;

  @Attr() headTenantSignature: string;
  @Attr() headTenantSignedAt: string;

  @Attr({persist: false}) userId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('users') user: User;
  @HasMany('rental_application_applicants')
  rentalApplicationApplicants: RentalApplicationApplicant[];
  @HasMany('listing_rental_applications')
  listingRentalApplications: ListingRentalApplication[];
  @HasMany('listings') listings: Listing[];
}

export default RentalApplication;
