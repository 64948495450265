import {useEffect, useState} from 'react';

import {type FormikHelpers, Formik, Form, type FormikProps} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import * as Yup from 'yup';

import {AutocompleteField, InputField} from 'components/forms_fields';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightFooter from 'components/walkthrough/common/RightFooter';
import RightSideText from 'components/walkthrough/common/RightSideText';
import {API_URL} from 'globals/app-globals';
import Property from 'models/properties/Property';
import User from 'models/users/User';
import TrackingService from 'services/TrackingService';
import useAuth from 'services/useAuth';

type FormValues = {
  address: string;
  addressMeta: object;
  phoneNumber: string;
  externalLandlordId?: string | null;
};

const PersonalProfileStep = ({
  property,
  stepNumber,
  totalSteps,
}: {
  property: Property;
  stepNumber: number;
  totalSteps: number;
}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_StartLandlordDetailsStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const [isLoading, setIsLoading] = useState(false);

  const {currentUser} = useAuth();

  const {data: model} = useQuery('landlord-profile-checklist', async () => {
    return (await User.includes(['landlord_profile']).find(currentUser.id))
      .data;
  });

  const queryAddresses = async (str: string) => {
    if (str.length <= 2) {
      return [];
    }

    const url = `${API_URL}/addresses.json?query=${str}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-USER-TOKEN': currentUser.meta.authenticationToken,
          'X-USER-EMAIL': currentUser.email,
        },
      });
      const data = await response.json();
      const places = data.addresses;
      return places;
    } catch (e) {
      return [];
    }
  };

  const selectAddress = async (
    option: any,
    formik: FormikProps<FormValues>,
  ): Promise<any[]> => {
    if (option) {
      const url = `${API_URL}/addresses/${option['id']}.json`;

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-USER-TOKEN': currentUser.meta.authenticationToken,
            'X-USER-EMAIL': currentUser.email,
          },
        });
        const data = await response.json();

        const ad = data.address;

        formik.setFieldValue('addressMeta', ad);
        formik.setFieldValue('address', ad.full);
      } catch (e) {
        return [];
      }
    }
  };

  const queryClient = useQueryClient();

  const handleSubmit = async (
    formValues: FormValues,
    _actions: FormikHelpers<FormValues>,
  ) => {
    setIsLoading(true);

    const profile = model.landlordProfile;
    profile.assignAttributes(formValues);
    const result = await profile.save();
    if (result) {
      property.lastOnboardingStepCompleted = 'new_personal_profile';
      property.markStepAsCompleted('personal_profile');

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.NewTenancy_CompleteLandlordDetailsStep,
        {
          propertyId: property.id,
        },
      );

      await property.save();
      queryClient.setQueryData(['new-property', property.id], property);
    }

    setIsLoading(false);
  };

  const previousStep = async () => {
    property.lastOnboardingStepCompleted = 'new_availability';
    await property.save();

    queryClient.setQueryData(['new-property', property.id], property);
  };

  const title = 'Add your personal details';
  const subtitle = 'These will be used for lease and bond documents';

  return (
    <Container>
      <LeftSide title={title} subtitle={subtitle} />
      <RightContainer>
        <RightSideText title={title} />
        {model && (
          <Formik
            initialValues={
              {
                address: model.landlordProfile.address || '',
                addressMeta: model.landlordProfile.addressMeta || null,
                phoneNumber: model.landlordProfile.phoneNumber || '',
                externalLandlordId:
                  model.landlordProfile.externalLandlordId || '',
              } as FormValues
            }
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              address: Yup.string().required().label('Address'),
              phoneNumber: Yup.string().min(7).required().label('Phone Number'),
              externalLandlordId: Yup.string()
                .optional()
                .label('Tenancy Services Landlord Id')
                .min(5)
                .max(7),
            })}>
            {(formik) => (
              <Form>
                <div className="flex flex-col md:mx-16 space-y-8">
                  <div>
                    <AutocompleteField
                      label="Landlord Postal Address"
                      name="address"
                      formik={formik}
                      placeholder="123 Example Street, Suburb, City"
                      filter={queryAddresses}
                      select={(option: any) => selectAddress(option, formik)}
                      value={formik.values.address}
                    />
                  </div>
                  <InputField
                    label="Phone Number"
                    name="phoneNumber"
                    formik={formik}
                    placeholder="eg: 027 123 4567"
                    autoCapitalize="none"
                  />
                  <InputField
                    label="Tenancy Services Landlord Id"
                    name="externalLandlordId"
                    formik={formik}
                    placeholder="If you don't know, leave blank"
                    autoCapitalize="none"
                  />
                </div>
                <ProgressBox
                  nextStep={formik.submitForm}
                  previousStep={previousStep}
                  step={stepNumber}
                  totalSteps={totalSteps}
                  previousStepEnabled
                  nextStepEnabled={!isLoading}
                />
              </Form>
            )}
          </Formik>
        )}
      </RightContainer>
    </Container>
  );
};

export default PersonalProfileStep;
