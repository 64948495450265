import React, {useState} from 'react';

import {Capacitor} from '@capacitor/core';
import {LightBulbIcon} from '@heroicons/react/outline';
import {Form, Formik, type FormikHelpers} from 'formik';
import {useQueryClient} from 'react-query';
import {useLocation, useNavigate} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {InputField, SubmitButton} from 'components/forms_fields';
import MoneyField from 'components/forms_fields/MoneyField';
import ActionCard from 'components/property/landlord/profile_blocks/ActionCard';
import ProfileItemModal from 'components/property/landlord/profile_blocks/ProfileItemModal';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';

const MaintenanceSettingsAction = ({
  property,
  tenancy,
  storeKey,
}: {
  property: Property;
  tenancy: Tenancy;
  storeKey: string;
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (formValues: any, actions: FormikHelpers<any>) => {
    const prop = property;

    prop.assignAttributes(formValues);
    prop.markStepAsCompleted(storeKey);

    const result = await prop.save();

    if (result) {
      queryClient.setQueryData(`landlord-property-detail-${prop.id}`, prop);
      toast.success('Property successfully updated!');
      setModalVisible(false);
    } else {
      for (const key of Object.keys(prop.errors)) {
        const message = prop.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  const showAddPreferredTradesperson = () => {
    if (Capacitor.isNativePlatform()) {
      let path = location.pathname;
      if (!path.endsWith('/')) {
        path += '/';
      }
      path += 'preferred-tradespeople';
      navigate(path);
    } else {
      window.open(`${window.location.href}/preferred-tradespeople`, '_blank');
    }
  };

  const title = 'Maintenance Settings';
  const subtitle = 'Configure how maintenace is conducted within Keyhook.';
  const iconBgColor = 'bg-yellow-300';

  return (
    <div>
      <ActionCard
        title={title}
        subtitle={subtitle}
        IconClass={LightBulbIcon}
        iconBgColor={iconBgColor}
        onClick={() => setModalVisible(true)}
      />
      <ProfileItemModal
        modalIsOpen={modalVisible}
        setModalIsOpen={setModalVisible}
        title={title}
        subtitle={subtitle}
        IconClass={LightBulbIcon}
        bgColor={iconBgColor}
        form={
          <Formik
            initialValues={{
              authorityToFixLimit: null,
            }}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              authorityToFixLimit: Yup.number()
                .nullable()
                .required()
                .min(0)
                .max(100000)
                .label('Authority to fix limit'),
            })}>
            {(formik) => (
              <Form className="md:h-full flex-1">
                <div className="flex flex-col justify-between h-full">
                  <div className="flex flex-col justify-center grow space-y-8">
                    <MoneyField
                      label="Authority to fix limit"
                      formik={formik}
                      name="authorityToFixLimit"
                      min={0}
                      max={100000}
                      placeholder="Eg: 500"
                      helpText="The Keyhook maintenance team wont ask for your approval to complete repairs costing under this amount."
                    />

                    <div>
                      <p className="text-sm">Who does your work?</p>
                      <a
                        onClick={showAddPreferredTradesperson}
                        className="btn btn-primary btn-block mt-2 text-white">
                        Add preferred tradesperson
                      </a>
                    </div>
                  </div>

                  <SubmitButton
                    formik={formik}
                    text="Save"
                    submittingText="Saving"
                    className={`!rounded-full ${iconBgColor} border-none mt-6`}
                  />
                </div>
              </Form>
            )}
          </Formik>
        }
      />
    </div>
  );
};

export default MaintenanceSettingsAction;
