import {FaClipboardList, FaDollarSign, FaFile, FaHammer} from 'react-icons/fa';
import {Link} from 'react-router-dom';

import TenancyMembership from 'models/properties/TenancyMembership';
import {toCurrency} from 'utilities/StringHelpers';

const CurrentTenancyCard = ({membership}: {membership: TenancyMembership}) => {
  const statCards = () => {
    let nextInspectionDate: string;
    if (membership.tenancy.nextInspectionDate) {
      const today = new Date();
      const inspectionDate = new Date(membership.tenancy.nextInspectionDate);

      const timeDifference = inspectionDate.getTime() - today.getTime();
      nextInspectionDate = Number(timeDifference / (1000 * 3600 * 24)).toFixed(
        0,
      );
    }

    let leaseExpires: string;
    if (membership.tenancy.endDate) {
      const today = new Date();
      const leaseDate = new Date(membership.tenancy.endDate);

      const timeDifference = leaseDate.getTime() - today.getTime();
      leaseExpires = Number(timeDifference / (1000 * 3600 * 24)).toFixed(0);
    }

    const serviceCount = membership.tenancy.openServiceRequests.length;

    return (
      <div>
        <div className="w-full bordered shadow-xl stats mmd:grid-flow-row mlg:hidden">
          <div className="stat">
            <div className="stat-title">Rent</div>
            <div className="stat-value text-2xl">
              {toCurrency(membership.rentSplit)}
            </div>
            <div className="stat-desc">{membership.tenancy.rentalPeriod}</div>
          </div>
          <div className="stat">
            <div className="stat-title">Maintenance Requests</div>
            <div className="stat-value text-2xl">{serviceCount}</div>
            <div className="stat-desc">Open Requests</div>
          </div>
          <div className="stat">
            <div className="stat-title">Next Inspection</div>
            <div className="stat-value text-2xl">
              {nextInspectionDate ? nextInspectionDate : 'N/A'}
            </div>
            <div className="stat-desc">
              {nextInspectionDate ? 'Days' : null}
            </div>
          </div>
          <div className="stat">
            <div className="stat-title">Lease Expires</div>
            <div className="stat-value text-2xl">
              {leaseExpires ? leaseExpires : 'Ongoing'}
            </div>
            <div className="stat-desc">{leaseExpires ? 'Days' : null}</div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-y-2 gap-x-2 block lg:hidden">
          <div className="flex justify-start items-center bg-white rounded-full">
            <div className="bg-neutral rounded-full w-8 h-8 flex justify-center items-center">
              <FaDollarSign className="w-4 h-4 text-white" />
            </div>
            <div className="flex flex-col ml-2 pt-1">
              <span className="text-gray-500" style={{fontSize: 10}}>
                Rent
              </span>
              <h3 className="text-md font-semibold">
                {toCurrency(membership.rentSplit)}
              </h3>
            </div>
          </div>

          <div className="flex justify-start items-center bg-white rounded-full">
            <div className="bg-neutral rounded-full w-8 h-8 flex justify-center items-center">
              <FaHammer className="w-4 h-4 text-white" />
            </div>
            <div className="flex flex-col ml-2 pt-1">
              <span className="text-gray-500" style={{fontSize: 10}}>
                Maintenance
              </span>
              <h3 className="text-md font-semibold">{serviceCount} Requests</h3>
            </div>
          </div>

          <div className="flex justify-start items-center bg-white rounded-full">
            <div className="bg-neutral rounded-full w-8 h-8 flex justify-center items-center">
              <FaClipboardList className="w-4 h-4 text-white" />
            </div>
            <div className="flex flex-col ml-2 pt-1">
              <span className="text-gray-500" style={{fontSize: 10}}>
                Next Inspection
              </span>
              <h3 className="text-md font-semibold">
                {nextInspectionDate ? nextInspectionDate + ' Days' : 'N/A'}
              </h3>
            </div>
          </div>

          <div className="flex justify-start items-center bg-white rounded-full">
            <div className="bg-neutral rounded-full w-8 h-8 flex justify-center items-center">
              <FaFile className="w-4 h-4 text-white" />
            </div>
            <div className="flex flex-col ml-2 pt-1">
              <span className="text-gray-500" style={{fontSize: 10}}>
                Lease Expires
              </span>
              <h3 className="text-md font-semibold">
                {leaseExpires ? `${leaseExpires} Days` : 'Ongoing'}
              </h3>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Link to={`/tenancies/${membership.tenancy.id}`}>
      <div className="flex flex-col justify-center mb-10">
        <div className="relative flex flex-col md:flex-row md:space-x-0 space-y-3 md:space-y-0 rounded-xl shadow-lg p-3 w-full mx-auto border border-white bg-white">
          <div className="w-full md:w-1/4 bg-white grid place-items-center">
            <img
              src={membership.tenancy.property.mainImage}
              alt={membership.tenancy.property.streetAddress}
              className="rounded-xl"
            />
          </div>
          <div className="w-full md:w-2/3 bg-white flex flex-col space-y-2 p-3">
            <div className="flex justify-between item-center">
              <p className="text-gray-500 font-medium md:block">
                {membership.tenancy.property.suburb},{' '}
                {membership.tenancy.property.city}
              </p>
            </div>
            <h3 className="font-black text-gray-800 md:text-3xl text-xl">
              {membership.tenancy.property.streetAddress}
            </h3>
            {statCards()}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CurrentTenancyCard;
