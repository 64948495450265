import {Capacitor} from '@capacitor/core';
import {Form, Formik, type FormikHelpers} from 'formik';
import {useQuery} from 'react-query';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import {InputField, SubmitButton} from 'components/forms_fields';
import {HeaderButton} from 'components/navbar/Header';
import PageWrapper from 'components/PageWrapper';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import Tenancy from 'models/properties/Tenancy';
import TenancyInvite from 'models/properties/TenancyInvite';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

type FormValues = {
  email: string;
};

const NewTenancyInvitePage = () => {
  usePageVisit('NewTenancyInvitePage');
  useTitle('Invite Tenant');

  const {propertyId, id} = useParams();

  const {activeAccountRole} = useLocalUserSettings();

  const {data, isLoading, error} = useQuery(
    `tenancy-${id}-invite-tenants`,
    async () => {
      const t = await Tenancy.includes('property')
        .select({
          tenancies: ['id', 'is_new'],
          properties: ['street_address'],
        })
        .find(id);

      return t.data;
    },
  );

  const handleSubmit = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    const invite = new TenancyInvite(formValues);
    invite.tenancyId = id;

    const result = await invite.save();
    if (result) {
      toast.success('Tenancy invitation has been sent successfully!');
      actions.resetForm();
    } else {
      for (const key of Object.keys(invite.errors)) {
        const message = invite.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  const buttons: HeaderButton[] = Capacitor.isNativePlatform()
    ? []
    : [
        {
          text: 'Back To Property',
          href:
            activeAccountRole === 'Landlord'
              ? `/properties/${propertyId}`
              : `/tenancies/${id}`,
          bgColor: 'neutral',
        },
      ];

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Invite Tenant" buttons={buttons}>
        <LoadingView />
      </PageWrapper>
    );
  } else {
    if (data && data.isNew) {
      return (
        <PageWrapper title="Invite Tenant" buttons={buttons}>
          <Card className="mt-4">
            <CardBody>
              <CardTitle>Invite Tenant</CardTitle>
              <p className="mt-2">
                This tenancy was started on Keyhook and therefore you can't
                invite other tenants as they should already be part of the
                Keyhook tenancy.
              </p>
            </CardBody>
          </Card>
        </PageWrapper>
      );
    } else {
      return (
        <PageWrapper title="Invite Tenant" buttons={buttons}>
          <Card className="mt-4">
            <CardBody>
              <CardTitle>Invite Tenant</CardTitle>
              <p className="mt-2">
                Use the form below to invite other named tenants to join the
                tenancy on Keyhook. These should only be tenants who have signed
                the lease.
              </p>
              <p className="mt-2">
                Once accepted, the head tenant will be able to assign them their
                portion of the rent to pay if they wish.
              </p>

              <Formik
                initialValues={{email: ''} as FormValues}
                onSubmit={handleSubmit}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email().required().label('Email Address'),
                })}>
                {(formik) => (
                  <Form>
                    <InputField
                      name="email"
                      label="Email Address"
                      placeholder="Enter the email of the tenant"
                      formik={formik}
                      type="email"
                    />

                    <div className="mt-4">
                      <SubmitButton
                        formik={formik}
                        text="Invite Tenant"
                        submittingText="Saving"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </PageWrapper>
      );
    }
  }
};

export default NewTenancyInvitePage;
