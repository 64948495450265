import {useState} from 'react';

import {Formik, type FormikProps, Form} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import {
  InputField,
  SelectField,
  SubmitButton,
  TextareaField,
} from 'components/forms_fields';
import PageWrapper from 'components/PageWrapper';
import {InlineError} from 'components_sb/feedback';
import {API_URL, TARGET_ENV} from 'globals/app-globals';
import PresignResponse from 'helpers/PresignResponse';
import Property from 'models/properties/Property';
import ServiceRequest from 'models/service_requests/ServiceRequest';
import ServiceRequestAttachment from 'models/service_requests/ServiceRequestAttachment';
import TrackingService from 'services/TrackingService';
import useAuth from 'services/useAuth';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

const NewServiceRequestPage = () => {
  useTitle('New Maintenance Request');
  usePageVisit('NewServiceRequestPage');

  const {propertyId, tenancyId} = useParams();

  const {currentUser} = useAuth();

  const [isUploadingAttachment, setIsUploadingAttachment] = useState(false);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // We do this just as an easy auth check
  const {isLoading, error} = useQuery(
    `landlord-new-service-request-${propertyId}`,
    async () => {
      const property = await Property.select(['id']).find(propertyId);

      return property.data;
    },
  );

  const handleSubmit = async (formData: any, actions: any) => {
    // Doing this manually, because for some reason it doesn't like automatically
    // assigning the attributes with the nested relationship ?!?!?!?
    const request = new ServiceRequest();
    request.title = formData.title;
    request.description = formData.description;
    request.category = formData.category;
    request.renterContactName = formData.renterContactName;
    request.renterContactPhoneNumber = formData.renterContactPhoneNumber;
    if (request.category === 'Appliance Repair') {
      request.applianceMakeAndModel = formData.applianceMakeAndModel;
    }
    request.tenancyId = tenancyId;

    request.serviceRequestAttachments = formData.serviceRequestAttachments.map(
      (attachmentData: any) => {
        const attach = new ServiceRequestAttachment();
        attach.attachment = attachmentData.attachment;
        attach.taken = 'before';
        attach.serviceRequest = request;

        return attach;
      },
    );

    const result = await request.save({
      with: 'serviceRequestAttachments',
    });

    if (result) {
      await queryClient.invalidateQueries(
        `landlord-new-service-request-${propertyId}`,
      );
      toast.success(
        'Maintenance request succesfully created! The Keyhook maintenance team will now find quotes for this job.',
      );

      TrackingService.trackEvent(
        TrackingService.Event.CreateMaintenanceRequest,
      );

      navigate(`/properties/${propertyId}`, {replace: true});
    } else {
      for (const attachment of request.serviceRequestAttachments) {
        console.log(attachment.errors);
      }
      actions.setSubmitting(false);
    }
  };

  const processAttachments = async (
    formik: FormikProps<any>,
    attachments: FileList,
  ) => {
    setIsUploadingAttachment(true);

    const files = Array.from(attachments);

    for (const attachment of files) {
      const extension = attachment.type.split('/').pop();

      if (formik.values.serviceRequestAttachments.length >= 5) {
        setIsUploadingAttachment(false);
        return;
      }

      if (TARGET_ENV === 'development') {
        const formdata = new FormData();
        formdata.append('file', attachment);
        const uploadResponse = await fetch(
          `${API_URL}/uploads/service_request_attachment?property_id=${propertyId}`,
          {
            method: 'POST',
            headers: {
              'X-USER-TOKEN': currentUser.meta.authenticationToken,
              'X-USER-EMAIL': currentUser?.email,
            },
            body: formdata,
          },
        );

        const uploadData = await uploadResponse.json();
        const values: object[] = formik.values.serviceRequestAttachments;
        values.push({attachment: JSON.stringify(uploadData)});

        formik.setFieldValue('serviceRequestAttachments', values);
      } else {
        const presignResponse = await fetch(
          `${API_URL}/presigns/service_request_attachment.json?property_id=${propertyId}&filename=${attachment.name}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'X-USER-TOKEN': currentUser.meta.authenticationToken,
              'X-USER-EMAIL': currentUser?.email,
            },
          },
        );
        const presignInfo = (await presignResponse.json()) as PresignResponse;
        if (presignInfo) {
          const presignedUploadResponse = await fetch(presignInfo.url, {
            method: presignInfo.method,
            headers: presignInfo.headers as any,
            body: attachment,
          });

          const url = new URL(presignInfo.url);
          const id = url.pathname.split('/').pop();
          const uploadData = {
            id,
            storage: 'cache',
            metadata: {
              size: attachment.size,
              filename: attachment.name,
              mime_type: attachment.type,
            },
          };

          const values: object[] = formik.values.serviceRequestAttachments;
          values.push({attachment: JSON.stringify(uploadData)});

          formik.setFieldValue('serviceRequestAttachments', values);
        }
      }
    }

    setIsUploadingAttachment(false);
  };

  const removeAttachment = (formik: any, index: number) => {
    const values: object[] = formik.values.serviceRequestAttachments;
    values.splice(index, 1);

    formik.setFieldValue('serviceRequestAttachments', values);
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="New Maintenance Request">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="New Maintenance Request">
        <Card className="mt-3">
          <CardBody>
            <CardTitle>New Maintenance Request</CardTitle>
            <p className="mt-2 text-sm">
              Note: Keyhook does not facilitate urgent maintenance. If you
              require urgent maintenance, please arrange this directly. To find
              out more,{' '}
              <a
                href="https://www.tenancy.govt.nz/maintenance-and-inspections/repairs-and-damages"
                target="_blank"
                className="link link-primary">
                click here.
              </a>
            </p>
            <Formik
              initialValues={{
                title: '',
                description: '',
                category: '',
                renterContactName: '',
                renterContactPhoneNumber: '',
                applianceMakeAndModel: '',
                serviceRequestAttachments: [],
              }}
              validationSchema={Yup.object().shape({
                title: Yup.string()
                  .required()
                  .min(10)
                  .max(200)
                  .label('Short Description'),
                description: Yup.string()
                  .required()
                  .min(50)
                  .label('Description'),
                category: Yup.string()
                  .required()
                  .oneOf(ServiceRequest.categoryTypes)
                  .label('Category'),
                renterContactName: Yup.string()
                  .required()
                  .min(1)
                  .label('Tenant Name'),
                renterContactPhoneNumber: Yup.string()
                  .required()
                  .min(5)
                  .label('Tenant Phone Number'),
                applianceMakeAndModel: Yup.string()
                  .test(
                    'Appliance Repair Chosen',
                    'Add the appliance make and model',
                    function (value) {
                      if (this.parent.category === 'Appliance Repair') {
                        return value && value.length > 0;
                      } else {
                        return true;
                      }
                    },
                  )
                  .label('Appliance Make And Modal'),
                serviceRequestAttachments: Yup.array(
                  Yup.object().shape({
                    attachment: Yup.string()
                      .min(1)
                      .required()
                      .label('Attachment'),
                  }),
                )
                  .label('Attachments')
                  .min(1)
                  .max(10),
              })}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={handleSubmit}>
              {(formik) => (
                <Form>
                  <InputField
                    name="title"
                    formik={formik}
                    label="Short Description"
                    placeholder="Eg: Dishwasher not working"
                  />

                  <TextareaField
                    name="description"
                    formik={formik}
                    label="Description"
                    placeholder="Describe the issue in as much detail as possible."
                    rows={4}
                    className="h-auto w-full"
                  />

                  <SelectField name="category" formik={formik} label="Category">
                    <option>Choose a category</option>
                    {ServiceRequest.categoryTypes.map((cat) => (
                      <option
                        key={cat}
                        value={cat}
                        selected={formik.values.category === cat}>
                        {cat}
                      </option>
                    ))}
                  </SelectField>

                  {formik.values.category === 'Appliance Repair' ? (
                    <InputField
                      name="applianceMakeAndModel"
                      formik={formik}
                      label="Appliance Make and Model"
                      placeholder="Eg: Bosch H1ZTY"
                    />
                  ) : null}

                  <strong className="block mt-4">
                    Who should we contact to coordinate the maintenance?
                  </strong>

                  <div>
                    <InputField
                      name="renterContactName"
                      formik={formik}
                      label="Tenant Name"
                      placeholder="Eg: John Smith"
                    />
                    <InputField
                      name="renterContactPhoneNumber"
                      formik={formik}
                      label="Tenant Mobile Number"
                      placeholder="Eg: 021 123 4567"
                    />
                  </div>

                  <strong className="block mt-4">Attachments</strong>

                  <p className="mb-4">
                    Please attach at least 1 photo or video.
                    <small className="block text-secondary">
                      Max file size: 5mb image, 200mb video
                    </small>
                  </p>

                  {isUploadingAttachment && (
                    <span className="block text-success">
                      Processing attachments, please wait.
                    </span>
                  )}

                  {formik.values.serviceRequestAttachments.map(
                    (obj: any, index: number) => {
                      const json = JSON.parse(obj.attachment);

                      return (
                        <div key={index}>
                          <div className="flex justify-between items-center my-2">
                            <strong>{json.metadata.filename}</strong>
                            <button
                              className="btn btn-error btn-sm"
                              type="button"
                              onClick={() => removeAttachment(formik, index)}>
                              Remove
                            </button>
                          </div>
                          <hr className="bg-gray-200 w-full" />
                        </div>
                      );
                    },
                  )}

                  {formik.values.serviceRequestAttachments.length < 5 && (
                    <div className="mt-2">
                      <input
                        type="file"
                        multiple
                        accept=".png,.jpeg,.jpg,.mp4,.mov"
                        id="attachments-input"
                        onChange={(e) =>
                          processAttachments(formik, e.target.files)
                        }
                        className="hidden"
                      />
                      <button
                        className="btn btn-neutral btn-sm"
                        type="button"
                        onClick={() =>
                          document.getElementById('attachments-input').click()
                        }>
                        Select Files
                      </button>
                    </div>
                  )}

                  <InlineError
                    error={formik.errors.serviceRequestAttachments}
                  />

                  {!isUploadingAttachment && (
                    <SubmitButton
                      className="mt-3"
                      formik={formik}
                      text="Submit"
                      submittingText="Submitting..."
                    />
                  )}
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </PageWrapper>
    );
  }
};

export default NewServiceRequestPage;
