import moment from 'moment';
import {Link} from 'react-router-dom';

import Conversation from 'models/Conversation';
import Property from 'models/properties/Property';
import type {ChatChannel} from 'services/PusherService';
import useAuth from 'services/useAuth';
import {timeSince} from 'utilities/DateHelpers';
import {truncate} from 'utilities/StringHelpers';

const ChatIndexCard = ({channel}: {channel: ChatChannel}) => {
  const lastMessage = channel.mostRecentChatMessage;
  const {currentUser} = useAuth();

  const isRead =
    !lastMessage ||
    (lastMessage.readMarkers && currentUser.id in lastMessage.readMarkers);

  let name;
  let messageText;
  let timeAgo = '';
  let avatarSrc;

  if (channel.identifier === 'PropertyChannel') {
    const model = channel.model as Property;
    name = model.streetAddress;
    avatarSrc = model.mainImage;
  } else if (channel.identifier === 'ConversationChannel') {
    const model = channel.model as Conversation;
    // This get flipped as we want to show the other users details
    if (model.sender.id.toString() === currentUser.id) {
      name = model.receiver.name;
      avatarSrc = model.receiver.avatar;
    } else {
      name = model.sender.name;
      avatarSrc = model.sender.avatar;
    }
  }

  if (lastMessage) {
    if (lastMessage.content) {
      if (lastMessage.userId.toString() === currentUser.id) {
        messageText = 'You: ' + truncate(lastMessage.content, 50);
      } else {
        messageText =
          lastMessage.user.name.split(' ')[0] +
          ': ' +
          truncate(lastMessage.content, 50);
      }
    } else {
      if (lastMessage.userId.toString() === currentUser.id) {
        messageText = 'You sent an image';
      } else {
        messageText = lastMessage.user.name.split(' ')[0] + ' sent an image';
      }
    }

    timeAgo = timeSince(moment(lastMessage.createdAt).toDate());
  } else {
    messageText = 'No messages in this chat';
  }

  const key = `${channel.identifier.replace('Channel', '')}-${
    channel.model.id
  }`;

  return (
    <div className="border-b border-gray-200">
      <div className="lg:pl-8 xl:pl-0">
        <div className="relative">
          <div className="relative rounded-lg lg:px-2 py-2 flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary hover:bg-gray-200">
            <div className="flex-shrink-0">
              <img className="h-12 w-12 rounded-full" src={avatarSrc} />
            </div>
            <div className="flex-1 min-w-0">
              <Link to={key} className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true"></span>
                <div className="flex items-center justify-between">
                  <p className="text-sm font-bold text-neutral">{name}</p>
                  <div className="text-gray-400 text-xs">{timeAgo}</div>
                </div>
                <div className="flex items-center justify-between">
                  <p className="text-sm text-gray-500 truncate">
                    {messageText}
                  </p>
                  {!isRead && channel.unreadCount && channel.unreadCount > 0 ? (
                    <div className="text-white text-xs bg-red-400 rounded-full px-1 py-0">
                      {channel.unreadCount}
                    </div>
                  ) : null}
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatIndexCard;
