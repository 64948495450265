import PageWrapper from 'components/PageWrapper';
import BadRequestPage from 'pages/shared/errors/BadRequestPage';
import NotFoundPage from 'pages/shared/errors/NotFoundPage';
import ServerErrorPage from 'pages/shared/errors/ServerErrorPage';
import UnauthorisedPage from 'pages/shared/errors/UnauthorisedPage';

export const errorViewForError = (error: any) => {
  const getErrorPageComponent = () => {
    if (error.originalError) {
      error = error.originalError;
    }

    const message: string = error.message;
    const code: string = error.code;

    if (code) {
      if (code === '400') {
        return <BadRequestPage />;
      } else if (code === '404') {
        return <NotFoundPage />;
      } else if (code === '401') {
        return <UnauthorisedPage />;
      } else if (code === '500') {
        return <ServerErrorPage />;
      }
    }

    if (message.toLowerCase().includes('not found')) {
      return <NotFoundPage />;
    } else if (message.toLowerCase().includes('unauthorized')) {
      return <UnauthorisedPage />;
    } else if (message.toLowerCase().includes('server error')) {
      return <ServerErrorPage />;
    } else {
      return <NotFoundPage />;
    }
  };

  return <PageWrapper title="Error">{getErrorPageComponent()}</PageWrapper>;
};
