import {useNavigate} from 'react-router';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import InspectionsTable from 'components/inspection/InspectionsTable';
import Tenancy from 'models/properties/Tenancy';

const InspectionsCard = ({tenancy}: {tenancy?: Tenancy}) => {
  const navigate = useNavigate();

  const showInspection = (inspectionId: string) => {
    navigate(`inspections/${inspectionId}`);
  };

  if (tenancy && tenancy.inspections.length > 0) {
    return (
      <Card className="mt-8">
        <CardBody>
          <CardTitle>Inspections</CardTitle>
          <div>
            <InspectionsTable
              inspections={tenancy.inspections}
              clickHandler={showInspection}
              showCount={true}
            />
          </div>
        </CardBody>
      </Card>
    );
  } else {
    return (
      <Card className="mt-8">
        <CardBody>
          <CardTitle>Inspections</CardTitle>
          <p>
            Finish setting up your profile above to configure your inspection
            settings.
          </p>
        </CardBody>
      </Card>
    );
  }
};

export default InspectionsCard;
