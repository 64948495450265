import {useState} from 'react';

import {Form, Formik, type FormikHelpers} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import {SubmitButton, ToggleField} from 'components/forms_fields';
import SignatureModalInput from 'components/forms_fields/SignatureModalInput';
import PropertyTransfer from 'models/properties/PropertyTransfer';
import useAuth from 'services/useAuth';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';

interface ReceivingTransferForm {
  receiverSignature: string;
  receiverIsNewLandlord: boolean;
}

const IncomingPropertyTransferList = () => {
  const [isUpdatingTransfer, setIsUpdatingTransfer] = useState(false);
  const [approveFormId, setApproveFormId] = useState('-1');

  const {currentUser} = useAuth();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const queryClient = useQueryClient();

  const {data} = useQuery('landlord-property-transfers', async () => {
    const transfers = await PropertyTransfer.where({
      receiving_user_id: currentUser.id,
    })
      .includes(['property', 'sending_user'])
      .select({
        properties: [
          'street_address',
          'suburb',
          'city',
          'country',
          'main_image',
        ],
        users: ['name', 'avatar'],
      })
      .where({status: 'confirmed_by_landlord'})
      .all();

    return transfers.data;
  });

  const handleApproveSubmit = async (
    formValues: ReceivingTransferForm,
    actions: FormikHelpers<ReceivingTransferForm>,
  ) => {
    const transfer = data.find((t) => t.id === approveFormId);

    if (transfer) {
      transfer.assignAttributes(formValues);
      transfer.status = 'confirmed_by_receiver';

      const result = await transfer.save();
      if (result) {
        await queryClient.invalidateQueries('landlord-property-transfers');
        toast.success(
          'Transfer was successfully approved, this property will show up in your account within a few minutes.',
        );
      } else {
        toast.error(
          'There was an issue trying to approve this transfer, please try again',
        );
      }
    }

    actions.setSubmitting(false);
  };

  const confirmRejectTransfer = async (transfer: PropertyTransfer) => {
    setConfirmationOptions({
      title: 'Reject Transfer',
      message: 'Are you sure you want to reject the transfer of this property?',
      buttonTitle: 'Reject',
      action: () => rejectTransfer(transfer),
      color: 'error',
    });
  };

  const rejectTransfer = async (transfer: PropertyTransfer) => {
    setIsUpdatingTransfer(true);

    const result = await transfer.destroy();
    if (result) {
      await queryClient.invalidateQueries('landlord-property-transfers');
      toast.success('The transfer has been rejected.');
    } else {
      toast.error(
        'There was an issue trying to reject this transfer, please try again.',
      );
    }

    setIsUpdatingTransfer(false);
  };

  const renderItem = (item: PropertyTransfer) => {
    return (
      <Card key={item.id} className="mb-4 cursor-pointer">
        <CardBody>
          <div className="flex justify-start items-start">
            <img
              src={item.property.mainImage}
              alt={item.property.streetAddress}
              className="w-auto max-w-[40%] h-[120px]"
            />

            <div className="ml-3" style={{flexGrow: 1}}>
              <CardTitle className="mb-0">
                {item.property.streetAddress}
              </CardTitle>
              <small className="block text-secondary mt-0">
                {item.property.suburb}, {item.property.city}
              </small>

              <p className="mb-2">
                {item.sendingUser.name} has confirmed that they wish to transfer
                this property to your account. Please accept or reject this
                transfer by clicking the appropriate button below.
              </p>

              <p className="mb-2">
                Accepting this transfer will make you the new landlord of the
                property within Keyhook. If there is an active tenancy for this
                property we will generate and file the{' '}
                <a
                  href="https://www.tenancy.govt.nz/assets/forms-templates/change-of-landlord-agent-form.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="link link-primary">
                  change of landlord paperwork
                </a>{' '}
                for you.
              </p>

              {approveFormId === item.id ? (
                <Formik
                  initialValues={{
                    receiverSignature: '',
                    receiverIsNewLandlord: false,
                  }}
                  onSubmit={handleApproveSubmit}
                  validateOnBlur={false}
                  validateOnChange={false}
                  validationSchema={Yup.object().shape({
                    receiverSignature: Yup.string()
                      .required()
                      .label('Your Signature')
                      .min(10),
                    receiverIsNewLandlord: Yup.boolean().label(
                      'New Landlord Status',
                    ),
                  })}>
                  {(formik) => (
                    <Form>
                      <SignatureModalInput
                        mode="formik"
                        name="receiverSignature"
                      />

                      <div className="mt-3">
                        <ToggleField
                          name="receiverIsNewLandlord"
                          label="Are you a new landlord?"
                          formik={formik}
                        />
                      </div>

                      <SubmitButton
                        formik={formik}
                        text="Approve Transfer"
                        submittingText="Saving"
                        color="success"
                        block={false}
                      />
                    </Form>
                  )}
                </Formik>
              ) : (
                <div className="mt-2">
                  <button
                    className="btn btn-error mr-3"
                    type="button"
                    disabled={isUpdatingTransfer}
                    onClick={() => confirmRejectTransfer(item)}>
                    Reject
                  </button>
                  <button
                    className="btn btn-success"
                    type="button"
                    disabled={isUpdatingTransfer}
                    onClick={() => setApproveFormId(item.id)}>
                    Accept
                  </button>
                </div>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  if (data && data.length > 0) {
    return (
      <div className="mb-2">
        <h4 className="text-2xl mb-2 font-semibold">
          Incoming Property Transfers
        </h4>
        {data.map(renderItem)}
      </div>
    );
  } else {
    return null;
  }
};

export default IncomingPropertyTransferList;
