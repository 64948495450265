import {useState} from 'react';

import {Formik, Form} from 'formik';
import {camelCase} from 'lodash-es';
import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import LandlordProfileForm from 'components/forms/LandlordProfileForm';
import RenterProfileForm from 'components/forms/RenterProfileForm';
import {InputField, SubmitButton} from 'components/forms_fields';
import PageWrapper from 'components/PageWrapper';
import UserAvatar from 'components/user/UserAvatar';
import LandlordProfile from 'models/users/LandlordProfile';
import RenterProfile from 'models/users/RenterProfile';
import User from 'models/users/User';
import useAuth from 'services/useAuth';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

const MyAccountPage = () => {
  useTitle('My Account');
  usePageVisit('MyAccountPage');

  const [avatarUploading, setAvatarUploading] = useState(false);

  const {currentUser, updateUser, setAvatar} = useAuth();

  const {isLoading, error, data} = useQuery('user-account-page', async () => {
    const user = await User.includes([
      'landlord_profile',
      'renter_profile',
    ]).find(currentUser.id);

    if (!user.data.landlordProfile) {
      user.data.landlordProfile = new LandlordProfile({userId: currentUser.id});
    }
    if (!user.data.renterProfile) {
      user.data.renterProfile = new RenterProfile({userId: currentUser.id});
    }

    return user.data;
  });
  const queryClient = useQueryClient();

  const saveLandlordProfile = async (formData: any, actions: any) => {
    const profile = data.landlordProfile;
    profile.assignAttributes(formData);
    const result = await profile.save();

    if (result) {
      toast.success('Your profile has been successfully updated!');
    } else {
      for (const key of Object.keys(profile.errors)) {
        const message = profile.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  const saveRenterProfile = async (formData: any, actions: any) => {
    const profile = data.renterProfile;
    profile.assignAttributes(formData);
    const result = await profile.save();

    if (result) {
      toast.success('Your profile has been successfully updated!');
    } else {
      for (const key of Object.keys(profile.errors)) {
        const message = profile.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  const saveUserDetails = async (formData: any, actions: any) => {
    const result = await updateUser(formData);
    if ([200, 204].includes(result.status)) {
      toast.success('Your profile has been successfully updated!');
      queryClient.invalidateQueries('user-account-page');
    } else {
      for (const key of Object.keys(result.responseObject.errors)) {
        const error = result.responseObject.errors[key];
        const camelKey = camelCase(key);
        actions.setFieldError(camelKey, error);
      }
    }
    actions.setSubmitting(false);
  };

  const readAvatarImage = async (event: any) => {
    const file = event.target.files[0];
    const maxAllowedSize = 1 * 1024 * 1024;

    if (file && file.size <= maxAllowedSize) {
      const reader = new FileReader();

      reader.onload = async (readerEvent) => {
        setAvatarUploading(true);
        const base64 = readerEvent.target.result.toString();

        const user: User = data;
        data.avatar = base64;
        const result = await data.save();

        if (result) {
          await setAvatar(user.avatar);
          queryClient.setQueryData('user-account-page', user);
        }

        setAvatarUploading(false);
      };

      reader.readAsDataURL(file);
    }
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="My Account">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="My Account">
        <Card className="mt-2">
          <CardBody>
            <div className="flex justify-start items-end">
              <div>
                <UserAvatar user={currentUser} size="10" />
              </div>
              <div className="ml-3">
                <CardTitle className="mb-0">{currentUser.name}</CardTitle>
                <p>{currentUser.email}</p>
                <p className="text-secondary text-sm mb-2">
                  Keyhook Member Since{' '}
                  {moment(data.createdAt).format(DATE_FORMAT)}
                </p>
                <div>
                  <input
                    type="file"
                    id="avatar-image-hidden-input"
                    accept=".png,.jpeg,.jpg"
                    onChange={readAvatarImage}
                    className="hidden"
                  />

                  <button
                    className="btn btn-sm btn-neutral"
                    type="button"
                    onClick={() =>
                      document
                        .getElementById('avatar-image-hidden-input')
                        .click()
                    }>
                    {avatarUploading
                      ? 'Uploading profile picture...'
                      : 'Change profile picture'}
                  </button>

                  <small className="text-secondary ml-2">
                    ( Max allowed file size 1MB )
                  </small>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>

        <Card className="mt-4">
          <CardBody>
            <CardTitle>Account Information</CardTitle>
            <Formik
              initialValues={{
                name: data.name,
                email: data.email,
                password: '',
                passwordConfirmation: '',
                currentPassword: '',
              }}
              onSubmit={saveUserDetails}
              validationSchema={Yup.object().shape({
                name: Yup.string()
                  .required()
                  .label('Full Name')
                  .min(2)
                  .max(128)
                  .test(
                    'two-names-plus',
                    'Please input your first and last name',
                    (value) => {
                      return value.split(' ').length >= 2;
                    },
                  ),
                email: Yup.string().email().required().label('Email'),
                password: Yup.string().min(6).max(128).label('Password'),
                passwordConfirmation: Yup.string()
                  .min(6)
                  .max(128)
                  .label('Password Confirmation')
                  .test(
                    'passwords-match',
                    'Passwords must match',
                    function (value) {
                      return this.parent.password === value;
                    },
                  ),
                currentPassword: Yup.string()
                  .min(6)
                  .max(128)
                  .label('Current Password')
                  .required(),
                avatar: Yup.string().min(1).label('Avatar'),
              })}
              validateOnBlur={false}
              validateOnChange={false}>
              {(formik) => {
                return (
                  <Form>
                    <InputField
                      placeholder="eg: John Smith"
                      label="Full Name"
                      name="name"
                      formik={formik}
                    />
                    <InputField
                      placeholder="eg: johnsmith@gmail.com"
                      label="Email Address"
                      name="email"
                      type="email"
                      formik={formik}
                      autoComplete="email"
                    />
                    <small className="text-secondary">
                      ( You will need to confirm your new email address if you
                      change it. )
                    </small>

                    <div className="flex justify-between">
                      <div className="flex-1">
                        <InputField
                          placeholder="*********"
                          label="New Password (leave blank if you don't want to change it)"
                          name="password"
                          formik={formik}
                          type="password"
                          autoComplete="new-password"
                        />
                      </div>
                      <div className="flex-1 ml-2">
                        <InputField
                          placeholder="*********"
                          label="Confirm New Password"
                          name="passwordConfirmation"
                          formik={formik}
                          type="password"
                          autoComplete="new-password"
                        />
                      </div>
                    </div>
                    <div>
                      <InputField
                        placeholder="*********"
                        label="Current Password (We need this to confirm your changes)"
                        name="currentPassword"
                        formik={formik}
                        type="password"
                        autoComplete="password"
                      />
                    </div>

                    <SubmitButton
                      formik={formik}
                      text="Update Information"
                      submittingText="Saving"
                    />
                  </Form>
                );
              }}
            </Formik>
          </CardBody>
        </Card>

        {currentUser.roles.includes('Landlord') && (
          <Card className="mt-4">
            <CardBody>
              <CardTitle className="mb-0">Landlord Profile</CardTitle>
              {!data.landlordProfile.isPersisted ? (
                <div>
                  <div tabIndex={0} className="collapse collapse-arrow">
                    <input type="checkbox" />
                    <div className="collapse-title">
                      <p className="mb-2">
                        You do not currently have a landlord profile, click here
                        to reveal the form to create one. Doing so will allow
                        you to manage your rentals with Keyhook.
                      </p>
                    </div>
                    <div className="collapse-content">
                      <LandlordProfileForm
                        model={data.landlordProfile}
                        submitHandler={saveLandlordProfile}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <LandlordProfileForm
                  model={data.landlordProfile}
                  submitHandler={saveLandlordProfile}
                />
              )}
            </CardBody>
          </Card>
        )}

        {currentUser.roles.includes('Renter') && (
          <Card className="mt-4">
            <CardBody>
              <CardTitle className="mb-0">Renter Profile</CardTitle>
              {!data.renterProfile.isPersisted ? (
                <div>
                  <div tabIndex={0} className="collapse collapse-arrow">
                    <input type="checkbox" />
                    <div className="collapse-title">
                      <p className="mb-2">
                        You do not currently have a renter profile, click here
                        to reveal the form to create one. Doing so will allow
                        you to rent properties on Keyhook.
                      </p>
                    </div>
                    <div className="collapse-content">
                      <RenterProfileForm
                        model={data.renterProfile}
                        submitHandler={saveRenterProfile}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <RenterProfileForm
                  model={data.renterProfile}
                  submitHandler={saveRenterProfile}
                />
              )}
            </CardBody>
          </Card>
        )}
      </PageWrapper>
    );
  }
};

export default MyAccountPage;
