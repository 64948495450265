import {type FormikProps} from 'formik';

import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import InputField from 'components/forms_fields/InputField';
import {InsurancePolicy} from 'models/properties/InsurancePolicy';

const InsurancePolicyField = ({
  formik,
  index,
}: {
  formik: FormikProps<any>;
  index: number;
}) => {
  const removePolicy = () => {
    const policies = formik.values.insurancePolicies as InsurancePolicy[];
    policies.splice(index, 1);

    formik.setFieldValue('insurancePolicies', policies);
  };

  return (
    <div className="mt-4">
      <div className="flex justify-start gap-2 items-center">
        <strong className="d-block mb-2 mt-2">
          Policy {index + 1} Details -
        </strong>
        <a className="link link-primary no-underline" onClick={removePolicy}>
          Remove
        </a>
      </div>
      <FormRow responsive>
        <FormRowItem>
          <InputField
            name={`insurancePolicies[${index}].policy_name`}
            label="Policy Name / Type*"
            formik={formik}
            placeholder="Eg: Home and contents"
          />
        </FormRowItem>
        <FormRowItem>
          <InputField
            name={`insurancePolicies[${index}].insurer`}
            label="Insurer*"
            formik={formik}
            placeholder="Eg: AA Insurance"
          />
        </FormRowItem>
      </FormRow>

      <FormRow responsive>
        <FormRowItem>
          <InputField
            name={`insurancePolicies[${index}].excess_amount`}
            label="Excess Amount*"
            prefixLabel="$"
            formik={formik}
            placeholder="Eg: 750.00"
            type="number"
          />
        </FormRowItem>
        <FormRowItem>
          <InputField
            prefixLabel="$"
            name={`insurancePolicies[${index}].yearly_cost`}
            label="Yearly Cost"
            formik={formik}
            placeholder="Eg: 3230.00"
            type="number"
          />
        </FormRowItem>
      </FormRow>
    </div>
  );
};

export default InsurancePolicyField;
