let baseUrl: string;
let wsUrl: string;
let stripeKey: string;

export const {TARGET_ENV} = process.env;

if (TARGET_ENV === 'development') {
  baseUrl = 'http://localhost:8000';
  stripeKey =
    'pk_test_51LZjDrKR0p3qKsAQkigpq4ZH2eZbwuOrFG25w4AkyRMcPh8kllcPtLsmCzO8FeTEeUo1ebkDweSLXvxJbr1eifQ500AsMHvN2I';
} else if (TARGET_ENV === 'staging') {
  baseUrl = 'https://staging-api.keyhook.com';
  stripeKey =
    'pk_test_51LR3OuJM7uy1IgVNnkNqtG3s0q2fpBE6kwfInshZbxOLiSQRoHxqNlpNtocewo5VHWKes4SIjryMuYvMhfS0u6EP00JCuGzB67';
} else if (TARGET_ENV === 'production') {
  baseUrl = 'https://api.keyhook.com';
  stripeKey =
    'pk_live_51LR3OuJM7uy1IgVNDS16dTDIsj8eKN2SIMtcsFtKz4GfZ6ZughywQBLYIMTRx91acUeqlg4VDmUkpfFGtXFBkc57002pt7T3TO';
} else {
  baseUrl = 'https://localhost:8000';
  stripeKey =
    'pk_test_51LZjDrKR0p3qKsAQkigpq4ZH2eZbwuOrFG25w4AkyRMcPh8kllcPtLsmCzO8FeTEeUo1ebkDweSLXvxJbr1eifQ500AsMHvN2I';
}

export const BASE_URL = baseUrl;
export const API_NAMESPACE = '/api/v1';

export const API_URL = BASE_URL + API_NAMESPACE;

export const STRIPE_KEY = stripeKey;
