import Lottie from 'lottie-react';

import loadingData from 'assets/loading_animation.json';
import loadingAnimation from 'assets/loading_animation.svg';

const LoadingView = () => {
  return (
    <div className="flex justify-center w-full mt-2">
      {/* <img src={loadingAnimation} className="w-[100px] h-[100px]" /> */}
      <Lottie
        animationData={loadingData}
        loop={true}
        style={{width: 250, height: 250}}
      />
    </div>
  );
};

export default LoadingView;
