import React from 'react';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';

const RenterAwaitingInspectionTypeCard = () => {
  return (
    <Card className="mt-4">
      <CardBody>
        <CardTitle>Inspection Information</CardTitle>

        <p className="mt-2">
          Details of this inspection have not yet been confirmed.
        </p>
      </CardBody>
    </Card>
  );
};

export default RenterAwaitingInspectionTypeCard;
