import {Link} from 'react-router-dom';

const ManageListingMenu = ({
  propertyId,
  listingId,
}: {
  propertyId: string;
  listingId: string;
}) => {
  const items = [
    {
      text: 'Dashboard',
      path: `/properties/${propertyId}/listings/${listingId}`,
    },
    {
      text: 'Enquiries',
      path: `/properties/${propertyId}/listings/${listingId}/enquiries`,
    },
    {
      text: 'Applications',
      path: `/properties/${propertyId}/listings/${listingId}/applications`,
    },
    {
      text: 'Open Homes',
      path: `/properties/${propertyId}/listings/${listingId}/open-homes`,
    },
    {
      text: 'Edit',
      path: `/properties/${propertyId}/listings/${listingId}/edit`,
    },
  ];

  return (
    <ul className="menu menu-vertical lg:menu-horizontal bg-base-100 !w-full p-2 mb-4 flex">
      {items.map((item, index) => (
        <li key={index} className="flex-grow">
          <Link
            to={item.path}
            replace
            className={
              window.location.pathname === item.path
                ? 'active !text-white flex-grow block text-center'
                : 'flex-grow text-center block'
            }>
            {item.text}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default ManageListingMenu;
