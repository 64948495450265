import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router';
import {Link} from 'react-router-dom';

import keyhookLogo from 'assets/img/logo/logo-black-text.png';
import trademeLogo from 'assets/img/partner_logos/trademe.png';
import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightFooter from 'components/walkthrough/common/RightFooter';
import RightSideText from 'components/walkthrough/common/RightSideText';
import Property from 'models/properties/Property';

const PublishCapacitorStep = ({property}: {property: Property}) => {
  const listing = property.listings[0];

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const previousStep = async () => {
    property.lastOnboardingStepCompleted = 'listing_description';
    await property.save();
    queryClient.setQueryData('new-listing-wizard', property);
  };

  const title = 'Sorry! This next step is not available on your mobile device';
  const subtitle =
    'Oops! Publishing your listing is unavailable on the Keyhook mobile app.';

  return (
    <Container>
      <LeftSide title={title} subtitle="" />
      <RightContainer>
        <RightSideText title={title} subtitle={subtitle} />

        <div className="flex flex-col md:mx-16 space-y-8">
          <Link
            to={`/properties/${property.id}`}
            className="btn btn-neutral btn-block"
            replace>
            Back to property profile
          </Link>
        </div>
        <ProgressBox
          nextStep={() => {
            'noop';
          }}
          previousStep={previousStep}
          step={10}
          previousStepEnabled
          nextStepEnabled={false}
        />
      </RightContainer>
    </Container>
  );
};

export default PublishCapacitorStep;
