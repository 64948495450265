import {useCallback, useEffect} from 'react';

import {Browser} from '@capacitor/browser';
import {Capacitor} from '@capacitor/core';
import {AiOutlineInfoCircle} from '@react-icons/all-files/ai/AiOutlineInfoCircle';
import {useTour as useReactour} from '@reactour/tour';
import clsx from 'clsx';
import moment from 'moment';
import {
  FaClipboardCheck,
  FaClipboardList,
  FaDollarSign,
  FaFile,
  FaHammer,
} from 'react-icons/fa';
import {useLocation, useNavigate} from 'react-router';
import {Link} from 'react-router-dom';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import PageWrapper from 'components/PageWrapper';
import {Button} from 'components_sb/buttons';
import MobileListItem from 'components_sb/lists/MobileListItem/MobileListItem';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import LandlordWelcome from 'tours/LandlordWelcome';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

const DemoPropertyDetailPage = () => {
  usePageVisit('PropertyDetailPage');
  useTitle('1 Example Street');

  const navigate = useNavigate();

  const showInspection = (inspectionId: string) => {
    navigate(`inspections/${inspectionId}`);
  };
  const showServiceRequest = (serviceRequestId: string) => {
    navigate(`service-requests/${serviceRequestId}`);
  };
  const showDocument = (document: string) => {
    Browser.open({url: document});
  };

  // ========================================================================
  // Welcome Tour
  // ========================================================================
  // const demoPropertyTour = useTour(LandlordWelcome.DemoProperty);
  // const location = useLocation();
  // const handleContinueTour = useCallback(() => {
  //   if (location.state?.continueTourOnMount) {
  //     demoPropertyTour.start();
  //   }
  // }, [location, demoPropertyTour]);
  // /**
  //  * Start the tour on mount if continuing from the tour from the landlord dashboard.
  //  */
  // useEffect(() => {
  //   handleContinueTour();
  // }, [handleContinueTour]);
  // ========================================================================

  const navigateToAddProperty = useCallback(() => {
    localStorage.removeItem('new-property-id');
    navigate('/properties/new', {replace: true});
  }, [navigate]);

  const {isOpen: tourIsActive} = useReactour();

  const isMobile = !useTailwindBreakpoint('sm');

  return (
    <PageWrapper title="Example Property">
      <div>
        <div
          data-tour="demo-property-card"
          className="flex flex-col justify-center mb-10 demo-property-card">
          <div className="relative flex flex-col md:flex-row md:space-x-0 space-y-3 md:space-y-0 rounded-xl shadow-lg p-3 w-full mx-auto border border-white bg-white">
            <div className="w-full md:w-1/4 bg-white grid place-items-center">
              <img
                src="https://storage.googleapis.com/propup-demo-media/property-1.jpeg"
                className="rounded-xl"
              />
            </div>
            <div className="w-full md:w-2/3 bg-white flex flex-col space-y-2 p-3">
              <div className="flex justify-between item-center">
                <p className="text-gray-500 font-medium md:block">
                  Thorndon, Wellington
                </p>
              </div>
              <h3 className="font-black text-gray-800 md:text-3xl text-xl">
                1 Example Street
              </h3>

              {isMobile ? (
                <div
                  className="grid grid-cols-2 gap-y-2 gap-x-2"
                  data-tour="demo-property-item-stats">
                  <div className="flex justify-start items-center bg-white rounded-full">
                    <div className="bg-neutral rounded-full w-8 h-8 flex justify-center items-center">
                      <FaDollarSign className="w-4 h-4 text-white" />
                    </div>
                    <div className="flex flex-col ml-2 pt-1">
                      <span className="text-gray-500" style={{fontSize: 10}}>
                        Rent
                      </span>
                      <h3 className="text-md font-semibold">$820.00</h3>
                    </div>
                  </div>

                  <div className="flex justify-start items-center bg-white rounded-full">
                    <div className="bg-neutral rounded-full w-8 h-8 flex justify-center items-center">
                      <FaHammer className="w-4 h-4 text-white" />
                    </div>
                    <div className="flex flex-col ml-2 pt-1">
                      <span className="text-gray-500" style={{fontSize: 10}}>
                        Maintenance
                      </span>
                      <h3 className="text-md font-semibold">1 Request</h3>
                    </div>
                  </div>

                  <div className="flex justify-start items-center bg-white rounded-full">
                    <div className="bg-neutral rounded-full w-8 h-8 flex justify-center items-center">
                      <FaClipboardList className="w-4 h-4 text-white" />
                    </div>
                    <div className="flex flex-col ml-2 pt-1">
                      <span className="text-gray-500" style={{fontSize: 10}}>
                        Next Inspection
                      </span>
                      <h3 className="text-md font-semibold">Today</h3>
                    </div>
                  </div>

                  <div className="flex justify-start items-center bg-white rounded-full">
                    <div className="bg-neutral rounded-full w-8 h-8 flex justify-center items-center">
                      <FaFile className="w-4 h-4 text-white" />
                    </div>
                    <div className="flex flex-col ml-2 pt-1">
                      <span className="text-gray-500" style={{fontSize: 10}}>
                        Lease Expires
                      </span>
                      <h3 className="text-md font-semibold">123 Days</h3>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  data-tour="demo-property-item-stats"
                  className="w-full bordered shadow-xl stats mmd:grid-flow-row">
                  <div className="stat">
                    <div className="stat-title">Rent</div>
                    <div className="stat-value text-2xl">$820.00</div>
                    <div className="stat-desc">Weekly</div>
                  </div>
                  <div className="stat">
                    <div className="stat-title">Maintenance Requests</div>
                    <div className="stat-value text-2xl">1</div>
                    <div className="stat-desc">Open Requests</div>
                  </div>
                  <div className="stat">
                    <div className="stat-title">Next Inspection</div>
                    <div className="stat-value text-2xl">Today</div>
                    <div className="stat-desc"></div>
                  </div>
                  <div className="stat">
                    <div className="stat-title">Lease Expires</div>
                    <div className="stat-value text-2xl">123</div>
                    <div className="stat-desc">Days</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div data-tour="demo-action-banners" className="action-banners">
          {/* <Link to="inspections/2">
            <div className="alert alert-info shadow-lg mb-4">
              <div>
                <AiOutlineInfoCircle className="w-6 h-6" />
                <span>
                  Your tenants have submitted their inspection, click here to
                  review it.
                </span>
              </div>
            </div>
          </Link> */}

          <Link to="service-requests/2">
            <div className="alert alert-info shadow-lg mb-4">
              <div>
                <AiOutlineInfoCircle className="w-6 h-6" />
                <span>
                  Quotes have been found for the Maintenance Request: 'Bedroom
                  light stopped working', click here to review them.
                </span>
              </div>
            </div>
          </Link>
        </div>

        <Card data-tour="demo-bond-card" className="mt-8">
          <CardBody>
            <CardTitle>Bond Status</CardTitle>
            <p>
              The bond for this tenancy has successfully been filed with Tenancy
              Services, the id for the bond id is ABC-1234.
            </p>
          </CardBody>
        </Card>

        <Card
          data-tour="demo-inspections-card"
          className="mt-8 inspections-card">
          <CardBody>
            <CardTitle className="flex justify-between">Inspections</CardTitle>
            <div className="hidden lg:block">
              <div className="overflow-x-auto">
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Type</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Done by</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      className="hover cursor-pointer"
                      onClick={() => showInspection('1')}>
                      <td>1</td>
                      <td>Pre-tenancy</td>
                      <td>
                        {moment().subtract(90, 'days').format(DATE_FORMAT)}
                      </td>
                      <td>Complete</td>
                      <td>Landlord</td>
                      <td>0</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="block lg:hidden">
              <div className="flex flex-col gap-2">
                <MobileListItem
                  onClick={() => showInspection('1')}
                  title="Pre-tenancy"
                  icon={FaClipboardCheck}
                  subtitle={
                    <>
                      <span>Today</span>
                      <span>Complete</span>
                      <span className="text-grey-300">Done by: Landlord</span>
                    </>
                  }
                />
              </div>
            </div>
          </CardBody>
        </Card>

        <Card
          data-tour="demo-maintenance-requests-card"
          className="mt-8 service-requests-card">
          <CardBody>
            <CardTitle className="flex justify-between">
              Maintenance Requests
            </CardTitle>
            <div className="hidden lg:block">
              <div className="overflow-x-auto">
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Job Date</th>
                      <th>Total Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      onClick={() => showServiceRequest('2')}
                      className="hover cursor-pointer">
                      <td>2</td>
                      <td>Bedroom light stopped working</td>
                      <td>Awaiting Quote Acceptance</td>
                      <td>{moment().subtract(1, 'day').format(DATE_FORMAT)}</td>
                      <td>N/A</td>
                    </tr>
                    <tr
                      onClick={() => showServiceRequest('1')}
                      className="hover cursor-pointer">
                      <td>1</td>
                      <td>The kitchen sink is leaking</td>
                      <td>Complete</td>
                      <td>
                        {moment().subtract(35, 'days').format(DATE_FORMAT)}
                      </td>
                      <td>$115.00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="block lg:hidden">
              <div className="flex flex-col gap-2">
                <MobileListItem
                  onClick={() => showServiceRequest('2')}
                  title="Bedroom light stopped working"
                  icon={FaHammer}
                  subtitle={
                    <>
                      <span>Awaiting Quote Acceptance</span>
                      <span>
                        {moment().subtract(1, 'day').format(DATE_FORMAT)}
                      </span>
                    </>
                  }
                />

                <MobileListItem
                  onClick={() => showServiceRequest('1')}
                  title="The kitchen sink is leaking"
                  icon={FaHammer}
                  subtitle={
                    <>
                      <span>Complete</span>
                      <span>
                        {moment().subtract(35, 'days').format(DATE_FORMAT)}
                      </span>
                    </>
                  }
                />
              </div>
            </div>
          </CardBody>
        </Card>

        <Card data-tour="demo-documents-card" className="mt-8 documents-card">
          <CardBody>
            <CardTitle className="flex justify-between">Documents</CardTitle>

            <div className="hidden lg:block">
              <div className="overflow-x-auto">
                <table className="table w-full">
                  <thead className="thead-light">
                    <tr>
                      <th>Document Type</th>
                      <th>Date Added</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      onClick={() =>
                        showDocument(
                          'https://storage.googleapis.com/propup-demo-media/documents/healthy-homes-compliance.pdf',
                        )
                      }
                      className="hover cursor-pointer">
                      <td>Healthy Homes Compliance</td>
                      <td>
                        {moment().subtract(110, 'days').format(DATE_FORMAT)}
                      </td>
                      <td>Property</td>
                    </tr>
                    <tr
                      onClick={() =>
                        showDocument(
                          'https://storage.googleapis.com/propup-demo-media/documents/demo-lease.pdf',
                        )
                      }
                      className="hover cursor-pointer">
                      <td>Lease</td>
                      <td>
                        {moment().subtract(90, 'days').format(DATE_FORMAT)}
                      </td>
                      <td>Tenancy</td>
                    </tr>
                    <tr
                      onClick={() =>
                        showDocument(
                          'https://storage.googleapis.com/propup-demo-media/documents/demo-bond.pdf',
                        )
                      }
                      className="hover cursor-pointer">
                      <td>Bond</td>
                      <td>
                        {moment().subtract(90, 'days').format(DATE_FORMAT)}
                      </td>
                      <td>Tenancy</td>
                    </tr>
                    <tr
                      onClick={() =>
                        showDocument(
                          'https://storage.googleapis.com/propup-demo-media/documents/bond_receipt.pdf',
                        )
                      }
                      className="hover cursor-pointer">
                      <td>Bond Receipt</td>
                      <td>
                        {moment().subtract(90, 'days').format(DATE_FORMAT)}
                      </td>
                      <td>Tenancy</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="block lg:hidden">
              <div className="flex flex-col gap-2">
                <MobileListItem
                  onClick={() =>
                    showDocument(
                      'https://storage.googleapis.com/propup-demo-media/documents/healthy-homes-compliance.pdf',
                    )
                  }
                  title="Healthy Home Compliance"
                  icon={FaFile}
                  subtitle={
                    <>
                      <span>
                        {moment().subtract(110, 'days').format(DATE_FORMAT)}
                      </span>
                      <span>Property</span>
                    </>
                  }
                />

                <MobileListItem
                  onClick={() =>
                    showDocument(
                      'https://storage.googleapis.com/propup-demo-media/documents/bond_receipt.pdf',
                    )
                  }
                  title="Bond Receipt"
                  icon={FaFile}
                  subtitle={
                    <>
                      <span>
                        {moment().subtract(100, 'days').format(DATE_FORMAT)}
                      </span>
                      <span>Tenancy</span>
                    </>
                  }
                />

                <MobileListItem
                  onClick={() =>
                    showDocument(
                      'https://storage.googleapis.com/propup-demo-media/documents/demo-lease.pdf',
                    )
                  }
                  title="Lease"
                  icon={FaFile}
                  subtitle={
                    <>
                      <span>
                        {moment().subtract(90, 'days').format(DATE_FORMAT)}
                      </span>
                      <span>Tenancy</span>
                    </>
                  }
                />

                <MobileListItem
                  onClick={() =>
                    showDocument(
                      'https://storage.googleapis.com/propup-demo-media/documents/demo-bond.pdf',
                    )
                  }
                  title="Bond"
                  icon={FaFile}
                  subtitle={
                    <>
                      <span>
                        {moment().subtract(90, 'days').format(DATE_FORMAT)}
                      </span>
                      <span>Tenancy</span>
                    </>
                  }
                />
              </div>
            </div>
          </CardBody>
        </Card>

        <Card className="mt-8">
          <CardBody>
            <CardTitle className="flex justify-between">Tenants</CardTitle>

            <div>
              <div className="overflow-x-auto hidden lg:block">
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th>Avatar</th>
                      <th>Name</th>
                      <th>Head Tenant?</th>
                      <th>Share Of Rent</th>
                      <th>Bond Paid?</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="avatar">
                          <div className="rounded-full w-10 h-10">
                            <img src="https://storage.googleapis.com/propup-demo-media/people/renter_1.jpeg" />
                          </div>
                        </div>
                      </td>
                      <td>John Doe</td>
                      <td>Yes</td>
                      <td>$420.00</td>
                      <td>Yes</td>
                    </tr>

                    <tr>
                      <td>
                        <div className="avatar">
                          <div className="rounded-full w-10 h-10">
                            <img src="https://storage.googleapis.com/propup-demo-media/people/renter_2.jpeg" />
                          </div>
                        </div>
                      </td>
                      <td>Jane Smith</td>
                      <td>No</td>
                      <td>$400.00</td>
                      <td>Yes</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="block lg:hidden">
                <div className="flex flex-col gap-2">
                  {/*  */}
                  <div className="mt-2">
                    <div className="flex justify-between items-center mb-2">
                      <div className="flex justify-start gap-2 items-center">
                        <div className="avatar">
                          <div className="rounded-full w-12 h-12">
                            <img src="https://storage.googleapis.com/propup-demo-media/people/renter_1.jpeg" />
                          </div>
                        </div>
                        <div>
                          <strong>John Doe</strong>
                          <p className="text-sm flex flex-col">
                            <span>Head Tenant</span>

                            <span>$420.00 rent share</span>
                            <span>Bond Paid</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-full h-px bg-gray-200"></div>
                  </div>

                  <div className="mt-2">
                    <div className="flex justify-between items-center mb-2">
                      <div className="flex justify-start gap-2 items-center">
                        <div className="avatar">
                          <div className="rounded-full w-12 h-12">
                            <img src="https://storage.googleapis.com/propup-demo-media/people/renter_2.jpeg" />
                          </div>
                        </div>
                        <div>
                          <strong>Jane Smith</strong>
                          <p className="text-sm flex flex-col">
                            <span>$400.00 rent share</span>
                            <span>Bond Paid</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-full h-px bg-gray-200"></div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>

      {!tourIsActive && (
        <>
          <div
            className={clsx(
              'z-40 fixed w-full bottom-0 left-0 flex justify-center items-center',
              'bg-white shadow-2xl rounded-t-lg p-6',
              'border-2 border-brand-50',
            )}>
            <div className="flex-1 max-w-md">
              <Button
                label="Add my own property"
                category="primary"
                size="lg"
                mode="manual"
                onClick={navigateToAddProperty}
              />
            </div>
          </div>
          {/* This adds extra padding so that you can scroll to the bottom properly.  */}
          {/* Otherwise its hidden behind the add my property button */}
          {Capacitor.isNativePlatform() && <div style={{height: 100}}></div>}
        </>
      )}
    </PageWrapper>
  );
};

export default DemoPropertyDetailPage;
