import {useCallback} from 'react';

import {RiGhostLine} from '@react-icons/all-files/ri/RiGhostLine';
import clsx from 'clsx';
import {UnmountClosed} from 'react-collapse';
import {useNavigate} from 'react-router';
import {useLocalStorage} from 'usehooks-ts';

import {Button} from 'components_sb/buttons';
import useAuth from 'services/useAuth';

const GhostingUserWarning = () => {
  const [ghosting] = useLocalStorage<boolean>('ghosting', false);

  const navigate = useNavigate();

  const {logOutUser} = useAuth();

  const onExitGhostModeClicked = useCallback(async () => {
    logOutUser();
  }, [logOutUser]);

  return (
    <UnmountClosed isOpened={ghosting}>
      <div
        className={clsx(
          'alert-warning p-2',
          'text-sm font-medium',
          'flex flex-col sm:flex-row justify-between items-center gap-2',
        )}>
        <div className="flex-1 flex flex-row items-center gap-x-2 whitespace-nowrap">
          <RiGhostLine className="hidden sm:flex w-5 h-5 mx-2" />
          <strong className="hidden sm:flex">Warning: </strong>
          You are currently in ghost mode.
        </div>
        <div className="w-full sm:w-auto max-w-xs flex-none">
          <Button
            label="Exit ghost mode"
            category="warning"
            size="sm"
            mode="manual"
            onClick={onExitGhostModeClicked}
          />
        </div>
      </div>
    </UnmountClosed>
  );
};
export default GhostingUserWarning;
