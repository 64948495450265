import {Capacitor} from '@capacitor/core';
import {useQuery} from 'react-query';
import {Navigate, useNavigate} from 'react-router';
import {toast} from 'react-toastify';

import PageWrapper from 'components/PageWrapper';
import LoadingStep from 'components/walkthrough/common/LoadingStep';
import AvailabilityStep from 'components/walkthrough/new_listing/AvailabilityStep';
import DescriptionStep from 'components/walkthrough/new_listing/DescriptionStep';
import FinancialsStep from 'components/walkthrough/new_listing/FinancialsStep';
import GeneralInfoStep from 'components/walkthrough/new_listing/GeneralInfoStep';
import PhotosStep from 'components/walkthrough/new_listing/PhotosStep';
import PreviewStep from 'components/walkthrough/new_listing/PreviewStep';
import PropertyTypeStep from 'components/walkthrough/new_listing/PropertyTypeStep';
import PublishCapacitorStep from 'components/walkthrough/new_listing/PublishCapacitorStep';
import PublishStep from 'components/walkthrough/new_listing/PublishStep';
import RequirementsStep from 'components/walkthrough/new_listing/RequirementsStep';
import TagsStep from 'components/walkthrough/new_listing/TagsStep';
import useNativeInsets from 'hooks/useNativeInsets';
import useScrollPositionFix from 'hooks/useScrollPositionFix';
import Listing from 'models/listings/Listing';
import Property from 'models/properties/Property';
import useAuth from 'services/useAuth';
import {usePageVisit, useTitle} from 'utilities/hooks';

const Wrapper = ({children}: {children: React.ReactNode}) => {
  const insets = useNativeInsets();

  useScrollPositionFix();

  if (Capacitor.isNativePlatform()) {
    return (
      <div style={{paddingTop: insets.top}} className="h-full">
        <div className="w-full h-full flex flex-col overflow-y-scroll bg-white">
          <PageWrapper title="New Listing" disablePadding>
            {children}
          </PageWrapper>
        </div>
      </div>
    );
  } else {
    return <>{children}</>;
  }
};

const NewListingWalkthroughPage = () => {
  usePageVisit('NewListingWalkthroughPage');
  useTitle('Create a Listing');

  const navigate = useNavigate();

  const address = localStorage.getItem('new-listing-address');

  // A bit clearer than !!!address
  if (!!address === false) {
    navigate('/listings');
  }

  const {currentUser} = useAuth();

  if (!currentUser) {
    navigate('/register/landlord');
  }
  const {userIsLoggedIn} = useAuth();

  const {data, isLoading} = useQuery(
    `new-listing-wizard`,
    async () => {
      const addressInfo = JSON.parse(address);

      const property = await Property.includes({listings: 'listing_photos'})
        .where({
          // The curlies are to prevent graphiti from treating addresses with a comma as an array
          street_address: `{{${addressInfo.address1}}}`,
        })
        .order({'listings.created_at': 'desc'})
        .order({'listings.listings_photos.order_index': 'asc'})
        .first();

      if (property.data) {
        if (property.data.listings.length > 0) {
          const listing = property.data.listings[0];
          if (listing.status === 'active') {
            toast.error('This property already has an active listing.');
            navigate('/listings/' + listing.publicId);
          } else {
            return property.data;
          }
        } else {
          const listing = new Listing({propertyId: property.data.id});
          property.data.listings = [listing];

          await property.data.save({with: 'listings'});

          return property.data;
        }
      } else {
        const property = new Property();
        property.addressMeta = addressInfo;
        property.streetAddress = addressInfo.displayline;
        property.suburb = addressInfo.suburb;
        property.city = addressInfo.city;
        property.postcode = addressInfo.postcode;
        property.country = 'New Zealand';
        property.noTenancyActionType = 'advertise';

        const result = await property.save();
        if (result) {
          const listing = new Listing({propertyId: property.id});
          property.listings = [listing];
          await property.save({with: 'listings'});
        } else {
          console.log(property.errors);
        }

        return property;
      }
    },
    {
      enabled: userIsLoggedIn && !!address,
    },
  );

  // A bit clearer than !!!address
  if (!!address === false) {
    return <Navigate to="/listings" />;
  }

  if (!userIsLoggedIn) {
    return <Navigate to="/register/landlord" />;
  }

  if (isLoading || !data) {
    return (
      <Wrapper>
        <LoadingStep />
      </Wrapper>
    );
  } else {
    const property = data;

    if (
      !property.lastOnboardingStepCompleted ||
      property.lastOnboardingStepCompleted === 'select_onboarding_type'
    ) {
      return (
        <Wrapper>
          <PropertyTypeStep property={data} />
        </Wrapper>
      );
    } else if (
      property.lastOnboardingStepCompleted === 'listing_property_type'
    ) {
      return (
        <Wrapper>
          <GeneralInfoStep property={data} />
        </Wrapper>
      );
    } else if (
      property.lastOnboardingStepCompleted === 'listing_general_information'
    ) {
      return (
        <Wrapper>
          <RequirementsStep property={data} />
        </Wrapper>
      );
    } else if (
      property.lastOnboardingStepCompleted === 'listing_requirements'
    ) {
      return (
        <Wrapper>
          <FinancialsStep property={data} />
        </Wrapper>
      );
    } else if (property.lastOnboardingStepCompleted === 'listing_financials') {
      return (
        <Wrapper>
          <AvailabilityStep property={data} />
        </Wrapper>
      );
    } else if (
      property.lastOnboardingStepCompleted === 'listing_availability'
    ) {
      return (
        <Wrapper>
          <TagsStep property={data} />
        </Wrapper>
      );
    } else if (property.lastOnboardingStepCompleted === 'listing_tags') {
      return (
        <Wrapper>
          <PhotosStep property={data} />
        </Wrapper>
      );
    } else if (property.lastOnboardingStepCompleted === 'listing_photos') {
      return (
        <Wrapper>
          <DescriptionStep property={data} />
        </Wrapper>
      );
    } else if (property.lastOnboardingStepCompleted === 'listing_description') {
      return (
        <Wrapper>
          <PreviewStep property={data} />
        </Wrapper>
      );
    } else if (property.lastOnboardingStepCompleted === 'listing_preview') {
      if (Capacitor.isNativePlatform()) {
        return (
          <Wrapper>
            <PublishCapacitorStep property={data} />
          </Wrapper>
        );
      } else {
        return (
          <Wrapper>
            <PublishStep property={data} />
          </Wrapper>
        );
      }
    } else {
      return <Navigate to="/listings" replace />;
    }
  }
};

export default NewListingWalkthroughPage;
