import {PencilIcon, TrashIcon} from '@heroicons/react/outline';
import {FaUserCheck} from 'react-icons/fa';
import {useQuery, useQueryClient} from 'react-query';
import {Navigate, useNavigate, useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import MobileListItem from 'components_sb/lists/MobileListItem/MobileListItem';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import Property from 'models/properties/Property';
import PreferredTradesman from 'models/service_requests/PreferredTradesman';
import useAuth from 'services/useAuth';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

const PreferredTradesmanIndexPage = () => {
  useTitle('Preferred Tradesmen');
  usePageVisit('PreferredTradesmanIndexPage');

  const {propertyId} = useParams();
  const {currentUser} = useAuth();
  const queryClient = useQueryClient();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const navigate = useNavigate();

  if (!currentUser.meta.isPaid) {
    navigate('/');
  }

  // PUll property to make sure they have permission to access this property
  // Todo: This should be one API call. Use an includes
  const {isLoading, error, data} = useQuery(
    `property-${propertyId}-preferred-tradespeople`,
    async () => {
      const tradies = await PreferredTradesman.where({propertyId}).all();
      const property = await Property.select(['street_address']).find(
        propertyId,
      );
      return {
        property: property.data,
        tradies: tradies.data,
      };
    },
    {
      enabled: currentUser.meta.isPaid,
    },
  );

  const confirmDeleteTradie = async (tradie: PreferredTradesman) => {
    setConfirmationOptions({
      title: 'Remove Preferred Tradesperson',
      message: `Are you sure you want to remove ${tradie.name} as a preferred tradesperson?`,
      buttonTitle: 'Remove',
      action: () => deleteTradie(tradie),
      color: 'error',
    });
  };

  const deleteTradie = async (tradie: PreferredTradesman) => {
    const result = await tradie.destroy();
    if (result) {
      toast.success(
        `${tradie.name} has been removed as your preferred tradesperson.`,
      );
      queryClient.invalidateQueries(
        `property-${propertyId}-preferred-tradespeople`,
      );
    } else {
      toast.error(`There was an issue removing ${tradie.name}.`);
    }
  };

  const isMobile = !useTailwindBreakpoint('sm');

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Preferred Tradespeople">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper
        title="Preferred Tradespeople"
        buttons={[{text: 'Add', href: 'new'}]}>
        <Card>
          <CardBody>
            <CardTitle>Preferred Tradesmen</CardTitle>
            {data.tradies.length > 0 ? (
              <div>
                <p className="mb-4">
                  These are your preferred tradespeople for the property at{' '}
                  {data.property.streetAddress}
                </p>

                {isMobile ? (
                  <div>
                    {data.tradies.map((tradie) => (
                      <MobileListItem
                        key={tradie.id}
                        title={tradie.name}
                        subtitle={
                          <>
                            <p>{tradie.tradeCategory}</p>
                            <p>{tradie.phoneNumber}</p>
                            <p>{tradie.email}</p>
                          </>
                        }
                        link={`${tradie.id}/edit`}
                        icon={FaUserCheck}
                      />
                    ))}
                  </div>
                ) : (
                  <table className="table w-full">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Trade Category</th>
                        <th>Phone Number</th>
                        <th>Email</th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.tradies.map((tradie) => (
                        <tr key={tradie.id} className="hover">
                          <td>{tradie.name}</td>
                          <td>{tradie.tradeCategory}</td>
                          <td>{tradie.phoneNumber}</td>
                          <td>{tradie.email}</td>
                          <td>
                            <div className="flex justify-center">
                              <Link to={`${tradie.id}/edit`}>
                                <PencilIcon className="w-6 h-6 text-secondary" />
                              </Link>
                              <TrashIcon
                                className="w-6 h-6 ml-3 text-secondary cursor-pointer"
                                onClick={() => confirmDeleteTradie(tradie)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            ) : (
              <p className="my-4">
                You do not currently have any preferred tradespeople for the
                property at {data.property.streetAddress}
              </p>
            )}
          </CardBody>
        </Card>
      </PageWrapper>
    );
  }
};

export default PreferredTradesmanIndexPage;
