import {useEffect, useState} from 'react';

import {type FormikHelpers, Formik, Form} from 'formik';
import _ from 'lodash';
import moment from 'moment';
import {DayPicker} from 'react-day-picker';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightSideText from 'components/walkthrough/common/RightSideText';
import {API_URL} from 'globals/app-globals';
import Property from 'models/properties/Property';
import TrackingService from 'services/TrackingService';
import useAuth from 'services/useAuth';

const days = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

type FormValues = {
  startDate: string;
};

const CommencementDateStep = ({property}: {property: Property}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.MigrateTenancy_StartCommencementDateStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const queryClient = useQueryClient();
  const tenancy = property.tenancies[0];

  const {currentUser} = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    setIsLoading(true);

    const response = await fetch(
      `${API_URL}/tenancies/${tenancy.id}/set_commencement_date.json`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          start_date: formValues.startDate,
        }),
        headers: {
          'Content-Type': 'application/json',
          'X-USER-TOKEN': currentUser?.meta.authenticationToken,
          'X-USER-EMAIL': currentUser?.email,
          'X-ACCOUNT-TYPE': 'Landlord',
        },
      },
    );

    const result1 = response.ok;

    property.lastOnboardingStepCompleted = 'migrate_commencement_date';
    const result2 = await property.save();

    if (result1 && result2) {
      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.MigrateTenancy_CompleteCommencementDateStep,
        {
          propertyId: property.id,
        },
      );

      /**
       * Track completion of the flow.
       */
      TrackingService.trackEvent(
        TrackingService.Event.MigrateTenancy_CompleteFlow,
        {
          propertyId: property.id,
        },
      );

      queryClient.setQueryData(['new-property', property.id], property);
      localStorage.removeItem('new-property-id');
      toast.success('Your property has been successfully set up!');
      navigate('/properties/' + property.id, {replace: true});
    } else {
      for (const key of Object.keys(property.errors)) {
        const message = property.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    setIsLoading(false);
  };

  const goToPrevious = async () => {
    setIsLoading(true);
    property.lastOnboardingStepCompleted = 'migrate_invite_tenant';
    await property.save();

    queryClient.setQueryData(['new-property', property.id], property);
    setIsLoading(false);
  };

  const title = 'When shall we start?';
  const subtitle = 'Set the date when your tenancy will transition to Keyhook';

  const initialDate = moment()
    .startOf('isoWeek')
    .add(1, 'week')
    .day(tenancy.dayOfWeekRentPaid.toLowerCase())
    .format('YYYY-MM-DD');

  const invalidDays: number[] = Object.values(
    _.omit(days, tenancy.dayOfWeekRentPaid),
  );

  return (
    <Container>
      <LeftSide title={title} subtitle={subtitle} />
      <RightContainer>
        <RightSideText title={title} subtitle={subtitle} />
        <Formik
          initialValues={
            {
              startDate: tenancy.startDate || initialDate,
            } as FormValues
          }
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            startDate: Yup.string().required().label('Commencement Date'),
          })}>
          {(formik) => (
            <Form className="mx-16">
              <div className="flex justify-center">
                <DayPicker
                  mode="single"
                  selected={new Date(formik.values.startDate)}
                  onSelect={(date) =>
                    formik.setFieldValue(
                      'startDate',
                      moment(date).format('YYYY-MM-DD'),
                    )
                  }
                  disabled={[
                    {
                      from: new Date(1900, 1, 1),
                      to: moment(initialDate).subtract(1, 'day').toDate(),
                    },
                    {dayOfWeek: invalidDays},
                  ]}
                  className="border-2 p-4 rounded-xl"
                />
              </div>
              <div className="flex justify-center text-center mt-2">
                {formik.values.startDate &&
                  formik.values.startDate.length > 0 && (
                    <strong>
                      {moment(formik.values.startDate).format(
                        'dddd, MMMM Do YYYY',
                      )}
                    </strong>
                  )}
              </div>
              <div>
                <p className="text-secondary text-sm text-center mt-2">
                  Commencement date should be at least one week away and align
                  with rent payment.
                </p>
              </div>

              <ProgressBox
                nextStep={formik.submitForm}
                previousStep={goToPrevious}
                step={6}
                totalSteps={7}
                previousStepEnabled
                nextStepEnabled={!isLoading}
              />
            </Form>
          )}
        </Formik>
      </RightContainer>
    </Container>
  );
};

export default CommencementDateStep;
