import React, {useState} from 'react';

import {HomeIcon} from '@heroicons/react/outline';
import {Form, Formik, type FormikHelpers} from 'formik';
import {useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {PROPERTY_TYPES} from 'components/forms/NewPropertyForm';
import {
  CountableNumberField,
  SelectField,
  SubmitButton,
} from 'components/forms_fields';
import ActionCard from 'components/property/landlord/profile_blocks/ActionCard';
import ProfileItemModal from 'components/property/landlord/profile_blocks/ProfileItemModal';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';

const PropertyInformationAction = ({
  property,
  tenancy,
  storeKey,
}: {
  property: Property;
  tenancy: Tenancy;
  storeKey: string;
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = async (formValues: any, actions: FormikHelpers<any>) => {
    const prop = property;

    prop.assignAttributes(formValues);
    prop.markStepAsCompleted(storeKey);

    const result = await prop.save();

    if (result) {
      queryClient.setQueryData(`landlord-property-detail-${prop.id}`, prop);
      toast.success('Property successfully updated!');
      setModalVisible(false);
    } else {
      for (const key of Object.keys(prop.errors)) {
        const message = prop.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  return (
    <div>
      <ActionCard
        title="Property Information"
        subtitle="Basic information about your property."
        IconClass={HomeIcon}
        iconBgColor="bg-blue-300"
        onClick={() => setModalVisible(true)}
      />
      <ProfileItemModal
        modalIsOpen={modalVisible}
        setModalIsOpen={setModalVisible}
        title="Property Information"
        subtitle="Basic information about your property."
        IconClass={HomeIcon}
        bgColor="bg-blue-300"
        form={
          <Formik
            initialValues={{
              bedrooms: property.bedrooms || 0,
              bathrooms: property.bathrooms || 0,
              garages: property.garages || 0,
              propertyType: property.propertyType || 'House',
            }}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              bathrooms: Yup.number()
                .min(1)
                .max(10)
                .required()
                .label('Bathrooms'),
              bedrooms: Yup.number()
                .min(0)
                .max(12)
                .required()
                .label('Bedrooms'),
              garages: Yup.number().min(0).max(12).required().label('Garages'),
              propertyType: Yup.string()
                .oneOf(PROPERTY_TYPES)
                .required()
                .label('Property Type'),
            })}>
            {(formik) => (
              <Form className="md:h-full flex-1">
                <div className="flex flex-col justify-between h-full">
                  <div className="flex flex-col justify-center grow space-y-8">
                    <CountableNumberField
                      formik={formik}
                      name="bedrooms"
                      label="Bedrooms"
                    />
                    <CountableNumberField
                      formik={formik}
                      name="bathrooms"
                      label="Bathrooms"
                      incrementValue={0.5}
                    />
                    <CountableNumberField
                      formik={formik}
                      name="garages"
                      label="Garages"
                    />

                    <div>
                      <SelectField
                        label=""
                        name="propertyType"
                        formik={formik}
                        helpText="If unsure, select house.">
                        {PROPERTY_TYPES.map((propType, index) => (
                          <option value={propType} key={index}>
                            {propType}
                          </option>
                        ))}
                      </SelectField>
                    </div>
                  </div>

                  <SubmitButton
                    formik={formik}
                    text="Save"
                    submittingText="Saving"
                    className="!rounded-full bg-blue-300 border-none mt-6"
                  />
                </div>
              </Form>
            )}
          </Formik>
        }
      />
    </div>
  );
};

export default PropertyInformationAction;
