import {useCallback, useImperativeHandle, Fragment, useState} from 'react';

import {Capacitor} from '@capacitor/core';
import {saveAs} from 'file-saver';
import {Form, Formik, useFormik, type FormikProps} from 'formik';
import {isEmpty} from 'lodash';
import moment from 'moment';
import {useQuery} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {DateField, SelectField} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import {InlineError} from 'components_sb/feedback';
import {ModalDefinition} from 'components_sb/layout';
import Paragraph from 'components_sb/typography/Paragraph/Paragraph';
import {API_URL} from 'globals/app-globals';
import Property from 'models/properties/Property';
import useAuth from 'services/useAuth';

const queryString = require('query-string');

interface FormValues {
  start_date: any;
  end_date: any;
  property_id: any;
  exportMode: 'download' | 'email';
  email?: boolean;
}

const ExportPaymentsModal: ModalDefinition = {
  title: 'Export Payments',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'export',
        label: 'Export',
        handle: 'onExport',
        closeOnSuccess: false,
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const {closeModal} = props;
    const {currentUser} = useAuth();

    const {data: properties} = useQuery(
      'landlord-financials-properties-export',
      async () => {
        const properties = await Property.select([
          'id',
          'street_address',
        ]).all();

        return properties.data;
      },
    );

    const handleSubmit = useCallback(
      async (values: FormValues, formikHelpers: any) => {
        const vals = {...values};
        if (vals.property_id === 0) {
          delete vals.property_id;
        }

        if (vals.exportMode === 'download') {
          const qString = queryString.stringify(vals);
          const response = await fetch(
            API_URL + `/reports/landlord.csv?${qString}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'X-USER-TOKEN': currentUser.meta.authenticationToken,
                'X-USER-EMAIL': currentUser.email,
              },
            },
          );

          const blob = await response.blob();
          let filename = `all_payments_${moment(vals.start_date).format(
            'DD-MM-YYYY',
          )}-${moment(vals.end_date).format('DD-MM-YYYY')}.csv`;
          if (vals.property_id) {
            const prop = properties.find((p) => p.id === vals.property_id);
            if (prop) {
              const address = prop.streetAddress
                .toLowerCase()
                .replaceAll(' ', '_');
              filename = `${address}_payments_${moment(vals.start_date).format(
                'DD-MM-YYYY',
              )}-${moment(vals.end_date).format('DD-MM-YYYY')}.csv`;
            }
          }
          saveAs(blob, filename);
          closeModal();
          toast.success('Your download has started!');
        } else {
          vals.email = true;
          const qString = queryString.stringify(vals);
          const response = await fetch(
            API_URL + `/reports/landlord.csv?${qString}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'X-USER-TOKEN': currentUser.meta.authenticationToken,
                'X-USER-EMAIL': currentUser.email,
              },
            },
          );

          if (response.ok) {
            closeModal();
            toast.success(
              'Your payments CSV will be emailed to you within the next few minutes.',
            );
          }
        }
      },
      [closeModal, currentUser, properties],
    );

    const formik = useFormik<FormValues>({
      initialValues: {
        start_date: undefined,
        end_date: undefined,
        property_id: 0,
        // TODO: Re-enable choosing export mode
        exportMode: Capacitor.isNativePlatform() ? 'email' : 'download',
      },
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        start_date: Yup.date().required().label('Start Date'),
        end_date: Yup.date()
          .required()
          .label('End Date')
          .test(
            'greater than start date',
            'End Date must be after the start date',
            function (value) {
              return value > this.parent.start_date;
            },
          ),
        property_id: Yup.number().optional().label('Property'),
      }),
    });

    const quickSelectDateRange = (period: string, formik: FormikProps<any>) => {
      let startDate: Date;

      if (period === '30d') {
        startDate = moment().subtract('30', 'days').toDate();
      } else if (period === '60d') {
        startDate = moment().subtract('60', 'days').toDate();
      } else if (period === '3m') {
        startDate = moment().subtract('3', 'months').toDate();
      } else if (period === '6m') {
        startDate = moment().subtract('6', 'months').toDate();
      } else if (period === '1y') {
        startDate = moment().subtract('1', 'year').toDate();
      } else {
        startDate = new Date('2000-01-01');
      }

      formik.setFieldValue('start_date', startDate);
      formik.setFieldValue('end_date', moment().toDate());
    };

    const onExport = useCallback(async () => {
      const result = await formik.validateForm();
      if (isEmpty(result)) {
        const {setSubmitting, setFieldError} = formik;
        await handleSubmit(formik.values, {setSubmitting, setFieldError});
      }
      return false;
    }, [formik, handleSubmit]);

    useImperativeHandle(ref, () => ({
      onExport,
    }));

    return (
      <>
        <Paragraph>
          Generate a CSV file containing the payments we have sent to you.{' '}
          {Capacitor.isNativePlatform()
            ? 'This file will be emailed to you.'
            : ''}{' '}
          The CSV file will include the following:
        </Paragraph>
        <ol className="mt-2 text-secondary text-sm list-disc list-inside">
          <li>Date</li>
          <li>Gross Earnings</li>
          <li>Expenses</li>
          <li>GST</li>
          <li>Net Amount Received</li>
          <li>Description</li>
          <li>Reference</li>
        </ol>
        <div className="flex justify-between my-2">
          <div className="flex-1">
            <DateField
              formik={formik}
              name="start_date"
              label="Start Date"
              minDate={new Date('1900-01-01')}
              maxDate={new Date()}
            />
          </div>
          <div className="flex-1 ml-2">
            <DateField
              formik={formik}
              name="end_date"
              label="End Date"
              minDate={new Date('1900-01-01')}
              maxDate={new Date()}
            />
          </div>
        </div>

        <div>
          <label className="label block">
            <span className="label-text">Quick Select</span>
          </label>
          <div className="btn-group">
            <button
              className="btn btn-sm btn-neutral"
              type="button"
              onClick={() => quickSelectDateRange('30d', formik)}>
              30d
            </button>
            <button
              className="btn btn-sm btn-neutral"
              type="button"
              onClick={() => quickSelectDateRange('60d', formik)}>
              60d
            </button>
            <button
              className="btn btn-sm btn-neutral"
              type="button"
              onClick={() => quickSelectDateRange('3m', formik)}>
              3m
            </button>
            <button
              className="btn btn-sm btn-neutral"
              type="button"
              onClick={() => quickSelectDateRange('6m', formik)}>
              6m
            </button>
            <button
              className="btn btn-sm btn-neutral"
              type="button"
              onClick={() => quickSelectDateRange('1y', formik)}>
              1y
            </button>
            <button
              className="btn btn-sm btn-neutral"
              type="button"
              onClick={() => quickSelectDateRange('all', formik)}>
              All
            </button>
          </div>
        </div>

        <div>
          <SelectField name="property_id" formik={formik} label="Property">
            <option value={0}>All Properties</option>
            {properties &&
              properties.length > 0 &&
              properties.map((prop) => (
                <option key={prop.id} value={prop.id}>
                  {prop.streetAddress}
                </option>
              ))}
          </SelectField>
        </div>

        {!Capacitor.isNativePlatform() && (
          <div className="mt-2">
            <label className="label flex justify-start items-center">
              <span className="label-text mr-2">
                How would you like to receive the export?
              </span>
            </label>

            <FormRow>
              <FormRowItem>
                <button
                  type="button"
                  disabled={formik.isSubmitting}
                  className={`btn w-full ${
                    formik.values.exportMode === 'email'
                      ? 'btn-secondary'
                      : 'btn-neutral btn-outline'
                  }`}
                  onClick={() => formik.setFieldValue('exportMode', 'email')}>
                  Send via email
                </button>
              </FormRowItem>

              <FormRowItem>
                <button
                  type="button"
                  disabled={formik.isSubmitting}
                  className={`btn w-full ${
                    formik.values.exportMode === 'download'
                      ? 'btn-secondary'
                      : 'btn-neutral btn-outline'
                  }`}
                  onClick={() =>
                    formik.setFieldValue('exportMode', 'download')
                  }>
                  Download to my device
                </button>
              </FormRowItem>
            </FormRow>

            <InlineError error={formik.errors.exportMode} />
          </div>
        )}
      </>
    );
  },
};

export default ExportPaymentsModal;
