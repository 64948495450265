export const pluralize = (num: number, str: string) => {
  if (num === 0) {
    return `${str}s`;
  } else if (num === 1) {
    return str;
  } else {
    return `${str}s`;
  }
};

export const truncate = (input: string, length: number): string => {
  return input.length > length ? `${input.substring(0, length)}...` : input;
};

export const titleize = (sentence: string): string => {
  if (!sentence.split) {
    return sentence;
  }
  const _titleizeWord = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  const result: string[] = [];

  sentence.split(' ').forEach((w) => {
    result.push(_titleizeWord(w));
  });
  return result.join(' ');
};

export const removeUnderscores = (words: string): string => {
  return words.replace(/_/g, ' ');
};

const formatter = new Intl.NumberFormat('en-NZ', {
  style: 'currency',
  currency: 'NZD',
});
export const toCurrency = (amount: number | string): string => {
  if (typeof amount === 'string') {
    return formatter.format(Number(amount));
  } else {
    return formatter.format(amount);
  }
};

export const capitalize = (word: string): string => {
  return word[0].toUpperCase() + word.substring(1).toLowerCase();
};

export const isFullName = (name: string): boolean => {
  // When you split this string: "Aaron " it will create ["Aaron", ""], so we need to filter out
  // those empty strings
  // And then make sure its 2 names.
  return name.split(' ').filter((s) => s.length > 0).length >= 2;
};
