import React, {Fragment, useState} from 'react';
import {FC} from 'react';

import {Popover, Transition} from '@headlessui/react';
import {BiBed} from '@react-icons/all-files/bi/BiBed';
import {Range} from 'rc-slider';

export interface BedroomRangeInputProps {
  onChange?: (data: any) => void;
  fieldClassName?: string;
}

const BedroomRangeInput: FC<BedroomRangeInputProps> = ({
  onChange,
  fieldClassName = '[ nc-hero-field-padding ]',
}) => {
  const defaultMin = 0;
  const defaultMax = 10;

  const [minBeds, setMinBeds] = useState(defaultMin);
  const [maxBeds, setMaxBeds] = useState(defaultMax);

  const handleMinBedsChange = (val: number) => {
    setMinBeds(val);
    onChange([val, maxBeds]);
  };

  const handleMaxBedsChange = (val: number) => {
    setMaxBeds(val);
    onChange([minBeds, val]);
  };

  return (
    <Popover className="flex relative [ nc-flex-1 ]">
      {({open, close}) => (
        <>
          <Popover.Button
            className={`flex text-left w-full flex-shrink-0 items-center ${fieldClassName} space-x-3 focus:outline-none cursor-pointer ${
              open ? 'shadow-2xl rounded-full dark:bg-neutral-800' : ''
            }`}>
            <div className="text-neutral-300 dark:text-neutral-400">
              <BiBed className="w-8 h-8" />
            </div>
            <div className="flex-grow">
              <span className="block xl:text-lg font-semibold truncate">
                {minBeds} ~ {maxBeds}
              </span>
              <span className="block mt-1 text-sm text-neutral-400 leading-none font-light ">
                Bedrooms
              </span>
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1">
            <Popover.Panel className="absolute left-0 lg:right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <div className="relative flex flex-col space-y-8">
                <div className="space-y-5">
                  <span className="font-medium">Bedrooms </span>
                  <Range
                    className="text-red-400"
                    min={defaultMin}
                    max={defaultMax}
                    defaultValue={[minBeds, maxBeds]}
                    value={[minBeds, maxBeds]}
                    allowCross={false}
                    step={1}
                    onChange={(val) => {
                      handleMinBedsChange(val[0]);
                      handleMaxBedsChange(val[1]);
                    }}
                  />
                </div>

                <div className="flex justify-between space-x-3">
                  <div>
                    <label
                      htmlFor="minBedrooms"
                      className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                      Min bedrooms
                    </label>
                    <div className="mt-1 relative rounded-md">
                      <input
                        type="number"
                        name="minBedrooms"
                        id="minBedrooms"
                        min={defaultMin}
                        max={defaultMax}
                        className="focus:ring-primary-500 focus:border-primary-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                        value={minBeds}
                        onChange={(e) =>
                          handleMinBedsChange(Number(e.target.value))
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="maxBedrooms"
                      className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                      Max bedrooms
                    </label>
                    <div className="mt-1 relative rounded-md">
                      <input
                        type="number"
                        name="maxBedrooms"
                        id="maxBedrooms"
                        min={defaultMin}
                        max={defaultMax}
                        className="focus:ring-primary-500 focus:border-priring-primary-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                        value={maxBeds}
                        onChange={(e) =>
                          handleMaxBedsChange(Number(e.target.value))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default BedroomRangeInput;
