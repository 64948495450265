import React, {Fragment, useState} from 'react';
import {FC} from 'react';

import {Popover, Transition} from '@headlessui/react';
import {BiDollarCircle} from '@react-icons/all-files/bi/BiDollarCircle';
import {Range} from 'rc-slider';

export interface PriceRangeInputProps {
  onChange?: (data: any) => void;
  fieldClassName?: string;
}

const PriceRangeInput: FC<PriceRangeInputProps> = ({
  onChange,
  fieldClassName = '[ nc-hero-field-padding ]',
}) => {
  const defaultMin = 0;
  const defaultMax = 2000;
  const step = 10;

  const [minRent, setMinRent] = useState(defaultMin);
  const [maxRent, setMaxRent] = useState(defaultMax);

  const handleMinRentChange = (val: number) => {
    setMinRent(val);
    onChange([val, maxRent]);
  };

  const handleMaxRentChange = (val: number) => {
    setMaxRent(val);
    onChange([minRent, val]);
  };

  return (
    <Popover className="flex relative [ nc-flex-1 ]">
      {({open, close}) => (
        <>
          <Popover.Button
            className={`flex text-left w-full flex-shrink-0 items-center ${fieldClassName} space-x-3 focus:outline-none cursor-pointer ${
              open ? 'shadow-2xl rounded-full dark:bg-neutral-800' : ''
            }`}>
            <div className="text-neutral-300 dark:text-neutral-400">
              <BiDollarCircle className="w-8 h-8" />
            </div>
            <div className="flex-grow">
              <span className="block xl:text-lg font-semibold truncate">
                ${minRent} ~ ${maxRent}
              </span>
              <span className="block mt-1 text-sm text-neutral-400 leading-none font-light ">
                Weekly Rent
              </span>
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1">
            <Popover.Panel className="absolute left-0 lg:right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <div className="relative flex flex-col space-y-8">
                <div className="space-y-5">
                  <span className="font-medium">Rent Range </span>
                  <Range
                    min={defaultMin}
                    max={defaultMax}
                    defaultValue={[minRent, maxRent]}
                    allowCross={false}
                    step={step}
                    onChange={(val) => {
                      handleMinRentChange(val[0]);
                      handleMaxRentChange(val[1]);
                    }}
                    trackStyle={[{backgroundColor: '#3366FF'}]}
                    handleStyle={[
                      {border: '2px solid #3366FF'},
                      {border: '2px solid #3366FF'},
                    ]}
                  />
                </div>

                <div className="flex justify-between space-x-3">
                  <div>
                    <label
                      htmlFor="minPrice"
                      className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                      Min rent
                    </label>
                    <div className="mt-1 relative rounded-md">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-neutral-500 sm:text-sm">$</span>
                      </div>
                      <input
                        type="number"
                        name="minPrice"
                        id="minPrice"
                        className="focus:ring-primary-500 focus:border-primary-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                        value={minRent}
                        onChange={(e) =>
                          handleMinRentChange(Number(e.target.value))
                        }
                        min={defaultMin}
                        max={defaultMax}
                        step={step}
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="maxPrice"
                      className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                      Max rent
                    </label>
                    <div className="mt-1 relative rounded-md">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-neutral-500 sm:text-sm">$</span>
                      </div>
                      <input
                        type="number"
                        name="maxPrice"
                        id="maxPrice"
                        className="focus:ring-primary-500 focus:border-priring-primary-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                        value={maxRent}
                        onChange={(e) =>
                          handleMaxRentChange(Number(e.target.value))
                        }
                        min={defaultMin}
                        max={defaultMax}
                        step={step}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default PriceRangeInput;
