import {ReactNode} from 'react';

import {type FormikProps} from 'formik';
import moment from 'moment';
import Datetime, {DatetimepickerProps} from 'react-datetime';

import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import {InlineError} from 'components_sb/feedback';
import TrackingService from 'services/TrackingService';
import {DATE_FORMAT} from 'utilities/DateHelpers';

import './DateField.css';

interface DateFieldProps extends DatetimepickerProps {
  formik: FormikProps<any>;
  minDate: Date;
  maxDate: Date;
  label: string;
  name: string;
  helpText?: string;
  children?: ReactNode;
}

const DateField = ({
  formik,
  label,
  name,
  minDate = new Date(),
  maxDate = new Date(),
  helpText,
  ...rest
}: DateFieldProps) => {
  const classN = formik.errors[name] ? ' border border-red-500' : '';
  if (rest.className) {
    rest.className += classN;
  } else {
    rest.className = classN;
  }

  if (rest.children) {
    rest.className += ' flex-1';
  }

  const valid = (
    currentDate: moment.Moment,
    selectedDate: moment.Moment,
  ): boolean => {
    return (
      currentDate.isSameOrAfter(moment(minDate)) &&
      currentDate.isSameOrBefore(moment(maxDate))
    );
  };

  if (rest.children) {
    return (
      <div className="form-control">
        <label className="label flex justify-start items-center">
          <span className="label-text mr-2">{label}</span>
          <HelpTextPresenter helpText={helpText} />
        </label>
        <div className="input-group">
          <Datetime
            value={formik.values[name] ? moment(formik.values[name]) : ''}
            onChange={(val) => {
              const m = moment(val);
              formik.setFieldValue(name, m.format('YYYY-MM-DD'));
              TrackingService.trackEvent(TrackingService.Event.FillField, {
                field: name,
              });
            }}
            dateFormat={DATE_FORMAT}
            isValidDate={valid}
            timeFormat={false}
            inputProps={{
              placeholder: 'Click to select date',
              className: 'input input-bordered w-full',
            }}
            {...rest}
          />
          {rest.children}
        </div>
        <InlineError error={formik.errors[name]} />
      </div>
    );
  } else {
    return (
      <>
        <label className="label flex justify-start items-center">
          <span className="label-text mr-2">{label}</span>
          <HelpTextPresenter helpText={helpText} />
        </label>
        <Datetime
          value={formik.values[name] ? moment(formik.values[name]) : ''}
          onChange={(val) => {
            const m = moment(val);
            formik.setFieldValue(name, m.format('YYYY-MM-DD'));
          }}
          dateFormat={DATE_FORMAT}
          isValidDate={valid}
          timeFormat={false}
          inputProps={{
            placeholder: 'Click to select date',
            className: 'input input-bordered w-full',
          }}
          {...rest}
        />
        <InlineError error={formik.errors[name]} />
      </>
    );
  }
};

export default DateField;
