// @ts-nocheck

import {ReactNode, useEffect, useState} from 'react';

import {Form, Formik} from 'formik';
import {PersistFormikValues} from 'formik-persist-values';
import * as Yup from 'yup';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import {
  InputField,
  SelectField,
  ToggleField,
  AutocompleteField,
  ResizingImageField,
} from 'components/forms_fields';
import ChattelField from 'components/forms_fields/ChattelField';
import PetTypeField from 'components/forms_fields/PetTypeField';
import {API_URL} from 'globals/app-globals';
import TrackingService from 'services/TrackingService';
import useAuth from 'services/useAuth';
import {useAutoAnimate} from 'utilities/hooks';

export const PROPERTY_TYPES = ['Apartment', 'House', 'Townhouse', 'Unit'];

type InsurancePolicy = {
  policy_name: string;
  insurer: string;
  excess_amount: number;
};

const WelcomeCard = ({formik}: {formik: any}) => {
  return (
    <Card className="mt-3">
      <CardBody>
        <CardTitle>Let's get started adding your property</CardTitle>
        <p>By clicking the button below, you are confirming that:</p>
        <p>
          • You own this property or are in a legal capacity to manage renting
          and tenants.
        </p>
        <p>
          • This property conforms to the{' '}
          <a
            href="https://www.tenancy.govt.nz/healthy-homes/about-the-healthy-homes-standards/"
            target="_blank"
            rel="noreferrer"
            className="link link-primary">
            New Zealand Healthy Homes Act.
          </a>
        </p>
        <p>
          • This property meets the{' '}
          <a
            href="https://www.tenancy.govt.nz/maintenance-and-inspections/smoke-alarms/"
            target="_blank"
            rel="noreferrer"
            className="link link-primary">
            required laws for smoke alarm installations.
          </a>
        </p>

        <p className="text-sm text-secondary mt-4">
          The information in this form is used to create a profile for your
          property, it will be used to automatically fill in legal paperwork
          (such as leases) for you.
        </p>
      </CardBody>
    </Card>
  );
};

const AddressCard = ({formik}: {formik: any}) => {
  const {currentUser} = useAuth();
  const [formType, setFormType] = useState<'autocomplete' | 'manual'>(
    'autocomplete',
  );

  const [animateAddressRef] = useAutoAnimate();

  const queryAddresses = async (str: string) => {
    if (str.length <= 2) {
      return [];
    }

    const url = `${API_URL}/addresses.json?query=${str}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-USER-TOKEN': currentUser?.meta.authenticationToken,
          'X-USER-EMAIL': currentUser?.email,
        },
      });
      const data = await response.json();
      const places = data.addresses;

      return places;
    } catch (e) {
      return [];
    }
  };

  const selectAddress = async (option: any): Promise<any[]> => {
    if (option) {
      const url = `${API_URL}/addresses/${option['id']}.json`;

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-USER-TOKEN': currentUser?.meta.authenticationToken,
            'X-USER-EMAIL': currentUser?.email,
          },
        });
        const data = await response.json();

        const ad = data.address;

        formik.setFieldValue('addressMeta', ad);
        formik.setFieldValue('streetAddress', ad.displayline);

        formik.setFieldValue('suburb', ad.suburb);
        formik.setFieldValue('city', ad.city);
        formik.setFieldValue('postcode', ad.postcode);
        // formik.setFieldValue('address', '');

        TrackingService.trackEvent(
          TrackingService.Event.SelectAutocompleteAddress,
        );
      } catch (e) {
        return [];
      }
    }
  };

  return (
    <Card className="mt-3 w-full overflow-visible">
      <CardBody>
        <CardTitle>Property Address</CardTitle>
        <p>Choose an Address from the autocomplete field below.</p>

        <div ref={animateAddressRef as any}>
          {formType === 'autocomplete' ? (
            <>
              <div>
                <AutocompleteField
                  label="Address"
                  name="address"
                  formik={formik}
                  placeholder="123 Example Street, Suburb, City"
                  filter={queryAddresses}
                  select={selectAddress}
                />
              </div>
              <p className="mt-2">
                Don't see your address in the results? Click the button below to
                manually add it.
              </p>
              <button
                className="btn btn-neutral btn-block mt-2"
                onClick={() => setFormType('manual')}>
                Add Address Manually
              </button>
            </>
          ) : (
            <>
              <InputField
                label="Street Address"
                name="streetAddress"
                formik={formik}
                placeholder="eg: 123 Example Street"
                autoComplete="off"
              />
              <div className="flex justify-between">
                <div style={{flex: 1}}>
                  <InputField
                    label="Suburb"
                    name="suburb"
                    formik={formik}
                    placeholder="eg: Tawa"
                    autoComplete="off"
                  />
                </div>
                <div className="ml-2" style={{flex: 1}}>
                  <InputField
                    label="City"
                    name="city"
                    formik={formik}
                    placeholder="eg: Wellington"
                    autoComplete="off"
                  />
                </div>
              </div>
              <InputField
                label="Post Code"
                name="postcode"
                formik={formik}
                placeholder="eg: 1234"
                type="number"
                autoComplete="off"
              />

              <button
                className="btn btn-neutral btn-block mt-2"
                onClick={() => setFormType('autocomplete')}>
                Use Autocomplete
              </button>
            </>
          )}
        </div>

        <small className="text-secondary mt-4 block">
          Please use the format Unit/Street Number for houses with apt / unit
          numbers, Eg: "12/59 Fake Street".
        </small>
      </CardBody>
    </Card>
  );
};

export const PropertyInformationCard = ({formik}: {formik: any}) => {
  const imagePreview = () => {
    if (formik.values.mainImage && formik.values.mainImage.length > 0) {
      return (
        <div className="mt-1">
          <strong className="d-block">Preview:</strong>
          <div className="flex justify-center">
            <img
              src={formik.values.mainImage}
              alt=""
              style={{
                width: '50%',
                height: 'auto',
                maxWidth: 1000,
                marginTop: 16,
              }}
            />
          </div>
          <button
            className="btn btn-sm mt-4"
            type="button"
            onClick={() => formik.setFieldValue('mainImage', '')}>
            Remove
          </button>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Card className="mt-3">
      <CardBody>
        <CardTitle>Property Information</CardTitle>
        <div>
          <div className="flex justify-between">
            <div style={{flex: 1}}>
              <InputField
                label="Bedrooms"
                name="bedrooms"
                formik={formik}
                placeholder="Eg: 3"
                type="number"
                step={1}
              />
            </div>
            <div className="ml-2" style={{flex: 1}}>
              <InputField
                label="Bathrooms"
                name="bathrooms"
                formik={formik}
                placeholder="Eg: 2.5"
                type="number"
                step={0.5}
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div style={{flex: 1}}>
              <InputField
                label="Garages"
                name="garages"
                formik={formik}
                placeholder="Eg: 1"
                type="number"
              />
            </div>
            <div className="ml-2" style={{flex: 1}}>
              <InputField
                label="Max # of tenants"
                name="maxTenants"
                formik={formik}
                placeholder="Eg: 3"
                type="number"
              />
            </div>
          </div>

          <SelectField
            label="Property Type"
            name="propertyType"
            formik={formik}>
            {PROPERTY_TYPES.map((propType, index) => (
              <option value={propType} key={index}>
                {propType}
              </option>
            ))}
          </SelectField>
        </div>

        <div className="mt-2">
          <CardTitle>Property Image</CardTitle>
          <p>
            Do you want to upload a property image? Keyhook will use the Google
            StreetView image by default.
          </p>

          <div className="mt-4">
            <ResizingImageField
              formik={formik}
              name="mainImage"
              label="Choose an Image"
              accept=".png,.jpeg,.jpg"
              width={1000}
              height={600}
            />
          </div>
        </div>
        {imagePreview()}
      </CardBody>
    </Card>
  );
};

export const RequirementsCard = ({
  formik,
  isNew,
}: {
  formik: any;
  isNew: boolean;
}) => {
  const [animatePetsHook] = useAutoAnimate();

  return (
    <Card className="mt-3">
      <CardBody>
        <CardTitle>Requirements</CardTitle>
        <div className="w-full sm:w-1/2">
          <div ref={animatePetsHook as any}>
            <ToggleField
              name="petsAllowed"
              formik={formik}
              label="Are pets allowed?"
            />

            {formik.values.petsAllowed && <PetTypeField formik={formik} />}
          </div>

          <ToggleField
            name="smokersAllowed"
            formik={formik}
            label="Is smoking allowed?"
          />

          <strong className="block mt-4">Inspections</strong>

          <SelectField
            name="inspectionPeriod"
            formik={formik}
            label="Inspections to be held every?">
            <option value="1">Month</option>
            <option value="2">2 Months</option>
            <option value="3">3 Months</option>
            <option value="4">4 Months</option>
            <option value="5">5 Months</option>
            <option value="6">6 Months</option>
          </SelectField>
        </div>
        <p className="text-secondary text-sm mt-3">
          Most insurance companies require inspections to be held at least every
          3 months for your insurance plan to remain valid.
        </p>

        {!isNew && (
          <div>
            <strong className="block mt-4">Maintenance Requests</strong>
            <InputField
              label="Authority to fix limit ($)"
              formik={formik}
              name="authorityToFixLimit"
              type="number"
              min={0}
              max={100000}
              step={10}
              placeholder="Eg: 200"
            />

            <p className="text-secondary text-sm mt-2">
              Keyhook will automatically arrange for jobs to be completed for
              any maintenance requests under this limit. Set as{' '}
              <strong>0</strong> if you would live to review every request.
            </p>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export const ChattelsCard = ({
  formik,
  showCard = true,
}: {
  formik: any;
  showCard: boolean;
}) => {
  const [chattelName, setChattelName] = useState('');
  const [courtesyChattelName, setCourtesyChattelName] = useState('');

  const [animateChattelsRef] = useAutoAnimate();
  const [animateCourtesyRef] = useAutoAnimate();

  const add = () => {
    let chattels = formik.values.chattels;
    if (!chattels) {
      chattels = {[chattelName]: 1};
    } else {
      chattels[chattelName] = 1;
    }

    formik.setFieldValue('chattels', chattels);

    setChattelName('');
  };

  const addCourtesy = () => {
    let chattels = formik.values.courtesyChattels;
    if (!chattels) {
      chattels = {[courtesyChattelName]: 1};
    } else {
      chattels[courtesyChattelName] = 1;
    }
    formik.setFieldValue('courtesyChattels', chattels);

    setCourtesyChattelName('');
  };

  const renderCard = (children: ReactNode): JSX.Element => {
    if (showCard) {
      return (
        <Card className="mt-3">
          <CardBody>{children}</CardBody>
        </Card>
      );
    } else {
      return children;
    }
  };

  return renderCard(
    <>
      <CardTitle>Included Chattels</CardTitle>
      <p className="text-secondary text-sm mb-2">
        Include any chattels that should be included in the lease. Tenants will
        be reasonably responsible for any damage to these chattels and the
        landlord will be responsible for their maintenance.
      </p>
      <div ref={animateChattelsRef as any}>
        {Object.keys(formik.values.chattels || {}).map((key) => (
          <ChattelField
            formik={formik}
            chattels={formik.values.chattels}
            chattelKey={key}
            key={key}
            formikKey="chattels"
          />
        ))}
      </div>

      <div className="mt-3">
        <strong className="block">Add a chattel</strong>
        <input
          onChange={(e) => setChattelName(e.currentTarget.value)}
          value={chattelName}
          placeholder="Eg: Washing Machine"
          className="input input-bordered"
        />
        <button
          className="btn btn-neutral btn-sm block mt-2"
          onClick={add}
          disabled={chattelName.length === 0}
          type="button">
          Add Chattel
        </button>
      </div>

      <CardTitle className="mt-2">Courtesy Chattels</CardTitle>
      <p className="text-secondary text-sm mb-2">
        Courtesy chattels are provided by the landlord under the understanding
        that the landlord is not responsible for their maintenance. An example
        of a courtesy chattel is a BBQ a landlord has left on their rental
        property for a tenant to use throughout their tenancy.
      </p>
      <div ref={animateCourtesyRef as any}>
        {Object.keys(formik.values.courtesyChattels || {}).map((key) => (
          <ChattelField
            formik={formik}
            chattels={formik.values.courtesyChattels}
            chattelKey={key}
            key={key}
            formikKey="courtesyChattels"
          />
        ))}
      </div>

      <div className="mt-3">
        <strong className="block">Add a courtesy chattel</strong>
        <input
          onChange={(e) => setCourtesyChattelName(e.currentTarget.value)}
          value={courtesyChattelName}
          placeholder="Eg: Bookcase"
          className="input input-bordered"
        />
        <button
          className="btn btn-neutral btn-sm block mt-2"
          onClick={addCourtesy}
          disabled={courtesyChattelName.length === 0}
          type="button">
          Add Courtesy Chattel
        </button>
      </div>
    </>,
  );
};

// export const ImageCard = ({formik}) => {
//   const imagePreview = () => {
//     if (formik.values.mainImage && formik.values.mainImage.length > 0) {
//       return (
//         <div className="mt-1">
//           <strong className="d-block">Preview:</strong>
//           <div className="flex justify-center">
//             <img
//               src={formik.values.mainImage}
//               alt=""
//               style={{
//                 width: '100%',
//                 height: 'auto',
//                 maxWidth: 1000,
//                 marginTop: 16,
//               }}
//             />
//           </div>
//           <button
//             className="btn btn-sm mt-4"
//             type="button"
//             onClick={() => formik.setFieldValue('mainImage', '')}>
//             Remove
//           </button>
//         </div>
//       );
//     } else {
//       return null;
//     }
//   };
//   return (
//     <Card className="mt-3">
//       <CardBody>
//         <CardTitle>Property Image</CardTitle>
//         <div>
//           <p>
//             Do you want to upload a property image? Keyhook will use the Google
//             StreetView image by default.
//           </p>

//           <div className="mt-4">
//             <ResizingImageField
//               formik={formik}
//               name="mainImage"
//               label="Choose an Image"
//               accept=".png,.jpeg,.jpg"
//               width={1000}
//               height={600}
//             />
//           </div>
//         </div>
//         {imagePreview()}
//       </CardBody>
//     </Card>
//   );
// };

export const InsuranceCard = ({formik}: {formik: any}) => {
  const [animateInsuranceRef] = useAutoAnimate();

  const handleAddPolicy = () => {
    let policies = formik.values.insurancePolicies;
    if (!policies) {
      policies = [] as InsurancePolicy[];
    }
    policies.push({
      policy_name: '',
      insurer: '',
      excess_amount: 0,
    });
    formik.setFieldValue('insurancePolicies', policies);
  };

  const handleRemovePolicy = () => {
    const policies = formik.values.insurancePolicies;
    policies.pop();
    formik.setFieldValue('insurancePolicies', policies);
  };

  const policies = formik.values.insurancePolicies || ([] as InsurancePolicy[]);

  return (
    <Card className="mt-3">
      <CardBody>
        <CardTitle>Insurance Policies</CardTitle>
        <p>
          NZ law requires the insurance information for a property to be
          included with any leases.
        </p>
        <p>You can add this information later if you do not have it on hand.</p>
        <div className="mt-1 w-full sm:w-1/2">
          <ToggleField
            name="insuranceCoversTenants"
            formik={formik}
            label="Insurance Covers Tenants?"
            helpText="There is insurance covering this rental property that is relevant
            to tenant's liability for damage to premises, including damage to
            body corporate facilities."
          />

          <div ref={animateInsuranceRef as any}>
            {policies.map((policy: any, index: number) => {
              return (
                <div key={index} className="mt-4">
                  <strong className="d-block mb-2 mt-2">
                    Policy {index + 1} Details
                  </strong>
                  <div>
                    <InputField
                      name={`insurancePolicies[${index}].policy_name`}
                      label="Policy Name / Type"
                      formik={formik}
                      placeholder="Eg: Home and contents"
                    />
                  </div>

                  <div>
                    <InputField
                      name={`insurancePolicies[${index}].insurer`}
                      label="Insurer"
                      formik={formik}
                      placeholder="Eg: AA Insurance"
                    />
                  </div>

                  <div>
                    <InputField
                      name={`insurancePolicies[${index}].excess_amount`}
                      label="Excess Amount ($)"
                      formik={formik}
                      placeholder="Eg: 750.00"
                    />
                  </div>
                </div>
              );
            })}
          </div>

          {formik.errors.insurancePolicies &&
            typeof formik.errors.insurancePolicies === 'string' && (
              <span className="block text-red-500">
                {formik.errors.insurancePolicies}
              </span>
            )}

          <div className="flex justify-between items-center mt-2">
            {formik.values.insurancePolicies &&
              formik.values.insurancePolicies.length > 0 && (
                <div>
                  <button
                    className="btn btn-sm mr-8"
                    type="button"
                    onClick={handleRemovePolicy}>
                    Remove Last Policy
                  </button>
                </div>
              )}

            <div>
              <button
                className="btn btn-sm"
                type="button"
                onClick={handleAddPolicy}>
                Add A Policy
              </button>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const ResultCard = ({formik}: {formik: any}) => {
  const renderError = (key: any, index: number) => {
    const error = formik.errors[key];
    return <p>{error}</p>;
  };

  if (formik.isSubmitting) {
    return (
      <Card>
        <CardBody>
          <CardTitle>Saving</CardTitle>
          <LoadingView />
        </CardBody>
      </Card>
    );
  } else {
    return (
      <Card className="mt-3">
        <CardBody>
          <CardTitle>There were some problems</CardTitle>
          <div className="mt-2">
            {Object.keys(formik.errors).map(renderError)}
          </div>
        </CardBody>
      </Card>
    );
  }
};

export const NewPropertyForm = ({submitHandler}: {submitHandler: any}) => {
  const STEP_STORAGE_KEY = 'new_property_form_step';
  const [step, setStep] = useState(
    Number(localStorage.getItem(STEP_STORAGE_KEY) || '0'),
  );

  useEffect(() => {
    if (step < 6) {
      localStorage.setItem(STEP_STORAGE_KEY, step.toString());
    }
  }, [step]);

  const componentForStep = (step: number, formik: any) => {
    switch (step) {
      case 0:
        return <WelcomeCard formik={formik} />;
      case 1:
        return <AddressCard formik={formik} />;
      case 2:
        return <PropertyInformationCard formik={formik} />;
      case 3:
        return <RequirementsCard formik={formik} isNew={true} />;
      case 4:
        return <ChattelsCard formik={formik} showCard={true} />;
      case 5:
        return <InsuranceCard formik={formik} />;
      case 6:
        return <ResultCard formik={formik} />;

      default:
        return null;
    }
  };

  const handleBackPress = () => {
    setStep(step - 1);
  };

  const handleSubmit = async (formData: any, actions: any) => {
    if (step === 5) {
      setStep(step + 1);
      await submitHandler(formData, actions);
    } else if (step === 4) {
      const newChattels = {};
      for (const key in formData.chattels) {
        const val = formData.chattels[key];
        if (val > 0) {
          newChattels[key] = val;
        }
      }

      actions.setFieldValue('chattels', newChattels);
      actions.setSubmitting(false);
      setStep(step + 1);
    } else if (step === 6) {
      submitHandler(formData, actions);
    } else {
      setStep(step + 1);
      actions.setSubmitting(false);
    }
  };

  const textForButton = () => {
    switch (step) {
      case 0:
        return 'Agree & Continue';
      case 5:
        return 'Finish';
      case 6:
        return 'Try Again';

      default:
        return 'Next';
    }
  };

  const schemaForStep = () => {
    switch (step) {
      case 0:
        return null;
      case 1:
        return Yup.object({
          streetAddress: Yup.string()
            .required('Required')
            .label('Street Address'),
          suburb: Yup.string().required('Required').label('Suburb'),
          city: Yup.string().required('Required').label('City'),
          postcode: Yup.string()
            .required('Required')
            .length(4)
            .label('Post Code'),
          addressMeta: Yup.object(),
        });
      case 2:
        return Yup.object({
          bathrooms: Yup.number().min(1).max(10).required().label('Bathrooms'),
          bedrooms: Yup.number().min(0).max(12).required().label('Bedrooms'),
          garages: Yup.number().min(0).max(12).required().label('Garages'),
          maxTenants: Yup.number()
            .min(1)
            .max(20)
            .required()
            .label('Max Number of Tenants'),
          propertyType: Yup.string()
            .oneOf(PROPERTY_TYPES)
            .required()
            .label('Property Type'),
          mainImage: Yup.string().min(1).label('Main Image'),
        });
      case 3:
        return Yup.object({
          petsAllowed: Yup.boolean().required().label('Pets Allowed?'),
          smokersAllowed: Yup.boolean().required().label('Smokers Allowed?'),
          rentersInsuranceRequired: Yup.boolean()
            .required()
            .label('Renters Insurance Required?'),

          inspectionPeriod: Yup.number()
            .min(1)
            .max(6)
            .required()
            .label('Inspection Period'),
          allowedPetTypes: Yup.array()
            .optional()
            .nullable()
            .label('Allowed Pet Types')
            .min(0),
          authorityToFixLimit: Yup.number()
            .required()
            .min(0)
            .max(100000)
            .label('Authority to fix limit'),
        });
      case 4:
        return Yup.object({
          chattels: Yup.object().required().label('Chattels'),
          courtesyChattels: Yup.object().optional().label('Courtesy Chattels'),
        });
      case 5:
        return Yup.object({
          insuranceCoversTenants: Yup.boolean().label(
            'Insurance Covers Tenants',
          ),
          insurancePolicies: Yup.array()
            .of(
              Yup.object().shape({
                policy_name: Yup.string()
                  .required()
                  .min(3)
                  .max(40)
                  .label('Policy Name'),
                insurer: Yup.string()
                  .required()
                  .min(2)
                  .max(40)
                  .label('Insurer'),
                excess_amount: Yup.number()
                  .min(1)
                  .required()
                  .label('Excess Amount'),
              }),
            )
            .min(0)
            .label('Insurance Policies')
            .optional(),
        });

      default:
        return null;
    }
  };

  const progressCounter = () => {
    const steps = [
      'Terms',
      'Address',
      'Property Info',
      'Requirements',
      'Chattels',
      'Insurance',
      'Final',
    ];

    return (
      <ul className="steps steps-vertical w-full">
        {steps.map((name, index) => (
          <li
            key={index}
            data-content={step > index ? '✓' : (index + 1).toString()}
            className={step >= index ? 'step step-success' : 'step'}>
            {name}
          </li>
        ))}
      </ul>
    );
  };

  const [parent] = useAutoAnimate();

  return (
    <Formik
      initialValues={{
        address: '',
        streetAddress: '',
        suburb: '',
        city: '',
        country: 'New Zealand',
        postcode: '',
        bathrooms: '',
        bedrooms: '',
        maxTenants: '',
        garages: '',
        propertyType: PROPERTY_TYPES[1],

        petsAllowed: false,
        smokersAllowed: false,
        rentersInsuranceRequired: false,

        allowedPetTypes: [],

        inspectionPeriod: '3',

        chattels: {Fridge: 0, Oven: 0, Heater: 0, 'Heat Pump': 0},
        courtesyChattels: {},

        authorityToFixLimit: 0,

        mainImage: '',

        insuranceCoversTenants: false,
        insurancePolicies: [] as InsurancePolicy[],

        addressMeta: {},
      }}
      onSubmit={handleSubmit}
      validationSchema={schemaForStep()}
      validateOnBlur={false}
      validateOnChange={false}>
      {(formik) => (
        <Form autoComplete="off" autoCorrect="off">
          <div className="flex justify-between">
            <div className="w-[300px]">
              <Card className="mr-4 mt-3">
                <CardBody>{progressCounter()}</CardBody>
              </Card>
            </div>
            <div className="w-full" ref={parent as any}>
              {componentForStep(step, formik)}
              <div className="flex justify-between mt-6">
                <div>
                  <button
                    className="btn btn-secondary"
                    onClick={handleBackPress}
                    disabled={step === 0}
                    type="button">
                    Back
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-primary mt-0"
                    type="button"
                    onClick={formik.submitForm}>
                    {formik.isSubmitting ? 'Loading' : textForButton()}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <PersistFormikValues name="new-property-form" persistInvalid={true} />
        </Form>
      )}
    </Formik>
  );
};
