import React, {useState} from 'react';

import {ChevronDownIcon} from '@heroicons/react/outline';
import {Formik} from 'formik';
import {useQueryClient} from 'react-query';
import {useParams} from 'react-router';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import {InputField, TextareaField} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import PseudoBooleanDisplayView from 'components/rental_application/PseudoBooleanDisplayView';
import ListingRentalApplication from 'models/listings/ListingRentalApplication';

const RentalApplicationSummaryView = ({
  data,
}: {
  data: ListingRentalApplication;
}) => {
  const rentalApplication = data.rentalApplication;

  const [mutationLoading, setMutationLoading] = useState(false);

  const {listingId} = useParams();
  const queryClient = useQueryClient();

  const setTag = async (tag: 'shortlisted' | 'excluded') => {
    setMutationLoading(true);
    const app = data;
    app.landlordTag = tag;

    const result = await app.save();
    if (result) {
      queryClient.invalidateQueries(['listing-applications', listingId]);
    }
    setMutationLoading(false);
  };

  return (
    <div className="">
      <div className="mx-16 mb-16">
        <div className="flex justify-between items-center">
          <div>
            <CardTitle className="mt-2">Quick View</CardTitle>
            <p>Click the table row above to view the full application.</p>
          </div>
          <div className="dropdown dropdown-end">
            <label tabIndex={0} className="btn btn-neutral btn-sm m-1">
              {mutationLoading ? (
                'Updating'
              ) : (
                <>
                  Actions
                  <ChevronDownIcon className="ml-2 text-white w-5 h-5" />
                </>
              )}
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
              <li>
                <a onClick={() => setTag('shortlisted')}>Shortlist</a>
              </li>
              <li>
                <a onClick={() => setTag('excluded')}>Exclude</a>
              </li>
            </ul>
          </div>
        </div>

        <Formik
          initialValues={rentalApplication}
          onSubmit={() => {}}
          validateOnBlur={false}
          validateOnChange={false}>
          {(formik) => (
            <div>
              <Card className="mt-4">
                <CardBody>
                  <CardTitle className="mt-2">Employment Information</CardTitle>

                  <FormRow>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="occupation"
                        label="Occupation"
                        disabled
                      />
                    </FormRowItem>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="weeklyIncome"
                        label="Weekly Income Before Tax ($)"
                        type="number"
                        disabled
                      />
                    </FormRowItem>
                  </FormRow>

                  <FormRow>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="employer"
                        label="Employer"
                        disabled
                      />
                    </FormRowItem>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="yearsEmployedAtCurrentPlace"
                        label="Years employed there"
                        disabled
                      />
                    </FormRowItem>
                  </FormRow>

                  <div className="w-1/2">
                    <PseudoBooleanDisplayView
                      label="Are you currently employed full time?"
                      value={formik.values.headTenantEmployedFullTime}
                    />
                  </div>

                  <CardTitle className="mt-2">
                    Current Living Situation
                  </CardTitle>
                  <FormRow>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="currentAddress"
                        label="What is your current address?"
                        disabled
                      />
                    </FormRowItem>
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="currentAddressStayLength"
                        label="How long have you stayed there?"
                        disabled
                      />
                    </FormRowItem>
                  </FormRow>

                  <TextareaField
                    formik={formik}
                    name="currentAddressReasonLeaving"
                    label="What is your reason for leaving?"
                    disabled
                  />
                </CardBody>
              </Card>

              <Card className="mt-4">
                <CardBody>
                  <CardTitle>About the tenants</CardTitle>

                  <div>
                    <PseudoBooleanDisplayView
                      value={formik.values.anySmokers}
                      label="Are any of the occupants smokers?"
                    />
                  </div>

                  <div>
                    <PseudoBooleanDisplayView
                      value={formik.values.involvedWithTenancyTribunal}
                      label="Have any of the tenants been involved with the tenancy tribunal?"
                    />
                    {formik.values.involvedWithTenancyTribunal === 'Yes' ? (
                      <TextareaField
                        formik={formik}
                        name="tenancyTribunalInformation"
                        label="Further information"
                        disabled
                      />
                    ) : null}
                  </div>

                  <div>
                    <PseudoBooleanDisplayView
                      value={formik.values.pastBondDeductions}
                      label="Have you ever had money deducted from your bond?"
                    />
                  </div>

                  <div>
                    <PseudoBooleanDisplayView
                      value={formik.values.oweMoneyToLandlord}
                      label="Does any proposed occupant owe money to a landlord or property manager?"
                    />
                  </div>

                  <div>
                    <PseudoBooleanDisplayView
                      value={formik.values.hasMissedRentPayments}
                      label="Has any proposed occupant every missed a rent payment?"
                    />
                  </div>

                  <div>
                    <PseudoBooleanDisplayView
                      value={formik.values.canPayBondAndAdvance}
                      label="If accepted, can you pay 2 weeks rent in advance, plus 4 weeks rent for the bond immediately upon acceptance?"
                    />
                  </div>

                  <div>
                    <PseudoBooleanDisplayView
                      value={formik.values.wouldLikeHelpWithUtilities}
                      label="Would you like help setting up utilities? I.e., internet, power, gas, etc"
                    />
                  </div>

                  <div>
                    <PseudoBooleanDisplayView
                      value={formik.values.anyBadCredit}
                      label="Does any proposed tenant have bad credit?"
                    />
                  </div>

                  <div>
                    <PseudoBooleanDisplayView
                      value={formik.values.anyCriminalRecords}
                      label="Does any proposed tenant have a criminal record?"
                    />
                  </div>
                </CardBody>
              </Card>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RentalApplicationSummaryView;
