import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import ServiceRequest from 'models/service_requests/ServiceRequest';

@Model()
class ServiceRequestQuote extends ApplicationRecord {
  static jsonapiType = 'service_request_quotes';

  @Attr({persist: false}) companyName: string;
  @Attr({persist: false}) companyPhoneNumber: string;

  @Attr({persist: false}) expectedDate: string;
  @Attr({persist: false}) quotedAmount: number;

  @Attr({persist: false}) timeframe: string;

  @Attr({persist: false}) notes: string;

  @Attr({persist: false}) serviceRequestId: string;

  @Attr() accepted: boolean;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('service_requests') serviceRequest: ServiceRequest;
}

export default ServiceRequestQuote;
