import {Formik, type FormikHelpers, Form} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import {InputField, SubmitButton} from 'components/forms_fields';
import SignatureSignatureModalInputModalField from 'components/forms_fields/SignatureModalInput';
import SignatureModalInput from 'components/forms_fields/SignatureModalInput';
import PageWrapper from 'components/PageWrapper';
import Property from 'models/properties/Property';
import PropertyTransfer from 'models/properties/PropertyTransfer';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

interface FormValues {
  receivingLandlordEmail: string;
  propertyId: string;
}

const NewPropertyTransferPage = () => {
  useTitle('New Property Transfer');
  usePageVisit('NewPropertyTransferPage');

  const {propertyId} = useParams();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // This is just to make sure the landlord has permission to access this page.
  const {data, isLoading, error} = useQuery(
    `landlord-property-new-transfer-${propertyId}`,
    async () => {
      const property = await Property.select({
        properties: ['id'],
        tenancies: ['id'],
      })
        .includes('current_tenancy')
        .find(propertyId);

      return property.data;
    },
  );

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    const transfer = new PropertyTransfer(values);

    const result = await transfer.save();

    if (result) {
      toast.success(
        'Property transfer has started, please check your email for the confirmation email.',
      );

      await queryClient.invalidateQueries(
        `landlord-property-detail-${propertyId}`,
      );

      navigate(`/properties/${propertyId}`);
    } else {
      for (const key of Object.keys(transfer.errors)) {
        const error = transfer.errors[key];

        actions.setFieldError(key, error.message);
        if (key === 'receivingUser') {
          actions.setFieldError(
            'receivingLandlordEmail',
            'There is no Keyhook landlord with this email',
          );
        }
      }
    }

    actions.setSubmitting(false);
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Property Transfer">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Property Transfer">
        <Card className="mt-4">
          <CardBody>
            <CardTitle>Transfer your property</CardTitle>
            <p>
              To transfer your property to another Keyhook user, please enter
              their email below. Please note the following:
            </p>

            <ul className="list-disc list-inside mt-2">
              <li className="text-sm">
                The other user will need to have already signed up for a Keyhook
                landlord account
              </li>
              <li className="text-sm">
                We will send you an email to confirm this action.
              </li>
              <li className="text-sm">
                After the other user has accepted the transfer, the property
                will immediately be moved from your account to theirs.
              </li>
              <li className="text-sm">
                If this property has an active tenancy, we will file the{' '}
                <a
                  href="https://www.tenancy.govt.nz/assets/forms-templates/change-of-landlord-agent-form.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="link link-primary">
                  change of landlord paperwork
                </a>{' '}
                with Tenancy Services for you.
              </li>
            </ul>

            <Formik
              initialValues={{
                receivingLandlordEmail: '',
                senderSignature: '',
                propertyId: propertyId,
              }}
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                receivingLandlordEmail: Yup.string()
                  .email()
                  .required()
                  .label('New Owners email'),
                senderSignature: Yup.string()
                  .required()
                  .min(10)
                  .label('Your signature'),
                propertyId: Yup.string().required(),
              })}>
              {(formik) => (
                <Form className="w-50">
                  <div className="mt-2">
                    <InputField
                      name="receivingLandlordEmail"
                      formik={formik}
                      label="New Owners Email"
                      type="email"
                      placeholder="Eg: example@gmail.com"
                    />

                    <div className="mt-3">
                      <SignatureModalInput
                        mode="formik"
                        name="senderSignature"
                      />
                    </div>

                    <div className="mt-4">
                      <SubmitButton
                        formik={formik}
                        text="Transfer Property"
                        submittingText="Saving..."
                        block={false}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </PageWrapper>
    );
  }
};

export default NewPropertyTransferPage;
