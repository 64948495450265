interface EmptyDataSetProps {
  title: string;
  message: string;
  image: any;
  buttonTitle?: string;
  buttonAction?: () => void;
}

const EmptyDataSet = ({
  title,
  message,
  image,
  buttonTitle,
  buttonAction,
}: EmptyDataSetProps) => {
  return (
    <div className="flex justify-center mt-3">
      <div>
        <div className="flex justify-center">
          <img src={image} style={{width: 'auto', height: 200}} />
        </div>

        <div className="flex justify-center">
          <h5 className="text-2xl font-semibold mt-4 mb-0">{title}</h5>
        </div>
        <div className="flex justify-center">
          <p className="mt-1">{message}</p>
        </div>

        {buttonTitle && (
          <div className="flex justify-center">
            <button
              className="btn btn-primary mt-4"
              type="button"
              onClick={buttonAction}>
              {buttonTitle}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmptyDataSet;
