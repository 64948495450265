import {useEffect, useState} from 'react';

import clsx from 'clsx';
import {type FormikHelpers, Formik, Form, type FormikProps} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import InlineMoneyField from 'components/forms_fields/InlineMoneyField';
import ToggleButtonGroup from 'components/forms_fields/ToggleButtonGroup';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightFooter from 'components/walkthrough/common/RightFooter';
import RightSideText from 'components/walkthrough/common/RightSideText';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import TrackingService from 'services/TrackingService';

const rentalPeriods = ['Weekly', 'Fortnightly'];

type FormValues = {
  totalRent: string;
  bond: number;
  rentalPeriod: 'Weekly' | 'Fortnightly';
  weeksRentInAdvance: number;
};

const FinancialsStep = ({
  property,
  stepNumber,
  totalSteps,
}: {
  property: Property;
  stepNumber: number;
  totalSteps: number;
}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_StartRentDetailsStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const tenancy = property.tenancies[0];
  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    setIsLoading(true);

    const t = tenancy || new Tenancy();
    t.status = 'draft';
    t.isNew = true;
    t.assignAttributes(formValues);
    t.propertyId = property.id;

    const result = await t.save();

    if (result) {
      property.lastOnboardingStepCompleted = 'new_financials';
      property.markStepAsCompleted('property_documents');
      property.markStepAsCompleted('tenancy_information');
      await property.save();

      if (property.tenancies.length === 0) {
        property.tenancies = [t];
      } else {
        property.tenancies.unshift(t);
      }

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.NewTenancy_CompleteRentDetailsStep,
        {
          propertyId: property.id,
        },
      );

      setIsLoading(false);
      queryClient.setQueryData(['new-property', property.id], property);
    } else {
      console.log(t.errors);
      for (const key of Object.keys(t.errors)) {
        const message = t.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }
  };

  const previousStep = async () => {
    property.lastOnboardingStepCompleted = 'new_property_type';
    await property.save();
    queryClient.setQueryData(['new-property', property.id], property);
  };

  const quickSetBond = (
    formik: FormikProps<FormValues>,
    numberOfWeeks: number,
  ) => {
    if (formik.values.totalRent && !!formik.values.totalRent) {
      let rentAmount: number;
      if (formik.values.rentalPeriod === 'Weekly') {
        rentAmount = Number(formik.values.totalRent);
      } else {
        rentAmount = Number(formik.values.totalRent) / 2.0;
      }
      const bondAmount = rentAmount * numberOfWeeks;
      formik.setFieldValue('bond', bondAmount);

      TrackingService.trackEvent(TrackingService.Event.QuickSetBond, {
        numberOfWeeks,
      });
    }
  };

  const title = 'Next up, rent information...';

  return (
    <Container>
      <LeftSide title={title} subtitle="" />
      <RightContainer>
        <RightSideText title={title} />
        <Formik
          initialValues={
            {
              totalRent: tenancy ? tenancy.totalRent : '0',
              bond: tenancy ? tenancy.bond : '0',
              rentalPeriod: tenancy ? tenancy.rentalPeriod : 'Weekly',
              weeksRentInAdvance: tenancy ? tenancy.weeksRentInAdvance : '0',
            } as FormValues
          }
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            bond: Yup.number()
              .min(0)
              .required()
              .nullable()
              .label('Bond')
              .test(
                'max-bond-is-allowed',
                'Bond can not be greater than 4 weeks rent',
                function (value) {
                  if (!value || value == 0) {
                    return true;
                  }
                  const rentalPeriod = this.parent.rentalPeriod;
                  const rent = this.parent.totalRent;
                  if (rentalPeriod === 'Weekly') {
                    return value <= rent * 4;
                  } else if (rentalPeriod === 'Fortnightly') {
                    return value <= rent * 2;
                  } else {
                    return true;
                  }
                },
              ),
            totalRent: Yup.number().min(1).required().label('Rent'),
            rentalPeriod: Yup.string()
              .oneOf(rentalPeriods)
              .required()
              .label('Rental Period'),
            weeksRentInAdvance: Yup.number()
              .min(0)
              .max(2)
              .required()
              .label('Rent in advance'),
          })}>
          {(formik) => (
            <Form>
              <div className="flex flex-col flex-1 md:mx-16 space-y-8">
                <InlineMoneyField
                  formik={formik}
                  name="totalRent"
                  label="Rent amount"
                />

                <ToggleButtonGroup
                  formik={formik}
                  name="rentalPeriod"
                  label="Frequency"
                  options={[
                    {value: 'Weekly', label: 'Weekly'},
                    {value: 'Fortnightly', label: 'Fortnightly'},
                  ]}
                />

                <div>
                  <InlineMoneyField
                    formik={formik}
                    name="bond"
                    label={
                      <div>
                        <span>Bond amount</span>
                        <div className="dropdown dropdown-start">
                          <label
                            tabIndex={0}
                            className={clsx(
                              'btn btn-sm',
                              (!formik.values.totalRent ||
                                formik.values.totalRent === '' ||
                                formik.values.totalRent === '0') &&
                                'btn-disabled',
                            )}>
                            Quick Set
                          </label>
                          <ul
                            tabIndex={0}
                            className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                            {[1, 2, 3, 4].map((numWeeks) => (
                              <li key={numWeeks}>
                                <a
                                  className="!text-sm"
                                  onClick={() =>
                                    quickSetBond(formik, numWeeks)
                                  }>
                                  {numWeeks} Weeks Rent
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    }
                  />
                </div>

                <ToggleButtonGroup
                  formik={formik}
                  name="weeksRentInAdvance"
                  label="Rent in advance"
                  options={[
                    {value: 0, label: 'None'},
                    {value: 1, label: '1 Week'},
                    {value: 2, label: '2 Weeks'},
                  ]}
                />
              </div>

              <ProgressBox
                nextStep={formik.submitForm}
                previousStep={previousStep}
                step={stepNumber}
                totalSteps={totalSteps}
                previousStepEnabled
                nextStepEnabled={!isLoading}
              />
            </Form>
          )}
        </Formik>
      </RightContainer>
    </Container>
  );
};

export default FinancialsStep;
