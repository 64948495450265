import React, {useEffect, useState} from 'react';

import {Capacitor} from '@capacitor/core';
import {CalendarIcon} from '@heroicons/react/outline';
import {ShareIcon} from '@heroicons/react/solid';
import {BiBath} from '@react-icons/all-files/bi/BiBath';
import {BiBed} from '@react-icons/all-files/bi/BiBed';
import {BiCar} from '@react-icons/all-files/bi/BiCar';
import {FaUsers} from '@react-icons/all-files/fa/FaUsers';
import {MdPets} from '@react-icons/all-files/md/MdPets';
import L, {LatLngTuple} from 'leaflet';
import moment from 'moment';
import {
  AttributionControl,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
} from 'react-leaflet';
import {useQuery, useQueryClient} from 'react-query';
import {useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';

import ModalPhotos from 'components/chifis_theme/ModalPhotos';
import NcImage from 'components/chifis_theme/NcImage';
import LoadingView from 'components/common/LoadingView';
import ListingSidebar from 'components/listing/ListingSidebar';
import ShareListingModal from 'components/listing/ShareListingModal';
import PageWrapper from 'components/PageWrapper';
import {Modal} from 'components_sb/layout';
import {Paragraph, Title} from 'components_sb/typography';
import {API_URL} from 'globals/app-globals';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import Listing from 'models/listings/Listing';
import OpenHomeAttendee from 'models/listings/OpenHomeAttendee';
import useAuth from 'services/useAuth';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';
import {removeUnderscores, titleize} from 'utilities/StringHelpers';

const {useModal} = Modal.Imperative;

const pluralize = (num: number, str: string) => {
  if (num <= 1) {
    return str;
  } else {
    return `${str}s`;
  }
};

const ListingDetailPage = () => {
  usePageVisit('ListingDetailPage');

  const {id} = useParams();
  const queryClient = useQueryClient();

  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);

  const {userIsLoggedIn, currentUser} = useAuth();

  const isMobile = !useTailwindBreakpoint('sm');

  const openModal = useModal();

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const {data, error, isLoading} = useQuery(`listing-${id}`, async () => {
    const listing = await Listing.includes(['listing_photos', 'open_homes'])
      .selectExtra(['landlord_id'])
      .order({'open_homes.start_time': 'asc'})
      .order({'listing_photos.order_index': 'asc'})
      .find(id);

    return listing.data;
  });

  const {data: openHomeAttendees, isLoading: openHomeAttendeesIsLoading} =
    useQuery(
      `listing-${id}-open-home-attendees`,
      async () => {
        const ohas = await OpenHomeAttendee.where({
          open_home_id: data.openHomes.map((oh) => oh.id),
        }).all();

        const map = {} as Record<string, OpenHomeAttendee>;
        if (ohas.data) {
          for (const oha of ohas.data) {
            map[oha.openHomeId] = oha;
          }
        }

        return map;
      },
      {
        enabled:
          userIsLoggedIn && !isLoading && data && data.openHomes.length > 0,
      },
    );

  useTitle(data ? data.streetAddress : '');

  useEffect(() => {
    if (data && data.id) {
      try {
        fetch(`${API_URL}/listings/${data.id}/view.json`, {method: 'PATCH'});
      } catch (e) {
        console.log(e);
      }
    }
  }, [data]);

  const renderMainInfoCard = () => {
    const showShareModal = () => openModal(ShareListingModal, {listing: data});

    return (
      <div className="listingSection__wrap">
        <div className="flex justify-between items-center">
          <div className="badge badge-primary p-4 text-white">
            {data.propertyType}
          </div>
          <div className="flow-root">
            <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
              <span
                className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer"
                onClick={showShareModal}>
                <ShareIcon className="w-5 h-5" />
                <span className="hidden sm:block ml-2.5">Share</span>
              </span>
              {/* <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
                <MdBookmark className="w-5 h-5" />
                <span className="hidden sm:block ml-2.5">Save</span>
              </span> */}
            </div>
          </div>
        </div>

        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {data.listingTitle} <br />
          <span className="ml-1 text-secondary text-sm">
            {data.suburb}, {data.city}
          </span>
        </h2>

        <div className="flex justify-start flex-col md:flex-row md:gap-10">
          <div>
            <span className="text-secondary text-sm">Rent</span>
            <h6 className="text-primary text-xl font-semibold">
              ${Number(data.rentAmount).toFixed(0)}{' '}
              <span className="text-sm">/ week</span>
            </h6>
          </div>

          {data.bondAmount && (
            <div>
              <span className="text-secondary text-sm">Bond</span>
              <h6 className="text-primary text-xl font-semibold">
                ${Number(data.bondAmount).toFixed(0)}
              </h6>
            </div>
          )}

          <div>
            <span className="text-secondary text-sm">Available From</span>
            <h6 className="text-primary text-xl font-semibold">
              {moment(data.startDate).format('DD MMM YYYY')}
            </h6>
          </div>

          {data.endDate && (
            <div>
              <span className="text-secondary text-sm">Lease Until</span>
              <h6 className="text-primary text-xl font-semibold">
                {moment(data.endDate).format('DD MMM YYYY')}
              </h6>
            </div>
          )}
        </div>

        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        <div className="flex items-center flex-row justify-start space-x-4 sm:space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-2">
            <BiBed className="w-5 h-5" />
            <span className=" ">
              {data.bedrooms}{' '}
              <span className="inline-block">
                {isMobile
                  ? pluralize(data.bedrooms, 'Bed')
                  : pluralize(data.bedrooms, 'Bedroom')}
              </span>
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <BiBath className="w-5 h-5" />
            <span className=" ">
              {data.bathrooms}{' '}
              <span className="inline-block">
                {isMobile
                  ? pluralize(data.bathrooms, 'Bath')
                  : pluralize(data.bathrooms, 'Bathroom')}
              </span>
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <BiCar className="w-5 h-5" />
            <span className="">
              {data.garages}{' '}
              <span className="inline-block">
                {pluralize(data.garages, 'Garage')}
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderDescriptionCard = () => {
    return (
      <div className="listingSection__wrap">
        <Title level="h2">Description</Title>
        <Paragraph>{data.description}</Paragraph>
        <Title level="h3">Tags</Title>
        <div className="flex flex-wrap justify-start !mb-0 !mt-0">
          {data.tags.map((tag) => (
            <div
              key={tag}
              className="flex justify-center badge badge-accent p-4 mr-4 mt-4 text-white">
              {titleize(removeUnderscores(tag))}
            </div>
          ))}
        </div>

        {data.chattels && Object.keys(data.chattels).length > 0 && (
          <div>
            <Title level="h3">Chattels</Title>
            <div className="flex flex-wrap justify-start !mt-0">
              {Object.entries(data.chattels).map(([chattelName, count]) => (
                <div
                  key={chattelName}
                  className="flex justify-center badge bg-indigo-500 border-indigo-500 p-4 mr-4 mt-4 text-white">
                  {chattelName} x{count}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderRestrictionsCard = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <Title level="h2">Restrictions </Title>
          <Paragraph secondary>
            Any requirements for renting this property
          </Paragraph>
        </div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <div className="flex items-center">
                <MdPets className="w-5 h-5 inline-block" />
                <span className="ml-2 inline-block">
                  Pets Allowed?
                  {data.allowedPetTypes.length > 0 && (
                    <div className="text-secondary text-xs">
                      {data.allowedPetTypes
                        .map((pet) => titleize(removeUnderscores(pet)))
                        .join(', ')}
                    </div>
                  )}
                </span>
              </div>

              <span>{data.petsAllowed ? 'Yes' : 'No'}</span>
            </div>

            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <p className="flex items-center">
                <FaUsers className="w-5 h-5 inline-block" />
                <span className="ml-2 inline-block">
                  Maxium Number of Tenants
                </span>
              </p>
              <span>{data.maxTenants}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderLocationCard = () => {
    const markerIcon = new L.Icon({
      iconUrl: require('assets/img/leaflet/marker-icon.png'),
      iconRetinaUrl: require('assets/img/leaflet/marker-icon-2x.png'),
      iconSize: [48, 72],
      iconAnchor: [24, 36],
    });

    if (data.latitude && data.longitude) {
      const coord = [
        parseFloat(data.latitude),
        parseFloat(data.longitude),
      ] as LatLngTuple;
      return (
        <div className="listingSection__wrap">
          {/* HEADING */}
          <div>
            <Title level="h2">Location</Title>
          </div>

          {/* MAP */}
          <div className="w-full h-auto">
            <div className="rounded-xl overflow-hidden">
              <MapContainer
                center={coord}
                zoom={15}
                style={{height: 400}}
                scrollWheelZoom={false}
                attributionControl={false}>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <AttributionControl position="bottomright" prefix="Leaflet" />
                <Marker position={coord} icon={markerIcon}>
                  <Popup>{data.listingTitle}</Popup>
                </Marker>
              </MapContainer>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const attendOpenHome = async (openHomeId: string) => {
    const openHome = data.openHomes.find((oh) => oh.id === openHomeId);

    if (openHome) {
      const oha = new OpenHomeAttendee({openHomeId: openHomeId});

      const result = await oha.save();

      if (result) {
        toast.success("You have successfully RSVP'd to this open home!");
        const attendees = openHomeAttendees;
        attendees[openHomeId] = oha;
        queryClient.setQueryData(
          `listing-${id}-open-home-attendees`,
          attendees,
        );
      } else {
        toast.error('There was an errror trying to RSVP to this open home.');
      }
    }
  };

  const unattendOpenHome = async (openHomeId: string) => {
    const oha = openHomeAttendees[openHomeId];

    if (oha) {
      const result = await oha.destroy();

      if (result) {
        toast.success('You are no longer attending this open home.');
        const attendees = openHomeAttendees;
        delete attendees[openHomeId];
        queryClient.setQueryData(
          `listing-${id}-open-home-attendees`,
          attendees,
        );
      } else {
        toast.error('There was an errror trying to RSVP to this open home.');
      }
    }
  };

  const renderOpenHomesCard = () => {
    const filteredOpenHomes = data.openHomes.filter((oh) =>
      moment(oh.startTime).isAfter(moment()),
    );

    if (filteredOpenHomes.length > 0) {
      if (!currentUser) {
        return (
          <div className="listingSection__wrap">
            <div>
              <Title level="h2">Open Homes</Title>
              <Paragraph secondary>
                Contact the Landlord to arrange a private viewing.
              </Paragraph>
            </div>

            <Paragraph>
              Please login to see the open homes for this rental.
            </Paragraph>
          </div>
        );
      }

      if (openHomeAttendeesIsLoading) {
        return (
          <div className="listingSection__wrap">
            <div>
              <Title level="h2">Open Homes</Title>
              <Paragraph>
                Contact the Landlord to arrange a private viewing.
              </Paragraph>
            </div>
          </div>
        );
      }

      const attendees = openHomeAttendees || {};

      return (
        <div className="listingSection__wrap">
          <div>
            <Title level="h2">Open Homes</Title>.
            <Paragraph secondary>
              Contact the Landlord to arrange a private viewing.
            </Paragraph>
          </div>

          <div>
            {filteredOpenHomes.map((oh) => {
              const st = moment(oh.startTime);
              const et = moment(oh.endTime);

              const isAttending = !!attendees[oh.id];
              return (
                <div key={oh.id} className="flex justify-between items-center">
                  <div className="flex justify-start items-center">
                    <div className="mr-2">
                      <CalendarIcon className="w-10 h-10 text-primary" />
                    </div>
                    <div>
                      <strong>
                        {moment(oh.startTime).format('dddd DD MMMM')}
                      </strong>
                      <p className="text-gray-500">
                        {st.format('hh:mm a')} - {et.format('hh:mm a')}
                      </p>
                    </div>
                  </div>

                  <div>
                    {isAttending ? (
                      <button
                        className="btn btn-sm btn-neutral"
                        onClick={() => unattendOpenHome(oh.id)}>
                        Unattend
                      </button>
                    ) : (
                      <button
                        className="btn btn-sm btn-neutral"
                        onClick={() => attendOpenHome(oh.id)}>
                        Attend
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      return (
        <div className="listingSection__wrap">
          <div>
            <Title level="h2">Open Homes</Title>
            <Paragraph secondary>
              Contact the Landlord to arrange a private viewing.
            </Paragraph>
          </div>
          <Paragraph>
            There are no current open homes for this rental.
          </Paragraph>
        </div>
      );
    }
  };

  const renderPhotos = () => {
    const photos = data.listingPhotos
      .sort((photo) => photo.orderIndex)
      .map((photo) => photo.photo);

    if (photos.length === 1) {
      return (
        <>
          <header className="container rounded-md sm:rounded-xl">
            <div
              className="flex justify-start rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}>
              <NcImage
                className="object-cover w-full h-auto rounded-md sm:rounded-xl max-h-[400px]"
                src={photos[0]}
                prevImageHorizontal
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
          </header>
          <ModalPhotos
            imgs={photos}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
          />
        </>
      );
    } else {
      return (
        <>
          <header className="container rounded-md sm:rounded-xl">
            <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
              <div
                className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                onClick={() => handleOpenModal(0)}>
                <NcImage
                  containerClassName="absolute inset-0"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl"
                  src={photos[0]}
                  prevImageHorizontal
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>
              {photos
                .filter((_, i) => i >= 1 && i < 5)
                .map((photo, index) => (
                  <div
                    key={index}
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                      index >= 3 ? 'hidden sm:block' : ''
                    }`}>
                    <NcImage
                      containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                      className="object-cover w-full h-full rounded-md sm:rounded-xl "
                      src={photo}
                      prevImageHorizontal
                    />

                    {/* OVERLAY */}
                    <div
                      className="absolute inset-0 bg-neutral-500 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                      onClick={() => handleOpenModal(index + 1)}
                    />
                  </div>
                ))}

              <div
                className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-white text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                onClick={() => handleOpenModal(0)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                  />
                </svg>
                <span className="ml-2 text-neutral text-sm font-medium">
                  Show all photos
                </span>
              </div>
            </div>
          </header>
          <ModalPhotos
            imgs={photos}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
          />
        </>
      );
    }
  };

  const renderLandlordMangeAlert = () => {
    if (currentUser) {
      if (
        data &&
        data.landlordId === currentUser.id &&
        data.status !== 'draft'
      ) {
        return (
          <div className="container mt-4">
            <Link
              to={`/properties/${data.propertyId}/listings/${data.publicId}`}>
              <div className="alert alert-info shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="stroke-current flex-shrink-0 w-6 h-6">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                  This is your listing, click this banner to go to the
                  management dashboard.
                </div>
              </div>
            </Link>
          </div>
        );
      }
    }

    return null;
  };

  const renderWrapper = (children: JSX.Element) => {
    if (Capacitor.isNativePlatform()) {
      return <PageWrapper title="Listing">{children}</PageWrapper>;
    } else {
      return <div>{children}</div>;
    }
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return <LoadingView />;
  } else {
    return renderWrapper(
      <>
        {renderLandlordMangeAlert()}
        {data.status === 'completed' ? (
          <div className="container mt-4">
            <div className="alert alert-info shadow-lg">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="stroke-current flex-shrink-0 w-6 h-6">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                This listing is no longer active. Tenants have already been
                found or the landlord has chosen to withdraw it.
              </div>
            </div>
          </div>
        ) : null}

        {data.status === 'pending_approval' ? (
          <div className="container mt-4">
            <div className="alert alert-info shadow-lg">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="stroke-current flex-shrink-0 w-6 h-6">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                This listing is awaiting approval from the Keyhook team. It will
                be published live once approved.
              </div>
            </div>
          </div>
        ) : null}
        <div
          className="nc-ListingStayDetailPage mt-10 mb-10"
          data-nc-id="ListingStayDetailPage">
          {renderPhotos()}

          <main className="container mt-11 flex flex-col lg:flex-row">
            {/* CONTENT */}
            <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
              {renderMainInfoCard()}
              {renderDescriptionCard()}
              {renderRestrictionsCard()}
              {renderLocationCard()}
              {renderOpenHomesCard()}
            </div>

            {/* SIDEBAR */}
            <div className="mt-11 lg:mt-0 block w-full lg:w-2/5 xl:w-1/3">
              <ListingSidebar listing={data} />
            </div>
          </main>
        </div>
      </>,
    );
  }
};

export default ListingDetailPage;
