import {useEffect, useState} from 'react';

import {type FormikHelpers, Formik, Form} from 'formik';
import moment from 'moment';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import filePdf from 'assets/img/file_icons/file-pdf-solid-240.png';
import {Base64FileField} from 'components/forms_fields';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightSideText from 'components/walkthrough/common/RightSideText';
import Document from 'models/properties/Document';
import Property from 'models/properties/Property';
import TrackingService from 'services/TrackingService';

type FormValues = {
  document: string;
};

const HealthyHomesStep = ({
  property,
  stepNumber,
  totalSteps,
}: {
  property: Property;
  stepNumber: number;
  totalSteps: number;
}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_StartHealthyHomesStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const existingDoc = property.documents.find(
    (d) => d.documentType === 'healthy_homes_report',
  );
  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    setIsLoading(true);

    let result = false;
    let doc: Document;

    if (existingDoc && formValues.document.length === 0) {
      result = true;
    } else if (formValues.document.length === 0) {
      actions.setFieldError('document', 'Please select a file');
    } else {
      doc = new Document(formValues);
      doc.documentableType = 'Property';
      doc.documentableId = property.id;
      doc.documentType = 'healthy_homes_report';

      result = await doc.save();
    }

    if (result) {
      property.lastOnboardingStepCompleted = 'new_healthy_homes';
      await property.save();

      if (doc) {
        if (property.documents.findIndex((d) => d.id === doc.id) === -1) {
          property.documents.push(doc);
        }
      }

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.NewTenancy_CompleteHealthyHomesStep,
        {
          propertyId: property.id,
        },
      );

      queryClient.setQueryData(['new-property', property.id], property);
    }

    setIsLoading(false);
  };

  const previousStep = async () => {
    property.lastOnboardingStepCompleted = 'new_lease_conditions';
    await property.save();

    queryClient.setQueryData(['new-property', property.id], property);
  };

  const title = 'Healthy Homes compliance';
  const subtitle = 'Upload your Healthy Homes compliance report.';

  return (
    <Container>
      <LeftSide title={title} subtitle={subtitle} />
      <RightContainer>
        <RightSideText title={title} />
        <Formik
          initialValues={{document: ''} as FormValues}
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            document: Yup.string().label('Healthy Homes File'),
          })}>
          {(formik) => (
            <Form>
              <div className="flex flex-col md:mx-16 space-y-8">
                {existingDoc && (
                  <div className="flex justify-start items-center gap-2">
                    <img
                      src={filePdf}
                      alt={existingDoc.name}
                      className="w-12 h-12"
                    />
                    <div>
                      <strong>{existingDoc.name}</strong>
                      <p className="text-sm text-secondary">
                        Uploaded {moment(existingDoc.createdAt).fromNow()}
                      </p>
                    </div>
                  </div>
                )}
                <div>
                  <Base64FileField
                    formik={formik}
                    name="document"
                    label="Healthy Homes File (PDF)"
                    accept=".pdf"
                  />
                </div>
                <p>
                  New Zealand law requires a report detailing compliance with
                  the Healthy Homes Act to be included with any new tenancy
                  agreement.
                </p>
                <p>
                  You can download a Health Homes report by{' '}
                  <a
                    href="https://www.tenancy.govt.nz/assets/forms-templates/compliance-statement.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary">
                    clicking here.
                  </a>
                </p>
              </div>
              <ProgressBox
                nextStep={formik.submitForm}
                previousStep={previousStep}
                step={stepNumber}
                totalSteps={totalSteps}
                previousStepEnabled
                nextStepEnabled={!isLoading}
              />
            </Form>
          )}
        </Formik>
      </RightContainer>
    </Container>
  );
};

export default HealthyHomesStep;
