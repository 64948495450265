import {useState} from 'react';

import {Capacitor} from '@capacitor/core';
import {Formik, Form, type FormikHelpers} from 'formik';
import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import DocumentPreviewModal from 'components/document/DocumentPreviewModal';
import {InputField, SelectField, SubmitButton} from 'components/forms_fields';
import SignatureModalInput from 'components/forms_fields/SignatureModalInput';
import PageWrapper from 'components/PageWrapper';
import {API_URL} from 'globals/app-globals';
import TenancyContinuation from 'models/properties/TenancyContinuation';
import useAuth from 'services/useAuth';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

const EditTenancyRenewalPage = () => {
  useTitle('Tenancy Renewal');
  usePageVisit('EditTenancyRenewalPage');

  const {tenancyId, id} = useParams();

  const [documentViewerVisible, setDocumentViewerVisible] = useState(false);

  const {currentUser} = useAuth();

  const navigate = useNavigate();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const {data, isLoading, error} = useQuery(
    `renter-tenancy-renewal-${id}`,
    async () => {
      const renewal = await TenancyContinuation.includes('tenancy').find(id);

      return renewal.data;
    },
  );
  const queryClient = useQueryClient();

  const confirmHandleGoPeriodic = () => {
    setConfirmationOptions({
      title: 'Go Periodic',
      message:
        'Are you sure you want to go periodic after this end date passes',
      action: handleGoPeriodic,
      color: 'success',
      buttonTitle: 'Confirm',
    });
  };

  const handleGoPeriodic = async () => {
    const renewal: TenancyContinuation = data;

    renewal.tenantChosenAction = 'go_periodic';
    const result = await renewal.save();
    if (result) {
      queryClient.setQueryData(`renter-tenancy-renewal-${id}`, renewal);
      await queryClient.invalidateQueries(`renter-tenancy-detail-${tenancyId}`);

      toast.success('You have succesfully chosen to go periodic!');
    } else {
      console.log(renewal.errors);
    }
  };

  const confirmNewLease = () => {
    setConfirmationOptions({
      title: 'Start New Lease',
      message:
        'Are you sure you want to sign the new lease? You will be shown the form to do so after confirming',
      action: handleNewLease,
      color: 'success',
      buttonTitle: 'Confirm',
    });
  };

  const handleNewLease = async () => {
    const renewal: TenancyContinuation = data;

    renewal.tenantChosenAction = 'start_new_lease';
    const result = await renewal.save();
    if (result) {
      queryClient.setQueryData(`renter-tenancy-renewal-${id}`, renewal);
      await queryClient.invalidateQueries(`renter-tenancy-detail-${tenancyId}`);

      toast.success('You have succesfully chosen to sign the new lease!');
    } else {
      console.log(renewal.errors);
    }
  };

  const signNewLease = async (formValues: any, actions: FormikHelpers<any>) => {
    const renewal: TenancyContinuation = data;

    renewal.tenantSignatures = {
      signature: formValues.signature,
      identification_type: formValues.identificationType,
      identification_number: formValues.identificationNumber,
    };

    const result = await renewal.save();
    if (result) {
      queryClient.setQueryData(`renter-tenancy-renewal-${id}`, renewal);
      await queryClient.invalidateQueries(`renter-tenancy-detail-${tenancyId}`);

      toast.success('You have succesfully signed the new lease!');
    } else {
      console.log(renewal.errors);
    }
  };

  const documentURL = `${API_URL}/tenancy_continuations/${id}/lease_preview.pdf`;
  const showLease = () => {
    if (Capacitor.isNativePlatform()) {
      navigate(`/document-previews?url=${documentURL}`);
    } else {
      setDocumentViewerVisible(true);
    }
  };

  const cardForStatus = () => {
    if (
      data.tenantChosenAction &&
      data.tenantsWhoHaveSigned.includes(currentUser.id)
    ) {
      return cardForTenantSigned();
    }

    if (!data.tenantChosenAction) {
      if (data.tenancy.headTenantId == currentUser.id) {
        return cardForOptionType();
      } else {
        return cardForRegularTenantNoAction();
      }
    }

    if (data.tenantChosenAction === 'start_new_lease') {
      return cardForNewLeaseType();
    } else if (data.tenantChosenAction === 'go_periodic') {
      return cardForTenantSigned();
    }

    return null;
  };

  const cardForTenantSigned = () => {
    if (data.tenantChosenAction === 'go_periodic') {
      return (
        <Card className="mt-4">
          <CardBody>
            <CardTitle className="mb-0">Go Periodic</CardTitle>
            <p className="mt-2">
              This tenancy will go periodic after the end date.
            </p>
          </CardBody>
        </Card>
      );
    } else {
      return (
        <Card className="mt-4">
          <CardBody>
            <CardTitle className="mb-0">Start New Lease</CardTitle>
            <p className="mt-2">
              You have signed the new lease for this tenancy.
            </p>
          </CardBody>
        </Card>
      );
    }
  };

  const cardForRegularTenantNoAction = () => {
    return (
      <Card className="mt-4">
        <CardBody>
          <CardTitle className="mb-0">Waiting For Head Tenant</CardTitle>
          <p className="mt-2">
            Keyhook is currently waiting for the head tenant to make their
            decision about how to proceed with your landlords renewal request.
          </p>
        </CardBody>
      </Card>
    );
  };

  const cardForOptionType = () => {
    if (data.landlordRequestedAction === 'go_periodic') {
      return (
        <Card className="mt-4">
          <CardBody>
            <CardTitle className="mb-0">Go Periodic</CardTitle>
            <p className="mb-2">
              Your landlord has decided they wish to go periodic, if you and
              your other tenants agree with this, then please click confirm. If
              not confirmed before the tenancy end date, this tenancy will
              automatically go periodic.
            </p>

            <div className="mt-4">
              <button
                className="btn btn-block btn-success"
                type="button"
                onClick={confirmHandleGoPeriodic}>
                Go Periodic
              </button>
            </div>
          </CardBody>
        </Card>
      );
    } else if (data.landlordRequestedAction === 'start_new_lease') {
      return (
        <Card className="mt-4">
          <CardBody>
            <CardTitle className="mb-0">Sign New Lease</CardTitle>
            <p className="mb-2">
              Your landlord has decided they wish to sign a new lease with an
              end date of {moment(data.endDate).format(DATE_FORMAT)}.
            </p>

            <div>
              <button
                className="btn btn-neutral"
                type="button"
                onClick={showLease}>
                Read Lease
              </button>
            </div>

            <DocumentPreviewModal
              isVisible={documentViewerVisible}
              setIsVisible={setDocumentViewerVisible}
              documentUrl={documentURL}
              title="Lease Preview"
            />

            <p>
              If you and your other tenants agree with this, then please read
              the new lease and sign your signature. Otherwise you can choose
              for this tenancy to go periodic after the end date.
            </p>

            {data.tenancy.isNew && (
              <p className="mt-2 font-semibold">
                Your tenancy was migrated to Keyhook and might not have all
                tenants onboard yet. Before making a decision, if the other
                members of the lease aren't currently part of this tenancy on
                Keyhook, please first invite them on and wait for them to have
                signed up. As we need them to be part of Keyhook to sign any
                agreements.{' '}
                <Link
                  to={`/tenancies/${tenancyId}/invite-tenants`}
                  className="link link-primary">
                  Invite Other Tenants
                </Link>
              </p>
            )}

            <div className="mt-4">
              <button
                className="btn btn-block btn-success"
                type="button"
                onClick={confirmNewLease}>
                Sign New Lease
              </button>
            </div>

            <div className="mt-4">
              <button
                className="btn btn-block btn-neutral"
                type="button"
                onClick={confirmHandleGoPeriodic}>
                Go Periodic
              </button>
            </div>
          </CardBody>
        </Card>
      );
    }
  };

  const cardForNewLeaseType = () => {
    const schema = Yup.object().shape({
      signature: Yup.string().required().min(1).label('Signature'),
      identificationType: Yup.string().required().label('Identification Type'),
      identificationNumber: Yup.string()
        .required()
        .min(5)
        .label('Identification Number'),
    });

    return (
      <Card className="mt-4">
        <CardBody>
          <CardTitle className="mb-0">Sign The New Lease</CardTitle>
          <p className="mb-2">
            Fill in the information below to sign the new lease for this
            tenancy.
          </p>

          <Formik
            initialValues={{
              signature: '',
              identificationType: '',
              identificationNumber: '',
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={schema}
            onSubmit={signNewLease}>
            {(formik) => {
              return (
                <Form>
                  <div>
                    <button
                      className="btn btn-neutral btn-sm"
                      type="button"
                      onClick={showLease}>
                      Read Lease
                    </button>

                    <div className="mt-2">
                      <div className="flex justify-start items-center">
                        <div className="flex-1">
                          <SelectField
                            name="identificationType"
                            formik={formik}
                            label="Identification Type (Required)">
                            <option>Choose An Identification Type</option>
                            {['Passport', 'Drivers licence'].map((type) => (
                              <option key={type} value={type}>
                                {type}
                              </option>
                            ))}
                          </SelectField>
                        </div>
                        <div className="flex-1 ml-2">
                          <InputField
                            formik={formik}
                            name="identificationNumber"
                            label="Identification Number (Required)"
                            placeholder="Eg: A012345"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <SignatureModalInput mode="formik" name="signature" />
                  </div>

                  <SubmitButton
                    color="success"
                    formik={formik}
                    className="mt-4"
                    text="Save"
                    submittingText="Saving"
                  />
                </Form>
              );
            }}
          </Formik>
          <DocumentPreviewModal
            isVisible={documentViewerVisible}
            setIsVisible={setDocumentViewerVisible}
            documentUrl={documentURL}
            title="Lease Preview"
          />
        </CardBody>
      </Card>
    );
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Tenancy Renewal">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper
        title="Tenancy Renewal"
        buttons={[
          {
            text: 'Back To Property',
            href: `/tenancies/${tenancyId}`,
            bgColor: 'neutral',
          },
        ]}>
        {cardForStatus()}
      </PageWrapper>
    );
  }
};

export default EditTenancyRenewalPage;
