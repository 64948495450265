import React, {useEffect, useState} from 'react';

import {useQuery} from 'react-query';
import {useNavigate} from 'react-router';

import emptyBg from 'assets/img/empty_dataset_images/listing_search_results.svg';
import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import EmptyDataSet from 'components/common/EmptyDataSet';
import LoadingView from 'components/common/LoadingView';
import ListingAdvancedSearchForm from 'components/listing/ListingAdvancedSearchForm';
import ListingSearchResultCard from 'components/listing/ListingSearchResultCard';
import PageWrapper from 'components/PageWrapper';
import useQueryString from 'hooks/useQueryString';
import Listing from 'models/listings/Listing';
import {errorViewForError} from 'utilities/ErrorHelpers';

const PER_PAGE = 10;

const constructSearchObject = (query: URLSearchParams): any => {
  const clauses = {} as any;

  if (query.has('locations')) {
    clauses.locations = query.get('locations');
  }

  if (query.has('propertyTypes')) {
    clauses.propertyType = query.get('propertyTypes').split(',');
  }

  if (query.has('minBeds')) {
    clauses.bedrooms = {gte: Number(query.get('minBeds'))};
  }
  if (query.has('maxBeds')) {
    clauses.bedrooms = {lte: Number(query.get('maxBeds'))};
  }

  if (query.has('minBaths')) {
    clauses.bathrooms = {gte: Number(query.get('minBaths'))};
  }
  if (query.has('maxBaths')) {
    clauses.bathrooms = {lte: Number(query.get('maxBaths'))};
  }

  if (query.has('minGarages')) {
    clauses.garages = {gte: Number(query.get('minGarages'))};
  }
  if (query.has('maxBeds')) {
    clauses.garages = {lte: Number(query.get('maxGarages'))};
  }

  if (query.has('minRent')) {
    clauses.rentAmount = {gte: Number(query.get('minRent'))};
  }
  if (query.has('maxRent')) {
    clauses.rentAmount = {lte: Number(query.get('maxRent'))};
  }

  if (query.has('availableFrom')) {
    clauses.startDate = {gte: query.get('availableFrom')};
  }

  if (query.has('allowedPets')) {
    clauses.allowedPetTypes = {eq: query.get('allowedPets').split(',')};
  }

  if (query.has('smokingAllowed')) {
    clauses.smokingAllowed = {eq: true};
  }

  if (query.has('maxTenants')) {
    clauses.maxTenants = {gte: Number(query.get('maxTenants'))};
  }

  if (query.has('tags')) {
    clauses.tags = {eq: query.get('tags').split(',')};
  }

  return clauses;
};

const ListingSearchPage = () => {
  let query = useQueryString();
  const navigate = useNavigate();

  const [page, setPage] = useState(
    query.has('page') ? Number(query.get('page')) : 1,
  );

  const {
    data: listings,
    isLoading,
    error,
    refetch,
  } = useQuery(`listings-search-${page}`, async () => {
    const listings = await Listing.page(page)
      .includes('listing_photos')
      .per(PER_PAGE)
      .order({createdAt: 'desc'})
      .where(constructSearchObject(query))
      .stats({total: 'count'})
      .all();

    return listings;
  });

  useEffect(() => {
    refetch();
  }, [query]);

  const incrementPage = () => {
    query.set('page', (page + 1).toString());
    navigate(`?${query.toString()}`);
    setPage(page + 1);
  };
  const decrementPage = () => {
    query.set('page', (page - 1).toString());
    navigate(`?${query.toString()}`);
    setPage(page - 1);
  };

  const renderPagination = () => {
    const total = listings.meta.stats.total.count;
    if (total > PER_PAGE) {
      return (
        <div className="flex justify-center mt-8">
          <div className="btn-group">
            <button
              className="btn"
              onClick={decrementPage}
              disabled={page === 1}>
              «
            </button>
            <button className="btn">Page {page}</button>
            <button
              className="btn"
              onClick={incrementPage}
              disabled={(page + 1) * PER_PAGE >= total}>
              »
            </button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Search Results">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div className="col-span-1">
            <ListingAdvancedSearchForm />
          </div>
          <div className="col-span-1 md:col-span-3">
            <LoadingView />
          </div>
        </div>
      </PageWrapper>
    );
  } else {
    if (listings.data.length === 0) {
      return (
        <PageWrapper title="Search Results">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div className="col-span-1">
              <ListingAdvancedSearchForm />
            </div>
            <div className="col-span-1 md:col-span-3">
              <EmptyDataSet
                title="No Listings Found"
                message="We couldn't find any rental listings matching your search criteria, try adjusting your search options."
                image={emptyBg}
              />
            </div>
          </div>
        </PageWrapper>
      );
    } else {
      return (
        <PageWrapper title="Search Results">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div className="col-span-1">
              <ListingAdvancedSearchForm />
            </div>
            <div className="col-span-1 md:col-span-3">
              <Card className="mb-2">
                <CardBody className="!py-4">
                  <CardTitle className="mb-0">
                    Found {listings.meta.stats.total.count} Rentals
                  </CardTitle>
                </CardBody>
              </Card>
              {listings.data.map((listing) => (
                <ListingSearchResultCard listing={listing} key={listing.id} />
              ))}

              {renderPagination()}
            </div>
          </div>
        </PageWrapper>
      );
    }
  }
};

export default ListingSearchPage;
