import {useEffect, useState} from 'react';

import {
  Datepicker,
  datepickerPhrases,
  END_DATE,
  START_DATE,
  type FocusedInput,
} from '@datepicker-react/styled';
import {type FormikHelpers, Formik, Form, type FormikProps} from 'formik';
import moment from 'moment';
import {useQueryClient} from 'react-query';
import {ThemeProvider} from 'styled-components';
import * as Yup from 'yup';

import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightSideText from 'components/walkthrough/common/RightSideText';
import {MOBILE_BREAKPOINT_WIDTH} from 'globals/ScreenSizeConstants';
import useWindowSize from 'hooks/useWindowResize';
import Property from 'models/properties/Property';
import TrackingService from 'services/TrackingService';

import theme from '../../../themes/keyhook';

type FormValues = {
  startDate: string | null;
  endDate: string | null;
};

const AvailabilityStep = ({
  property,
  stepNumber,
  totalSteps,
}: {
  property: Property;
  stepNumber: number;
  totalSteps: number;
}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_StartRentalAvailabilityStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const tenancy = property.tenancies[0];
  const [isLoading, setIsLoading] = useState(false);
  const [focusedInput, setFocusedInput] = useState(START_DATE);

  const [isMobile, setIsMobile] = useState(
    window.screen.width <= MOBILE_BREAKPOINT_WIDTH,
  );

  const queryClient = useQueryClient();

  const {width: windowWidth} = useWindowSize();

  useEffect(
    () => setIsMobile(windowWidth <= MOBILE_BREAKPOINT_WIDTH),
    [windowWidth],
  );

  const handleSubmit = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    setIsLoading(true);

    if (formValues.endDate === '') {
      formValues.endDate = null;
    }

    tenancy.assignAttributes(formValues);
    const result = await tenancy.save();

    if (result) {
      property.lastOnboardingStepCompleted = 'new_availability';
      await property.save();

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.NewTenancy_CompleteRentalAvailabilityStep,
        {
          propertyId: property.id,
        },
      );

      setIsLoading(false);
      queryClient.setQueryData(['new-property', property.id], property);
    } else {
      for (const key of Object.keys(tenancy.errors)) {
        const message = tenancy.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }
  };

  const previousStep = async () => {
    property.lastOnboardingStepCompleted = 'new_general_information';
    await property.save();

    queryClient.setQueryData(['new-property', property.id], property);
  };

  const quickSetEndDate = (
    formik: FormikProps<FormValues>,
    numberOfMonths: number,
  ) => {
    if (formik.values.startDate) {
      const sd = moment(formik.values.startDate);
      let endDate = sd.add(numberOfMonths, 'months');
      if (numberOfMonths === 12) {
        endDate = endDate.subtract(1, 'day');
      }
      formik.setFieldValue('endDate', endDate.format('YYYY-MM-DD'));

      TrackingService.trackEvent(TrackingService.Event.QuickSetEndDate, {
        numberOfMonths,
      });
    }
  };

  const title = 'Set the tenancy start and end dates';
  const subtitle = 'Leave the end date blank if this is a periodic tenancy';

  return (
    <Container>
      <LeftSide title={title} subtitle={subtitle} />
      <RightContainer>
        <RightSideText title={title} subtitle={subtitle} />
        <Formik
          initialValues={
            {
              startDate: tenancy.startDate || null,
              endDate: tenancy.endDate || null,
            } as FormValues
          }
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            startDate: Yup.string().nullable().required().label('Start Date'),
            endDate: Yup.string()
              .nullable()
              .label('End Date')
              .test(
                'should-be-greather-than-start-date',
                'Should be after the Start Date',
                function (value) {
                  if (!value) {
                    return true;
                  }
                  const startDate = this.parent.startDate;
                  if (!startDate) {
                    return true;
                  }

                  return moment(value) > moment(startDate);
                },
              ),
          })}>
          {(formik) => {
            const phrases = datepickerPhrases;
            phrases.close = 'Clear';
            return (
              <Form>
                {formik.values.startDate &&
                  formik.values.startDate.length > 0 && (
                    <div className="w-[270px] mx-auto my-4 flex justify-center items-center">
                      <div className="dropdown dropdown-end dropdown-bottom">
                        <label tabIndex={0} className="btn ">
                          Quick Set End Date
                        </label>
                        <ul
                          tabIndex={0}
                          className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                          {[3, 6, 12, 24].map((numMonths) => (
                            <li key={numMonths}>
                              <a
                                onClick={() =>
                                  quickSetEndDate(formik, numMonths)
                                }>
                                {numMonths} Months
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}

                <div className="flex justify-center">
                  <ThemeProvider
                    theme={{
                      reactDatepicker: {
                        colors: {
                          primaryColor: theme.colors.brand[500],
                          selectedDay: theme.colors.brand[200],
                        },
                        datepickerMonthsGridHeight: '100%',
                      },
                    }}>
                    <Datepicker
                      onDatesChange={(data) => {
                        setFocusedInput(data.focusedInput || START_DATE);

                        if (data.startDate) {
                          formik.setFieldValue(
                            'startDate',
                            moment(data.startDate).format('YYYY-MM-DD'),
                          );
                        }

                        // If you're setting the start date, clear out the end date.
                        // Otherwise it causes invalid interval errors sometimes.
                        if (data.focusedInput === END_DATE) {
                          formik.setFieldValue('endDate', null);
                        } else {
                          if (data.endDate) {
                            formik.setFieldValue(
                              'endDate',
                              moment(data.endDate).format('YYYY-MM-DD'),
                            );
                          }
                        }
                      }}
                      startDate={
                        formik.values.startDate &&
                        formik.values.startDate.length > 0
                          ? moment(formik.values.startDate).toDate()
                          : null
                      }
                      endDate={
                        formik.values.endDate &&
                        formik.values.endDate.length > 0
                          ? moment(formik.values.endDate).toDate()
                          : null
                      }
                      focusedInput={focusedInput as FocusedInput}
                      showClose
                      onClose={() => {
                        formik.setFieldValue('startDate', null);
                        formik.setFieldValue('endDate', null);
                      }}
                      showResetDates={false}
                      displayFormat="dd MMM yy"
                      minBookingDate={moment().add(1, 'day').toDate()}
                      phrases={phrases}
                      numberOfMonths={isMobile ? 1 : 2}
                      vertical={isMobile}
                    />
                  </ThemeProvider>
                </div>

                <ProgressBox
                  nextStep={formik.submitForm}
                  previousStep={previousStep}
                  step={stepNumber}
                  totalSteps={totalSteps}
                  previousStepEnabled
                  nextStepEnabled={
                    formik.values.startDate &&
                    formik.values.startDate.length > 0 &&
                    !isLoading
                  }
                />
              </Form>
            );
          }}
        </Formik>
      </RightContainer>
    </Container>
  );
};

export default AvailabilityStep;
