import moment from 'moment';
import {Link} from 'react-router-dom';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import TenancyContinuation from 'models/properties/TenancyContinuation';
import {removeUnderscores, titleize} from 'utilities/StringHelpers';

export const statusForRenewal = (renewal: TenancyContinuation): string => {
  let status = '';

  if (!renewal.tenantChosenAction) {
    let prestatus = '';
    if (renewal.landlordRequestedAction === 'go_periodic') {
      prestatus = 'You have requested to go periodic after the end date. ';
    } else if (renewal.landlordRequestedAction === 'start_new_lease') {
      prestatus = `You have requested to start a new lease with an end date of ${moment(
        renewal.endDate,
      ).format('DD MMM YYYY')}. `;
    }
    status =
      'We are currently waiting for the head tenant to respond to your renewal request. We will notify you once they do.';

    if (prestatus) {
      status = prestatus + status;
    }
  } else {
    if (renewal.landlordRequestedAction === 'go_periodic') {
      if (renewal.tenantChosenAction === 'go_periodic') {
        status = 'The head tenant agreed with your decision to go periodic.';
      } else {
        status = 'The head tenant has not acted on your decision yet.';
      }
    } else if (renewal.landlordRequestedAction === 'start_new_lease') {
      if (renewal.tenantChosenAction === 'start_new_lease') {
        if (renewal.allTenantsSigned) {
          status = `All tenants have agreed and signed, this tenancy will renew after the end date until ${moment(
            renewal.endDate,
          ).format('DD MMM YYYY')}.`;
        } else {
          status =
            'The head tenant has accepted your decision and we are currently waiting for the other tenants to sign.';
        }
      } else {
        status =
          'The tenants have chosen to not renew for a fixed term and instead go periodic.';
      }
    }
  }

  return status;
};

export const RenewalStatusCard = ({
  renewal,
}: {
  renewal: TenancyContinuation;
}) => {
  const title = titleize(removeUnderscores(renewal.landlordRequestedAction));
  const status = statusForRenewal(renewal);

  return (
    <Card className="mt-8">
      <CardBody>
        <CardTitle className="mb-0">Renewal Option: {title}</CardTitle>
        <p className="mb-2">{status}</p>

        <Link to={`tenancy-renewals/${renewal.id}/edit`}>
          <button className="btn btn-neutral btn-block mt-4" type="button">
            Edit Tenancy Renewal
          </button>
        </Link>
      </CardBody>
    </Card>
  );
};
