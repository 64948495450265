import {useState} from 'react';

import moment from 'moment';
import Datetime from 'react-datetime';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {toast} from 'react-toastify';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import Tenancy from 'models/properties/Tenancy';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

const EndTenancyPage = () => {
  useTitle('End Tenancy');
  usePageVisit('EndTenancyPage');

  const {propertyId, id} = useParams();
  const {activeAccountRole} = useLocalUserSettings();

  const {data, isLoading, error} = useQuery(`end-tenancy-${id}`, async () => {
    const tenancy = await Tenancy.find(id);

    return tenancy.data;
  });

  const [endDate, setEndDate] = useState<moment.Moment | null>(null);
  const [saveErrors, setSaveErrors] = useState<string | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleDateChange = (date: string | moment.Moment) => {
    if (typeof date === 'string') {
      setEndDate(moment(endDate));
    } else {
      setEndDate(date);
    }
  };

  const handleEndTenancy = async () => {
    if (endDate === null) {
      return;
    }
    setIsSubmitting(true);

    const tenancy: Tenancy = data;

    tenancy.endDate = endDate.format('YYYY-MM-DD');

    const result = await tenancy.save();

    setIsSubmitting(false);

    if (result) {
      queryClient.invalidateQueries(`landlord-property-detail-${id}`);
      queryClient.invalidateQueries('landlord-properties');
      queryClient.invalidateQueries('renter-tenancies');

      const caption =
        activeAccountRole === 'Landlord'
          ? 'We have successfully received your request to end this tenancy and your tenants have been given notice.'
          : 'We have successfully received your request to end this tenancy and your landlord has been given notice.';

      toast.success(caption);

      navigate(-1); // ( go back )
    } else {
      const modelErrors = Object.keys(tenancy.errors)
        .map((key) => tenancy.errors[key].fullMessage)
        .join(', ');

      setSaveErrors(modelErrors);
    }
  };

  const minDate =
    activeAccountRole == 'Landlord'
      ? moment().add(90, 'days')
      : moment().add(21, 'days');

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="End Tenancy">
        <LoadingView />
      </PageWrapper>
    );
  } else if (data.status !== 'active_periodic' || data.endDate) {
    return errorViewForError({code: '400'});
  } else {
    return (
      <PageWrapper title="End Tenancy">
        <Card className="mt-2">
          <CardBody>
            <CardTitle className="mb-0">End Tenancy</CardTitle>
            <p>
              {activeAccountRole === 'Landlord'
                ? 'You can use the form below to give notice to your tenant(s) that this periodic tenancy will end. NZ law requires a minimum of 90 days notice.'
                : 'You can use the form below to give notivce to your landlord that this periodic tenancy will end. Please make sure all tenants in this tenancy have agreed to this. NZ law requires a minimum of 21 days notice.'}
            </p>

            <div className="mt-2">
              <label className="label">
                <span className="label-text">Tenancy End Date</span>
              </label>
              <Datetime
                value={endDate}
                onChange={handleDateChange}
                dateFormat={DATE_FORMAT}
                isValidDate={(currentDate: moment.Moment, selectedDate) =>
                  currentDate.isSameOrAfter(minDate)
                }
                timeFormat={false}
                viewDate={minDate}
                className="py-2 px-4 border-gray border-2 w-[200px]"
              />
            </div>

            {saveErrors && (
              <div className="mt-2">
                <p className="text-red-500">{saveErrors}</p>
              </div>
            )}

            <div className="mt-4">
              <button
                className="btn btn-primary btn-block"
                type="button"
                onClick={handleEndTenancy}
                disabled={isSubmitting}>
                {isSubmitting ? 'Saving...' : 'Set Tenancy End Date'}
              </button>
            </div>
          </CardBody>
        </Card>
      </PageWrapper>
    );
  }
};

export default EndTenancyPage;
