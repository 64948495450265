import {PencilAltIcon, TrashIcon} from '@heroicons/react/outline';
import {useQueryClient} from 'react-query';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';

import ChatableMessages from 'components/chat/ChatableMessages';
import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';

const PendingTenancyCard = ({
  property,
  tenancy,
}: {
  property: Property;
  tenancy?: Tenancy;
}) => {
  const queryClient = useQueryClient();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const confirmDestroyPendingTenancy = () => {
    if (
      property &&
      tenancy &&
      (tenancy.status === 'pending' || tenancy.status === 'draft')
    ) {
      setConfirmationOptions({
        title: 'Delete Tenancy',
        message:
          'Are you sure you want to delete this tenancy? If you do this in error you will have to remake it.',
        buttonTitle: 'Delete',
        action: destroyPendingTenancy,
        color: 'error',
      });
    } else {
      toast.error('This tenancy cannot be deleted as it is already active.');
    }
  };

  const destroyPendingTenancy = async () => {
    const result = await tenancy.destroy();
    if (result) {
      await queryClient.invalidateQueries(
        `landlord-property-detail-${property.id}`,
      );
      toast.success('The tenancy was successfully deleted!');
    } else {
      toast.error('This tenancy cannot be deleted as it is already active.');
    }
  };

  if (property && tenancy && tenancy.status === 'pending') {
    return (
      <Card className="mt-8">
        <CardBody>
          <CardTitle>Pending Tenancy</CardTitle>
          <p>Your tenant is yet to accept your Keyhook tenancy.</p>

          <p className="my-2">
            Modify this tenancy by clicking the buttons below. This tenancy
            cannot be modified once your tenant has accepted.
          </p>

          <div className="flex justify-start">
            <div className="mr-3">
              <Link to={`tenancies/${tenancy.id}/edit`}>
                <button className="btn">
                  <PencilAltIcon className="text-white w-6 h-6 hidden lg:inline-block" />
                  Edit Tenancy
                </button>
              </Link>
            </div>
            <div className="mr-3">
              <button className="btn" onClick={confirmDestroyPendingTenancy}>
                <TrashIcon className="text-white w-6 h-6 hidden lg:inline-block" />
                Delete Tenancy
              </button>
            </div>
          </div>

          <ChatableMessages
            chatableId={tenancy.id}
            chatableType="Tenancy"
            isLandlord={true}
          />
        </CardBody>
      </Card>
    );
  } else {
    return null;
  }
};

export default PendingTenancyCard;
