import {useCallback, useEffect, useState} from 'react';

import {AiFillCheckCircle} from '@react-icons/all-files/ai/AiFillCheckCircle';
import {Form, Formik, type FormikHelpers} from 'formik';

import {InputField, SubmitButton, TextareaField} from 'components/forms_fields';
import {Button} from 'components_sb/buttons';
import {Divider} from 'components_sb/layout';
import {Paragraph, Title} from 'components_sb/typography';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import EnquiryMessage from 'models/listings/EnquiryMessage';
import EnquiryThread from 'models/listings/EnquiryThread';
import Listing from 'models/listings/Listing';
import useAuth from 'services/useAuth';

type EnquiryFormValues = {
  name: string;
  email: string;
  message: string;
};

const ListingEnquireCard = ({listing}: {listing: Listing}) => {
  const [messageSent, setMessageSent] = useState(false);

  const {userIsLoggedIn, currentUser} = useAuth();
  const {activeAccountRole} = useLocalUserSettings();

  const handleEnquiryFormSubmit = useCallback(
    async (
      values: EnquiryFormValues,
      actions: FormikHelpers<EnquiryFormValues>,
    ) => {
      actions.setSubmitting(true);
      setMessageSent(false);

      const message = new EnquiryMessage({
        name: values.name,
        email: values.email,
        userId: currentUser.id,
        message: values.message,
      });

      const thread = new EnquiryThread({
        name: values.name,
        email: values.email,
        listingId: listing.id,
      });
      thread.enquiryMessages = [message];
      const result = await thread.save({with: 'enquiryMessages'});

      if (result) {
        setMessageSent(result);
        actions.resetForm();
      } else {
        if (thread.errors.email) {
          actions.setFieldError(
            'email',
            'You have already enquired about this listing, please reply via your enquiry inbox.',
          );
        }
      }

      actions.setSubmitting(false);
    },
    [currentUser, listing],
  );

  const {status} = listing;

  return (
    <div className="listingSection__wrap shadow-xl">
      <Title level="h2">Enquire</Title>

      {status === 'completed' ? (
        <Paragraph>Enquriries cannot be made for inactive listings.</Paragraph>
      ) : (
        <>
          {!userIsLoggedIn && (
            <>
              <Paragraph>
                You need to be logged in to send an enquiry.
              </Paragraph>

              {/* TODO: Redirect back to listing after registering/logging in */}
              <Button
                label="Register"
                category="primary"
                size="base"
                mode="link"
                to="/register/tenant"
              />
              <Divider orientation={'horizontal'} labelPosition={'middle'}>
                OR
              </Divider>
              <Button
                label="Log in"
                category="secondary"
                size="base"
                mode="link"
                to="/login"
              />
            </>
          )}

          {userIsLoggedIn && (
            <>
              {activeAccountRole === 'Landlord' && (
                <Paragraph>
                  You cannot enquire about a property with a Landlord account.
                </Paragraph>
              )}
              {activeAccountRole === 'Renter' && (
                <>
                  {messageSent && (
                    <div className="alert alert-success">
                      <div>
                        <AiFillCheckCircle className="stroke-current flex-shrink-0 h-6 w-6 mr-2" />

                        <span>
                          Your message has been sent, replies will be sent in
                          your enquiry inbox.
                        </span>
                      </div>
                    </div>
                  )}
                  <Formik
                    initialValues={{
                      name: currentUser?.name || '',
                      email: currentUser?.email || '',
                      message: '',
                    }}
                    onSubmit={handleEnquiryFormSubmit}>
                    {(formik) => (
                      <Form className="!mt-2">
                        <div>
                          <InputField
                            name="name"
                            formik={formik}
                            label="Your Name"
                            placeholder="eg: John Smith"
                            disabled={!!currentUser}
                          />
                        </div>

                        <div>
                          <InputField
                            name="email"
                            formik={formik}
                            label="Email"
                            placeholder="eg: johnsmith@gmail.com"
                            type="email"
                            disabled={!!currentUser}
                          />
                        </div>

                        <div>
                          <TextareaField
                            name="message"
                            formik={formik}
                            label="Message"
                            placeholder="Your message in here..."
                          />
                        </div>

                        <div>
                          <SubmitButton
                            formik={formik}
                            text="Send"
                            submittingText="Sending..."
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ListingEnquireCard;
