import {useState} from 'react';

import {Browser} from '@capacitor/browser';
import {Form, Formik} from 'formik';
import type {FormikHelpers} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import ChatableMessages from 'components/chat/ChatableMessages';
import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LightboxGallery from 'components/common/LightboxGallery';
import LoadingView from 'components/common/LoadingView';
import {SubmitButton, TextareaField} from 'components/forms_fields';
import {HeaderButton} from 'components/navbar/Header';
import PageWrapper from 'components/PageWrapper';
import ServiceRequest from 'models/service_requests/ServiceRequest';
import ServiceRequestQuote from 'models/service_requests/ServiceRequestQuote';
import TrackingService from 'services/TrackingService';
import useAuth from 'services/useAuth';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';
import {removeUnderscores, titleize, toCurrency} from 'utilities/StringHelpers';

const ServiceRequestDetailPage = () => {
  usePageVisit('ServiceRequestDetailPage');

  const {id} = useParams();

  const {currentUser} = useAuth();

  const {isLoading, data, error} = useQuery(
    `landlord-service-request-${id}`,
    async () => {
      const request = await ServiceRequest.includes([
        'service_request_attachments',
        'service_request_quotes',
      ]).find(id);

      return request.data;
    },
  );
  useTitle(data ? data.title : '');
  const queryClient = useQueryClient();

  const [quoteMutationLoading, setQuoteMutationLoading] = useState(false);
  const [showRejectionReasonInput, setShowRejectionReasonInput] =
    useState(false);
  const [invoiceModalVisible, setInvoiceModalVisible] = useState(false);

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const confirmApproveQuote = (quote: ServiceRequestQuote) => {
    setConfirmationOptions({
      title: 'Approve Quote',
      message:
        'Are you sure you want to accept this quote? We will begin the process with the trades person if you confirm.',
      buttonTitle: 'Approve',
      action: () => approveQuote(quote),
      color: 'success',
    });
  };
  const approveQuote = async (quote: ServiceRequestQuote) => {
    setQuoteMutationLoading(true);

    quote.accepted = true;
    await quote.save();

    const request: ServiceRequest = data;
    request.status = 'confirming_job_with_service_person';

    const result = await request.save();

    if (result) {
      queryClient.setQueryData(`landlord-service-request-${id}`, request);
      toast.success(
        'You have successfully approved this quote! We will begin to organize a time with the tradesperson.',
      );

      TrackingService.trackEvent(
        TrackingService.Event.ApproveMaintenanceRequestQuote,
        {
          requestId: id,
          quoteId: quote.id,
        },
      );
    } else {
      console.log(request.errors);
    }

    setQuoteMutationLoading(false);
  };

  const rejectServiceRequest = async (
    formValues: any,
    actions: FormikHelpers<any>,
  ) => {
    actions.setSubmitting(true);

    const request: ServiceRequest = data;
    request.landlordRejectedReason = formValues.landlordRejectedReason;
    request.status = 'landlord_rejected';

    const result = await request.save();

    if (result) {
      queryClient.setQueryData(`landlord-service-request-${id}`, request);
      toast.success('The maintenance request has been rejected.');

      TrackingService.trackEvent(
        TrackingService.Event.RejectMaintenanceRequest,
        {
          requestId: id,
        },
      );
    } else {
      console.log(request.errors);
    }

    actions.setSubmitting(false);
  };

  const viewInvoice = async () => {
    await Browser.open({url: data?.customerInvoiceUrl});
  };

  let quote: ServiceRequestQuote;
  if (data && data.serviceRequestQuotes) {
    const accepted = data.serviceRequestQuotes.filter((qu) => qu.accepted);
    if (accepted && accepted.length > 0) {
      quote = accepted[0];
    }
  }

  const beforeAttachments = data
    ? data?.serviceRequestAttachments.filter(
        (attach) => attach.taken === 'before',
      )
    : [];
  const afterAttachments = data
    ? data?.serviceRequestAttachments.filter(
        (attach) => attach.taken === 'after',
      )
    : [];

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Maintenance Request">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    const buttons = [] as HeaderButton[];
    if (data.userId === currentUser.id && data.status !== 'completed') {
      buttons.push({
        text: 'Edit',
        href: 'edit',
        bgColor: 'neutral',
      });
    }
    return (
      <PageWrapper title="Maintenance Request" buttons={buttons} backEnabled>
        <Card className="lg:mt-4">
          <CardBody>
            <CardTitle className="mb-0">{data.title}</CardTitle>
            <span className="text-secondary text-sm block">
              Category: {data.category}
            </span>
            <span className="text-secondary text-sm block">
              Status: {titleize(removeUnderscores(data.status))}
            </span>
            <p className="my-4">{data.description}</p>

            {data.category === 'Appliance Repair' && (
              <p>Appliance: {data.applianceMakeAndModel}</p>
            )}

            {data.finalizedCost && (
              <div>
                <strong>Finalized Cost: </strong>
                <span>{toCurrency(data.finalizedCost)}</span>
              </div>
            )}

            {data.status === 'completed' && (
              <div className="my-4">
                {data.customerInvoiceUrl && (
                  <div className="mt-2">
                    <button
                      className="btn btn-sm btn-neutral"
                      onClick={viewInvoice}
                      type="button">
                      View Invoice
                    </button>
                  </div>
                )}
              </div>
            )}

            {beforeAttachments.length > 0 && (
              <div>
                <strong className="block mb-2">Attachments</strong>

                <LightboxGallery
                  sources={beforeAttachments.map((attachment) => {
                    if (attachment.attachmentThumbnail) {
                      return {
                        thumbnail:
                          attachment.attachmentThumbnail ||
                          attachment.attachment,
                        source: attachment.attachment,
                        type: 'image',
                      };
                    } else {
                      return {source: attachment.attachment, type: 'video'};
                    }
                  })}
                />
              </div>
            )}

            {afterAttachments.length > 0 && (
              <div className="mt-4">
                <strong className="block mb-2">
                  Attachments ( Post Completion )
                </strong>

                <LightboxGallery
                  sources={afterAttachments.map((attachment) => {
                    if (attachment.attachmentThumbnail) {
                      return {
                        thumbnail:
                          attachment.attachmentThumbnail ||
                          attachment.attachment,
                        source: attachment.attachment,
                        type: 'image',
                      };
                    } else {
                      return {source: attachment.attachment, type: 'video'};
                    }
                  })}
                />
              </div>
            )}
          </CardBody>
        </Card>
        {quote && (
          <Card className="mt-4">
            <CardBody>
              <CardTitle>Quote Details</CardTitle>
              <div>
                <strong>Company Name: </strong>
                <span>{quote.companyName}</span>
              </div>
              <div>
                <strong>Quoted Cost: </strong>
                <span>{toCurrency(quote.quotedAmount)}</span>
              </div>
              {quote.notes && (
                <div>
                  <strong>Maintenance Support Notes:</strong>
                  <p>{quote.notes}</p>
                </div>
              )}
            </CardBody>
          </Card>
        )}

        {(data.status === 'awaiting_quote' ||
          data.status === 'awaiting_site_visit') && (
          <Card className="mt-4">
            <CardBody>
              <CardTitle>Awaiting Quotes</CardTitle>
              <p>
                Our support team is searching for quotes from available
                tradespeople. We will update you once quotes have been found.
              </p>
            </CardBody>
          </Card>
        )}

        {data.status === 'awaiting_quote_acceptance' && (
          <Card className="mt-4">
            <CardBody>
              <CardTitle>Quotes</CardTitle>
              <p>
                Keyhook has found these quote(s) for this maintenance request.
                Select a quote or "Reject maintenance request". If you reject
                Keyhook's quotes, you'll need to find a tradesperson yourself.
              </p>

              {data.serviceRequestQuotes.map((quote) => (
                <div key={quote.id}>
                  <div className="flex justify-between items-center my-2">
                    <div>
                      <strong className="block">{quote.companyName}</strong>
                      <span className="block text-secondary">
                        Quoted Amount: {toCurrency(quote.quotedAmount)}
                      </span>
                      <span className="block text-secondary">
                        Timeframe: {quote.timeframe}
                      </span>
                      {quote.notes && (
                        <div>
                          <strong className="text-secondary">
                            Maintenance Support Notes
                          </strong>
                          <p className="text-secondary">{quote.notes}</p>
                        </div>
                      )}
                    </div>
                    <div className="ml-2 float-right">
                      <button
                        className="btn btn-sm btn-success"
                        type="button"
                        onClick={() => confirmApproveQuote(quote)}
                        disabled={quoteMutationLoading}>
                        Accept
                      </button>
                    </div>
                  </div>
                  <hr className="border border-gray-200" />
                </div>
              ))}

              {showRejectionReasonInput ? (
                <div>
                  <Formik
                    initialValues={{landlordRejectedReason: ''}}
                    onSubmit={rejectServiceRequest}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={Yup.object().shape({
                      landlordRejectedReason: Yup.string()
                        .required()
                        .min(5)
                        .label('Reason'),
                    })}>
                    {(formik) => (
                      <Form>
                        <TextareaField
                          formik={formik}
                          name="landlordRejectedReason"
                          label="Reason"
                          rows={2}
                          className="h-auto"
                          placeholder="Please provide a reason for rejecting this request, it will be shown to your tenants."
                        />

                        <SubmitButton
                          formik={formik}
                          text="Reject Maintenance Request"
                          submittingText="Saving"
                          color="error"
                        />
                      </Form>
                    )}
                  </Formik>
                </div>
              ) : (
                <button
                  className="btn btn-sm btn-neutral mt-4"
                  type="button"
                  onClick={() => setShowRejectionReasonInput(true)}>
                  Reject Maintenance Request
                </button>
              )}
            </CardBody>
          </Card>
        )}

        {data.status === 'landlord_rejected' && (
          <Card className="mt-4 border-t-2 border-red-500">
            <CardBody>
              <CardTitle>Maintenance Request Rejected</CardTitle>
              <p>
                You have rejected this job. If you wish to have it fixed, it
                will be up to you to organise a tradesperson to fix it.
              </p>

              {data.landlordRejectedReason && (
                <p className="mt-2">Reason: {data.landlordRejectedReason}</p>
              )}
            </CardBody>
          </Card>
        )}

        {(data.status === 'confirming_job_with_service_person' ||
          data.status === 'awaiting_job_completion') && (
          <Card className="mt-3 border-t-1 border-success">
            <CardBody>
              <CardTitle>Quote Accepted</CardTitle>
              <p>
                You have chosen a quote. Keyhook is now coordinating with your
                tenant and your selected tradesperson. You will be updated once
                the maintenance has been completed.
              </p>
            </CardBody>
          </Card>
        )}

        <ChatableMessages
          chatableId={data.id}
          chatableType="ServiceRequest"
          isLandlord={true}
        />
      </PageWrapper>
    );
  }
};

export default ServiceRequestDetailPage;
