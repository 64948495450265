import {ReactNode, useEffect, useState} from 'react';

import {Capacitor} from '@capacitor/core';
import {motion} from 'framer-motion';
import {useLocation} from 'react-router';

import Header, {HeaderButton} from 'components/navbar/Header';
import BottomTabBar from 'components_sb/navigation/BottomTabBar/BottomTabBar';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import useScrollPosition from 'hooks/useScrollPosition';

const landlordTabRoutes = new Set(['/', '/chat', '/payments', '/settings']);
const renterTabRoutes = new Set(['/', '/chat', '/listings', '/settings']);

const allTabRoutes = new Set([...landlordTabRoutes, ...renterTabRoutes]);

const PageWrapper = ({
  title,
  buttons,
  children,
  showButtonsAsDropDown = false,
  dropdownTitle,
  backEnabled = false,
  disablePadding = false,
}: {
  title: string;
  buttons?: HeaderButton[];
  children: ReactNode;
  showButtonsAsDropDown?: boolean;
  dropdownTitle?: string;
  backEnabled?: boolean;
  disablePadding?: boolean;
}) => {
  useEffect(() => {
    document.title = `${title} - Keyhook`;
    return () => {
      document.title = 'Keyhook';
    };
  }, [title]);

  const [hasLoadedScrollPosition, setHasLoadedScrollPosition] = useState(false);
  const {activeAccountRole} = useLocalUserSettings();

  const {pathname} = useLocation();
  const scrollPosition = useScrollPosition('root-scroll-container');

  useEffect(() => {
    const storageValue = sessionStorage.getItem(pathname);
    const position = storageValue ? Number(storageValue) : null;

    if (position && !hasLoadedScrollPosition) {
      const rootScrollContainer = window.document.getElementById(
        'root-scroll-container',
      );

      if (rootScrollContainer) {
        const scrollContainerContent = rootScrollContainer.firstElementChild;
        scrollContainerContent.scrollTop = position;
        setHasLoadedScrollPosition(true);
      }
    }
    return () => {
      sessionStorage.setItem(pathname, scrollPosition.toString());
    };
  }, [pathname, scrollPosition, hasLoadedScrollPosition]);

  if (Capacitor.isNativePlatform() && !allTabRoutes.has(pathname)) {
    backEnabled = true;
  }

  if (Capacitor.isNativePlatform()) {
    return (
      <>
        <Header
          title={title}
          buttons={buttons}
          showButtonsAsDropDown={showButtonsAsDropDown}
          dropdownTitle={dropdownTitle}
          backEnabled={backEnabled}
        />

        <main
          className={`w-full max-w-7xl flex-1 flex flex-col mx-auto ${
            disablePadding ? '' : 'py-6 px-6 lg:px-8'
          }`}>
          {children}
        </main>

        {activeAccountRole === 'Landlord' &&
          landlordTabRoutes.has(pathname) && (
            <>
              {/* This is extra padding for the bottom tab bar so that nothing sits behind it in the scrollview */}
              <div className="h-20"></div>
              <BottomTabBar accountType="Landlord" />
            </>
          )}

        {activeAccountRole === 'Renter' && renterTabRoutes.has(pathname) && (
          <>
            {/* This is extra padding for the bottom tab bar so that nothing sits behind it in the scrollview */}
            <div className="h-20"></div>
            <BottomTabBar accountType="Renter" />
          </>
        )}
      </>
    );
  } else {
    return (
      <>
        <Header
          title={title}
          buttons={buttons}
          showButtonsAsDropDown={showButtonsAsDropDown}
          dropdownTitle={dropdownTitle}
          backEnabled={backEnabled}
        />
        <main className="w-full max-w-7xl flex-1 flex flex-col mx-auto py-6 px-6 lg:px-8">
          {children}
        </main>
      </>
    );
  }
};

export default PageWrapper;
