import React, {useEffect, useMemo} from 'react';

import {Capacitor} from '@capacitor/core';
import _ from 'lodash';
import {Navigate, useNavigate, useParams} from 'react-router';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import ActionsList from 'components/inspection/actions/ActionsList';
import AddInspectionItemModal from 'components/inspection/AddInspectionItemModal';
import InspectionAddActionModal from 'components/inspection/InspectionAddActionModal';
import InspectionAddNoteModal from 'components/inspection/InspectionAddNoteModal';
import InspectionAddPhotosModal from 'components/inspection/InspectionAddPhotosModal';
import ItemOptionsDropdown from 'components/inspection/ItemOptionsDropdown';
import InspectionItemTitleWithCounts from 'components/inspection/items/InspectionItemTitleWithCounts';
import MediaItemGrid from 'components/inspection/media_items/MediaItemGrid';
import NoteList from 'components/inspection/notes/NoteList';
import {HeaderButton} from 'components/navbar/Header';
import PageWrapper from 'components/PageWrapper';
import {Modal} from 'components_sb/layout';
import useInspectionQueries from 'hooks/useInspectionQueries';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import InspectionItem from 'models/inspections/InspectionItem';
import InspectionItemAttachment from 'models/inspections/InspectionItemAttachment';
import useAuth from 'services/useAuth';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import useInspectionStore from 'stores/InspectionStore';

const {useModal} = Modal.Imperative;

const InspectionRoomDetailPage = () => {
  const {tenancyId, propertyId, inspectionId, roomKey} = useParams();
  const decodedRoomKey = decodeURIComponent(roomKey);
  const navigate = useNavigate();

  const {inspectionIsLoading, inspectionItemsIsLoading} =
    useInspectionQueries(inspectionId);

  const openModal = useModal();

  const {currentUser} = useAuth();

  const {activeAccountRole} = useLocalUserSettings();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const isMobile = !useTailwindBreakpoint('sm');

  const [
    inspection,
    setInspection,
    inspectionItems,
    setInspectionItems,
    selectedRoomKey,
    setSelectedRoomKey,
  ] = useInspectionStore((state) => [
    state.inspection,
    state.setInspection,
    state.inspectionItems,
    state.setInspectionItems,
    state.selectedRoomKey,
    state.setSelectedRoomKey,
  ]);

  useEffect(() => {
    if (!selectedRoomKey) {
      setSelectedRoomKey(decodedRoomKey);
    }
  }, [roomKey]);

  const roomItems = useMemo(
    () => inspectionItems?.filter((item) => item.room === selectedRoomKey),
    [inspectionItems, selectedRoomKey],
  );

  const confirmDeleteAttachment = (attachment: InspectionItemAttachment) => {
    if (
      inspection.status === 'awaiting_inspection' &&
      attachment.userId === currentUser.id
    ) {
      const type = attachment.attachmentMimeType?.includes('image')
        ? 'Photo'
        : 'Video';
      setConfirmationOptions({
        color: 'error',
        title: `Remove ${type}`,
        message: `Are you sure you want to remove this ${type.toLowerCase()}?`,
        buttonTitle: 'Remove',
        action: () => deleteAttachment(attachment),
      });
    }
  };

  const deleteAttachment = async (attachment: InspectionItemAttachment) => {
    const item = inspectionItems?.find(
      (i) => i.id === attachment.inspectionItemId,
    );

    if (item) {
      const attachId = attachment.id;

      const result = await attachment.destroy();

      if (result) {
        item.inspectionItemAttachments = item.inspectionItemAttachments.filter(
          (a) => a.id !== attachId,
        );

        setInspectionItems([...(inspectionItems ?? [])]);
      }
    }
  };

  const renderRoomItem = (item: InspectionItem, index: number) => {
    return (
      <div className="mt-4" key={index}>
        <div className="flex justify-between items-center">
          <InspectionItemTitleWithCounts
            item={item}
            size={isMobile ? 'small' : 'large'}
          />

          <ItemOptionsDropdown
            addPhoto={() => showAddPhotoModal(item)}
            addNote={() => showAddNoteModal(item)}
            addAction={() => showAddActionModal(item)}
            deleteItem={() => confirmDeleteItem(item)}
          />
        </div>

        <MediaItemGrid inspection={inspection} item={item} />

        <NoteList inspection={inspection} item={item} />
        <ActionsList inspection={inspection} item={item} />
      </div>
    );
  };

  const renderBasicItem = (item: InspectionItem, index: number) => {
    return (
      <div key={index}>
        <CardTitle>Photos and videos</CardTitle>

        <MediaItemGrid inspection={inspection} item={item} />
      </div>
    );
  };

  const confirmDeleteItem = (item: InspectionItem) => {
    console.log('item', item);
    setConfirmationOptions({
      color: 'error',
      title: 'Remove Item',
      message: 'Are you sure you want to remove this item?',
      buttonTitle: 'Remove',
      action: () => deleteItem(item),
    });
  };

  const deleteItem = async (item: InspectionItem) => {
    const id = item.id;
    const result = await item.destroy();

    if (result) {
      const items = inspectionItems?.filter((i) => i.id !== id);
      setInspectionItems(items);
    }
  };

  const finishRoom = async () => {
    const i = inspection;
    if (i && !i.completedRooms.includes(selectedRoomKey)) {
      i.completedRooms.push(selectedRoomKey);

      await i.save();

      setInspection(i);
    }

    if (Capacitor.isNativePlatform()) {
      navigate(-2);
      if (activeAccountRole === 'Landlord') {
        // Landlord
        navigate(`/properties/${propertyId}/inspections/${inspectionId}`);
      } else {
        // Tenant
        navigate(`/tenancies/${tenancyId}/inspections/${inspectionId}`);
      }
    }
  };

  const showAddPhotoModal = (item: InspectionItem) => {
    openModal(InspectionAddPhotosModal, {inspectionItem: item});
  };

  const showAddNoteModal = (item: InspectionItem) => {
    openModal(InspectionAddNoteModal, {inspectionItem: item});
  };

  const showAddActionModal = (item: InspectionItem) => {
    openModal(InspectionAddActionModal, {inspectionItem: item});
  };

  const showAddItemModal = () =>
    openModal(AddInspectionItemModal, {orderIndex: roomItems.length + 1});

  if (inspectionIsLoading || inspectionItemsIsLoading || !inspection) {
    return (
      <PageWrapper title={selectedRoomKey || decodedRoomKey} backEnabled>
        <LoadingView />
      </PageWrapper>
    );
  }

  if (!inspection) {
    if (activeAccountRole === 'Renter') {
      return (
        <Navigate to={`/tenancies/${tenancyId}/inspections/${inspectionId}`} />
      );
    } else {
      return (
        <Navigate
          to={`/properties/${propertyId}/inspections/${inspectionId}`}
        />
      );
    }
  } else {
    const buttons = [] as HeaderButton[];
    if (
      inspection.status !== 'awaiting_sign_offs' &&
      inspection.status !== 'complete' &&
      activeAccountRole === 'Landlord'
    ) {
      buttons.push({
        text: 'Add Item',
        bgColor: 'neutral',
        onClick: showAddItemModal,
      });
    }

    if (inspection?.reportType === 'detailed') {
      return (
        <PageWrapper
          title={selectedRoomKey || decodedRoomKey}
          backEnabled
          buttons={buttons}>
          <div>
            <Card className="mt-4">
              <CardBody>{roomItems?.map(renderRoomItem)}</CardBody>
            </Card>

            <button
              type="button"
              className="mt-4 btn btn-block btn-success"
              onClick={finishRoom}>
              Finish Area
            </button>
          </div>
        </PageWrapper>
      );
    } else {
      const item = roomItems[0];
      return (
        <PageWrapper
          title={selectedRoomKey || decodedRoomKey}
          backEnabled
          buttons={buttons}>
          <div>
            <Card className="mt-4">
              <CardBody>{renderBasicItem(item, 0)}</CardBody>
            </Card>

            <Card className="mt-4">
              <CardBody>
                <div className="flex justify-between items-center">
                  <CardTitle>Notes</CardTitle>

                  <button
                    type="button"
                    className="btn btn-info btn-sm"
                    onClick={() => showAddNoteModal(item)}>
                    Add Note
                  </button>
                </div>

                <NoteList inspection={inspection} item={item} />
              </CardBody>
            </Card>

            <Card className="mt-4">
              <CardBody>
                <div className="flex justify-between items-center">
                  <CardTitle>Actions</CardTitle>

                  <button
                    type="button"
                    className="btn btn-warning btn-sm"
                    onClick={() => showAddActionModal(item)}>
                    Add Action
                  </button>
                </div>

                <ActionsList inspection={inspection} item={item} />
              </CardBody>
            </Card>

            <button
              type="button"
              className="mt-4 btn btn-block btn-success"
              onClick={finishRoom}>
              Finish Area
            </button>
          </div>
        </PageWrapper>
      );
    }
  }
};

export default InspectionRoomDetailPage;
