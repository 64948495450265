import React, {useState} from 'react';

import {type FormikProps} from 'formik';

import chattelsIcon from 'assets/img/tenancy_clause_icons/chattels.svg';
import courtesyChattelsIcon from 'assets/img/tenancy_clause_icons/courtesy-chattels.svg';
import customIcon from 'assets/img/tenancy_clause_icons/custom.svg';
import clauseIcon from 'assets/img/tenancy_clause_icons/document.svg';
import maxTenantsIcon from 'assets/img/tenancy_clause_icons/max-tenants.svg';
import petIcon from 'assets/img/tenancy_clause_icons/pets.svg';
import smokingIcon from 'assets/img/tenancy_clause_icons/smoking.svg';
import CardTitle from 'components/common/card/CardTitle';
import TenancyClause from 'components/common/TenancyClause';
import ClauseLibraryModal from 'components/tenancy/ClauseLibraryModal';
import {Clause} from 'models/properties/ClauseLibrary';
import Property from 'models/properties/Property';
import TrackingService from 'services/TrackingService';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {useAutoAnimate} from 'utilities/hooks';
import {removeUnderscores, titleize} from 'utilities/StringHelpers';

interface Vals {
  customClauses: {
    clauses: string[];
  };
  clauses: Clause[];
}

interface LeaseConditionsProps {
  property: Property;
  formik: FormikProps<Vals>;
}

const LeaseConditions = ({property, formik}: LeaseConditionsProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [customConditionText, setCustomConditionText] = useState('');

  const [isEditingClause, setIsEditingClause] = useState(false);
  const [editingClauseId, setEditingClauseId] = useState('');
  const [editingClauseText, setEditingClauseText] = useState('');

  const [animateRef] = useAutoAnimate();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const scrollConditionsToBottom = () => {
    const el = document.getElementById('all-clauses');
    el.scrollTop = el.scrollHeight;
  };

  const saveCondition = () => {
    const clauses = formik.values.customClauses.clauses;

    if (editingIndex === -1) {
      clauses.push(customConditionText);
    } else {
      clauses[editingIndex] = customConditionText;
    }

    formik.setFieldValue('customClauses', {clauses: clauses});

    setCustomConditionText('');
    setIsEditing(false);
    setEditingIndex(-1);

    TrackingService.trackEvent(TrackingService.Event.AddCustomClause);
    scrollConditionsToBottom();
  };

  const editAtIndex = (index: number) => {
    const clauses: string[] = formik.values.customClauses.clauses;
    const clause = clauses[index];

    setEditingIndex(index);
    setIsEditing(true);
    setCustomConditionText(clause);
  };

  const confirmDeleteAtIndex = (index: number) => {
    setConfirmationOptions({
      title: 'Remove Custom Clause',
      message: 'Are you sure you want to remove this Custom Clause?',
      buttonTitle: 'Remove',
      action: () => deleteAtIndex(index),
      color: 'error',
    });
  };

  const deleteAtIndex = (index: number) => {
    const clauses: string[] = formik.values.customClauses.clauses;
    clauses.splice(index, 1);

    formik.setFieldValue('customClauses', {clauses: clauses});
  };

  const saveLibraryClause = () => {
    const clauses = formik.values.clauses;
    const index = clauses.findIndex((c) => c.id === editingClauseId);

    clauses[index].clause = editingClauseText;

    formik.setFieldValue('clauses', clauses);

    setEditingClauseText('');
    setIsEditingClause(false);
    setEditingClauseId('');

    TrackingService.trackEvent(TrackingService.Event.EditKeyhookClause);
  };

  const editLibraryClause = (clause: Clause) => {
    setEditingClauseText(clause.clause);
    setEditingClauseId(clause.id);
    setIsEditingClause(true);
  };

  const addLibraryClause = (clause: Clause) => {
    const clauses = formik.values.clauses;
    clauses.push(clause);
    formik.setFieldValue('clauses', clauses);

    scrollConditionsToBottom();
  };

  const confirmRemoveLibraryClause = (clause: Clause) => {
    setConfirmationOptions({
      title: 'Remove Clause',
      message: `Are you sure you want to remove '${clause.title}'`,
      buttonTitle: 'Remove',
      action: () => removeLibraryClause(clause),
      color: 'error',
    });
  };

  const removeLibraryClause = (clause: Clause) => {
    const clauses = formik.values.clauses;
    const index = clauses.findIndex((c) => c.id === clause.id);
    clauses.splice(index, 1);

    formik.setFieldValue('clauses', clauses);
  };

  const setInitialLibraryClauses = (clauses: Clause[]) => {
    formik.setFieldValue('clauses', clauses);
  };

  return (
    <div>
      <CardTitle>Lease Conditions</CardTitle>
      <p className="mb-5">
        These conditions are automatically derived from your property
        information. You can also add extra custom conditions.
      </p>
      <div
        ref={animateRef as any}
        id="all-clauses"
        className="max-h-[500px] overflow-y-scroll">
        {property.petsAllowed === false && (
          <TenancyClause
            title="Pets"
            clause="Pets are not allowed in this property."
            icon={petIcon}
          />
        )}

        {property.petsAllowed === true &&
          property.allowedPetTypes &&
          property.allowedPetTypes.length > 0 && (
            <TenancyClause
              title="Pets"
              clause={`Only these types of pets are allowed: ${property.allowedPetTypes
                .map((type) => titleize(removeUnderscores(type)))
                .join(', ')}`}
              icon={petIcon}
            />
          )}

        <TenancyClause
          title="Max Tenants"
          clause={`There is a maximum of ${property.maxTenants} people allowed to live in the property.`}
          icon={maxTenantsIcon}
        />

        {property.smokersAllowed === false && (
          <TenancyClause
            title="Smoking"
            clause="Smoking is not allowed anywhere in or on the property."
            icon={smokingIcon}
          />
        )}

        {property.chattels && Object.keys(property.chattels).length > 0 && (
          <TenancyClause
            title="Chattels"
            clause={`These chattels have been provided by the landlord: ${Object.entries(
              property.chattels,
            )
              .map((pair) => `${pair[0]} x${pair[1]}`)
              .join(', ')}`}
            icon={chattelsIcon}
          />
        )}

        {property.courtesyChattels &&
          Object.keys(property.courtesyChattels).length > 0 && (
            <TenancyClause
              title="Courtesy Chattels"
              clause={`These courtesy chattels have been provided by the landlord: ${Object.entries(
                property.courtesyChattels,
              )
                .map((pair) => `${pair[0]} x${pair[1]}`)
                .join(', ')}`}
              icon={courtesyChattelsIcon}
            />
          )}

        {formik.values.clauses.map((clause) => {
          if (isEditingClause && editingClauseId === clause.id) {
            return (
              <div className="mt-2" key={clause.id}>
                <label htmlFor="edit-clause" className="label pb-0">
                  <span className="label-text">Edit {clause.title}</span>
                </label>
                <p className="text-sm text-secondary ml-1">
                  This must comply with New Zealand tenancy law.{' '}
                  <a
                    href="https://www.tenancy.govt.nz/disputes/breaches-of-the-residential-tenancies-act/unenforceable-clauses-in-tenancy-agreements/"
                    className="link link-primary"
                    rel="noreferrer"
                    target="_blank">
                    Click here
                  </a>{' '}
                  for more information on unenforceable clauses.
                </p>

                <textarea
                  id="edit-clause"
                  className="textarea textarea-bordered mt-2 w-full"
                  placeholder="Write your condition here"
                  onChange={(e) => setEditingClauseText(e.target.value)}
                  onBlur={formik.handleBlur('clauses')}
                  disabled={formik.isSubmitting}
                  value={editingClauseText}
                  rows={5}
                />

                <button
                  className="btn btn-success btn-block mt-2"
                  onClick={saveLibraryClause}
                  type="button">
                  Save Clause
                </button>
              </div>
            );
          } else {
            return (
              <TenancyClause
                key={clause.id}
                title={
                  <>
                    {clause.title}
                    <a
                      className="link link-secondary inline-block ml-2"
                      onClick={() => editLibraryClause(clause)}>
                      Edit
                    </a>
                    <a
                      className="link link-secondary inline-block ml-2"
                      onClick={() => confirmRemoveLibraryClause(clause)}>
                      Delete
                    </a>
                  </>
                }
                clause={clause.clause}
                icon={clauseIcon}
              />
            );
          }
        })}

        {formik.values.customClauses.clauses.map((clause, index) => {
          if (isEditing && editingIndex === index) {
            return (
              <div className="mt-2" key={index}>
                <label htmlFor="custom-condition" className="label pb-0">
                  <span className="label-text">
                    Edit Custom Condition {index + 1}
                  </span>
                </label>
                <p className="text-sm text-secondary ml-1">
                  This must comply with New Zealand tenancy law.{' '}
                  <a
                    href="https://www.tenancy.govt.nz/disputes/breaches-of-the-residential-tenancies-act/unenforceable-clauses-in-tenancy-agreements/"
                    className="link link-primary"
                    rel="noreferrer"
                    target="_blank">
                    Click here
                  </a>{' '}
                  for more information on unenforceable clauses.
                </p>

                <textarea
                  id="custom-condition"
                  className="textarea textarea-bordered mt-2 w-full"
                  placeholder="Write your condition here"
                  onChange={(e) => setCustomConditionText(e.target.value)}
                  onBlur={formik.handleBlur('customClauses')}
                  disabled={formik.isSubmitting}
                  value={customConditionText}
                />

                <button
                  className="btn btn-success btn-block mt-2"
                  onClick={saveCondition}
                  type="button">
                  Save Custom Condition
                </button>
              </div>
            );
          } else {
            return (
              <TenancyClause
                key={index}
                title={
                  <>
                    {`Custom Clause ${index + 1}`}
                    <a
                      className="link link-secondary inline-block ml-2"
                      onClick={() => editAtIndex(index)}>
                      Edit
                    </a>
                    <a
                      className="link link-secondary inline-block ml-2"
                      onClick={() => confirmDeleteAtIndex(index)}>
                      Delete
                    </a>
                  </>
                }
                clause={clause}
                icon={customIcon}
              />
            );
          }
        })}
      </div>
      {isEditing && editingIndex === -1 ? (
        <div className="mt-2">
          <label htmlFor="custom-condition" className="label pb-0">
            <span className="label-text">Custom Condition</span>
          </label>
          <p className="text-sm text-secondary ml-1">
            This must comply with New Zealand tenancy law.{' '}
            <a
              href="https://www.tenancy.govt.nz/disputes/breaches-of-the-residential-tenancies-act/unenforceable-clauses-in-tenancy-agreements/"
              className="link link-primary"
              rel="noreferrer"
              target="_blank">
              Click here
            </a>{' '}
            for more information on unenforceable clauses.
          </p>

          <textarea
            id="custom-condition"
            className="textarea textarea-bordered mt-2 w-full"
            placeholder="Write your condition here"
            onChange={(e) => setCustomConditionText(e.target.value)}
            onBlur={formik.handleBlur('customClauses')}
            disabled={formik.isSubmitting}
            value={customConditionText}
          />

          <button
            className="btn btn-success btn-block mt-2"
            onClick={saveCondition}
            type="button">
            Save Custom Condition
          </button>
        </div>
      ) : (
        <div className="mt-2">
          <ClauseLibraryModal
            addClause={addLibraryClause}
            removeClause={removeLibraryClause}
            setInitialClauses={setInitialLibraryClauses}
            chosenClauses={formik.values.clauses}
          />

          <button
            className="btn btn-outline btn-neutral btn-block mt-2 add-custom-condition-button"
            onClick={() => {
              setEditingIndex(-1);
              setIsEditing(true);
            }}
            type="button">
            Add Custom Condition
          </button>
        </div>
      )}
    </div>
  );
};

export default LeaseConditions;
