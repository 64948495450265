import {useEffect, useRef, useState} from 'react';

import {
  type FilePondFile,
  type LoadServerConfigFunction,
  type ProcessServerConfigFunction,
  registerPlugin,
} from 'filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import {Form, Formik, type FormikHelpers, type FormikProps} from 'formik';
import {motion} from 'framer-motion';
import {FilePond} from 'react-filepond';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightSideText from 'components/walkthrough/common/RightSideText';
import {InlineError} from 'components_sb/feedback';
import {TARGET_ENV, API_URL} from 'globals/app-globals';
import PresignResponse from 'helpers/PresignResponse';
import ListingPhoto from 'models/listings/ListingPhoto';
import Property from 'models/properties/Property';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import TrackingService from 'services/TrackingService';
import useAuth from 'services/useAuth';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
);

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const pageTransition = {
  type: 'tween',
  ease: 'linear',
  duration: 0.5,
};

const PhotosStep = ({property}: {property: Property}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_StartUploadPhotosStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const listing = property.listings[0];

  const [imagePreviewHeight, setImagePreviewHeight] = useState(220);

  const [isLoading, setIsLoading] = useState(false);
  const {currentUser} = useAuth();

  const pond = useRef(null);
  const [isUploadingPhotos, setIsUploadingPhotos] = useState(false);

  const queryClient = useQueryClient();

  // This isn't ideal
  // But basically with out it, if you are restoring images previously uploaded
  // Then they will be a grey square until the page is rerendered as the height
  // wont be accurate.
  // There is definitely a better way of doing this.
  useEffect(() => {
    const interval = setInterval(() => {
      const el = document.querySelectorAll('.filepond--item')[0];
      if (el) {
        if (el.clientWidth !== imagePreviewHeight) {
          console.log(el.clientWidth);
          setImagePreviewHeight(el.clientWidth);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [setImagePreviewHeight]);

  const handleSubmit = async (formValues: any, actions: FormikHelpers<any>) => {
    setIsLoading(true);

    const values = {...formValues};
    const listing2 = listing.dup();

    const photos: FilePondFile[] = values.listing_photos;

    listing2.listingPhotos = photos.map((p, index) => {
      const id = p.getMetadata('photoId');
      if (id) {
        const photo = listing.listingPhotos.find((ph) => ph.id === id);
        photo.orderIndex = index;

        return photo;
      } else {
        const photo = new ListingPhoto();
        photo.photo = JSON.stringify({
          id: p.serverId,
          storage: 'cache',
          metadata: {
            size: p.file.size,
            filename: p.file.name,
            mime_type: p.file.type,
          },
        });
        photo.orderIndex = index;
        photo.listingId = listing.id;
        return photo;
      }
    });

    const result = await listing2.save({with: 'listingPhotos'});
    if (result) {
      property.lastOnboardingStepCompleted = 'listing_photos';
      await property.save();

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.ListProperty_CompleteUploadPhotosStep,
        {
          propertyId: property.id,
        },
      );

      await queryClient.invalidateQueries('new-listing-wizard');
      setIsLoading(false);
    } else {
      for (const field in listing2.errors) {
        const error = listing2.errors[field];
        actions.setFieldError(field, error?.fullMessage);
      }
      console.log(listing2.errors);
      setIsLoading(false);
    }

    actions.setSubmitting(false);
  };

  const previousStep = async () => {
    property.lastOnboardingStepCompleted = 'listing_availability';
    await property.save();
    queryClient.setQueryData('new-listing-wizard', property);
  };

  const uploadPhoto: ProcessServerConfigFunction = async (
    _fieldName,
    file,
    _metadata,
    load,
    error,
    progress,
    abort,
    _transfer,
    _options,
  ) => {
    const extension = file.type.split('/').pop();
    const filename = `${Date.now()}.${extension}`;

    const formdata = new FormData();
    formdata.append('file', file);

    if (TARGET_ENV === 'development') {
      const request = new XMLHttpRequest();
      request.open(
        'POST',
        `${API_URL}/uploads/listing_attachment.json?property_id=${listing.propertyId}`,
      );

      request.setRequestHeader(
        'X-USER-TOKEN',
        currentUser.meta.authenticationToken,
      );
      request.setRequestHeader('X-USER-EMAIL', currentUser.email);

      request.upload.onprogress = (e) => {
        progress(e.lengthComputable, e.loaded, e.total);
      };

      request.onload = function () {
        if (request.status >= 200 && request.status < 300) {
          // the load method accepts either a string (id) or an object
          const data = JSON.parse(request.responseText);
          load(data.id);
        } else {
          // Can call the error method if something is wrong, should exit after
          error('oh no');
        }
      };

      request.send(formdata);

      return {
        abort: () => {
          // This function is entered if the user has tapped the cancel button
          request.abort();

          // Let FilePond know the request has been cancelled
          abort();
        },
      };
    } else {
      const presignResponse = await fetch(
        `${API_URL}/presigns/listing_attachment.json?property_id=${listing.propertyId}&filename=${filename}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-USER-TOKEN': currentUser.meta.authenticationToken,
            'X-USER-EMAIL': currentUser?.email,
          },
        },
      );
      const presignInfo = (await presignResponse.json()) as PresignResponse;
      if (presignInfo) {
        const request = new XMLHttpRequest();
        request.open(presignInfo.method, presignInfo.url);

        for (const [key, value] of Object.entries(presignInfo.headers)) {
          request.setRequestHeader(key, value);
        }

        request.upload.onprogress = (e) => {
          progress(e.lengthComputable, e.loaded, e.total);
        };

        request.onload = function () {
          if (request.status >= 200 && request.status < 300) {
            // the load method accepts either a string (id) or an object

            const url = new URL(presignInfo.url);
            const id = url.pathname.split('/').pop();

            load(id);
          } else {
            // Can call the error method if something is wrong, should exit after
            error('oh no');
          }
        };

        request.send(file);

        return {
          abort: () => {
            request.abort();
            abort();
          },
        };
      }
    }
  };

  const downloadPhoto: LoadServerConfigFunction = (
    source,
    load,
    _error,
    progress,
    abort,
    _headers,
  ) => {
    progress(true, 0, 1024);

    const request = new XMLHttpRequest();
    request.onprogress = (e) => {
      progress(e.lengthComputable, e.loaded, e.total);
    };

    request.open('GET', source, true);
    request.responseType = 'blob';

    request.onload = function (e) {
      if (this.status !== 200) return;
      progress(true, 1024, 1024);
      load(this.response);
    };

    request.send();

    return {
      abort: () => {
        request.abort();
        abort();
      },
    };
  };

  const deletePhoto = async (
    source: any,
    load: () => void,
    error: (err: string) => void,
    formik: FormikProps<any>,
  ) => {
    const photo = listing.listingPhotos.find((p) => p.photo === source);
    if (photo) {
      const id = photo.id;
      const result = await photo.destroy();
      if (result) {
        const formPhotos = formik.values.listing_photos.filter((p: any) => {
          const meta = p.getMetadata();
          if (meta['photoId'] && meta['photoId'] === id) {
            return false;
          } else {
            return true;
          }
        });

        formik.setFieldValue('listing_photos', formPhotos);
        load();
      } else {
        error('There was an issue deleting the photo');
      }
    }
  };

  const title = 'Add photos... ';
  const subtitle =
    'The first photo will be your display photo. Drag and drop photos to rearrange.';

  // The RightContainer messes with scrolling, so we manually write it here with different classes.
  return (
    <Container>
      <LeftSide title={title} subtitle={subtitle} />
      <Formik
        initialValues={{
          listing_photos: listing.listingPhotos.map((p) => {
            return {
              source: p.photo,
              options: {
                type: 'local',
                metadata: {
                  photoId: p.id,
                },
              },
            };
          }),
        }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          listing_photos: Yup.array().min(4).label('Photos'),
        })}>
        {(formik) => (
          <div
            className={`flex flex-1 h-full w-full justify-center items-center ${
              formik.values.listing_photos.length === 0
                ? 'items-center'
                : 'items-start'
            } py-10 bg-white overflow-y-scroll`}>
            <motion.div
              className="w-full"
              initial="initial"
              animate="in"
              variants={pageVariants}
              transition={pageTransition}>
              <div className="container">
                <RightSideText title={title} subtitle={subtitle} />
                <Form>
                  <div className="mx-0 md:mx-16 listings-photo-upload">
                    <FilePond
                      ref={pond}
                      allowMultiple
                      allowReplace={false}
                      allowRevert
                      files={formik.values.listing_photos as any}
                      maxFiles={30}
                      maxParallelUploads={5}
                      imageTransformOutputQuality={80}
                      imageTransformOutputQualityMode="always"
                      imageTransformOutputMimeType="image/jpeg"
                      imageResizeTargetWidth={1200}
                      imageResizeMode="contain"
                      imageResizeUpscale={false}
                      imagePreviewHeight={imagePreviewHeight}
                      maxFileSize="5MB"
                      acceptedFileTypes={[
                        'image/png',
                        'image/jpeg',
                        'image/jpg',
                      ]}
                      allowReorder={true}
                      imagePreviewMinHeight={200}
                      server={{
                        process: uploadPhoto,
                        fetch: null,
                        revert: (_uniqueFileId, load, _error) => {
                          load();
                        },
                        load: downloadPhoto,
                        remove: (source, load, err) => {
                          deletePhoto(source, load, err, formik);
                        },
                      }}
                      instantUpload={true}
                      onprocessfilestart={() => setIsUploadingPhotos(true)}
                      onprocessfile={() => setIsUploadingPhotos(false)}
                      onprocessfiles={() => setIsUploadingPhotos(false)}
                      onupdatefiles={(files) => {
                        formik.setFieldValue('listing_photos', files);
                      }}
                      onreorderfiles={(files) =>
                        formik.setFieldValue('listing_photos', files)
                      }
                      credits={false}
                    />
                    <InlineError error={formik.errors.listing_photos} />
                  </div>
                  <ProgressBox
                    nextStep={formik.submitForm}
                    previousStep={previousStep}
                    step={7}
                    previousStepEnabled
                    nextStepEnabled={!isUploadingPhotos && !isLoading}
                  />
                </Form>
              </div>
            </motion.div>
          </div>
        )}
      </Formik>
    </Container>
  );
};

export default PhotosStep;
