import React, {useState} from 'react';

import {Form, Formik, type FormikProps, type FormikHelpers} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import Toggle from 'react-toggle';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import {Button} from 'components_sb/buttons';
import User, {NotificationOptions} from 'models/users/User';
import useAuth from 'services/useAuth';
import {errorViewForError} from 'utilities/ErrorHelpers';

type NotificationsFormValues = {
  notificationOptions: NotificationOptions;
};

const descriptions = {
  general: {
    new_chat_message:
      'When you receive a direct chat message or property chat message.',
  },
  landlord: {
    property_general: 'General info about your property',
    rent_payment_sent: 'When we send your rent payment to you',
    inspection_reminders: 'Be reminded when inspections are soon',
    inspection_general:
      'Be notified at moments like a tenant confirming a time',
    tenancy_confirmed:
      'When a tenant approves your request and signs the lease',
    tenancy_expiring: 'When a tenancy is nearly expired',
    tenancy_change_requested:
      'When a tenant requests to change their lease to someone else',
    new_service_request: 'When a tenant submits a new maintenance request.',
    service_request_updated:
      'When a maintenance request is updated with a quote or new details.',
    subscription_information:
      'When there are changes to your Keyhook subscription',
  },
  renter: {
    rent_received: 'When we receive a rent payment from you',
    bond_received: 'When we receive any bond payments from you',
    inspection_reminders: 'Be reminded when inspections are soon',
    inspection_general:
      'Be notified at moments like your landlord confirming a time',
    tenancy_request_received:
      'Be notified when a landlord sends a tenancy offer',
    tenancy_expiring: 'When a tenancy is nearly expired',
    tenancy_change_confirmed:
      'When your landlord approves your request to change your lease to someone else',
    service_request_updated: 'When a maintenance request is updated',
    rent_changed: 'When the rent for your tenancy is changed by your landlord.',
  },
} as Record<string, Record<string, string>>;

const SettingsNotificationsPage = () => {
  const [avatarUploading, setAvatarUploading] = useState(false);

  const {currentUser} = useAuth();

  const {isLoading, error, data} = useQuery(
    'user-notifications-page',
    async () => {
      const user = await User.find(currentUser.id);
      return user.data;
    },
  );
  const queryClient = useQueryClient();

  const handleSubmit = async (
    formValues: NotificationsFormValues,
    actions: FormikHelpers<NotificationsFormValues>,
  ) => {
    const user = data;
    user.notificationOptions = formValues.notificationOptions;
    const result = await user.save();

    if (result) {
      toast.success('Your notification options has been successfully updated!');

      queryClient.setQueryData('user-notifications-page', user);
    }

    actions.setSubmitting(false);
  };

  const toggleNotificationSetting = async (
    formik: FormikProps<NotificationsFormValues>,
    sectionKey: string,
    rowKey: string,
    type: string,
  ) => {
    const options = formik.values.notificationOptions as any;
    options[sectionKey][rowKey][type] = !options[sectionKey][rowKey][type];

    formik.setFieldValue('notificationOptions', options);
  };

  const sections = () => {
    const sections = [{key: 'general', name: 'General'}];

    if (currentUser?.roles.indexOf('Landlord') !== -1) {
      sections.push({key: 'landlord', name: 'Landlord'});
    }
    if (currentUser?.roles.indexOf('Renter') !== -1) {
      sections.push({key: 'renter', name: 'Renter'});
    }
    if (currentUser?.roles.indexOf('ServicePerson') !== -1) {
      sections.push({key: 'service_person', name: 'Service Person'});
    }

    return sections;
  };

  const renderRow = (
    formik: FormikProps<NotificationsFormValues>,
    sectionKey: string,
    rowKey: string,
  ) => {
    const rowItem = data?.notificationOptions[sectionKey][rowKey];
    const description =
      descriptions[sectionKey] && descriptions[sectionKey][rowKey];

    return (
      <div key={rowKey}>
        <div className="flex justify-between items-start pb-2">
          <div>
            <strong className="text-sm">{rowItem.name}</strong>
            <p className="text-secondary text-sm">{description}</p>
          </div>

          <div className="flex">
            <div>
              <span className="block text-sm text-secondary">App</span>
              <Toggle
                checked={rowItem.push}
                onChange={() =>
                  toggleNotificationSetting(formik, sectionKey, rowKey, 'push')
                }
              />
            </div>
            <div className="ml-4">
              <span className="block text-sm text-secondary">Email</span>
              <Toggle
                checked={rowItem.email}
                onChange={() =>
                  toggleNotificationSetting(formik, sectionKey, rowKey, 'email')
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSection = (
    formik: FormikProps<NotificationsFormValues>,
    section: any,
  ) => {
    const rows = data?.notificationOptions[section.key];
    const keys = Object.keys(rows);

    return (
      <div className="mt-2" key={section.key}>
        <h5 className="text-md font-semibold">{section.name}</h5>
        {keys.map((row) => renderRow(formik, section.key, row))}
        <hr className="bg-gray-200 w-full" />
      </div>
    );
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Notifications">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    const items = sections();
    return (
      <PageWrapper title="Notifications">
        <Card className="mt-2">
          <CardBody>
            <CardTitle>Notification Options</CardTitle>

            <Formik
              initialValues={
                {
                  notificationOptions: data.notificationOptions,
                } as NotificationsFormValues
              }
              onSubmit={handleSubmit}>
              {(formik) => (
                <Form className="mt-2">
                  <p className="text-sm">
                    We allow you to fully customize what you would like to
                    receive notifications about.
                  </p>
                  <p className="text-sm">
                    We highly recommend that you keep the defaults on, in order
                    to not miss any important information.
                  </p>

                  {items.map((item) => renderSection(formik, item))}

                  <div className="mt-4">
                    <Button
                      label="Save options"
                      mode="formik"
                      category="primary"
                      size="base"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </PageWrapper>
    );
  }
};

export default SettingsNotificationsPage;
