import {ReactNode} from 'react';

const FormRow = ({
  children,
  responsive = false,
}: {
  children: ReactNode;
  responsive?: boolean;
}) => {
  if (responsive) {
    return (
      <div className="flex flex-col lg:flex-row justify-between space-x-0 lg:space-x-2">
        {children}
      </div>
    );
  } else {
    return <div className="flex justify-between space-x-2">{children}</div>;
  }
};

export default FormRow;
