import {useMemo, useState} from 'react';

import {useQuery} from 'react-query';
import {useParams} from 'react-router';

import LoadingView from 'components/common/LoadingView';
import {HeaderButton} from 'components/navbar/Header';
import PageWrapper from 'components/PageWrapper';
import BondStatusCard from 'components/property/landlord/detail_page_cards/BondStatusCard';
import DocumentsCard from 'components/property/landlord/detail_page_cards/DocumentsCard';
import InspectionsCard from 'components/property/landlord/detail_page_cards/InspectionsCard';
import ListingWalkthroughCard from 'components/property/landlord/detail_page_cards/ListingWalkthroughCard';
import MaintenanceRequestsCard from 'components/property/landlord/detail_page_cards/MaintenanceRequestsCard';
import ManageListingCard from 'components/property/landlord/detail_page_cards/ManageListingCard';
import PendingTenancyCard from 'components/property/landlord/detail_page_cards/PendingTenancyCard';
import PendingTransferCard from 'components/property/landlord/detail_page_cards/PendingTransferCard';
import PretenancyInspectionAlert from 'components/property/landlord/detail_page_cards/PretenancyInspectionAlert';
import RentPriceChangesCard from 'components/property/landlord/detail_page_cards/RentPriceChangesCard';
import TenancyRenewalCard from 'components/property/landlord/detail_page_cards/TenancyRenewalCard';
import TenancySelectCard from 'components/property/landlord/detail_page_cards/TenancySelectCard';
import TenancyWalkthroughCard from 'components/property/landlord/detail_page_cards/TenancyWalkthroughCard';
import TenantsCard from 'components/property/landlord/detail_page_cards/TenantsCard';
import CompleteProfileSection from 'components/property/landlord/profile_blocks/CompleteProfileSection';
import LandlordPropertyIndexCard from 'components/property/landlord/PropertyIndexCard';
import PrepareNextTenancyModal from 'components/tenancy/PrepareNextTenancyModal';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

const PropertyDetailPage = () => {
  usePageVisit('PropertyDetailPage');
  const {id} = useParams();

  const [selectedTenancyId, setSelectedTenancyId] = useState<
    string | undefined
  >(undefined);
  const [prepareNextTenancyModalOpen, setPrepareNextTenancyModalOpen] =
    useState(false);

  const {isLoading, data, error} = useQuery(
    `landlord-property-detail-${id}`,
    async () => {
      const res = await Property.select({
        // Select everything in properties
        tenancies: [
          'status',
          'total_rent',
          'rental_period',
          'start_date',
          'end_date',
          'next_inspection_date',
          'external_bond_id',
          'external_bond_filed',
          'ready_to_be_filed',
          'is_new',
          'bond',
          'head_tenant_id',
        ],
        users: ['name', 'avatar'],
        tenancy_requests: ['renter_email', 'created_at', 'accepted'],
        documents: [
          'document',
          'document_type',
          'private',
          'name',
          'documentable_type',
          'created_at',
        ],
        inspections: [
          'finalized_date',
          'estimated_date',
          'status',
          'done_by',
          'inspection_period_type',
          'inspection_actions_count',
        ],
        room_plans: ['id'],
        listings: ['id', 'public_id', 'status', 'created_at'],
      })
        .includes([
          'documents',
          'listings',
          {
            tenancies: [
              {tenancy_memberships: 'renter'},
              {tenancy_requests: 'renter'},
              {tenancy_invites: 'renter'},
              {inspections: 'inspection_actions'},
              'documents',
              'active_tenancy_continuation',
              'service_requests',
              'unprocessed_rent_price_changes',
            ],
          },
          'room_plan',
        ])
        .order({'tenancies.start_date': 'desc', 'listings.created_at': 'desc'})
        .where({
          'listings.status': ['draft', 'pending_approval', 'active', 'paused'],
        })
        .find(id);
      return res.data;
    },
    {retry: 1, refetchOnWindowFocus: false},
  );
  const property = data;
  const tenancy: Tenancy | undefined = useMemo(() => {
    if (!data) {
      return undefined;
    }

    if (selectedTenancyId) {
      if (selectedTenancyId === '-1') {
        return undefined;
      } else {
        return data.tenancies.find((t) => t.id === selectedTenancyId);
      }
    } else {
      const t = data.tenancies.find(
        (t) => t.status === 'active' || t.status === 'active_periodic',
      );
      if (t) {
        return t;
      } else if (data.tenancies.length > 0) {
        return data.tenancies[0];
      } else {
        return undefined;
      }
    }
  }, [data, selectedTenancyId]);

  useTitle(property ? property.streetAddress : '');

  // Should Usememo this.
  const actionButtons = () => {
    const items = [] as HeaderButton[];

    items.push({
      text: 'Edit Property',
      href: 'edit',
      isPro: false,
    });

    if (tenancy && ['active', 'active_periodic'].includes(tenancy.status)) {
      items.push({
        text: 'End Tenancy',
        href: `tenancies/${tenancy.id}/end-tenancy`,
        isPro: false,
      });
    }

    if (tenancy && tenancy.unprocessedRentPriceChanges.length === 0) {
      items.push({
        text: 'Change Rent',
        href: `tenancies/${tenancy.id}/rent-changes/new`,
        isPro: false,
      });
    }

    if (property && !property.isPendingTransfer) {
      items.push({
        text: 'Transfer Property',
        href: 'transfer',
        isPro: false,
      });
    }

    items.push({
      text: 'Manage Preferred Tradespeople',
      href: 'preferred-tradespeople',
      isPro: true,
    });

    items.push({
      text: 'Bank Account Information',
      href: 'bank-account',
      isPro: false,
    });

    if (property && property.roomPlan) {
      items.push({
        text: 'Edit Inspection Template',
        href: 'inspection-template',
        isPro: false,
      });
    }

    if (
      property &&
      property.tenanciesCount >= 1 &&
      !property.nextTenancyActionType
    ) {
      items.push({
        text: 'Prepare Next Tenancy',
        onClick: () => setPrepareNextTenancyModalOpen(true),
        isPro: false,
      });
    }

    if (property && tenancy && !tenancy.isNew) {
      items.push({
        text: 'Invite Other Tenants',
        href: `tenancies/${tenancy.id}/invite-tenants`,
        isPro: false,
      });
    }

    return items;
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Property">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    const buttons = actionButtons();

    return (
      <PageWrapper
        title="Property"
        buttons={buttons}
        showButtonsAsDropDown={true}
        dropdownTitle="Actions">
        <LandlordPropertyIndexCard
          property={property}
          tenancy={tenancy}
          pageType="detail"
        />

        <ListingWalkthroughCard property={property} />
        <TenancyWalkthroughCard property={property} tenancy={tenancy} />

        <ManageListingCard property={property} />

        <PretenancyInspectionAlert tenancy={tenancy} />

        <CompleteProfileSection property={property} tenancy={tenancy} />

        <TenancySelectCard
          property={property}
          tenancy={tenancy}
          setSelectedTenancyId={setSelectedTenancyId}
        />

        <PendingTransferCard property={property} />
        <TenancyRenewalCard property={property} tenancy={tenancy} />
        <RentPriceChangesCard property={property} tenancy={tenancy} />

        <BondStatusCard property={property} tenancy={tenancy} />

        <PendingTenancyCard property={property} tenancy={tenancy} />

        <InspectionsCard tenancy={tenancy} />
        <MaintenanceRequestsCard tenancy={tenancy} />
        <DocumentsCard property={property} tenancy={tenancy} />
        <TenantsCard property={property} tenancy={tenancy} />

        {property && property.tenanciesCount >= 1 ? (
          <PrepareNextTenancyModal
            property={property}
            isOpen={prepareNextTenancyModalOpen}
            setIsOpen={setPrepareNextTenancyModalOpen}
          />
        ) : null}
      </PageWrapper>
    );
  }
};

export default PropertyDetailPage;
