import {
  createElement,
  ForwardedRef,
  forwardRef,
  MouseEvent,
  useCallback,
  useRef,
} from 'react';

import clsx from 'clsx';
import {useFormikContext} from 'formik';
import {type IconType} from 'react-icons/lib';
import {useNavigate} from 'react-router-dom';

import {SpinningLoader} from 'components_sb/feedback';

/**
 * Tailwind class configuration
 */
const classes = {
  // Classes for all permutations
  base: clsx(
    'flex-1',
    'w-full',
    'flex',
    'justify-center',
    'items-center',
    'font-standard',
    'whitespace-nowrap',
    'text-center',
    'leading-none',
    'transition-all',
    'duration-200',
    'scale-100',
    'active:scale-95',
    'ring-0',
    'focus:ring-2',
    'border',
    'select-none',
  ),
  // Classes for disabled state
  disabled: clsx(
    // Disable interaction
    'pointer-events-none',
    // Opacity
    'opacity-50',
  ),
  // Classes based on category
  category: {
    primary: clsx(
      // Background
      'bg-brand-500 hover:bg-brand-600',
      // Border
      'border-brand-500 hover:border-brand-600',
      // Text
      'text-brand-0',
      // Ring
      'ring-brand-200',
    ),
    secondary: clsx(
      // Background
      'bg-transparent hover:bg-brand-100 hover:bg-opacity-20',
      // Border
      'border-brand-500 hover:border-brand-600',
      // Text
      'text-brand-500',
      // Ring
      'ring-brand-200',
    ),
    tertiary: clsx(
      // Background
      'bg-brand-100 bg-opacity-0 hover:bg-opacity-20',
      // Border
      'border-brand-100 border-opacity-0 hover:border-opacity-20',
      // Text
      'text-brand-500',
      // Ring
      'ring-brand-200',
    ),
    warning: clsx(
      // Background
      'bg-amber-500 hover:bg-amber-600',
      // Border
      'border-amber-500 hover:border-amber-600',
      // Text
      'text-amber-900',
      // Ring
      'ring-amber-300',
    ),
    danger: clsx(
      // Background
      'bg-red-400 hover:bg-red-500',
      // Border
      'border-red-400 hover:border-red-500',
      // Text
      'text-red-900',
      // Ring
      'ring-red-300',
    ),
  },
  // Classes based on size
  size: {
    main: {
      sm: clsx(
        // Text
        'text-xs',
        // Padding
        'px-3',
        // Height
        'h-8',
        'min-h-8',
        'max-h-8',
        // Roundness
        'rounded-lg',
        // Gap between spinning loader
        'gap-x-2',
      ),
      base: clsx(
        // Text
        'text-base',
        // Padding
        'px-6',
        // Height
        'h-12',
        'min-h-12',
        'max-h-12',
        // Roundness
        'rounded-lg',
        // Gap between spinning loader
        'gap-x-3',
      ),
      lg: clsx(
        // Text
        'text-base',
        // Padding
        'px-12',
        // Height
        'h-14',
        'min-h-14',
        'max-h-14',
        // Roundness
        'rounded-xl',
        // Gap between spinning loader
        'gap-x-3',
      ),
    },
    icon: {
      sm: 'text-sm',
      base: 'text-lg',
      lg: 'text-xl',
    },
  },
};

export type ButtonCategory =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'warning'
  | 'danger'
  | 'custom';

interface BaseButtonProps {
  /**
   *  The text shown within the button.
   */
  label: string;
  /**
   *  Indicates the visual importance of the button.
   */
  category: ButtonCategory;
  /**
   *  The size of the button.
   */
  size: 'sm' | 'base' | 'lg';
  /**
   *  The strategy for handling button events and states.
   */
  mode: 'manual' | 'formik' | 'link' | 'html-label';
  /**
   * An icon exported from react-icons to include beside the label.
   */
  icon?: IconType;
  /**
   *  Prevents the button from being interacted with.
   */
  disabled?: boolean;
  /**
   *  The text shown within the button when loading.
   */
  loadingLabel?: string;
}

type ConditionalCustomConfigProps =
  | {
      category: 'primary' | 'secondary' | 'tertiary' | 'warning' | 'danger';
      customClasses?: never;
    }
  | {
      category: 'custom';
      customClasses: string;
    };

type ConditionalModeProps =
  /**
   * Types when mode is 'manual'
   */
  | {
      mode: 'manual';
      loading?: boolean;
      onClick: (event: MouseEvent<HTMLElement>) => void;
      to?: never;
      htmlFor?: never;
    }
  /**
   * Types when mode is 'formik'
   */
  | {
      mode: 'formik';
      loading?: never;
      onClick?: never;
      to?: never;
      htmlFor?: never;
    }
  /**
   * Types when mode is 'link'
   */
  | {
      mode: 'link';
      loading?: never;
      onClick?: never;
      to: string;
      htmlFor?: never;
    }
  /**
   * Types when mode is 'html-label'
   */
  | {
      mode: 'html-label';
      loading?: never;
      onClick?: never;
      to?: never;
      htmlFor: string;
    };

/**
 * Conditional types combined with the base types
 */
export type ButtonProps = BaseButtonProps &
  ConditionalModeProps &
  ConditionalCustomConfigProps;

/**
 * Properties that wrapper components must provide to the base component.
 */
interface WrapperOutput {
  onClick: (event: MouseEvent<HTMLElement>) => void;
  type: 'button' | 'submit';
  loading: boolean;
}

/**
 * The minimum properties that wrapper components must receive from
 * the base component.
 */
interface BaseWrapperInput {
  children: (props: WrapperOutput) => JSX.Element;
}

/**
 * A component that wraps the based component to provide functionality
 * based on particular configuration.
 */
interface WrapperComponent {
  (props: BaseWrapperInput): JSX.Element;
}

/**
 * Wraps the component to enable manual control.
 */
const ManualWrapper = ({
  onClick,
  type,
  loading,
  children,
  ...props
}: BaseWrapperInput & WrapperOutput) =>
  /**
   * For the manual wrapper, the input must include the base properties
   * but also include the expected output for direct passing
   */
  children({
    ...props,
    onClick,
    type,
    loading,
  });

/**
 * Wraps the base component to enable support for
 * integration with the current Formik context.
 */
const FormikWrapper = ({children, ...props}: BaseWrapperInput) => {
  const {submitForm, isSubmitting} = useFormikContext();
  const onClick = useCallback(
    (event: MouseEvent<HTMLElement>): void => {
      event.preventDefault();
      submitForm();
    },
    [submitForm],
  );
  return children({
    ...props,
    onClick,
    type: 'submit',
    loading: isSubmitting,
  });
};

/**
 * Wraps the base component to enable the button to act as a link.
 * Note that it is usuually preferred to use the Link component
 * instead of this setting - since a button with 'link; mode only
 * simulates navigation, and does not directly create an anchor tag.
 */
const LinkWrapper = ({
  to,
  children,
  ...props
}: BaseWrapperInput & {to: string}) => {
  const navigate = useNavigate();
  const onClick = useCallback(() => navigate(to), []);
  return children({
    ...props,
    onClick,
    type: 'button',
    loading: false,
  });
};

/**
 * Wraps the base component to enable the button to act as an HTML
 * label element (allowing it to be used to control other elements).
 */
const HtmlLabelWrapper = ({
  htmlFor,
  children,
  ...props
}: BaseWrapperInput & {htmlFor: string}) => {
  /**
   * Programatically click the label element when the
   * contained button is clicked.
   */
  const labelRef = useRef();
  const onClick = useCallback(() => {
    (labelRef.current as HTMLElement).click();
  }, [labelRef]);

  return (
    <label htmlFor={htmlFor} ref={labelRef}>
      {children({
        ...props,
        onClick,
        type: 'button',
        loading: false,
      })}
    </label>
  );
};

/**
 * Primary UI component for user interaction.
 */
const Button = forwardRef(
  (props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      label,
      category,
      size,
      mode,
      customClasses = '',
      icon = null,
      disabled = false,
      loadingLabel = null,
    } = props;

    /**
     * Select the wrapper based on the mode
     */
    const Wrapper: WrapperComponent = {
      manual: ManualWrapper,
      formik: FormikWrapper,
      link: LinkWrapper,
      'html-label': HtmlLabelWrapper,
    }[mode];

    return (
      <Wrapper {...props}>
        {({onClick, type, loading = false}: WrapperOutput) => (
          <button
            ref={ref}
            type={type}
            onClick={onClick}
            disabled={disabled}
            className={clsx(
              classes.base,
              category === 'custom'
                ? customClasses
                : classes.category[category],
              classes.size.main[size],
              (disabled || loading) && classes.disabled,
            )}>
            {loading && (
              <SpinningLoader
                size={'xs'}
                color={category === 'primary' ? 'white' : 'brand'}
              />
            )}
            {loading && loadingLabel ? (
              loadingLabel
            ) : (
              <>
                {icon &&
                  createElement(icon, {
                    className: classes.size.icon[size],
                  })}
                <span>{label}</span>
              </>
            )}
          </button>
        )}
      </Wrapper>
    );
  },
);

export default Button;
