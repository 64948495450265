import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Subscription from 'models/payments/Subscription';
import LandlordProfile from 'models/users/LandlordProfile';

@Model()
class LandlordSubscription extends ApplicationRecord {
  static jsonapiType = 'landlord_subscriptions';

  @Attr() promoString: string;

  @Attr({persist: false}) cancelledAt: string;
  @Attr({persist: false}) freeUntil: string;

  @Attr() isActive: boolean;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('landlord_profiles') landlordProfile: LandlordProfile;
  @BelongsTo('subscriptions') subscription: Subscription;
}

export default LandlordSubscription;
