import {useEffect, useState} from 'react';

import {Capacitor} from '@capacitor/core';
import {BiBath} from '@react-icons/all-files/bi/BiBath';
import {BiBed} from '@react-icons/all-files/bi/BiBed';
import {BiCar} from '@react-icons/all-files/bi/BiCar';
import {type FormikHelpers, Formik, Form} from 'formik';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router';
import * as Yup from 'yup';

import DocumentPreviewModal from 'components/document/DocumentPreviewModal';
import SignatureModalInput from 'components/forms_fields/SignatureModalInput';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightSideText from 'components/walkthrough/common/RightSideText';
import {API_URL} from 'globals/app-globals';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import Property from 'models/properties/Property';
import TrackingService from 'services/TrackingService';
import {toCurrency} from 'utilities/StringHelpers';

type FormValues = {
  landlordSignature: string;
};

const PreviewLeaseStep = ({
  property,
  stepNumber,
  totalSteps,
}: {
  property: Property;
  stepNumber: number;
  totalSteps: number;
}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_StartPreviewLeaseStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const tenancy = property.tenancies[0];
  const [isLoading, setIsLoading] = useState(false);

  const [leaseModalVisible, setLeaseModalVisible] = useState(false);
  const [bondModalVisible, setBondModalVisible] = useState(false);

  const isMobile = !useTailwindBreakpoint('sm');

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const handleSubmit = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    setIsLoading(true);

    tenancy.assignAttributes(formValues);
    await tenancy.save();

    property.lastOnboardingStepCompleted = 'new_preview_lease';
    await property.save();

    /**
     * Track completion of the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_CompletePreviewLeaseStep,
      {
        propertyId: property.id,
      },
    );

    queryClient.setQueryData(['new-property', property.id], property);
    setIsLoading(false);
  };

  const previousStep = async () => {
    property.lastOnboardingStepCompleted = 'new_insurance';
    await property.save();

    queryClient.setQueryData(['new-property', property.id], property);
  };

  const title = 'Review and sign your lease and bond documents';
  const subtitle = 'Please make sure all the details are correct';

  const leaseDocumentUrl = `${API_URL}/tenancies/${tenancy.id}/lease_preview.pdf`;
  const bondDocumentUrl = `${API_URL}/tenancies/${tenancy.id}/bond_preview.pdf`;

  const showLease = () => {
    if (Capacitor.isNativePlatform()) {
      navigate(`/document-previews?url=${leaseDocumentUrl}`);
    } else {
      setLeaseModalVisible(true);
      TrackingService.trackEvent(TrackingService.Event.PreviewLease);
    }
  };

  const showBond = () => {
    if (Capacitor.isNativePlatform()) {
      navigate(`/document-previews?url=${bondDocumentUrl}`);
    } else {
      setBondModalVisible(true);
      TrackingService.trackEvent(TrackingService.Event.PreviewBond);
    }
  };

  return (
    <Container>
      <LeftSide title={title} subtitle={subtitle} />
      <RightContainer>
        <RightSideText title={title} subtitle={subtitle} />
        <Formik
          initialValues={
            {landlordSignature: tenancy.landlordSignature || ''} as FormValues
          }
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            landlordSignature: Yup.string()
              .required()
              .label('Signature')
              .min(10),
          })}>
          {(formik) => (
            <Form>
              <div className="flex flex-col md:mx-16 space-y-8">
                <div
                  className="nc-PropertyCardH group relative bg-white border border-neutral-100 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow h-full cursor-pointer"
                  data-nc-id="PropertyCardH">
                  <div className="h-full w-full flex flex-row items-center">
                    <div className="hidden lg:block flex-shrink-0 p-3 w-32 h-32 xl:w-64 xl:h-64">
                      <img
                        src={property.mainImage}
                        className="w-auto h-full rounded-2xl overflow-hidden object-cover"
                      />
                    </div>

                    <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
                      <div className="space-y-4 w-full">
                        <div className="flex items-center">
                          <h2 className="text-xl font-medium capitalize">
                            <span className="line-clamp-2">
                              {property.streetAddress}
                            </span>
                          </h2>
                        </div>
                        <p className="text-sm text-secondary !mt-[-4px]">
                          {property.suburb}, {property.city}
                        </p>

                        <div className="flex justify-start gap-4">
                          <div className="flex items-center space-x-2">
                            <span className="inline-block">
                              <BiBed className="w-5 h-5" />
                            </span>
                            <span className="text-xs text-neutral">
                              {property.bedrooms}{' '}
                              <span className="hidden sm:inline-block">
                                beds
                              </span>
                            </span>
                          </div>

                          {/* ---- */}
                          <div className="flex items-center space-x-2">
                            <span className="inline-block">
                              <BiBath className="w-5 h-5" />
                            </span>
                            <span className="text-xs text-neutral">
                              {property.bathrooms}{' '}
                              <span className="hidden sm:inline-block">
                                baths
                              </span>
                            </span>
                          </div>

                          {/* ---- */}
                          {property.garages > 0 && (
                            <div className="flex items-center space-x-2">
                              <span className="inline-block">
                                <BiCar className="w-5 h-5" />
                              </span>
                              <span className="text-xs text-neutral">
                                {property.garages}{' '}
                                <span className="hidden sm:inline-block">
                                  garages
                                </span>
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="w-14 border-b border-neutral-100"></div>
                        <div className="flex w-full justify-start items-end gap-2">
                          <span className="flex items-center justify-center px-3 py-2 border border-accent rounded leading-none text-base font-medium text-accent">
                            {toCurrency(tenancy.totalRent)} /{' '}
                            {tenancy.rentalPeriod}
                          </span>

                          <span className="flex items-center justify-center px-3 py-2 border border-primary rounded leading-none text-base font-medium text-primary">
                            {toCurrency(tenancy.bond)} Bond
                          </span>
                        </div>

                        <div className="flex flex-row w-full justify-start items-start md:items-end gap-2">
                          <button
                            className="btn btn-neutral btn-sm mt-2"
                            type="button"
                            onClick={showLease}>
                            Preview Lease
                          </button>

                          <button
                            className="btn btn-neutral btn-sm mt-2"
                            type="button"
                            onClick={showBond}>
                            Preview Bond
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {!Capacitor.isNativePlatform() && (
                  <DocumentPreviewModal
                    isVisible={leaseModalVisible}
                    setIsVisible={setLeaseModalVisible}
                    documentUrl={leaseDocumentUrl}
                    title="Lease Preview"
                  />
                )}

                {!Capacitor.isNativePlatform() && (
                  <DocumentPreviewModal
                    isVisible={bondModalVisible}
                    setIsVisible={setBondModalVisible}
                    documentUrl={bondDocumentUrl}
                    title="Bond Preview"
                  />
                )}

                <div className="md:flex md:justify-center">
                  <SignatureModalInput mode="formik" name="landlordSignature" />
                </div>
              </div>
              <ProgressBox
                nextStep={formik.submitForm}
                previousStep={previousStep}
                step={stepNumber}
                totalSteps={totalSteps}
                previousStepEnabled
                nextStepEnabled={
                  !isLoading && formik.values.landlordSignature.length > 0
                }
              />
            </Form>
          )}
        </Formik>
      </RightContainer>
    </Container>
  );
};

export default PreviewLeaseStep;
