import React, {useState} from 'react';

import {CheckIcon} from '@heroicons/react/outline';
import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';

import {Button} from 'components_sb/buttons';
import {Divider} from 'components_sb/layout';
import {Paragraph, Title} from 'components_sb/typography';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import Listing from 'models/listings/Listing';
import ListingRentalApplication from 'models/listings/ListingRentalApplication';
import RentalApplication from 'models/listings/RentalApplication';
import useAuth from 'services/useAuth';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';

const ListingApplicationCard = ({listing}: {listing: Listing}) => {
  const {currentUser} = useAuth();

  const {activeAccountRole} = useLocalUserSettings();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const queryClient = useQueryClient();

  const {data: rentalApplication, isLoading: rentalApplicationIsLoading} =
    useQuery(
      'user-rental-application-minmal',
      async () => {
        const rentalApp = await RentalApplication.order({
          createdAt: 'desc',
        })
          .includes('rental_application_applicants')
          .select({
            rental_applications: ['id', 'created_at'],
            rental_application_applicants: ['id', 'name'],
          })
          .first();

        return rentalApp.data;
      },
      {enabled: !!currentUser},
    );

  const {data: joinModel, isLoading: joinModelIsLoading} = useQuery(
    `rental-application-listing-${listing.id}`,
    async () => {
      const join = await ListingRentalApplication.where({
        listing_id: listing.id,
        rental_application_id: rentalApplication.id,
      }).first();

      return join.data;
    },
    {enabled: !!rentalApplication},
  );

  const loadingView = () => {
    return (
      <div className="listingSection__wrap shadow-xl mt-2">
        <span className="text-3xl font-semibold">Apply</span>
      </div>
    );
  };

  const confirmApplyForListing = async () => {
    setConfirmationOptions({
      title: 'Apply for Property',
      message: 'Are you sure you want to apply for this property?',
      color: 'success',
      buttonTitle: 'Apply',
      action: applyForListing,
    });
  };

  const applyForListing = async () => {
    const join = new ListingRentalApplication();
    join.listingId = listing.id;
    join.rentalApplicationId = rentalApplication.id;

    const result = await join.save();

    if (result) {
      toast.success('You have successfully applied for this property!');

      queryClient.invalidateQueries(`rental-application-listing-${listing.id}`);
    } else {
      console.log(join.errors);
    }
  };

  if (listing.status === 'completed') {
    return (
      <div className="listingSection__wrap shadow-xl mt-2">
        <Title level="h2">Apply</Title>
        <Paragraph>
          Applications cannot be made for inactive listings.
        </Paragraph>
      </div>
    );
  }

  if (currentUser) {
    if (activeAccountRole === 'Renter') {
      if (!rentalApplicationIsLoading) {
        if (rentalApplication) {
          if (!joinModelIsLoading) {
            // Already applied
            if (joinModel) {
              return (
                <div className="listingSection__wrap shadow-xl mt-2">
                  <Title level="h2">Apply</Title>
                  <div className="flex justify-start gap-2 items-center">
                    <CheckIcon className="w-4 h-4 text-white bg-success rounded-full" />
                    <div>
                      <Paragraph>
                        You applied for this property on{' '}
                        {moment(joinModel.createdAt).format('DD MMMM')}.
                      </Paragraph>
                      {rentalApplication.rentalApplicationApplicants.length >
                        0 && (
                        <Paragraph>
                          With:{' '}
                          {rentalApplication.rentalApplicationApplicants
                            .map((a) => a.name)
                            .join(', ')}
                        </Paragraph>
                      )}
                    </div>
                  </div>
                </div>
              );
            } else {
              // Hasn't applied yet.
              return (
                <div className="listingSection__wrap shadow-xl mt-2">
                  <Title level="h2">Apply</Title>
                  <Paragraph>
                    Click the button below to apply for this property. This will
                    send your most recent application to the landlord.
                  </Paragraph>
                  <Button
                    label="Apply"
                    category="primary"
                    size="base"
                    mode="manual"
                    onClick={confirmApplyForListing}
                  />
                </div>
              );
            }
          } else {
            return loadingView();
          }
        } else {
          return (
            <div className="listingSection__wrap shadow-xl mt-2">
              <Title level="h2">Apply</Title>
              <Paragraph>
                You have not created a rental application yet. You only need to
                create one once to apply for properties.
              </Paragraph>
              <Paragraph>
                Click the button below to set up your rental application.
              </Paragraph>
              <Link to="/rental-applications/new" className="btn btn-neutral">
                Create Application
              </Link>
            </div>
          );
        }
      } else {
        return loadingView();
      }
    } else {
      // Is a landlord
      return (
        <div className="listingSection__wrap shadow-xl mt-2">
          <Title level="h2">Apply</Title>
          <Paragraph>
            You cannot apply for a property with a landlord account.
          </Paragraph>
        </div>
      );
    }
  } else {
    // Not logged in.
    return (
      <div className="listingSection__wrap shadow-xl mt-2">
        <Title level="h2">Apply</Title>
        <Paragraph>
          You need to be logged in to apply for this property.
        </Paragraph>

        {/* TODO: Redirect back to listing after registering/logging in */}
        <Button
          label="Register"
          category="primary"
          size="base"
          mode="link"
          to="/register/tenant"
        />
        <Divider orientation={'horizontal'} labelPosition={'middle'}>
          OR
        </Divider>
        <Button
          label="Log in"
          category="secondary"
          size="base"
          mode="link"
          to="/login"
        />
      </div>
    );
  }
};

export default ListingApplicationCard;
