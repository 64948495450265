import {Model, Attr, BelongsTo, HasMany, HasOne} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Inspection from 'models/inspections/Inspection';
import {Clause} from 'models/properties/ClauseLibrary';
import Document from 'models/properties/Document';
import Property from 'models/properties/Property';
import RentPriceChange from 'models/properties/RentPriceChange';
import TenancyContinuation from 'models/properties/TenancyContinuation';
import TenancyInvite from 'models/properties/TenancyInvite';
import TenancyMembership from 'models/properties/TenancyMembership';
import TenancyRequest from 'models/properties/TenancyRequest';
import ServiceRequest from 'models/service_requests/ServiceRequest';

export type CustomClauses = {
  clauses: string[];
};

@Model()
class Tenancy extends ApplicationRecord {
  static jsonapiType = 'tenancies';

  @Attr() status: string;

  @Attr() bond: number;

  @Attr() startDate: string;
  @Attr() endDate: string;

  @Attr() dayOfWeekRentPaid: string;

  @Attr() landlordSignature: string;

  @Attr() totalRent: number;
  @Attr() rentalPeriod: string;

  @Attr() isNew: boolean;

  @Attr() leaseDocumentType:
    | 'keyhook_new_zealand'
    | 'tenancy_services'
    | null
    | undefined;

  @Attr() nextInspectionDate: string;

  @Attr() weeksRentInAdvance: number;

  @Attr() externalBondId: string;
  @Attr({persist: false}) externalBondFiled: boolean;
  @Attr({persist: false}) readyToBeFiled: boolean;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @Attr() propertyId: string;

  @Attr() headTenantId: string;

  @Attr({persist: false}) originalRenterIds: number[];

  @Attr() customClauses: CustomClauses;
  @Attr() clauses: Clause[];

  @Attr() messageFromLandlord: string | null;

  @BelongsTo('properties') property: Property;

  @HasMany('tenancy_requests') tenancyRequests: TenancyRequest[];
  @HasMany('tenancy_invites') tenancyInvites: TenancyInvite[];
  @HasMany('tenancy_memberships') tenancyMemberships: TenancyMembership[];
  @HasMany('tenancy_memberships') activeTenancyMemberships: TenancyMembership[];
  @HasMany('inspections') inspections: Inspection[];
  @HasMany('documents') documents: Document[];

  @HasMany('tenancy_continuations') tenancyContinuations: TenancyContinuation;
  @HasOne('tenancy_continuations')
  activeTenancyContinuation: TenancyContinuation;

  @HasMany('service_requests') serviceRequests: ServiceRequest[];
  @HasMany('service_requests') openServiceRequests: ServiceRequest[];

  @HasMany('rent_price_changes') rentPriceChanges: RentPriceChange[];
  @HasMany('rent_price_changes') unprocessedRentPriceChanges: RentPriceChange[];

  get isActive(): boolean {
    return this.status === 'active' || this.status === 'active_periodic';
  }
}

export default Tenancy;
