import {useState} from 'react';

import {PhotographIcon} from '@heroicons/react/outline';
import moment from 'moment';
import {useParams} from 'react-router';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LightboxGallery from 'components/common/LightboxGallery';
import DocumentPreviewModal from 'components/document/DocumentPreviewModal';
import PageWrapper from 'components/PageWrapper';
import UserAvatar from 'components/user/UserAvatar';
import ChatMessage from 'models/ChatMessage';
import User from 'models/users/User';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

const DemoServiceRequestDetailPage = () => {
  usePageVisit('DemoServiceRequestDetailPage');

  const {id} = useParams();

  const title =
    id === '1'
      ? 'The kitchen sink is leaking'
      : 'Bedroom light stopped working';
  useTitle(title);

  const [invoiceModalVisible, setInvoiceModalVisible] = useState(false);
  const [messageText, setMessageText] = useState('');

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const confirmApproveQuote = () => {
    setConfirmationOptions({
      title: 'Approve Quote',
      message:
        'Once you approve, Keyhook will coordinate your maintenance and notify you once the job is complete.',
      buttonTitle: 'Approve',
      action: () => 'noop',
      color: 'success',
    });
  };

  const confirmRejectAllQuotes = () => {
    setConfirmationOptions({
      title: 'Reject All Quotes',
      message: "I'll find my own tradesperson.",
      buttonTitle: 'Reject Quotes',
      action: () => 'noop',
      color: 'error',
    });
  };

  const viewInvoice = () => setInvoiceModalVisible(true);

  if (id === '1') {
    const ll = new User({id: '1', name: 'Demo User', avatar: null});
    const t1 = new User({
      id: '2',
      name: 'John Smith',
      avatar:
        'https://storage.googleapis.com/propup-demo-media/people/renter_1.jpeg',
    });

    const messages = [
      new ChatMessage({
        content: 'Are you sure all the washers are tightened properly?',
        user: ll,
        readMarkers: {},
        createdAt: moment().subtract(6, 'days').set({hour: 13, minutes: 5}),
      }),

      new ChatMessage({
        content: 'Yes, I tightened them as much as possible with a spanner.',
        user: t1,
        readMarkers: {},
        createdAt: moment().subtract(6, 'days').set({hour: 18, minutes: 20}),
      }),

      new ChatMessage({
        content: "Ok thanks for confirming, I'll approve the request.",
        user: ll,
        readMarkers: {},
        createdAt: moment().subtract(6, 'days').set({hour: 13, minutes: 5}),
      }),
    ];

    return (
      <PageWrapper title="Maintenance Request">
        <Card className="mt-3">
          <CardBody>
            <CardTitle className="mb-0">The kitchen sink is leaking</CardTitle>
            <span className="text-secondary text-sm block">
              Category: Plumbing
            </span>
            <span className="text-secondary text-sm block">
              Status: Complete
            </span>
            <p className="my-4">
              The kitchen sink has been leaking for a day now, we've tried
              tightening everything that we could. But it still continues to
              leak.
            </p>

            <div>
              <strong>Finalized Cost: </strong>
              <span>$115.00</span>
            </div>

            <div className="my-4">
              <div>
                <button
                  className="btn btn-sm btn-neutral demo-invoice-button"
                  onClick={viewInvoice}
                  type="button">
                  View Invoice
                </button>

                <DocumentPreviewModal
                  isVisible={invoiceModalVisible}
                  setIsVisible={setInvoiceModalVisible}
                  documentUrl="https://storage.googleapis.com/propup-demo-media/service-request-1/invoice.pdf"
                  title="Invoice"
                />
              </div>
            </div>

            <div>
              <strong className="block mb-2">Attachments</strong>

              <LightboxGallery
                sources={[
                  {
                    thumbnail:
                      'https://storage.googleapis.com/propup-demo-media/service-request-1/before-image-1.jpg',
                    source:
                      'https://storage.googleapis.com/propup-demo-media/service-request-1/before-image-1.jpg',
                    type: 'image',
                  },
                  {
                    thumbnail:
                      'https://storage.googleapis.com/propup-demo-media/service-request-1/before-image-2.jpg',
                    source:
                      'https://storage.googleapis.com/propup-demo-media/service-request-1/before-image-2.jpg',
                    type: 'image',
                  },
                  // {
                  //   source:
                  //     'https://storage.googleapis.com/propup-demo-media/service-request-1/before-video.mp4',
                  //   thumbnail:
                  //     'https://storage.googleapis.com/propup-demo-media/service-request-1/before-image-1.jpg',
                  //   type: 'video',
                  // },
                ]}
              />
            </div>

            <div className="mt-4">
              <strong className="block mb-2">
                Attachments ( Post Completion )
              </strong>

              <LightboxGallery
                sources={[
                  {
                    thumbnail:
                      'https://storage.googleapis.com/propup-demo-media/service-request-1/after-image-1.jpg',
                    source:
                      'https://storage.googleapis.com/propup-demo-media/service-request-1/after-image-1.jpg',
                    type: 'image',
                  },
                  {
                    thumbnail:
                      'https://storage.googleapis.com/propup-demo-media/service-request-1/after-image-2.jpg',
                    source:
                      'https://storage.googleapis.com/propup-demo-media/service-request-1/after-image-2.jpg',
                    type: 'image',
                  },
                  // {
                  //   source:
                  //     'https://storage.googleapis.com/propup-demo-media/service-request-1/after-video.mp4',
                  //   type: 'video',
                  // },
                ]}
              />
            </div>
          </CardBody>
        </Card>

        <Card className="mt-4">
          <CardBody>
            <CardTitle>Quote Details</CardTitle>
            <div>
              <strong>Company Name: </strong>
              <span>Simply Plumbing</span>
            </div>
            <div>
              <strong>Phone Number: </strong>
              <span>022 123 5678</span>
            </div>
            <div>
              <strong>Quoted Cost: </strong>
              <span>$115.00</span>
            </div>
          </CardBody>
        </Card>

        <Card className="mt-4 messages-card">
          <CardBody>
            <CardTitle>Messages</CardTitle>

            <p className="text-sm text-secondary mb-4">
              If you need to see any more photos of items for further
              inspection, you can request them here. Message sending is disabled
              in demo mode.
            </p>

            <div>
              {messages.map((item, index) => {
                const dateAgo = moment(item.createdAt).fromNow();

                return (
                  <div className="alert mt-2" key={index}>
                    <div className="flex-1">
                      <UserAvatar user={item.user} size="6" />
                      <label className="ml-2">
                        <h4>{item.user.name}</h4>
                        <p className="text-sm text-base-content text-opacity-60">
                          {item.content}
                        </p>

                        <p className="text-xs text-base-content text-opacity-60 italic">
                          {dateAgo}
                        </p>
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="flex justify-between items-center mt-4">
              <input
                type="file"
                id="chatable-image-hidden-input"
                accept=".png,.jpeg,.jpg"
                disabled
                className="hidden"
              />

              <button className="btn btn-neutral mr-2" type="button" disabled>
                <PhotographIcon className="text-white w-5 h-5" />
              </button>

              <textarea
                className="textarea textarea-bordered flex-grow"
                placeholder="Write a message..."
                value={messageText}
                onChange={(e) => setMessageText(e.currentTarget.value)}
                maxLength={300}
              />

              <button className="btn btn-neutral ml-2" type="button" disabled>
                Send
              </button>
            </div>
          </CardBody>
        </Card>
      </PageWrapper>
    );
  } else if (id === '2') {
    const ll = new User({id: '1', name: 'Demo User', avatar: null});
    const t1 = new User({
      id: '2',
      name: 'John Smith',
      avatar:
        'https://storage.googleapis.com/propup-demo-media/people/renter_1.jpeg',
    });

    const messages = [
      new ChatMessage({
        content: 'Did a power surge happen at all?',
        user: ll,
        readMarkers: {},
        createdAt: moment().subtract(1, 'days').set({hour: 13, minutes: 5}),
      }),

      new ChatMessage({
        content:
          'Not that we have been able to tell, every outlet and appliance is working normally.',
        user: t1,
        readMarkers: {},
        createdAt: moment().subtract(1, 'days').set({hour: 18, minutes: 20}),
      }),
    ];

    return (
      <PageWrapper title="Maintenance Request">
        <Card className="mt-3 service-request-info">
          <CardBody className="gap-0">
            <CardTitle className="mb-0">
              Bedroom light stopped working
            </CardTitle>
            <span className="text-secondary text-sm block">
              Category: Electrical
            </span>
            <span className="text-secondary text-sm block">
              Status: Awaiting Quote Acceptance
            </span>
            <p className="my-4">
              The light in the master bedroom has stopped working, we have tried
              replacing the light bulb and have checked that none of the fuses
              have been flipped.
            </p>

            <div>
              <strong className="block mb-2">Attachments</strong>

              <LightboxGallery
                sources={[
                  {
                    thumbnail:
                      'https://storage.googleapis.com/propup-demo-media/service-request-2/image-1.jpeg',
                    source:
                      'https://storage.googleapis.com/propup-demo-media/service-request-2/image-1.jpeg',
                    type: 'image',
                  },
                  {
                    thumbnail:
                      'https://storage.googleapis.com/propup-demo-media/service-request-2/image-2.jpeg',
                    source:
                      'https://storage.googleapis.com/propup-demo-media/service-request-2/image-2.jpeg',
                    type: 'image',
                  },
                  {
                    thumbnail:
                      'https://storage.googleapis.com/propup-demo-media/service-request-2/image-3.jpeg',
                    source:
                      'https://storage.googleapis.com/propup-demo-media/service-request-2/image-3.jpeg',
                    type: 'image',
                  },
                ]}
              />
            </div>
          </CardBody>
        </Card>

        <Card className="mt-4 quotes-card">
          <CardBody>
            <CardTitle>Quotes</CardTitle>
            <p>Keyhook has found the following quotes:</p>

            <div>
              <div className="flex justify-between items-center my-2">
                <div>
                  <strong className="block">James Electrical</strong>
                  <span className="block text-secondary">
                    Quoted Amount: $60.00
                  </span>
                  <span className="block text-secondary">
                    Expected Date:{' '}
                    {moment().add('3', 'days').format(DATE_FORMAT)}
                  </span>
                </div>
                <div className="ml-2 float-right">
                  <button
                    className="btn btn-sm btn-success bg-success demo-accept-button"
                    type="button"
                    onClick={confirmApproveQuote}>
                    Accept
                  </button>
                </div>
              </div>
              <hr className="border border-gray-200" />
            </div>

            <div>
              <div className="flex justify-between items-center my-2">
                <div>
                  <strong className="block">Electricians R Us</strong>
                  <span className="block text-secondary">
                    Quoted Amount: $90.00
                  </span>
                  <span className="block text-secondary">
                    Expected Date:{' '}
                    {moment().add('1', 'day').format(DATE_FORMAT)}
                  </span>
                </div>
                <div className="ml-2 float-right">
                  <button
                    className="btn btn-sm btn-success bg-success"
                    type="button"
                    onClick={confirmApproveQuote}>
                    Accept
                  </button>
                </div>
              </div>
              <hr className="border border-gray-200" />
            </div>

            <div>
              <div className="flex justify-between items-center my-2">
                <div>
                  <strong className="block">Sparkies</strong>
                  <span className="block text-secondary">
                    Quoted Amount: $70.00
                  </span>
                  <span className="block text-secondary">
                    Expected Date:{' '}
                    {moment().add('2', 'days').format(DATE_FORMAT)}
                  </span>
                </div>
                <div className="ml-2 float-right">
                  <button
                    className="btn btn-sm btn-success bg-success"
                    type="button"
                    onClick={confirmApproveQuote}>
                    Accept
                  </button>
                </div>
              </div>
              <hr className="border border-gray-200" />
            </div>

            <button
              className="btn btn-md btn-neutral mt-4"
              type="button"
              onClick={confirmRejectAllQuotes}>
              Reject Maintenance Request
            </button>
          </CardBody>
        </Card>

        <Card className="mt-4">
          <CardBody>
            <CardTitle>Messages</CardTitle>

            <p className="text-sm text-secondary mb-4">
              If you need to see any more photos of the problem, you can request
              them here. Message sending is disabled in demo mode.
            </p>

            <div>
              {messages.map((item, index) => {
                const dateAgo = moment(item.createdAt).fromNow();

                return (
                  <div className="alert mt-2" key={index}>
                    <div className="flex-1">
                      <UserAvatar user={item.user} size="6" />
                      <label className="ml-2">
                        <h4>{item.user.name}</h4>
                        <p className="text-sm text-base-content text-opacity-60">
                          {item.content}
                        </p>

                        <p className="text-xs text-base-content text-opacity-60 italic">
                          {dateAgo}
                        </p>
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="flex justify-between items-center mt-4">
              <input
                type="file"
                id="chatable-image-hidden-input"
                accept=".png,.jpeg,.jpg"
                disabled
                className="hidden"
              />

              <button className="btn btn-neutral mr-2" type="button" disabled>
                <PhotographIcon className="text-white w-5 h-5" />
              </button>

              <textarea
                className="textarea textarea-bordered flex-grow"
                placeholder="Write a message..."
                value={messageText}
                onChange={(e) => setMessageText(e.currentTarget.value)}
                maxLength={300}
              />

              <button className="btn btn-neutral ml-2" type="button" disabled>
                Send
              </button>
            </div>
          </CardBody>
        </Card>
      </PageWrapper>
    );
  } else {
    return null;
  }
};

export default DemoServiceRequestDetailPage;
