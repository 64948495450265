const PROPERTY_INDEX_SETTINGS_KEY = 'property_index_settings';

export type PropertyIndexSettings = {
  page: number;
  perPage: number;
  viewType: string;
  sortField: string;
  sortDirection: string;
};

export class GlobalUIService {
  static setPropertyIndexSettings = (settings: PropertyIndexSettings): void => {
    localStorage[PROPERTY_INDEX_SETTINGS_KEY] = JSON.stringify(settings);
  };

  static getPropertyIndexSettings = (): PropertyIndexSettings => {
    const settings = localStorage[PROPERTY_INDEX_SETTINGS_KEY];
    if (settings) {
      return JSON.parse(settings) as PropertyIndexSettings;
    } else {
      return {
        page: 0,
        perPage: 10,
        viewType: 'list',
        sortField: 'createdAt',
        sortDirection: 'desc',
      } as PropertyIndexSettings;
    }
  };
}
