import {Fragment, useCallback, useEffect, useRef} from 'react';

import {Menu, Transition} from '@headlessui/react';
import useResizeObserver from '@react-hook/resize-observer';
import clsx from 'clsx';
import {useNavigate} from 'react-router';

import keyhookLogo from 'assets/img/logo/logo-main.png';
import {useMobileDrawer} from 'components/navbar/MobileDrawer';
// import useAtTop from 'hooks/useAtTop';
import UserAvatar from 'components/user/UserAvatar';
import {Button} from 'components_sb/buttons';
import {NumberBadge} from 'components_sb/feedback';
import {Link} from 'components_sb/navigation';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import GhostingUserWarning from 'pages/shared/ghost/GhostingUserWarning';
import {usePusherService, ChatChannel} from 'services/PusherService';
import useAuth from 'services/useAuth';
import {useNavItemBadgeCount} from 'utilities/nav-links/NavItemBadgeCount';
import useNavItems from 'utilities/nav-links/useNavItems';

/**
 * The breakpoint at which the nav bar layout changes from
 * mobile (drawer) to desktop (within nav bar).
 */
const DESKTOP_LAYOUT_BREAKPOINT = 'md';

/**
 *  The main site-wide navigation bar.
 */
const NavBar = () => {
  // const atTopOfPage = useAtTop();
  const elementRef = useRef();

  // Set header height as a CSS variable and update on resize
  useResizeObserver(elementRef, ({contentRect: {height}}) => {
    document.documentElement.style.setProperty(
      '--nav-height',
      `${height * 0.01}px`,
    );
  });

  const {userIsLoggedIn, currentUser, logOutUser} = useAuth();

  // ========================================================================
  // Unread Messages Badge Count Handling
  // ========================================================================
  const {channels} = usePusherService();
  const [totalUnreadChatCount, setTotalUnreadChatCount] =
    useNavItemBadgeCount('chat');

  const calculateUnreadCount = useCallback(() => {
    if (channels.length > 0) {
      const val = (channels as ChatChannel[])
        .map((chan) => chan.unreadCount || 0)
        .reduce((prevVal, currentVal) => prevVal + currentVal, 0);
      if (totalUnreadChatCount !== val) {
        setTotalUnreadChatCount(val);
      }
    } else {
      if (totalUnreadChatCount !== 0) {
        setTotalUnreadChatCount(0);
      }
    }
  }, [channels, totalUnreadChatCount, setTotalUnreadChatCount]);

  /**
   * Calculate the unread message count when the channels update.
   */
  useEffect(() => {
    calculateUnreadCount();
  }, [channels, calculateUnreadCount]);
  // ========================================================================

  const navItems = useNavItems();

  const mobileDrawerToggleButton = useMobileDrawer({
    breakpoint: DESKTOP_LAYOUT_BREAKPOINT,
    navItems,
  });

  const desktopLayout = useTailwindBreakpoint(DESKTOP_LAYOUT_BREAKPOINT);

  return (
    <div
      id="page-navbar"
      ref={elementRef}
      className={clsx(
        'z-10 sticky top-0 flex-shrink-0 flex flex-col',
        // atTopOfPage ? 'border-opacity-0' : 'border-opacity-100',
      )}>
      <GhostingUserWarning />
      <div
        className={clsx(
          'h-20 lg:h-24 flex gap-x-12 items-center px-6 sm:px-8 md:px-10 lg:px-16',
          'bg-white border-brand-50 border-b-2',
        )}>
        <div className="flex-shrink-0">
          <a href="https://www.keyhook.com/">
            <img
              src={keyhookLogo}
              alt="Keyhook"
              className="h-10 xxs:h-12 lg:h-14 w-auto"
            />
          </a>
        </div>
        <div className="flex-1 flex justify-end">
          {desktopLayout ? (
            <>
              <div className="flex-1 flex flex-row items-center gap-x-10">
                {navItems.map((navItem) => {
                  return navItem.subItems ? (
                    <Menu key={navItem.id} as="div" className="relative">
                      <div>
                        <Menu.Button className="max-w-xs rounded-full flex items-center">
                          <span className="sr-only">Open listings menu</span>
                          <a className="text-brand-500 hover:text-brand-400">
                            Listings
                          </a>
                          <NumberBadge count={navItem.badgeCount} />
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95">
                          <Menu.Items
                            className={clsx(
                              'origin-top left-0 absolute w-48 mt-2 z-50',
                              'rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none',
                              'py-4 px-6 flex flex-col gap-y-6',
                            )}>
                            {navItem.subItems.map(
                              ({id, label, to, badgeCount}) => (
                                <Menu.Item key={id}>
                                  <span className="flex flex-row">
                                    <Link to={to}>{label}</Link>
                                    <NumberBadge
                                      count={badgeCount}
                                      positioning="inline"
                                    />
                                  </span>
                                </Menu.Item>
                              ),
                            )}
                          </Menu.Items>
                        </Transition>
                      </div>
                    </Menu>
                  ) : (
                    <Link key={navItem.id} to={navItem.to}>
                      <span>{navItem.label}</span>
                      <NumberBadge count={navItem.badgeCount} />
                    </Link>
                  );
                })}
              </div>
              {userIsLoggedIn ? (
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button
                      className={clsx(
                        'rounded-full flex items-center text-sm',
                        'transition-all duration-300',
                        'opacity-100 hover:opacity-90 active:opacity-100',
                      )}>
                      <span className="sr-only">Open user menu</span>
                      <UserAvatar user={currentUser} size="10" />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items
                        className={clsx(
                          'origin-top right-0 absolute w-48 mt-2 z-50',
                          'rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none',
                          'py-4 px-6 flex flex-col gap-y-6',
                        )}>
                        <Menu.Item>
                          <Menu.Item>
                            <Link to="/account/my-account">My Account</Link>
                          </Menu.Item>
                        </Menu.Item>
                        <Menu.Item>
                          <Menu.Item>
                            <Link to="/account/settings">Settings</Link>
                          </Menu.Item>
                        </Menu.Item>
                        {currentUser.roles.includes('Landlord') && (
                          <Menu.Item>
                            <Link to="/account/subscription">Subscription</Link>
                          </Menu.Item>
                        )}
                        <Menu.Item>
                          <button
                            onClick={logOutUser}
                            className={clsx(
                              'inline-block text-start transition-color duration-300 text-brand-500 hover:text-brand-400',
                            )}>
                            Log out
                          </button>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </div>
                </Menu>
              ) : (
                <div className="flex flex-row items-center gap-x-4">
                  <Button
                    label="Log in"
                    category="secondary"
                    size="base"
                    mode="link"
                    to="/login"
                  />
                  <Button
                    label="Register"
                    category="primary"
                    size="base"
                    mode="link"
                    to="/welcome"
                  />
                </div>
              )}
            </>
          ) : (
            mobileDrawerToggleButton
          )}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
