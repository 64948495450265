

import {AiOutlineInfoCircle} from '@react-icons/all-files/ai/AiOutlineInfoCircle';
import {Link} from 'react-router-dom';

import Property from 'models/properties/Property';

const ManageListingCard = ({property}: {property: Property}) => {
  if (property && property.listings.length > 0) {
    const listing = property.listings[0];
    const link = `listings/${listing.publicId}`;
    if (listing.status === 'pending_approval') {
      return (
        <Link to={link}>
          <div className="alert alert-info shadow-lg bg-[#232A59] text-brand-25 cursor-pointer mt-2">
            <div className="flex justify-between items-center w-full">
              <div className="flex justify-start gap-2">
                <AiOutlineInfoCircle className="w-6 h-6" />
                <span>
                  Your listing has been submitted and is pending approval. You
                  will be notified once its approved.
                </span>
              </div>
              <button className="btn btn-xs bg-white text-black">
                Manage Listing
              </button>
            </div>
          </div>
        </Link>
      );
    } else if (listing.status === 'active') {
      return (
        <Link to={link}>
          <div className="alert alert-info shadow-lg bg-[#232A59] text-brand-25 cursor-pointer mt-2">
            <div className="flex justify-between items-center w-full">
              <div className="flex justify-start gap-2">
                <AiOutlineInfoCircle className="w-6 h-6" />
                <span>
                  Your listing is live on Keyhook Listings, click here to manage
                  your listing.
                </span>
              </div>
              <button className="btn btn-xs bg-white text-black">
                Manage Listing
              </button>
            </div>
          </div>
        </Link>
      );
    }
  } else {
    return null;
  }
};

export default ManageListingCard;
