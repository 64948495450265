import {useMemo} from 'react';

import {Capacitor} from '@capacitor/core';
import {Navigate, Outlet, useLocation} from 'react-router';

import useAuth from 'services/useAuth';

const LoggedInRoute = () => {
  const {userIsLoggedIn, currentUser} = useAuth();
  const location = useLocation();

  const emailRequiresSetting = useMemo(
    () =>
      userIsLoggedIn && currentUser.email.endsWith('@privaterelay.appleid.com'),
    [userIsLoggedIn, currentUser],
  );

  if (!userIsLoggedIn) {
    localStorage.setItem('back-url', window.location.pathname);
    if (Capacitor.isNativePlatform()) {
      return <Navigate to="/welcome" />;
    } else {
      return <Navigate to="/login" />;
    }
  }

  if (emailRequiresSetting && location.pathname !== '/register/set-email') {
    return <Navigate to="/register/set-email" />;
  }

  return <Outlet />;
};

export default LoggedInRoute;
