// @ts-nocheck

import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import Toggle from 'react-toggle';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import UserAvatar from 'components/user/UserAvatar';
import User from 'models/users/User';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

const descriptions = {
  general: {
    new_chat_message:
      'When you receive a direct chat message or property chat message.',
  },
  landlord: {
    property_general: 'General info about your property',
    rent_payment_sent: 'When we send your rent payment to you',
    inspection_reminders: 'Be reminded when inspections are soon',
    inspection_general:
      'Be notified at moments like a tenant confirming a time',
    tenancy_confirmed:
      'When a tenant approves your request and signs the lease',
    tenancy_expiring: 'When a tenancy is nearly expired',
    tenancy_change_requested:
      'When a tenant requests to change their lease to someone else',
    new_service_request: 'When a tenant submits a new maintenance request.',
    service_request_updated:
      'When a maintenance request is updated with a quote or new details.',
    subscription_information:
      'When there are changes to your Keyhook subscription',
  },
  renter: {
    rent_received: 'When we receive a rent payment from you',
    bond_received: 'When we receive any bond payments from you',
    inspection_reminders: 'Be reminded when inspections are soon',
    inspection_general:
      'Be notified at moments like your landlord confirming a time',
    tenancy_request_received:
      'Be notified when a landlord sends a tenancy offer',
    tenancy_expiring: 'When a tenancy is nearly expired',
    tenancy_change_confirmed:
      'When your landlord approves your request to change your lease to someone else',
    service_request_updated: 'When a maintenance request is updated',
    rent_changed: 'When the rent for your tenancy is changed by your landlord.',
  },
};

const SettingsPage = () => {
  useTitle('Settings');
  usePageVisit('SettingsPage');

  const {currentUser} = useAuth();

  const {isLoading, data, error} = useQuery('user-settings', async () => {
    const user = await User.select(['notificationOptions']).find(
      currentUser.id,
    );

    return user.data;
  });

  const queryClient = useQueryClient();

  // const [changeSettingMutation] = useMutation(
  //   async ({
  //     user,
  //     sectionKey,
  //     rowKey,
  //     type,
  //     value,
  //   }: {
  //     user: User;
  //     sectionKey: string;
  //     rowKey: string;
  //     type: string;
  //     value: boolean;
  //   }) => {
  //     user.notificationOptions[sectionKey][rowKey][type] = value;
  //     queryClient.setQueryData('user-settings', user);

  //     await user.save();

  //     return user;
  //   },
  // );

  const toggleNotificationSetting = async (
    sectionKey: string,
    rowKey: string,
    type: string,
  ) => {
    const user: User = data;
    user.notificationOptions[sectionKey][rowKey][type] =
      !user.notificationOptions[sectionKey][rowKey][type];
    queryClient.setQueryData('user-settings', user);

    await user.save();
  };

  const sections = () => {
    const sections = [{key: 'general', name: 'General'}];

    if (currentUser?.roles.indexOf('Landlord') !== -1) {
      sections.push({key: 'landlord', name: 'Landlord'});
    }
    if (currentUser?.roles.indexOf('Renter') !== -1) {
      sections.push({key: 'renter', name: 'Renter'});
    }
    if (currentUser?.roles.indexOf('ServicePerson') !== -1) {
      sections.push({key: 'service_person', name: 'Service Person'});
    }

    return sections;
  };

  const renderRow = (sectionKey: string, rowKey: string) => {
    const rowItem = data?.notificationOptions[sectionKey][rowKey];
    const description =
      descriptions[sectionKey] && descriptions[sectionKey][rowKey];

    return (
      <div key={rowKey}>
        <div className="flex justify-between items-start pb-2">
          <div>
            <strong className="text-sm">{rowItem.name}</strong>
            <p className="text-secondary text-sm">{description}</p>
          </div>

          <div className="flex">
            <div>
              <span className="block text-sm text-secondary">App</span>
              <Toggle
                checked={rowItem.push}
                onChange={() =>
                  toggleNotificationSetting(sectionKey, rowKey, 'push')
                }
              />
            </div>
            <div className="ml-4">
              <span className="block text-sm text-secondary">Email</span>
              <Toggle
                checked={rowItem.email}
                onChange={() =>
                  toggleNotificationSetting(sectionKey, rowKey, 'email')
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSection = (section) => {
    const rows = data?.notificationOptions[section.key];
    const keys = Object.keys(rows);

    return (
      <div className="mt-2" key={section.key}>
        <h5 className="text-xl font-semibold">{section.name}</h5>
        {keys.map((row) => renderRow(section.key, row))}
        <hr className="bg-gray-200 w-full" />
      </div>
    );
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Settings">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    const items = sections();
    return (
      <PageWrapper title="Settings">
        <Card className="mt-2">
          <CardBody>
            <div className="flex justify-start items-end">
              <div>
                <UserAvatar user={currentUser} size="10" />
              </div>
              <div className="ml-3">
                <CardTitle className="mb-0">
                  {currentUser.name}'s Settings
                </CardTitle>
                <p>{currentUser.email}</p>
                <p className="text-secondary text-sm mb-2">
                  Keyhook Member Since{' '}
                  {moment(data.createdAt).format(DATE_FORMAT)}
                </p>
              </div>
            </div>
          </CardBody>
        </Card>

        <Card className="mt-3">
          <CardBody>
            <CardTitle className="mb-0">Notification Options</CardTitle>
            <p>
              We allow you to fully customize what you would like to receive
              notifications about.
            </p>
            <p>
              We highly recommend that you keep the defaults on, in order to not
              miss any important information.
            </p>

            {items.map(renderSection)}
          </CardBody>
        </Card>
      </PageWrapper>
    );
  }
};

export default SettingsPage;
