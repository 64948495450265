import {useCallback, useEffect, useState} from 'react';

import {
  type FormikHelpers,
  Formik,
  Form,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import {PersistFormikValues} from 'formik-persist-values';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import AddTenantsSection from 'components/tenancy/AddTenantsSection';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightSideText from 'components/walkthrough/common/RightSideText';
import {Button} from 'components_sb/buttons';
import Property from 'models/properties/Property';
import TenancyRequest from 'models/properties/TenancyRequest';
import TrackingService from 'services/TrackingService';

type FormTenancyRequest = {
  renterEmail: string;
};

const tenancyInviteSchema = Yup.object().shape({
  messageFromLandlord: Yup.string().nullable().optional(),
  tenancyRequests: Yup.array()
    .of(
      Yup.object().shape({
        renterEmail: Yup.string()
          .email()
          .required('Required')
          .label('Renter Email')
          .test('Unique', 'Email needs te be unique', function (val) {
            const ctx = this.options.context;

            if (ctx) {
              const reqs = ctx.tenancyRequests as FormTenancyRequest[];
              if (reqs.filter((r) => r.renterEmail === val).length > 1) {
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          }),
      }),
    )
    .required('Must have tenant invites'),
});

type FormValues = {
  numberOfTenants: number;
  messageFromLandlord?: string | null;
  tenancyRequests: FormTenancyRequest[];
};

const InviteTenantsStep = ({
  property,
  stepNumber,
  totalSteps,
}: {
  property: Property;
  stepNumber: number;
  totalSteps: number;
}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_StartInviteTenantsStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const tenancy = property.tenancies[0];
  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleSubmit = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    setIsLoading(true);

    if (
      formValues.messageFromLandlord &&
      formValues.messageFromLandlord.length > 0
    ) {
      tenancy.messageFromLandlord = formValues.messageFromLandlord;
    }

    const requestCount = formValues.tenancyRequests.length;
    tenancy.tenancyRequests = formValues.tenancyRequests.map(
      (req: FormTenancyRequest, index: number) => {
        const request = new TenancyRequest(req);
        if (requestCount > 1) {
          request.isHeadTenant = index === 0;
        } else {
          request.isHeadTenant = false;
          request.rentSplit = tenancy.totalRent;
          request.bondSplit = tenancy.bond;
        }

        return request;
      },
    );

    tenancy.status = 'pending';

    const result = await tenancy.save({with: 'tenancyRequests'});
    if (result) {
      property.lastOnboardingStepCompleted = 'new_invite_tenants';
      await property.save();

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.NewTenancy_CompleteInviteTenantsStep,
        {
          propertyId: property.id,
        },
      );

      /**
       * Track completion of the flow.
       */
      TrackingService.trackEvent(
        TrackingService.Event.NewTenancy_CompleteFlow,
        {
          propertyId: property.id,
        },
      );

      await queryClient.invalidateQueries(
        `landlord-property-detail-${property.id}`,
      );
      localStorage.removeItem(`property-${property.id}-new-invite-tenants`);
      toast.success('Your lease has been successfully sent to your tenant(s).');
      navigate(`/properties/${property.id}`, {replace: true});
    }

    setIsLoading(false);
  };

  const previousStep = async () => {
    property.lastOnboardingStepCompleted = 'new_preview_lease';
    await property.save();

    queryClient.setQueryData(['new-property', property.id], property);
  };

  const onSaveForLater = useCallback(() => {
    /**
     * Track that the step has been saved for later.
     */
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_SaveInviteTenantsStep,
      {
        propertyId: property.id,
      },
    );

    /**
     * Track completion of the flow.
     */
    TrackingService.trackEvent(TrackingService.Event.NewTenancy_CompleteFlow, {
      propertyId: property.id,
    });

    /**
     * Navigate the user to their dashboard.
     */
    navigate('/');
  }, [navigate, property.id]);

  const title = 'Send your tenants the lease';
  const subtitle =
    'Be sure to include all tenants who will appear on the lease';

  return (
    <Container>
      <LeftSide title={title} subtitle={subtitle} />
      <RightContainer>
        <div className="mt-[-16px] mb-8 flex justify-end w-52 ml-auto p-4">
          <Button
            label="Save for later"
            mode="manual"
            category="secondary"
            size="sm"
            onClick={onSaveForLater}
          />
        </div>

        <RightSideText title={title} />
        <Formik
          initialValues={
            {
              numberOfTenants: 1,
              messageFromLandlord: undefined,
              tenancyRequests: [{renterEmail: ''}] as FormTenancyRequest[],
            } as FormValues
          }
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validate={(value) => {
            try {
              validateYupSchema(value, tenancyInviteSchema, true, value);
            } catch (err) {
              return yupToFormErrors(err); //for rendering validation errors
            }

            return {};
          }}>
          {(formik) => (
            <Form>
              <div className="flex flex-col md:mx-16 space-y-8">
                <AddTenantsSection formik={formik} />
              </div>
              <ProgressBox
                nextStep={formik.submitForm}
                previousStep={previousStep}
                step={stepNumber}
                totalSteps={totalSteps}
                previousStepEnabled
                nextStepEnabled={!isLoading}
              />

              <PersistFormikValues
                name={`property-${property.id}-new-invite-tenants`}
                persistInvalid
              />
            </Form>
          )}
        </Formik>
      </RightContainer>
    </Container>
  );
};

export default InviteTenantsStep;
