import {Capacitor} from '@capacitor/core';
import {AiOutlineRight} from '@react-icons/all-files/ai/AiOutlineRight';
import moment from 'moment';
import {useQuery} from 'react-query';
import {useParams} from 'react-router';
import {Link} from 'react-router-dom';

import emptyImage from 'assets/img/empty_dataset_images/chat.svg';
import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import EmptyDataSet from 'components/common/EmptyDataSet';
import LoadingView from 'components/common/LoadingView';
import ManageListingMenu from 'components/listing/ManageListingMenu';
import PageWrapper from 'components/PageWrapper';
import EnquiryThread from 'models/listings/EnquiryThread';
import Listing from 'models/listings/Listing';
import {DATE_TIME_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';

const EnquiryThreadIndexPage = () => {
  usePageVisit('EnquiryThreadIndexPage');
  const {propertyId, listingId} = useParams();

  const {data, error, isLoading} = useQuery(
    `listing-${listingId}-enquiries`,
    async () => {
      const listing = await Listing.find(listingId);

      const enquiries = await EnquiryThread.where({listingId: listing.data.id})
        .includes({enquiry_messages: 'user'})
        .order({updated_at: 'desc'})
        .per(100)
        .all();

      return enquiries.data;
    },
  );

  const buttons = Capacitor.isNativePlatform()
    ? []
    : [{text: 'View Listing Ad', href: `/listings/${listingId}`}];

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Listing Enquiries">
        <ManageListingMenu propertyId={propertyId} listingId={listingId} />
        <LoadingView />
      </PageWrapper>
    );
  } else {
    if (data.length > 0) {
      return (
        <PageWrapper title="Listing Enquiries" buttons={buttons}>
          <ManageListingMenu propertyId={propertyId} listingId={listingId} />

          {data.map((thread) => {
            const lastMessage =
              thread.enquiryMessages[thread.enquiryMessages.length - 1];
            return (
              <Link to={thread.id} key={thread.id}>
                <Card className="mb-2 cursor-pointer">
                  <CardBody className="p-4">
                    <div className="flex justify-between items-center">
                      <div>
                        <CardTitle className="mb-0">{thread.name}</CardTitle>
                        <span className="block text-secondary text-sm">
                          {thread.email}
                        </span>
                        <span className="block text-secondary text-sm">
                          Last Message Received at{' '}
                          {moment(thread.updatedAt).format(DATE_TIME_FORMAT)}
                        </span>

                        <p className="mt-1">
                          {lastMessage ? lastMessage.message : null}
                        </p>
                      </div>
                      <div>
                        <AiOutlineRight className="w-5 h-5 text-secondary-300" />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Link>
            );
          })}
        </PageWrapper>
      );
    } else {
      return (
        <PageWrapper title="Listing Enquiries" buttons={buttons}>
          <ManageListingMenu propertyId={propertyId} listingId={listingId} />
          <div className="mt-4">
            <EmptyDataSet
              title="No Enquiries"
              message="You have not received any enquiries for this listing yet"
              image={emptyImage}
            />
          </div>
        </PageWrapper>
      );
    }
  }
};

export default EnquiryThreadIndexPage;
