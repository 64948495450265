import {HTMLProps} from 'react';

import {type FormikProps} from 'formik';

import {errorsFor, valueFor, isTouched} from 'components/forms_fields/Helpers';
import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import TrackingService from 'services/TrackingService';

interface InputFieldProps extends HTMLProps<HTMLInputElement> {
  formik: FormikProps<any>;
  label: string;
  name: string;
  helpText?: string;
  prefixLabel?: string;
}

const InputField = ({
  formik,
  label,
  name,
  helpText,
  prefixLabel,
  children,
  ...rest
}: InputFieldProps) => {
  const errors = errorsFor(formik, name);
  const value = valueFor(formik, name);
  const touched = isTouched(formik, name);

  const classN = errors && touched ? ' border border-red-500' : '';
  if (rest.className) {
    rest.className += classN;
  } else {
    rest.className = classN;
  }
  rest.className = 'input input-bordered w-full ' + rest.className;

  if (!rest.type) {
    rest.type = 'text';
  }

  const handleBlur = () => {
    formik.handleBlur(name);

    const v = value as string | undefined | null;
    if (v && v.toString().length > 0) {
      TrackingService.trackEvent(TrackingService.Event.FillField, {
        field: name,
      });
    }
  };

  if (children) {
    return (
      <div className="form-control">
        <label className="label flex justify-start items-center">
          <span className="label-text mr-2">{label}</span>
          <HelpTextPresenter helpText={helpText} />
        </label>
        <div className="input-group">
          <input
            name={name}
            onChange={(e) => formik.setFieldValue(name, e.target.value)}
            value={value}
            onBlur={handleBlur}
            {...rest}
          />
          {children}
        </div>

        {errors && touched && (
          <span className="text-red-500">
            <small>{errors}</small>
          </span>
        )}
      </div>
    );
  } else {
    return (
      <div className="form-control">
        {label && (
          <label className="label flex justify-start items-center">
            <span className="label-text mr-2">{label}</span>
            <HelpTextPresenter helpText={helpText} />
          </label>
        )}
        <label className="input-group">
          {prefixLabel ? <span>{prefixLabel}</span> : null}
          <input
            name={name}
            onChange={(e) => formik.setFieldValue(name, e.target.value)}
            value={value}
            onBlur={handleBlur}
            {...rest}
          />
        </label>

        {errors && touched && (
          <span className="text-red-500">
            <small>{errors}</small>
          </span>
        )}
      </div>
    );
  }
};

export default InputField;
