import {motion} from 'framer-motion';
import {Outlet} from 'react-router';

import FooterLarge from 'components/footer/FooterLarge';
import ScrollContainer from 'components_sb/layout/ScrollContainer/ScrollContainer';

import NavBar from '../NavBar/NavBar';

const MainLayout = () => {
  return (
    <div className="absolute top-0 left-0 w-full h-screen min-h-screen max-h-screen flex flex-col">
      <NavBar />
      <ScrollContainer id="root-scroll-container" data-tour="page-content">
        <motion.div
          className="w-full bg-white min-h-content flex flex-col justify-center items-center"
          initial={{
            opacity: 0,
            y: 40,
          }}
          animate={{
            transition: {
              ease: 'easeOut',
              duration: 0.4,
            },
            opacity: 1,
            y: 0,
          }}
          exit={{
            transition: {
              ease: 'easeIn',
              duration: 0.2,
            },
            opacity: 0,
            y: -80,
          }}>
          <Outlet />
        </motion.div>
        <FooterLarge />
      </ScrollContainer>
    </div>
  );
};

export default MainLayout;
