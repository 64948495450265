import React, {
  useCallback,
  useImperativeHandle,
  useRef,
  useEffect,
  useState,
} from 'react';

import {Capacitor} from '@capacitor/core';
import {Network} from '@capacitor/network';
import {Form, useFormik, type FormikHelpers, Formik} from 'formik';
import {isEmpty} from 'lodash';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {
  TextareaField,
  ToggleField,
  SubmitButton,
} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import InspectionMediaPreview from 'components/inspection/media_items/InspectionMediaPreview';
import {InlineError} from 'components_sb/feedback';
import {ModalDefinition, Modal} from 'components_sb/layout';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import User from 'models/users/User';
import {useInspectionUploader} from 'providers/InspectionUploader';
import useAuth from 'services/useAuth';
import useInspectionStore, {PendingUploadRow} from 'stores/InspectionStore';

type FormValues = {
  action: string;
  actionType: 'landlord' | 'tenant' | null;
  shouldCreateMaintenanceRequest: boolean;
};

const InspectionAddActionPhotoModal: ModalDefinition = {
  title: 'Add an action',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'save',
        label: {
          idle: 'Save',
          loading: 'Saving',
        },
        handle: 'onSave',
        closeOnSuccess: false,
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const {closeModal, inspectionItem, inspection, propertyId, filePath} =
      props;
    const {currentUser} = useAuth();
    const {activeAccountRole} = useLocalUserSettings();

    const inspectionUploader = useInspectionUploader();

    const [networkConnected, setNetworkConnected] = useState<boolean>(true);

    const database = useInspectionStore((state) => state.database);

    useEffect(() => {
      Network.addListener('networkStatusChange', (status) => {
        setNetworkConnected(status.connected);
      });

      return () => {
        Network.removeAllListeners();
      };
    }, []);

    const handleSubmit = useCallback(
      async (formValues: FormValues, formikHelpers: any) => {
        const doc = {
          data: filePath,
          attachmentType: 'action',
          inspectionId: inspection.id,
          inspectionItemId: inspectionItem.id,
          notes: null,
          actionParams: {
            action: formValues.action,
            actionType: formValues.actionType,
            shouldCreateMaintenanceRequest:
              formValues.shouldCreateMaintenanceRequest,
          },
          _id: new Date().getTime().toString(),
        } as PendingUploadRow;
        const result = await database.put(doc);
        if (result.ok && networkConnected) {
          if (!inspectionUploader.isRunning) {
            inspectionUploader.processQueue(inspection.id);
          }
          formikHelpers.setSubmitting(false);
          closeModal();
          toast.success('Action successfully added!');
        } else {
          toast.error('There was an issue adding the action.');
          formikHelpers.setSubmitting(false);
        }
      },
      [
        closeModal,
        database,
        inspectionUploader,
        networkConnected,
        filePath,
        inspection,
        inspectionItem,
      ],
    );

    const formik = useFormik<FormValues>({
      initialValues: {
        action: '',
        actionType: null,
        shouldCreateMaintenanceRequest: false,
      },
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        action: Yup.string().required().min(5).label('Action'),
        actionType: Yup.string()
          .required()
          .nullable()
          .oneOf(['landlord', 'tenant'])
          .label('Action responsibility'),
        shouldCreateMaintenanceRequest: Yup.boolean().label(
          'Create a maintenance request?',
        ),
      }),
    });

    const onSave = useCallback(async () => {
      const result = await formik.validateForm();
      if (isEmpty(result)) {
        const {setSubmitting, setFieldError} = formik;
        await handleSubmit(formik.values, {setSubmitting, setFieldError});
      }
      return false;
    }, [formik, handleSubmit]);

    useImperativeHandle(ref, () => ({
      onSave,
    }));

    return (
      <>
        <div className="mt-2 flex justify-center">
          <img
            src={Capacitor.convertFileSrc(props.filePath)}
            className="h-[150px] object-fit"
          />
        </div>

        <div className="mt-2">
          <TextareaField
            formik={formik}
            label="Action"
            name="action"
            placeholder="What needs to be done?"
          />
        </div>

        <div className="mt-2">
          <label className="label flex justify-start items-center">
            <span className="label-text mr-2">Who is the action for?</span>
          </label>
          <FormRow>
            <FormRowItem>
              <button
                type="button"
                disabled={formik.isSubmitting || activeAccountRole === 'Renter'}
                className={`btn  w-full ${
                  formik.values.actionType === 'landlord'
                    ? 'btn-primary'
                    : 'btn-neutral btn-outline'
                }`}
                onClick={() => formik.setFieldValue('actionType', 'landlord')}>
                Landlord
              </button>
            </FormRowItem>

            <FormRowItem>
              <button
                type="button"
                disabled={formik.isSubmitting}
                className={`btn w-full ${
                  formik.values.actionType === 'tenant'
                    ? 'btn-primary'
                    : 'btn-neutral btn-outline'
                }`}
                onClick={() => formik.setFieldValue('actionType', 'tenant')}>
                Tenant
              </button>
            </FormRowItem>
          </FormRow>
        </div>

        <div className="mt-2">
          <ToggleField
            formik={formik}
            name="shouldCreateMaintenanceRequest"
            label="Create a maintenance request"
          />
        </div>
      </>
    );
  },
};

export default InspectionAddActionPhotoModal;
