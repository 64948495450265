import bankAccountValidator from '@fnzc/nz-bank-account-validator';
import {Form, Formik, FormikHelpers} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import {InputField, SubmitButton} from 'components/forms_fields';
import PageWrapper from 'components/PageWrapper';
import Property from 'models/properties/Property';
import {errorViewForError} from 'utilities/ErrorHelpers';

const EditBankAccountPage = () => {
  const {id} = useParams();

  const queryClient = useQueryClient();

  const {data, isLoading, error} = useQuery(
    `property-${id}-bank-account`,
    async () => {
      const property = await Property.select([
        'id',
        'street_address',
        'bank_account_name',
        'bank_account_number',
      ]).find(id);

      return property.data;
    },
  );

  const handleBankingSubmit = async (
    formValues: any,
    actions: FormikHelpers<any>,
  ) => {
    const prop = data;

    prop.assignAttributes(formValues);

    const result = await prop.save();

    if (result) {
      queryClient.setQueryData(`property-${id}-bank-account`, prop);
      toast.success('Banking information successfully updated!');
    } else {
      console.log(prop.errors);
      for (const key of Object.keys(prop.errors)) {
        const message = prop.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Bank Account Information">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title={`${data.streetAddress} Bank Account Information`}>
        <Card>
          <CardBody>
            <CardTitle>{data.streetAddress} Bank Account Information</CardTitle>

            <Formik
              initialValues={{
                bankAccountName: data.bankAccountName || '',
                bankAccountNumber: data.bankAccountNumber || '',
              }}
              onSubmit={handleBankingSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                bankAccountName: Yup.string()
                  .min(2)
                  .required()
                  .label('Account Name'),
                bankAccountNumber: Yup.string()
                  .required()
                  .test(
                    'is-valid-format',
                    'This is not a valid NZ account number',
                    (value) => {
                      if (!value) return false;

                      const parts = value!.split('-');
                      if (parts.length === 4) {
                        return bankAccountValidator.isValidNZBankNumber(
                          parts[0],
                          parts[1],
                          parts[2],
                          parts[3],
                        );
                      } else {
                        return false;
                      }
                    },
                  )
                  .label('Account Number'),
              })}>
              {(formik) => (
                <Form>
                  <p className="mt-2">
                    Keyhook will deposit rent payments into the bank account
                    entered below. Keyhook will collect and calculate rent, and
                    chase arrears on your behalf. Your full rent payment will be
                    transferred to you within 24 hours of Keyhook receiving it,
                    minus any outstanding bills. For more information about our
                    smart-payment structure, visit out FAQs.
                  </p>

                  <InputField
                    label="Account Name"
                    name="bankAccountName"
                    formik={formik}
                    placeholder="eg: John Smith"
                    autoCapitalize="words"
                  />
                  <InputField
                    label="Account Number"
                    name="bankAccountNumber"
                    formik={formik}
                    placeholder="eg: 02-1234-5678900-00 (include dashes)"
                    autoCapitalize="words"
                  />

                  <SubmitButton
                    formik={formik}
                    text="Update"
                    submittingText="Updating"
                    className="mt-4"
                  />
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </PageWrapper>
    );
  }
};

export default EditBankAccountPage;
