import {useMemo} from 'react';

import {Capacitor} from '@capacitor/core';
import {useQuery} from 'react-query';

import heroImage from 'assets/img/chifis_theme/listing_index/hero-right-custom.png';
import ListingIndexBasicSearchForm from 'components/listing/ListingIndexBasicSearchForm';
import ListingIndexCard from 'components/listing/ListingIndexCard';
import StartNewListingForm from 'components/listing/StartNewListingForm';
import {HeaderButton} from 'components/navbar/Header';
import PageWrapper from 'components/PageWrapper';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import Listing from 'models/listings/Listing';
import {usePageVisit, useTitle} from 'utilities/hooks';

import 'rc-slider/assets/index.css';

const ListingIndexPage = () => {
  useTitle('Listings');
  usePageVisit('ListingIndexPage');

  const isNative = useMemo(() => Capacitor.isNativePlatform(), []);

  const {data} = useQuery('latest-listings', async () => {
    const listings = await Listing.order({createdAt: 'desc'})
      .includes('listing_photos')
      .order({'listing_photos.order_index': 'asc'})
      .per(20)
      .page(0)
      .all();
    return listings.data;
  });

  const {activeAccountRole} = useLocalUserSettings();

  if (isNative) {
    const buttons: HeaderButton[] =
      activeAccountRole === 'Renter'
        ? [
            {
              text: 'Enquiries',
              href: '/enquiries',
            },
            {
              text: 'Applications',
              href: '/rental-applications',
            },
            {
              text: 'Open Homes',
              href: '/open-homes',
            },
          ]
        : [];
    return (
      <PageWrapper
        title="Listings"
        buttons={buttons}
        showButtonsAsDropDown
        dropdownTitle="Menu">
        <main className="w-full flex-grow bg-white">
          {(!activeAccountRole || activeAccountRole === 'Landlord') && (
            <StartNewListingForm />
          )}

          <div className="nc-PageHome relative overflow-hidden">
            <div className="z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
              <ListingIndexBasicSearchForm />
            </div>

            <div className="container relative py-8 bg-base-100 rounded-lg">
              <div className="nc-SectionGridFeatureProperty relative">
                <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-4">
                  <div className="text-center w-full max-w-2xl mx-auto">
                    <h2 className="text-3xl md:text-4xl font-semibold">
                      Latest Listings
                    </h2>
                    <div className="mt-4 md:mt-6 font-normal text-base sm:text-lg text-neutral-500 dark:text-neutral-400 flex flex-col gap-2">
                      {data?.length ? (
                        <span>
                          Check out the most recent rentals listed on Keyhook
                        </span>
                      ) : (
                        <>
                          <span>
                            'There are currently no available listings.
                          </span>
                          <span>
                            New listings are being added all the time, so please
                            check back later.
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {!!data?.length && (
                  <div className="grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2">
                    {data.map((listing) => (
                      <ListingIndexCard key={listing.id} listing={listing} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </PageWrapper>
    );
  } else {
    return (
      <main className="w-full flex-grow bg-white">
        {(!activeAccountRole || activeAccountRole === 'Landlord') && (
          <StartNewListingForm />
        )}

        <div className="nc-PageHome relative overflow-hidden">
          <div
            className={`nc-BgGlassmorphism absolute inset-x-0 md:top-10 xl:top-40 min-h-0 pl-20 py-24 flex overflow-hidden z-0`}
            data-nc-id="BgGlassmorphism">
            <span className="block bg-[#ef233c] w-72 h-72 rounded-full mix-blend-multiply filter blur-3xl opacity-10 lg:w-96 lg:h-96"></span>
            <span className="block bg-[#04868b] w-72 h-72 -ml-20 mt-40 rounded-full mix-blend-multiply filter blur-3xl opacity-10 lg:w-96 lg:h-96 nc-animation-delay-2000"></span>
          </div>

          <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
            <div
              className="nc-SectionHero flex flex-col-reverse lg:flex-col relative pt-10 lg:pt-20 pb-16"
              data-nc-id="SectionHero">
              <div className="flex flex-col lg:flex-row lg:items-center">
                <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
                  <h2 className="font-semibold text-4xl md:text-5xl xl:text-7xl !leading-[114%] ">
                    Find your next home
                  </h2>
                  <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
                    The comprehensive marketplace for New Zealand rentals.
                  </span>
                </div>
                <div className="flex-grow">
                  <img className="w-full" src={heroImage} alt="hero" />
                </div>
              </div>

              <div className="z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
                <ListingIndexBasicSearchForm />
              </div>
            </div>
          </div>

          <div className="container relative py-16">
            <div
              className="nc-BackgroundSection absolute inset-y-0 w-screen xl:max-w-[1340px] 2xl:max-w-screen-2xl left-1/2 transform -translate-x-1/2 xl:rounded-[40px] z-0 bg-base-200"
              data-nc-id="BackgroundSection"></div>

            <div className="nc-SectionGridFeatureProperty relative">
              <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-20">
                <div className="text-center w-full max-w-2xl mx-auto">
                  <h2 className="text-3xl md:text-4xl font-semibold">
                    Latest Listings
                  </h2>
                  <div className="mt-4 md:mt-6 font-normal text-base sm:text-lg text-neutral-500 dark:text-neutral-400 flex flex-col gap-2">
                    {data?.length ? (
                      <span>
                        Check out the most recent rentals listed on Keyhook
                      </span>
                    ) : (
                      <>
                        <span>'There are currently no available listings.</span>
                        <span>
                          New listings are being added all the time, so please
                          check back later.
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {!!data?.length && (
                <div className="grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2">
                  {data.map((listing) => (
                    <ListingIndexCard key={listing.id} listing={listing} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    );
  }
};

export default ListingIndexPage;
