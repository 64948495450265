import {useCallback, useImperativeHandle, useState} from 'react';

import {pick} from 'lodash';
import {toast} from 'react-toastify';

import Checkbox from 'components/chifis_theme/Checkbox';
import {ModalDefinition} from 'components_sb/layout';
import Paragraph from 'components_sb/typography/Paragraph/Paragraph';
import {TARGET_ENV} from 'globals/app-globals';
import useAuth from 'services/useAuth';

interface CheckOptions {
  [type: string]: {
    label: string;
    price: number;
  };
}

const CHECK_OPTIONS: CheckOptions = {
  credit: {
    label: 'Credit check',
    price: 15,
  },
  background: {
    label: 'Background check',
    price: 0,
  },
  references: {
    label: 'References check',
    price: 15,
  },
  socials: {
    label: 'Socials check',
    price: 0,
  },
};

const RequestApplicationCheckModal: ModalDefinition = {
  title: 'Request checks',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'submit',
        label: {
          idle: 'Accept',
          loading: 'Accepting',
        },
        handle: 'onSubmit',
        closeOnSuccess: true,
        toastOnSuccess:
          "We're on it! You should receive your tenant check via email within 48 hours.",
      },
    ],
  },
  ContentComponent: ({application}, ref) => {
    const {currentUser} = useAuth();
    const [selectedOptions, setSelectedOptions] = useState([]);

    const onSubmit = useCallback(async () => {
      if (!selectedOptions.length) {
        toast.warn("You haven't selected any options!");
        throw new Error();
      }

      const data = {
        landlord_id: currentUser.id,
        landlord_name: currentUser.name,
        landlord_email: currentUser.email,
        requestedChecks: selectedOptions
          .map((type) => {
            const {label, price} = CHECK_OPTIONS[type];
            return `${label} ($${price})`;
          })
          .toString(),
        listing_id: application.listing.id,
        property_id: application.listing.propertyId,
        rental_application_id: application.rentalApplication.id,
        applicant_user_id: application.rentalApplication.user.id,
        applicant_name: application.rentalApplication.user.name,
      };

      /**
       * Ignore submission in development mode
       */
      if (TARGET_ENV !== 'development') {
        const rawResponse = await fetch('https://formcarry.com/s/4Kr-GWkHT4', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        const response = await rawResponse.json();
        if (response.code !== 200) {
          toast.error(
            'There was an error submitting your request, please try again later.',
          );
          throw new Error('Error submitting request');
        }
      }
    }, [selectedOptions]);

    useImperativeHandle(ref, () => ({
      onSubmit,
    }));

    const setOptionChecked = useCallback(
      (option: string, checked: boolean) => {
        if (!checked && selectedOptions.includes(option)) {
          // Remove option
          setSelectedOptions((current) =>
            current.filter((item) => item !== option),
          );
        } else if (checked && !selectedOptions.includes(option)) {
          // Add option
          setSelectedOptions((current) => [...current, option]);
        }
      },
      [setSelectedOptions, selectedOptions],
    );

    return (
      <div className="w-full rounded-lg flex flex-col">
        <div className="flex flex-col gap-y-2 mb-6">
          {Object.entries(CHECK_OPTIONS).map(([key, {label, price}]) => (
            <Checkbox
              key={key}
              name={key}
              label={`${label}${
                price ? ` ($${price} per applicant)` : ' (FREE)'
              }`}
              onChange={(checked) => setOptionChecked(key, checked)}
            />
          ))}
        </div>
        <Paragraph>
          Your credit card will be charged for any applicable fees.
        </Paragraph>
        <Paragraph>
          If a check is unable to be completed for any reason, you will be
          refunded for the check.
        </Paragraph>
      </div>
    );
  },
};

export default RequestApplicationCheckModal;
