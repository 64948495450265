import {useState} from 'react';

import {Formik, Form} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import LoadingView from 'components/common/LoadingView';
import {
  PropertyInformationCard,
  RequirementsCard,
  ChattelsCard,
  InsuranceCard,
  PROPERTY_TYPES,
} from 'components/forms/NewPropertyForm';
import PageWrapper from 'components/PageWrapper';
import Property from 'models/properties/Property';
import TrackingService from 'services/TrackingService';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

const EditPropertyPage = () => {
  useTitle('Edit Property');
  usePageVisit('EditPropertyPage');

  const {id} = useParams();

  const [hasSaved, setHasSaved] = useState(false);

  const {
    data: property,
    error,
    isLoading,
  } = useQuery(
    `landlord-property-edit-${id}`,
    async () => {
      const res = await Property.find(id);
      return res.data;
    },
    {retry: 1},
  );
  const queryClient = useQueryClient();

  const handleSubmit = async (formData: any, actions: any) => {
    const formValues = {...formData};
    delete formValues.address;
    if (formValues.mainImage && formValues.mainImage.data === '') {
      delete formValues.mainImage;
    }
    if (formValues.mainImage && formValues.mainImage.includes('https')) {
      delete formValues.mainImage;
    }

    property?.assignAttributes(formValues);

    const success = await property.save();

    if (success) {
      queryClient.invalidateQueries('landlord-properties');
      queryClient.invalidateQueries(`landlord-property-detail-${property.id}`);
      toast.success('Property successfully updated!');
      TrackingService.trackEvent(TrackingService.Event.EditProperty);
      setHasSaved(true);
    } else {
      for (const field in property.errors) {
        const error = property.errors[field];
        actions.setFieldError(field, error?.fullMessage);
      }
    }
    actions.setSubmitting(false);
  };

  const schema = Yup.object({
    bathrooms: Yup.number().min(1).max(10).required().label('Bathrooms'),
    bedrooms: Yup.number().min(0).max(12).required().label('Bedrooms'),
    garages: Yup.number().min(0).max(12).required().label('Garages'),
    maxTenants: Yup.number()
      .min(1)
      .max(20)
      .nullable()
      .optional()
      .label('Max Number of Tenants'),
    propertyType: Yup.string()
      .oneOf(PROPERTY_TYPES)
      .required()
      .label('Property Type'),
    petsAllowed: Yup.boolean().required().label('Pets Allowed?'),
    allowedPetTypes: Yup.array()
      .optional()
      .nullable()
      .label('Allowed Pet Types')
      .min(0),
    smokersAllowed: Yup.boolean().required().label('Smokers Allowed?'),
    rentersInsuranceRequired: Yup.boolean().label(
      'Renters Insurance Required?',
    ),
    inspectionPeriod: Yup.number()
      .min(1)
      .max(6)
      .nullable()
      .optional()
      .label('Inspection Period'),
    authorityToFixLimit: Yup.number()
      .required()
      .min(0)
      .max(100000)
      .label('Authority to fix limit'),
    chattels: Yup.object().optional().nullable().label('Chattels'),
    courtesyChattels: Yup.object()
      .optional()
      .nullable()
      .label('Courtesy Chattels'),
    mainImage: Yup.string().min(1).label('Main Image'),
    insurancePolicies: Yup.array()
      .of(
        Yup.object().shape({
          policy_name: Yup.string()
            .required()
            .min(3)
            .max(40)
            .label('Policy Name'),
          insurer: Yup.string().required().min(2).max(40).label('Insurer'),
          excess_amount: Yup.number().min(1).required().label('Excess Amount'),
        }),
      )
      .min(0)
      .label('Insurance Policies')
      .optional(),
  });

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title={'Edit ' + property.streetAddress}>
        <Formik
          initialValues={property.attributes}
          onSubmit={handleSubmit}
          validationSchema={schema}>
          {(formik) => (
            <Form>
              <PropertyInformationCard formik={formik} />
              <RequirementsCard formik={formik} isNew={false} />
              <ChattelsCard formik={formik} showCard={true} />
              {/* <ImageCard formik={formik} /> */}
              <InsuranceCard formik={formik} />

              <div className="mt-4">
                <button
                  className="btn btn-primary btn-block"
                  type="button"
                  onClick={formik.submitForm}>
                  {formik.isSubmitting ? 'Loading' : 'Update'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </PageWrapper>
    );
  }
};

export default EditPropertyPage;
