import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Tenancy from 'models/properties/Tenancy';

@Model()
class RentPriceChange extends ApplicationRecord {
  static jsonapiType = 'rent_price_changes';

  @Attr() newTotalRent: number;
  @Attr() effectiveDate: string;
  @Attr({persist: false}) newRentSplits: any;

  @Attr({persist: false}) processed: boolean;

  @Attr() tenancyId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('tenancies') tenancy: Tenancy;
}

export default RentPriceChange;
