import {useEffect, useState} from 'react';

import {type FormikHelpers, type FormikProps, Formik, Form} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import airConditioningIcon from 'assets/img/listing_tag_icons/air_conditioning.png';
import bathIcon from 'assets/img/listing_tag_icons/bath.png';
import dishwasherIcon from 'assets/img/listing_tag_icons/dishwasher.png';
import dryerIcon from 'assets/img/listing_tag_icons/dryer.png';
import electricHeatingIcon from 'assets/img/listing_tag_icons/electric_heating.png';
import fridgeIcon from 'assets/img/listing_tag_icons/fridge.png';
import furnishedIcon from 'assets/img/listing_tag_icons/furnished.png';
import heatPumpIcon from 'assets/img/listing_tag_icons/heat_pump.png';
import outdoorSpaceIcon from 'assets/img/listing_tag_icons/outdoor_space.png';
import ovenIcon from 'assets/img/listing_tag_icons/oven.png';
import parkingIcon from 'assets/img/listing_tag_icons/parking.png';
import washerIcon from 'assets/img/listing_tag_icons/washer.png';
import ListingWalkthroughMoreTagsField from 'components/forms_fields/ListingWalkthroughMoreTagsField';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightSideText from 'components/walkthrough/common/RightSideText';
import Property from 'models/properties/Property';
import TrackingService from 'services/TrackingService';

type TagsFormValues = {
  tags: string[];
};

const tagOptions = [
  [
    {tag: 'fridge', label: 'Fridge', icon: fridgeIcon},
    {tag: 'oven', label: 'Oven', icon: ovenIcon},
    {tag: 'dishwasher', label: 'Dishwasher', icon: dishwasherIcon},
    {tag: 'washer', label: 'Washer', icon: washerIcon},
    {tag: 'dryer', label: 'Dryer', icon: dryerIcon},
    {tag: 'heat_pump', label: 'Heat Pump', icon: heatPumpIcon},
  ],
  [
    {
      tag: 'air_conditioning',
      label: 'Air Conditioning',
      icon: airConditioningIcon,
    },
    {tag: 'bath', label: 'Bath', icon: bathIcon},
    {tag: 'parking', label: 'Parking', icon: parkingIcon},
    {
      tag: 'private_outdoor_space',
      label: 'Outdoor Space',
      icon: outdoorSpaceIcon,
    },
    {
      tag: 'electric_heating',
      label: 'Electric Heating',
      icon: electricHeatingIcon,
    },
    {
      tag: 'furnished',
      label: 'Furnished',
      icon: furnishedIcon,
    },
  ],
];

const TagsStep = ({property}: {property: Property}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_StartKeyPropertyFeaturesStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const listing = property.listings[0];

  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = async (
    formValues: TagsFormValues,
    actions: FormikHelpers<TagsFormValues>,
  ) => {
    setIsLoading(true);

    listing.assignAttributes(formValues);
    await listing.save();

    property.lastOnboardingStepCompleted = 'listing_tags';
    await property.save();

    /**
     * Track completion of the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_CompleteKeyPropertyFeaturesStep,
      {
        propertyId: property.id,
      },
    );

    setIsLoading(false);
    queryClient.setQueryData('new-listing-wizard', property);
  };

  const previousStep = async () => {
    property.lastOnboardingStepCompleted = 'listing_financials';
    await property.save();
    queryClient.setQueryData('new-listing-wizard', property);
  };

  const title = "Highlight your property's key features...";
  const subtitle = 'This will allow more tenants to find your property';

  const toggleTag = (formik: FormikProps<TagsFormValues>, tag: string) => {
    let tags = formik.values.tags;
    if (tags.includes(tag)) {
      tags = tags.filter((t) => t !== tag);
    } else {
      tags.push(tag);
    }

    formik.setFieldValue('tags', tags);
  };

  return (
    <Container>
      <LeftSide title={title} subtitle={subtitle} />
      <RightContainer align="items-start">
        <RightSideText title={title} subtitle={subtitle} />
        <Formik
          initialValues={{
            tags: listing.tags || [],
          }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            tags: Yup.array().min(0).label('Tags'),
          })}>
          {(formik) => (
            <Form>
              {tagOptions.map((options, index) => (
                <div
                  className="grid grid-cols-3 gap-4 mt-12 mx-0 md:mx-16"
                  key={index}>
                  {options.map((tag) => {
                    const isSelected = formik.values.tags.includes(tag.tag);
                    return (
                      <div
                        onClick={() => toggleTag(formik, tag.tag)}
                        className={
                          'flex flex-col justify-center items-center px-4 py-6 border-2 rounded-xl cursor-pointer ' +
                          (isSelected
                            ? 'border-neutral bg-gray-100'
                            : 'border-gray-300 bg-white')
                        }
                        key={tag.tag}>
                        <img
                          src={tag.icon}
                          alt={tag.label}
                          className="w-10 h-10"
                        />
                        <p
                          className={
                            isSelected
                              ? 'font-semibold mt-2 text-xs'
                              : 'mt-2 text-xs'
                          }>
                          {tag.label}
                        </p>
                      </div>
                    );
                  })}
                </div>
              ))}

              <div className="mx-16">
                <ListingWalkthroughMoreTagsField formik={formik} name="tags" />
              </div>

              <ProgressBox
                nextStep={formik.submitForm}
                previousStep={previousStep}
                step={6}
                previousStepEnabled
                nextStepEnabled={!isLoading}
              />
            </Form>
          )}
        </Formik>
      </RightContainer>
    </Container>
  );
};

export default TagsStep;
