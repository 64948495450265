import React, {useState} from 'react';

import {Capacitor} from '@capacitor/core';
import {Form, Formik, type FormikHelpers} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import {
  Base64FileField,
  InputField,
  SelectField,
  SubmitButton,
  ToggleField,
} from 'components/forms_fields';
import PageWrapper from 'components/PageWrapper';
import Document from 'models/properties/Document';
import Property from 'models/properties/Property';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';
import {removeUnderscores, titleize} from 'utilities/StringHelpers';

const NewDocumentPage = () => {
  const [formKey, setFormKey] = useState('1234');
  const {propertyId} = useParams();

  useTitle('New Document');
  usePageVisit('NewDocumentPage');

  const DOCUMENT_TYPES = [
    'other',
    'insurance_information',
    'general_information',
    'property_rules',
    'body_corporate_information',
  ];

  const TENANCY_DOCUMENT_TYPES = [
    'lease',
    'bond',
    'bond_supplementary',
    'bond_receipt',
  ];

  // Used to check landlord has permission for property
  const {data, isLoading, error} = useQuery(
    `property-${propertyId}-add-insulation-report`,
    async () => {
      const property = await Property.select({
        properties: ['id', 'has_insulation_report'],
        tenancies: ['id', 'is_new'],
      })
        .includes('current_tenancy')
        .find(propertyId);

      return property.data;
    },
  );

  const queryClient = useQueryClient();

  const handleSubmit = async (formValues: any, actions: FormikHelpers<any>) => {
    console.log(Document);
    const doc = new Document(formValues);
    if (TENANCY_DOCUMENT_TYPES.includes(doc.documentType)) {
      doc.documentableType = 'Tenancy';
      doc.documentableId = data.currentTenancy.id;
    } else {
      doc.documentableType = 'Property';
      doc.documentableId = propertyId;
    }

    if (doc.name.length === 0 && doc.documentType !== 'other') {
      doc.name = null;
    }

    const result = await doc.save();
    if (result) {
      queryClient.invalidateQueries(`landlord-property-detail-${propertyId}`);
      toast.success('Document successfully created!');
      setFormKey((Math.random() * 10000).toFixed(0));
    } else {
      for (const key of Object.keys(doc.errors)) {
        const message = doc.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  const buttons = Capacitor.isNativePlatform()
    ? []
    : [
        {
          text: 'Back To Property',
          href: `/properties/${propertyId}`,
          bgColor: 'neutral',
        },
      ];

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Add New Document" buttons={buttons}>
        <LoadingView />
      </PageWrapper>
    );
  } else {
    const docTypes =
      data.currentTenancy && !data.currentTenancy.isNew
        ? DOCUMENT_TYPES.concat(TENANCY_DOCUMENT_TYPES)
        : DOCUMENT_TYPES;
    return (
      <PageWrapper title="Add New Document" buttons={buttons}>
        <Card key={formKey}>
          <CardBody>
            <CardTitle>Add New Document</CardTitle>
            <p>
              Upload any documents that you wish to associate with this
              property. Please note these should not be tenancy specific and
              will be available to all future tenants.
            </p>

            <div className="mt-3">
              <Formik
                initialValues={{document: '', documentType: 'other', name: ''}}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={Yup.object().shape({
                  document: Yup.string()
                    .required()
                    .min(1)
                    .label('Document File'),
                  documentType: Yup.string()
                    .required()
                    .oneOf(
                      data.currentTenancy && !data.currentTenancy.isNew
                        ? DOCUMENT_TYPES.concat(TENANCY_DOCUMENT_TYPES)
                        : DOCUMENT_TYPES,
                    )
                    .label('Document Type'),
                  name: Yup.string()
                    .label('Document Name')
                    .min(0)
                    .when('documentType', {
                      is: (docType: any) => docType === 'other',
                      then: Yup.string()
                        .required('Document Name is required')
                        .min(1)
                        .label('Document Name'),
                    }),
                  private: Yup.boolean().label('Private'),
                })}>
                {(formik) => (
                  <Form>
                    <SelectField
                      formik={formik}
                      name="documentType"
                      label="Document Type">
                      {docTypes.map((type) => (
                        <option value={type} key={type}>
                          {titleize(removeUnderscores(type))}
                        </option>
                      ))}
                    </SelectField>

                    <Base64FileField
                      formik={formik}
                      name="document"
                      accept=".pdf"
                      label="Document File ( PDF only )"
                    />

                    <div className="mt-2 w-1/2">
                      <ToggleField
                        name="private"
                        formik={formik}
                        label="Private"
                        helpText="Private documents are not viewable by tenants."
                      />
                    </div>

                    {formik.values.documentType === 'other' && (
                      <div className="mt-2">
                        <InputField
                          formik={formik}
                          name="name"
                          label="Document Name"
                        />
                      </div>
                    )}

                    <div>
                      <SubmitButton
                        formik={formik}
                        text="Upload"
                        submittingText="Uploading"
                        block={false}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </CardBody>
        </Card>
      </PageWrapper>
    );
  }
};

export default NewDocumentPage;
