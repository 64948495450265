import {useQuery} from 'react-query';
import {useParams} from 'react-router';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LightboxGallery from 'components/common/LightboxGallery';
import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import ServiceRequest from 'models/service_requests/ServiceRequest';
import ServiceRequestAttachment from 'models/service_requests/ServiceRequestAttachment';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

const PublicServiceRequestDetailPage = () => {
  usePageVisit('PublicServiceRequestDetailPage');
  const {id} = useParams();

  const {isLoading, data, error} = useQuery(
    `public-service-request-${id}`,
    async () => {
      const request = await ServiceRequest.includes([
        'service_request_attachments',
      ])
        .where({publicId: id})
        .selectExtra(['location'])
        .first();

      return request.data;
    },
  );

  useTitle(data ? data.title : '');

  const viewForAttachment = (
    attachment: ServiceRequestAttachment,
    index: number,
  ) => {
    if (attachment.attachmentThumbnail) {
      return (
        <a href={attachment.attachment} key={attachment.id} className="w-auto">
          <img
            className={(index === 0 ? '' : 'ml-3') + ' inline-block'}
            src={attachment.attachmentThumbnail}
            style={{maxHeight: '200px', maxWidth: '200px'}}
          />
        </a>
      );
    } else {
      return (
        <video
          className="ml-3 inline-block"
          key={attachment.id}
          style={{maxHeight: '200px', maxWidth: '200px'}}>
          <source src={attachment.attachment} type="video/mp4" />
        </video>
      );
    }
  };

  const beforeAttachments = data
    ? data?.serviceRequestAttachments.filter(
        (attach) => attach.taken === 'before',
      )
    : [];

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Maintenance Request">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Maintenance Request">
        <Card className="mt-2">
          <CardBody>
            <CardTitle className="mb-0">{data.title}</CardTitle>
            <span className="block text-sm text-secondary">
              Category: {data.category}
            </span>
            <span className="block text-sm text-secondary">
              Location: {data.location}
            </span>
            <p className="mt-2">{data.description}</p>

            {beforeAttachments.length > 0 && (
              <div>
                <strong className="block mb-4">Attachments</strong>

                <LightboxGallery
                  sources={beforeAttachments.map((attachment) => {
                    if (attachment.attachmentThumbnail) {
                      return {
                        thumbnail:
                          attachment.attachmentThumbnail ||
                          attachment.attachment,
                        source: attachment.attachment,
                        type: 'image',
                      };
                    } else {
                      return {source: attachment.attachment, type: 'video'};
                    }
                  })}
                />
              </div>
            )}
          </CardBody>
        </Card>
      </PageWrapper>
    );
  }
};

export default PublicServiceRequestDetailPage;
