import {useState, useEffect, useMemo, useCallback} from 'react';

import clsx from 'clsx';
import {type FormikErrors} from 'formik';
import {debounce} from 'lodash';
import {Collapse} from 'react-collapse';
import './InlineError.css';

export type FieldError =
  | undefined
  | boolean
  | string
  | string[]
  | FormikErrors<any>
  | FormikErrors<any>[];

interface InlineErrorProps {
  /**
   *  The message to display to the user indicating the error.
   */
  error: FieldError;
}

/**
 * Displays an error message within the context of the page or section.
 */
const InlineError = ({error}: InlineErrorProps) => {
  /**
   * When the message props changes from a valid string to null or undefined, the
   * state value below allows us to persist the message until the animation has completed.
   */
  const [persistentMessage, setPersistentMessage] = useState<
    string | undefined
  >();

  /**
   * Controls when the error message is shown.
   */
  const [show, setShow] = useState<boolean>(!!error);

  /**
   * A debounce function for setting the show state is necessary to avoid incorrect
   * show states when an error messages changes from a valid value, to undefined, then
   * back to a valid value (or vice versa) in quick succession.
   */
  const debounceSetShow = useMemo(() => debounce(setShow, 100), [setShow]);

  /**
   * Providing an error message indicates that the message should be displayed.
   */
  useEffect(() => {
    debounceSetShow(!!error);
  }, [debounceSetShow, error]);

  /**
   * Update the persistent message whenever the provided error message is changed
   * to a new string.
   */
  useEffect(() => {
    if (error) {
      setPersistentMessage(error as string);
    }
  }, [error]);

  return (
    <Collapse isOpened={show}>
      <div
        className={clsx(
          `block text-error text-base py-1`,
          // Opacity transition when opening should be longer than when closing
          show ? 'opacity-100 duration-500' : 'opacity-0 duration-300',
        )}>
        {persistentMessage}
      </div>
    </Collapse>
  );
};

export default InlineError;
