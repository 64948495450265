import React, {useEffect} from 'react';

import {type FormikProps} from 'formik';
import _ from 'lodash';

import {InputField, TextareaField} from 'components/forms_fields';
import InlineSelectField from 'components/forms_fields/InlineSelectField';
import {useAutoAnimate} from 'utilities/hooks';

type FormTenancyRequest = {
  renterEmail: string;
};

const AddTenantsSection = ({formik}: {formik: FormikProps<any>}) => {
  const [tenantAnimateRef] = useAutoAnimate();

  const requests = formik.values.tenancyRequests as FormTenancyRequest[];

  useEffect(() => {
    handleSelectChange(formik.values.numberOfTenants);
  }, [formik.values.numberOfTenants]);

  const handleSelectChange = (val: number) => {
    if (val < requests.length) {
      const diff = requests.length - val;
      _.times(diff, () => {
        requests.pop();
      });
    } else if (val > requests.length) {
      const diff = val - requests.length;
      _.times(diff, () => {
        requests.push({renterEmail: ''});
      });
    }

    formik.setFieldValue('tenancyRequests', requests);
  };

  return (
    <div className="mt-2">
      <InlineSelectField
        formik={formik}
        name="numberOfTenants"
        label="Number of tenants"
        helpText="Currently we only allow for a maximum of 6 tenants due to
              limitations from Tenancy Services. This should be the number of adults who will be signing the lease.">
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
      </InlineSelectField>

      <div ref={tenantAnimateRef as any} className="mt-4">
        {requests.length === 1 && (
          <div>
            <InputField
              formik={formik}
              name={'tenancyRequests[0].renterEmail'}
              label="Tenant Email"
              placeholder="name@email.com"
              type="email"
              autoComplete="off"
            />
          </div>
        )}

        {requests.length > 1 &&
          requests.map((req, index) => {
            if (index === 0) {
              return (
                <div key={index}>
                  <InputField
                    formik={formik}
                    name={'tenancyRequests[0].renterEmail'}
                    label="Tenant 1 Email"
                    placeholder="name@email.com"
                    type="email"
                    autoComplete="off"
                    helpText="This tenant will be responsible for inputting the portion of total rent that each tenant pays."
                  />
                </div>
              );
            } else {
              return (
                <div className="mt-1" key={index}>
                  <InputField
                    formik={formik}
                    name={`tenancyRequests[${index}].renterEmail`}
                    label={`Tenant ${index + 1} Email`}
                    placeholder="name@email.com"
                    type="email"
                    autoComplete="off"
                  />
                </div>
              );
            }
          })}
      </div>

      <div className="mt-2">
        <TextareaField
          name="messageFromLandlord"
          label="Add a message (optional)"
          formik={formik}
          placeholder="Include a welcome message for your tenants."
        />
      </div>
    </div>
  );
};

export default AddTenantsSection;
