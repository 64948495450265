import {HTMLProps} from 'react';

import {type FormikProps} from 'formik';

import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import {InlineError} from 'components_sb/feedback';
import TrackingService from 'services/TrackingService';

interface ResizingImageFieldProps extends HTMLProps<HTMLInputElement> {
  formik: FormikProps<any>;
  label: string;
  name: string;
  width: number;
  height: number;
  helpText?: string;
}

const ResizingImageField = ({
  formik,
  label,
  name,
  width,
  height,
  helpText,
  ...rest
}: ResizingImageFieldProps) => {
  let baseClass =
    'block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100';
  if (formik.errors[name]) {
    baseClass += ' border border-danger';
  }
  if (rest.className) {
    rest.className = baseClass + rest.className;
  } else {
    rest.className = baseClass;
  }
  return (
    <>
      {label && (
        <label className="label flex justify-start items-center">
          <span className="label-text mr-2">{label}</span>
          <HelpTextPresenter helpText={helpText} />
        </label>
      )}
      <input
        name={name}
        onChange={(e) => {
          const file = e.target.files[0];
          const reader = new FileReader();

          const image = new Image();
          const canvas = document.createElement('canvas');

          reader.onload = (readerEvent) => {
            const base64 = readerEvent.target.result.toString();

            image.onload = () => {
              canvas.width = width;
              canvas.height = height;
              canvas.getContext('2d').drawImage(image, 0, 0, width, height);
              const dataUrl = canvas.toDataURL('image/jpeg');

              formik.setFieldValue(name, dataUrl);

              TrackingService.trackEvent(TrackingService.Event.SelectImage, {
                field: name,
              });
            };

            image.src = base64;
          };

          reader.readAsDataURL(file);
        }}
        type="file"
        {...rest}
      />
      <InlineError error={formik.errors[name]} />
    </>
  );
};

export default ResizingImageField;
