// @ts-nocheck

import React, {Fragment, useEffect, useState} from 'react';

import {Dialog, Transition} from '@headlessui/react';
import moment from 'moment';
import {Range} from 'rc-slider';
import Datetime from 'react-datetime';
import {useNavigate} from 'react-router';
import Select, {components, type OptionProps} from 'react-select';
import AsyncSelect from 'react-select/async';

import ButtonClose from 'components/chifis_theme/Button/ButtonClose';
import Checkbox from 'components/chifis_theme/Checkbox';
import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import useQuery from 'hooks/useQueryString';
import SearchLocation from 'models/listings/SearchLocation';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import ListingTags from 'utilities/ListingTags';
import {removeUnderscores, titleize} from 'utilities/StringHelpers';

const queryString = require('query-string');

interface SelectOption {
  readonly label: string;
  readonly value: string;
}

interface LocationSelectOption {
  readonly label: string;
  readonly value: SearchLocation;
}

const LocationOption = (props: OptionProps<LocationSelectOption>) => {
  if (props.data.value.suburb) {
    return (
      <components.Option {...props}>
        {props.data.value.suburb}, {props.data.value.city}
      </components.Option>
    );
  } else {
    return (
      <components.Option {...props}>{props.data.value.city}</components.Option>
    );
  }
};

const constructOption = (opt: string | number) => {
  return {label: opt.toString(), value: opt.toString()};
};

const constructNumberOptions = (max: number, plusOne = false) => {
  const options = [];
  for (let i = 0; i < max; i++) {
    options.push(constructOption(plusOne ? i + 1 : i));
  }

  return options;
};

const PROPERTY_TYPE_OPTIONS = [
  {
    label: 'Apartment',
    value: 'Apartment',
  },
  {
    label: 'House',
    value: 'House',
  },
  {
    label: 'Townhouse',
    value: 'Townhouse',
  },
  {
    label: 'Unit',
    value: 'Townhouse',
  },
];

const MIN_RENT = 0;
const MAX_RENT = 2000.0;
const RENT_STEP = 10.0;

const MIN_BEDS = 1;
const MAX_BEDS = 10;

const MIN_BATHS = 1;
const MAX_BATHS = 10;

const MIN_GARAGES = 0;
const MAX_GARAGES = 10;

const DEFAULT_AVAILABLE_FROM = null;

const DEFAULT_PROPERTY_TYPES = [] as string[];

const DEFAULT_ALLOWED_PETS = [] as string[];

const PET_OPTIONS: SelectOption[] = [
  {label: 'Cats', value: 'cats'},
  {label: 'Small Dogs', value: 'small_dogs'},
  {label: 'Large Dogs', value: 'large_dogs'},
  {label: 'Fish', value: 'fish'},
  {label: 'Caged Birds', value: 'caged_birds'},
];

const DEFAULT_MAX_TENANTS = null;

const DEFAULT_TAGS = new Set<string>();

const DEFAULT_LOCATIONS = [] as SearchLocation[];

const parseLocationsString = (locations: string): SearchLocation[] => {
  const list = locations.split(',');

  return list.map((loc) => {
    const parts = loc.split('-');

    if (parts.length === 1) {
      return new SearchLocation({city: parts[0]});
    } else {
      return new SearchLocation({suburb: parts[0], city: parts[1]});
    }
  });
};

const ListingAdvancedSearchForm = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const [searchLocations, setSearchLocations] = useState(
    query.has('locations')
      ? parseLocationsString(query.get('locations'))
      : DEFAULT_LOCATIONS,
  );

  const [propertyTypes, setPropertyTypes] = useState<string[]>(
    query.has('propertyTypes')
      ? query.get('propertyTypes').split(',')
      : DEFAULT_PROPERTY_TYPES,
  );

  const [minRent, setMinRent] = useState<number>(
    query.has('minRent') ? Number(query.get('minRent')) : MIN_RENT,
  );
  const [maxRent, setMaxRent] = useState<number>(
    query.has('maxRent') ? Number(query.get('maxRent')) : MAX_RENT,
  );

  const [minBeds, setMinBeds] = useState<number>(
    query.has('minBeds') ? Number(query.get('minBeds')) : MIN_BEDS,
  );
  const [maxBeds, setMaxBeds] = useState<number>(
    query.has('maxBeds') ? Number(query.get('maxBeds')) : MAX_BEDS,
  );

  const [minBaths, setMinBaths] = useState<number>(
    query.has('minBaths') ? Number(query.get('minBaths')) : MIN_BATHS,
  );
  const [maxBaths, setMaxBaths] = useState<number>(
    query.has('maxBaths') ? Number(query.get('maxBaths')) : MAX_BATHS,
  );

  const [minGarages, setMinGarages] = useState<number>(
    query.has('minGarages') ? Number(query.get('minGarages')) : MIN_GARAGES,
  );
  const [maxGarages, setMaxGarages] = useState<number>(
    query.has('maxGarages') ? Number(query.get('maxGarages')) : MAX_GARAGES,
  );

  const [availableFrom, setAvailableFrom] = useState<string>(
    query.has('availableFrom')
      ? query.get('availableFrom')
      : DEFAULT_AVAILABLE_FROM,
  );

  const [allowedPets, setAllowedPets] = useState<string[]>(
    query.has('allowedPets')
      ? query.get('allowedPets').split(',')
      : DEFAULT_ALLOWED_PETS,
  );

  const [smokingAllowed, setSmokingAllowed] = useState<boolean>(
    query.has('smokingAllowed'),
  );

  const [maxTenants, setMaxTenants] = useState<number | null>(
    query.has('maxTenants')
      ? Number(query.get('maxTenants'))
      : DEFAULT_MAX_TENANTS,
  );

  const [selectedTags, setSelectedTags] = useState<Set<string>>(
    query.has('tags') ? new Set(query.get('tags').split(',')) : DEFAULT_TAGS,
  );
  const [selectedTagsString, setSelectedTagsString] = useState('');

  useEffect(() => {
    const params = {} as any;

    if (searchLocations.length > 0) {
      params.locations = searchLocations
        .map((sl) => {
          if (sl.suburb) {
            return `${sl.suburb}-${sl.city}`;
          } else {
            return sl.city;
          }
        })
        .join(',');
    } else {
      delete params.locations;
    }

    if (propertyTypes.length > 0) {
      params.propertyTypes = propertyTypes.join(',');
    }
    if (minBeds !== MIN_BEDS) {
      params.minBeds = minBeds.toString();
    }
    if (maxBeds !== MAX_BEDS) {
      params.maxBeds = maxBeds.toString();
    }
    if (minBaths !== MIN_BATHS) {
      params.minBaths = minBaths.toString();
    }
    if (maxBaths !== MAX_BATHS) {
      params.maxBaths = maxBaths.toString();
    }
    if (minGarages !== MIN_GARAGES) {
      params.minGarages = minGarages.toString();
    }
    if (maxGarages !== MAX_GARAGES) {
      params.maxGarages = maxGarages.toString();
    }
    if (minRent !== MIN_RENT) {
      params.minRent = minRent.toString();
    }
    if (maxRent !== MAX_RENT) {
      params.maxRent = maxRent.toString();
    }
    if (availableFrom !== DEFAULT_AVAILABLE_FROM) {
      params.availableFrom = availableFrom;
    }

    if (allowedPets.length > 0) {
      params.allowedPets = allowedPets.join(',');
    }

    if (smokingAllowed) {
      params.smokingAllowed = 'true';
    }

    if (maxTenants !== DEFAULT_MAX_TENANTS) {
      params.maxTenants = maxTenants.toString();
    }

    if (selectedTagsString.length > 0) {
      params.tags = selectedTagsString;
    }

    const paramString = queryString.stringify(params, {
      arrayFormat: 'comma',
    });

    navigate('?' + paramString);
  }, [
    searchLocations,
    propertyTypes,
    minBeds,
    maxBeds,
    minBaths,
    maxBaths,
    minGarages,
    maxGarages,
    minRent,
    maxRent,
    availableFrom,
    allowedPets,
    maxTenants,
    smokingAllowed,
    selectedTagsString,
  ]);

  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);

  //
  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  const openModalMoreFilter = () => setisOpenMoreFilter(true);

  const addTag = (tag: string) => {
    selectedTags.add(tag);
    setSelectedTagsString(Array.from(selectedTags).join(','));
  };

  const removeTag = (tag: string) => {
    selectedTags.delete(tag);
    setSelectedTagsString(Array.from(selectedTags).join(','));
  };

  const clearTags = () => {
    setSelectedTags(new Set());
    setSelectedTagsString('');
  };

  const renderMoreFilterItem = (
    data: {
      label: string;
      value: string;
    }[],
  ) => {
    const list1 = data.filter((_, i) => i < data.length / 2);
    const list2 = data.filter((_, i) => i >= data.length / 2);
    return (
      <div className="grid grid-cols-2 gap-8">
        <div className="flex flex-col space-y-5">
          {list1.map((item) => (
            <Checkbox
              key={item.value}
              name={item.label}
              label={item.label}
              defaultChecked={selectedTags.has(item.value)}
              onChange={(checked) =>
                checked ? addTag(item.value) : removeTag(item.value)
              }
            />
          ))}
        </div>
        <div className="flex flex-col space-y-5">
          {list2.map((item) => (
            <Checkbox
              key={item.value}
              name={item.label}
              label={item.label}
              defaultChecked={selectedTags.has(item.value)}
              onChange={(checked) =>
                checked ? addTag(item.value) : removeTag(item.value)
              }
            />
          ))}
        </div>
      </div>
    );
  };

  const renderTabMoreFilter = () => {
    return (
      <div>
        <div
          className="flex items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-700 focus:outline-none cursor-pointer mt-2"
          onClick={openModalMoreFilter}>
          <span>Tags: ({selectedTags.size})</span>
        </div>

        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}>
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 h-screen w-full"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900">
                      Tags
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilter} />
                    </span>
                    <a
                      className="absolute right-3 top-3 cursor-pointer"
                      onClick={clearTags}>
                      Clear
                    </a>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                      {ListingTags.map((tagGroup, index) => (
                        <div className="py-7" key={index}>
                          <h3 className="text-xl font-medium">
                            {tagGroup.label}
                          </h3>
                          <div className="mt-6 relative ">
                            {renderMoreFilterItem(tagGroup.options)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <button
                      type="button"
                      className="btn btn-primary btn-block"
                      onClick={closeModalMoreFilter}>
                      Done
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  const searchQuery = async (inputVal: string) => {
    if (inputVal.length < 2) {
      return [];
    }

    const results = await SearchLocation.where({location: inputVal})
      .per(100)
      .all();

    const items = results.data;
    if (items.length > 0 && items[items.length - 1].suburb === null) {
      const sl = items.pop();
      items.unshift(sl);
    }

    return items.map((sl) => {
      return {label: sl.suburb || sl.city, value: sl};
    });
  };

  const searchQueryPromise = (inputValue: string) =>
    new Promise<LocationSelectOption[]>((resolve) => {
      setTimeout(() => {
        resolve(searchQuery(inputValue));
      }, 1000);
    });

  return (
    <div>
      <Card className="mb-4 overflow-visible">
        <CardBody>
          <CardTitle>Location</CardTitle>

          <AsyncSelect
            isMulti
            cacheOptions
            defaultValue={searchLocations.map((sl) => {
              return {label: sl.suburb || sl.city, value: sl};
            })}
            loadOptions={searchQueryPromise}
            closeMenuOnSelect={true}
            components={{Option: LocationOption, ClearIndicator: null}}
            onChange={(vals: LocationSelectOption[]) => {
              if (vals.length > 0) {
                setSearchLocations(vals.map((v) => v.value));
              } else {
                setSearchLocations([]);
              }
            }}
          />
        </CardBody>
      </Card>

      <Card className="mb-4 overflow-visible">
        <CardBody>
          <CardTitle>Property</CardTitle>

          <div>
            <label className="label">
              <span className="label-text">Property Types</span>
            </label>

            <Select
              isMulti={true}
              options={PROPERTY_TYPE_OPTIONS}
              value={propertyTypes.map((propType) => {
                return {label: propType, value: propType};
              })}
              onChange={(vals: SelectOption[]) =>
                setPropertyTypes(vals.map((val) => val.value))
              }
              placeholder="Select property type(s)"
              isSearchable={false}
              isClearable={false}
            />
          </div>

          <div className="flex justify-between">
            <div className="w-full mr-1">
              <label className="label">
                <span className="label-text">Min Beds</span>
              </label>
              <Select
                options={constructNumberOptions(10, true)}
                value={{label: minBeds.toString(), value: minBeds.toString()}}
                onChange={(val: SelectOption) => setMinBeds(Number(val.value))}
                placeholder="Min Beds"
                isSearchable={false}
              />
            </div>
            <div className="w-full ml-1">
              <label className="label">
                <span className="label-text">Max Beds</span>
              </label>
              <Select
                options={constructNumberOptions(10, true)}
                value={{label: maxBeds.toString(), value: maxBeds.toString()}}
                onChange={(val: SelectOption) => setMaxBeds(Number(val.value))}
                placeholder="Max Beds"
                isSearchable={false}
              />
            </div>
          </div>

          <div className="flex justify-between">
            <div className="w-full mr-1">
              <label className="label">
                <span className="label-text">Min Baths</span>
              </label>
              <Select
                options={constructNumberOptions(10, true)}
                value={{label: minBaths.toString(), value: minBaths.toString()}}
                onChange={(val: SelectOption) => setMinBaths(Number(val.value))}
                placeholder="Min Baths"
                isSearchable={false}
              />
            </div>
            <div className="w-full ml-1">
              <label className="label">
                <span className="label-text">Max Baths</span>
              </label>
              <Select
                options={constructNumberOptions(10, true)}
                value={{label: maxBaths.toString(), value: maxBaths.toString()}}
                onChange={(val: SelectOption) => setMaxBaths(Number(val.value))}
                placeholder="Max Baths"
                isSearchable={false}
              />
            </div>
          </div>

          <div className="flex justify-between">
            <div className="w-full mr-1">
              <label className="label">
                <span className="label-text">Min Garages</span>
              </label>
              <Select
                options={constructNumberOptions(5, false)}
                value={{
                  label: minGarages.toString(),
                  value: minGarages.toString(),
                }}
                onChange={(val: SelectOption) =>
                  setMinGarages(Number(val.value))
                }
                placeholder="Min Garages"
                isSearchable={false}
              />
            </div>
            <div className="w-full ml-1">
              <label className="label">
                <span className="label-text">Max Garages</span>
              </label>
              <Select
                options={constructNumberOptions(5, false)}
                value={{
                  label: maxGarages.toString(),
                  value: maxGarages.toString(),
                }}
                onChange={(val: SelectOption) =>
                  setMaxGarages(Number(val.value))
                }
                placeholder="Max Garages"
                isSearchable={false}
              />
            </div>
          </div>
        </CardBody>
      </Card>

      <Card className="mb-4 overflow-visible">
        <CardBody>
          <CardTitle>Tenancy</CardTitle>

          <div className="relative flex flex-col mb-1">
            <label className="label block mb-0">
              <span className="label-text">Rent (Weekly)</span>
            </label>
            <div className="p-1">
              <Range
                min={MIN_RENT}
                max={MAX_RENT}
                className="text-primary"
                value={[minRent, maxRent]}
                allowCross={false}
                step={RENT_STEP}
                onChange={(val) => {
                  setMinRent(val[0]);
                  setMaxRent(val[1]);
                }}
                trackStyle={[{backgroundColor: '#3366FF'}]}
                handleStyle={[
                  {border: '2px solid #3366FF'},
                  {border: '2px solid #3366FF'},
                ]}
              />
            </div>

            <div className="flex justify-between space-x-3">
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Min Rent</span>
                </label>
                <label className="input-group input-group-sm">
                  <span>$</span>
                  <input
                    type="number"
                    className="input input-bordered input-sm"
                    value={minRent}
                    onChange={(e) => setMinRent(Number(e.target.value))}
                    min={MIN_RENT}
                    max={MAX_RENT}
                    step={RENT_STEP}
                  />
                </label>
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Max Rent</span>
                </label>
                <label className="input-group input-group-sm">
                  <span>$</span>
                  <input
                    type="number"
                    className="input input-bordered input-sm"
                    value={maxRent}
                    onChange={(e) => setMaxRent(Number(e.target.value))}
                    min={MIN_RENT}
                    max={MAX_RENT}
                    step={RENT_STEP}
                  />
                </label>
              </div>
            </div>
          </div>

          <div>
            <label className="label">
              <span className="label-text">Earliest Move In</span>
            </label>
            <Datetime
              value={
                availableFrom && availableFrom.length > 0
                  ? moment(availableFrom)
                  : availableFrom
              }
              onChange={(val) =>
                setAvailableFrom(moment(val).format('YYYY-MM-DD'))
              }
              dateFormat={DATE_FORMAT}
              isValidDate={(currentDate: moment.Moment, _): boolean => {
                return currentDate.isSameOrAfter(moment());
              }}
              timeFormat={false}
              inputProps={{
                placeholder: 'Click to select date',
                className: 'input input-bordered w-full',
              }}
            />
          </div>
        </CardBody>
      </Card>

      <Card className="mb-4 overflow-visible">
        <CardBody>
          <CardTitle>Amenities & Restrictions</CardTitle>

          <div>
            <label className="label">
              <span className="label-text">Allowed Pets</span>
            </label>

            <Select
              isMulti={true}
              options={PET_OPTIONS}
              value={allowedPets.map((pet) => {
                return {label: titleize(removeUnderscores(pet)), value: pet};
              })}
              onChange={(vals: SelectOption[]) =>
                setAllowedPets(vals.map((val) => val.value))
              }
              placeholder="Choose Pet Type(s)"
              isSearchable={false}
              isClearable={false}
            />
          </div>

          <div>
            <label className="label">
              <span className="label-text">Number of Tenants</span>
            </label>
            <Select
              options={constructNumberOptions(6, true)}
              value={
                maxTenants
                  ? {label: maxTenants.toString(), value: maxTenants.toString()}
                  : null
              }
              onChange={(val: SelectOption) => setMaxTenants(Number(val.value))}
              placeholder="Number of tenants"
              isSearchable={false}
            />
          </div>

          <div className="form-control">
            <label className="cursor-pointer label">
              <span className="label-text">Smoking Allowed?</span>
              <input
                type="checkbox"
                checked={smokingAllowed}
                className="toggle toggle-primary"
                onChange={(e) => {
                  setSmokingAllowed(!smokingAllowed);
                }}
              />
            </label>
          </div>

          {renderTabMoreFilter()}
        </CardBody>
      </Card>
    </div>
  );
};

export default ListingAdvancedSearchForm;
