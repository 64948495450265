import {useState} from 'react';

import moment from 'moment';
import {useQuery} from 'react-query';
import {useNavigate} from 'react-router';
import {Link} from 'react-router-dom';

import emptyBg from 'assets/img/empty_dataset_images/landlord_payments.svg';
import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import EmptyDataSet from 'components/common/EmptyDataSet';
import LoadingView from 'components/common/LoadingView';
import ExportPaymentsModal from 'components/landlord_payment/ExportPaymentsModal';
import PageWrapper from 'components/PageWrapper';
import {Modal} from 'components_sb/layout';
import useQueryString from 'hooks/useQueryString';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import LandlordPayment from 'models/payments/LandlordPayment';
import Tenancy from 'models/properties/Tenancy';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';
import {paginationArray} from 'utilities/paginationArray';
import {removeUnderscores, titleize, toCurrency} from 'utilities/StringHelpers';

const {useModal} = Modal.Imperative;

const LandlordPaymentIndexPage = () => {
  const navigate = useNavigate();

  const params = useQueryString();
  const page = params.has('page') ? Number(params.get('page')) : 1;

  useTitle('Financials');
  usePageVisit('LandlordPaymentIndexPage');

  const [statPeriod, setStatPeriod] = useState('30d');
  const [perPage, setPerPage] = useState(10);

  const isMobile = !useTailwindBreakpoint('sm');

  // Use an Array here as its easier to have empty array
  // for where query when we want all tenancies
  const [tenancyId, setTenancyId] = useState<string[]>([]);

  const tenanciesResponse = useQuery(
    `landlord-payments-tenancies`,
    async () => {
      const tenancies = await Tenancy.select({
        tenancies: ['id', 'property_id'],
        properties: ['street_address'],
      })
        .includes('property')
        .all();
      return tenancies.data;
    },
  );

  const statPaymentsResponse = useQuery(
    ['landlord-payments-stats', statPeriod, tenancyId],
    async () => {
      let dateFilter: string;
      if (statPeriod === '30d') {
        dateFilter = moment().subtract(30, 'days').format('YYYY-MM-DD');
      } else if (statPeriod === '90d') {
        dateFilter = moment().subtract(90, 'days').format('YYYY-MM-DD');
      } else if (statPeriod === '6m') {
        dateFilter = moment().subtract(6, 'months').format('YYYY-MM-DD');
      } else {
        dateFilter = moment().subtract(1, 'year').format('YYYY-MM-DD');
      }

      const payments = await LandlordPayment.where({
        payment_date: {gte: dateFilter},
        // status: ['paid', 'partially_paid'],
      })
        .order({payment_date: 'asc'})
        .per(0)
        .where({tenancyId})
        .stats({
          gross_amount: 'sum',
          expenses_amount: 'sum',
          net_amount: 'sum',
        })
        .all();
      return payments;
    },
  );

  const tablePaymentsResponse = useQuery(
    [`landlord-payments-table`, page, perPage, tenancyId],
    async () => {
      const payments = await LandlordPayment.page(page)
        .per(perPage)
        .includes({tenancy: 'property'})
        .order({payment_date: 'desc'})
        .select({
          tenancies: ['id'],
          properties: ['street_address'],
        })
        .where({tenancyId})
        .stats({
          total: 'count',
        })
        .all();
      return payments;
    },
    {keepPreviousData: true},
  );

  let datePeriod: string;
  if (statPeriod === '30d') {
    datePeriod = '30 Days';
  } else if (statPeriod === '90d') {
    datePeriod = '90 days';
  } else if (statPeriod === '6m') {
    datePeriod = '6 months';
  } else {
    datePeriod = '1 year';
  }

  const openModal = useModal();
  const openExportModal = () => openModal(ExportPaymentsModal, {});

  const setPage = (page: number | string) => navigate(`?page=${page}`);

  if (statPaymentsResponse.error) {
    return errorViewForError(statPaymentsResponse.error);
  } else if (tablePaymentsResponse.isLoading && page === 1) {
    return (
      <PageWrapper title="Financials">
        <LoadingView />
      </PageWrapper>
    );
  } else if (
    tablePaymentsResponse.data &&
    tablePaymentsResponse.data.data.length === 0
  ) {
    return (
      <PageWrapper title="Financials">
        <div className="flex justify-between items-center mb-8">
          <h3 className="text-xl lg:text-2xl font-semibold">
            Payments you've received
          </h3>
        </div>

        {tenanciesResponse.data && (
          <div className="mb-4">
            <select
              className="select select-lg select-bordered w-full"
              onChange={(e) => {
                const val = e.currentTarget.value;
                if (val === '0') {
                  setTenancyId([]);
                } else {
                  setTenancyId([val]);
                }
              }}>
              <option value="0">All Properties</option>
              {tenanciesResponse.data.map((t) => (
                <option value={t.id} key={t.id}>
                  {t.property.streetAddress}
                </option>
              ))}
            </select>
          </div>
        )}
        <EmptyDataSet
          title="No Received Payments"
          message="You have not received any payments from your rentals yet. Once payments from tenants have been made, they will show up here."
          image={emptyBg}
        />
      </PageWrapper>
    );
  } else {
    const total = tablePaymentsResponse.data.meta.stats.total.count;
    const maxPages = Math.ceil(total / perPage);

    return (
      <PageWrapper
        title="Financials"
        buttons={[{text: 'Export', onClick: openExportModal}]}>
        <div className="flex justify-between items-center mb-4 lg:mb-8">
          <h3 className="text-xl lg:text-2xl font-semibold">
            Payments you've received
          </h3>
        </div>

        {tenanciesResponse.data && (
          <div className="mb-4">
            <select
              className="select select-md lg:select-lg select-bordered w-full"
              onChange={(e) => {
                const val = e.currentTarget.value;
                if (val === '0') {
                  setTenancyId([]);
                } else {
                  setTenancyId([val]);
                }
              }}>
              <option value="0">All Properties</option>
              {tenanciesResponse.data.map((t) => (
                <option value={t.id} key={t.id}>
                  {t.property.streetAddress}
                </option>
              ))}
            </select>
          </div>
        )}

        <Card>
          <CardBody>
            <div className="flex flex-col lg:flex-row justify-between lg:items-center space-y-2 lg:space-y-0 mt-4 mb-2">
              <h5 className="text-xl font-semibold">Last {datePeriod}</h5>
              <div className="btn-group">
                {['30d', '90d', '6m', '1y'].map((period) => (
                  <button
                    key={period}
                    className={`btn btn-sm btn-${
                      statPeriod === period ? 'primary' : 'neutral'
                    }`}
                    onClick={() => setStatPeriod(period)}
                    type="button">
                    {period}
                  </button>
                ))}
              </div>
            </div>

            <div className="grid grid-cols-3 bg-white py-2 rounded-lg shadow-lg block lg:hidden">
              <div className="flex flex-col items-center">
                <span className="text-xs text-gray-500">Gross</span>
                <h4 className="text-info text-lg font-semibold">
                  {statPaymentsResponse.data &&
                    toCurrency(
                      statPaymentsResponse.data.meta.stats.gross_amount.sum,
                    )}
                </h4>
              </div>

              <div className="flex flex-col items-center">
                <span className="text-xs text-gray-500">Expenses</span>
                <h4 className="text-error text-lg font-semibold">
                  {statPaymentsResponse.data &&
                    toCurrency(
                      statPaymentsResponse.data.meta.stats.expenses_amount.sum,
                    )}
                </h4>
              </div>

              <div className="flex flex-col items-center">
                <span className="text-xs text-gray-500">Net</span>
                <h4 className="text-success text-lg font-semibold">
                  {statPaymentsResponse.data &&
                    toCurrency(
                      statPaymentsResponse.data.meta.stats.net_amount.sum,
                    )}
                </h4>
              </div>
            </div>

            <div className="w-full shadow stats stats-horizontal mlg:hidden">
              <div className="stat place-items-center place-content-center">
                <div className="stat-title">Gross</div>
                <div className="stat-value text-info">
                  {statPaymentsResponse.data &&
                    toCurrency(
                      statPaymentsResponse.data.meta.stats.gross_amount.sum,
                    )}
                </div>
              </div>
              <div className="stat place-items-center place-content-center">
                <div className="stat-title text-sm">Expenses</div>
                <div className="stat-value text-error">
                  {statPaymentsResponse.data &&
                    toCurrency(
                      statPaymentsResponse.data.meta.stats.expenses_amount.sum,
                    )}
                </div>
              </div>
              <div className="stat place-items-center place-content-center">
                <div className="stat-title">Net</div>
                <div className="stat-value text-success">
                  {statPaymentsResponse.data &&
                    toCurrency(
                      statPaymentsResponse.data.meta.stats.net_amount.sum,
                    )}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>

        <div className="mt-8">
          <div className="flex justify-between items-center">
            <h5 className="mb-0 font-xl font-semibold">All Payments</h5>

            <select
              className="select select-bordered select-sm py-0"
              onChange={(e) => setPerPage(Number(e.target.value))}
              value={perPage}>
              {[10, 25, 50, 100].map((count) => (
                <option key={count} value={count}>
                  {count}
                </option>
              ))}
            </select>
          </div>
          <div>
            <small className="text-secondary mt-0 block">
              Click on a {isMobile ? 'card' : 'row'} for more information.
            </small>
            <small className="text-secondary mt-0 block text-xs">
              Received refers to the amount that has been collected and cleared
              our account.
            </small>
            <small className="text-secondary mt-0 mb-2 block text-xs">
              Pending refers to the amount that has been collected but is
              pending bank clearance.
            </small>
          </div>

          {isMobile ? (
            <div className="flex flex-col space-y-2">
              {tablePaymentsResponse.data.data &&
                tablePaymentsResponse.data.data.map((lp) => (
                  <Link key={lp.id} to={lp.id}>
                    <Card>
                      <CardBody>
                        <div className="flex justify-between items-center">
                          <div className="flex flex-col">
                            <strong>
                              {moment(lp.paymentDate).format(DATE_FORMAT)}
                            </strong>
                            <span className="text-sm">
                              {lp.tenancy.property.streetAddress}
                            </span>
                            <span className="text-xs">
                              Status: {titleize(removeUnderscores(lp.status))}
                            </span>
                            <span className="text-xs text-gray-500">
                              Reference: {lp.reference}
                            </span>
                          </div>

                          <div className="flex flex-col items-center">
                            <span className="text-xs text-gray-400">Net</span>
                            <strong className="text-success">
                              {toCurrency(lp.netAmount)}
                            </strong>
                          </div>
                        </div>

                        <div className="grid grid-cols-4">
                          <div className="flex flex-col items-center">
                            <span className="text-xs">Gross</span>
                            <strong className="text-sm">
                              {toCurrency(lp.grossAmount)}
                            </strong>
                          </div>

                          <div className="flex flex-col items-center">
                            <span className="text-xs">Expenses</span>
                            <strong className="text-sm">
                              {toCurrency(lp.expensesAmount)}
                            </strong>
                          </div>

                          <div className="flex flex-col items-center">
                            <span className="text-xs">Received</span>
                            <strong className="text-sm">
                              {toCurrency(lp.amountOfRentPaid || 0.0)}
                            </strong>
                          </div>

                          <div className="flex flex-col items-center">
                            <span className="text-xs">Pending</span>
                            <strong className="text-sm">
                              {toCurrency(lp.pendingAmount || 0.0)}
                            </strong>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Link>
                ))}
            </div>
          ) : (
            <div className="overflow-x-auto">
              <table className="table w-full">
                <thead className="thead-light">
                  <tr>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Gross</th>
                    <th>Expenses</th>
                    <th>Net</th>
                    <th>Received</th>
                    <th>Pending</th>
                    <th>Reference</th>
                    <th>Property</th>
                  </tr>
                </thead>
                <tbody>
                  {tablePaymentsResponse.data.data &&
                    tablePaymentsResponse.data.data.map((lp) => (
                      <tr
                        key={lp.id}
                        className="hover cursor-pointer"
                        onClick={() => navigate(lp.id)}>
                        <td>{moment(lp.paymentDate).format(DATE_FORMAT)}</td>
                        <td>{titleize(removeUnderscores(lp.status))}</td>
                        <td>{toCurrency(lp.grossAmount)}</td>
                        <td>{toCurrency(lp.expensesAmount)}</td>
                        <td className="text-success">
                          {toCurrency(lp.netAmount)}
                        </td>
                        <td>{toCurrency(lp.amountOfRentPaid || 0.0)}</td>
                        <td className="text-secondary">
                          {toCurrency(lp.pendingAmount || 0.0)}
                        </td>
                        <td>{lp.reference}</td>
                        <td>{lp.tenancy.property.streetAddress}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <div className="flex flex-wrap justify-center mt-8">
          <div className="btn-group">
            <button
              className="btn"
              onClick={() => setPage(page - 1)}
              disabled={page == 0}>
              Prev
            </button>
            {paginationArray(page - 1, maxPages).map((pageIter) =>
              pageIter === '...' ? (
                <button key={pageIter} className="btn btn-disabled">
                  {pageIter}
                </button>
              ) : (
                <button
                  key={pageIter}
                  className={`btn ${
                    page === Number(pageIter) ? 'btn-active' : ''
                  }`}
                  onClick={() => setPage(Number(pageIter))}>
                  {pageIter}
                </button>
              ),
            )}
            <button
              className="btn"
              onClick={() => setPage(page + 1)}
              disabled={tablePaymentsResponse.data.data.length < perPage}>
              Next
            </button>
          </div>
        </div>
      </PageWrapper>
    );
  }
};

export default LandlordPaymentIndexPage;
