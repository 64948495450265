import {type FormikProps} from 'formik';
import moment from 'moment';
import Datetime, {DatetimepickerProps} from 'react-datetime';

import {errorsFor, valueFor} from 'components/forms_fields/Helpers';
import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import TrackingService from 'services/TrackingService';
import {DATE_FORMAT} from 'utilities/DateHelpers';

import './DateField.css';

interface DateTimeFieldProps extends DatetimepickerProps {
  formik: FormikProps<any>;
  minDate: Date;
  maxDate: Date;
  label: string;
  name: string;
  helpText?: string;
}

const DatetimeField = ({
  formik,
  label,
  name,
  minDate = new Date(),
  maxDate = new Date(),
  helpText,
  ...rest
}: DateTimeFieldProps) => {
  const classN = formik.errors[name] ? ' border border-danger' : '';
  if (rest.className) {
    rest.className += classN;
  } else {
    rest.className = classN;
  }

  const valid = (
    currentDate: moment.Moment,
    selectedDate: moment.Moment,
  ): boolean => {
    return (
      currentDate.isSameOrAfter(moment(minDate)) &&
      currentDate.isSameOrBefore(moment(maxDate))
    );
  };

  const value = valueFor(formik, name);
  const errors = errorsFor(formik, name);

  return (
    <>
      <label className="label flex justify-start items-center">
        <span className="label-text mr-2">{label}</span>
        <HelpTextPresenter helpText={helpText} />
      </label>
      <Datetime
        value={value ? moment(value) : ''}
        dateFormat={DATE_FORMAT}
        onChange={(val) => {
          const m = moment(val);
          formik.setFieldValue(name, m.toISOString());
          TrackingService.trackEvent(TrackingService.Event.FillField, {
            field: name,
          });
        }}
        isValidDate={valid}
        {...rest}
      />
      {errors && (
        <span className="text-red-500 text-sm">
          <small>{errors}</small>
        </span>
      )}
    </>
  );
};

export default DatetimeField;
