import {useQuery} from 'react-query';
import {useParams} from 'react-router';

import emptyBg from 'assets/img/empty_dataset_images/rental_applications.svg';
import EmptyDataSet from 'components/common/EmptyDataSet';
import LoadingView from 'components/common/LoadingView';
import ListingIndexCard from 'components/listing/ListingIndexCard';
import PageWrapper from 'components/PageWrapper';
import ListingRentalApplication from 'models/listings/ListingRentalApplication';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';

const RentalApplicationListingsPage = () => {
  usePageVisit('RentalApplicationListingsPage');
  const {id} = useParams();

  const {data, isLoading, error} = useQuery(
    `rental-application-${id}-listings`,
    async () => {
      const lras = await ListingRentalApplication.where({
        rental_application_id: id,
      })
        .includes({listing: 'listing_photos'})
        .all();

      return lras.data;
    },
  );

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Listings you've applied for">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    if (data.length > 0) {
      return (
        <PageWrapper title="Listings you've applied for">
          <div className="grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2">
            {data.map((lra) => (
              <ListingIndexCard key={lra.id} listing={lra.listing} />
            ))}
          </div>
        </PageWrapper>
      );
    } else {
      return (
        <PageWrapper title="Listings you've applied for">
          <EmptyDataSet
            title="No Listings"
            message="You haven't applied for any listings with this application yet."
            image={emptyBg}
          />
        </PageWrapper>
      );
    }
  }
};

export default RentalApplicationListingsPage;
