import {useEffect, useState} from 'react';

import {useQueryClient} from 'react-query';

import apartmentIcon from 'assets/img/property_types/apartment.png';
import houseIcon from 'assets/img/property_types/house.png';
import townhouseIcon from 'assets/img/property_types/townhouse.png';
import unitIcon from 'assets/img/property_types/unit.png';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightSideText from 'components/walkthrough/common/RightSideText';
import Property from 'models/properties/Property';
import TrackingService from 'services/TrackingService';

const propertyTypes = [
  {name: 'Apartment', icon: apartmentIcon},
  {name: 'House', icon: houseIcon},
  {name: 'Townhouse', icon: townhouseIcon},
  {name: 'Unit', icon: unitIcon},
];

const PropertyTypeStep = ({property}: {property: Property}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_StartPropertyTypeStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const listing = property.listings[0];
  const [selectedType, setSelectedType] = useState(listing.propertyType || '');

  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  const savePropertyType = async () => {
    setIsLoading(true);

    if (selectedType) {
      property.propertyType = selectedType;
      property.lastOnboardingStepCompleted = 'listing_property_type';
      listing.propertyType = selectedType;

      const result1 = await property.save();
      const result2 = await listing.save();

      if (result1 && result2) {
        /**
         * Track specific event type.
         */
        const event = {
          Apartment: TrackingService.Event.ListProperty_SelectApartment,
          House: TrackingService.Event.ListProperty_SelectHouse,
          Townhouse: TrackingService.Event.ListProperty_SelectTownhouse,
          Unit: TrackingService.Event.ListProperty_SelectUnit,
        }[selectedType];
        if (event) {
          TrackingService.trackEvent(event, {
            propertyId: property.id,
          });
        }
        /**
         * Track general completion of the step.
         */
        TrackingService.trackEvent(
          TrackingService.Event.ListProperty_CompletePropertyTypeStep,
          {
            propertyId: property.id,
          },
        );

        setIsLoading(false);
        queryClient.setQueryData('new-listing-wizard', property);
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const title = 'What type of property do you have?';

  return (
    <Container>
      <LeftSide
        title={title}
        subtitle={
          <span>
            Most properties are houses but if you're unsure,{' '}
            <a
              target="_blank"
              href="https://help.keyhook.com/adding-a-property/type-of-property"
              className="link link-primary text-white">
              find out more here.
            </a>
          </span>
        }
      />
      <RightContainer>
        <RightSideText
          title={title}
          subtitle={
            <span>
              Most properties are houses but if you're unsure,{' '}
              <a
                target="_blank"
                href="https://help.keyhook.com/adding-a-property/type-of-property"
                className="link link-primary">
                find out more here.
              </a>
            </span>
          }
        />
        <div className="flex flex-col items-center space-y-4">
          {propertyTypes.map((type, index) => (
            <div
              onClick={() => setSelectedType(type.name)}
              className={
                'flex flex-col justify-center px-4 py-6 border-2 rounded-xl w-full md:w-1/2 cursor-pointer ' +
                (selectedType === type.name
                  ? 'border-neutral bg-gray-100'
                  : 'border-gray-300 bg-white')
              }
              key={index}>
              <div className="flex justify-start items-center gap-2">
                <img src={type.icon} alt={type.name} className="w-8 h-8" />
                <p
                  className={type.name === selectedType ? 'font-semibold' : ''}>
                  {type.name}
                </p>
              </div>
            </div>
          ))}
        </div>

        <ProgressBox
          nextStep={savePropertyType}
          step={1}
          previousStep={() => {
            console.log('previous_step');
          }}
          previousStepEnabled={false}
          nextStepEnabled={selectedType.length > 0 && !isLoading}
        />
      </RightContainer>
    </Container>
  );
};

export default PropertyTypeStep;
