import React, {FormEvent, useState} from 'react';

import {AiOutlineSearch} from '@react-icons/all-files/ai/AiOutlineSearch';
import {useNavigate} from 'react-router';

import BedroomRangeInput from 'components/chifis_theme/BedroomRangeInput';
import LocationInput from 'components/chifis_theme/LocationInput';
import PriceRangeInput from 'components/chifis_theme/PriceRangeInput';
import PropertyTypeSelect from 'components/chifis_theme/PropertyTypeSelect';
import SearchLocation from 'models/listings/SearchLocation';

const queryString = require('query-string');

const ListingIndexBasicSearchForm = () => {
  const navigate = useNavigate();

  const [locationInputValue, setLocationInputValue] = useState('');
  const [selectedSearchLocation, setSelectedSearchLocation] =
    useState<SearchLocation | null>(null);
  const [propertyTypes, setPropertyTypes] = useState<string[]>([]);
  const [bedroomRange, setBedroomRange] = useState<number[]>([]);
  const [rentRange, setRentRange] = useState<number[]>([]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const vals = {} as any;
    if (selectedSearchLocation) {
      if (selectedSearchLocation.suburb) {
        vals.locations = [
          `${selectedSearchLocation.suburb}-${selectedSearchLocation.city}`,
        ];
      } else {
        vals.locations = [selectedSearchLocation.city];
      }
    }

    if (propertyTypes && propertyTypes.length > 0) {
      vals.propertyTypes = propertyTypes.join(',');
    }
    if (bedroomRange && bedroomRange.length > 0) {
      vals.minBedrooms = bedroomRange[0];
      vals.maxBedrooms = bedroomRange[1];
    }
    if (rentRange && rentRange.length > 0) {
      vals.minRent = rentRange[0];
      vals.maxRent = rentRange[1];
    }

    if (Object.keys(vals).length > 0) {
      const qs = queryString.stringify(vals, {
        arrayFormat: 'comma',
      });
      navigate(`search?${qs}`);
    } else {
      navigate('search');
    }
  };

  return (
    <div>
      <form
        className="w-full relative xl:mt-8 flex flex-col lg:flex-row lg:items-center rounded-3xl lg:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-700 lg:divide-y-0"
        onSubmit={handleSubmit}>
        <LocationInput
          defaultValue={locationInputValue}
          onChange={setLocationInputValue}
          onInputDone={setSelectedSearchLocation}
        />

        <PropertyTypeSelect onChange={setPropertyTypes} />
        <BedroomRangeInput onChange={setBedroomRange} />
        <PriceRangeInput onChange={setRentRange} />
        {/* BUTTON SUBMIT OF FORM */}
        <div className="px-4 py-4 lg:py-0">
          <button
            type="submit"
            className="h-14 md:h-16 w-full md:w-16 rounded-full bg-primary hover:bg-accent flex items-center justify-center text-white focus:outline-none">
            <span className="mr-3 md:hidden">Search</span>
            <AiOutlineSearch className="w-5 h-5" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default ListingIndexBasicSearchForm;
