import {create} from 'zustand';

import {PropertyIndexSettings, GlobalUIService} from 'services/GlobalUIService';

type GlobalUIStore = {
  accountTypeModalVisible: boolean;
  setAccountTypeModalVisible: (val: boolean) => void;
  toggleAccountTypeModalVisible: () => void;

  propertyIndexSettings: PropertyIndexSettings;
  setPropertyIndexSettings: (settings: PropertyIndexSettings) => void;
};

const useGlobalUIStore = create<GlobalUIStore>(
  (set): GlobalUIStore => ({
    accountTypeModalVisible: false,
    setAccountTypeModalVisible: (val: boolean) =>
      set((_) => ({accountTypeModalVisible: val})),
    toggleAccountTypeModalVisible: () =>
      set((state) => ({
        accountTypeModalVisible: !state.accountTypeModalVisible,
      })),

    propertyIndexSettings: GlobalUIService.getPropertyIndexSettings(),
    setPropertyIndexSettings: (settings: PropertyIndexSettings) => {
      set((_) => ({propertyIndexSettings: settings}));
      GlobalUIService.setPropertyIndexSettings(settings);
    },
  }),
);

export default useGlobalUIStore;
