// @ts-nocheck

import bankAccountValidator from '@fnzc/nz-bank-account-validator';
import {Formik, Form} from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import {
  InputField,
  SelectField,
  SubmitButton,
  DOBField,
  TextareaField,
} from 'components/forms_fields';
import RenterProfile from 'models/users/RenterProfile';

const RenterProfileForm = ({
  model,
  submitHandler,
}: {
  model: RenterProfile;
  submitHandler: any;
}) => {
  const schema = Yup.object({
    bio: Yup.string().max(1000).label('Bio').nullable().optional(),
    birthDate: Yup.string()
      .test('is-valid-format', 'This is not a valid Date', (value) =>
        moment(value, 'DD/MM/YYYY').isValid(),
      )
      .test('is-not-too-old', 'This is not a valid Age', (val) =>
        moment(val, 'DD/MM/YYYY').isSameOrAfter(moment('1900-01-01')),
      )
      .test('is-not-too-young', 'This is not a valid Age', (val) =>
        moment(val, 'DD/MM/YYYY').isSameOrBefore(
          moment().subtract({years: 16}),
        ),
      )
      .required()
      .nullable()
      .optional()
      .label('Date Of Birth'),
    jobTitle: Yup.string().min(3).max(100).label('Job Title').optional(),
    pets: Yup.string().label('Pets').nullable().optional(),
    phoneNumber: Yup.string().min(7).required().label('Phone Number'),
  });

  if (model.isPersisted) {
    schema['bankAccountName'] = Yup.string().required().label('Account Name');

    schema['bankAccountNumber'] = Yup.string()
      .required()
      .test('is-valid-format', 'This is not a valid NZ number', (value) => {
        if (!value) return false;

        const parts = value.split('-');
        if (parts.length === 4) {
          return bankAccountValidator.isValidNZBankNumber(
            parts[0],
            parts[1],
            parts[2],
            parts[3],
          );
        } else {
          return false;
        }
      })
      .label('Account Number');
  }

  return (
    <Formik
      initialValues={{
        bio: model.bio,
        birthDate: model.birthDate,
        jobTitle: model.jobTitle || '',
        pets: model.pets || '',
        phoneNumber: model.phoneNumber || '',
        bankAccountName: model.bankAccountName || null,
        bankAccountNumber: model.bankAccountNumber || null,
      }}
      onSubmit={submitHandler}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}>
      {(formik) => (
        <Form>
          <div>
            {model.isPersisted && (
              <TextareaField
                formik={formik}
                name="bio"
                label="About Me (Optional)"
                type="textarea"
                placeholder="A description of yourself that will be included in any rental applications."
              />
            )}

            <DOBField formik={formik} label="Date of Birth" name="birthDate" />

            {model.isPersisted && (
              <InputField
                formik={formik}
                name="jobTitle"
                label="Job Title"
                placeholder="Eg: Policy Analyst"
                autoComplete="off"
              />
            )}

            {model.isPersisted && (
              <SelectField formik={formik} name="pets" label="Pets">
                <option value="None">None</option>
                <option value="Dog">Dog</option>
                <option value="Cat">Cat</option>
                <option value="Other">Other</option>
              </SelectField>
            )}

            <InputField
              label="Phone Number"
              name="phoneNumber"
              formik={formik}
              placeholder="eg: 027 123 4567"
              autoCapitalize="none"
            />
            <p className="text-secondary text-sm mt-1">
              Your phone number is only used for Tenancy Services paperwork.
            </p>

            {model.isPersisted && (
              <div>
                <strong className="block mt-4">Bank Account Information</strong>
                <p className="text-secondary text-sm">
                  This is the account we will pay your bond refunds to.
                </p>

                <InputField
                  label="Account Name"
                  name="bankAccountName"
                  formik={formik}
                  placeholder="eg: John Smith"
                  autoCapitalize="words"
                />
                <InputField
                  label="Account Number"
                  name="bankAccountNumber"
                  formik={formik}
                  placeholder="eg: 02-1234-5678900-00 include dashes"
                  autoCapitalize="words"
                />
              </div>
            )}

            <SubmitButton
              formik={formik}
              text="Save Profile"
              submittingText="Saving"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RenterProfileForm;
