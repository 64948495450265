import {HTMLProps} from 'react';

import {MinusIcon, PlusIcon} from '@heroicons/react/outline';
import {type FormikProps} from 'formik';

import {errorsFor, valueFor} from 'components/forms_fields/Helpers';

interface InputFieldProps extends HTMLProps<HTMLInputElement> {
  formik: FormikProps<any>;
  label: string;
  name: string;
  incrementValue?: number;
}

const CountableNumberField = ({
  formik,
  label,
  name,
  incrementValue = 1,
  ...rest
}: InputFieldProps) => {
  const errors = errorsFor(formik, name);
  const value = valueFor(formik, name);

  const handleClickIncrement = () => {
    const currentValue = formik.values[name];
    if (currentValue) {
      formik.setFieldValue(name, currentValue + incrementValue);
    } else {
      formik.setFieldValue(name, incrementValue);
    }
  };

  const handleClickDecrement = () => {
    const currentValue = formik.values[name];
    if (currentValue) {
      formik.setFieldValue(name, Math.max(0, currentValue - incrementValue));
    } else {
      formik.setFieldValue(name, 0);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between space-x-5">
        <div className="flex flex-col">
          <span className="text-lg md:text-3xl font-medium text-neutral-800 dark:text-neutral-200">
            {label}
          </span>
        </div>

        <div className="flex items-center justify-between">
          <button
            className="w-10 h-10 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
            type="button"
            onClick={handleClickDecrement}>
            <MinusIcon className="w-4 h-4" />
          </button>
          <span className="inline-block mx-4">{value}</span>
          <button
            className="w-10 h-10 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
            type="button"
            onClick={handleClickIncrement}>
            <PlusIcon className="w-4 h-4" />
          </button>
        </div>
      </div>

      {errors && (
        <span className="text-red-500">
          <small>{errors}</small>
        </span>
      )}
    </div>
  );
};

export default CountableNumberField;
