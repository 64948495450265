import {Formik, Form} from 'formik';
import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import {DateField, InputField, SubmitButton} from 'components/forms_fields';
import PageWrapper from 'components/PageWrapper';
import RentPriceChange from 'models/properties/RentPriceChange';
import Tenancy from 'models/properties/Tenancy';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';
import {toCurrency} from 'utilities/StringHelpers';

const NewRentPriceChangePage = () => {
  useTitle('Rent Change');
  usePageVisit('NewRentPriceChangePage');

  const {id, propertyId} = useParams();

  const {data, isLoading, error} = useQuery(
    `tenancy-${id}-new-rent-change`,
    async () => {
      const tenancy = await Tenancy.select([
        'id',
        'start_date',
        'end_date',
        'rental_period',
        'total_rent',
      ]).find(id);

      return tenancy.data;
    },
  );
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleSubmit = async (fieldValues: any, actions: any) => {
    const rentChange = new RentPriceChange(fieldValues);
    rentChange.tenancyId = id;

    const result = await rentChange.save();
    if (result) {
      toast.success(
        'The rent change has been successfully added, we will notify your tenants and handle adjusting the rent on the set date.',
      );
      await queryClient.invalidateQueries(
        `landlord-property-detail-${propertyId}`,
      );
      navigate(`/properties/${propertyId}`);
    } else {
      if (rentChange.errors.effectiveDate) {
        actions.setFieldError(
          'effectiveDate',
          rentChange.errors.effectiveDate.message,
        );
      }
    }

    actions.setSubmitting(false);
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Rent Change">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    const minDate = moment().add(60, 'days');
    let maxDate: moment.Moment;
    if (data.endDate) {
      maxDate = moment(data.endDate);
    } else {
      maxDate = moment().add(2, 'years');
    }

    const numDays = data.rentalPeriod === 'Weekly' ? 7 : 14;
    const dates = new Set();
    let currentDate = moment(minDate);
    while (currentDate.isSameOrBefore(maxDate)) {
      dates.add(moment(currentDate).format('YYYY-MM-DD'));
      currentDate = moment(currentDate).add(numDays, 'days');
    }

    const dateFilter = (
      currentDate: moment.Moment,
      _selectedDate: moment.Moment,
    ) => {
      return dates.has(currentDate.format('YYYY-MM-DD'));
    };

    return (
      <PageWrapper title="Rent Change">
        <Card className="overflow-visible">
          <CardBody>
            <CardTitle>Rent Change</CardTitle>
            <p>
              Please note that under the new NZ tenancy laws, you are only
              allowed to increase rent once every 12 months. You must also
              provide your tenants with a minimum of 60 days notice.
            </p>
            <p>
              Keyhook also only allow days that match up with the day of the
              week your tenants currently pay rent.
            </p>

            <p>
              The current rent for this tenancy is{' '}
              <strong>{toCurrency(data.totalRent)}</strong>
            </p>

            <Formik
              initialValues={{
                newTotalRent: undefined,
                effectiveDate: undefined,
              }}
              onSubmit={handleSubmit}
              validationSchema={Yup.object().shape({
                newTotalRent: Yup.number().min(0).required().label('New Rent'),
                effectiveDate: Yup.string().required().label('Effective Date'),
              })}>
              {(formik) => (
                <Form>
                  <div className="mt-2">
                    <div className="w-1/2">
                      <InputField
                        name="newTotalRent"
                        formik={formik}
                        label="New Rent ($)"
                        placeholder="eg: 780.00"
                      />
                    </div>
                    <div>
                      <DateField
                        name="effectiveDate"
                        formik={formik}
                        label="Effective Date"
                        minDate={minDate.toDate()}
                        maxDate={maxDate.toDate()}
                        dateFormat={DATE_FORMAT}
                        isValidDate={dateFilter}
                        defaultValue={minDate}
                      />
                    </div>

                    <SubmitButton
                      formik={formik}
                      text="Save"
                      submittingText="Saving..."
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </PageWrapper>
    );
  }
};

export default NewRentPriceChangePage;
