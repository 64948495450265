import {useEffect, useState} from 'react';

import {type FormikHelpers, Formik, Form} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import {CountableNumberField} from 'components/forms_fields';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightFooter from 'components/walkthrough/common/RightFooter';
import RightSideText from 'components/walkthrough/common/RightSideText';
import Property from 'models/properties/Property';
import TrackingService from 'services/TrackingService';

type FormValues = {
  bedrooms: number;
  bathrooms: number;
  garages: number;
};

const GeneralInfoStep = ({
  property,
  stepNumber,
  totalSteps,
}: {
  property: Property;
  stepNumber: number;
  totalSteps: number;
}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_StartRoomsStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = async (
    formValues: FormValues,
    _actions: FormikHelpers<FormValues>,
  ) => {
    setIsLoading(true);

    property.assignAttributes(formValues);
    property.lastOnboardingStepCompleted = 'new_general_information';
    property.markStepAsCompleted('general_information');

    await property.save();

    /**
     * Track completion of the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_CompleteRoomsStep,
      {
        propertyId: property.id,
      },
    );

    queryClient.setQueryData(['new-property', property.id], property);
    setIsLoading(false);
  };

  const previousStep = async () => {
    property.lastOnboardingStepCompleted = 'select_onboarding_type';
    await property.save();

    queryClient.setQueryData(['new-property', property.id], property);
  };

  const title = 'Tell us about your property...';

  return (
    <Container>
      <LeftSide title={title} subtitle="" />
      <RightContainer>
        <RightSideText title={title} />
        <Formik
          initialValues={
            {
              bedrooms: property.bedrooms || 1,
              bathrooms: property.bathrooms || 1,
              garages: property.garages || 0,
            } as FormValues
          }
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            bathrooms: Yup.number()
              .min(1)
              .max(10)
              .required()
              .label('Bathrooms'),
            bedrooms: Yup.number().min(0).max(12).required().label('Bedrooms'),
            garages: Yup.number().min(0).max(12).required().label('Garages'),
          })}>
          {(formik) => (
            <Form>
              <div className="flex flex-col md:mx-16 space-y-8">
                <CountableNumberField
                  label="Bedrooms"
                  name="bedrooms"
                  formik={formik}
                />

                <CountableNumberField
                  label="Bathrooms"
                  name="bathrooms"
                  incrementValue={0.5}
                  formik={formik}
                />

                <CountableNumberField
                  label="Garages"
                  name="garages"
                  formik={formik}
                />
              </div>
              <ProgressBox
                nextStep={formik.submitForm}
                previousStep={previousStep}
                step={stepNumber}
                totalSteps={totalSteps}
                previousStepEnabled
                nextStepEnabled={!isLoading}
              />
            </Form>
          )}
        </Formik>
      </RightContainer>
    </Container>
  );
};

export default GeneralInfoStep;
