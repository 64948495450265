import {Formik} from 'formik';
import moment from 'moment';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import {InputField, TextareaField} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import PseudoBooleanDisplayView from 'components/rental_application/PseudoBooleanDisplayView';
import UserAvatar from 'components/user/UserAvatar';
import RentalApplication from 'models/listings/RentalApplication';

const RentalApplicationDetailView = ({
  rentalApplication,
  tag = '',
}: {
  rentalApplication: RentalApplication;
  tag?: '' | 'shortlisted' | 'excluded';
}) => {
  return (
    <div>
      <Card className="mt-4">
        <CardBody>
          <div className="flex justify-start items-center gap-2">
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <h3 className="text-xl font-semibold">
                  {rentalApplication.headTenantName}
                </h3>

                {tag === 'shortlisted' && (
                  <div className="badge badge-success">Shortlisted</div>
                )}

                {tag === 'excluded' && (
                  <div className="badge badge-error">Excluded</div>
                )}
              </div>
              {rentalApplication.headTenantDob && (
                <p className="text-secondary">
                  DOB:{' '}
                  {moment(rentalApplication.headTenantDob).format(
                    'DD MMM YYYY',
                  )}
                </p>
              )}
            </div>
          </div>
        </CardBody>
      </Card>

      <Formik
        initialValues={rentalApplication}
        onSubmit={() => {
          'noop';
        }}
        validateOnBlur={false}
        validateOnChange={false}>
        {(formik) => (
          <div>
            <Card className="mt-4">
              <CardBody>
                <CardTitle className="mt-2">Employment Information</CardTitle>

                <FormRow responsive>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="occupation"
                      label="Occupation"
                      disabled
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="weeklyIncome"
                      label="Weekly Income Before Tax ($)"
                      type="number"
                      disabled
                    />
                  </FormRowItem>
                </FormRow>

                <FormRow responsive>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="employer"
                      label="Employer"
                      disabled
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="yearsEmployedAtCurrentPlace"
                      label="Years employed there"
                      disabled
                    />
                  </FormRowItem>
                </FormRow>

                <div className="w-full lg:w-1/2">
                  <PseudoBooleanDisplayView
                    label="Are you currently employed full time?"
                    value={formik.values.headTenantEmployedFullTime}
                  />
                </div>

                <CardTitle className="mt-2">Current Living Situation</CardTitle>
                <FormRow responsive>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="currentAddress"
                      label="What is your current address?"
                      disabled
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="currentAddressStayLength"
                      label="How long have you stayed there?"
                      disabled
                    />
                  </FormRowItem>
                </FormRow>

                <TextareaField
                  formik={formik}
                  name="currentAddressReasonLeaving"
                  label="What is your reason for leaving?"
                  disabled
                />
              </CardBody>
            </Card>

            <Card className="mt-4">
              <CardBody>
                <CardTitle>About Us</CardTitle>

                <p className="whitespace-pre-line">
                  {rentalApplication.aboutUs}
                </p>
              </CardBody>
            </Card>

            <Card className="mt-4">
              <CardBody>
                <CardTitle>Tenancy Information</CardTitle>
                <FormRow>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="preferredTenancyLength"
                      label="Preferred Tenancy Length"
                      value={
                        rentalApplication.preferredTenancyLength
                          ? `${rentalApplication.preferredTenancyLength} Months`
                          : ''
                      }
                      disabled
                    />
                  </FormRowItem>

                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="preferredTenancyStartDate"
                      label="Preferred Tenancy Start Date"
                      disabled
                      value={
                        rentalApplication.preferredTenancyStartDate
                          ? moment(
                              rentalApplication.preferredTenancyStartDate,
                            ).format('DD MMMM YYYY')
                          : ''
                      }
                    />
                  </FormRowItem>
                </FormRow>

                <TextareaField
                  formik={formik}
                  name="cantCompleteTenancyReasons"
                  label="Are there any reasons you might not be able to complete the length of the tenancy?"
                  value={rentalApplication.cantCompleteTenancyReasons || ''}
                  disabled
                />

                <InputField
                  formik={formik}
                  name="vehiclesCount"
                  label="How many vehicles will be parked at the property?"
                  disabled
                />

                <PseudoBooleanDisplayView
                  label="Does any tenant have pets?"
                  value={formik.values.hasPets}
                />

                {Object.keys(rentalApplication.petTypes).length > 0 && (
                  <div className="ml-2">
                    <strong className="text-md">Pets</strong>
                    <ul className="list-disc ml-4">
                      {Object.entries(formik.values.petTypes).map(
                        ([key, val], index) => (
                          <li className="text-md" key={index}>
                            {key} x {val}
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                )}
              </CardBody>
            </Card>

            <Card className="mt-4">
              <CardBody>
                <CardTitle>References</CardTitle>
                <strong>Employer Reference</strong>

                <FormRow responsive>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="employerReferenceName"
                      label="Full Name"
                      disabled
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="employerReferenceCompanyName"
                      label="Company Name"
                      disabled
                    />
                  </FormRowItem>
                </FormRow>

                <FormRow>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="employerReferenceRelationship"
                      label="Relationship"
                      disabled
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="employerReferenceContactNumber"
                      label="Contact Number"
                      disabled
                    />
                  </FormRowItem>
                </FormRow>

                <strong className="block mt-2">Landlord Reference</strong>

                <FormRow responsive>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="landlordReferenceName"
                      label="Full Name"
                      disabled
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="landlordReferenceRelationship"
                      label="Relationship"
                      disabled
                    />
                  </FormRowItem>
                </FormRow>

                <FormRow responsive>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="landlordReferenceContactNumber"
                      label="Contact Number"
                      disabled
                    />
                  </FormRowItem>
                </FormRow>

                <strong className="block mt-2">Other Reference</strong>

                <FormRow responsive>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="otherReferenceName"
                      label="Full Name"
                      disabled
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="otherReferenceRelationship"
                      label="Relationship"
                      disabled
                    />
                  </FormRowItem>
                </FormRow>

                <FormRow responsive>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="otherReferenceContactNumber"
                      label="Contact Number"
                      disabled
                    />
                  </FormRowItem>
                </FormRow>
              </CardBody>
            </Card>

            <Card className="mt-4">
              <CardBody>
                <CardTitle>Other Tenants</CardTitle>

                <FormRow responsive>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="additionalTotalOccupants"
                      label="Additional number of occupants"
                      disabled
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <span className="label">
                      <span className="label-text">
                        Additional tenants who will sign the lease
                      </span>
                    </span>
                    <input
                      type="text"
                      className="input input-bordered w-full"
                      disabled
                      value={
                        rentalApplication.rentalApplicationApplicants.length
                      }
                    />
                  </FormRowItem>
                </FormRow>

                <div>
                  {formik.values.rentalApplicationApplicants.map(
                    (applicant, index) => (
                      <div className="mt-4 bg-gray-100 p-4" key={index}>
                        <strong>Additional Tenant {index + 1}</strong>
                        <FormRow responsive>
                          <FormRowItem>
                            <InputField
                              formik={formik}
                              name={`rentalApplicationApplicants[${index}].name`}
                              label="Full Name"
                              disabled
                            />
                          </FormRowItem>
                          <FormRowItem>
                            <InputField
                              formik={formik}
                              name={`rentalApplicationApplicants[${index}].email`}
                              label="Email Address"
                              type="email"
                              disabled
                            />
                          </FormRowItem>
                        </FormRow>
                        <FormRow responsive>
                          <FormRowItem>
                            <InputField
                              formik={formik}
                              name={`rentalApplicationApplicants[${index}].dateOfBirth`}
                              label="Date Of Birth"
                              value={
                                rentalApplication.rentalApplicationApplicants[
                                  index
                                ].dateOfBirth
                                  ? moment(
                                      rentalApplication
                                        .rentalApplicationApplicants[index]
                                        .dateOfBirth,
                                    ).format('DD MMMM YYYY')
                                  : null
                              }
                              disabled
                            />
                          </FormRowItem>
                          <FormRowItem>
                            <InputField
                              formik={formik}
                              name={`rentalApplicationApplicants[${index}].weeklyIncome`}
                              label="Weekly Income Before Tax ($)"
                              disabled
                            />
                          </FormRowItem>
                        </FormRow>
                      </div>
                    ),
                  )}
                </div>

                <div className="mt-2 w-full lg:w-1/2">
                  <PseudoBooleanDisplayView
                    value={formik.values.applicantsCanProvideReferences}
                    label="Can all of the tenants above provide up to 3 references?"
                  />
                </div>
              </CardBody>
            </Card>

            <Card className="mt-4">
              <CardBody>
                <CardTitle>About the tenants</CardTitle>

                <div>
                  <PseudoBooleanDisplayView
                    value={formik.values.anySmokers}
                    label="Are any of the proposed tenants smokers?"
                  />
                </div>

                <div>
                  <PseudoBooleanDisplayView
                    value={formik.values.involvedWithTenancyTribunal}
                    label="Have any of the proposed tenants been involved with the Tenancy Tribunal?"
                  />
                  {formik.values.involvedWithTenancyTribunal === 'Yes' ? (
                    <TextareaField
                      formik={formik}
                      name="tenancyTribunalInformation"
                      label="Further information"
                      disabled
                    />
                  ) : null}
                </div>

                <div>
                  <PseudoBooleanDisplayView
                    value={formik.values.pastBondDeductions}
                    label="Has any proposed tenant ever had money deducted from their bond?"
                  />
                </div>

                <div>
                  <PseudoBooleanDisplayView
                    value={formik.values.oweMoneyToLandlord}
                    label="Does any proposed tenant currently owe money to a landlord or property manager?"
                  />
                </div>

                <div>
                  <PseudoBooleanDisplayView
                    value={formik.values.hasMissedRentPayments}
                    label="Has any proposed tenant ever missed a rent payment?"
                  />
                </div>

                <div>
                  <PseudoBooleanDisplayView
                    value={formik.values.canPayBondAndAdvance}
                    label="Are you able to pay any rent in advance and bond immediately upon being accepted?"
                  />
                </div>

                {/* <div>
                  <PseudoBooleanDisplayView
                    value={formik.values.wouldLikeHelpWithUtilities}
                    label="Would you like help setting up utilities? I.e., internet, power, gas, etc"
                  />
                </div> */}

                <div>
                  <PseudoBooleanDisplayView
                    value={formik.values.anyBadCredit}
                    label="Does any proposed tenant have a low credit score? (Below 500)"
                  />
                </div>

                <div>
                  <PseudoBooleanDisplayView
                    value={formik.values.anyCriminalRecords}
                    label="Does any proposed tenant have a criminal record?"
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default RentalApplicationDetailView;
