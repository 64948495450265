import React, {useMemo, useState} from 'react';

import {Capacitor} from '@capacitor/core';
import moment from 'moment';
import DataTable, {type TableColumn} from 'react-data-table-component';
import {useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {Link} from 'react-router-dom';

import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import ManageListingMenu from 'components/listing/ManageListingMenu';
import PageWrapper from 'components/PageWrapper';
import RentalApplicationSummaryView from 'components/rental_application/RentalApplicationSummaryView';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import Listing from 'models/listings/Listing';
import ListingRentalApplication from 'models/listings/ListingRentalApplication';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';

type PseudoBoolean = 'Unspecified' | 'Yes' | 'No' | undefined;

const pseudoBooleanFormatter = (val: PseudoBoolean) => {
  if (val === 'Yes') {
    return '';
  } else if (val === 'No') {
    return 'X';
  } else {
    return 'Unspecified';
  }
};

const columns: TableColumn<ListingRentalApplication>[] = [
  {
    name: 'Name',
    selector: (row) => row.rentalApplication.headTenantName,
    sortable: false,
    reorder: true,
  },
  {
    name: 'Date Applied',
    selector: (row) => row.createdAt,
    format: (row) => moment(row.createdAt).format('DD MMM'),
    sortable: false,
    reorder: true,
  },
  {
    name: 'Other Tenants',
    selector: (row) => row.rentalApplication.rentalApplicationApplicantsCount,
    sortable: false,
    reorder: true,
  },
  {
    name: 'Pets',
    selector: (row) => row.rentalApplication.hasPets,
    format: (row) => pseudoBooleanFormatter(row.rentalApplication.hasPets),
    sortable: false,
    reorder: true,
  },
  {
    name: 'Smoker',
    selector: (row) => row.rentalApplication.anySmokers,
    format: (row) => pseudoBooleanFormatter(row.rentalApplication.anySmokers),
    sortable: false,
    reorder: true,
  },
  {
    name: 'Preferred Start Date',
    selector: (row) => row.rentalApplication.preferredTenancyStartDate,
    format: (row) =>
      row.rentalApplication.preferredTenancyStartDate
        ? moment(row.rentalApplication.preferredTenancyStartDate).format(
            'Do MMMM',
          )
        : '-',
    sortable: false,
    reorder: true,
  },
  {
    name: 'Preferred Tenancy Length',
    selector: (row) => row.rentalApplication.preferredTenancyLength,
    format: (row) =>
      row.rentalApplication.preferredTenancyLength
        ? row.rentalApplication.preferredTenancyLength + ' Months'
        : '-',
    sortable: false,
    reorder: true,
  },
];

const conditionalRowStyles = [
  {
    when: (row: ListingRentalApplication) => row.landlordTag === 'shortlisted',
    classNames: ['border-l-8 border-success'],
  },
  {
    when: (row: ListingRentalApplication) => row.landlordTag === 'excluded',
    classNames: ['border-l-8 border-error'],
  },
];

const RentalApplicationIndexPage = () => {
  usePageVisit('RentalApplicationIndexPage');
  const {propertyId, listingId} = useParams();

  const useListView = !useTailwindBreakpoint('md');

  const [excludePets, setExcludePets] = useState(false);
  const [excludeSmokers, setExcludeSmokers] = useState(false);
  const [showShortlisted, setShowShortlisted] = useState(false);
  const [hideExcluded, setHideExcluded] = useState(false);
  const [hideInsufficientReferences, setHideInsufficientReferences] =
    useState(false);

  const navigate = useNavigate();

  const {data: listing, isLoading: listingIsLoading} = useQuery(
    `listing-id-only-${listingId}`,
    async () => {
      const l = await Listing.select(['id']).find(listingId);

      return l.data;
    },
  );

  const {data, isLoading, error} = useQuery(
    ['listing-applications', listingId],
    async () => {
      const apps = await ListingRentalApplication.includes('rental_application')
        .where({listingId: listing.id})
        .all();

      return apps.data;
    },
    {
      enabled: !!listing,
    },
  );

  const filteredItems = useMemo((): ListingRentalApplication[] => {
    if (!data) {
      return [];
    }

    const filtered = [];

    for (const lra of data) {
      if (excludePets && lra.rentalApplication.hasPets === 'Yes') {
        continue;
      }

      if (excludeSmokers && lra.rentalApplication.anySmokers === 'Yes') {
        continue;
      }

      if (showShortlisted && lra.landlordTag !== 'shortlisted') {
        continue;
      }

      if (hideExcluded && lra.landlordTag === 'excluded') {
        continue;
      }

      // Check to see we have names for all 3 references.
      if (
        hideInsufficientReferences &&
        (!lra.rentalApplication.employerReferenceName ||
          !lra.rentalApplication.landlordReferenceName ||
          !lra.rentalApplication.otherReferenceName)
      ) {
        continue;
      }

      filtered.push(lra);
    }

    return filtered;
  }, [
    data,
    excludePets,
    excludeSmokers,
    showShortlisted,
    hideExcluded,
    hideInsufficientReferences,
  ]);

  const acceptedApplication = useMemo(() => {
    const list = data || ([] as ListingRentalApplication[]);

    return list.find((lra) => lra.accepted);
  }, [data]);

  if (error) {
    return errorViewForError(error);
  } else if (useListView) {
    return (
      <PageWrapper title="Listing Applications">
        <ManageListingMenu propertyId={propertyId} listingId={listingId} />{' '}
        {acceptedApplication && (
          <div className="alert alert-success shadow-lg mb-4">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span>
                You have accepted an application from{' '}
                {acceptedApplication.rentalApplication.headTenantName}.{' '}
                <Link
                  to={acceptedApplication.id}
                  className="text-white font-bold underline">
                  Click here
                </Link>{' '}
                to view it.
              </span>
            </div>
          </div>
        )}
        <Card className="mb-4">
          <CardBody className="p-2">
            <CardTitle>Filters</CardTitle>
            <div className="grid grid-cols-2">
              <div className="form-control mr-4">
                <label className="cursor-pointer label">
                  <span className="label-text mr-2">No Pets</span>
                  <input
                    type="checkbox"
                    checked={excludePets}
                    className="checkbox checkbox-accent checkbox-sm"
                    onChange={({target}) => setExcludePets(target.checked)}
                  />
                </label>
              </div>

              <div className="form-control mr-4">
                <label className="cursor-pointer label">
                  <span className="label-text mr-2">Non Smokers</span>
                  <input
                    type="checkbox"
                    checked={excludeSmokers}
                    className="checkbox checkbox-accent checkbox-sm"
                    onChange={({target}) => setExcludeSmokers(target.checked)}
                  />
                </label>
              </div>

              <div className="form-control mr-4">
                <label className="cursor-pointer label">
                  <span className="label-text mr-2">Promote Shortlisted</span>
                  <input
                    type="checkbox"
                    checked={showShortlisted}
                    className="checkbox checkbox-accent checkbox-sm"
                    onChange={({target}) => setShowShortlisted(target.checked)}
                  />
                </label>
              </div>

              {/* <div className="form-control mr-4">
                <label className="cursor-pointer label">
                  <span className="label-text mr-2">Hide Excluded</span>
                  <input
                    type="checkbox"
                    checked={hideExcluded}
                    className="checkbox checkbox-accent checkbox-sm"
                    onChange={({target}) => setHideExcluded(target.checked)}
                  />
                </label>
              </div> */}

              <div className="form-control mr-4">
                <label className="cursor-pointer label">
                  <span className="label-text mr-2">Has References</span>
                  <input
                    type="checkbox"
                    checked={hideInsufficientReferences}
                    className="checkbox checkbox-accent checkbox-sm"
                    onChange={({target}) =>
                      setHideInsufficientReferences(target.checked)
                    }
                  />
                </label>
              </div>
            </div>
          </CardBody>
        </Card>
        <div className="mb-4">
          <CardTitle>Applications</CardTitle>
        </div>
        <div className="flex flex-col space-y-4">
          {filteredItems.map((row) => (
            <Link key={row.id} to={row.id}>
              <Card>
                <CardBody>
                  <CardTitle className="flex justify-between items-center">
                    <span>{row.rentalApplication.headTenantName}</span>
                    <span className="text-sm font-normal">
                      {moment(row.createdAt).format('DD MMM')}
                    </span>
                  </CardTitle>

                  <div className="text-sm">
                    <span className="font-medium">Other Tenants: </span>
                    <span>
                      {row.rentalApplication
                        .rentalApplicationApplicantsCount === 0
                        ? 'None'
                        : row.rentalApplication
                            .rentalApplicationApplicantsCount}
                    </span>
                  </div>

                  <div className="grid grid-cols-2">
                    <div className="text-sm">
                      <span className="font-medium">Pets?: </span>
                      <span>{row.rentalApplication.hasPets}</span>
                    </div>

                    <div className="text-sm">
                      <span className="font-medium">Smoker?: </span>
                      <span>{row.rentalApplication.anySmokers}</span>
                    </div>
                  </div>

                  <div className="text-sm">
                    <span className="font-medium">Preferred Start Date: </span>
                    <span>
                      {row.rentalApplication.preferredTenancyStartDate
                        ? moment(
                            row.rentalApplication.preferredTenancyStartDate,
                          ).format('Do MMMM')
                        : 'Unspecified'}
                    </span>
                  </div>

                  <div className="text-sm">
                    <span className="font-medium">
                      Preferred Tenancy Length:{' '}
                    </span>
                    <span>
                      {row.rentalApplication.preferredTenancyLength
                        ? row.rentalApplication.preferredTenancyLength +
                          ' Months'
                        : 'Unspecified'}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Link>
          ))}
        </div>
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Listing Applications">
        <ManageListingMenu propertyId={propertyId} listingId={listingId} />{' '}
        {acceptedApplication && (
          <div className="alert alert-success shadow-lg mb-4">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span>
                You have accepted an application from{' '}
                {acceptedApplication.rentalApplication.headTenantName}.{' '}
                <Link
                  to={acceptedApplication.id}
                  className="text-white font-bold underline">
                  Click here
                </Link>{' '}
                to view it.
              </span>
            </div>
          </div>
        )}
        <Card className="mb-4 rounded-none shadow-none">
          <CardBody className="p-2">
            <CardTitle>Filters</CardTitle>
            <div className="flex justify-start items-center">
              <div className="form-control mr-4">
                <label className="cursor-pointer label">
                  <span className="label-text mr-2">No Pets</span>
                  <input
                    type="checkbox"
                    checked={excludePets}
                    className="checkbox checkbox-accent checkbox-sm"
                    onChange={({target}) => setExcludePets(target.checked)}
                  />
                </label>
              </div>

              <div className="form-control mr-4">
                <label className="cursor-pointer label">
                  <span className="label-text mr-2">Non Smokers</span>
                  <input
                    type="checkbox"
                    checked={excludeSmokers}
                    className="checkbox checkbox-accent checkbox-sm"
                    onChange={({target}) => setExcludeSmokers(target.checked)}
                  />
                </label>
              </div>

              <div className="form-control mr-4">
                <label className="cursor-pointer label">
                  <span className="label-text mr-2">Promote Shortlisted</span>
                  <input
                    type="checkbox"
                    checked={showShortlisted}
                    className="checkbox checkbox-accent checkbox-sm"
                    onChange={({target}) => setShowShortlisted(target.checked)}
                  />
                </label>
              </div>

              {/* <div className="form-control mr-4">
                <label className="cursor-pointer label">
                  <span className="label-text mr-2">Hide Excluded</span>
                  <input
                    type="checkbox"
                    checked={hideExcluded}
                    className="checkbox checkbox-accent checkbox-sm"
                    onChange={({target}) => setHideExcluded(target.checked)}
                  />
                </label>
              </div> */}

              <div className="form-control mr-4">
                <label className="cursor-pointer label">
                  <span className="label-text mr-2">Has References</span>
                  <input
                    type="checkbox"
                    checked={hideInsufficientReferences}
                    className="checkbox checkbox-accent checkbox-sm"
                    onChange={({target}) =>
                      setHideInsufficientReferences(target.checked)
                    }
                  />
                </label>
              </div>
            </div>
          </CardBody>
        </Card>
        <DataTable
          columns={columns}
          data={filteredItems}
          selectableRows={false}
          expandableRows
          expandableRowsComponent={RentalApplicationSummaryView}
          progressPending={listingIsLoading || isLoading}
          highlightOnHover
          striped
          pointerOnHover
          fixedHeader
          fixedHeaderScrollHeight="300"
          responsive
          pagination
          onRowClicked={(row) => {
            if (Capacitor.isNativePlatform()) {
              navigate(row.id);
            } else {
              if (window.location.href.endsWith('/')) {
                window.open(window.location.href + row.id, '_blank');
              } else {
                window.open(window.location.href + '/' + row.id, '_blank');
              }
            }
          }}
          conditionalRowStyles={conditionalRowStyles}
        />
      </PageWrapper>
    );
  }
};

export default RentalApplicationIndexPage;
