import {useEffect, useState} from 'react';

import moment from 'moment';

import useLocalUserSettings from 'hooks/useLocalUserSettings';
import Conversation from 'models/Conversation';
import Property from 'models/properties/Property';
import User from 'models/users/User';
import {usePusherService} from 'services/PusherService';
import type {ChatChannel} from 'services/PusherService';
import useAuth from 'services/useAuth';
import {timeSince} from 'utilities/DateHelpers';

const ChatDetailHeader = ({
  channel,
  users,
}: {
  channel: ChatChannel;
  users: User[];
}) => {
  const pusherService = usePusherService();
  const [isConnected, setIsConnected] = useState(
    pusherService.client.connection.state === 'connected',
  );
  const {currentUser} = useAuth();
  const {activeAccountRole} = useLocalUserSettings();

  // We check every 2 seconds to see if the connection is still alive
  useEffect(() => {
    const interval = setInterval(() => {
      const status = pusherService.client.connection.state;
      if (status === 'disconnected' || status === 'unavailable') {
        setIsConnected(false);
        try {
          pusherService.client.connect();
        } catch (e) {
          // TODO: Handle pusher service connection error
        }
      } else if (status === 'connected') {
        setIsConnected(true);
      }
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [pusherService.client]);

  let name = '';
  let avatarSrc;
  if (channel.identifier === 'PropertyChannel') {
    const model = channel.model as Property;
    name = model.streetAddress;
    avatarSrc = model.mainImage;
  } else if (channel.identifier === 'ConversationChannel') {
    const model = channel.model as Conversation;
    // This get flipped as we want to show the other users details

    if (model.sender.id.toString() === currentUser.id) {
      name = model.receiver.name;
      avatarSrc = model.receiver.avatar;
    } else {
      name = model.sender.name;
      avatarSrc = model.sender.avatar;
    }
  }

  let lastTimeAgo;
  if (channel.mostRecentChatMessage) {
    lastTimeAgo = timeSince(
      moment(channel.mostRecentChatMessage.createdAt).toDate(),
    );
  }

  const renderMembers = () => {
    if (activeAccountRole === 'Landlord') {
      const members = users
        .filter((u) => u.id !== currentUser.id)
        .map((u) => u.name);

      if (members.length === 1) {
        return (
          <span className="text-xs text-gray-400 mr-3">
            Tenant: {members[0]}
          </span>
        );
      } else if (members.length > 1) {
        return (
          <span className="text-xs text-gray-400 mr-3">
            Tenants: {members.join(', ')}
          </span>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return (
    <div>
      {!isConnected && (
        <div className="bg-red-500 text-white text-xs flex justify-center items-center py-1 opacity-80">
          Disconnected from chat server, trying to reconnect...
        </div>
      )}

      <div className="flex sm:items-center justify-between border-b border-gray-200 pb-3">
        <div className="flex items-center space-x-4">
          <img
            src={avatarSrc}
            className="w-10 sm:w-12 h-10 sm:h-12 rounded-full cursor-pointer"
          />
          <div className="flex flex-col leading-tight">
            <div className="text-1xl mt-1 flex items-center">
              <span className="text-gray-700 mr-3">{name}</span>
            </div>
            <span className="text-xs text-gray-400 mr-3">
              {lastTimeAgo
                ? `Last message received ${lastTimeAgo} ago`
                : 'No messages in this chat'}
            </span>
            {renderMembers()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatDetailHeader;
