import {useEffect} from 'react';

import {useQueryClient} from 'react-query';

import ListingIndexCard from 'components/listing/ListingIndexCard';
import Container from 'components/walkthrough/common/Container';
import LeftSide from 'components/walkthrough/common/LeftSide';
import ProgressBox from 'components/walkthrough/common/ProgressBox';
import RightContainer from 'components/walkthrough/common/RightContainer';
import RightSideText from 'components/walkthrough/common/RightSideText';
import Property from 'models/properties/Property';
import TrackingService from 'services/TrackingService';

const PreviewStep = ({property}: {property: Property}) => {
  /**
   * Track starting the step.
   */
  useEffect(() => {
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_StartPreviewListingStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id]);

  const listing = property.listings[0];

  const queryClient = useQueryClient();

  const nextStep = async () => {
    property.lastOnboardingStepCompleted = 'listing_preview';
    await property.save();

    /**
     * Track completion of the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_CompletePreviewListingStep,
      {
        propertyId: property.id,
      },
    );

    queryClient.setQueryData('new-listing-wizard', property);
  };

  const previousStep = async () => {
    property.lastOnboardingStepCompleted = 'listing_photos';
    await property.save();
    queryClient.setQueryData('new-listing-wizard', property);
  };

  const title = 'Congratulations!';
  const subtitle =
    'Time to look everything over. Click the card to preview your listing...';

  return (
    <Container>
      <LeftSide title={title} subtitle={subtitle} />
      <RightContainer>
        <RightSideText title={title} subtitle={subtitle} />
        <div className="flex flex-col md:mx-16">
          <ListingIndexCard listing={listing} openInNewTab />
        </div>
        <ProgressBox
          nextStep={nextStep}
          previousStep={previousStep}
          step={9}
          previousStepEnabled
          nextStepEnabled
        />
      </RightContainer>
    </Container>
  );
};

export default PreviewStep;
