import {useParams} from 'react-router';

import LoadingView from 'components/common/LoadingView';
import CompletedInspectionList from 'components/inspection/CompletedInspectionList';
import InspectionInProgressCard from 'components/inspection/InspectionInProgressCard';
import LandlordScheduleInspectionCard from 'components/inspection/LandlordScheduleInspectionCard';
import RenterAwaitingInspectionTypeCard from 'components/inspection/RenterAwaitingInspectionTypeCard';
import RoomList from 'components/inspection/RoomList';
import {HeaderButton} from 'components/navbar/Header';
import PageWrapper from 'components/PageWrapper';
import useInspectionQueries from 'hooks/useInspectionQueries';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import Inspection from 'models/inspections/Inspection';
import InspectionItem from 'models/inspections/InspectionItem';
import useInspectionStore from 'stores/InspectionStore';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

const InspectionDetailPage = () => {
  useTitle('Inspection');
  usePageVisit('InspectionDetailPage');

  const {id} = useParams();
  const {activeAccountRole} = useLocalUserSettings();

  const {
    inspection,
    inspectionIsLoading,
    inspectionError,
    inspectionItemsIsLoading,
  } = useInspectionQueries(id);

  const landlordViewForStatus = () => {
    if (inspection?.status === 'scheduled') {
      return <LandlordScheduleInspectionCard inspection={inspection} />;
    } else if (
      inspection.status === 'awaiting_inspection' &&
      inspection.doneBy === 'landlord'
    ) {
      return <RoomList />;
    } else if (
      inspection.status === 'awaiting_inspection' &&
      inspection.doneBy === 'tenant'
    ) {
      return <InspectionInProgressCard />;
    } else if (inspection.status === 'awaiting_sign_offs') {
      return <RoomList />;
    } else if (inspection.status === 'complete') {
      return <CompletedInspectionList />;
    }
  };

  const renterViewForStatus = () => {
    if (inspection?.status === 'scheduled') {
      return <RenterAwaitingInspectionTypeCard />;
    } else if (
      inspection.status === 'awaiting_inspection' &&
      inspection.doneBy === 'tenant'
    ) {
      return <RoomList />;
    } else if (
      inspection.status === 'awaiting_inspection' &&
      inspection.doneBy === 'landlord'
    ) {
      return <InspectionInProgressCard />;
    } else if (inspection.status === 'awaiting_sign_offs') {
      return <RoomList />;
    } else if (inspection.status === 'complete') {
      return <CompletedInspectionList />;
    }
  };

  if (inspectionError) {
    return errorViewForError(inspectionError);
  } else if (inspectionIsLoading || inspectionItemsIsLoading) {
    return (
      <PageWrapper title="Inspection">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    const buttons = [] as HeaderButton[];
    if (
      activeAccountRole === 'Landlord' &&
      inspection.status !== 'awaiting_sign_offs' &&
      inspection.status !== 'complete'
    ) {
      buttons.push({
        text: 'Edit',
        href: 'edit',
        bgColor: 'neutral',
      });
    }

    return (
      <PageWrapper title="Inspection" buttons={buttons}>
        {activeAccountRole === 'Landlord'
          ? landlordViewForStatus()
          : renterViewForStatus()}
      </PageWrapper>
    );
  }
};

export default InspectionDetailPage;
