import React, {useEffect, useState} from 'react';

import {Capacitor} from '@capacitor/core';
import {useQuery} from 'react-query';
import {Navigate} from 'react-router';

import PageWrapper from 'components/PageWrapper';
import LoadingStep from 'components/walkthrough/common/LoadingStep';
import OnboardTypeStep from 'components/walkthrough/common/OnboardTypeStep';
import PropertyAddressStep from 'components/walkthrough/common/PropertyAddressStep';
import BankingStep from 'components/walkthrough/migrate_tenancy/BankingStep';
import CommencementDateStep from 'components/walkthrough/migrate_tenancy/CommencementDateStep';
import RentStep from 'components/walkthrough/migrate_tenancy/RentStep';
import TenantDetailsStep from 'components/walkthrough/migrate_tenancy/TenantDetailsStep';
import AvailabilityStep from 'components/walkthrough/new_tenancy/AvailabilityStep';
import ChattelsStep from 'components/walkthrough/new_tenancy/ChattelsStep';
import FinancialsStep from 'components/walkthrough/new_tenancy/FinancialsStep';
import GeneralInfoStep from 'components/walkthrough/new_tenancy/GeneralInfoStep';
import HealthyHomesStep from 'components/walkthrough/new_tenancy/HealthyHomesStep';
import InsuranceStep from 'components/walkthrough/new_tenancy/InsuranceStep';
import InviteTenantsStep from 'components/walkthrough/new_tenancy/InviteTenantsStep';
import LeaseConditionsStep from 'components/walkthrough/new_tenancy/LeaseConditionsStep';
import PersonalProfileStep from 'components/walkthrough/new_tenancy/PersonalProfileStep';
import PreviewLeaseStep from 'components/walkthrough/new_tenancy/PreviewLeaseStep';
import PropertyTypeStep from 'components/walkthrough/new_tenancy/PropertyTypeStep';
import RequirementsStep from 'components/walkthrough/new_tenancy/RequirementsStep';
import useKeyboardHeight from 'hooks/useKeyboardHeight';
import useNativeInsets from 'hooks/useNativeInsets';
import useScrollPositionFix from 'hooks/useScrollPositionFix';
import Property from 'models/properties/Property';
import {usePageVisit, useTitle} from 'utilities/hooks';

const Wrapper = ({children}: {children: React.ReactNode}) => {
  const insets = useNativeInsets();

  // IMPORTANT THIS IS HERE
  useScrollPositionFix();

  const paddingHeight = useKeyboardHeight();

  if (Capacitor.isNativePlatform()) {
    return (
      <div style={{paddingTop: insets.top}} className="h-full">
        <div className="w-full h-full flex flex-col overflow-y-scroll bg-white">
          <PageWrapper title="Add Property" disablePadding>
            {children}

            <div style={{height: paddingHeight}}></div>
          </PageWrapper>
        </div>
      </div>
    );
  } else {
    return <>{children}</>;
  }
};

const NewPropertyPage = () => {
  usePageVisit('NewPropertyPage');
  useTitle('Add Your Property');

  const [propertyId, setPropertyId] = useState<string | null>(
    localStorage.getItem('new-property-id'),
  );

  const {data, isLoading, error} = useQuery(
    [`new-property`, propertyId],
    async () => {
      const property = await Property.includes([
        {
          tenancies: 'tenancy_requests',
        },
        'documents',
      ])
        .order({'tenancies.id': 'desc'})
        .find(propertyId);
      return property.data;
    },
    {
      enabled: !!propertyId,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (error) {
      localStorage.removeItem('new-property-id');
    }
  }, [error]);

  const componentForStep = () => {
    const step = data.lastOnboardingStepCompleted;

    if (data.noTenancyActionType === 'advertise') {
      localStorage.setItem(
        'new-listing-address',
        JSON.stringify({address1: data.streetAddress}),
      );
      return <Navigate to="/listings/new" replace />;
    } else if (data.noTenancyActionType === 'migrate') {
      if (step === 'select_onboarding_type') {
        return <RentStep property={data} />;
      } else if (step === 'migrate_rent_information') {
        return <TenantDetailsStep property={data} />;
      } else if (step === 'migrate_invite_tenant') {
        return <BankingStep property={data} />;
      } else if (step === 'migrate_banking') {
        return <CommencementDateStep property={data} />;
      }
    } else if (data.noTenancyActionType === 'new') {
      const totalSteps = 15;
      // The listing_ is a fail safe for when someone creates the listing through mobile
      // goes to web to publish it
      // and then comes back to mobile and tries to enter the flow again without refreshing.
      if (step === 'select_onboarding_type' || step.startsWith('listing_')) {
        return (
          <PropertyTypeStep
            property={data}
            stepNumber={3}
            totalSteps={totalSteps}
          />
        );
      } else if (step === 'new_property_type') {
        return (
          <GeneralInfoStep
            property={data}
            stepNumber={4}
            totalSteps={totalSteps}
          />
        );
      } else if (step === 'new_general_information') {
        return (
          <FinancialsStep
            property={data}
            stepNumber={5}
            totalSteps={totalSteps}
          />
        );
      } else if (step === 'new_financials') {
        return (
          <AvailabilityStep
            property={data}
            stepNumber={6}
            totalSteps={totalSteps}
          />
        );
      } else if (step === 'new_availability') {
        return (
          <RequirementsStep
            property={data}
            stepNumber={7}
            totalSteps={totalSteps}
          />
        );
      } else if (step === 'new_requirements') {
        return (
          <PersonalProfileStep
            property={data}
            stepNumber={8}
            totalSteps={totalSteps}
          />
        );
      } else if (step === 'new_personal_profile') {
        return (
          <ChattelsStep
            property={data}
            stepNumber={9}
            totalSteps={totalSteps}
          />
        );
      } else if (step === 'new_chattels') {
        return (
          <InsuranceStep
            property={data}
            stepNumber={10}
            totalSteps={totalSteps}
          />
        );
      } else if (step === 'new_insurance') {
        return (
          <LeaseConditionsStep
            property={data}
            stepNumber={11}
            totalSteps={totalSteps}
          />
        );
      } else if (step === 'new_lease_conditions') {
        return (
          <PreviewLeaseStep
            property={data}
            stepNumber={12}
            totalSteps={totalSteps}
          />
        );
      } else if (step === 'new_preview_lease') {
        return (
          <HealthyHomesStep
            property={data}
            stepNumber={13}
            totalSteps={totalSteps}
          />
        );
      } else if (step === 'new_healthy_homes') {
        return (
          <InviteTenantsStep
            property={data}
            stepNumber={14}
            totalSteps={totalSteps}
          />
        );
      }
    }

    return <Navigate to={`/properties/${propertyId}`} replace />;
  };

  if (isLoading) {
    return (
      <Wrapper>
        <LoadingStep />
      </Wrapper>
    );
  } else if (error || !propertyId) {
    return (
      <Wrapper>
        <PropertyAddressStep setPropertyId={setPropertyId} />
      </Wrapper>
    );
  } else if (
    data &&
    (data.lastOnboardingStepCompleted === null ||
      data.lastOnboardingStepCompleted === 'add_property_address')
  ) {
    return (
      <Wrapper>
        <OnboardTypeStep property={data} />
      </Wrapper>
    );
  } else {
    if (data) {
      return <Wrapper>{componentForStep()}</Wrapper>;
    } else {
      return <Navigate to={`/properties/${propertyId}`} replace />;
    }
  }
};

export default NewPropertyPage;
