import {CircularProgressBar} from '@tomik23/react-circular-progress-bar';

const ProgressCard = ({percent}: {percent: number}) => {
  return (
    <div className="w-48 h-48 lg:w-64 lg:h-64 py-4 px-6 text-gray-600 rounded-xl border border-gray-200 bg-white">
      <div className="flex flex-col justify-center items-center">
        <div className="block lg:hidden">
          <CircularProgressBar
            percent={percent}
            colorCircle="#F3F3F3"
            colorSlice="#3366FF"
            size={100}
          />
        </div>
        <div className="hidden lg:block">
          <CircularProgressBar
            percent={percent}
            colorCircle="#F3F3F3"
            colorSlice="#3366FF"
            size={200}
          />
        </div>

        <h3 className="font-semibold text-lg lg:text-xl">Profile Completion</h3>
      </div>
    </div>
  );
};

export default ProgressCard;
