import React, {Fragment, useState} from 'react';

import {Dialog, Transition} from '@headlessui/react';
import {useParams} from 'react-router';

import CardTitle from 'components/common/card/CardTitle';
import EnquiryMessage from 'models/listings/EnquiryMessage';
import EnquiryThread from 'models/listings/EnquiryThread';
import RentalApplication from 'models/listings/RentalApplication';
import useAuth from 'services/useAuth';

const LandlordNewEnquiryThreadModal = ({
  modalIsOpen,
  setModalIsOpen,
  application,
  listingId,
}: {
  modalIsOpen: boolean;
  setModalIsOpen: (val: boolean) => void;
  application: RentalApplication;
  listingId: string;
}) => {
  const [messageText, setMessageText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [sentMessage, setSentMessage] = useState<EnquiryMessage | undefined>(
    undefined,
  );

  const {propertyId, listingId: listingPublicId} = useParams();

  const {currentUser} = useAuth();

  const sendMessage = async () => {
    if (messageText.length === 0) {
      return;
    }
    setIsLoading(true);

    const thread = await EnquiryThread.where({
      email: application.headTenantEmail,
      listingId: listingId,
    }).first();

    if (thread.data) {
      const message = new EnquiryMessage({
        email: currentUser.email,
        name: currentUser.name,
        message: messageText,
        enquiryThreadId: thread.data.id,
      });

      const result = await message.save();

      if (result) {
        setSentMessage(message);
      }
    } else {
      const message = new EnquiryMessage({
        name: currentUser.name,
        email: currentUser.email,
        userId: currentUser.id,
        message: messageText,
      });

      const newThread = new EnquiryThread({
        name: application.user.name,
        email: application.headTenantEmail,
        listingId: listingId,
      });
      newThread.enquiryMessages = [message];
      const result = await newThread.save({with: 'enquiryMessages'});

      if (result) {
        setSentMessage(message);
      }
    }

    setMessageText('');
    setIsLoading(false);
  };

  const closeModal = () => {
    setSentMessage(undefined);
    setMessageText('');
    setModalIsOpen(false);
  };

  return (
    <Transition appear show={modalIsOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}>
        <div className="min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            className="inline-block py-8 h-screen w-full"
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white shadow-xl pt-4">
              <div className="flex-grow overflow-y-auto">
                <div className="px-4">
                  <CardTitle>Send a message</CardTitle>

                  <p className="mt-2">
                    Need more information from this applicant? Or need to
                    contact them for another reason? Write them a message here.
                  </p>

                  <textarea
                    className="input input-bordered w-full mt-4 h-auto"
                    value={messageText}
                    onChange={(e) => setMessageText(e.target.value)}
                    rows={3}
                    placeholder="Write a message here..."></textarea>

                  {sentMessage ? (
                    <div className="alert alert-success shadow-lg mt-4">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="stroke-current flex-shrink-0 h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        <span>
                          Your message has been sent. You can see it be{' '}
                          <a
                            className="link text-white font-bold underline"
                            href={`/properties/${propertyId}/listings/${listingPublicId}/enquiries/${
                              sentMessage!.enquiryThreadId
                            }`}
                            target="_blank">
                            clicking here
                          </a>{' '}
                          to go to your enquiries inbox.
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="p-4 flex-shrink-0 bg-neutral-100 flex items-center justify-between">
                <button
                  className="btn btn-outline btn-secondary btn-sm"
                  onClick={closeModal}
                  disabled={isLoading}>
                  Cancel
                </button>

                <button
                  className="btn btn-primary btn-sm"
                  onClick={sendMessage}
                  disabled={isLoading || messageText.length === 0}>
                  Send Message
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LandlordNewEnquiryThreadModal;
