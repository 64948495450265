import {useEffect} from 'react';

import {AiOutlineWarning} from '@react-icons/all-files/ai/AiOutlineWarning';
import _ from 'lodash';
import moment from 'moment';
import Toggle from 'react-toggle';

import CalendarIcon from 'components/common/CalendarIcon';
import Card from 'components/common/card/Card';
import CardBody from 'components/common/card/CardBody';
import CardTitle from 'components/common/card/CardTitle';
import LoadingView from 'components/common/LoadingView';
import demoInspection from 'components/demo/DemoInspection';
import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import ActionsList from 'components/inspection/actions/ActionsList';
import InspectionItemTitleWithCounts from 'components/inspection/items/InspectionItemTitleWithCounts';
import MediaItemGrid from 'components/inspection/media_items/MediaItemGrid';
import NoteList from 'components/inspection/notes/NoteList';
import SignOffView from 'components/inspection/sign_offs/SignOffView';
import PageWrapper from 'components/PageWrapper';
import useInspectionStore from 'stores/InspectionStore';
import {titleize} from 'utilities/StringHelpers';

const helpTexts = {
  'Lights/Power points':
    'Are all lights and power points in the property working?',
  'Smoke alarms':
    "Test all smoke alarms in the property to check if they're working.",
} as Record<string, string>;

const DemoInspectionDetailPage = () => {
  const [inspection, setInspection, setInspectionItems] = useInspectionStore(
    (state) => [
      state.inspection,
      state.setInspection,
      state.setInspectionItems,
    ],
  );

  useEffect(() => {
    if (!inspection) {
      setInspection(demoInspection);
      setInspectionItems(demoInspection.inspectionItems);
    }

    return () => {
      setInspection(null);
      setInspectionItems([]);
    };
  }, [demoInspection]);

  const inspectionItems = inspection
    ? _.groupBy(inspection.inspectionItems, 'room')
    : {};

  const renderRoomCard = (roomKey: string) => {
    const items = inspectionItems[roomKey];

    if (roomKey === 'All') {
      return <div key={roomKey} />;
    }

    if (inspection.reportType === 'detailed') {
      return (
        <Card className="mt-4">
          <CardBody>
            <CardTitle>{roomKey}</CardTitle>

            {items.map((item, index) => {
              return (
                <div className="mt-2" key={index}>
                  <InspectionItemTitleWithCounts item={item} size="small" />

                  <MediaItemGrid inspection={inspection} item={item} />

                  <NoteList inspection={inspection} item={item} />
                  <ActionsList inspection={inspection} item={item} />
                </div>
              );
            })}
          </CardBody>
        </Card>
      );
    } else {
      const item = items[0];

      return (
        <Card className="mt-4">
          <CardBody>
            <InspectionItemTitleWithCounts item={item} />

            <MediaItemGrid inspection={inspection} item={item} />

            <NoteList inspection={inspection} item={item} />
            <ActionsList inspection={inspection} item={item} />
          </CardBody>
        </Card>
      );
    }
  };

  const renderSignOffs = () => {
    return (
      <Card className="mt-4">
        <CardBody>
          <CardTitle className="mb-0">Sign Offs</CardTitle>

          <div className="mt-4 flex justify-start items-center gap-8 flex-wrap">
            {inspection.inspectionSignOffs.map((signOff) => (
              <SignOffView signOff={signOff} key={signOff.id} />
            ))}
          </div>
        </CardBody>
      </Card>
    );
  };

  const renderAllRoom = () => {
    const all = inspectionItems.All;

    if (all) {
      return (
        <Card className="mt-4">
          <CardBody>
            {all.map((item, index) => {
              const helpText = helpTexts[item.name];
              return (
                <div key={index} className={index > 0 ? 'mt-4' : ''}>
                  <div className="flex justify-between items-center">
                    <strong>
                      {item.name} <HelpTextPresenter helpText={helpText} />
                    </strong>
                    <Toggle
                      checked={item.toggleState}
                      onChange={() => 'noop'}
                    />
                  </div>

                  <NoteList inspection={inspection} item={item} />
                  <ActionsList inspection={inspection} item={item} />
                </div>
              );
            })}
          </CardBody>
        </Card>
      );
    }
  };

  if (inspection) {
    let title: string;
    if (inspection.inspectionPeriodType === 'pre_tenancy') {
      title = 'Initial Inspection';
    } else if (inspection.inspectionPeriodType === 'final') {
      title = 'Final Inspection';
    } else {
      title = 'Routine Inspection';
    }

    return (
      <PageWrapper title="Inspection">
        <div className="alert alert-warning shadow-lg mb-4">
          <div>
            <AiOutlineWarning className="w-6 h-6" />
            <span>
              This inspection is only a sample of a full inspection for demo
              purposes. A real inspection will generate a customisable section
              for every area in the property.
            </span>
          </div>
        </div>

        <Card className="mt-4">
          <CardBody>
            <div className="flex justify-start items-center gap-4 ">
              <CalendarIcon date={new Date(inspection.finalizedDate)} />

              <div>
                <CardTitle>{title}</CardTitle>
                <p className="text-secondary">
                  {moment(inspection?.finalizedDate).format(
                    inspection?.newInspectionTimeFormat,
                  )}
                </p>
                <p className="text-secondary">
                  Conducted by the {titleize(inspection.doneBy)}
                </p>
              </div>
            </div>
          </CardBody>
        </Card>

        {Object.keys(inspectionItems).map(renderRoomCard)}

        {renderAllRoom()}

        {renderSignOffs()}
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Inspection">
        <LoadingView />
      </PageWrapper>
    );
  }
};

export default DemoInspectionDetailPage;
