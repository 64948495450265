import {useCallback, useEffect, useMemo} from 'react';

import * as Sentry from '@sentry/react';
import {sentenceCase} from 'change-case';
import {Form, Formik} from 'formik';
import _ from 'lodash';
import {Navigate, useNavigate, useNavigation} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useQueryParam, StringParam} from 'use-query-params';
import * as Yup from 'yup';

import LandlordDashboardScreenshot from 'assets/img/iphone-mobile-landlord-dashboard.png';
import {Button} from 'components_sb/buttons';
import {TextField} from 'components_sb/input';
import {AuthFormLayout} from 'components_sb/layout';
import {Link} from 'components_sb/navigation';
import Paragraph from 'components_sb/typography/Paragraph/Paragraph';
import TrackingService from 'services/TrackingService';
import useAuth, {accountTypeMapping} from 'services/useAuth';
import SocialAuth, {SocialAuthProviderType} from 'utilities/auth/SocialAuth';
import {usePageVisit, useTitle} from 'utilities/hooks';
import {capitalize, isFullName} from 'utilities/StringHelpers';

interface RegisterPageProps {
  /**
   * The types of account a user can register for.
   */
  accountType: 'landlord' | 'tenant';
}

/**
 * A set of form error message mappings.
 */
interface ErrorMessageMapping {
  /**
   * The keyword to find within the 'reason' to activate the mapping.
   */
  keyword: string;
  /**
   * The message that replaces the 'reason' returned for the error.
   */
  message: string;
}

/**
 * Key/value pairings of a form field name to a set of form error
 * message mappings.
 */
interface ErrorMessageMappings {
  [key: string]: ErrorMessageMapping[];
}

/**
 * Define mappings here to override the 'reason' string for the error
 * messages for particular fields.
 */
const ERROR_MESSAGE_MAPPINGS: ErrorMessageMappings = {
  email: [
    {
      keyword: 'taken',
      message: 'There is already an account registered to this email address',
    },
  ],
};

interface RegisterFormData {
  name: string;
  email: string;
  password: string;
}

const RegisterPage = ({accountType}: RegisterPageProps) => {
  useTitle('Register');
  usePageVisit('RegisterPage');

  // Load the external scripts required for social authentication
  SocialAuth.useAuthScripts();

  const isLandlord = useMemo(() => accountType === 'landlord', [accountType]);

  const {registerUser} = useAuth();

  const showGenericError = useCallback(() => {
    toast.error(
      `There was an issue registering your account. Please try again later or contact us for support.`,
    );
  }, []);

  const handleFormSubmit = useCallback(
    async (formData: RegisterFormData, actions: any) => {
      let response;
      try {
        // Attempt the registration request
        const {name, email, password} = formData;
        response = await registerUser('email', {
          user: {
            name,
            email,
            password,
          },
          account_type: accountTypeMapping[accountType], // Include the account type alongside the form data
        });
      } catch (error) {
        // Error performing the registration request
        showGenericError();
        // Reset the submitting state
        actions.setSubmitting(false);
        return;
      }

      // Request performed successfully
      const {responseObject, status} = response;

      // Request was performed successfully but the server returned errors
      if (status !== 200) {
        // Initial error response object (before message mappings applied)
        const {errors} = responseObject;

        // Server returned error messages to display
        if (errors) {
          // Replace any matched errors with messages from the defined mapping
          for (const [fieldName, reasons] of Object.entries(errors)) {
            const mappings = ERROR_MESSAGE_MAPPINGS[fieldName] ?? [];
            errors[fieldName] = (reasons as string[]).map(
              (reason: string) =>
                mappings.find(({keyword}: {keyword: string}) =>
                  reason.includes(keyword),
                ).message ?? `${sentenceCase(fieldName)} reason`,
            );
          }

          // Set error messages on the applicable fields
          for (const fieldName in errors) {
            const messages = errors[fieldName];
            for (const message of messages) {
              actions.setFieldError(fieldName, message);
            }
          }
        }

        // No specific errors to display were returned by the server
        else {
          showGenericError();
        }
      }
      // Reset the submitting state
      actions.setSubmitting(false);
    },
    [accountType, registerUser, showGenericError],
  );

  const onSocialAuthSuccess = useCallback(
    async (provider: SocialAuthProviderType, data: any) => {
      try {
        // Attempt the registration request
        const response = await registerUser(provider, {
          ...data,
          account_type: accountTypeMapping[accountType], // Include the account type alongside social auth data
        });

        // Request performed successfully
        const {status} = response;

        // Request performed successfully but registration was unsuccessful
        if (status !== 200) {
          throw new Error();
        }
      } catch (error) {
        // Error performing the registration request
        showGenericError();
      }
    },
    [accountType, registerUser, showGenericError],
  );

  return (
    <AuthFormLayout
      title={`Register as a ${isLandlord ? 'Landlord' : 'Tenant'}`}
      description={`${
        isLandlord
          ? 'Self-manage easily in less than 5 minutes.'
          : 'Set up an account to help create healthier tenancies.'
      }`}
      socialAuthButtonConfig={{
        action: 'register',
        onSuccess: onSocialAuthSuccess,
        onError: showGenericError,
      }}
      appPreviewConfig={
        isLandlord
          ? {
              screenshot: LandlordDashboardScreenshot,
            }
          : null
      }>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{
          name: '',
          email: '',
          password: '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required('Please enter your full name')
            .min(1, 'Must be 1 character or more')
            .max(128, 'Must be 128 characters or less')
            .test(
              'two-names-plus',
              'Please enter both your first and last name',
              (value) => {
                if (!value) {
                  return false;
                }
                return isFullName(value);
              },
            ),
          email: Yup.string()
            .email('Invalid email address')
            .required('Please enter your email address'),
          password: Yup.string()
            .required('Please enter a password for your account')
            .min(6, 'Your password must be at least 6 characters')
            .max(128, 'Your password must be 128 characters or less'),
        })}
        validateOnBlur={false}
        validateOnChange={false}>
        <Form noValidate className="flex flex-col items-center gap-y-8">
          <div className="w-full flex flex-col gap-y-8">
            <TextField
              name="name"
              label="Full name"
              type="text"
              size="base"
              mode="formik"
              placeholder="Enter your full name here..."
              required
            />
            <TextField
              name="email"
              label="Email"
              type="email"
              size="base"
              mode="formik"
              placeholder="Enter your email address here..."
              required
            />
            <TextField
              name="password"
              label="Password"
              description="(6+ characters)"
              type="password"
              size="base"
              mode="formik"
              placeholder="Enter a password here..."
              required
            />
            {/* TODO: Move promo entry to billing setup */}
            {/* <TextField
              name="promo_string"
              label="Promo code"
              type="text"
              size="base"
              mode="formik"
              placeholder="Enter a promo code here if you have one..."
            /> */}
          </div>
          <Paragraph>
            <span>
              {`By clicking the button below, you acknowledge that you accept the `}
            </span>
            <Link openInNewTab to="https://www.keyhook.com/terms-of-service">
              Terms of Service
            </Link>
            <span>{` and `}</span>
            <Link openInNewTab to="https://www.keyhook.com/privacy">
              Privacy Policy
            </Link>
            <span>.</span>
          </Paragraph>
          <Button
            label="Register"
            category="primary"
            size="base"
            mode="formik"
            loadingLabel="Registering..."
          />
        </Form>
      </Formik>
      <span className="w-full flex flex-col xs:flex-row items-center justify-between gap-x-12 gap-y-4">
        <Link to="/login">Already have an account?</Link>
        <Link to={`/register/${isLandlord ? 'tenant' : 'landlord'}`}>
          {`I want to register as a ${isLandlord ? 'tenant' : 'landlord'}`}
        </Link>
      </span>
    </AuthFormLayout>
  );
};

export default RegisterPage;
