import React from 'react';

import styled from '@emotion/styled';

// Taken from https://github.com/kkostov/react-calendar-icon/
// Didnt have the customization we need in the actual package.

const themedConfig = (theme: any) => ({
  textColor: 'white', // text color of the header and footer
  primaryColor: '#3366FF', // used as background of the header and footer
  backgroundColor: '#fafafa',
  ...theme?.calendarIcon,
});

const IconDiv = styled('div')((props) => ({
  fontSize: '0.7em',
  backgroundColor: themedConfig(props.theme).backgroundColor,
  height: '6em',
  width: '6em',
  borderRadius: '0.7em',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const HeaderDiv = styled('div')((props) => ({
  color: themedConfig(props.theme).textColor,
  backgroundColor: themedConfig(props.theme).primaryColor,
  width: '100%',
  textAlign: 'center',
  fontSize: '1.0em',
  lineHeight: '1.4em',
}));

const ValueDiv = styled('div')({
  letterSpacing: '-0.05em',
  fontSize: '1.30rem',
  marginRight: '0.15em',
  marginTop: '0.1em',
});

const FooterDiv = styled('div')((props) => ({
  color: themedConfig(props.theme).textColor,
  backgroundColor: themedConfig(props.theme).primaryColor,
  width: '100%',
  textAlign: 'center',
  fontSize: '1.2em',
  lineHeight: '1.4em',
}));

interface OptionsObject {
  locale: Intl.LocalesArgument;
  header: Intl.DateTimeFormatOptions;
  value: Intl.DateTimeFormatOptions;
  footer: Intl.DateTimeFormatOptions;
}

const defaultOptions: OptionsObject = {
  header: {weekday: 'long'},
  footer: {month: 'short'},
  value: {day: '2-digit'},
  locale: [] as Intl.LocalesArgument,
};

function formatDate(
  date: Date,
  locale: Intl.LocalesArgument,
  formatOptions: Intl.DateTimeFormatOptions,
) {
  return date.toLocaleDateString(locale, formatOptions);
}

export default function CalendarIcon({
  date,
  options = defaultOptions,
}: {
  date: Date;
  options?: OptionsObject;
}) {
  return (
    <IconDiv>
      <HeaderDiv>{formatDate(date, options.locale, options.header)}</HeaderDiv>
      <ValueDiv>{formatDate(date, options.locale, options.value)}</ValueDiv>
      <FooterDiv>{formatDate(date, options.locale, options.footer)}</FooterDiv>
    </IconDiv>
  );
}

export {CalendarIcon};
