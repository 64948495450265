import clsx from 'clsx';

/**
 * Tailwind class configuration
 */
const classes = {
  // Classes for all permutations
  base: clsx('animate-spin'),
  // Classes based on color
  color: {
    brand: 'fill-brand-500',
    white: 'fill-white',
  },
  // Classes based on size
  size: {
    xs: 'w-4 h-4',
    sm: 'w-6 h-6',
    base: 'w-8 h-8',
    lg: 'w-12 h-12',
  },
};

interface SpinningLoaderProps {
  /**
   *  The size of the spinning loader.
   */
  size: 'xs' | 'sm' | 'base' | 'lg';
  /**
   *  The color of the spinning loader.
   */
  color: 'brand' | 'white';
}

/**
 * Indicates a loading state.
 */
const SpinningLoader = ({color, size}: SpinningLoaderProps) => (
  <svg className={clsx(classes.base, classes.size[size])} viewBox="3 3 18 18">
    <path
      className={clsx(classes.color[color], 'opacity-25')}
      d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
    <path
      className={clsx(classes.color[color], 'opacity-100')}
      d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
  </svg>
);

export default SpinningLoader;
